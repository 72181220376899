import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { fetchPengajaran, fetchRuangan } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterTahunAjaran,
  FilterTanggal,
  LayoutAddBtn,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import {
  getGenapGanjil,
  getHari,
  getIconStatusPengajaran,
  getStatusPengajaran,
  saAlert,
} from "../../helpers";
import { userState } from "../../storage/auth";
import { semesterAjaranState, tahunAjaranState } from "../../storage/mhs";
import { tanggalPengajaranState, titleState } from "../../storage/pages";
import PengajaranAbsensi from "./Absensi";
import PengajaranCreate from "./Create";
import PengajaranDelete from "./Delete";
import PengajaranEdit from "./Edit";
import PengajaranList from "./List";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const Pengajaran = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);
  const user = useRecoilValue(userState);
  const tA = useRecoilValue(tahunAjaranState);
  const sA = useRecoilValue(semesterAjaranState);

  const [items, setItems] = useState({});
  const [ketepatanWaktuPengajaran, setKetepatanWaktuPengajaran] = useState({});

  const [tahunAjaran, setTahunAjaran] = useState(tA);
  const [semesterAjaran, setSemesterAjaran] = useState(sA);
  const [tanggalPengajaran, setTanggalPengajaran] = useRecoilState(
    tanggalPengajaranState
  );
  const [tanggal, setTanggal] = useState(tanggalPengajaran);

  useEffect(() => {
    setTitle("Jadwal Pengajaran");
  }, [setTitle]);

  const fetchData = useCallback(async () => {
    if (tahunAjaran !== "" && semesterAjaran !== "" && tanggal !== "") {
      try {
        const res = await fetchPengajaran(
          "all",
          `tahun_ajaran=${tahunAjaran}&semester_ajaran=${semesterAjaran}&tanggal=${tanggal}`
        );
        if (
          res.data &&
          res.data.pengajaran &&
          res.data.ketepatan_waktu_pengajaran
        ) {
          res.data && res.data.pengajaran && setItems(res.data.pengajaran);
          res.data && res.data.ketepatan_waktu_pengajaran
            ? setKetepatanWaktuPengajaran(res.data.ketepatan_waktu_pengajaran)
            : setKetepatanWaktuPengajaran({});
        } else {
          setItems({});
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [tahunAjaran, semesterAjaran, tanggal]);

  useEffect(() => {
    setIsLoaded(true);
    fetchData();
  }, [fetchData, setIsLoaded]);

  // Ruangan
  const [ruangan, setRuangan] = useState({});

  useEffect(() => {
    const fetchDataRuangan = async () => {
      try {
        const res = await fetchRuangan("all");
        setRuangan(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchDataRuangan();
  }, [setRuangan]);

  const history = useHistory();

  const handleAddCustom = () => {
    if (tanggal === "") {
      saAlert(
        "warning",
        "Pilih tanggal terlebih dahulu !",
        "Untuk menggunakan tombol ini harap menambahkan tanggal terlebih dahulu."
      );
    } else {
      history.push(`/kelas-akademik/${tanggal}`);
    }
  };

  if (isLoaded) {
    return (
      <>
        {user.group === "Akademik" || user.group === "Kacab" ? (
          <>
            <LayoutAddBtn add="Tambah Kelas Custom" onClick={handleAddCustom} />
            <PengajaranCreate
              onFetchData={fetchData}
              tahunAjaran={tahunAjaran}
              semesterAjaran={semesterAjaran}
            />
          </>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-md-6">
            <FilterTahunAjaran
              onChangeTahun={(res) => setTahunAjaran(res)}
              onChangeSemester={(res) => setSemesterAjaran(res)}
              valueTahun={tahunAjaran}
              valueSemester={semesterAjaran}
            />
          </div>
          <div className="col-md-6">
            <FilterTanggal
              onChange={(res) => {
                setTanggal(res);
                setTanggalPengajaran(res);
              }}
              value={tanggalPengajaran}
            />
          </div>
        </div>
        <p className="text-center text-dark mb-4">
          <i className="fa fa-fw fa-circle-exclamation text-warning"/> Tahun Ajaran, Semester, dan Tanggal tidak boleh kosong.
        </p>

        <TextOnOffMhs>
          <div className="row mt-3">
            <div className="col-lg">
              <h6>Kode warna icon untuk status pengajaran :</h6>
              <p className="my-1 text-gray-400"><i className="me-1 fa fa-lg fa-fw fa-circle text-secondary" /> <span className="me-1">=</span> Dibuat oleh Akademik</p>
              <p className="my-1 text-gray-400"><i className="me-1 fa fa-lg fa-fw fa-circle-up text-info" /> <span className="me-1">=</span> Diperbarui oleh Akademik</p>
              <p className="my-1 text-gray-400"><i className="me-1 fa fa-lg fa-fw fa-circle-up text-warning" /> <span className="me-1">=</span> Diperbarui oleh UPT</p>
              <p className="my-1 text-gray-400"><i className="me-1 fa fa-lg fa-fw fa-circle-pause fa-fade text-info" /> <span className="me-1">=</span> Kelas sedang berlangsung</p>
              <p className="my-1 text-gray-400"><i className="me-1 fa fa-lg fa-fw fa-circle-check text-success-2" /> <span className="me-1">=</span> Kelas selesai</p>
              <p className="my-1 text-gray-400"><i className="me-1 fa fa-lg fa-fw fa-circle-xmark text-danger" /> <span className="me-1">=</span> Kelas dibatalkan</p>
            </div>
            <div className="col-lg">
              <h6>Kode warna icon untuk kelas tambahan :</h6>
              <p className="my-1 text-gray-400"><i className="me-1 fa fa-lg fa-fw fa-circle-plus" style={{color: '#d400c0'}}/> <span className="me-1">=</span> Kelas tambahan</p>
              <p className="my-1 text-gray-400"><i className="me-1 fa fa-lg fa-fw fa-circle text-secondary" /> <span className="me-1">=</span> Kelas biasa</p>
            </div>
            <div className="col-lg">
              <h4>Analisa data :</h4>
              <p className="mb-2 text-gray-400">
                Ketepatan waktu Tahun Ajaran {tahunAjaran} Semester {parseInt(semesterAjaran) === 1 && "Ganjil"} {parseInt(semesterAjaran) === 2 && "Genap"} adalah :
              </p>
              <p className="mb-0 bg-dark rounded py-1 px-2 h1" style={{boxShadow: 'inset 0px 0px 12px rgba(0, 0, 0, 0.5)'}}>{ketepatanWaktuPengajaran.ketepatan_waktu ? `${ketepatanWaktuPengajaran.ketepatan_waktu}%` : '0%'}</p>
            </div>
          </div>
        </TextOnOffMhs>

        {/* <h5>Kode Warna Text :</h5>
        <span>Created by Akademik</span>
        {` | `}
        <span className="text-warning">Updated by Akademik</span>
        {` | `}
        <span className="text-primary">Updated by UPT</span>
        {` | `}
        <span className="text-info">Mulai Dosen</span>
        {` | `}
        <span className="text-success">Sukses</span>
        {` | `}
        <span className="text-danger">Cancelled</span>
        {` | `}
        <span style={{ color: "pink" }}>
          Kelas Tambahan (Warna hanya di Kode Kelas)
        </span>

        <h5 className="mt-3">Kode Warna Background Mata Kuliah :</h5>
        <span className="px-2 text-white bg-success">Praktek</span>
        {` | `}
        <span className="px-2 text-white bg-info">Teori</span> */}

        {/* <h2>
          Ketepatan Waktu Tahun Ajaran {tahunAjaran} Semester{" "}
          {semesterAjaran === "1" && "Ganjil"}
          {semesterAjaran === "2" && "Genap"}{" "}
          {`= ${ketepatanWaktuPengajaran.ketepatan_waktu}`}%
        </h2> */}

        {items && items.length > 0 ? (
          <div className="row">
            <div className="col-6 pe-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center'>
                    <Th>No</Th>
                    <Th className="text-nowrap">Kode Kelas</Th>
                    <Th className="text-nowrap">Hari</Th>
                    <Th className="text-nowrap">Dari</Th>
                    <Th className="text-nowrap">Sampai</Th>
                    <Th className="text-nowrap">
                      <i className="fa fa-cog"></i>
                    </Th>
                    <Th className="text-nowrap">
                      <i className="fa fa-fw fa-circle text-danger-2"/>
                      <i className="fa fa-fw fa-circle text-warning"/>
                      <i className="fa fa-fw fa-circle text-success-2"/>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-center">{index + 1}</Td>
                        <Td
                          className="text-nowrap text-center"
                        >
                          {i.kelas ? i.kelas.kode : "-"}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {i.hari ? getHari(i.hari) : "-"}
                        </Td>
                        <Td className="text-nowrap text-center">{i.dari}</Td>
                        <Td className="text-nowrap text-center">{i.sampai}</Td>
                        <Td className="text-nowrap text-center">
                          <PengajaranEdit
                            pengajaran={i}
                            ruangan={ruangan}
                            onFetchData={fetchData}
                          />
                          <PengajaranList
                            pengajaran={i}
                            tahunAjaran={tahunAjaran}
                            semesterAjaran={semesterAjaran}
                            onFetchData={fetchData}
                          />
                          <PengajaranAbsensi
                            pengajaran={i}
                            onFetchData={fetchData}
                          />
                          {user.group === "Akademik" && parseInt(i.status) === 9 && (
                            <PengajaranDelete
                              pengajaran={i}
                              onFetchData={fetchData}
                            />
                          )}
                        </Td>
                        <Td className='text-end text-nowrap'>
                          {getIconStatusPengajaran(i, true, '0px', '24px')}
                          {i.custom ? 
                            <div className="parent position-relative d-inline-block me-1">
                              <i className="align-middle fa fa-lg fa-fw fa-circle-plus" style={{color: 'purple'}}/>
                              <div className="position-absolute child bg-primary text-white rounded px-4" style={{top: '0px', right: '24px'}}>Kelas tambahan</div> 
                            </div>
                            : 
                            <div className="parent position-relative d-inline-block me-1">
                              <i className="align-middle fa fa-lg fa-fw fa-circle text-secondary"/>
                              <div className="position-absolute child bg-primary text-white rounded px-4" style={{top: '0px', right: '24px'}}>Kelas biasa</div>
                            </div>
                          }
                          {
                            i.matkul &&
                            parseInt(i.matkul.jenis_matkul) === 1 &&
                            // <div className="parent position-relative d-inline-block">
                            //   <i className="fa fa-fw fa-circle text-warning"/>
                            //   <div className="position-absolute child bg-primary text-white rounded px-4" style={{top: '0px', right: '24px'}}>Praktek</div>
                            // </div>
                            <div style={{padding: '0px 32px'}} className="position-relative d-inline bg-warning text-black rounded text-sm fw-semibold"><span className="position-absolute" style={{left: '50%', top: '50%', transform: 'translateX(-50%) translateY(-50%)'}}>Praktek</span></div>
                          }
                          {
                            i.matkul &&
                            parseInt(i.matkul.jenis_matkul) === 2 &&
                            // <div className="parent position-relative d-inline-block">
                            //   <i className="fa fa-fw fa-circle text-primary"/>
                            //   <div className="position-absolute child bg-primary text-white rounded px-4" style={{top: '0px', right: '24px'}}>Teori</div>
                            // </div>
                            <div style={{padding: '0px 32px'}} className="position-relative d-inline bg-primary text-white rounded text-sm"><span className="position-absolute" style={{left: '50%', top: '50%', transform: 'translateX(-50%) translateY(-50%)'}}>Teori</span></div>
                          }
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-6 ps-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center'>
                    <Th className="text-nowrap">Cabang</Th>
                    <Th className="text-nowrap">Mata Kuliah</Th>
                    <Th className="text-nowrap">Hadir</Th>
                    <Th className="text-nowrap">Jumlah Mhs</Th>
                    <Th className="text-nowrap">Ruangan</Th>
                    <Th className="text-nowrap">Pertemuan</Th>
                    <Th className="text-nowrap">Semester</Th>
                    <Th className="text-nowrap">Dosen</Th>
                    <Th className="text-nowrap">Status</Th>
                    <Th className="text-nowrap">Alasan Batal</Th>
                    <Th className="text-nowrap">Catatan Akademik</Th>
                    <Th className="text-nowrap">Persiapan Akademik</Th>
                    <Th className="text-nowrap">Persiapan UPT</Th>
                    <Th className="text-nowrap">Mulai Kelas</Th>
                    <Th className="text-nowrap">Berakhir Kelas</Th>
                    <Th className="text-nowrap">Dibuat Oleh</Th>
                    {/* <Th className="text-nowrap">Created At</Th>
                    <Th className="text-nowrap">Updated At</Th> */}
                  </Tr>
                </Thead>
                <Tbody>
                  {items.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-nowrap text-center">
                          {i.kelas ? i.kelas.cabang : "-"}
                        </Td>
                        <Td
                          className='text-nowrap'
                          // className={`text-nowrap ${
                          //   i.matkul &&
                          //   parseInt(i.matkul.jenis_matkul) === 1 &&
                          //   "text-white bg-success"
                          // } ${
                          //   i.matkul &&
                          //   parseInt(i.matkul.jenis_matkul) === 2 &&
                          //   "text-white bg-info"
                          // }`}
                        >
                          {i.matkul ? i.matkul.nama : "-"}
                        </Td>
                        <Td className="text-nowrap text-center text-success">
                          {i.absensi && i.absensi.length > 0
                            ? i.absensi.filter(
                                (a) =>
                                  parseInt(a.status) === 1 &&
                                  a.pertemuan === i.pertemuan
                              ).length
                            : ""}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {i.krs ? i.krs.length : "-"}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {i.ruangan ? i.ruangan.nama : "-"}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {i.pertemuan}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {getGenapGanjil(i.semester)}
                        </Td>
                        <Td className="text-nowrap">
                          {i.dosen ? i.dosen.nama : "-"}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {getStatusPengajaran(i, true)}
                        </Td>
                        <Td className="text-nowrap text-center text-danger">
                          {i.keterangan ? i.keterangan : "-"}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {i.catatan_akademik ? i.catatan_akademik : "-"}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {i.akademik_at ? i.akademik_at : "-"}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {i.upt_at ? i.upt_at : "-"}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {i.mulai_at ? i.mulai_at : "-"}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {i.selesai_at ? i.selesai_at : "-"}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {i.pembuat ? i.pembuat.nama : "-"}
                        </Td>
                        {/* <Td className="text-nowrap text-center">
                          {i.created_at}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {i.updated_at}
                        </Td> */}
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
          </div>
        ) : (
          <PageNoData />
        )}
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default Pengajaran;
