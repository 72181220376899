import React from 'react';

const PageLoading = (props) => {

  const {title} = props

  // const [textLoading, setTextLoading] = useState("Loading. . .");

  // useEffect(() => {
  //   const timeLoadA = setTimeout(() => {
  //     setTextLoading("Mengecek data. . .");
  //   }, 3000);

  //   const timeLoadB = setTimeout(() => {
  //     setTextLoading("Memproses data. . .");
  //   }, 6000);

  //   const timeLoadC = setTimeout(() => {
  //     setTextLoading("Data akan segera ditampilkan. . .");
  //   }, 9000);

  //   return () => {
  //     clearTimeout(timeLoadA);
  //     clearTimeout(timeLoadB);
  //     clearTimeout(timeLoadC);
  //   }
  // }, []);

  return (
    <div className="position-fixed start-0 top-0 d-flex justify-content-center align-items-center w-100 vh-100" style={{zIndex: '50', backgroundColor: 'rgba(0, 0, 0, 0.5)'}}>
      {/* <div className="spinner-border text-secondary" role="status">
        <span className="loader-awesome-2"></span>
        <span className="sr-only">Loading...</span>
      </div> */}
      <div className='position-realtive d-flex justify-content-center align-items-center bg-gradient-primary d-flex rounded-pill' style={{padding: '10px', boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.5)'}}>
        <span className="loader-awesome-2"></span>
        {/* <div className='position-absolute rounded-pill' style={{padding: '6px'}}/> */}
        <i className='position-absolute fa fa-sm fa-cog fa-spin text-gray-500'/>
      </div>
      <h5 className='mb-0 ms-2 text-white'>{title ? title : 'Loading. . .'}</h5>
      {/* {props.title ? <h3> &nbsp; {props.title}</h3> : <h3> &nbsp; Loading . . .</h3>} */}
    </div>
  );
};

export default PageLoading;
