import React from "react";
import { deleteBeritaTerkini, deletePengumuman, deleteSurveyMhs } from "../../../api";
import { saAlert, saConfirm } from "../../../helpers";
import SmallButton from "../../../components/forms/SmallButton";

const SMDelete = ({ surveyMhs, fetchdata }) => {
  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus survey ini ?").then(async (res) => {
      if (res.isConfirmed) {
        const res = await deleteSurveyMhs(surveyMhs.id);
        if (res.data && res.data.status) {
          fetchdata();
          saAlert(res.data.status, res.data.msg);
        }
      }
    });
  };

  return (
    <SmallButton onClick={handleDelete} type='delete'/>
  );
};

export default SMDelete;