import React from "react";
import { namaBulan } from "../../helpers";

// const PrintTtd = ({
//   tanggal,
//   cabang,
//   nama,
//   jabatan,
//   footer,
//   menyetujui = false,
// }) => {
//   return (
//     <div className="w-100 text-center">
//       <>
//         {/* <div className="row justify-content-end mb-5"> */}
//         {/* </div> */}
//       </>
//       <span style={{ textTransform: "capitalize" }}>
//         {cabang ? `${cabang},` : ""}
//       </span>{" "}
//       {tanggal ? (
//         <>
//           {tanggal === "default" ? (
//             <>
//               {new Date().getDate()} {namaBulan[new Date().getMonth()]}{" "}
//               {new Date().getFullYear()}
//             </>
//           ) : (
//             tanggal
//           )}
//         </>
//       ) : (
//         <></>
//       )}{" "}
//       <br />
//       <span style={{ textTransform: "capitalize" }}>{jabatan}</span>{" "}
//       {menyetujui ? "Menyetujui," : "Alfa Prima "}
//       {cabang && `${cabang.charAt(0).toUpperCase()}${cabang.slice(1)}`} <br />
//       <br />
//       <br />
//       <br />
//       <br />
//       <b>
//         <u>{nama}</u>
//       </b>
//       <br />
//       {footer}
//     </div>
//   );
// };

const PrintTtd = ({ tanggal, cabang, nama, jabatan, menyetujui = false }) => {
  return (
    <div className="w-100 text-center">
      <>
        {/* <div className="row justify-content-end mb-5"> */}
        {/* </div> */}
      </>
      <b>
        <span style={{ textTransform: "capitalize" }}>
          {cabang ? `${cabang},` : ""}
        </span>{" "}
        {tanggal ? (
          <>
            {tanggal === "default" ? (
              <>
                {new Date().getDate()} {namaBulan[new Date().getMonth()]}{" "}
                {new Date().getFullYear()}
              </>
            ) : (
              tanggal
            )}
          </>
        ) : (
          <></>
        )}{" "}
        <br />
        {menyetujui ? (
          <span>
            <br />
            Menyetujui,
          </span>
        ) : (
          <span>
            Lembaga Pendidikan Manajemen
            <br />
            dan Informatika Alfa Prima{" "}
          </span>
        )}
      </b>
      <br />
      <br />
      <br />
      <br />
      <b>
        <u>{nama}</u>
      </b>
      <br />
      <span style={{ textTransform: "capitalize" }}>
        {jabatan} {!menyetujui && 'Alfa Prima'}
      </span>{" "}
      {cabang && `${cabang.charAt(0).toUpperCase()}${cabang.slice(1)}`}
      {menyetujui && "Dosen"}
      {/* {footer} */}
    </div>
  );
};

export default PrintTtd;
