import React from "react";
import { deleteJenisSurveyMhs } from "../../../api";
import { saAlert, saConfirm } from "../../../helpers";
import SmallButton from "../../../components/forms/SmallButton";

const SMJenisDelete = ({ jenisSurvey, fetchdata }) => {
  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus survey ini ?").then(async (res) => {
      if (res.isConfirmed) {
        const res = await deleteJenisSurveyMhs(jenisSurvey.id);
        if (res.data && res.data.status) {
          fetchdata();
          saAlert(res.data.status, res.data.msg);
        }
      }
    });
  };

  return (
    <SmallButton onClick={handleDelete} margin="me-1" type="delete"/>
  );
};

export default SMJenisDelete;