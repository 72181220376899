import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../axios";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterBulan,
  defaultFilterTahun,
  FilterBulan,
  FilterCabang,
  FilterTahun,
} from "../../components/layouts/TopContent";
import LoadingPage from "../../components/pages/Loading";
import NoDataPage from "../../components/pages/NoData";
import { formatDate, formatTimeStamp, saAlert, saConfirm } from "../../helpers";
import { tokenState, userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import SmallButton from "../../components/forms/SmallButton";
import { deleteCuti } from "../../api";

const CutiHrd = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const user = useRecoilValue(userState);
  const [isLoaded, setIsLoaded] = useState();

  const [items, setItems] = useState();
  const [page] = useState("all");
  const [bulan, setBulan] = useState(defaultFilterBulan);
  const [tahun, setTahun] = useState(defaultFilterTahun);
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    setTitle("Semua Data Cuti");
    try {
      const res = await axios.get(
        "/api/cuti" +
          "?page=" +
          page +
          "&bulan=" +
          bulan +
          "&tahun=" +
          tahun +
          "&cabang=" +
          cabang,
        { headers: { Authorization: "Bearer " + token } }
      );
      if (res.data) {
        setItems(res.data.cuti);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [setTitle, page, bulan, tahun, cabang, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoaded && user.cabang === "pusat") {
    return (
      <>
        <div className="row mb-2">
          <div className="col-md">
            <FilterCabang pusat={true} onChange={(res) => setCabang(res)} />
          </div>
          <div className="col-md">
            <FilterBulan all={true} onChange={(res) => setBulan(res)} />
          </div>
          <div className="col-md">
            <FilterTahun onChange={(res) => setTahun(res)} />
          </div>
        </div>

        {items.length >= 1 ? (
          <TableNew>
            <Thead>
              <Tr className="text-center text-nowrap">
                <Th rowSpan={2} className='align-middle'>No</Th>
                <Th rowSpan={2} className='align-middle'>Cabang</Th>
                <Th rowSpan={2} className='align-middle'>NIP</Th>
                <Th rowSpan={2} className='align-middle'>Nama</Th>
                <Th rowSpan={2} className='align-middle'>Dari</Th>
                <Th rowSpan={2} className='align-middle'>Sampai</Th>
                <Th colSpan={3} className='align-middle'>Jumlah</Th>
                <Th colSpan={4} className='align-middle'>Cuti Digunakan</Th>
                <Th rowSpan={2} className='align-middle'>Alamat</Th>
                <Th rowSpan={2} className='align-middle'>No HP</Th>
                <Th rowSpan={2} className='align-middle'>Keperluan</Th>
                <Th rowSpan={2} className='align-middle'>Status</Th>
                <Th rowSpan={2} className='align-middle'>Approval</Th>
                <Th rowSpan={2} className='align-middle'>Created at</Th>
                <Th rowSpan={2} className='align-middle'>Updated at</Th>
                {(user.jabatan === "IT Programmer" || user.jabatan === "Bagian SDM") &&
                  <Th rowSpan={2} className='align-middle'><i className="fa fa-fw fa-cog"/></Th>
                }
              </Tr>
              <Tr>
                <Th>Hari</Th>
                <Th>Bulan</Th>
                <Th>Tahun</Th>
                <Th>Tahunan</Th>
                <Th>Bersama</Th>
                <Th>Bersalin</Th>
                <Th className="text-nowrap">Alasan Penting</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map((i, index) => {

                const jenisCuti = i.keperluan.split(":")[0].trim();

                return (
                  <Tr key={index}>
                    <Td className="text-center align-middle">{1 + index}</Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.cabang}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.nip}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.nama}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatDate(i.dari)}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatDate(i.sampai)}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.jumlah && i.jumlah.split(" | ")[0].split(" ")[0]}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.jumlah && i.jumlah.split(" | ")[1].split(" ")[0]}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.jumlah && i.jumlah.split(" | ")[2].split(" ")[0]}
                    </Td>
                    <Td className="text-center align-middle">
                      {i.pegawai &&
                        i.pegawai.cuti_count &&
                        i.pegawai.cuti_count.map((cc) => cc.cuti_tahunan)}
                    </Td>
                    <Td className="text-center align-middle">
                      {i.pegawai &&
                        i.pegawai.cuti_count &&
                        i.pegawai.cuti_count.map((cc) => cc.cuti_bersama)}
                    </Td>
                    <Td className="text-center align-middle">
                      {i.pegawai &&
                        i.pegawai.cuti_count &&
                        i.pegawai.cuti_count.map((cc) => cc.cuti_bersalin)}
                    </Td>
                    <Td className="text-center align-middle">
                      {i.pegawai &&
                        i.pegawai.cuti_count &&
                        i.pegawai.cuti_count.map(
                          (cc) => cc.cuti_alasan_penting
                        )}
                    </Td>
                    <Td className="align-middle">{i.alamat}</Td>
                    <Td className="text-center align-middle">{i.no_hp}</Td>
                    <Td className="align-middle">
                      {jenisCuti === "Cuti Tahunan" &&
                        <p className="mb-0 text-center fw-medium text-success">{i.keperluan}</p>
                      }
                      {jenisCuti === "Cuti Bersama" &&
                        <p className="mb-0 text-center fw-medium text-primary-2">{i.keperluan}</p>
                      }
                      {jenisCuti === "Cuti Bersalin" &&
                        <p className="mb-0 text-center fw-medium text-purple">{i.keperluan}</p>
                      }
                      {jenisCuti === "Cuti Alasan Penting" &&
                        <span className="fw-medium text-warning-2">{i.keperluan.split(":")[0]} <span className="fw-normal text-black">: {i.keperluan.split(":")[1]}</span></span>
                      }
                    </Td>
                    <Td className="text-center align-middle">
                      {parseInt(i.status) === 1 && (
                        <span className="text-success">Approved</span>
                      )}
                      {parseInt(i.status) === 2 && (
                        <span className="text-danger">Rejected</span>
                      )}
                      {!parseInt(i.status) && (
                        <span className="text-warning">Waiting</span>
                      )}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.atasan && i.atasan.nama}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatTimeStamp(i.created_at)}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatTimeStamp(i.updated_at)}
                    </Td>
                    {(user.jabatan === "IT Programmer" || user.jabatan === "Bagian SDM") &&
                      <Td className="text-nowrap text-center align-middle">
                        <DeleteCuti id={i.id} onFetchData={fetchData}/>
                      </Td>
                    }
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <NoDataPage />
        )}
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

const DeleteCuti = (props) => {
  const {id, onFetchData} = props;
  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus data cuti ?").then((res) => {
      if (res.isConfirmed) {
        deleteCuti(id).then((res) => {
          if (res.status === 200) {
            onFetchData();
            saAlert("success", "Berhasil hapus cuti !");
          }
        });
      }
    });
  };
  return (
    <SmallButton onClick={handleDelete} type="delete" text="Hapus"/>
  );
};

export default CutiHrd;
