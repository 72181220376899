import React, { useState, useRef, createContext, useContext } from "react";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import FormInput from "../../components/forms/Input";
import { postKpi, patchKpi, deleteKpi } from "../../api";
import { getCurrency, saAlert, saConfirm } from "../../helpers";
import FormSelect from "../../components/forms/Select";
import FormTextarea from "../../components/forms/Textarea";
import SmallButton from "../../components/forms/SmallButton";
import { useRecoilValue } from "recoil";
import { userState } from "../../storage/auth";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const DetailContext = createContext();

const JabatanAddKpi = ({
  jabatan,
  aspekBsc,
  onFetchData,
  showOnly = false,
}) => {
  const user = useRecoilValue(userState);

  const [modal, setModal] = useState(false);
  const [addBtn, setAddBtn] = useState(false);
  const [editBtn, setEditBtn] = useState(false);

  const [kpiToEdit, setKpiToEdit] = useState({});

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleDeleteKpi = (id) => {
    saConfirm("warning", "Yakin hapus KPI ?").then(async (res) => {
      if (res.isConfirmed) {
        const res = await deleteKpi(id);
        console.log(res.data);
        if (res.data && res.data.status) {
          onFetchData(false);
          saAlert(res.data.status, res.data.msg);
        }
      }
    });
  };

  return (
    <DetailContext.Provider
      value={{
        jabatan,
        aspekBsc,
        addBtn,
        setAddBtn,
        editBtn,
        setEditBtn,
        onFetchData,
        kpiToEdit,
        setKpiToEdit,
        showOnly,
      }}
    >
      {window.location.pathname === '/kpi-show' ?
        <button onClick={handleOpen} className="btn btn-sm btn-success-2 w-100 rounded-3"><i className="fa fa-fw fa-eye"/> Lihat detail KPI</button>
        :
        <SmallButton margin="me-1" type="show" onClick={handleOpen} text="Detail"/>
      }
      <LayoutModal title="Detail KPi" modal={modal} onClose={handleClose}>
        <div className="bg-gradient-primary border-bottom-warning justify-content-center shadow-lg rounded-4 p-3 mb-4">
          <div className="text-center">
            <h6 className="mb-1 text-gray-500">Jabatan :</h6>
            <h5 className="text-white">{jabatan.nama}</h5>
          </div>
        </div>

        <div className="row">
          {/* Start Form */}
          <div className="col-lg-4 mb-3">
            {addBtn && <AddKpi />}
            {editBtn && <EditKpi />}
          </div>
          {/* End Form */}

          <div className={`${!addBtn && !editBtn ? "col-lg-12" : "col-lg-8"} mb-3`}>
            <div className="row">
              <div className="col-9">
                <h4 className="fw-semibold text-black">List KPI</h4>
              </div>
              {!showOnly && (
                <div className="col-3">
                  {!addBtn && (
                    <button
                      className="btn btn-sm w-100 btn-warning-2 fw-semibold shadow rounded-3"
                      onClick={() => {
                        setAddBtn(true);
                        setEditBtn(false);
                      }}
                    >
                      <i className="fa fa-fw fa-plus"></i> Tambah KPI
                    </button>
                  )}
                </div>
              )}
            </div>
            <TableNew>
              <Thead>
                <Tr className="text-center text-nowrap">
                  <Th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</Th>
                  <Th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Type KPI</Th>
                  <Th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Judul</Th>
                  {showOnly && (
                    <>
                      <Th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Self Aassesment</Th>
                      <Th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Capaian</Th>
                    </>
                  )}
                  <Th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Target</Th>
                  <Th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Satuan</Th>
                  <Th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Bobot</Th>
                  <Th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Aspek Bsc</Th>
                  <Th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                    <i className="fa fa-fw fa-cog"></i>
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {jabatan.kpi &&
                  jabatan.kpi.length > 0 &&
                  jabatan.kpi.map((kpi, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="py-1 border-silver text-center">{index + 1}</Td>
                        <Td className="py-1 border-silver text-center">{kpi.type_kpi}</Td>
                        <Td className="py-1 border-silver">{kpi.nama}</Td>
                        {showOnly && (
                          <>
                            <Td className="py-1 border-silver text-center">
                              {jabatan.kpi_audit &&
                                jabatan.kpi_audit
                                  .filter(
                                    (ka) =>
                                      parseInt(ka.kpi_id) ===
                                        parseInt(kpi.id) &&
                                      parseInt(ka.pegawai_id) ===
                                        parseInt(user.id) &&
                                      parseInt(ka.jabatan_id) ===
                                        parseInt(jabatan.id)
                                  )
                                  .map((ka) => {
                                    if (ka.satuan === "Rupiah") {
                                      return getCurrency(
                                        ka.self_assesment || "0"
                                      );
                                    } else {
                                      return ka.self_assesment || "0";
                                    }
                                  })}
                            </Td>
                            <Td className="py-1 border-silver text-center">
                              {jabatan.kpi_audit &&
                                jabatan.kpi_audit
                                  .filter(
                                    (ka) =>
                                      parseInt(ka.kpi_id) ===
                                        parseInt(kpi.id) &&
                                      parseInt(ka.pegawai_id) ===
                                        parseInt(user.id) &&
                                      parseInt(ka.jabatan_id) ===
                                        parseInt(jabatan.id)
                                  )
                                  .map((ka) => {
                                    if (ka.satuan === "Rupiah") {
                                      return getCurrency(ka.capaian || "0");
                                    } else {
                                      return ka.capaian || "0";
                                    }
                                  })}
                            </Td>
                          </>
                        )}
                        <Td className="py-1 border-silver text-center">
                          {kpi.satuan === "Rupiah"
                            ? getCurrency(kpi.target || 0)
                            : kpi.target || 0}
                        </Td>
                        <Td className="py-1 border-silver text-center">{kpi.satuan}</Td>
                        <Td className="py-1 border-silver text-center">{kpi.bobot}%</Td>
                        <Td className="py-1 border-silver text-center">
                          {kpi.aspek_bsc && kpi.aspek_bsc.kode}
                        </Td>
                        <Td className="py-1 border-silver text-nowrap text-center">
                          <SmallButton 
                            margin="me-1"
                            type="edit" 
                            onClick={() => {
                              setKpiToEdit(kpi);
                              setEditBtn(false);
                              setAddBtn(false);
                              setTimeout(() => {
                                setEditBtn(true);
                              }, 500);
                            }}
                          />
                          {!showOnly && (
                            <SmallButton type="delete" onClick={() => handleDeleteKpi(kpi.id)}/>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
              </Tbody>
            </TableNew>
          </div>
        </div>
      </LayoutModal>
    </DetailContext.Provider>
  );
};

const AddKpi = () => {
  const { jabatan, aspekBsc, setAddBtn, onFetchData } =
    useContext(DetailContext);
  const tagForm = useRef(null);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const resetForm = () => {
    setForm({});
    setErrors({});
    onFetchData(false);
    tagForm.current && tagForm.current.reset();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      const res = await postKpi({
        jabatan_id: jabatan.id,
        ...form,
      });
      if (res.data && res.data.status) {
        resetForm();
        saAlert(res.data.status, res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <form onSubmit={handleSubmit} ref={tagForm} className="bg-gradient-primary p-3 rounded-3">
      <h5 className="text-center text-white">Tambah KPI</h5>
      <FormSelect
        labelClassName="text-info"
        label="Type KPI"
        name="type_kpi"
        onChange={handleChange}
        error={errors.type_kpi}
      >
        <option value="">Pilih</option>
        <option value="Exact">Exact</option>
        <option value="Proxy">Proxy</option>
        <option value="Activity">Activity</option>
      </FormSelect>
      <FormInput
        labelClassName="text-info"
        label="Judul"
        name="nama"
        onChange={handleChange}
        error={errors.nama}
      />
      <FormInput
        labelClassName="text-info"
        label="Target"
        name="target"
        onChange={handleChange}
        error={errors.target}
      />
      <FormSelect
        labelClassName="text-info"
        label="Satuan"
        name="satuan"
        onChange={handleChange}
        error={errors.satuan}
      >
        <option value="">Pilih</option>
        <option value="Persentase">Persentase</option>
        <option value="Jumlah">Jumlah</option>
        <option value="Rupiah">Rupiah</option>
      </FormSelect>
      <FormInput
        labelClassName="text-info"
        label="Bobot"
        name="bobot"
        onChange={handleChange}
        error={errors.bobot}
      />
      <FormSelect
        labelClassName="text-info"
        label="Aspek BSC"
        name="aspek_bsc_id"
        onChange={handleChange}
        error={errors.aspek_bsc_id}
      >
        <option value="">Pilih</option>
        {aspekBsc.length > 0 &&
          aspekBsc.map((ab, index) => {
            return (
              <React.Fragment key={index}>
                <option value={ab.id}>{ab.kode}</option>
              </React.Fragment>
            );
          })}
      </FormSelect>
      <FormTextarea
        labelClassName="text-info"
        label="Keterangan"
        name="keterangan"
        onChange={handleChange}
        error={errors.keterangan}
        row={10}
      />
      <NewSubmitButton 
        widthFull
        onClose={() => {
          resetForm();
          setAddBtn(false);
        }}
      />
    </form>
  );
};

const EditKpi = () => {
  const { jabatan, aspekBsc, setEditBtn, kpiToEdit, onFetchData, showOnly } =
    useContext(DetailContext);
  const tagForm = useRef(null);

  const user = useRecoilValue(userState);

  const [form, setForm] = useState(kpiToEdit);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const resetForm = () => {
    setForm({});
    setErrors({});
    onFetchData(false);
    tagForm.current && tagForm.current.reset();
    setEditBtn(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      const res = await patchKpi(kpiToEdit.id, {
        jabatan_id: jabatan.id,
        aspek_bsc_id: form.aspek_bsc_id,
        type_kpi: form.type_kpi,
        nama: form.nama,
        keterangan: form.keterangan,
        target: form.target,
        satuan: form.satuan,
        bobot: form.bobot,
        self_assesment: showOnly ? form.self_assesment : false,
        is_self_assesment: showOnly ? 1 : 0,
      });
      if (res.data && res.data.status) {
        resetForm();
        saAlert(res.data.status, res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <form onSubmit={handleSubmit} ref={tagForm} className="bg-gradient-primary p-3 rounded-3">
      <h5 className="text-center text-white">Edit KPI</h5>
      {showOnly ? (
        <>
          <FormInput labelClassName="text-info" label="Judul" name="nama" value={form.nama} readOnly={1} />
          <FormInput
            labelClassName="text-info"
            label="Self Assesment <span class='fw-normal text-sm text-warning'>(Hanya angka, tanpa simbol atau karakter lainnya)</span>"
            name="self_assesment"
            value={jabatan.kpi_audit
              .filter(
                (ka) =>
                  parseInt(ka.kpi_id) === parseInt(kpiToEdit.id) &&
                  parseInt(ka.pegawai_id) === parseInt(user.id) &&
                  parseInt(ka.jabatan_id) === parseInt(jabatan.id)
              )
              .map((ka) => ka.self_assesment)}
            onChange={handleChange}
            error={errors.self_assesment}
          />
          <FormTextarea
            labelClassName="text-info"
            label="Keterangan"
            name="keterangan"
            value={form.keterangan}
            readOnly={1}
            row={10}
          />
        </>
      ) : (
        <>
          <FormSelect
            labelClassName="text-info"
            label="Type KPI"
            name="type_kpi"
            value={form.type_kpi}
            onChange={handleChange}
            error={errors.type_kpi}
          >
            <option value="">Pilih</option>
            <option value="Exact">Exact</option>
            <option value="Proxy">Proxy</option>
            <option value="Activity">Activity</option>
          </FormSelect>
          <FormInput
            labelClassName="text-info"
            label="Judul"
            name="nama"
            value={form.nama}
            onChange={handleChange}
            error={errors.nama}
          />
          <FormInput
            labelClassName="text-info"
            label="Target"
            name="target"
            value={form.target}
            onChange={handleChange}
            error={errors.target}
          />
          <FormSelect
            labelClassName="text-info"
            label="Satuan"
            name="satuan"
            value={form.satuan}
            onChange={handleChange}
            error={errors.satuan}
          >
            <option value="">Pilih</option>
            <option value="Persentase">Persentase</option>
            <option value="Jumlah">Jumlah</option>
            <option value="Rupiah">Rupiah</option>
          </FormSelect>
          <FormInput
            labelClassName="text-info"
            label="Bobot"
            name="bobot"
            value={form.bobot}
            onChange={handleChange}
            error={errors.bobot}
          />
          <FormSelect
            labelClassName="text-info"
            label="Aspek BSC"
            name="aspek_bsc_id"
            value={form.aspek_bsc_id ?? ""}
            onChange={handleChange}
            error={errors.aspek_bsc_id}
          >
            <option value="">Pilih</option>
            {aspekBsc.length > 0 &&
              aspekBsc.map((ab, index) => {
                return (
                  <React.Fragment key={index}>
                    <option value={ab.id}>{ab.kode}</option>
                  </React.Fragment>
                );
              })}
          </FormSelect>
          <FormTextarea
            labelClassName="text-info"
            label="Keterangan"
            name="keterangan"
            value={form.keterangan}
            onChange={handleChange}
            error={errors.keterangan}
            row={10}
          />
        </>
      )}
      <NewSubmitButton
        widthFull
        onClose={() => {
          resetForm();
          setEditBtn(false);
        }}
      />
    </form>
  );
};

export default JabatanAddKpi;
