import React, { useState, useRef } from "react";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import LayoutModal from "../../components/layouts/Modal";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import FormSelect from "../../components/forms/Select";
import { postAbsensiPegawai } from "../../api";
import { saAlert } from "../../helpers";

const AbsensiCreate = ({ onFetchData }) => {
  const tagForm = useRef(null);

  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const resetForm = () => {
    tagForm.current && tagForm.current.reset();
    setForm({});
    setErrors({});
  };

  const handleClose = () => {
    onFetchData();
    resetForm();
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await postAbsensiPegawai(form);
      if (res.data && res.data.status === "success") {
        onFetchData();
        handleClose();
        saAlert("success", "Berhasil melakukan absensi pegawai.");
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <div>
      <LayoutAddBtn onClick={() => setModal(true)} add="Buat Absensi Pegawai" />
      <LayoutModal
        size="md"
        title="Buat Absensi Pegawai"
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
              <FormSelect
                label="jenis"
                name="jenis"
                onChange={handleChange}
                error={errors.jenis}
              >
                <option value="">Pilih</option>
                <option value="1">Datang</option>
                <option value="2">Pulang</option>
                <option value="3">Datang & Pulang</option>
              </FormSelect>
              <FormInput
                label="tanggal"
                type="date"
                name="tanggal"
                onChange={handleChange}
                error={errors.tanggal}
              />
            {form.jenis && parseInt(form.jenis) !== 2 && (
                <FormInput
                  label="jam datang"
                  type="time"
                  name="jam_datang"
                  onChange={handleChange}
                  error={errors.jam_datang}
                />
            )}
            {form.jenis && parseInt(form.jenis) !== 1 && (
                <FormInput
                  label="jam pulang"
                  type="time"
                  name="jam_pulang"
                  onChange={handleChange}
                  error={errors.jam_pulang}
                />
            )}
              <FormTextarea
                label="keterangan"
                name="keterangan"
                onChange={handleChange}
                error={errors.keterangan}
              />
            <div className="btn-group w-100">
              <button type="submit" className="btn btn-sm btn-success-2">
                Simpan
              </button>
              <button
                type="button"
                className="btn btn-sm btn-dark border-0"
                onClick={handleClose}
              >
                <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
              </button>
            </div>
        </form>
      </LayoutModal>
    </div>
  );
};

export default AbsensiCreate;
