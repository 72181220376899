import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { fetchDosen } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { FilterBulan } from "../../components/layouts/TopContent";
import PageNoData from "../../components/pages/NoData";
import { getCurrency } from "../../helpers";
import { titleState } from "../../storage/pages";

const DosenHonor = () => {
  const setTitle = useSetRecoilState(titleState);

  useEffect(() => setTitle("Data Honor Dosen"), [setTitle]);

  const [bulan, setBulan] = useState("");

  useEffect(() => {
    let d = new Date();
    let month = d.getMonth() + 1;
    let date = d.getDate();

    if (date > 25) {
      let m = month + 1;
      setBulan(m === 13 ? 1 : m);
    } else {
      setBulan(month);
    }
  }, [setBulan]);

  const [items, setItems] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchDosen("all", `honor_dosen=true&bulan=${bulan}`);
      setItems(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [bulan]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="row mb-3">
        <div className="col-md-6">
          <FilterBulan value={bulan} onChange={(res) => setBulan(res)} />
        </div>
      </div>
      {items && items.length > 0 ? (
        <>
          <div className="row">
            <div className="col-4">
              <LayoutTable>
                <LayoutThead>
                  <th className="text-nowrap">No</th>
                  <th colSpan="2" className="text-nowrap">
                    Dosen
                  </th>
                </LayoutThead>
                <tbody>
                  {items.map((i, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-nowrap text-center align-middle align-middle"
                          >
                            {1 + index}
                          </td>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-nowrap align-middle align-middle"
                          >
                            {i.nama}
                          </td>
                          {i.pengajaran.length > 0 &&
                            i.pengajaran.map((i, jindex) => {
                              if (jindex === 0) {
                                return (
                                  <React.Fragment key={i.id}>
                                    <td className="text-center">
                                      {jindex + 1}
                                    </td>
                                  </React.Fragment>
                                );
                              }
                              return null;
                            })}
                        </tr>
                        {i.pengajaran.length > 0 &&
                          i.pengajaran.map((i, jindex) => {
                            if (jindex > 0) {
                              return (
                                <tr key={i.id} className="text-center">
                                  <td>{jindex + 1}</td>
                                </tr>
                              );
                            }
                            return null;
                          })}
                        <tr className="bg-success text-white">
                          <td colSpan="3" className="text-center">
                            Total
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
            <div className="col-8">
              <LayoutTable>
                <LayoutThead>
                  <th className="text-nowrap">Nama</th>
                  <th className="text-nowrap">Kursus</th>
                  <th className="text-nowrap">LPK</th>
                  <th className="text-nowrap">LTA</th>
                  <th className="text-nowrap">TA</th>
                  <th className="text-nowrap">Menguji</th>
                  <th className="text-nowrap">Mata Kuliah</th>
                  <th className="text-nowrap">Modul</th>
                  <th className="text-nowrap">SAP Kelas</th>
                  <th className="text-nowrap">Soal</th>
                  <th className="text-nowrap">Korelasi</th>
                  <th className="text-nowrap">Remidi Susulan</th>
                  <th className="text-nowrap">Pertemuan</th>
                  <th className="text-nowrap">Tanggal</th>
                  <th className="text-nowrap">SKS</th>
                  <th className="text-nowrap">Mengajar</th>
                  <th className="text-nowrap">Menit</th>
                  <th className="text-nowrap">Potongan</th>
                  <th className="text-nowrap">Honor</th>
                </LayoutThead>
                <tbody>
                  {items.map((i, index) => {
                    return (
                      <>
                        <tr key={index}>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-nowrap align-middle"
                          >
                            {i.nama}
                          </td>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-nowrap align-middle"
                          >
                            {i.honor}
                          </td>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-nowrap align-middle"
                          >
                            {i.honor_kursus}
                          </td>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-nowrap align-middle"
                          >
                            {i.honor_membimbing_lpk}
                          </td>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-nowrap align-middle"
                          >
                            {i.honor_membimbing_lta}
                          </td>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-nowrap align-middle"
                          >
                            {i.honor_membimbing_ta}
                          </td>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-nowrap align-middle"
                          >
                            {i.honor_menguji}
                          </td>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-center align-middle"
                          >
                            -
                          </td>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-center align-middle"
                          >
                            -
                          </td>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-center align-middle"
                          >
                            -
                          </td>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-center align-middle"
                          >
                            -
                          </td>
                          <td
                            rowSpan={i.pengajaran.length}
                            className="text-center align-middle"
                          >
                            -
                          </td>
                          {i.pengajaran.length > 0 &&
                            i.pengajaran.map((p, pindex) => {
                              if (pindex === 0) {
                                return (
                                  <React.Fragment key={pindex}>
                                    <td className="text-nowrap">
                                      Ke - {p.pertemuan}
                                    </td>
                                    <td className="text-nowrap">
                                      {p.tanggal}/{p.dari}-{p.sampai}
                                    </td>
                                    <td className="text-center">
                                      {p.kelas &&
                                        p.kelas.matkul &&
                                        p.kelas.matkul.sks}
                                    </td>
                                    <td className="text-nowrap">
                                      {p.kelas &&
                                        p.kelas.matkul &&
                                        p.kelas.matkul.nama}{" "}
                                      / {p.kelas && p.kelas.kode}
                                    </td>
                                    <td className="text-center">
                                      {p.kelas &&
                                        p.kelas.matkul &&
                                        parseInt(p.kelas.matkul.sks) * 52.5}
                                    </td>
                                    <td className="text-center">-</td>
                                    <td className="text-center text-nowrap">
                                      {i.honor ? (
                                        getCurrency(i.honor)
                                      ) : (
                                        <span className="text-danger">
                                          Belum ditetapkan
                                        </span>
                                      )}
                                    </td>
                                  </React.Fragment>
                                );
                              }
                              return null;
                            })}
                        </tr>
                        {i.pengajaran.length > 0 &&
                          i.pengajaran.map((p, pindex) => {
                            if (pindex > 0) {
                              return (
                                <tr key={pindex}>
                                  <td className="text-nowrap">
                                    Ke - {p.pertemuan}
                                  </td>
                                  <td className="text-nowrap">
                                    {p.tanggal}/{p.dari}-{p.sampai}
                                  </td>
                                  <td className="text-center">
                                    {p.kelas &&
                                      p.kelas.matkul &&
                                      p.kelas.matkul.sks}
                                  </td>
                                  <td className="text-nowrap">
                                    {p.kelas &&
                                      p.kelas.matkul &&
                                      p.kelas.matkul.nama}{" "}
                                    / {p.kelas && p.kelas.kode}
                                  </td>
                                  <td className="text-center">
                                    {p.kelas &&
                                      p.kelas.matkul &&
                                      parseInt(p.kelas.matkul.sks) * 52.5}
                                  </td>
                                  <td className="text-center">-</td>
                                  <td className="text-center text-nowrap">
                                    {i.honor ? (
                                      getCurrency(i.honor)
                                    ) : (
                                      <span className="text-danger">
                                        Belum ditetapkan
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              );
                            }
                            return null;
                          })}
                        <tr className="bg-success text-white">
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td colSpan="6"></td>
                          <td>{getCurrency(i.honor) * i.pengajaran.length}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
          </div>
        </>
      ) : (
        <PageNoData />
      )}
    </>
  );
};

export default DosenHonor;
