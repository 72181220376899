import React, { useRef, useState } from "react";
import { postPerusahaan } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import { saAlert } from "../../helpers";

const PerusahaanCreate = (props) => {
  const { onFetchData } = props;

  const tagForm = useRef();
  const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [form, setForm] = useState({});

  const handleClose = () => {
    tagForm.current.reset();
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    form.nama && formData.append("nama", form.nama);
    form.status && formData.append("status", form.status);
    form.mou_dari && formData.append("mou_dari", form.mou_dari);
    form.mou_sampai && formData.append("mou_sampai", form.mou_sampai);
    form.nomor_mou && formData.append("nomor_mou", form.nomor_mou);
    form.alamat && formData.append("alamat", form.alamat);
    form.no_telf && formData.append("no_telf", form.no_telf);
    form.no_hp && formData.append("no_hp", form.no_hp);
    form.pic && formData.append("pic", form.pic);
    form.ultah && formData.append("ultah", form.ultah);
    form.email && formData.append("email", form.email);
    form.bukti_mou && formData.append("bukti_mou", form.bukti_mou);
    form.foto_mou && formData.append("foto_mou", form.foto_mou);

    try {
      const res = await postPerusahaan(formData);
      if (res.data.status === "success") {
        onFetchData();
        tagForm.current.reset();
        setForm({});
        setErrors({});
        setModal(false);
        saAlert("success", "Berhasil tambah data perusahaan !");
      }
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  return (
    <>
      <LayoutAddBtn add="Tambah Perushaan" onClick={() => setModal(true)} />
      <LayoutModal
        title="Tambah Data Perusahaan"
        modal={modal}
        onClose={handleClose}
      >
        <form ref={tagForm} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <FormInput
                label="Nama"
                name="nama"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="col-md-6">
              <FormSelect
                label="Status MoU"
                name="status"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              >
                <option value="">Pilih</option>
                <option value="1">Baru</option>
                <option value="2">Perpanjang</option>
                <option value="3">Belum MoU</option>
                <option value="4">Berita Acara</option>
              </FormSelect>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInput
                label="MoU Dari"
                type="date"
                name="mou_dari"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="MoU Sampai"
                type="date"
                name="mou_sampai"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInput
                label="Nomor MoU"
                name="nomor_mou"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
              />
            </div>
            {/* <div className="col-md-6">
              <FormInput
                label="MoU ke -"
                type="number"
                name="mou_ke"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
              />
            </div> */}
          </div>
          <FormTextarea
            label="Alamat"
            name="alamat"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
          />
          <div className="row">
            <div className="col-md-6">
              <FormInput
                label="No Telfon"
                name="no_telf"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="No HP"
                name="no_hp"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInput
                label="PIC"
                name="pic"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Ulang Tahun Perusahaan"
                type="date"
                name="ultah"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInput
                label="Email"
                name="email"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Bukti MoU"
                type="file"
                name="bukti_mou"
                error={errors.bukti_mou}
                accept="application/pdf"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.files[0] };
                  })
                }
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Foto MoU"
                type="file"
                name="foto_mou"
                error={errors.foto_mou}
                accept=".jpg, .jpeg, .png"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.files[0] };
                  })
                }
              />
            </div>
            {/* <div className="col-md-6">
              <FormInput
                label="Kuota"
                name="kuota"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div> */}
          </div>
          <div className="row mt-3">
            <NewSubmitButton onClose={handleClose}/>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default PerusahaanCreate;
