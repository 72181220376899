import React, { useEffect, useState, useCallback, createContext } from "react";
import { useSetRecoilState } from "recoil";
import { titleState } from "../../storage/pages";
import { Switch, Route } from "react-router-dom";
import { fetchCeklistMonitoringList } from "./apis";
import CeklistMonitoringList from "./pages/CeklistMonitoringList";
import Create from "./pages/Create";
import Edit from "./pages/Edit";
import List from "./pages/List";

export const CeklistMonitoringListContext = createContext();

const CeklistMonitoringListRoutes = () => {
  const setTitle = useSetRecoilState(titleState);
  const mainUrl = "/ceklist-monitoring-list";

  const [page, setPage] = useState(1);

  const [ceklistMonitoringList, setCeklistMonitoringList] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchCeklistMonitoringList(page);
      if (res.data && res.data.ceklist_monitoring_list) {
        setCeklistMonitoringList(res.data.ceklist_monitoring_list);
        setTitle(res.data.title);
      }
    } catch (err) {
      console.error(err.response);
    }
  }, [setTitle, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <CeklistMonitoringListContext.Provider
      value={{ ceklistMonitoringList, setPage }}
    >
      <Switch>
        <Route exact path={mainUrl}>
          <CeklistMonitoringList mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
        <Route path={`${mainUrl}/create`}>
          <Create mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
        <Route path={`${mainUrl}/:id/edit`}>
          <Edit mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
        <Route path={`${mainUrl}/:id/list`}>
          <List mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
      </Switch>
    </CeklistMonitoringListContext.Provider>
  );
};

export default CeklistMonitoringListRoutes;
