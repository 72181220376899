import { useCallback, useEffect, useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { fetchRuangan, patchRuangan, postRuangan } from "../../api";
import FormInput from "../../components/forms/Input";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { saAlert } from "../../helpers";
import { titleState } from "../../storage/pages";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const RuanganAkademik = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState();
  const [items, setItems] = useState({});
  const [page, setPage] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchRuangan(page);
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page]);

  useEffect(() => {
    setTitle("Data Ruangan");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        <CreateRuangan onFetchData={fetchData} />
        {items.data.length >= 1 ? (
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>Cabang</Th>
                <Th>Kode Ruangan</Th>
                <Th>Nama Ruangan</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center">{items.from + index}</Td>
                    <Td className="text-center">{i.cabang}</Td>
                    <Td className="text-center">{i.kode}</Td>
                    <Td>{i.nama}</Td>
                    <Td className="text-center">
                      <EditRuangan ruangan={i} onFetchData={fetchData} />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

const CreateRuangan = (props) => {
  const tagForm = useRef();
  const [modal, setModal] = useState(false);
  const [ruangan, setRuangan] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    setRuangan({});
    if (tagForm) {
      tagForm.current.reset();
    }
    setRuangan({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postRuangan(ruangan)
      .then((res) => {
        if (res.data === "success") {
          props.onFetchData();
          handleClose();
          saAlert("success", "Berhasil menambahkan data ruangan !");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            setErrors(err.response.data.errors);
          }
        }
      });
  };

  return (
    <>
      <LayoutAddBtn onClick={handleOpen} add="Tambah Ruangan" />
      <LayoutModal
        size="sm"
        title="Tambah Data Ruangan"
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
          <FormInput
            label="Kode Ruangan"
            name="kode"
            onChange={(e) =>
              setRuangan((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.kode}
          />
          <FormInput
            label="Nama Ruangan"
            name="nama"
            onChange={(e) =>
              setRuangan((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.nama}
          />
          <div className="btn-group">
            <button className="btn btn-sm btn-success-2" type="submit">
              Tambah
            </button>
            <button
              className="btn btn-sm btn-dark border-0"
              type="button"
              onClick={handleClose}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

const EditRuangan = (props) => {
  const tagForm = useRef();
  const [modal, setModal] = useState(false);
  const [ruangan, setRuangan] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setRuangan(props.ruangan);
    setModal(true);
  };

  const handleClose = () => {
    setRuangan({});
    setErrors({});
    if (tagForm) {
      tagForm.current.reset();
    }
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    patchRuangan(ruangan.id, ruangan)
      .then((res) => {
        if (res.data === "success") {
          props.onFetchData();
          handleClose();
          saAlert("success", "Berhasil hubah data !");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            setErrors(err.response.data.errors);
          }
        }
      });
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-success-2 text-sm py-0 px-1"
        onClick={handleOpen}
      >
        <i className="fa fa-fw fa-sm fa-pen"/>
      </button>
      <LayoutModal
        size="sm"
        title="Tambah Data Ruangan"
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
          <FormInput
            label="Kode"
            name="kode"
            value={ruangan.kode}
            onChange={(e) =>
              setRuangan((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.kode}
          />
          <FormInput
            label="Nama"
            name="nama"
            value={ruangan.nama}
            onChange={(e) =>
              setRuangan((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.nama}
          />
          <div className="btn-group">
            <button className="btn btn-sm btn-success-2" type="submit">
              Simpan perubahaan
            </button>
            <button
              className="btn btn-sm btn-dark border-0"
              type="button"
              onClick={handleClose}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default RuanganAkademik;
