import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { showKelas } from "../../api";
import PageLoading from "../../components/pages/Loading";
import { getHari, getOrigin } from "../../helpers";
import PrintHeader from "../../components/print/Header";

const KelasJurnal = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [print, setPrint] = useState(false);
  const [items, setItems] = useState({});

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await showKelas(id);
        setItems(res.data);
        setIsLoaded(true);
        console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [id]);

  if (isLoaded) {
    return (
      <>
        <div className="container-fluid">
          <PrintHeader type="landscape" fontSize="11pt"/>
          <h2 className="text-center mb-5">JURNAL MENGAJAR DOSEN</h2>
          <div className="row mb-5">
            <div className="col">
              <table>
                <tbody>
                  <tr>
                    <td>Kode Kelas</td>
                    <td>{` : `}</td>
                    <td>{items.kode}</td>
                  </tr>
                  <tr>
                    <td>Hari / Jam (WITA)</td>
                    <td>{` : `}</td>
                    <td>{`${getHari(items.jamkul.hari)} / ${
                      items.jamkul.dari
                    } - ${items.jamkul.sampai}`}</td>
                  </tr>
                  <tr>
                    <td>Kelas/Ruangan</td>
                    <td>{` : `}</td>
                    <td>{items.ruangan.nama}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col justify-content-end d-flex">
              <table>
                <tbody>
                  <tr>
                    <td>Dosen</td>
                    <td>{` : `}</td>
                    <td>{items.dosen && items.dosen.nama}</td>
                  </tr>
                  <tr>
                    <td>Mata Kuliah</td>
                    <td>{` : `}</td>
                    <td>{items.matkul.nama}</td>
                  </tr>
                  <tr>
                    <td>Semester</td>
                    <td>{` : `}</td>
                    <td>{items.semester}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <table className="table table-bordered">
          <thead className="text-center">
            <tr>
              <th rowSpan="2" className="align-middle">
                No.
              </th>
              <th rowSpan="2" className="align-middle">
                Hari/Tgl.
              </th>
              <th rowSpan="2" className="align-middle">
                Jam Mulai/Selesai
              </th>
              <th rowSpan="2" className="align-middle">
                Ruangan
              </th>
              <th rowSpan="2" className="px-5 align-middle">
                <span className="px-5">Pembahasan</span>
              </th>
              <th rowSpan="2" className="px-5 align-middle">
                Tugas/ <br /> Pekerjaan Rumah
              </th>
              <th colSpan="3" className="align-middle">
                Paraf
              </th>
              <th rowSpan="2" className="align-middle">
                Jml. Mhs. Hadir
              </th>
              <th rowSpan="2" className="align-middle">
                <span className="">
                  Hambatan & Persiapan <br /> Pertemuan Berikutnya
                </span>
              </th>
            </tr>
            <tr>
              <th className="align-middle">Mhs.</th>
              <th className="align-middle">Dosen</th>
              <th className="align-middle">Prodi</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-center">1</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center">2</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center">3</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center">4</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center">5</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center">6</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center">UTS</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center">7</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center">8</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center">9</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center">10</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center">11</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center">12</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="text-center">UAS</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>
        <table className="table table-sm table-bordered">
          <thead className="text-center">{/*  */}</thead>
          <tbody>{/*  */}</tbody>
          <tfoot className="text-center">{/*  */}</tfoot>
        </table>
        <div className={`text-center mb-5 ${print && "d-none"}`}>
          <span
            type="button"
            className="text-info fa fa-print fa-lg mx-1"
            onClick={() => {
              setPrint(true);
              window.scrollTo(0, 0);
              setTimeout(() => {
                window.print();
                setPrint(false);
              }, 900);
            }}
          >
            Print
          </span>
        </div>
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default KelasJurnal;
