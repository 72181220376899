import React, { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { postSkDosen, showDosen } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import {
  getCurrency,
  getHari,
  saAlert,
  saConfirm,
  tahunAngkatan,
} from "../../helpers";
import { kacabState } from "../../storage/auth";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const CreateSkDosen = ({
  dosen = {},
  semesterAjaran,
  tahunAjaran,
  has_sk_dosen,
  onFetchData,
}) => {
  const kacab = useRecoilValue(kacabState);

  const [modal, setModal] = useState(false);
  const tagForm = useRef(null);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    const openNow = () => {
      fetchData();

      let yourDate = new Date();

      setForm({
        dosen_id: dosen.id,
        nomor: "",
        tanggal: yourDate.toISOString().split("T")[0],
        nama_kacab: kacab.nama,
        nip_kacab: kacab.nip,
        nama_dosen: dosen.nama,
        honor_dosen: dosen.honor,
        waktu_sks: 52.5,
        tanggal_mulai_perkuliahan: "",
        semester: semesterAjaran,
        tahun_ajaran: tahunAjaran,
      });

      setModal(true);
    };

    if (has_sk_dosen) {
      saConfirm(
        "warning",
        "Dosen ini sudah memiliki SK",
        "Apabila anda melanjutkan proses maka SK yang lama akan terhapus dan diganti dengan SK yang baru."
      ).then((res) => {
        if (res.isConfirmed) {
          openNow();
        }
      });
    } else {
      openNow();
    }
  };

  const handleClose = () => {
    setForm({});
    setErrors({});
    setKelas({});
    tagForm && tagForm.current && tagForm.current.reset();
    setModal(false);
  };

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const [kelas, setKelas] = useState({});

  const fetchData = async () => {
    try {
      const res = await showDosen(
        dosen.id,
        `kelas_dosen=1&semesterAjaran=${semesterAjaran}&tahunAjaran=${tahunAjaran}`
      );
      if (typeof res.data === "object") {
        if (res.data.kelas && res.data.kelas.length > 0) {
          setKelas(res.data.kelas);

          setForm((prevState) => {
            return { ...prevState, data_kelas: JSON.stringify(res.data.kelas) };
            // return { ...prevState, data_kelas: res.data.kelas };
          });

          // let dataJamkul = res.data.kelas.map((k) => k.jamkul);
          // let dataMatkul = res.data.kelas.map((k) => k.matkul);

          // setJamkul(dataJamkul);
          // setMatkul(dataMatkul);
        }
      }
    } catch (err) {
      console.error(err.response);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await postSkDosen(form);
      if (res.data && res.data.msg === "success") {
        onFetchData();
        handleClose();
        saAlert("success", "Berhasil buat SK Dosen !");
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  return (
    <>
      <button
        type="button"
        title="Simpan SK Dosen"
        className="btn btn-sm btn-success-2 text-sm py-0 px-1"
        onClick={handleOpen}
      >
        <i className="fa fa-fw fa-sm fa-save" />
      </button>
      <LayoutModal modal={modal} title={dosen.nama} onClose={handleClose}>
      <TextOnOffMhs>
        <h6 className="fw-semibold">Cek data secara keseluruhan sebelum <span className="text-warning">disimpan</span></h6>
        <p className="mb-0">Data kelas dapat diubah pada menu kelas, data honor dosen dapat diubah di menu dosen.</p>
      </TextOnOffMhs>
        <form onSubmit={handleSubmit} className="row" ref={tagForm}>
          <div className="col-6">
            <FormInput
              label="Nomor Surat <span class='text-danger-2'>* Isi terlebih dahulu.</span>"
              name="nomor"
              onChange={handleChange}
              error={errors.nomor}
            />
          </div>
          <div className="col-6">
            <FormInput
              label="Tanggal Surat"
              type="date"
              name="tanggal"
              value={form.tanggal}
              onChange={handleChange}
              error={errors.tanggal}
            />
          </div>
          <div className="col-6">
            <FormInput
              label="Nama Kacab"
              name="nama_kacab"
              value={form.nama_kacab}
              onChange={handleChange}
              error={errors.nama_kacab}
            />
          </div>
          <div className="col-6">
            <FormInput
              label="NIP Kacab"
              name="nip_kacab"
              value={form.nip_kacab}
              onChange={handleChange}
              error={errors.nip_kacab}
            />
          </div>
          <div className="col-6">
            <FormInput
              label="Nama Dosen"
              name="nama_dosen"
              value={form.nama_dosen}
              onChange={handleChange}
              error={errors.nama_dosen}
            />
          </div>
          <div className="col-6">
            <FormInput
              label="Tanggal Mulai Perkuliahan <span class='text-danger-2'>* Isi terlebih dahulu.</span>"
              type="date"
              name="tanggal_mulai_perkuliahan"
              onChange={handleChange}
              error={errors.tanggal_mulai_perkuliahan}
            />
            {form.tanggal_mulai_perkuliahan}
          </div>
          <div className="col-6">
            <FormSelect
              label="Semester"
              name="semester"
              value={form.semester}
              onChange={handleChange}
              error={errors.semester}
              disabled={true}
            >
              <option value="">Pilih</option>
              <option value="1">Ganjil</option>
              <option value="2">Genap</option>
            </FormSelect>
          </div>
          <div className="col-6">
            <FormSelect
              label="Tahun Ajaran"
              name="tahun_ajaran"
              value={form.tahun_ajaran}
              onChange={handleChange}
              error={errors.tahun_ajaran}
              disabled={true}
            >
              <option value="">Pilih</option>
              {tahunAngkatan.map((ta, index) => {
                return (
                  <option key={index} value={ta}>
                    {ta}
                  </option>
                );
              })}
            </FormSelect>
          </div>
          {/* Data Kelas */}
          <div className="col-12">
            <h6 className="text-dark fw-semibold">Data Kelas</h6>
            {errors.data_kelas && (
              <p className="text-danger">Data kelas tidak boleh kosong !</p>
            )}
            <TableNew>
              <Thead>
                <Tr className="text-center">
                  <Th>No</Th>
                  <Th>Kode Kelas</Th>
                  <Th>Mata Kuliah</Th>
                  <Th>SKS</Th>
                  <Th>Ruang</Th>
                  <Th>Jml Mhs</Th>
                  <Th>Jadwal</Th>
                </Tr>
              </Thead>
              <Tbody>
                {kelas.length > 0 ?
                  kelas.map((k, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-center">{index + 1}</Td>
                        <Td className="text-center">{k.kode}</Td>
                        <Td>{k.matkul && k.matkul.nama}</Td>
                        <Td>{k.matkul && k.matkul.sks}</Td>
                        <Td className="text-center">{k.ruangan && k.ruangan.nama}</Td>
                        <Td className="text-center">{k.mhs && k.mhs.length} Orang</Td>
                        <Td className="text-center">
                          {k.jamkul &&
                            `${getHari(k.jamkul.hari)} / ${k.jamkul.dari} - ${
                              k.jamkul.sampai
                            }`}
                        </Td>
                      </Tr>
                    );
                  }) : 
                    <Tr>
                      <Td colSpan="7" className="text-center">Tidak ada data.</Td>
                    </Tr>
                  }
              </Tbody>
            </TableNew>
          </div>
          {/* End Data Kelas */}
          {/* Data Honorarium */}
          <div className="col-12">
            <h6 className="text-dark fw-semibold">Data Honorarium</h6>
            {errors.honor_dosen && (
              <p className="text-danger">Honor dosen tidak boleh kosong !</p>
            )}
            <TableNew>
              <Thead>
                <Tr className="text-center">
                  <Th>No</Th>
                  <Th className="text-nowrap">Honor Dosen</Th>
                  <Th>SKS</Th>
                  <Th className="text-nowrap">Total / Pertemuan</Th>
                </Tr>
              </Thead>
              <Tbody>
                {kelas.length > 0 ?
                  kelas.map((k, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>Rp {getCurrency(form.honor_dosen)}</Td>
                        <Td>{k.matkul && k.matkul.sks}</Td>
                        <Td>
                          {k.matkul &&
                            `Rp ${getCurrency(
                              parseInt(k.matkul.sks * form.honor_dosen)
                            )}`}
                        </Td>
                      </Tr>
                    );
                  }) :
                    <Tr>
                      <Td colSpan="7" className="text-center">Tidak ada data.</Td>
                    </Tr>
                  }
              </Tbody>
            </TableNew>
          </div>
          {/* End Data Honorarium */}

          {/* Data Waktu Pertemuan */}
          <div className="col-12">
            <h6 className="text-dark fw-semibold">Data Waktu Pertemuan</h6>
            <TableNew>
              <Thead>
                <Tr className="text-center">
                  <Th>No</Th>
                  <Th>SKS</Th>
                  <Th className="text-nowrap">Waktu / SKS</Th>
                  <Th className="text-nowrap">Total / Pertemuan</Th>
                </Tr>
              </Thead>
              <Tbody>
                {kelas.length > 0 ?
                  kelas.map((k, index) => {
                    return (
                      <Tr key={index}>
                        <Td>{index + 1}</Td>
                        <Td>{k.matkul && k.matkul.sks}</Td>
                        <Td>{form.waktu_sks} Menit</Td>
                        <Td>
                          {k.matkul && parseInt(k.matkul.sks * form.waktu_sks)}{" "}
                          Menit
                        </Td>
                      </Tr>
                    );
                  }) :
                    <Tr>
                      <Td colSpan="7" className="text-center">Tidak ada data.</Td>
                    </Tr>
                  }
              </Tbody>
            </TableNew>
          </div>
          {/* End Data Waktu Pertemuan */}
          {/* <div className="btn-group mx-auto col-lg-6">
            <button type="submit" className="btn btn-sm btn-success-2">
              Simpan
            </button>
            <button
              type="button"
              className="btn btn-sm btn-dark border-0"
              onClick={handleClose}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div> */}
          <NewSubmitButton onClose={handleClose}/>
        </form>
      </LayoutModal>
    </>
  );
};

export default CreateSkDosen;
