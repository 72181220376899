import React, { useCallback, useEffect, useState } from 'react';
import SmallButton from '../../components/forms/SmallButton';
import LayoutModal from '../../components/layouts/Modal';
import { getGenapGanjil, getOrigin } from '../../helpers';
import axios from '../../axios';
import LoadingBasic from '../../components/pages/LoadingBasic';

function ShowAngket({ dosen, tahunAjaran, semester }) {
    const [modal, setModal] = useState(false);
    const [loading, setLoading] = useState(true);
    const [getUrl, setGetUrl] = useState("");

    const fetchData = async () => {
        setLoading(true);
        try {
            await axios.get(`${getOrigin()}/api/storage/dosen/angket/${tahunAjaran}/${semester}/${dosen.nip}.pdf`);
            setGetUrl(`${getOrigin()}/api/storage/dosen/angket/${tahunAjaran}/${semester}/${dosen.nip}.pdf`);
            setLoading(false);
        } catch (error) {
            setGetUrl("");
            setLoading(false);
        }
    }

    const handleOpen = () => {
        setModal(true);
        fetchData();
    };
    const handleClose = () => {
        setModal(false);
    };

    return (
        <>
            <SmallButton onClick={handleOpen} margin="me-1" type="custom" buttonColor="dark" icon="a" title="Lihat Angket" />

            <LayoutModal title={`Angket dosen: ${dosen.nama}`} modal={modal} onClose={handleClose}>
                <div className='mb-3'>
                    <table>
                        <tbody>
                            <tr>
                                <td className='fs-6 text-black'>Tahun Ajaran</td>
                                <td className='fs-6 fw-medium text-black px-3'>:</td>
                                <td className='fs-6 fw-semibold text-black'>{tahunAjaran}</td>
                            </tr>
                            <tr>
                                <td className='fs-6 text-black'>Semester</td>
                                <td className='fs-6 fw-medium text-black px-3'>:</td>
                                <td className='fs-6 fw-semibold text-black'>{getGenapGanjil(semester)}</td>
                            </tr>
                            <tr>
                                <td className='fs-6 text-black'>NIP</td>
                                <td className='fs-6 fw-medium text-black px-3'>:</td>
                                <td className='fs-6 fw-semibold text-black'>{dosen.nip}</td>
                            </tr>
                            <tr>
                                <td className='fs-6 text-black'>Dosen</td>
                                <td className='fs-6 fw-medium text-black px-3'>:</td>
                                <td className='fs-6 fw-semibold text-black'>{dosen.nama}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <AngketView loading={loading} tahun={tahunAjaran} semester={semester} getUrl={getUrl}/>
            </LayoutModal>
        </>
    );
}

const AngketView = ({loading, tahun, semester, getUrl }) => {

    return (
        <div className='mb-4'>
            {loading ? (
                <LoadingBasic/>
            ) 
            : 
                getUrl ? (
                    <div className="ratio ratio-1x1">
                        <iframe
                            className="ratio ratio-1x1"
                            title="Honor"
                            src={getUrl}
                            allowFullScreen
                        ></iframe>
                    </div>
                ) : (
                    <div className='d-flex justify-content-center align-items-center p-5 text-black rounded-3'>
                        <div className='text-center'>
                            <i className='fa fa-fw fa-circle-xmark text-danger'/>
                            <p className='mb-0'>Tidak ada file pada semester {getGenapGanjil(semester)} {tahun}.</p>
                        </div>
                    </div>
                )
            }
        </div>
    )
};

export default ShowAngket;