import React, { useState } from "react";
import { useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import FormInput from "../../../components/forms/Input";
import { saAlert } from "../../../helpers";
import { patchDepartemen } from "../apis";
import { DepartemenContext } from "../Routes";

const Edit = ({ mainUrl, onFetchData }) => {
  const { id } = useParams();
  const { departemen } = useContext(DepartemenContext);

  const [form, setForm] = useState(
    departemen.data.length > 0
      ? departemen.data.filter((d) => parseInt(d.id) === parseInt(id))[0]
      : {}
  );
  const [errors, setErrors] = useState({});

  const history = useHistory();

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await patchDepartemen(id, form);
      if (res.data && res.data.msg) {
        onFetchData();
        history.push(mainUrl);
        saAlert("success", res.data.msg);
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <h3>Ubah Data</h3>
        <FormInput
          label="nama"
          name="nama"
          value={form.nama}
          onChange={handleChange}
          error={errors.nama}
        />
        <div className="btn-group">
          <button type="submit" className="btn btn-sm btn-success">
            Simpan
          </button>
          <Link type="button" className="btn btn-sm btn-secondary" to={mainUrl}>
            Tutup
          </Link>
        </div>
      </form>
    </>
  );
};

export default Edit;
