import React from "react";
import { Helmet } from "react-helmet";
import { getOrigin } from "../../helpers";

const Header2022 = ({
  children,
  fontSize = "14pt",
  type = "potrait",
  className = "",
  fixed = false,
  header = true,
  footer = true,
  margin = "0mm 0mm 0mm 0mm",
}) => {
  return (
    <>
      <Helmet>
        <body className="bg-white" />
        <style type="text/css">
          {`
            * {
              font-family: 'Cambria', sans-serif;
              color: #000000;
            }

            p, span, li, td, th {
              font-size: ${fontSize};
            }

            @media print{
              @page {
                size: ${type};
                margin: ${margin};
              }
              
              ${
                fixed
                  ? `
                #table-thead {
                  top: 0;
                  position: fixed;
                }
                #table-tfoot {
                  bottom: 0;
                  position: fixed;
                }`
                  : ""
              }
            }
            @media screen {
              .ggwp {
                display: none;
              }
            }
          `}
        </style>
      </Helmet>

      <div className="container-fluid d-print-none bg-light py-5">
        <h3>Contoh KOP Surat (Section ini akan hilang pada saat anda print)</h3>
        <button
          className="btn btn-sm btn-primary"
          onClick={() =>
            window.open(
              `${getOrigin()}/storage/data/surat/Kop%20Surat.pdf`
            )
          }
        >
          <i className="fa fa-print text-white"></i> Print Kop Surat
        </button>
      </div>

      <div className="container-fluid vh-100 h-100">
        <table className="table table-borderless">
          {header && (
            <thead id="table-thead">
              <tr>
                <th>
                  <img
                    src={`${getOrigin()}/img/surat/header.png`}
                    alt=""
                    className="w-100 p-0 m-0 mb-5"
                  />
                </th>
              </tr>
            </thead>
          )}
          <tbody>
            <tr>
              <td className={`${className} d-flex justify-content-center row`}>
                <div className="col-11">
                  {fixed ? (
                    <div className="ggwp">
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  ) : (
                    ""
                  )}
                  {children}
                </div>
              </td>
            </tr>
          </tbody>
          {footer && (
            <tfoot id="table-tfoot">
              <tr>
                <th>
                  <img
                    src={`${getOrigin()}/img/surat/footer.png`}
                    alt=""
                    className="w-100 p-0 m-0"
                  />
                </th>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </>
  );
};

export default Header2022;
