import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../axios";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { formatDate, saAlert, saConfirm } from "../../helpers";
import { tokenState, userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import FormTextarea from "../../components/forms/Textarea";
import FormInput from "../../components/forms/Input";

const Workorder = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState();

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get("/api/wo/show?page=" + page, {
        headers: { Authorization: "Bearer " + token },
      });
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page, setItems, token, setIsLoaded]);

  useEffect(() => {
    setTitle("Data Workorder");
    fetchData();
  }, [setTitle, fetchData]);

  // delete
  const handleDelete = (id) => {
    saConfirm("warning", "Yakin hapus workorder ini ?").then((res) => {
      if (res.isConfirmed) {
        axios
          .delete("/api/wo/" + id, {
            headers: { Authorization: "Bearer " + token },
          })
          .then((res) => {
            if (res.status === 200) {
              fetchData();
              saAlert("success", "Berhasil hapus data workorder !");
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const [form, setForm] = useState();

  // Submit
  const handleSubmit = (e, id, status) => {
    e.preventDefault();
    saConfirm(
      "warning",
      "Yakin sudah mengisi tanggal verifikasi yang benar ?"
    ).then((res) => {
      if (res.isConfirmed) {
        axios
          .patch(
            "/api/wo/" + id,
            { status, ...form },
            { headers: { Authorization: "Bearer " + token } }
          )
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data);
              setForm("");
              fetchData();
              saAlert("success", "Berhasil mengubah status Work Order !");
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  if (isLoaded) {
    return (
      <>
        <TopContent onFetchData={fetchData} />
        {items.data.length >= 1 ? (
          <LayoutTable>
            <LayoutThead className="text-nowrap">
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Jabatan</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Nama</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Keterangan</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Harapan Selesai</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Selesai Pada</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Status</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Oleh</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal"><i className="fa fa-fw fa-cog"/></th>
            </LayoutThead>
            <tbody>
              {items.data.map((i, index) => {
                return (
                  <tr key={index}>
                    <td className="py-1 border-silver align-middle text-center">{items.from + index}</td>
                    <td className="py-1 border-silver align-middle text-center">{i.pegawai.jabatan}</td>
                    <td className="py-1 border-silver align-middle text-center">{i.pegawai.nama}</td>
                    <td className="py-1 border-silver align-middle">{i.keterangan}</td>
                    <td className="py-1 border-silver align-middle text-nowrap text-center">
                      {formatDate(i.harapan_selesai)}
                    </td>
                    <td className="py-1 border-silver align-middle text-nowrap text-center">
                      {i.tanggal_selesai ? (
                        <span className="text-success fw-semibold">
                          {formatDate(i.tanggal_selesai)}
                        </span>
                      ) : (
                        <span className="text-danger fw-semibold">Belum selesai</span>
                      )}
                    </td>
                    <td className="py-1 border-silver align-middle text-center">
                      {i.status === null && (
                        <span className="text-dark fw-semibold"><i className="fa fa-spin fa-spinner fa-sm"/> Waiting...</span>
                      )}
                      {parseInt(i.status) === 0 && (
                        <span className="text-danger fw-semibold"><i className="fa fa-fw fa-sm fa-circle-xmark"/> Reject</span>
                      )}
                      {parseInt(i.status) === 1 && (
                        <>
                          {i.tanggal_selesai ? (
                            <form onSubmit={(e) => handleSubmit(e, i.id, 3)}>
                              <p className="mb-0 text-dark">Waktu pengajuan :</p>
                              <p className="mb-0 text-dark fw-semibold">{formatDate(i.tanggal_selesai)}</p>
                              <input
                                className="form-control form-control-sm form-control-custom text-sm border-silver rounded mb-1"
                                onChange={(e) =>
                                  setForm({ [e.target.name]: e.target.value })
                                }
                                type="datetime-local"
                                name="tanggal_selesai"
                                required
                              />
                              <button
                                type="submit"
                                className="btn btn-sm btn-success-2 text-sm py-0 px-1 w-100"
                              >
                                <i className="fa fa-fw fa-sm fa-check"/> Verifikasi Jam Selesai
                              </button>
                            </form>
                          ) : (
                            <span className="text-success fw-semibold"><i className="fa fa-fw fa-sm fa-check"/> Menyetujui review</span>
                          )}
                        </>
                      )}

                      {parseInt(i.status) === 2 && (
                        <CheckReview
                          id={i.id}
                          harapan_selesai={i.harapan_selesai}
                          catatan_by={i.catatan_by}
                          updated_by={i.updated_by}
                          onFetchData={fetchData}
                        />
                      )}

                      {parseInt(i.status) === 3 && (
                        <span className="text-success-2 fw-semibold"><i className="fa fa-fw fa-sm fa-circle-check"/> Selesai</span>
                      )}
                    </td>
                    <td className="py-1 border-silver align-middle text-center">{i.updated_by}</td>
                    <td className="py-1 border-silver align-middle">
                      <button
                        onClick={() => handleDelete(i.id)}
                        className="btn btn-sm btn-danger-2 border-0 py-0 px-1"
                      >
                        <i className="fa fa-fw fa-sm fa-trash-alt"/>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </LayoutTable>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

const TopContent = (props) => {
  const token = useRecoilValue(tokenState);
  const user = useRecoilValue(userState);
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("/api/wo", form, { headers: { Authorization: "Bearer " + token } })
      .then((res) => {
        e.target.reset();
        props.onFetchData();
        setModal(false);
        setErrors({});
        setForm({});
        saAlert("success", "Berhasil membuat workorder baru !");
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            setErrors(err.response.data.errors);
          }
        }
      });
  };

  return (
    <>
      <LayoutAddBtn
        add="Buat Workorder Baru"
        onClick={() => setModal(!modal)}
      />
      <LayoutModal
        title="Buat Workorder Baru"
        size="md"
        modal={modal}
        onClose={() => setModal(false)}
      >
        <form onSubmit={handleSubmit}>
          <div className="bg-gradient-primary border-bottom-warning text-white rounded-3 shadow p-3 mb-3">
            <div className="d-flex align-items-center">
              <div className="bg-black rounded px-2 py-3 me-2">
              <i className="fa fa-fw fa-xl fa-user"/>
              </div>
              <div>
                <h6 className="mb-0">{user.nama}</h6>
                <p className="mb-0 text-warning">{user.nip}</p>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label className="fw-semibold text-primary">Ditujukan Kepada</label>
            <br />
            <div className="form-check form-check-inline text-dark">
              <input
                onChange={(e) =>
                  setForm({ ...form, [e.target.name]: e.target.value })
                }
                className={`form-check-input ${
                  errors.ditujukan ? "is-invalid" : "border-silver"
                }`}
                type="radio"
                name="ditujukan"
                id="multimedia"
                value="Bagian Desain & Multimedia"
              />
              <label className="form-check-label" htmlFor="multimedia">
                Bagian Desain &amp; Multimedia
              </label>
            </div>
            <div className="form-check form-check-inline text-dark">
              <input
                onChange={(e) =>
                  setForm({ ...form, [e.target.name]: e.target.value })
                }
                className={`form-check-input ${
                  errors.ditujukan ? "is-invalid" : "border-silver"
                }`}
                type="radio"
                name="ditujukan"
                id="upt"
                value="Bagian UPT"
              />
              <label className="form-check-label" htmlFor="upt">
                Bagian UPT
              </label>
            </div>
            {errors.ditujukan && (
              <p className="mb-0 text-sm text-danger">{errors.ditujukan}</p>
            )}
          </div>
          <div className="form-group">
            <label className="fw-semibold text-primary">Keterangan</label>
            <FormTextarea
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: e.target.value })
              }
              name="keterangan"
              // className={`${errors.keterangan ? "is-invalid" : ""}`}
              error={errors.keterangan}
              rows={3}
            />
            {errors.keterangan && (
              <div className="invalid-feedback">{errors.keterangan}</div>
            )}
          </div>
          <div className="form-group">
            <label className="fw-semibold text-primary">Harapan Selesai</label>
            <FormInput
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: e.target.value })
              }
              type="datetime-local"
              name="harapan_selesai"
              // className={`form-control ${
              //   errors.harapan_selesai ? "is-invalid" : ""
              // }`}
              error={errors.harapan_selesai}
            />
            {errors.harapan_selesai && (
              <div className="invalid-feedback">{errors.harapan_selesai}</div>
            )}
          </div>
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success">
              Simpan
            </button>
            <button
              type="button"
              onClick={() => setModal(false)}
              className="btn btn-sm btn-secondary"
            >
              Keluar
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

const CheckReview = (props) => {
  const token = useRecoilValue(tokenState);
  const [modal, setModal] = useState(false);

  // Approve Review
  const handleSubmit = () => {
    saConfirm("warning", "Yakin menyetujui review ?").then((res) => {
      if (res.isConfirmed) {
        axios
          .patch(
            "/api/wo/" + props.id,
            { status: 1 },
            { headers: { Authorization: "Bearer " + token } }
          )
          .then((res) => {
            props.onFetchData();
            setModal(false);
          })
          .catch((err) => console.log(err));
      }
    });
  };

  // Reject Review or Delete
  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus workorder ?").then((res) => {
      if (res.isConfirmed) {
        axios
          .delete("/api/wo/" + props.id, {
            headers: { Authorization: "Bearer " + token },
          })
          .then((res) => {
            props.onFetchData();
            setModal(false);
          })
          .catch((err) => console.log(err));
      }
    });
  };
  return (
    <>
      <span className="text-dark">
        <span className="text-nowrap fw-semibold"><i className="fa fa-fw fa-sm fa-rotate text-info"/> Review ulang</span>
        <button onClick={() => setModal(!modal)} className="btn btn-sm btn-dark border-0 rounded-1 text-sm py-0 px-2 ms-1">
          <i className="fa fa-fw fa-sm fa-eye"/> Detail
        </button>
      </span>
      <LayoutModal
        size="md"
        title="Detail Review"
        modal={modal}
        onClose={() => setModal(false)}
      >
        <div className="text-dark">
          <p className="mb-0 fw-semibold text-primary">Diajukan oleh :</p>
          <p>{props.updated_by}</p>
          <p className="mb-0 fw-semibold text-primary">Tanggal selesai menjadi :</p>
          <p>{formatDate(props.harapan_selesai)}</p>
          <p className="mb-0 fw-semibold text-primary">Prihal :</p>
          <p>{props.catatan_by}</p>
          <div className="btn-group w-100">
            <button
              type="button"
              className="btn btn-sm btn-success-2"
              onClick={handleSubmit}
            >
              <i className="fa fa-fw fa-sm fa-check"/> Menyetujui
            </button>
            <button
              type="button"
              className="btn btn-sm btn-danger-2"
              onClick={handleDelete}
            >
              <i className="fa fa-fw fa-sm fa-trash-alt"/> Hapus
            </button>
            <button
              type="button"
              className="btn btn-sm btn-dark"
              onClick={() => setModal(false)}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </div>
      </LayoutModal>
    </>
  );
};

export default Workorder;
