import React, { useCallback, useEffect, useState } from "react";
import { fetchAspekBsc } from "../../api";
import { useSetRecoilState } from "recoil";
import { titleState } from "../../storage/pages";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import AspekBscCreate from "./Create";
import SectionPagination from "../../components/sections/Pagination";
import {
  FilterPagination,
  FilterSearch,
} from "../../components/layouts/TopContent";
import AspekBscEdit from "./Edit";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const AspekBsc = () => {
  const setTitle = useSetRecoilState(titleState);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState(10);

  const [isLoaded, setIsLoaded] = useState(false);

  const [aspek_bsc, setKpi] = useState({});

  const fetchData = useCallback(async () => {
    setIsLoaded(false);
    try {
      const res = await fetchAspekBsc(
        page,
        `search=${search}&pagination=${pagination}`
      );
      setIsLoaded(true);
      setTitle(res.data.title);
      setKpi(res.data.aspek_bsc);
    } catch (err) {
      //
    }
  }, [page, search, setTitle, pagination]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <AspekBscCreate onFetchData={fetchData} />

      <div className="row justify-content-end mb-2">
        <div className="col-md-4">
          <FilterPagination onChange={(res) => setPagination(res)} />
        </div>
        <div className="col-md-4">
          <FilterSearch onChange={(res) => setSearch(res)} />
        </div>
      </div>

      {isLoaded ? (
        <>
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>Kode</Th>
                <Th>Nama</Th>
                <Th>Type</Th>
                <Th>
                  <i className="fa fa-bars"></i>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {aspek_bsc.data &&
                aspek_bsc.data.length > 0 &&
                aspek_bsc.data.map((ab, index) => {
                  return (
                    <Tr key={index}>
                      <Td className="text-center">{index + aspek_bsc.from}</Td>
                      <Td>{ab.kode}</Td>
                      <Td>{ab.nama}</Td>
                      <Td>{ab.type}</Td>
                      <Td className="text-center">
                        <AspekBscEdit aspekBsc={ab} onFetchData={fetchData} />
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>

          <SectionPagination
            links={aspek_bsc.links}
            onChangePage={(res) => setPage(res)}
          />
        </>
      ) : (
        "Waiting"
      )}
    </>
  );
};

export default AspekBsc;
