import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../axios";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { FilterBulan, FilterTahun } from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import { formatDate, saAlert, saConfirm } from "../../helpers";
import { tokenState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const Workorder = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState(false);

  const [items, setItems] = useState();

  const [bulan, setBulan] = useState(new Date().getMonth() + 1);
  const [tahun, setTahun] = useState(new Date().getFullYear());

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(
        "/api/wo?bulan=" + bulan + "&tahun=" + tahun,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [bulan, setItems, token, setIsLoaded, tahun]);

  useEffect(() => {
    setTitle("Data Workorder");
    fetchData();
  }, [setTitle, fetchData]);

  // Update
  const handleUpdate = (id, status, message) => {
    saConfirm("warning", `Yakin ${message} Work Order ?`).then((res) => {
      if (res.isConfirmed) {
        axios
          .patch(
            "/api/wo/" + id,
            { status },
            { headers: { Authorization: "Bearer " + token } }
          )
          .then((res) => {
            if (res.status === 200) {
              fetchData();
              saAlert("success", `Berhasil ${message} status Work Order !`);
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const [form, setForm] = useState();

  // Submit
  const handleSubmit = (e, id, status) => {
    e.preventDefault();
    saConfirm(
      "warning",
      "Yakin sudah mengisi tanggal Work Order yang benar ?"
    ).then((res) => {
      if (res.isConfirmed) {
        axios
          .patch(
            "/api/wo/" + id,
            { status, ...form },
            { headers: { Authorization: "Bearer " + token } }
          )
          .then((res) => {
            if (res.status === 200) {
              console.log(res.data);
              setForm("");
              fetchData();
              saAlert("success", "Berhasil mengubah status Work Order !");
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  if (isLoaded) {
    return (
      <>
        <div className="row mb-2">
          <div className="col-md-6">
            <FilterTahun onChange={(res) => setTahun(res)} value={tahun} />
          </div>
          <div className="col-md-6">
            <FilterBulan onChange={(res) => setBulan(res)} value={bulan} />
          </div>
        </div>
        {items.length >= 1 ? (
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th className="text-nowrap">No</Th>
                <Th className="text-nowrap">Jabatan</Th>
                <Th className="text-nowrap">Nama</Th>
                <Th className="text-nowrap">Keterangan</Th>
                <Th className="text-nowrap">Dibuat Pada</Th>
                <Th className="text-nowrap">Harapan Selesai</Th>
                <Th className="text-nowrap">Status</Th>
                <Th className="text-nowrap">Approved By</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center">{1 + index}</Td>
                    <Td>{i.pegawai && i.pegawai.jabatan}</Td>
                    <Td>{i.pegawai && i.pegawai.nama}</Td>
                    <Td>{i.keterangan}</Td>
                    <Td className="text-nowrap text-center">
                      {i.created_at.split("||")[0]} <br />
                      {i.created_at.split("||")[1]} WITA
                    </Td>
                    <Td className="text-nowrap">
                      {formatDate(i.harapan_selesai)}
                    </Td>
                    <Td className="text-center">
                      {i.status === null && (
                        <div className="btn-group w-100" role="group">
                          <button
                            onClick={() => handleUpdate(i.id, 1, "Approve")}
                            type="button"
                            className="btn btn-sm btn-success"
                          >
                            Approve
                          </button>
                          <BtnReview
                            onFetchData={fetchData}
                            id={i.id}
                            harapan_selesai={i.harapan_selesai}
                          />
                          <button
                            onClick={() => handleUpdate(i.id, 0, "Reject")}
                            type="button"
                            className="btn btn-sm btn-danger"
                          >
                            Reject
                          </button>
                        </div>
                      )}
                      {parseInt(i.status) === 0 && (
                        <>
                          <p className="text-danger">Rejected</p>
                          <button
                            onClick={() =>
                              handleUpdate(i.id, null, "Batal Reject")
                            }
                            type="button"
                            className="btn btn-sm btn-success"
                          >
                            Batal Reject
                          </button>
                        </>
                      )}
                      {parseInt(i.status) === 1 && (
                        <>
                          {i.tanggal_selesai ? (
                            <span className="text-warning">
                              Menunggu Verifikasi Selesai
                            </span>
                          ) : (
                            <form onSubmit={(e) => handleSubmit(e, i.id, 1)}>
                              <input
                                onChange={(e) =>
                                  setForm({ [e.target.name]: e.target.value })
                                }
                                type="datetime-local"
                                name="tanggal_selesai"
                                required
                              />
                              <button
                                type="submit"
                                className="btn btn-sm btn-success w-100"
                              >
                                Workorder Selesai
                              </button>
                            </form>
                          )}
                          <button
                            onClick={() =>
                              handleUpdate(i.id, null, "Batal Approve")
                            }
                            type="button"
                            className="btn btn-sm btn-danger w-100"
                          >
                            Batal Approve
                          </button>
                        </>
                      )}
                      {parseInt(i.status) === 2 && (
                        <span className="text-warning">
                          Review Telah Diajukan
                        </span>
                      )}
                      {parseInt(i.status) === 3 && (
                        <span className="text-info">
                          Done <br />
                          {i.tanggal_selesai} <br />
                          By : {i.updated_by}
                        </span>
                      )}
                    </Td>
                    <Td>{i.updated_by}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <PageNoData />
        )}
      </>
    );
  } else {
    return <PageLoading />;
  }
};

const BtnReview = (props) => {
  const [modal, setModal] = useState(false);
  const token = useRecoilValue(tokenState);

  const [form, setForm] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .patch("/api/wo/" + props.id + "?review=true", form, {
        headers: { Authorization: "Bearer " + token },
      })
      .then((res) => {
        e.target.reset();
        setForm({});
        props.onFetchData();
        setModal(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <button
        onClick={() => setModal(!modal)}
        className="btn btn-sm btn-warning"
      >
        Review
      </button>
      <LayoutModal
        title="Form Review Workorder"
        modal={modal}
        onClose={() => setModal(false)}
      >
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <p>
              Sebelumnya diharapkan selesai pada : <br />{" "}
              {formatDate(props.harapan_selesai)}
            </p>
            <label>Harapan Selesai</label>
            <input
              type="datetime-local"
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: e.target.value })
              }
              name="harapan_selesai"
              className="form-control form-control-sm"
              required
            />
          </div>
          <div className="form-group">
            <label>Catatan</label>
            <textarea
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: e.target.value })
              }
              name="catatan_by"
              placeholder="Tuliskan alasan anda . . ."
              className="form-control form-control-sm"
              required
            ></textarea>
          </div>
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success">
              Review
            </button>
            <button
              onClick={() => setModal(false)}
              className="btn btn-sm btn-secondary"
            >
              Tutup
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default Workorder;
