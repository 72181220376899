import React from "react";
import Time from "./Time";

const DashboardTopMain = () => {

  return (
    <> 
    <div className="mx-3 mb-3">
      <div className="row gy-3 my-0">
        <div className="col-lg-12">
          <div className="card bg-gradient-primary text-white border-bottom-warning shadow rounded-4 h-100 py-2">
            <div className="card-body px-4 py-2">
              <div className="row no-gutters align-items-start mb-3">
                <div className="col">
                  <h6 className="mb-0 fw-semibold">SISTEM INFORMASI MANAJEMEN</h6>
                  {/* <p className="mb-0 text-gray-500 text-sm"><i className="fa fa-fw fa-circle-check text-success-2"></i> Stabil digunakan pada Google Chrome.</p> */}
                </div>
                <div className="col-auto d-none d-sm-block">
                  <div className="d-flex align-items-center">
                    <Time onChangeMusic={(res) => console.log(res)} />
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-lg-3 mb-3 mb-lg-0">
                  <div className="shadow-lg rounded-3 border border-secondary p-3 h-100">
                    <p className="mb-2 fw-semibold text-warning">BUDAYA PRIMA</p>
                    <div className="d-flex justify-content-center align-items-center h-75 pt-2">
                      <ul className="list-unstyled mb-0">
                        <li className="text-sm"><span className="text-warning fw-semibold fs-6">P</span>ROFESSIONAL</li>
                        <li className="text-sm"><span className="text-warning fw-semibold fs-6">R</span>ESPECT</li>
                        <li className="text-sm"><span className="text-warning fw-semibold fs-6">I</span>NTEGRITY</li>
                        <li className="text-sm"><span className="text-warning fw-semibold fs-6">M</span>ISSION ORIENTED</li>
                        <li className="text-sm"><span className="text-warning fw-semibold fs-6">A</span>GILITY</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 mb-3 mb-lg-0">
                  <div className="shadow-lg rounded-3 border border-secondary p-3 h-100">
                    <p className="mb-2 fw-semibold text-warning">VISI</p>
                    <div className="d-flex justify-content-center align-items-center h-75 pb-4">
                      <p className="mb-0 fs-5 fw-semibold text-center text-uppercase">To be the <span className="text-warning">#1st Choice</span> of Industry</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="shadow-lg rounded-3 border border-secondary p-3 h-100">
                    <p className="mb-2 fw-semibold text-warning">MISI</p>
                    <ul className="mb-0">
                      <li className="text-sm mb-2">Menjalankan lembaga pendidikan yang membentuk mahasiswanya memiliki <span className="text-warning">kompetensi terbaik</span> dan <span className="text-warning">terupdate</span> melalui pembelajaran yang <span className="text-warning">joyfull</span>.</li>
                      <li className="text-sm mb-2">Berfokus pada pengembangan <span className="text-warning">Softskill</span> dan pengembangan <span className="text-warning">karakter</span> yang mendukung untuk sukses.</li>
                      <li className="text-sm">Menjalin sinergi yang luas dengan pemerintah, dunia industri dan komponen masyarakat lainnya melalui cara-cara yang <span className="text-warning">kreatif</span>.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default DashboardTopMain;
