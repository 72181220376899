import React from 'react'
import { useRecoilValue } from 'recoil';
import { userState } from '../../storage/auth';

function VirtualView(props) {

    const {beritaTerkini} = props;
    const user = useRecoilValue(userState);

  return (
    <div className='row'>
            {/* denpasar */}
            {(user.cabang === "pusat" || user.cabang === "denpasar") &&
                <div className='col-lg-4'>
                    {user.cabang === "pusat" && <h6 className='text-center fw-semibold'>Virtual View Denpasar</h6>}
                    <div className={`row ${user.cabang === "pusat" && "px-3"}`}>
                        {beritaTerkini.filter((bt) => bt.cabang === "denpasar").length >= 1 ?
                            beritaTerkini.slice(0,1).map((bt, index) => {
                                return (
                                    <div className='col-6 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>1</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-6 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>1</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        <div className='col-6'>
                            <div className='row g-0'>
                                {beritaTerkini.filter((bt) => bt.cabang === "denpasar").length >= 2 ?
                                    beritaTerkini.slice(1,2).map((bt, index) => {
                                        return (
                                            <div className='col-6 p-1' key={index}>
                                                <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                                    <div className='d-flex justify-content-center align-items-center p-3'>
                                                        <div className='text-center'>
                                                            <p className='mb-0'>2</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                :
                                <div className='col-6 p-1'>
                                    <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                        <div className='d-flex justify-content-center align-items-center p-3'>
                                            <div className='text-center'>
                                                <p className='mb-0'>2</p>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {beritaTerkini.filter((bt) => bt.cabang === "denpasar").length >= 3 ?
                                    beritaTerkini.slice(2,3).map((bt, index) => {
                                        return (
                                            <div className='col-6 p-1' key={index}>
                                                <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                                    <div className='d-flex justify-content-center align-items-center p-3'>
                                                        <div className='text-center'>
                                                            <p className='mb-0'>3</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                :
                                <div className='col-6 p-1'>
                                    <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                        <div className='d-flex justify-content-center align-items-center p-3'>
                                            <div className='text-center'>
                                                <p className='mb-0'>3</p>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {beritaTerkini.filter((bt) => bt.cabang === "denpasar").length >= 4 ?
                                    beritaTerkini.slice(3,4).map((bt, index) => {
                                        return (
                                            <div className='col-6 p-1' key={index}>
                                                <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                                    <div className='d-flex justify-content-center align-items-center p-3'>
                                                        <div className='text-center'>
                                                            <p className='mb-0'>4</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                :
                                <div className='col-6 p-1'>
                                    <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                        <div className='d-flex justify-content-center align-items-center p-3'>
                                            <div className='text-center'>
                                                <p className='mb-0'>4</p>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {beritaTerkini.filter((bt) => bt.cabang === "denpasar").length >= 5 ?
                                    beritaTerkini.slice(4,5).map((bt, index) => {
                                        return (
                                            <div className='col-6 p-1' key={index}>
                                                <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                                    <div className='d-flex justify-content-center align-items-center p-3'>
                                                        <div className='text-center'>
                                                            <p className='mb-0'>5</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                :
                                <div className='col-6 p-1'>
                                    <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                        <div className='d-flex justify-content-center align-items-center p-3'>
                                            <div className='text-center'>
                                                <p className='mb-0'>5</p>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>

                        {/* grade 3 */}

                        {beritaTerkini.filter((bt) => bt.cabang === "denpasar").length >= 6 ?
                            beritaTerkini.slice(5,6).map((bt, index) => {
                                return (
                                    <div className='col-3 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>6</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-3 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>6</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {beritaTerkini.filter((bt) => bt.cabang === "denpasar").length >= 7 ?
                            beritaTerkini.slice(6,7).map((bt, index) => {
                                return (
                                    <div className='col-3 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>7</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-3 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>7</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {beritaTerkini.filter((bt) => bt.cabang === "denpasar").length >= 8 ?
                            beritaTerkini.slice(7,8).map((bt, index) => {
                                return (
                                    <div className='col-3 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>8</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-3 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>8</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {beritaTerkini.filter((bt) => bt.cabang === "denpasar").length >= 9 ?
                            beritaTerkini.slice(8,9).map((bt, index) => {
                                return (
                                    <div className='col-3 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>9</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-3 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>9</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            }

            {/* singaraja */}
            {(user.cabang === "pusat" || user.cabang === "singaraja") &&
                <div className='col-lg-4'>
                    {user.cabang === "pusat" && <h6 className='text-center fw-semibold'>Virtual View Singaraja</h6>}
                    <div className={`row ${user.cabang === "pusat" && "px-3"}`}>
                        {beritaTerkini.filter((bt) => bt.cabang === "singaraja").length >= 1 ?
                            beritaTerkini.slice(0,1).map((bt, index) => {
                                return (
                                    <div className='col-6 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>1</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-6 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>1</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        <div className='col-6'>
                            <div className='row g-0'>
                                {beritaTerkini.filter((bt) => bt.cabang === "singaraja").length >= 2 ?
                                    beritaTerkini.slice(1,2).map((bt, index) => {
                                        return (
                                            <div className='col-6 p-1' key={index}>
                                                <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                                    <div className='d-flex justify-content-center align-items-center p-3'>
                                                        <div className='text-center'>
                                                            <p className='mb-0'>2</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                :
                                <div className='col-6 p-1'>
                                    <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                        <div className='d-flex justify-content-center align-items-center p-3'>
                                            <div className='text-center'>
                                                <p className='mb-0'>2</p>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {beritaTerkini.filter((bt) => bt.cabang === "singaraja").length >= 3 ?
                                    beritaTerkini.slice(2,3).map((bt, index) => {
                                        return (
                                            <div className='col-6 p-1' key={index}>
                                                <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                                    <div className='d-flex justify-content-center align-items-center p-3'>
                                                        <div className='text-center'>
                                                            <p className='mb-0'>3</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                :
                                <div className='col-6 p-1'>
                                    <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                        <div className='d-flex justify-content-center align-items-center p-3'>
                                            <div className='text-center'>
                                                <p className='mb-0'>3</p>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {beritaTerkini.filter((bt) => bt.cabang === "singaraja").length >= 4 ?
                                    beritaTerkini.slice(3,4).map((bt, index) => {
                                        return (
                                            <div className='col-6 p-1' key={index}>
                                                <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                                    <div className='d-flex justify-content-center align-items-center p-3'>
                                                        <div className='text-center'>
                                                            <p className='mb-0'>4</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                :
                                <div className='col-6 p-1'>
                                    <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                        <div className='d-flex justify-content-center align-items-center p-3'>
                                            <div className='text-center'>
                                                <p className='mb-0'>4</p>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {beritaTerkini.filter((bt) => bt.cabang === "singaraja").length >= 5 ?
                                    beritaTerkini.slice(4,5).map((bt, index) => {
                                        return (
                                            <div className='col-6 p-1' key={index}>
                                                <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                                    <div className='d-flex justify-content-center align-items-center p-3'>
                                                        <div className='text-center'>
                                                            <p className='mb-0'>5</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                :
                                <div className='col-6 p-1'>
                                    <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                        <div className='d-flex justify-content-center align-items-center p-3'>
                                            <div className='text-center'>
                                                <p className='mb-0'>5</p>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>

                        {/* grade 3 */}

                        {beritaTerkini.filter((bt) => bt.cabang === "singaraja").length >= 6 ?
                            beritaTerkini.slice(5,6).map((bt, index) => {
                                return (
                                    <div className='col-3 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>6</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-3 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>6</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {beritaTerkini.filter((bt) => bt.cabang === "singaraja").length >= 7 ?
                            beritaTerkini.slice(6,7).map((bt, index) => {
                                return (
                                    <div className='col-3 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>7</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-3 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>7</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {beritaTerkini.filter((bt) => bt.cabang === "singaraja").length >= 8 ?
                            beritaTerkini.slice(7,8).map((bt, index) => {
                                return (
                                    <div className='col-3 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>8</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-3 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>8</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {beritaTerkini.filter((bt) => bt.cabang === "singaraja").length >= 9 ?
                            beritaTerkini.slice(8,9).map((bt, index) => {
                                return (
                                    <div className='col-3 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>9</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-3 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>9</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            }

            {/* karangasem */}
            {(user.cabang === "pusat" || user.cabang === "karangasem") &&
                <div className='col-lg-4'>
                    {user.cabang === "pusat" && <h6 className='text-center fw-semibold'>Virtual View Karangasem</h6>}
                    <div className={`row ${user.cabang === "pusat" && "px-3"}`}>
                        {beritaTerkini.filter((bt) => bt.cabang === "karangasem").length >= 1 ?
                            beritaTerkini.slice(0,1).map((bt, index) => {
                                return (
                                    <div className='col-6 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>1</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-6 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>1</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        <div className='col-6'>
                            <div className='row g-0'>
                                {beritaTerkini.filter((bt) => bt.cabang === "karangasem").length >= 2 ?
                                    beritaTerkini.slice(1,2).map((bt, index) => {
                                        return (
                                            <div className='col-6 p-1' key={index}>
                                                <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                                    <div className='d-flex justify-content-center align-items-center p-3'>
                                                        <div className='text-center'>
                                                            <p className='mb-0'>2</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                :
                                <div className='col-6 p-1'>
                                    <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                        <div className='d-flex justify-content-center align-items-center p-3'>
                                            <div className='text-center'>
                                                <p className='mb-0'>2</p>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {beritaTerkini.filter((bt) => bt.cabang === "karangasem").length >= 3 ?
                                    beritaTerkini.slice(2,3).map((bt, index) => {
                                        return (
                                            <div className='col-6 p-1' key={index}>
                                                <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                                    <div className='d-flex justify-content-center align-items-center p-3'>
                                                        <div className='text-center'>
                                                            <p className='mb-0'>3</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                :
                                <div className='col-6 p-1'>
                                    <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                        <div className='d-flex justify-content-center align-items-center p-3'>
                                            <div className='text-center'>
                                                <p className='mb-0'>3</p>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {beritaTerkini.filter((bt) => bt.cabang === "karangasem").length >= 4 ?
                                    beritaTerkini.slice(3,4).map((bt, index) => {
                                        return (
                                            <div className='col-6 p-1' key={index}>
                                                <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                                    <div className='d-flex justify-content-center align-items-center p-3'>
                                                        <div className='text-center'>
                                                            <p className='mb-0'>4</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                :
                                <div className='col-6 p-1'>
                                    <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                        <div className='d-flex justify-content-center align-items-center p-3'>
                                            <div className='text-center'>
                                                <p className='mb-0'>4</p>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                                {beritaTerkini.filter((bt) => bt.cabang === "karangasem").length >= 5 ?
                                    beritaTerkini.slice(4,5).map((bt, index) => {
                                        return (
                                            <div className='col-6 p-1' key={index}>
                                                <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                                    <div className='d-flex justify-content-center align-items-center p-3'>
                                                        <div className='text-center'>
                                                            <p className='mb-0'>5</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })

                                :
                                <div className='col-6 p-1'>
                                    <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                        <div className='d-flex justify-content-center align-items-center p-3'>
                                            <div className='text-center'>
                                                <p className='mb-0'>5</p>                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>

                        {/* grade 3 */}

                        {beritaTerkini.filter((bt) => bt.cabang === "karangasem").length >= 6 ?
                            beritaTerkini.slice(5,6).map((bt, index) => {
                                return (
                                    <div className='col-3 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>6</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-3 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>6</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {beritaTerkini.filter((bt) => bt.cabang === "karangasem").length >= 7 ?
                            beritaTerkini.slice(6,7).map((bt, index) => {
                                return (
                                    <div className='col-3 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>7</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-3 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>7</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {beritaTerkini.filter((bt) => bt.cabang === "karangasem").length >= 8 ?
                            beritaTerkini.slice(7,8).map((bt, index) => {
                                return (
                                    <div className='col-3 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>8</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-3 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>8</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                        {beritaTerkini.filter((bt) => bt.cabang === "karangasem").length >= 9 ?
                            beritaTerkini.slice(8,9).map((bt, index) => {
                                return (
                                    <div className='col-3 p-1' key={index}>
                                        <div className='ratio ratio-1x1 bg-primary text-white rounded-3'>
                                            <div className='d-flex justify-content-center align-items-center p-3'>
                                                <div className='text-center'>
                                                    <p className='mb-0'>9</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })

                        :
                        <div className='col-3 p-1'>
                            <div className='ratio ratio-1x1 bg-secondary rounded-3'>
                                <div className='d-flex justify-content-center align-items-center p-3'>
                                    <div className='text-center'>
                                        <p className='mb-0'>9</p>                            
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            }

            <div className='col-lg-8'>
                <div className='my-3'>
                    <h6 className='fs-3 text-black fw-semibold'>Virtual View</h6>
                    <p>Kolom ini sebagai virtual view atau gambaran bentuk postingan di halaman berita terkini di SIA, dengan adanya virtual view ini kita mendapat gambaran bentuk postingan di SIA.</p>
                    <p>Kolom dengan warna <i className='fa fa-fw fa-square text-secondary'/> abu-abu memiliki arti postingan masih kosong, sedangkan kolom berwarna <i className='fa fa-fw fa-square text-primary'/> biru memiliki arti sudah ada postingan.</p>
                    <p className='mb-0'>Sedikit informasi terkait berita terkini, postingan yang aktif maksimal akan tampil 9 postingan, tujuan adanya limit ini untuk memaksimalkan performa atau menjaga kecepatan website SIA agar tetap stabil di pengguna mahasiswa.</p>
                </div>
            </div>
        </div>
  )
}

export default VirtualView