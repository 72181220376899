import React from 'react'
import { deletePerusahaanAlumni } from '../../api'
import { saAlert, saConfirm } from '../../helpers';
import SmallButton from '../../components/forms/SmallButton';

function DeletePerusahaanAlumni({fetchData, id}) {

    const handleDelete = async () => {
        saConfirm("warning", "Yakin menghapus data ini?").then( async (res) => {
            if (res.isConfirmed) {
                try {
                    const res = await deletePerusahaanAlumni(id);
                    if (res.data.status === "success") {
                        fetchData();
                        saAlert(res.data.status, res.data.message);
                    }
                } catch (error) {
                    saAlert("error", "Gagal menghapus data ini.");
                }
            }
        })
    }

  return (
    <SmallButton onClick={handleDelete} type="delete" text="Delete"/>
  )
}

export default DeletePerusahaanAlumni