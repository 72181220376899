import React, { useCallback, useEffect, useState } from "react";
import { fetchKpi } from "../../api";
import { useSetRecoilState } from "recoil";
import { titleState } from "../../storage/pages";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import KpiCreate from "./Create";
import SectionPagination from "../../components/sections/Pagination";
import {
  FilterPagination,
  FilterSearch,
} from "../../components/layouts/TopContent";
import { getCurrency } from "../../helpers";

const Kpi = () => {
  const setTitle = useSetRecoilState(titleState);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState(10);

  const [isLoaded, setIsLoaded] = useState(false);

  const [kpi, setKpi] = useState({});

  const fetchData = useCallback(async () => {
    setIsLoaded(false);
    try {
      const res = await fetchKpi(
        page,
        `search=${search}&pagination=${pagination}`
      );
      setIsLoaded(true);
      setTitle(res.data.title);
      setKpi(res.data.kpi);
    } catch (err) {
      //
    }
  }, [page, search, setTitle, pagination]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <KpiCreate onFetchData={fetchData} />

      <div className="row justify-content-end mb-2">
        <div className="col-md-4">
          <FilterPagination onChange={(res) => setPagination(res)} />
        </div>
        <div className="col-md-4">
          <FilterSearch onChange={(res) => setSearch(res)} />
        </div>
      </div>

      {isLoaded ? (
        <>
          <LayoutTable>
            <LayoutThead>
              <th>No</th>
              <th>Pegawai</th>
              <th>Judul</th>
              <th>Keterangan</th>
              <th>Capaian</th>
              <th>Target</th>
              <th>Satuan</th>
              <th>Bobot</th>
              <th>Satus</th>
            </LayoutThead>
            <tbody>
              {kpi.data &&
                kpi.data.length > 0 &&
                kpi.data.map((k, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + kpi.from}</td>
                      <td>{k.pegawai && k.pegawai.nama}</td>
                      <td>{k.nama}</td>
                      <td>{k.keterangan}</td>
                      <td>{k.capaian}</td>
                      <td>
                        {kpi.satuan === "Rupiah"
                          ? getCurrency(kpi.target)
                          : kpi.target}
                      </td>
                      <td>{k.satuan}</td>
                      <td>{k.bobot}</td>
                      <td>{k.status}</td>
                    </tr>
                  );
                })}
            </tbody>
          </LayoutTable>

          <SectionPagination
            links={kpi.links}
            onChangePage={(res) => setPage(res)}
          />
        </>
      ) : (
        "Waiting"
      )}
    </>
  );
};

export default Kpi;
