import { useRef, useState } from "react";
import { patchPegawi } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import LayoutModal from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";
import { userState } from "../../storage/auth";
import { useRecoilValue } from "recoil";
import SmallButton from "../../components/forms/SmallButton";

const EditMasterPegawai = ({ items, pegawai, onFetchData }) => {
  const user = useRecoilValue(userState);
  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);
  const [form, setForm] = useState({});

  const handleOpen = () => {
    setForm(pegawai);
    setModal(true);
  };

  const handleClose = () => {
    setForm({});
    if (tagForm && tagForm.current) {
      tagForm.current.reset();
    }
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await patchPegawi(form.id, form);
      if (res.data && res.data.id) {
        onFetchData();
        handleClose();
        saAlert("success", "Berhasil update data pegawai !");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <SmallButton margin="me-1" type="edit" onClick={handleOpen} text="Edit"/>
      <LayoutModal
        icon="edit"
        title="Ubah Data Pegawai"
        modal={modal}
        onClose={handleClose}
      >
        <form ref={tagForm} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md-3">
              <FormInput
                label="NIP"
                name="nip"
                value={form.nip}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Nama"
                name="nama"
                value={form.nama}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="col-md-3">
              <FormSelect
                label="Cabang"
                name="cabang"
                value={form.cabang ? form.cabang : ''}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              >
                <option value="">Pilih</option>
                <option value="pusat">Pusat</option>
                <option value="denpasar">Denpasar</option>
                <option value="singaraja">Singaraja</option>
                <option value="karangasem">Karangasem</option>
              </FormSelect>
            </div>
            <div className="col-md-3">
              <FormSelect
                label="Atasan I"
                name="atasan_1"
                value={form.atasan_1 ? form.atasan_1 : ''}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              >
                <option value="">Pilih</option>
                {items.map((i, index) => {
                  return (
                    <option key={index} value={i.nip}>{i.nama}</option>
                  )
                })}
              </FormSelect>
            </div>
            <div className="col-md-3">
              <FormSelect
                label="Atasan II"
                name="atasan_2"
                value={form.atasan_2 ? form.atasan_2 : ''}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              >
                <option value="">Pilih</option>
                {items.map((i, index) => {
                  return (
                    <option key={index} value={i.nip}>{i.nama}</option>
                  )
                })}
              </FormSelect>
            </div>
            <div className="col-md-3">
              <FormInput
                label="bergabung"
                type="date"
                name="bergabung"
                value={form.bergabung}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            {user.nip === "2020091471" &&
              <div className="col-md-3">
                <FormInput
                  label="password"
                  type="password"
                  name="password"
                  onChange={(e) =>
                    setForm((prevState) => {
                      return { ...prevState, [e.target.name]: e.target.value };
                    })
                  }
                />
              </div>
            }
            <div className="col-md-3">
              <FormSelect
                label="Group"
                name="group"
                value={form.group ? form.group : ''}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              >
                <option value="">Pilih</option>
                <option value="Dir">Dir</option>
                <option value="Kacab">Kacab</option>
                <option value="Akademik">Akademik</option>
                <option value="SDM">SDM</option>
                <option value="Marketing">Marketing</option>
                <option value="PM">PM</option>
                <option value="PKK">PKK</option>
                <option value="Magang">Magang</option>
              </FormSelect>
            </div>
            <div className="col-md-3">
              <FormSelect
                label="Grade"
                name="grade"
                value={form.grade ? form.grade : ''}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              >
                <option value="1">Grade 1</option>
                <option value="2">Grade 2</option>
                <option value="3">Grade 3</option>
                <option value="4">Grade 4</option>
                <option value="5">Grade 5</option>
              </FormSelect>
            </div>
            <div className="col-md-3">
              <FormInput
                label="HP"
                name="no_hp"
                value={form.no_hp}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="col-md-3">
              <FormInput
                label="Jabatan"
                name="jabatan"
                value={form.jabatan}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
          </div>
          <div>
            <button className="btn btn-sm btn-primary-3 rounded-3 border-0">Simpan</button>
            <button
              type="button"
              className="btn btn-sm btn-dark rounded-3 border-0 ms-1"
              onClick={handleClose}
            >
              Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default EditMasterPegawai;
