import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchJurusan, showPojt } from "../../../api";
import PrintLayout, { PrintButton } from "../../../components/layouts/PrintLayout";


function Wow() {

    const { id } = useParams(); // pojt id

    const [pojt, setPojt] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const res = await showPojt(id);
            if (res.data && res.data.pojt) {
                setPojt(res.data.pojt);
            }
        };

        fetchData();
    }, [id]);

    const [jurusan, setJurusan] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetchJurusan("all");
            if (res.data) {
                setJurusan(res.data);
            }
        };

        fetchData();
    }, [id]);

    const [pojtJurusan, setPojtJurusan] = useState([]);

    useEffect(() => {
        if (pojt.pojt_jurusan && pojt.pojt_jurusan.length > 0) {
            let jurusanPojt = pojt.pojt_jurusan.map((pj) => pj.jurusan_id);
            let jurusanMhs = pojt.mhs.map((m) => m.jurusan_id);

            let jurusanFix = jurusanPojt.filter((jp) =>
                jurusanMhs.find((jm) => parseInt(jm) === parseInt(jp))
            );
            setPojtJurusan(jurusanFix);
        }
    }, [pojt]);

    const getPojtDua = (pojt) => {
        if (pojt.dari_2 && pojt.sampai_2) {
            return (
                <p className="m-0 p-0">
                    2 Bulan (Tanggal {pojt.dari_2.split("-").reverse().join("-")} s/d{" "}
                    {pojt.sampai_2.split("-").reverse().join("-")})
                </p>
            );
        }
    };

    const getPojtEmpat = (pojt) => {
        if (pojt.dari_4 && pojt.sampai_4) {
            return (
                <p className="m-0 p-0">
                    3 Bulan (Tanggal {pojt.dari_4.split("-").reverse().join("-")} s/d{" "}
                    {pojt.sampai_4.split("-").reverse().join("-")})
                </p>
            );
        }
    };

    const getPojtEnam = (pojt) => {
        if (pojt.dari_6 && pojt.sampai_6) {
            return (
                <p className="m-0 p-0">
                    6 Bulan (Tanggal {pojt.dari_6.split("-").reverse().join("-")} s/d{" "}
                    {pojt.sampai_6.split("-").reverse().join("-")})
                </p>
            );
        }
    };

    // const headerRef = useRef(null);
    // const headerImgRef = useRef(null);
    // const footerRef = useRef(null);
    // const footerImgRef = useRef(null);

    // useEffect(() => {

    //     const headerHeight = headerImgRef.current && headerImgRef.current.scrollHeight;
    //     const footerHeight = footerImgRef.current && footerImgRef.current.scrollHeight;

    //     headerRef.current.style.paddingBottom = headerHeight + "px";
    //     footerRef.current.style.paddingTop = footerHeight + "px";

    // }, [headerRef, footerRef, headerImgRef, footerImgRef]);

  return (
    <>  
        <PrintLayout header footer>
            <div contentEditable>
                <h1 className="fs-4 text-center fw-semibold mb-5">
                    PROPOSAL <i>ON THE JOB TRAINING</i>
                    <br />
                    LEMBAGA PENDIDIKAN MANAJEMEN DAN INFORMATIKA
                    <br />
                    ALFA PRIMA
                </h1>
                <ul className="print-ul">
                    <li>
                        {/* ==============================  BAGIAN I ================================= */}

                        <div className="d-flex items-center fw-semibold">
                            <p className="mb-0">I.</p>
                            <p className="ps-3">LATAR BELAKANG</p>
                        </div>
                        <div className="ps-5 mb-4">
                            <p className="text-indent text-justify">
                                Memasuki era industri yang semakin kompetitif, harus disadari
                                bahwa kompetensi individual seseorang memiliki peranan yang sangat
                                penting untuk memenangkan sebuah persaingan termasuk di dalamnya
                                adalah peserta didik. Peserta didik diharapkan memiliki berbagai
                                kompetensi yang diperlukan oleh dunia usaha, dunia industri dan
                                dunia kerja (IDUKA). Oleh karena itu sudah seharusnya Lembaga
                                Pendidikan Manajemen dan Informatika Alfa Prima menyiapkan para
                                peserta didiknya untuk memiliki berbagai kompetensi yang
                                dibutuhkan sehingga mampu terserap dengan baik di dunia kerja.
                                Berbagai kegiatan perlu dilakukan salah satunya melalui{" "}
                                <i>On the Job Training</i> Alfa Prima. <i>On the Job Training</i>{" "}
                                adalah salah satu mata kuliah keahlian di dalam struktur kurikulum
                                program pendidikan yang menggunakan upaya khusus penerapan terpadu
                                ilmu yang telah didapatkan sesuai dengan peminatan, dalam bentuk
                                melakukan kerja lapangan baik di perusahaan maupun di instansi
                                tertentu. <i>On the Job Training</i> ini juga merupakan salah satu
                                kegiatan wajib yang harus dipenuhi oleh peserta didik Alfa Prima.
                            </p>
                        </div>

                        {/* ==============================  BAGIAN II ================================= */}

                        <div className="d-flex items-center fw-semibold">
                            <p className="mb-0">II.</p>
                            <p className="ps-3">TUJUAN DAN MANFAAT</p>
                        </div>
                        <div className="ps-5 mb-4">
                            <ul className="print-ul ps-0">
                                <li>
                                    <p className="fw-semibold">a. Tujuan</p>
                                    <p className="ps-5 text-justify">
                                        Untuk memberikan pengalaman kerja kepada peserta didik dalam
                                        rangka menerapkan/membandingkan teori dan pengetahuan yang
                                        telah diterimanya di dalam perkuliahan atau praktikum dengan
                                        situasi nyata di tempat <i>On the Job Training</i>.
                                    </p>
                                </li>
                                <li>
                                    <p className="fw-semibold">b. Manfaat</p>
                                    <div className="ps-5">
                                        <p>Bagi Peserta Didik</p>

                                        <ol className="ps-5 mb-4">
                                            <li>Memahami kebutuhan pekerjaan di tempat <i>On the Job Training.</i></li>
                                            <li>Menyesuaikan diri terhadap dunia kerja</li>
                                            <li>Memahami penggunaan teknologi informasi dan komunikasi di tempat <i>On the Job Training.</i></li>
                                            <li>Mampu menyajikan hasil-hasil yang diperoleh selama <i>On the Job Training</i> dalam bentuk laporan tertulis.</li>
                                        </ol>
                                        <p>Bagi Perusahaan/Instansi</p>
                                        <ol className="ps-5 mb-4">
                                            <li>Dapat memberikan masukan berupa ide untuk menjalankan program kerja perusahaan</li>
                                            <li>Dapat membantu meringankan tugas-tugas perusahaan</li>
                                            <li>Dapat dijadikan sebagai bahan pertimbangan dalam pengambilan keputusan untuk kemajuan perusahaan di masa yang akan datang</li>
                                        </ol>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        {/* ==============================  BAGIAN III ================================= */}

                        <div className="d-flex items-center fw-semibold">
                            <p className="mb-0">III.</p>
                            <p className="ps-3">KEGIATAN <i>ON THE JOB TRAINING</i></p>
                        </div>

                        <div className="ps-5 mb-4">
                            <p>
                                Selama <i>On the Job Training</i> hal yang harus dilakukan oleh
                                peserta didik antara lain :
                            </p>
                            <ol className="ps-5">
                                <li>Pengenalan tempat <i>On the Job Training.</i></li>
                                <li>Pembuatan Rencana Kegiatan selama pelaksanaan <i>On the Job Training</i></li>
                                <li>Pelaksanaan dan penyelesaian tugas-tugas yang terdapat di tempat <i>On the Job Training</i></li>
                                <li>Melakukan permintaan data-data untuk kebutuhan laporan (profile perusahaan, struktur organisasi, tugas dan tanggung jawab di masing-masing bagian).</li>
                                <li>Pembuatan Laporan Tertulis hasil kegiatan <i>On the Job Training</i></li>
                                <li>Dokumentasi</li>
                            </ol>
                        </div>

                        {/* ==============================  BAGIAN IV ================================= */}

                        <div className="d-flex items-center fw-semibold">
                            <p className="mb-0">IV.</p>
                            <p className="ps-3">PELAKSANAAN</p>
                        </div>

                        <ul className="print-ul ps-5 mb-4">
                            <li>
                                <p className="fw-semibold">a. Tempat dan Waktu</p>
                                <p className="ps-5">Kegiatan <i>On the Job Training</i> ini dilaksanakan pada :</p>

                                <div className="ps-5 row">
                                    <div className="col-5">
                                        <p className="mb-0">Perusahaan/Instansi/ Lembaga</p>
                                        <p className="mb-0">Alamat</p>
                                    </div>
                                    <div className="col-1 text-center">
                                        <p className="px-4 mb-0">:</p>
                                        <p className="px-4 mb-0">:</p>
                                    </div>
                                    <div className="col-6">
                                        <p className="mb-0 w-100">{pojt.perusahaan && pojt.perusahaan.nama}</p>
                                        <p className="mb-0 w-100">{pojt.perusahaan && pojt.perusahaan.alamat}</p>
                                    </div>
                                </div>

                                <div className="ps-5 mt-4">
                                    <p className="mb-0">Program Jurusan Peserta Didik :</p>
                                    <table className="table table-sm my-3">
                                        <thead>
                                            <tr>
                                            <th rowSpan={2} className="align-middle text-center border border-silver">
                                                Jurusan
                                            </th>
                                            <th colSpan={3} className="text-center border border-silver">
                                                Mahasiswa
                                            </th>
                                            </tr>
                                            <tr>
                                            <th className="text-center border border-silver">NIM</th>
                                            <th className="text-center border border-silver">Nama</th>
                                            <th className="text-center border border-silver">Program</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pojtJurusan.map((pj, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                <tr>
                                                    <td className="border border-silver align-middle text-center">
                                                    {jurusan
                                                        .filter(
                                                        (j) => parseInt(j.id) === parseInt(pj)
                                                        )
                                                        .map((j) => j.nama)}
                                                    </td>
                                                    <td className="border border-silver align-middle text-center text-nowrap">
                                                    {pojt.mhs &&
                                                        pojt.mhs.length > 0 &&
                                                        pojt.mhs
                                                        .filter(
                                                            (m) =>
                                                            parseInt(m.jurusan_id) ===
                                                            parseInt(pj)
                                                        )
                                                        .map((m, index) => {
                                                            return (
                                                            <React.Fragment key={index}>
                                                                {m.nim}
                                                                <br />
                                                            </React.Fragment>
                                                            );
                                                        })}
                                                    </td>
                                                    <td className="border border-silver align-middle text-nowrap">
                                                    {pojt.mhs &&
                                                        pojt.mhs.length > 0 &&
                                                        pojt.mhs
                                                        .filter(
                                                            (m) =>
                                                            parseInt(m.jurusan_id) ===
                                                            parseInt(pj)
                                                        )
                                                        .map((m, index) => {
                                                            return (
                                                            <React.Fragment key={index}>
                                                                {m.nama}
                                                                <br />
                                                            </React.Fragment>
                                                            );
                                                        })}
                                                    </td>
                                                    <td className="border border-silver align-middle text-center text-nowrap">
                                                    {pojt.mhs &&
                                                        pojt.mhs.length > 0 &&
                                                        pojt.mhs
                                                        .filter(
                                                            (m) =>
                                                            parseInt(m.jurusan_id) ===
                                                            parseInt(pj)
                                                        )
                                                        .map((m, index) => {
                                                            return (
                                                            <React.Fragment key={index}>
                                                                {`${
                                                                m.jenjang === "D1"
                                                                    ? "1 Tahun"
                                                                    : "2 Tahun"
                                                                }`}
                                                                <br />
                                                            </React.Fragment>
                                                            );
                                                        })}
                                                    </td>
                                                </tr>
                                                </React.Fragment>
                                            );
                                            })}
                                        </tbody>
                                    </table>
                                </div>

                                <div className="ps-5 mt-3">
                                    <p className="mb-0">Unit Penempatan :</p>
                                    <table className="table table-sm my-3">
                                        <tbody>
                                            {pojt.pojt_jurusan &&
                                            pojt.pojt_jurusan.map((pj) => {
                                                return (
                                                <tr>
                                                    <td style={{width: '40%'}} className="text-center border border-silver align-middle mb-0">{jurusan &&
                                                    jurusan.filter((j) => parseInt(j.id) === parseInt(pj.jurusan_id)).map((j) => {
                                                        return j.nama
                                                    })
                                                    }
                                                    </td>
                                                    <td className="border border-silver" style={{width: '60%'}}>
                                                    {pj.posisi}
                                                    </td>
                                                </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="ps-5 mb-3">
                                    <p>Waktu Pelaksanaan :</p>
                                    <div className="ps-5">
                                        {getPojtEnam(pojt)}
                                        {getPojtEmpat(pojt)}
                                        {getPojtDua(pojt)}
                                    </div>
                                </div>

                                <p className="fw-semibold">b. Sasaran</p>
                                <p className="ps-5">
                                    <i>On the Job Training</i> ini ditujukan bagi peserta didik
                                    Program 1 Tahun dan 2 Tahun Alfa Prima yang telah menempuh
                                    minimal 90% dari total beban studi secara keseluruhan.
                                </p>

                                <p className="fw-semibold">c. Beban Satuan Kredit Semester (SKS)</p>
                                <p className="ps-5">
                                    <i>On the Job Training</i> setara dengan mata kuliah keahlian
                                    lain yang pengerjaannya dikenai persyaratan akademik dan
                                    mempunyai beban studi masing-masing sebesar 3 (tiga) dan/atau
                                    4 (empat) satuan kredit semester (SKS).
                                </p>

                                <p className="fw-semibold">d. Teknis Pembimbingan</p>
                                <ol className="ps-5 mb-4">
                                    <li>
                                        <p>Pembimbing Akademik</p>
                                        <div className="mb-4">
                                            <p>Pembimbing akademik merupakan instruktur/dosen yang ditunjuk
                                            oleh Kepala Bagian Akademik dan Kemahasiswaan Alfa Prima
                                            dengan tugas antara lain :</p>
                                            <ol className="ps-5">
                                                <li>
                                                    Melaksanakan bimbingan kepada peserta didik untuk
                                                    mencapai keterampilan yang telah ditentukan sebelum
                                                    pelaksanaan <i>On the Job Training.</i>
                                                </li>
                                                <li>
                                                    Melaksanakan bimbingan kepada peserta didik dalam
                                                    menganalisa permasalahan yang terdapat di tempat{" "}
                                                    <i>On the Job Training</i> sehingga dapat dituangkan
                                                    kedalam Laporan <i>On the Job Training.</i>
                                                </li>
                                            </ol>
                                        </div>
                                    </li>
                                    <li>
                                        <p>Pembimbing Lapangan</p>
                                        <ol className="ps-5">
                                            <li>
                                                Memberikan tugas-tugas perusahaan/instansi kepada
                                                peserta didik yang disesuaikan dengan kompetensi yang
                                                dimiliki oleh peserta didik.
                                            </li>
                                            <li>
                                                Menilai dan memberikan evaluasi kepada peserta didik
                                                yang meliputi pengetahuan sikap dan keterampilan peserta
                                                didik selama <i>On the Job Training.</i>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>

                                <p className="fw-semibold">e. Tata Tertib</p>
                                <div className="ps-5 mb-4">
                                    <p>Tata tertib selama <i>On the Job Training adalah sebagai berikut :</i></p>
                                    <ol className="ps-5">
                                        <li>Peserta didik wajib menaati peraturan yang berlaku di tempat <i>On the Job Training.</i></li>
                                        <li>Peserta didik wajib datang tepat waktu sesuai jadwal yang ditentukan di tempat <i>On the Job Training.</i></li>
                                        <li>Peserta didik wajib mengisi daftar hadir yang disediakan oleh pihak Alfa Prima maupun perusahaan/instansi tempat melaksanakan <i>On the Job Training.</i></li>
                                        <li>Peserta didik menggunakan pakaian rapi sesuai dengan aturan yang berlaku di tempat <i>On the Job Training.</i></li>
                                        <li>Peserta didik diperkenankan izin/meninggalkan tugas <i>On the Job Training</i> dengan menyerahkan Surat Keterangan Dokter apabila dalam kondisi sakit dan Surat Ijin yang diketahui dan ditandatangani Orang Tua/Wali apabila berhalangan hadir karena kepentingan pribadi</li>
                                    </ol>
                                </div>

                                <p className="fw-semibold">f. Sanksi</p>
                                <ol className="ps-5 mb-4">
                                    <li>Apabila terjadi pelanggaran terhadap tata tertib yang berlaku maka akan dikenai sanksi oleh akademik menurut berat ringannya jenis pelanggaran.</li>
                                    <li>Apabila peserta didik merusak atau menghilangkan sarana dan prasarana yang digunakan selama <i>On the Job Training</i> makan peserta didik wajib untuk menggantinya.</li>
                                </ol>

                                <p className="fw-semibold">g. Penilaian dan Absensi</p>
                                <p className="ps-5">Dalam pelaksaan <i>On the Job Training</i> seluruh peserta didik akan dibekali formulir penilaian dan absensi yang selanjutnya akan diberikan menyusul setelah peserta didik diterima <i>On the Job Training</i> di perusahaan/ instansi yang dituju.</p>
                            </li>
                        </ul>

                        {/* ==============================  BAGIAN IV ================================= */}

                        <div className="d-flex items-center fw-semibold">
                            <p className="mb-0">V.</p>
                            <p className="ps-3">PENUTUP</p>
                        </div>

                        <p className="ps-5">
                            Demikianlah proposal ini dibuat, besar harapannya untuk peserta
                            didik kami dapat diterima melaksanakan <i>On the Job Training</i>{" "}
                            di perusahaan yang bapak/ibu pimpin. Atas perhatiannya kami
                            mengucapkan terima kasih.
                        </p>
                    </li>
                </ul>
            </div>
            <PrintButton/>
        </PrintLayout>
    </>
  )
}

export default Wow