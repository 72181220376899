import React, { useState, useRef } from "react";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import FormInput from "../../components/forms/Input";
import { postAspekBsc } from "../../api";
import { saAlert } from "../../helpers";
import { LayoutAddBtn } from "../../components/layouts/TopContent";

const AspekBscCreate = ({ onFetchData }) => {
  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
    setForm({});
    setErrors({});
  };

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      const res = await postAspekBsc(form);
      if (res.data && res.data.status) {
        onFetchData();
        handleClose();
        saAlert(res.data.status, res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <>
      <LayoutAddBtn onClick={handleOpen} add="Tambah Aspek BSC" />
      <LayoutModal
        title="Tambah Data Aspek BSC"
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
          <div className="row">
            <div className="col-md">
              <FormInput
                label="kode"
                name="kode"
                value={form.kode}
                onChange={handleChange}
                error={errors.kode}
              />
            </div>
            <div className="col-md">
              <FormInput
                label="nama"
                name="nama"
                value={form.nama}
                onChange={handleChange}
                error={errors.nama}
              />
            </div>
            <div className="col-md">
              <FormInput
                label="type"
                name="type"
                value={form.type}
                onChange={handleChange}
                error={errors.type}
              />
            </div>
          </div>
          <div className="row mt-3">
            <NewSubmitButton onClose={handleClose}/>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default AspekBscCreate;
