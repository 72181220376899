import React, { useState } from "react";
import { useRef } from "react";
import { patchBukuSakuKegiatan } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";
import SmallButton from "../../components/forms/SmallButton";

const BukuSakuKegiatanEdit = ({ bukuSakuKegiatan, onFetchData }) => {
  const titleModal = "Ubah Kegiatan Buku Saku";
  const tagForm = useRef(null);

  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setForm(bukuSakuKegiatan);
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      const res = await patchBukuSakuKegiatan(form.id, form);
      if (res.data && res.data.msg && res.data.msg === "success") {
        onFetchData();
        handleClose();
        saAlert("success", `Berhasil ${titleModal}`);
      }
    } catch (err) {
      if (err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  return (
    <>
      <SmallButton type="edit" onClick={handleOpen}/>

      <LayoutModal modal={modal} title={titleModal} onClose={handleClose}>
        <form ref={tagForm} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-6">
              <FormInput
                label="Judul"
                name="judul"
                value={form.judul}
                onChange={handleChange}
                error={errors.judul}
              />
            </div>
            <div className="col-6">
              <FormSelect
                label="Jenjang"
                name="jenjang"
                value={form.jenjang}
                onChange={handleChange}
                error={errors.jenjang}
              >
                <option value="">Pilih</option>
                <option value="D1">D1</option>
                <option value="D2">D2</option>
              </FormSelect>
            </div>
            <div className="col-6">
              <FormSelect
                label="Bulan"
                name="bulan"
                value={form.bulan}
                onChange={handleChange}
                error={errors.bulan}
              >
                <option value="">Pilih</option>
                <option value="01">Januari</option>
                <option value="02">Februari</option>
                <option value="03">Maret</option>
                <option value="04">April</option>
                <option value="05">Mei</option>
                <option value="06">Juni</option>
                <option value="07">Juli</option>
                <option value="08">Agustus</option>
                <option value="09">September</option>
                <option value="10">Oktober</option>
                <option value="11">November</option>
                <option value="12">Desember</option>
              </FormSelect>
            </div>
            <div className="col-6">
              <FormTextarea
                label="Keterangan"
                name="keterangan"
                value={form.keterangan}
                onChange={handleChange}
                error={errors.keterangan}
              />
            </div>
            <div className="col-6">
              <FormSelect
                label="Status"
                name="status"
                value={form.status}
                onChange={handleChange}
                error={errors.status}
              >
                <option value="">Pilih</option>
                <option value="1">Aktif</option>
                <option value="9">Nonaktif</option>
              </FormSelect>
            </div>
          </div>
          <div className="row mt-2">
            <NewSubmitButton submitText="Simpan Perubahaan" onClose={handleClose}/>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default BukuSakuKegiatanEdit;
