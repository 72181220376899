import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../axios";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterBulan,
  defaultFilterTahun,
  FilterBulan,
  FilterTahun,
} from "../../components/layouts/TopContent";
import LoadingPage from "../../components/pages/Loading";
import NoDataPage from "../../components/pages/NoData";
import { formatDate, formatDiffDate, saAlert, saConfirm } from "../../helpers";
import { tokenState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const MeninggalkanTugasApproval = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState(false);

  const [items, setItems] = useState();
  const [page] = useState("all");
  const [bulan, setBulan] = useState(defaultFilterBulan);
  const [tahun, setTahun] = useState(defaultFilterTahun);

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(
        "/api/meninggalkan-tugas/show" +
          "?page=" +
          page +
          "&bulan=" +
          bulan +
          "&tahun=" +
          tahun +
          "&approval=true",
        { headers: { Authorization: "Bearer " + token } }
      );
      if (res.data && res.data.meninggalkanTugas) {
        setItems(res.data.meninggalkanTugas);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [page, bulan, tahun, token]);

  useEffect(() => {
    setTitle("Approve Meninggalkan Tugas");

    fetchData();
  }, [setTitle, fetchData]);

  const handleUpdate = (id, status, message) => {
    // saConfirm("warning", `Yakin ${message} meninggalkan tugas ?`).then(
    //   (res) => {
    //     if (res.isConfirmed) {
          axios
            .patch(
              "/api/meninggalkan-tugas/" + id,
              { status },
              { headers: { Authorization: "Bearer " + token } }
            )
            .then((res) => {
              if (res.data && res.data.msg === "success") {
                fetchData();
                // saAlert("success", `Berhasil ${message} meninggalkan tugas.`);
              }
            })
            .catch((err) => console.log(err));
    //     }
    //   }
    // );
  };

  if (isLoaded) {
    return (
      <>
        <div className="row mb-2">
          <div className="col-md">
            <FilterBulan onChange={(res) => setBulan(res)} />
          </div>
          <div className="col-md">
            <FilterTahun onChange={(res) => setTahun(res)} />
          </div>
        </div>

        {items.length >= 1 ? (
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>Cabang</Th>
                <Th>NIP</Th>
                <Th>Nama</Th>
                <Th>Jenis</Th>
                <Th>Dari</Th>
                <Th>Sampai</Th>
                <Th>Lama</Th>
                {/* <Th>Pekerjaan</Th> */}
                <Th>Alasan</Th>
                <Th>Status</Th>
                <Th>Oleh</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center align-middle">{1 + index}</Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.cabang}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.nip}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.nama}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {parseInt(i.status_keterangan) === 1 && <span className="fw-semibold text-warning-2">Setengah Hari</span>}
                      {parseInt(i.status_keterangan) === 2 && <span className="fw-semibold text-success-2">Beberapa Jam</span>}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatDate(i.dari)}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatDate(i.sampai)}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatDiffDate(i.lama)}
                    </Td>
                    {/* <Td className="align-middle">{i.pekerjaan}</Td> */}
                    <Td className="align-middle">{i.keterangan}</Td>
                    <Td className="text-center align-middle">
                      {parseInt(i.status) === 1 && (
                        <span className="text-success-2 fw-semibold">Approved</span>
                      )}
                      {parseInt(i.status) === 2 && (
                        <span className="text-danger-2 fw-semibold">Rejected</span>
                      )}
                      {!parseInt(i.status) && (
                        <div>
                          <button
                            onClick={() => handleUpdate(i.id, 1, "Approve")}
                            type="button"
                            className="btn btn-sm btn-success-2 text-sm w-100 py-0 mb-1"
                          >
                            Menyetujui
                          </button>
                          <button
                            onClick={() => handleUpdate(i.id, 2, "Reject")}
                            type="button"
                            className="btn btn-sm btn-danger-2 text-sm w-100 py-0"
                          >
                            Tolak
                          </button>
                        </div>
                      )}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.atasan.nama}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <NoDataPage />
        )}
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

export default MeninggalkanTugasApproval;
