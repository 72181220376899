import React, { useState, useRef } from "react";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import LayoutModal from "../../components/layouts/Modal";
import FormInput from "../../components/forms/Input";
import { postAbsensiPegawai, postMorningReport } from "../../api";
import { saAlert } from "../../helpers";

const MorningReportCreate = ({ onFetchData }) => {
  const tagForm = useRef(null);

  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const resetForm = () => {
    tagForm.current && tagForm.current.reset();
    setForm({});
    setErrors({});
  };

  const handleClose = () => {
    onFetchData();
    resetForm();
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await postMorningReport(form);
      if (res.data && res.data.status === "success") {
        onFetchData();
        handleClose();
        saAlert("success", res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <div>
      <LayoutAddBtn 
        onClick={() => setModal(true)} 
        // modalButton
        // dataBsTarget="createDailyReport"
        add="Buat Daily Report" 
      />
      {/* <button className="btn btn-sm btn-success-2 py-0 text-sm mx-1" data-bs-toggle="modal" data-bs-target="#tambahList">
        <i className="fa fa-fw fa-plus fa-sm"/> Buat Daily Report
      </button> */}

      <LayoutModal
        // id="createDailyReport"
        title="Buat Daily Report"
        size="sm"
        // btnClose={false}
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
          <FormInput
            label="Tanggal"
            type="date"
            name="tanggal"
            onChange={handleChange}
            error={errors.tanggal}
          />
          <button data-bs-dismiss="modal" type="submit" className="btn btn-sm btn-success-2 w-100">Simpan</button>
        </form>
      </LayoutModal>
    </div>
  );
};

export default MorningReportCreate;
