import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchKs } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterCabang,
  FilterPagination,
  FilterSearch,
} from "../../components/layouts/TopContent";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import KsEdit from "./Edit";

const Ks = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);

  useEffect(() => {
    setTitle("Kritik & Saran");
  }, [setTitle]);

  const [ks, setKs] = useState({});

  const [page, setPage] = useState(1);
  const [paginate, setPaginate] = useState(10);
  const [search, setSearch] = useState("");
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchKs(
        page,
        `paginate=${paginate}&search=${search}&cabang=${cabang}&mhs=true`
      );
      if (res.data) {
        setKs(res.data.ks);
      }
    } catch (err) {
      console.error(err);
    }
  }, [page, paginate, search, cabang]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="row mb-2">
        <div className="col-md-4">
          {user.cabang === "pusat" && (
            <FilterCabang onChange={(res) => setCabang(res)} />
          )}
        </div>
        <div className="col-md-4">
          <FilterPagination onChange={(res) => setPaginate(res)} />
        </div>
        <div className="col-md-4">
          <FilterSearch onChange={(res) => setSearch(res)} />
        </div>
      </div>
      {ks.data && ks.data.length > 0 ? (
        <LayoutTable>
          <LayoutThead>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Cabang</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No HP</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Bagian</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Judul</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Status</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
              <i className="fa fa-fw fa-cog" />
            </th>
          </LayoutThead>
          <tbody>
            {ks.data.map((k, index) => {
              return (
                <tr key={index}>
                  <td className="py-1 border-silver text-center text-nowrap">{ks.from + index}</td>
                  <td
                    className="py-1 border-silver text-center text-nowrap"
                    style={{ textTransform: "capitalize" }}
                  >
                    {k.cabang}
                  </td>
                  <td className="py-1 border-silver text-center text-nowrap">{k.no_hp}</td>
                  <td className="py-1 border-silver text-nowrap">{k.bagian}</td>
                  <td className="py-1 border-silver text-nowrap">
                    {k.judul.length > 40 ? `${k.judul.substring(0, 40)} . . .` : k.judul}
                  </td>
                  <td className="py-1 border-silver text-center text-nowrap">
                    {k.status === null && <i className="fa fa-fw fa-circle-xmark text-danger"/>}
                    {parseInt(k.status) === 1 && <i className="fa fa-fw fa-circle-check text-success-2"/>}
                  </td>
                  <td className="py-1 border-silver text-center text-nowrap">
                    <KsEdit ks={k} onFetchData={fetchData} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </LayoutTable>
      ) : (
        <PageNoData />
      )}

      {ks.links && ks.links.length > 0 && (
        <SectionPagination
          links={ks.links}
          onChangePage={(res) => setPage(res)}
        />
      )}
    </>
  );
};

export default Ks;
