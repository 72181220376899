import React, { useRef, useState } from "react";
import { postMatkul } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import { saAlert } from "../../helpers";

const MatkulCreate = ({ kelompokMatkul, jurusan, onFetchData }) => {
  const tagForm = useRef(null);

  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    if (kelompokMatkul.length >= 1) {
      setModal(true);
    } else {
      saAlert(
        "warning",
        "Sebelum menambahkan mata kuliah, pastikan anda sudah menambahkan kelompok mata kuliah terlebih dahulu",
        "Proses ini dapat dilanjutkan apabila terdapat setidaknya satu kelompok mata kuliah"
      );
    }
  };

  const handleClose = () => {
    if (tagForm && tagForm.current) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    try {
      const res = await postMatkul(form);
      if (res.data === "success") {
        onFetchData();
        handleClose();
        saAlert("success", "Data berhasil diinput kedalam sistem");
      }
    } catch (err) {
      if (err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  return (
    <>
      <LayoutAddBtn add="Tambah Data" onClick={handleOpen} />
      <LayoutModal
        title="Tambah Data Mata Kuliah"
        modal={modal}
        onClose={handleClose}
      >
        <form ref={tagForm} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-md">
              <FormInput
                type="text"
                label="Kode Matkul"
                name="kode"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.kode}
              />
            </div>
            <div className="col-md">
              <FormInput
                type="text"
                label="Mata Kuliah"
                name="nama"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.nama}
              />
            </div>
          </div>
          <FormInput
            label="RPP Link"
            name="rpp_link"
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.rpp_link}
          />
          <FormInput
            label="Modul Link"
            name="modul_link"
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.modul_link}
          />
          <FormInput
            label="Bahan Ajar Link"
            name="bahan_ajar_link"
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.bahan_ajar_link}
          />
          <div className="row">
            <div className="col-md">
              <FormSelect
                label="Kelompok Mata Kuliah"
                name="kelompok_matkul_id"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.kelompok_matkul_id}
              >
                <option value="">Pilih Kelompok Mata Kuliah</option>
                {kelompokMatkul.length >= 1 &&
                  kelompokMatkul.map((i, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={i.id}>{i.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col-md">
              <FormInput
                type="number"
                label="SKS"
                name="sks"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.sks}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <FormSelect
                label="Jenis Penilaian"
                name="jenis_penilaian_id"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.jenis_penilaian_id}
              >
                <option value="">Pilih Jenis Penilaian</option>
                <option value="1">Umum</option>
                <option value="2">Per Jenjang</option>
                <option value="3">Mutlak</option>
                <option value="4">D2</option>
              </FormSelect>
            </div>
            <div className="col-md">
              <FormSelect
                label="Jenis Matkul"
                name="jenis_matkul"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.jenis_matkul}
              >
                <option value="">Pilih</option>
                <option value="1">Praktek</option>
                <option value="2">Teori</option>
              </FormSelect>
            </div>
            <div className="col-md">
              <FormSelect
                label="Jurusan"
                name="jurusan_id"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.jurusan_id}
              >
                <option value="">Umum</option>
                {jurusan.length > 0 &&
                  jurusan.map((j, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={j.id}>{j.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
          </div>
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success-2">
              Tambah
            </button>
            <button
              type="button"
              className="btn btn-sm btn-dark border-0"
              onClick={handleClose}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default MatkulCreate;
