import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../axios";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterBulan,
  defaultFilterTahun,
  FilterBulan,
  FilterTahun,
} from "../../components/layouts/TopContent";
import LoadingPage from "../../components/pages/Loading";
import NoDataPage from "../../components/pages/NoData";
import { formatDate, formatDiffDate } from "../../helpers";
import { tokenState } from "../../storage/auth";
import { titleState } from "../../storage/pages";

const LemburApproval = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState(false);

  const [items, setItems] = useState();
  const [page] = useState("all");
  const [bulan, setBulan] = useState(defaultFilterBulan);
  const [tahun, setTahun] = useState(defaultFilterTahun);

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(
        "/api/lembur/show" +
          "?page=" +
          page +
          "&bulan=" +
          bulan +
          "&tahun=" +
          tahun +
          "&approval=true",
        { headers: { Authorization: "Bearer " + token } }
      );
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page, bulan, tahun, token]);

  useEffect(() => {
    setTitle("Approve Lembur");

    fetchData();
  }, [setTitle, fetchData]);

  const handleUpdate = (id, status, message) => {
    // saConfirm("warning", `Yakin ${message} lembur ?`).then((res) => {
    //   if (res.isConfirmed) {
    axios
      .patch(
        "/api/lembur/" + id,
        { status },
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((res) => {
        fetchData();
        // saAlert("success", `Berhasil ${message} lembur.`);
      })
      .catch((err) => console.log(err));
    //   }
    // });
  };

  if (isLoaded) {
    return (
      <>
        <div className="row mb-2">
          <div className="col-md">
            <FilterBulan onChange={(res) => setBulan(res)} />
          </div>
          <div className="col-md">
            <FilterTahun onChange={(res) => setTahun(res)} />
          </div>
        </div>










        <div className="text-white bg-gradient-primary border-bottom-warning rounded-3 p-4 text-justify my-3">
          <div className="position-relative border border-secondary rounded p-3">
            <div className="text-animate position-absolute bg-gradient-warning text-black rounded fw-semibold px-3" style={{rotate: '-4deg', left: '-15px', top: '-10px'}}><i className="fa fa-fw fa-bell fa-shake"/> Informasi Penting !</div>
            <h6 className="mb-0 mt-2">
              " Diharapkan untuk seluruh Atasan yang melakukan approval terhadap lembur
              untuk memastikan apakah karyawan yang mengajukan lembur sudah sesuai
              dengan aturan yang sudah ditetapkan! "
            </h6>
          </div>
        </div>

        {items.lembur.length >= 1 ? (
          <LayoutTable>
            <LayoutThead className="text-nowrap">
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Cabang</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">NIP</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Nama</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Jenis</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Dari</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Sampai</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Lama</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Pekerjaan</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Alasan</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Type Lembur</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Status</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Oleh</th>
            </LayoutThead>
            <tbody>
              {items.lembur.map((i, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center align-middle">{1 + index}</td>
                    <td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.cabang}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.nip}
                    </td>
                    <td className="text-center align-middle">
                      {i.pegawai && i.pegawai.nama}
                    </td>
                    <td className="text-center align-middle">
                      {parseInt(i.status_keterangan) === 1 && (
                        <span className="text-success-2 fw-semibold">Hari Normal</span>
                      )}
                      {parseInt(i.status_keterangan) === 2 && (
                        <span className="text-danger fw-semibold">Hari Libur</span>
                      )}
                      {parseInt(i.status_keterangan) === 3 && (
                        <span className="text-info fw-semibold">Pagi Hari</span>
                      )}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {formatDate(i.dari)}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {formatDate(i.sampai)}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {formatDiffDate(i.lama)}
                    </td>
                    <td className="align-middle">{i.pekerjaan}</td>
                    <td className="align-middle">{i.keterangan}</td>
                    <td className="text-center align-middle">
                      {parseInt(i.type_lembur) === 1 && "Lembur Jabatan"}
                      {parseInt(i.type_lembur) === 2 &&
                        "Lembur Kegiatan Mahasiswa"}
                      {parseInt(i.type_lembur) === 3 && "Lembur Kepanitiaan"}
                      {parseInt(i.type_lembur) === 4 &&
                        "Lembur Menghadiri Rapat atau Undangan Kegiatan"}
                    </td>
                    <td className="text-center align-middle">
                      {parseInt(i.status) === 1 && (
                        <span className="text-success-2 fw-semibold">Approved</span>
                      )}
                      {parseInt(i.status) === 2 && (
                        <span className="text-danger-2 fw-semibold">Rejected</span>
                      )}
                      {!parseInt(i.status) && (
                        <div>
                          <button
                            onClick={() => handleUpdate(i.id, 1, "Approve")}
                            type="button"
                            className="btn btn-sm btn-success-2 text-sm w-100 py-0 mb-1"
                          >
                            Menyetujui
                          </button>
                          <button
                            onClick={() => handleUpdate(i.id, 2, "Reject")}
                            type="button"
                            className="btn btn-sm btn-danger-2 text-sm w-100 py-0"
                          >
                            Tolak
                          </button>
                        </div>
                      )}
                    </td>
                    <td className="text-center align-middle">
                      {i.atasan.nama}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </LayoutTable>
        ) : (
          <NoDataPage />
        )}
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

export default LemburApproval;
