import React, { useState } from 'react';
import SmallButton from '../../components/forms/SmallButton';
import LayoutModal from '../../components/layouts/Modal';
import { getOrigin, namaBulan } from '../../helpers';
import axios from '../../axios';
import LoadingBasic from '../../components/pages/LoadingBasic';

function ShowHonor({ dosen, tahunAjaran }) {
    const [modal, setModal] = useState(false);
    const [files, setFiles] = useState(Array(12).fill(false));
    const [loading, setLoading] = useState(true);
    const [loadingStates, setLoadingStates] = useState(Array(12).fill(true));

    const fetchFiles = async () => {
        try {
            const fetchedFiles = Array(12).fill(false);
            for (let i = 1; i <= 12; i++) {
                try {
                    setLoadingStates(prevLoadingStates => {
                        const newLoadingStates = [...prevLoadingStates];
                        newLoadingStates[i - 1] = true; // Set loading true untuk bulan ini
                        return newLoadingStates;
                    });

                    const response = await axios.get(`${getOrigin()}/api/storage/dosen/slip-gaji/${tahunAjaran}/${i}/${dosen.nip}.pdf`);
                    if (response.status === 200) {
                        const monthIndex = i - 1; // Convert month number to array index
                        fetchedFiles[monthIndex] = true; // Set file status
                    }
                } catch (error) {
                    // Ignore error if file is not found
                    console.error('File not found for month', i, error);
                } finally {
                    setLoadingStates(prevLoadingStates => {
                        const newLoadingStates = [...prevLoadingStates];
                        newLoadingStates[i - 1] = false; // Set loading false setelah selesai mengambil file
                        return newLoadingStates;
                    });
                    setFiles(fetchedFiles);
                }
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching files:', error);
            setLoading(false);
        }
    };

    const handleOpen = () => {
        setModal(true);
        fetchFiles();
    };
    const handleClose = () => {
        setModal(false);
        setFiles(Array(12).fill(false));
        setLoadingStates(Array(12).fill(false));
    };

    return (
        <>
            <SmallButton onClick={handleOpen} margin="me-1" type="custom" buttonColor="dark" icon="h" title="Lihat honor" />

            <LayoutModal title={`Honor dosen: ${dosen.nama}`} modal={modal} onClose={handleClose}>
                <div className='mb-3'>
                    <table>
                        <tbody>
                            <tr>
                                <td className='fs-6 text-black'>Tahun Ajaran</td>
                                <td className='fs-6 fw-medium text-black px-3'>:</td>
                                <td className='fs-6 fw-semibold text-black'>{tahunAjaran}</td>
                            </tr>
                            <tr>
                                <td className='fs-6 text-black'>NIP</td>
                                <td className='fs-6 fw-medium text-black px-3'>:</td>
                                <td className='fs-6 fw-semibold text-black'>{dosen.nip}</td>
                            </tr>
                            <tr>
                                <td className='fs-6 text-black'>Dosen</td>
                                <td className='fs-6 fw-medium text-black px-3'>:</td>
                                <td className='fs-6 fw-semibold text-black'>{dosen.nama}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="row px-2">
                {files.map((file, index) => (
                    <div key={index} className="col-md-6 col-lg-4 col-xl-2 p-1">
                        <div className="ratio ratio-1x1 rounded-3 bg-gradient-primary text-white">
                            <div className="w-100 h-100 d-flex justify-content-center align-items-center p-2">
                                {loadingStates[index] ? (
                                    <p className='mb-0'><i className='fa fa-fw fa-spinner fa-spin'/> Loading...</p>
                                ) : (
                                    <div>
                                        <h5 className="mb-0 fw-semibold text-center">Honor</h5>
                                        <p>Bulan : {namaBulan[index]}</p>
                                        <button
                                            className={`btn btn-sm ${file ? 'btn-warning-2' : 'btn-secondary'} fw-medium`}
                                            type="button"
                                            disabled={file ? false : true}
                                            onClick={() => {
                                                if (file) {
                                                    window.open(`${getOrigin()}/api/storage/dosen/slip-gaji/${tahunAjaran}/${index + 1}/${dosen.nip}.pdf`);
                                                }
                                            }}
                                        >
                                            <i className="fa fa-fw fa-file-pdf" /> {file ? 'Buka File' : 'File kosong'}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </LayoutModal>
        </>
    );
}

export default ShowHonor;