import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CeklistMonitoringContext } from "../Routes";
import { namaBulan } from "../../../helpers";

const Laporan = () => {
  const { id } = useParams();

  const { ceklistMonitoring, cabang, departemen, auditor } = useContext(
    CeklistMonitoringContext
  );

  const [auditee, setAuditee] = useState([]);
  const [detailCeklistMonitoring, setDetailCeklistMonitoring] = useState({});

  useEffect(() => {
    let data = ceklistMonitoring.data.filter(
      (cm) => parseInt(cm.id) === parseInt(id)
    );

    setDetailCeklistMonitoring(data[0] ? data[0] : {});
    console.log(data[0] ? data[0] : {});

    if (data.length > 0) {
      data = data[0].auditee.map((a) => parseInt(a.id));
      setAuditee(data);
    }
  }, [setAuditee, ceklistMonitoring.data, id]);

  const [form, setForm] = useState([]);

  useEffect(() => {
    let data = ceklistMonitoring.data.filter(
      (cm) =>
        parseInt(cm.id) === parseInt(id) &&
        cm.ceklist_monitoring_list &&
        cm.ceklist_monitoring_list.length > 0
    );

    if (data.length > 0) {
      data = data[0].ceklist_monitoring_list.map((cml) => cml);
      setForm(data);
    }
  }, [setForm, ceklistMonitoring.data, id]);

  return (
    <div className="bg-white">
      <div className="container py-5">
        <div className="text-center">
          <h1 className="fw-bold fs-4">LAPORAN</h1>
          <h1 className="fw-bold fs-4">
            KEGIATAN{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "MONITORING"
              : "AMI"}{" "}
            TAHAP {detailCeklistMonitoring.tahap}
          </h1>
        </div>
        <div className="col-lg-11 mt-5 pt-5">
          <h2 className="fs-5 fw-bold text-center">
            Ketua Auditor :{" "}
            {auditor.length > 0 &&
              auditor
                .filter(
                  (a) =>
                    parseInt(a.id) ===
                    parseInt(detailCeklistMonitoring.auditor_id)
                )
                .map((a) => a.nama)}
          </h2>
          <h2 className="fs-5 fw-bold text-center">Anggota :</h2>
          {detailCeklistMonitoring.auditee &&
            detailCeklistMonitoring.auditee.length > 0 &&
            detailCeklistMonitoring.auditee.map((a) => {
              return (
                <h2 className="fs-5 fw-normal text-center" key={a.id}>
                  {a.nama}
                </h2>
              );
            })}
        </div>
        <div className="col-lg-11 my-5 py-5">
          <h2
            className="fs-5 fw-bold text-center"
            style={{ textTransform: "uppercase" }}
          >
            PUSAT PENJAMINAN MUTU ALFA PRIMA CABANG{" "}
            {cabang.length > 0 &&
              cabang
                .filter(
                  (c) =>
                    parseInt(c.id) ===
                    parseInt(detailCeklistMonitoring.cabang_id)
                )
                .map((c) => c.nama)}{" "}
            {detailCeklistMonitoring.bulan &&
              namaBulan[parseInt(detailCeklistMonitoring.bulan) - 1]}{" "}
            {detailCeklistMonitoring.tahun}
          </h2>
        </div>
        <div className="col-lg-11 mt-5 pt-5">
          <h2 className="fs-5 fw-bold text-center">
            LAPORAN{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "MONITORING"
              : "AMI"}{" "}
            TAHAP {detailCeklistMonitoring.tahap}
          </h2>
          <p className="justify mt-4">
            Kegiatan{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "Monitoring"
              : "AMI"}{" "}
            Tahap {detailCeklistMonitoring.tahap} Alfa Prima Cabang{" "}
            {cabang.length > 0 &&
              cabang
                .filter(
                  (c) =>
                    parseInt(c.id) ===
                    parseInt(detailCeklistMonitoring.cabang_id)
                )
                .map((c) => c.nama)}
            . dilaksanakan pada tanggal{" "}
            {/* {detailCeklistMonitoring.tanggal && (
              <>
                {detailCeklistMonitoring.tanggal.split("-")[2]}{" "}
                {
                  namaBulan[
                    parseInt(detailCeklistMonitoring.tanggal.split("-")[1])
                  ]
                }{" "}
                {detailCeklistMonitoring.tanggal.split("-")[0]}
              </>
            )}{" "} */}
            pada pukul 09.00 WITA. Kegiatan{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "Monitoring"
              : "AMI"}{" "}
            Tahap {detailCeklistMonitoring.tahap} dilaksanakan secara offline di
            Alfa Prima Cabang{" "}
            {cabang.length > 0 &&
              cabang
                .filter(
                  (c) =>
                    parseInt(c.id) ===
                    parseInt(detailCeklistMonitoring.cabang_id)
                )
                .map((c) => c.nama)}{" "}
            . Secara teknis pelaksanaan kegiatan{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "Monitoring"
              : "AMI"}{" "}
            Tahap {detailCeklistMonitoring.tahap}
            dibedakan berdasarkan 5 divisi/ bagian, antara lain: (1) Kepala
            Cabang{" "}
            {cabang.length > 0 &&
              cabang
                .filter(
                  (c) =>
                    parseInt(c.id) ===
                    parseInt(detailCeklistMonitoring.cabang_id)
                )
                .map((c) => c.nama)}
            ;(2) Akademik dan Kemahasiswaan; (3) Keuangan dan Sumber Daya; (4)
            Humas dan Pemasaran; (5) Pusat Karier dan Kerjasama. Metode yang
            digunakan dalam kegiatan{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "Monitoring"
              : "AMI"}{" "}
            Tahap {detailCeklistMonitoring.tahap} adalah metode observasi,
            wawancara, dan berkas.{" "}
          </p>
        </div>
        <div className="col-lg-11 mt-2">
          <p className="justify mt-4">
            Adapun tujuan dari{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "Monitoring"
              : "AMI"}{" "}
            Tahap {detailCeklistMonitoring.tahap}, antara lain :
          </p>
          <ol className="justify">
            <li>
              Melakukan konfirmasi terkait dengan sistem penjaminan mutu yang
              telah dijalankan.
            </li>
            <li>
              Melakukan konfirmasi terkait pelaksanaan teknis, prosedur, dan
              strategis yang dijalankan oleh Cabang{" "}
              {cabang.length > 0 &&
                cabang
                  .filter(
                    (c) =>
                      parseInt(c.id) ===
                      parseInt(detailCeklistMonitoring.cabang_id)
                  )
                  .map((c) => c.nama)}
            </li>
            <li>
              Melakukan{" "}
              {parseInt(detailCeklistMonitoring.jenis) === 1
                ? "Monitoring"
                : "AMI"}{" "}
              Tahap {detailCeklistMonitoring.tahap} terkait kesesuaian dan
              ketidaksesuaian yang terjadi Alfa Prima Cabang{" "}
              {cabang.length > 0 &&
                cabang
                  .filter(
                    (c) =>
                      parseInt(c.id) ===
                      parseInt(detailCeklistMonitoring.cabang_id)
                  )
                  .map((c) => c.nama)}
            </li>
          </ol>
        </div>
        <div className="col-lg-11 mt-2">
          <p className="justify mt-4">
            Kegiatan{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "Monitoring"
              : "AMI"}{" "}
            Tahap {detailCeklistMonitoring.tahap} dapat dilaksanakan sesuai
            dengan agenda telah direncanakan. Adapun hasil dari Audit Mutu
            Internal yaitu ditemukan{" "}
            {detailCeklistMonitoring.ceklist_monitoring_list &&
              detailCeklistMonitoring.ceklist_monitoring_list.length > 0 &&
              detailCeklistMonitoring.ceklist_monitoring_list.filter(
                (cml) => cml.pivot && cml.pivot.ts
              ).length}{" "}
            ketidaksesuaian dan{" "}
            {detailCeklistMonitoring.ceklist_monitoring_list &&
              detailCeklistMonitoring.ceklist_monitoring_list.length > 0 &&
              detailCeklistMonitoring.ceklist_monitoring_list.filter(
                (cml) => cml.pivot && cml.pivot.sr
              ).length}{" "}
            saran perbaikan. Secara garis besar, temuan berkaitan dengan :
            <ol>
              {detailCeklistMonitoring.ceklist_monitoring_list &&
                detailCeklistMonitoring.ceklist_monitoring_list.length > 0 &&
                detailCeklistMonitoring.ceklist_monitoring_list
                  .filter((cml) => cml.pivot && cml.pivot.ts)
                  .map((cml) => {
                    return <li key={cml.id}>{cml.pivot.catatan}</li>;
                  })}
            </ol>
            <br />
            Sedangkan untuk saran berkaitan dengan :
            <ol>
              {detailCeklistMonitoring.ceklist_monitoring_list &&
                detailCeklistMonitoring.ceklist_monitoring_list.length > 0 &&
                detailCeklistMonitoring.ceklist_monitoring_list
                  .filter((cml) => cml.pivot && cml.pivot.sr)
                  .map((cml) => {
                    return <li key={cml.id}>{cml.pivot.catatan}</li>;
                  })}
            </ol>
          </p>
        </div>
        <div className="d-flex justify-content-end align-items-center col-lg-11 mt-5 pt-5">
          <div>
            <p className="mb-5">
              {cabang
                .filter(
                  (c) =>
                    parseInt(c.id) ===
                    parseInt(detailCeklistMonitoring.cabang_id)
                )
                .map((c) => c.nama)}
              ,.....................
            </p>
            <p className="m-0">....................................</p>
            <p>Lead Auditor</p>
          </div>
        </div>
        <div className="col-lg-11 mt-5 pt-5">
          <h2 className="fs-6 fw-bold text-center">
            LAPORAN KEGIATAN{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "MONITORING"
              : "AMI"}{" "}
            TAHAP {detailCeklistMonitoring.tahap} KEPALA CABANG
          </h2>
          <p className="fw-bold my-1 mt-5">I. PENDAHULUAN</p>
          <table className="table table-bordered m-0">
            <tbody>
              <tr>
                <td className="border border-dark">Bagian</td>
                <td className="border border-dark">
                  {departemen
                    .filter(
                      (d) =>
                        parseInt(d.id) ===
                        parseInt(detailCeklistMonitoring.departemen_id)
                    )
                    .map((d) => d.nama)}
                </td>
              </tr>
              <tr>
                <td className="border border-dark">Nama</td>
                <td className="border border-dark">
                  ............................................
                </td>
              </tr>
              <tr>
                <td className="border border-dark">Tanggal</td>
                <td className="border border-dark">
                  ............................................
                </td>
              </tr>
              <tr>
                <td className="border-bottom-0 border-dark">Auditor</td>
                <td className="border-bottom-0 border-dark">
                  {auditor.length > 0 &&
                    auditor
                      .filter(
                        (a) =>
                          parseInt(a.id) ===
                          parseInt(detailCeklistMonitoring.auditor_id)
                      )
                      .map((a) => a.nama)}
                </td>
              </tr>
            </tbody>
          </table>
          <table className="table table-bordered m-0">
            <tbody>
              <tr>
                <td className="border border-dark">Tanda Tangan Auditor</td>
                <td className="border border-dark">
                  ............................................
                </td>
                <td className="border border-dark">Tanda Tangan Auditee :</td>
                <td className="border border-dark">
                  ............................................
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-lg-11 mt-4">
          <p className="fw-bold my-1">II. TUJUAN KEGIATAN :</p>
          <ol className="justify">
            <li>
              Melakukan konfirmasi terkait dengan sistem penjaminan mutu yang
              telah dijalankan.
            </li>
            <li>
              Melakukan konfirmasi terkait pelaksanaan teknis, prosedur, dan
              strategis yang dijalankan oleh Cabang{" "}
              {cabang.length > 0 &&
                cabang
                  .filter(
                    (c) =>
                      parseInt(c.id) ===
                      parseInt(detailCeklistMonitoring.cabang_id)
                  )
                  .map((c) => c.nama)}
            </li>
            <li>
              Melakukan Audit Mutu Internal dan evaluasi terkait kesesuaian dan
              ketidaksesuaian yang terjadi Alfa Prima Cabang{" "}
              {cabang.length > 0 &&
                cabang
                  .filter(
                    (c) =>
                      parseInt(c.id) ===
                      parseInt(detailCeklistMonitoring.cabang_id)
                  )
                  .map((c) => c.nama)}
            </li>
          </ol>
        </div>
        <div className="col-lg-11 mt-4">
          <p className="fw-bold my-1">III. LINGKUP KEGIATAN :</p>
          <p className="justify my-2">
            Ruang lingkup Kegiatan{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "Monitoring"
              : "AMI"}{" "}
            Tahap {detailCeklistMonitoring.tahap} di ranah Kepala Cabang{" "}
            {cabang.length > 0 &&
              cabang
                .filter(
                  (c) =>
                    parseInt(c.id) ===
                    parseInt(detailCeklistMonitoring.cabang_id)
                )
                .map((c) => c.nama)}
            membangun indikator dari standar penjaminan mutu internal, mencakup:
          </p>
          <ol className="justify">
            <li>Dokumen sistem penjaminan mutu</li>
            <li>Proses dan pengendalian sistem penjaminan mutu</li>
            <li>Sumber daya manusia</li>
            <li>Evaluasi dan perbaikan</li>
          </ol>
        </div>
        <div className="col-lg-11 mt-4">
          <p className="fw-bold my-1">IV. JADWAL KEGIATAN:</p>
          <ol className="justify">
            <li>
              Pertemuan auditor dengan Kepala Cabang{" "}
              {cabang.length > 0 &&
                cabang
                  .filter(
                    (c) =>
                      parseInt(c.id) ===
                      parseInt(detailCeklistMonitoring.cabang_id)
                  )
                  .map((c) => c.nama)}{" "}
              Prima …………………… untuk mempertanyakan kelengkapan dokumen, kepatuhan
              terhadap kelengkapan dokumen dan proses pelaksanaan standar mutu.
            </li>
            <li>Penyampaian temuan dan merumuskan rencana tindak lanjut.</li>
          </ol>
        </div>
        <div className="col-lg-11 mt-2">
          <p className="fw-bold my-2">
            V. TEMUAN{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "MONITORING"
              : "AMI"}
            :
          </p>
          <table className="table table-bordered m-0">
            <thead>
              <tr className="align-middle">
                <th className="border border-dark text-center">TS/TR</th>
                <th className="border border-dark text-center">
                  Refrensi (butir mutu)
                </th>
                <th className="border border-dark text-center">Pernyataan</th>
              </tr>
            </thead>
            <tbody>
              {detailCeklistMonitoring.ceklist_monitoring_list &&
                detailCeklistMonitoring.ceklist_monitoring_list.length > 0 &&
                detailCeklistMonitoring.ceklist_monitoring_list.map(
                  (cml, index) => {
                    return (
                      <tr className="align-middle" key={cml.id}>
                        <td className="border border-dark text-center">
                          {index < 9 ? (
                            <span>TS-0{index + 1}</span>
                          ) : (
                            <span>TS-{index + 1}</span>
                          )}
                        </td>
                        <td className="border border-dark">{cml.klausul}</td>
                        <td className="border border-dark">{cml.deskripsi}</td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
        </div>
        <div className="col-lg-11 mt-4">
          <p className="fw-bold my-1">VI. PRAKTIK BAIK</p>
          <p className="justify my-2">
            Pelaksanaan Kegiatan{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "Monitoring"
              : "AMI"}{" "}
            Tahap {detailCeklistMonitoring.tahap} diawali dengan tahap persiapan
            yaitu melakukan penjadwalan kegiatan monitoring, mempersiapkan
            dokumen yang diperlukan, pembentukan tim auditor, dan sosialisasi
            pada auditee mengenai checklist monitoring yang akan digunakan pada
            Kegiatan{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "Monitoring"
              : "AMI"}{" "}
            tahun {detailCeklistMonitoring.tahun}. Tahap kedua adalah tahap
            pelaksanaan kegiatan monitoring yang dilaksanakan oleh auditor dan
            auditee sesuai dengan yang telah ditetapkan. Auditor melakukan audit
            lapangan di lokasi kerja auditee. Tahap akhir setelah proses
            kegiatan monitoring selesai yaitu dilakukan rapat koordinasi dengan
            tim auditor untuk merumuskan daftar temuan. Daftar temuan monitoring
            dikategorikan menjadi 2, yaitu Tidak Sesuai (TS) dan Saran (SR).
            Adapun yang wajib ditindakanjuti melalui form PTPP adalah temuan
            monitoring dikategorikan Tidak Sesuai (TS), sedangkan temuan yang di
            kategorikan Saran (SR) dapat digunakan sebagai panduan untuk
            peningkatan standar atau layanan.
          </p>
        </div>
        <div className="col-lg-11 mt-4">
          <p className="fw-bold my-1">
            VII. KESIMPULAN KEGIATAN{" "}
            {parseInt(detailCeklistMonitoring.jenis) === 1
              ? "MONITORING"
              : "AMI"}
          </p>
          <p className="justify my-2">
            Berdasarkan hasil pemantauan tim auditor terhadap Kepala Cabang{" "}
            {cabang.length > 0 &&
              cabang
                .filter(
                  (c) =>
                    parseInt(c.id) ===
                    parseInt(detailCeklistMonitoring.cabang_id)
                )
                .map((c) => c.nama)}
            dilaksanakan pada tanggal 27 Maret 2022, maka diketahui bahwa:
          </p>
          <ol className="justify">
            <li>
              Secara umum, pihak auditee telah menyelenggarakan kegiatan sesuai
              dengan tugas dan wewenang, walaupun demikian masih terdapat
              beberapa hal yang perlu dilakukan perbaikan berdasarkan
              butir-butir temuan yang diperoleh pada saat dilakukan monitoring
              tahap {detailCeklistMonitoring.tahap}.
            </li>
            <li>
              Diharapkan dengan adanya beberapa temuan, pihak auditee dapat
              memperbaiki atau menyempurnakan hal-hal yang menjadi temuan
              tersebut terkait risk register, MPP dan Matrik Kompetensi Karyawan
              (Tenaga Kependidikan) dan Dosen (Tenaga Pendidikan).
            </li>
          </ol>
        </div>
        <div className="col-lg-11 mt-4">
          <p className="fw-bold my-1">VIII. LAMPIRAN </p>
          <ol className="justify">
            <li>Check list monitoring tahap {detailCeklistMonitoring.tahap}</li>
            <>
              PTPP Kepala Cabang{" "}
              {cabang.length > 0 &&
                cabang
                  .filter(
                    (c) =>
                      parseInt(c.id) ===
                      parseInt(detailCeklistMonitoring.cabang_id)
                  )
                  .map((c) => c.nama)}
            </>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default Laporan;
