import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchJurusan, fetchMhs, patchMhs } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterAngkatan,
  FilterJenjang,
  FilterJurusan,
  FilterPagination,
  FilterSearch,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import MhsEdit from "./Edit";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import SmallButton from "../../components/forms/SmallButton";

const MhsFo = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const [isLoaded, setIsLoaded] = useState(false);

  const [items, setItems] = useState({});
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [jenjang, setJenjang] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [pagination, setPagination] = useState(10);
  const [angkatan, setAngkatan] = useState(new Date().getFullYear());

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchMhs(
        page,
        `search=${search}&jenjang=${jenjang}&jurusan=${jurusan}&pagination=${pagination}&angkatan=${angkatan}`
      );
      if (res.data && res.data.mhs) {
        setItems(res.data.mhs);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [page, search, jenjang, jurusan, pagination, angkatan]);

  useEffect(() => {
    setTitle("Data Mahasiswa Baru");
    fetchData();
  }, [fetchData, setTitle]);

  const [dataJurusan, setDataJurusan] = useState({});

  useEffect(() => {
    const fetchDataJurusan = async () => {
      try {
        const res = await fetchJurusan("all");
        setDataJurusan(res.data);
      } catch (error) {}
    };

    fetchDataJurusan();
  }, []);

  if (isLoaded) {
    return (
      <>
        {/* Filter 1 */}
        <div className="row mb-2">
          <div className="col-md-4">
            <FilterJenjang
              onChange={(res) => {
                setJenjang(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterJurusan
              onChange={(res) => {
                setJurusan(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {/* filter 2 */}
        <div className="row mb-2">
          <div className="col-md-4">
            <FilterPagination
              onChange={(res) => {
                setPagination(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterAngkatan
              value={angkatan}
              onChange={(res) => {
                setAngkatan(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {items.data.length >= 1 ? (
          <div className="row">
            <div className="col-4 pe-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center'>
                    <Th>No</Th>
                    <Th>Cabang</Th>
                    <Th>NIM</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-center">{items.from + index}</Td>
                        <Td className="text-center">{i.cabang}</Td>
                        <Td className="text-center">{i.nim}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-7 px-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center'>
                    <Th className="text-nowrap">Nama</Th>
                    <Th className="text-nowrap">Angkatan</Th>
                    <Th className="text-nowrap">Jenjang</Th>
                    <Th className="text-nowrap">Jurusan</Th>
                    <Th className="text-nowrap">Kelas</Th>
                    <Th className="text-nowrap">KDBB</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-nowrap">{i.nama}</Td>
                        <Td className="text-nowrap text-center">
                          {i.angkatan}
                        </Td>
                        <Td className="text-nowrap text-center">{i.jenjang}</Td>
                        <Td className="text-nowrap">
                          {i.jurusan_ && i.jurusan_.nama}
                        </Td>
                        <Td className="text-nowrap text-center">{i.waktu_belajar}</Td>
                        <Td className="text-nowrap text-center">
                          {i.kdbb === 1 ? <i className="fa fa-fw fa-circle-check text-success-2"/> : <i className="fa fa-fw fa-circle-xmark text-danger"/>}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-1 ps-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center'>
                    <Th className="text-nowrap"><i className="fa fa-fw fa-cog"/></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-center text-nowrap">
                          <MhsEdit
                            user={user}
                            mhs={i}
                            jurusan={dataJurusan}
                            onFetchData={fetchData}
                          />
                          {user.jabatan === "IT Programmer" && (
                            <SmallButton
                              type='delete'
                              onClick={() => {
                                saConfirm(
                                  "warning",
                                  "Yakin tidak aktifkan mahasiswa ?"
                                ).then((res) => {
                                  if (res.isConfirmed) {
                                    patchMhs(i.id, { status: "99" }).then(
                                      (res) => {
                                        if (res.data === "success") {
                                          fetchData();
                                          saAlert(
                                            "success",
                                            "Berhasil ubah status menjadi tidak aktif !"
                                          );
                                        }
                                      }
                                    );
                                  }
                                });
                              }}
                            />
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
          </div>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default MhsFo;
