import { Switch, Route } from "react-router-dom";
import Dashboard from "../views/Dashboard";
import CutiApproval from "../views/cuti/Approval";
import CutiCreate from "../views/cuti/Create";
import CutiHrd from "../views/cuti/Hrd";
import ShowCuti from "../views/cuti/Show";
import LemburApproval from "../views/lembur/Approval";
import LemburCreate from "../views/lembur/Create";
import LemburHrd from "../views/lembur/Hrd";
import LemburShow from "../views/lembur/Show";
import Login from "../views/Login";
import MhsAkademik from "../views/mhs/Akademik";
import NoMatch from "../views/NoMatch";
import PegawaiMaster from "../views/pegawai/Master";
import PegawaiShow from "../views/pegawai/Show";
import PendaftarFo from "../views/pendaftar/Fo";
import PendaftarPrint from "../views/pendaftar/Print";
import DashboardMain from "../components/dashboard/Main";
import PenilaianKompetensiCreate from "../views/penilaianKompetensi/Create";
import PenilaianKompetensi from "../views/penilaianKompetensi/PenilaianKompetensi";
import SoalPenilaian from "../views/soalPenilaian/SoalPenilaian";
import PenilaianKompetensiHasil from "../views/penilaianKompetensi/Hasil";
import WorkorderShow from "../views/workorder/Show";
import Workorder from "../views/workorder/Workorder";
import MhsOjtAkademik from "../views/mhs/OjtAkademik";
import DosenAkademik from "../views/dosen/Akademik";
import PerusahaanPkk from "../views/perusahaan/Pkk";
import AlumniPkk from "../views/alumni/Pkk";
import AfiliasiHp from "../views/afiliasi/Hp";
import PendaftarHp from "../views/pendaftar/Hp";
import MhsKeuangan from "../views/mhs/Keuangan";
import PojtPkk from "../views/pojt/Pkk";
import MhsFo from "../views/mhs/Fo";
import JamkulAkademik from "../views/jamkul/Akademik";
import MatkulAkademik from "../views/matkul/Akademik";
import KelompokMatkulAkademik from "../views/kelompokMatkul/Akademik";
import RuanganAkademik from "../views/ruangan/Akademik";
import MhsOjt from "../views/mhs/Ojt";
import KelasAkademik from "../views/kelas/Akademik";
import PendaftarInvoiceAfiliasi from "../views/pendaftar/InvoiceAfiliasi";
import ImportExport from "../views/ImportExport";
import PenilaianKompetensiStruktur from "../views/penilaianKompetensi/Struktur";
import KelasAbsensi from "../views/kelas/Absensi";
import KelasJurnal from "../views/kelas/Jurnal";
import Pengajaran from "../views/pengajaran/Pengajaran";
import DosenHonor from "../views/dosen/Honor";
import BidangAkademik from "../views/bidang/Akademik";
import PenyelenggaraAkademik from "../views/penyelenggara/Akademik";
import ProgkamAkademik from "../views/progkam/Akademik";
import KelasBeritaAcaraUts from "../views/kelas/BeritaAcaraUts";
import ProgkamLepasan from "../views/progkam/Lepasan";
import MhsPkk from "../views/mhs/Pkk";
import KelasDaftarNilai from "../views/kelas/DaftarNilai";
import PendaftarKeuangan from "../views/pendaftar/Keuangan";
import KelasBeritaAcaraUas from "../views/kelas/BeritaAcaraUas";
import KelasAngketDosen from "../views/kelas/AngketDosen";
import MasterFixAbsensi from "../views/master/FixAbsensi";
import KelasNilaiUts from "../views/kelas/NilaiUts";
import KelasNilaiUas from "../views/kelas/NilaiUas";
import LemburMaster from "../views/lembur/Master";
import Ks from "../views/ks/Ks";
import MhsPjm from "../views/mhs/Pjm";
import { useRecoilValue } from "recoil";
import { userState } from "../storage/auth";
import GenerateNilaiAbsensi from "../views/master/GenerateNilaiAbsensi";
import MhsNilai from "../views/mhs/Nilai";
import BukuPerpustakaan from "../views/bukuPerpustakaan/BukuPerpustakaan";
import KelasPjm from "../views/kelas/Pjm";
import SlipGaji from "../views/slipGaji/SlipGaji";
import NilaiTranskripAkademik from "../views/nilai/TranskripAkademik";
import NilaiUmumAkademik from "../views/nilai/UmumAkademik";
import NilaiOjtAkademik from "../views/nilai/OjtAkademik";
import NilaiAkhirAkademik from "../views/nilai/AkhirAkademik";
import AlfaPrima from "../views/AlfaPrima";
import NilaiTakAkademik from "../views/nilai/TakAkademik";
import NilaiPrintTranskripKhs from "../views/nilai/print/TranskripKhs";
import NilaiPrintTranskripTak from "../views/nilai/print/TranskripTak";
import NilaiPrintTranskripReguler from "../views/nilai/print/TranskripReguler";
import NilaiPrintTranskripRegulerSementara from "../views/nilai/print/TranskripRegulerSementara";
import NilaiAbsensiKurang from "../views/nilai/AbsensiKurang";
import PengajaranBapKurang from "../views/pengajaran/BapKurang";
import PendaftarIntership from "../views/PendaftarIntership/PendaftarIntership";
import PendaftarIntershipPeserta from "../views/PendaftarIntership/Peserta";
import PerusahaanPerpanjangMou from "../views/perusahaan/PerpanjangMou";
import PerusahaanUlangTahun from "../views/perusahaan/UlangTahun";
import LinkSurvei from "../views/linkSurvei/LinkSurvei";
import LinkSop from "../views/linkSop/LinkSop";
import LinkSopShow from "../views/linkSop/Show";
import DosenPrintSkDosen from "../views/dosen/print/SkDosen";
import DosenPrintLampiranSkDosen from "../views/dosen/print/LampiranSkDosen";
import MeninggalkanTugasShow from "../views/meninggalkanTugas/Show";
import IjinShow from "../views/ijin/Show";
import MeninggalkanTugasApproval from "../views/meninggalkanTugas/Approval";
import IjinApproval from "../views/ijin/Approval";
import IjinHrd from "../views/ijin/Hrd";
import MeninggalkanTugasHrd from "../views/meninggalkanTugas/Hrd";
import PegawaiHrd from "../views/pegawai/Hrd";
import BukuSakuKegiatan from "../views/bukuSakuKegiatan/BukuSakuKegiatan";
import WisudaAkademik from "../views/wisuda/Akademik";
import CabangRoutes from "../modules/cabang/Routes";
import DepartemenRoutes from "../modules/departemen/Routes";
import CeklistMonitoringRoutes from "../modules/ceklist-monitoring/Routes";
import CeklistMonitoringListRoutes from "../modules/ceklist-monitoring-list/Routes";
import CeklistMonitoringValueRoutes from "../modules/ceklist-monitoring-value/Routes";
import PurchaseRequestRoutes from "../modules/purchase-request/Routes";
import PojtPrintProposal from "../views/pojt/print/Proposal";
import PojtPrintSurat from "../views/pojt/print/Surat";
import PeminjamanRuangan from "../views/peminjaman-ruangan/PeminjamanRuangan";
import PengajuanPeminjamanRuangan from "../views/peminjaman-ruangan/Pengajuan";
import Jabatan from "../views/jabatan/Jabatan";
import Kpi from "../views/kpi/Kpi";
import AbsensiPegawai from "../views/absensi-pegawai/AbsensiPegawai";
import AbsensiPegawaiApproval from "../views/absensi-pegawai/Approval";
import AbsensiPegawaiHrd from "../views/absensi-pegawai/Hrd";
import MorningReport from "../views/morning-report/MorningReport";
import MorningReportHrd from "../views/morning-report/Hrd";
import KpiShow from "../views/kpi/Show";
import Pengumuman from "../views/pengumuman/Pengumuman";
import AspekBsc from "../views/aspek-bsc/AspekBsc";
import KpiAuditor from "../views/kpi/Auditor";
import KpiLaporan from "../views/kpi/Laporan";
import PrintLaporanKpi from "../views/kpi/Print/LaporanKpi";
import Direktorat from "../views/pegawai/Direktorat";
import Divisi from "../views/pegawai/Divisi";
import Departemen from "../views/pegawai/Departemen";
import Pelaksana from "../views/pegawai/Pelaksana";
import Rkat from "../views/rkat/Rkat";
import ArusKas from "../views/arus-kas/ArusKas";
import BukuSaku from "../views/bukuSaku/BukuSaku";
import SurveyDosen from "../views/survey/SurveyDosen";
import SurveyPegawai from "../views/survey/SurveyPegawai";
import BeritaTerkini from "../views/berita-terkini/BeritaTerkini";
import Wow from "../views/pojt/print/Wow";
import PerusahaanAlumni from "../views/alumni/PerusahaanAlumni";
import SurveyMhs from "../views/survey/mhs/SurveyMhs";
import PrintDebug from "../views/debug/PrintDebug";
import TutorialSistem from "../views/tutorialSistem/TutorialSistem";
import BrosurDigital from "../views/brosur-digital/BrosurDigital";

const Routes = () => {
  const user = useRecoilValue(userState);

  return (
    <Switch>
      <Route exact path="/">
        <Login />
      </Route>

      {/* Route */}
      <Route path="/cabang">
        <DashboardMain>
          <CabangRoutes />
        </DashboardMain>
      </Route>

      <Route path="/ceklist-monitoring">
        {/* <DashboardMain> */}
        <CeklistMonitoringRoutes />
        {/* </DashboardMain> */}
      </Route>

      <Route path="/ceklist-monitoring-list">
        <DashboardMain>
          <CeklistMonitoringListRoutes />
        </DashboardMain>
      </Route>

      <Route path="/ceklist-monitoring-value">
        <DashboardMain>
          <CeklistMonitoringValueRoutes />
        </DashboardMain>
      </Route>

      <Route path="/departemen">
        <DashboardMain>
          <DepartemenRoutes />
        </DashboardMain>
      </Route>

      <Route path="/purchase-request">
        <DashboardMain>
          <PurchaseRequestRoutes />
        </DashboardMain>
      </Route>
      {/* Route new Format */}

      <Route path="/dashboard">
        <DashboardMain>
          <Dashboard />
        </DashboardMain>
      </Route>
      {/* <Route path="/daily-report">
        <DashboardMain>
          <DailyReport />
        </DashboardMain>
      </Route> */}
      <Route path="/alfa-prima">
        <DashboardMain>
          <AlfaPrima />
        </DashboardMain>
      </Route>
      <Route path="/slip-gaji">
        <DashboardMain>
          <SlipGaji />
        </DashboardMain>
      </Route>
      <Route path="/profile">
        <DashboardMain>
          <PegawaiShow />
        </DashboardMain>
      </Route>
      <Route path="/cuti/create">
        <DashboardMain>
          <CutiCreate />
        </DashboardMain>
      </Route>
      <Route path="/cuti/show">
        <DashboardMain>
          <ShowCuti />
        </DashboardMain>
      </Route>
      <Route path="/cuti/approval">
        <DashboardMain>
          <CutiApproval />
        </DashboardMain>
      </Route>
      <Route path="/lembur/create">
        <DashboardMain>
          <LemburCreate />
        </DashboardMain>
      </Route>
      <Route path="/lembur/show">
        <DashboardMain>
          <LemburShow />
        </DashboardMain>
      </Route>
      <Route path="/lembur/approval">
        <DashboardMain>
          <LemburApproval />
        </DashboardMain>
      </Route>
      {/* Akademik */}
      {/* mhs */}
      <Route path="/mhs-akademik">
        <DashboardMain>
          <MhsAkademik />
        </DashboardMain>
      </Route>
      <Route path="/mhs-ojt">
        <DashboardMain>
          <MhsOjt />
        </DashboardMain>
      </Route>
      <Route path="/mhs-nilai">
        <DashboardMain>
          <MhsNilai />
        </DashboardMain>
      </Route>
      <Route path="/mhs-ojt-akademik">
        <DashboardMain>
          <MhsOjtAkademik />
        </DashboardMain>
      </Route>
      {/* ------------------ */}

      {/* Wisudawan */}
      <Route path="/wisuda">
        <DashboardMain>
          <WisudaAkademik />
        </DashboardMain>
      </Route>
      {/* End Wisudawan */}

      {/* Nilai */}
      <Route path="/nilai-umum-akademik">
        <DashboardMain>
          <NilaiUmumAkademik />
        </DashboardMain>
      </Route>
      <Route path="/nilai-tak-akademik">
        <DashboardMain>
          <NilaiTakAkademik />
        </DashboardMain>
      </Route>
      <Route path="/nilai-ojt-akademik">
        <DashboardMain>
          <NilaiOjtAkademik />
        </DashboardMain>
      </Route>
      <Route path="/nilai-akhir-akademik">
        <DashboardMain>
          <NilaiAkhirAkademik />
        </DashboardMain>
      </Route>
      <Route path="/nilai-transkrip-akademik">
        <DashboardMain>
          <NilaiTranskripAkademik />
        </DashboardMain>
      </Route>
      <Route path="/print-transkrip-reguler/:mhs_id">
        <NilaiPrintTranskripReguler />
      </Route>
      {/* reguler sementara */}
      <Route path="/print-transkrip-reguler-sementara/:mhs_id">
        <NilaiPrintTranskripRegulerSementara />
      </Route>
      {/* end reguler sementara */}
      <Route path="/print-transkrip-khs/:mhs_id/:semester">
        <NilaiPrintTranskripKhs />
      </Route>
      <Route path="/print-transkrip-tak/:mhs_id">
        <NilaiPrintTranskripTak />
      </Route>
      {/* ------------------ */}
      {/* dosen */}
      <Route path="/dosen-akademik">
        <DashboardMain>
          <DosenAkademik />
        </DashboardMain>
      </Route>
      <Route path="/dosen-honor">
        <DashboardMain>
          <DosenHonor />
        </DashboardMain>
      </Route>
      <Route path="/print-sk-dosen/:sk_dosen_id">
        <DosenPrintSkDosen />
      </Route>
      <Route path="/print-lampiran-sk-dosen/:sk_dosen_id">
        <DosenPrintLampiranSkDosen />
      </Route>
      {/* -------------------- */}
      {/* kuliah */}
      <Route path="/jamkul-akademik">
        <DashboardMain>
          <JamkulAkademik />
        </DashboardMain>
      </Route>
      <Route path="/kelompok-matkul-akademik">
        <DashboardMain>
          <KelompokMatkulAkademik />
        </DashboardMain>
      </Route>
      <Route path="/matkul-akademik">
        <DashboardMain>
          <MatkulAkademik />
        </DashboardMain>
      </Route>
      <Route path="/ruangan-akademik">
        <DashboardMain>
          <RuanganAkademik />
        </DashboardMain>
      </Route>
      {/* --------------------- */}
      {/* Kelas */}
      <Route path="/kelas-akademik/:tanggalPengajaran?">
        <DashboardMain>
          <KelasAkademik />
        </DashboardMain>
      </Route>
      <Route path="/kelas-pjm">
        <DashboardMain>
          <KelasPjm />
        </DashboardMain>
      </Route>
      <Route path="/kelas-absensi/:id">
        <KelasAbsensi />
      </Route>
      <Route path="/kelas-jurnal/:id">
        <KelasJurnal />
      </Route>
      <Route path="/kelas-berita-acara-uts/:id">
        <KelasBeritaAcaraUts />
      </Route>
      <Route path="/kelas-berita-acara-uas/:id">
        <KelasBeritaAcaraUas />
      </Route>
      <Route path="/nilai-uts/:id">
        <KelasNilaiUts />
      </Route>
      <Route path="/nilai-uas/:id">
        <KelasNilaiUas />
      </Route>
      <Route path="/daftar-nilai/:id">
        <KelasDaftarNilai />
      </Route>
      <Route path="/kelas-angket-dosen/:id">
        <KelasAngketDosen />
      </Route>
      <Route path="/nilai-absensi-kurang">
        <DashboardMain>
          <NilaiAbsensiKurang />
        </DashboardMain>
      </Route>
      {/* ----------- */}
      {/* perpustakaan */}
      <Route path="/buku-perpustakaan">
        <DashboardMain>
          <BukuPerpustakaan />
        </DashboardMain>
      </Route>
      {/* ------------------------------------ */}
      <Route path="/pengumuman">
        <DashboardMain>
          <Pengumuman />
        </DashboardMain>
      </Route>
      {/* Aktivitas */}
      <Route path="/pengajaran">
        <DashboardMain>
          <Pengajaran />
        </DashboardMain>
      </Route>
      <Route path="/pengajaran-bap-kurang">
        <DashboardMain>
          <PengajaranBapKurang />
        </DashboardMain>
      </Route>
      {/* Aktivitas Mahasiswa */}
      <Route path="/bidang">
        <DashboardMain>
          <BidangAkademik />
        </DashboardMain>
      </Route>
      <Route path="/penyelenggara">
        <DashboardMain>
          <PenyelenggaraAkademik />
        </DashboardMain>
      </Route>
      <Route path="/program-kampus">
        <DashboardMain>
          <ProgkamAkademik />
        </DashboardMain>
      </Route>
      <Route path="/program-lepasan">
        <DashboardMain>
          <ProgkamLepasan />
        </DashboardMain>
      </Route>
      {/* PKK */}
      <Route path="/perusahaan-pkk">
        <DashboardMain>
          <PerusahaanPkk />
        </DashboardMain>
      </Route>
      <Route exact path="/pojt-pkk">
        <DashboardMain>
          <PojtPkk />
        </DashboardMain>
      </Route>
      <Route path="/pojt-pkk/print-proposal/:id">
        <PojtPrintProposal />
      </Route>
      <Route path="/pojt-pkk/print-surat/:id">
        <PojtPrintSurat />
      </Route>
      <Route path="/wow/:id">
        <Wow/>
      </Route>
      <Route path="/mhs-pkk">
        <DashboardMain>
          <MhsPkk />
        </DashboardMain>
      </Route>
      <Route path="/alumni-pkk">
        <DashboardMain>
          <AlumniPkk />
        </DashboardMain>
      </Route>
      <Route path="/perusahaan-perpanjang-mou">
        <DashboardMain>
          <PerusahaanPerpanjangMou />
        </DashboardMain>
      </Route>
      <Route path="/perusahaan-ulang-tahun">
        <DashboardMain>
          <PerusahaanUlangTahun />
        </DashboardMain>
      </Route>
      {/* Kegiatan Buku Saku */}
      <Route path="/buku-saku">
        <DashboardMain>
          <BukuSaku />
        </DashboardMain>
      </Route>
      {/* End Kegiatan Buku Saku */}
      {/* Kegiatan Buku Saku */}
      <Route path="/buku-saku-kegiatan">
        <DashboardMain>
          <BukuSakuKegiatan />
        </DashboardMain>
      </Route>
      {/* End Kegiatan Buku Saku */}

      {/* Meninggalkan tugas */}
      <Route path="/meninggalkan-tugas/show">
        <DashboardMain>
          <MeninggalkanTugasShow />
        </DashboardMain>
      </Route>
      <Route path="/meninggalkan-tugas/approval">
        <DashboardMain>
          <MeninggalkanTugasApproval />
        </DashboardMain>
      </Route>

      {/* Ijin */}
      <Route path="/ijin/show">
        <DashboardMain>
          <IjinShow />
        </DashboardMain>
      </Route>
      <Route path="/ijin/approval">
        <DashboardMain>
          <IjinApproval />
        </DashboardMain>
      </Route>

      {/* Absensi Pegawai */}
      <Route path="/absensi-pegawai/show">
        <DashboardMain>
          <AbsensiPegawai />
        </DashboardMain>
      </Route>
      <Route path="/absensi-pegawai/approval">
        <DashboardMain>
          <AbsensiPegawaiApproval />
        </DashboardMain>
      </Route>

      {/* HRD */}
      <Route path="/lembur">
        <DashboardMain>
          <LemburHrd />
        </DashboardMain>
      </Route>
      <Route path="/cuti">
        <DashboardMain>
          <CutiHrd />
        </DashboardMain>
      </Route>
      <Route path="/ijin">
        <DashboardMain>
          <IjinHrd />
        </DashboardMain>
      </Route>
      <Route path="/absensi-pegawai">
        <DashboardMain>
          <AbsensiPegawaiHrd />
        </DashboardMain>
      </Route>
      <Route path="/meninggalkan-tugas">
        <DashboardMain>
          <MeninggalkanTugasHrd />
        </DashboardMain>
      </Route>
      {/*  */}
      {/* Workorder */}
      <Route path="/workorder-show">
        <DashboardMain>
          <WorkorderShow />
        </DashboardMain>
      </Route>
      {/* Design & UPT */}
      <Route path="/workorder">
        <DashboardMain>
          <Workorder />
        </DashboardMain>
      </Route>
      <Route path="/peminjaman-ruangan">
        <DashboardMain>
          <PeminjamanRuangan />
        </DashboardMain>
      </Route>
      <Route path="/pengajuan-peminjaman-ruangan">
        <DashboardMain>
          <PengajuanPeminjamanRuangan />
        </DashboardMain>
      </Route>
      {/* FO */}
      <Route path="/pendaftar-fo">
        <DashboardMain>
          <PendaftarFo />
        </DashboardMain>
      </Route>
      <Route path="/print-pendaftar/:id">
        <PendaftarPrint />
      </Route>
      <Route path="/mhs-fo">
        <DashboardMain>
          <MhsFo />
        </DashboardMain>
      </Route>
      {/* Humas & Pemasaran */}
      <Route path="/afiliasi-hp">
        <DashboardMain>
          <AfiliasiHp />
        </DashboardMain>
      </Route>
      <Route path="/pendaftar-hp">
        <DashboardMain>
          <PendaftarHp />
        </DashboardMain>
      </Route>
      <Route path="/cairkan-dana-afiliasi/:id">
        <PendaftarInvoiceAfiliasi />
      </Route>
      {/* Penilaian Kompetensi */}
      <Route exact path="/penilaian-kompetensi">
        <DashboardMain>
          <PenilaianKompetensi />
        </DashboardMain>
      </Route>
      <Route path="/penilaian-kompetensi-struktur">
        <DashboardMain>
          <PenilaianKompetensiStruktur />
        </DashboardMain>
      </Route>
      <Route path="/penilaian-kompetensi/:id/:cabang/:jabatan/:nama">
        <DashboardMain>
          <PenilaianKompetensiCreate />
        </DashboardMain>
      </Route>
      <Route path="/hasil-penilaian-kompetensi">
        <DashboardMain>
          <PenilaianKompetensiHasil />
        </DashboardMain>
      </Route>
      <Route path="/soal-penilaian">
        <DashboardMain>
          <SoalPenilaian />
        </DashboardMain>
      </Route>
      {/* Keuangan */}
      <Route path="/mhs-keuangan">
        <DashboardMain>
          <MhsKeuangan />
        </DashboardMain>
      </Route>
      <Route path="/pendaftar-keuangan">
        <DashboardMain>
          <PendaftarKeuangan />
        </DashboardMain>
      </Route>
      {/* Pjm */}
      <Route path="/mhs-pjm">
        <DashboardMain>
          <MhsPjm />
        </DashboardMain>
      </Route>
      <Route path="/survei">
        <DashboardMain>
          <MhsPjm />
        </DashboardMain>
      </Route>
      {/* Kacab */}
      <Route path="/ks">
        <DashboardMain>
          <Ks />
        </DashboardMain>
      </Route>
      {/* Intership */}
      <Route path="/pendaftar-intership">
        <DashboardMain>
          <PendaftarIntership />
        </DashboardMain>
      </Route>
      <Route path="/peserta-intership">
        <DashboardMain>
          <PendaftarIntershipPeserta />
        </DashboardMain>
      </Route>
      {/* link survei */}
      <Route path="/link-survei">
        <DashboardMain>
          <LinkSurvei />
        </DashboardMain>
      </Route>
      <Route path="/link-sop">
        <DashboardMain>
          <LinkSop />
        </DashboardMain>
      </Route>
      <Route path="/sop">
        <DashboardMain>
          <LinkSopShow />
        </DashboardMain>
      </Route>
      {/* HRD */}
      <Route path="/daily-report">
        <DashboardMain>
          <MorningReport />
        </DashboardMain>
      </Route>
      <Route path="/daily-report-hrd">
        <DashboardMain>
          <MorningReportHrd />
        </DashboardMain>
      </Route>

      <Route path="/pegawai-hrd">
        <DashboardMain>
          <PegawaiHrd />
        </DashboardMain>
      </Route>
      <Route path="/jabatan">
        <DashboardMain>
          <Jabatan />
        </DashboardMain>
      </Route>
      <Route path="/direktorat-hrd">
        <DashboardMain>
          <Direktorat />
        </DashboardMain>
      </Route>
      <Route path="/divisi-hrd">
        <DashboardMain>
          <Divisi />
        </DashboardMain>
      </Route>
      <Route path="/departemen-hrd">
        <DashboardMain>
          <Departemen />
        </DashboardMain>
      </Route>
      <Route path="/pelaksana-hrd">
        <DashboardMain>
          <Pelaksana />
        </DashboardMain>
      </Route>
      <Route path="/kpi">
        <DashboardMain>
          <Kpi />
        </DashboardMain>
      </Route>
      <Route path="/aspek-bsc">
        <DashboardMain>
          <AspekBsc />
        </DashboardMain>
      </Route>
      <Route path="/kpi-show">
        <DashboardMain>
          <KpiShow />
        </DashboardMain>
      </Route>
      <Route path="/kpi-auditor">
        <DashboardMain>
          <KpiAuditor />
        </DashboardMain>
      </Route>
      <Route path="/kpi-laporan" exact>
        <DashboardMain>
          <KpiLaporan />
        </DashboardMain>
      </Route>
      <Route path="/kpi-laporan/:pegawai_id/:jabatan_id">
        <PrintLaporanKpi />
      </Route>
      {/* End HRD */}

      {/* Start Keuangan */}
      <Route path="/rkat">
        <DashboardMain>
          <Rkat />
        </DashboardMain>
      </Route>
      {/* End Keuangan */}

      {/* Start arus kas */}
      <Route path="/arus-kas">
        <DashboardMain>
          <ArusKas />
        </DashboardMain>
      </Route>
      {/* End arus kas */}

      {/* Start survey mahasiswa */}
      <Route path="/survey-mahasiswa">
        <DashboardMain>
          <SurveyMhs />
        </DashboardMain>
      </Route>
      {/* End survey mahasiswa */}

      {/* Start survey dosen */}
      <Route path="/survey-dosen">
        <DashboardMain>
          <SurveyDosen />
        </DashboardMain>
      </Route>
      {/* End survey dosen */}

      {/* Start survey pegawai */}
      <Route path="/survey-pegawai">
        <DashboardMain>
          <SurveyPegawai />
        </DashboardMain>
      </Route>
      {/* End survey pegawai */}
      
      {/* Start berita terkini */}
      <Route path="/berita-terkini">
        <DashboardMain>
          <BeritaTerkini />
        </DashboardMain>
      </Route>
      {/* End berita terkini */}

      {/* Start berita terkini */}
      <Route path="/perusahaan-alumni">
        <DashboardMain>
          <PerusahaanAlumni />
        </DashboardMain>
      </Route>
      {/* End berita terkini */}

      {/* Start berita terkini */}
      <Route path="/tutorial-sistem">
        <DashboardMain>
          <TutorialSistem />
        </DashboardMain>
      </Route>
      {/* End berita terkini */}

      {/* Start brosur digital */}
      <Route path="/brosur-digital">
        <DashboardMain>
          <BrosurDigital />
        </DashboardMain>
      </Route>
      {/* End brosur digital */}






      <Route path="/print-debug">
        <PrintDebug />
      </Route>












      {/* Master */}
      <Route path="/pegawai-master">
        <DashboardMain>
          <PegawaiMaster user={user} />
        </DashboardMain>
      </Route>
      <Route path="/import-export">
        <DashboardMain>
          <ImportExport user={user} />
        </DashboardMain>
      </Route>
      <Route path="/master-fix-absensi">
        <DashboardMain>
          <MasterFixAbsensi user={user} />
        </DashboardMain>
      </Route>
      <Route path="/master-lembur">
        <DashboardMain>
          <LemburMaster user={user} />
        </DashboardMain>
      </Route>
      <Route path="/master-generate-nilai-absensi">
        <DashboardMain>
          <GenerateNilaiAbsensi user={user} />
        </DashboardMain>
      </Route>
      {/* End Master */}
      <Route path="*">
        <DashboardMain>
          <NoMatch />
        </DashboardMain>
      </Route>
    </Switch>
  );
};

export default Routes;
