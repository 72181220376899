import React, { useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { patchPegawi } from "../../api";
import FormInput from "../../components/forms/Input";
import LayoutModal from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";
import { userState } from "../../storage/auth";

const PegawaiEdit = (props) => {
  const { customClass, customBtn, user } = props;
  const [modal, setModal] = useState(false);
  const tagForm = useRef();

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const setUser = useSetRecoilState(userState);

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    if (tagForm && tagForm.current) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await patchPegawi(user.id, {
        ...form,
        password_update: true,
      });
      if (res.data && res.data.nip) {
        setUser(res.data);
        handleClose();
        saAlert("success", "Berhasil update Profile");
      }
      if (res.data === "fail") {
        saAlert("warning", "Password lama salah !");
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  return (
    <>
      <button className={customClass} onClick={handleOpen}>
        {customBtn ?? "Update Password"}
      </button>
      <LayoutModal title="Update Profile" modal={modal} onClose={handleClose}>
        <form onSubmit={handleSubmit} ref={tagForm}>
          <div className="row">
            <div className="col-md-6">
              <FormInput
                label="Password Lama"
                type="password"
                name="old_password"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.old_password}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInput
                label="Password Baru"
                type="password"
                name="password"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.password}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Ulangi Password Baru"
                type="password"
                name="confirm_password"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.confirm_password}
              />
            </div>
          </div>
          {form.password !== form.confirm_password && (
            <p className="text-danger">
              Password & Ulangi Password harus sama !
            </p>
          )}
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success">
              Ubah
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={handleClose}
            >
              Tutup
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default PegawaiEdit;
