import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { fetchPengajaran } from "../../api";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { FilterSearch } from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import SectionPagination from "../../components/sections/Pagination";
import { titleState } from "../../storage/pages";

const MasterFixAbsensi = (props) => {
  const { user = {} } = props;
  const setTitle = useSetRecoilState(titleState);

  useEffect(() => setTitle("Fix Absensi"), [setTitle]);

  const [pengajaran, setPengajaran] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const fetchDataPengajaran = useCallback(async () => {
    try {
      const res = await fetchPengajaran(page, `paginate=20&search=${search}`);
      setPengajaran(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [page, search]);

  useEffect(() => fetchDataPengajaran(), [fetchDataPengajaran]);

  const absensiKrs = (absensi, krs) => {
    if (absensi && krs) {
      return parseInt(absensi) > parseInt(krs) ? (
        <span className="text-danger">*</span>
      ) : (
        <span className="text-success">*</span>
      );
    }
    return "";
  };

  if (user.jabatan === "IT Programmer") {
    return (
      <>
        <div className="row justify-content-end">
          <div className="col-md-6">
            <FilterSearch onChange={(res) => setSearch(res)} />
          </div>
        </div>
        <LayoutTable>
          <LayoutThead>
            <th className="text-nowrap">No</th>
            <th className="text-nowrap">Cabang</th>
            <th className="text-nowrap">Kelas ID</th>
            <th className="text-nowrap">Kelas Kode</th>
            <th className="text-nowrap">Matkul</th>
            <th className="text-nowrap">Dosen</th>
            <th className="text-nowrap">Status</th>
            <th className="text-nowrap">Pertemuan</th>
            <th className="text-nowrap">Tanggal</th>
            <th className="text-nowrap">Total Mhs</th>
            <th className="text-nowrap">Absensi</th>
            <th className="text-nowrap"></th>
          </LayoutThead>
          <tbody>
            {pengajaran.data &&
              pengajaran.data.length > 0 &&
              pengajaran.data
                .sort((a, b) => a.pertemuan - b.pertemuan)
                .map((i, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + pengajaran.from}</td>
                      <td>{i.kelas && i.kelas.cabang}</td>
                      <td className="text-center">{i.kelas_id}</td>
                      <td>{i.kelas && i.kelas.kode}</td>
                      <td>
                        {i.matkul && i.matkul.nama.length > 10
                          ? i.matkul.nama.substring(0, 20)
                          : i.matkul.nama}
                      </td>
                      <td>
                        {i.dosen && i.dosen.nama.length > 10
                          ? i.dosen.nama.substring(0, 20)
                          : i.dosen.nama}
                      </td>
                      <td className="text-center">{i.status}</td>
                      <td className="text-center">{i.pertemuan}</td>
                      <td className="text-center">{i.tanggal}</td>
                      <td className="text-center">{i.krs && i.krs.length}</td>
                      <td className="text-center">
                        {i.absensi &&
                          i.absensi.length > 0 &&
                          i.absensi.filter((a) => parseInt(a.pertemuan) === parseInt(i.pertemuan))
                            .length}{" "}
                        {absensiKrs(
                          i.absensi.length > 0 &&
                            i.absensi.filter((a) => parseInt(a.pertemuan) === parseInt(i.pertemuan))
                              .length,
                          i.krs && i.krs.length
                        )}
                      </td>
                      <td>
                        <ShowKrsAbsensi
                          pengajaran={i}
                          krs={i.krs}
                          absensi={i.absensi}
                        />
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </LayoutTable>

        {pengajaran.links && (
          <SectionPagination
            links={pengajaran.links}
            onChangePage={(res) => setPage(res)}
          />
        )}
      </>
    );
  } else {
    return <PageLoading />;
  }
};

const ShowKrsAbsensi = (props) => {
  const { pengajaran, krs, absensi } = props;
  const [modal, setModal] = useState(false);

  const getConditionMhs = (i, krs) => {
    const data = krs.filter((k) => parseInt(k.mhs_id) === parseInt(i.mhs_id)).length;

    if (data === 1) {
      return <span className="text-success">*</span>;
    }

    if (data > 1) {
      return <span className="text-warning">*</span>;
    }

    if (data === 0) {
      return <span className="text-danger">*</span>;
    }
  };

  return (
    <>
      <span className="badge badge-success" onClick={() => setModal(true)}>
        Open
      </span>
      <LayoutModal
        title="Detail Pengajaran"
        modal={modal}
        onClose={() => setModal(false)}
      >
        <h5>
          <div className="row border-top border-bottom border-dark py-3 mb-2">
            <div className="col-4">Pertemuan</div>
            <div className="col-8">: {pengajaran.pertemuan}</div>
            <div className="col-4">Pengajaran</div>
            <div className="col-8">: {pengajaran.id}</div>
          </div>
        </h5>
        <div className="row">
          <div className="col-4">
            <h3>KRS</h3>
            <LayoutTable>
              <LayoutThead>
                <th>No</th>
                <th>KRS ID</th>
                <th>Mhs ID</th>
                <th>Kelas ID</th>
              </LayoutThead>
              <tbody>
                {krs.length > 0 &&
                  krs.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{i.id}</td>
                        <td className="text-center">{i.mhs_id}</td>
                        <td className="text-center">{i.kelas_id}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </LayoutTable>
          </div>
          <div className="col-8">
            <h3>Absensi</h3>
            <LayoutTable>
              <LayoutThead>
                <th>No</th>
                <th>ID Absensi</th>
                <th>Mhs ID</th>
                <th>Pengajaran ID</th>
                <th>Pertemuan</th>
                <th>Status</th>
              </LayoutThead>
              <tbody>
                {absensi.length > 0 &&
                  absensi
                    .filter((i) => parseInt(i.pertemuan) === parseInt(pengajaran.pertemuan))
                    .map((i, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td className="text-center">{i.id}</td>
                          <td className="text-center">
                            {i.mhs_id} {getConditionMhs(i, krs)}
                          </td>
                          <td className="text-center">{i.pengajaran_id}</td>
                          <td className="text-center">{i.pertemuan}</td>
                          <td className="text-center">{i.status}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </LayoutTable>
          </div>
        </div>
        <h3>Duplicate</h3>
        {/*  */}
      </LayoutModal>
    </>
  );
};

export default MasterFixAbsensi;
