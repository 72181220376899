import React from "react";
import { MultipleMenu, SinggleMenu } from "./Sidebar";

const Aktivitas = (props) => {
  const { user } = props;
  return (
    <>
      {user.jabatan === "IT Programmer" ||
      user.jabatan === "Dir. Pusat Karir dan Kerjasama" ||
      user.jabatan === "Kepala Cabang Denpasar" ||
      user.jabatan === "Kepala Cabang Singaraja" ||
      user.jabatan === "Kepala Cabang Karangasem" ||
      user.jabatan === "Dir. Akademik & Kemahasiswaan" ||
      user.jabatan === "Ka. Unit UPT" ||
      user.jabatan === "Bagian UPT" ||
      user.jabatan === "Ka. Unit Resepsionis" ||
      user.jabatan === "Resepsionis" ||
      user.group === "Akademik" ? (
        <>
          <hr className="border-0 my-2" />

          <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
            Aktivitas
          </div>

          <hr className="sidebar-divider border-0 mt-2 mb-0" />

          <SinggleMenu
            title="Pengajaran"
            link="/pengajaran"
            fa="fa-calendar-check"
          />

          {user.jabatan === "IT Programmer" ||
          user.jabatan === "Dir. Akademik & Kemahasiswaan" ||
          user.jabatan === "Kepala Cabang Denpasar" ||
          user.jabatan === "Kepala Cabang Singaraja" ||
          user.jabatan === "Kepala Cabang Karangasem" ||
          user.jabatan === "Ka. Bag. Akademik & Kemahasiswaaan" ||
          user.jabatan === "Ka. Unit AAK" ||
          user.jabatan === "Ka. Unit Kemahasiswaaan" ? (
            <MultipleMenu
              title="Aktivitas Mahasiswa"
              fa="fa-folder-open"
              links={[
                { title: "Bidang", link: "/bidang" },
                { title: "Penyelenggara", link: "/penyelenggara" },
                { title: "Program Kampus", link: "/program-kampus" },
                { title: "Lepasan", link: "/program-lepasan" },
              ]}
            />
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default Aktivitas;
