import React, { useEffect, useState, useCallback, createContext } from "react";
import { useSetRecoilState } from "recoil";
import { titleState } from "../../storage/pages";
import { Switch, Route } from "react-router-dom";
import { fetchDepartemen } from "./apis";
import Departemen from "./pages/Departemen";
import Create from "./pages/Create";
import Edit from "./pages/Edit";

export const DepartemenContext = createContext();

const DepartemenRoutes = () => {
  const setTitle = useSetRecoilState(titleState);
  const mainUrl = "/departemen";

  const [page, setPage] = useState(1);

  const [departemen, setDepartemen] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchDepartemen(page);
      if (res.data && res.data.departemen) {
        setDepartemen(res.data.departemen);
        setTitle(res.data.title);
      }
    } catch (err) {
      console.error(err.response);
    }
  }, [setTitle, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <DepartemenContext.Provider value={{ departemen, setPage }}>
      <Switch>
        <Route exact path={mainUrl}>
          <Departemen mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
        <Route path={`${mainUrl}/create`}>
          <Create mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
        <Route path={`${mainUrl}/:id/edit`}>
          <Edit mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
      </Switch>
    </DepartemenContext.Provider>
  );
};

export default DepartemenRoutes;
