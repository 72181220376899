import React, { useRef, useState } from "react";
import { postLinkSurvei } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import { saAlert } from "../../helpers";

const LinkSurveiCreate = (props) => {
  const { onFetchData } = props;
  const formRef = useRef();

  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleClose = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await postLinkSurvei(form);
      if (res.data && res.data.msg === "success") {
        onFetchData();
        handleClose();
        saAlert("success", "Berhasil tambah data link survei !");
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  return (
    <>
      <LayoutAddBtn onClick={() => setModal(true)} add="Tambah Data" />
      <LayoutModal
        modal={modal}
        title="Tambah Data Penyelenggara"
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={formRef}>
          <div className="row">
            <div className="col-6">
              <FormSelect
                label="cabang"
                name="cabang"
                onChange={handleChange}
                error={errors.cabang}
              >
                <option value="">Pilih</option>
                <option value="pusat">pusat</option>
                <option value="denpasar">denpasar</option>
                <option value="singaraja">singaraja</option>
                <option value="karangasem">karangasem</option>
              </FormSelect>
            </div>
            <div className="col-12">
              <FormInput
                label="judul"
                name="judul"
                onChange={handleChange}
                error={errors.judul}
              />
            </div>
            <div className="col-12">
              <FormInput
                label="link"
                name="link"
                onChange={handleChange}
                error={errors.link}
              />
            </div>
          </div>
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success">
              Simpan
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={handleClose}
            >
              Batal
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default LinkSurveiCreate;
