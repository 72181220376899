import React from "react";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";

const PendaftarTableDetail = ({ pendaftar }) => {
  return (
    <LayoutTable>
      <LayoutThead className="text-nowrap">
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">no</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Afiliasi</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">status</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">cabang</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Nama</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Tempat Lahir</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Tanggal Lahir</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Nama Ayah</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Nama Ibu</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Alamat Ortu</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">No HP</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">No HP Ortu</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Email</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Facebook</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Instagram</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Jenis Kelamin</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Agama</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Asal Sekolah</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Tahun Tamat</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Jurusan</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Waktu Belajar</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Informasi</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Keputusan Kuliah</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Refrensi</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Promo</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Prodi</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">No Surat</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">KDBB</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Update oleh</th>
        <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Tahun Ajaran</th>
      </LayoutThead>
      <tbody>
        {pendaftar.data.map((i, index) => {
          return (
            <tr key={index}>
              <td className="border-silver text-center text-nowrap">{pendaftar.from + index}</td>
              <td className="border-silver text-center text-nowrap">{i.afiliasi_id}</td>
              <td className="border-silver text-nowrap px-2">
              {!i.status && (
                <div className="text-secondary">
                  <i className="fa fa-fw fa-sm fa-ban me-1"/>
                  <span className="fw-semibold">Belum Daftar</span>
                </div>
              )}
              {parseInt(i.status) === 1 && (
                // <span className="fw-semibold text-warning">Sudah Daftar</span>
                <div className="text-success">
                  <i className="fa fa-fw fa-sm fa-circle-check me-1"/>
                  <span className="fw-semibold">Sudah Daftar</span>
                </div>
              )}
              {parseInt(i.status) === 2 && (
                // <span className="fw-semibold text-success">Sudah DU</span>
                <div className="text-info">
                  <i className="fa fa-fw fa-sm fa-circle-check me-1"/>
                  <span className="fw-semibold">Sudah DU</span>
                </div>
              )}
              {parseInt(i.status) === 3 && (
                // <span className="fw-semibold text-info">Sudah Cair</span>
                <div className="text-purple">
                  <i className="fa fa-fw fa-sm fa-sack-dollar me-1"/>
                  <span className="fw-semibold">Sudah Cair</span>
                </div>
              )}
              {parseInt(i.status) === 4 && (
                // <span className="fw-semibold text-primary">Mhs Aktif</span>
                <div className="text-primary-2">
                  <i className="fa fa-fw fa-sm fa-circle-user me-1"/>
                  <span className="fw-semibold">Mhs Aktif</span>
                </div>
              )}
              {parseInt(i.status) === 9 && (
                // <span className="fw-semibold text-danger">Batal Daftar</span>
                <div className="text-danger">
                  <i className="fa fa-fw fa-sm fa-circle-xmark me-1"/>
                  <span className="fw-semibold">Batal Daftar</span>
                </div>
              )}
              </td>
              <td className="border-silver text-center text-nowrap">{i.cabang}</td>
              <td className="border-silver text-nowrap">{i.nama}</td>
              <td className="border-silver text-nowrap">{i.tempat_lahir}</td>
              <td className="border-silver text-center text-nowrap">{i.tanggal_lahir}</td>
              <td className="border-silver text-nowrap">{i.nama_ayah}</td>
              <td className="border-silver text-nowrap">{i.nama_ibu}</td>
              <td className="border-silver text-nowrap">{i.alamat_ortu}</td>
              <td className="border-silver text-center text-nowrap">{i.no_hp}</td>
              <td className="border-silver text-center text-nowrap">{i.no_hp_ortu}</td>
              <td className="border-silver text-nowrap">{i.email}</td>
              <td className="border-silver text-nowrap">{i.fb}</td>
              <td className="border-silver text-nowrap">{i.ig}</td>
              <td className="border-silver text-center text-nowrap">{i.jenis_kelamin}</td>
              <td className="border-silver text-center text-nowrap">{i.agama}</td>
              <td className="border-silver text-nowrap">{i.asal_sekolah}</td>
              <td className="border-silver text-center text-nowrap">{i.tahun_tamat}</td>
              <td className="border-silver text-nowrap">{i.jurusan}</td>
              <td className="border-silver text-center text-nowrap">{i.waktu_belajar}</td>
              <td className="border-silver text-nowrap">{i.informasi}</td>
              <td className="border-silver text-nowrap">{i.keputusan_kuliah}</td>
              <td className="border-silver text-nowrap">{i.refrensi}</td>
              <td className="border-silver text-nowrap">{i.promo}</td>
              <td className="border-silver text-nowrap">{i.prodi}</td>
              <td className="border-silver text-center text-nowrap">{i.no_surat}</td>
              <td className="border-silver text-center text-nowrap">{i.kdbb ? "Yes" : "No"}</td>
              <td className="border-silver text-nowrap">{i.updated_by}</td>
              <td className="border-silver text-center text-nowrap">{i.tahun_ajaran}</td>
            </tr>
          );
        })}
      </tbody>
    </LayoutTable>
  );
};

export default PendaftarTableDetail;
