import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deletePojt, fetchPerusahaan } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterCabang,
  FilterSearch,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import { formatDate, saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import { periodeOjtState } from "../../storage/mhs";
import { titleState } from "../../storage/pages";
import PerusahaanAktifOjt from "./AktifOjt";
import CountOnTop from "./CountOnTop";
import PerusahaanEdit from "./Edit";

const PerusahaanUlangTahun = () => {
  const setTitle = useSetRecoilState(titleState);
  const periodeOjt = useRecoilValue(periodeOjtState);
  const user = useRecoilValue(userState);

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState();
  const [count, setCount] = useState({});

  const [page] = useState("all");
  const [search, setSearch] = useState("");
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchPerusahaan(
        page,
        `notification_ulang_tahun=1&search=${search}&cabang=${cabang}`
      );
      setItems(res.data && res.data.perusahaan);
      res.data &&
        res.data.baru_mou &&
        setCount((prevState) => {
          return { ...prevState, baru: res.data.baru_mou };
        });
      res.data &&
        res.data.perpanjang_mou &&
        setCount((prevState) => {
          return { ...prevState, perpanjang: res.data.perpanjang_mou };
        });
      res.data &&
        res.data.belum_mou &&
        setCount((prevState) => {
          return { ...prevState, belum: res.data.belum_mou };
        });
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page, search, cabang]);

  useEffect(() => {
    setTitle("Perusahaan Ulang Tahun");
    fetchData();
  }, [fetchData, setTitle]);

  if (isLoaded) {
    return (
      <>
        <CountOnTop
          baru={count.baru}
          perpanjang={count.perpanjang}
          belum={count.belum}
        />
        <h6 className="mb-3 fw-semibold text-dark"><i className="fa fa-fw fa-bell text-warning"/> Notifikasi ini akan muncul 7 hari sebelum ulang tahun perusahaan.</h6>
        <div className="row mb-2">
          <div className="col-md-6">
            {user.cabang === "pusat" && (
              <FilterCabang onChange={(res) => setCabang(res)} pusat={true} />
            )}
          </div>
          <div className="col-md-6">
            <FilterSearch onChange={(res) => setSearch(res)} />
          </div>
        </div>
        {items.length > 0 ? (
          <div className="row">
            <div className="col-4">
              <LayoutTable>
                <LayoutThead>
                  <th>No</th>
                  <th>Nama</th>
                  <th>PIC</th>
                  <th>MoU</th>
                </LayoutThead>
                <tbody>
                  {items.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-nowrap">{i.nama}</td>
                        <td className="text-nowrap">{i.pic}</td>
                        <td className="text-nowrap text-center">
                          {i.status === 1 && (
                            <span className="text-info">Baru</span>
                          )}
                          {i.status === 2 && (
                            <span className="text-success">Perpanjang</span>
                          )}
                          {i.status === 3 && (
                            <span className="text-warning">Belum MoU</span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
            <div className="col-6">
              <LayoutTable>
                <LayoutThead>
                  <th>Cabang</th>
                  <th>Dari</th>
                  <th>Sampai</th>
                  <th>Alamat</th>
                  <th className="text-nowrap">No Telfon</th>
                  <th className="text-nowrap">No HP</th>
                  <th>Email</th>
                  <th>Ultah</th>
                  <th>Jurusan</th>
                  <th>Kuota</th>
                </LayoutThead>
                <tbody>
                  {items.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td
                          style={{ textTransform: "capitalize" }}
                          className="text-center"
                        >
                          {i.cabang}
                        </td>
                        <td className="text-nowrap">
                          {formatDate(i.mou_dari)}
                        </td>
                        <td className="text-nowrap">
                          {formatDate(i.mou_sampai)}
                        </td>
                        <td className="text-nowrap">{i.alamat}</td>
                        <td className="text-nowrap">{i.no_telf}</td>
                        <td className="text-nowrap">{i.no_hp}</td>
                        <td className="text-nowrap">{i.email}</td>
                        <td className="text-nowrap">{formatDate(i.ultah)}</td>
                        <td className="text-nowrap">{i.jurusan}</td>
                        <td className="text-nowrap text-center">{i.kuota}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
            <div className="col-2">
              <LayoutTable>
                <LayoutThead>
                  <th>OJT</th>
                  <th>
                    <i className="fa fa-bars" />
                  </th>
                </LayoutThead>
                <tbody>
                  {items.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center">
                          {i.pojt && i.pojt.length > 0 ? (
                            i.pojt
                              .filter(
                                (p) =>
                                  parseInt(p.periode) === parseInt(periodeOjt)
                              )
                              .map((p) => {
                                return (
                                  <div key={p.id} className="text-nowrap">
                                    <span className="text-success mr-2">
                                      Aktif
                                    </span>
                                    <span
                                      type="button"
                                      className="badge badge-danger"
                                      onClick={() => {
                                        saConfirm(
                                          "warning",
                                          "Yakin hapus dari aktif OJT ?"
                                        ).then((res) => {
                                          if (res.isConfirmed) {
                                            deletePojt(p.id)
                                              .then((res) => {
                                                if (res === "success") {
                                                  fetchData();
                                                  saAlert(
                                                    "success",
                                                    "Berhasil hapus dari aktif OJT !"
                                                  );
                                                }
                                              })
                                              .catch((err) => console.log(err));
                                          }
                                        });
                                      }}
                                    >
                                      <i className="fa fa-trash-alt" />
                                    </span>
                                  </div>
                                );
                              })
                          ) : (
                            <PerusahaanAktifOjt
                              perusahaan={i}
                              onFetchData={fetchData}
                            />
                          )}
                        </td>
                        <td className="text-nowrap text-center">
                          <PerusahaanEdit
                            perusahaan={i}
                            onFetchData={fetchData}
                          />
                          <i className="mr-1" />
                          <span
                            className="badge badge-danger"
                            type="button"
                            onClick={() =>
                              alert("Oops, sedang dalam perbaikan !")
                            }
                          >
                            <i className="fa fa-trash-alt" />
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
          </div>
        ) : (
          <PageNoData />
        )}
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default PerusahaanUlangTahun;
