import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deletePojt, fetchJurusan, fetchPojt, patchPojt } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterCabang,
  FilterJurusan,
  FilterPagination,
  FilterSearch,
} from "../../components/layouts/TopContent";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { formatDate, getJurusanShort, saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import { periodeOjtState } from "../../storage/mhs";
import { titleState } from "../../storage/pages";
import { KelompokOjt } from "../mhs/Ojt";
import { FilterTahunAjaranPerusahaan } from "../perusahaan/Components";
import { BtnForPerusahaan } from "../perusahaan/Helper";
import PojtAddMahasiswa from "./AddMahasiswa";
import PojtEdit from "./Edit";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import LoadingBasic from "../../components/pages/LoadingBasic";

const PojtPkk = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const periodeOjt = useRecoilValue(periodeOjtState);

  const [isLoaded, setIsLoaded] = useState(false);

  const [page, setPage] = useState(1);

  const [search, setSearch] = useState("");
  const [cabang, setCabang] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [tahunAjaran, setTahunAjaran] = useState(periodeOjt);
  const [pagination, setPagination] = useState(10);

  const [items, setItems] = useState({});

  const fetchData = useCallback(
    async (data = false) => {
      const { triggerIsLoaded = true } = data;
      if (triggerIsLoaded) {
        setIsLoaded(false);
      }

      try {
        const res = await fetchPojt(
          page,
          `search=${search}&pagination=${pagination}&cabang=${cabang}&jurusan=${jurusan}&tahun=${tahunAjaran}`
        );
        setItems(res.data.pojt);
        setIsLoaded(true);
      } catch (err) {
        console.log(err);
      }
    },
    [page, search, pagination, cabang, jurusan, tahunAjaran]
  );

  useEffect(() => {
    setTitle("Perusahaan OJT");
    fetchData();
  }, [setTitle, fetchData]);

  const [dataJurusan, setDataJurusan] = useState([]);

  useEffect(() => {
    const fetchDataJurusan = async () => {
      const res = await fetchJurusan("all");
      setDataJurusan(res.data);
    };

    fetchDataJurusan();
  }, []);

  const handleUpdate = (id, status, message) => {
    saConfirm("warning", `Yakin update status menjadi ${message} ?`).then(
      (res) => {
        if (res.isConfirmed) {
          patchPojt(id, { status }).then((res) => {
            if (res.status === 200) {
              fetchData();
              saAlert("success", `Berhasil update status menjadi ${message}`);
            }
          });
        }
      }
    );
  };

  const getColorForJurusanMahasiswa = (pojt, pojt_jurusan) => {
    let mhs_jur =
      pojt.mhs.length > 0 &&
      pojt.mhs.filter(
        (m) => parseInt(m.jurusan_id) === parseInt(pojt_jurusan.jurusan_id)
      ).length;
    if (parseInt(mhs_jur) === parseInt(pojt_jurusan.jumlah_mhs)) {
      return "bg-primary text-white";
    } else if (parseInt(mhs_jur) < parseInt(pojt_jurusan.jumlah_mhs)) {
      return "bg-warning";
    } else if (parseInt(mhs_jur) > parseInt(pojt_jurusan.jumlah_mhs)) {
      return "bg-danger text-white";
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-4 mb-3">
          <FilterJurusan onChange={(res) => setJurusan(res)} />
        </div>
        <div className="col-lg-4 mb-3">
          <FilterSearch onChange={(res) => setSearch(res)} />
        </div>
        <div className="col-lg-4 mb-3">
          <FilterTahunAjaranPerusahaan
            tahunAjaran={tahunAjaran}
            onChange={(res) => setTahunAjaran(res)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 mb-3">
          <FilterCabang onChange={(res) => setCabang(res)} />
        </div>
        <div className="col-lg-4 mb-3">
          <FilterPagination
            value={pagination}
            onChange={(res) => setPagination(res)}
          />
        </div>
      </div>

      <br />
      <BtnForPerusahaan />

      <div className="mb-3">
        <p className="fw-semibold text-black mb-0"><span className="d-inline-block align-middle bg-warning rounded py-2 px-4"></span> = Jumlah kurang</p>
        <p className="fw-semibold text-black mb-0"><span className="d-inline-block align-middle bg-primary rounded py-2 px-4"></span> = Jumlah pas</p>
        <p className="fw-semibold text-black mb-0"><span className="d-inline-block align-middle bg-danger rounded py-2 px-4"></span> = Jumlah lebih</p>
      </div>

      {/* <p>
        Warna Jumlah & Jurusan{" "}
        <div className="text-warning">Jumlah Kurang</div>{" "}
        <div className="text-success">Jumlah Pas</div>{" "}
        <div className="text-danger">Jumlah Lebih</div>
      </p> */}

      {isLoaded ? (
        items.data.length > 0 ? (
          <TableNew striped={false} hover={false}>
            <Thead tr={true}>
              <Tr className="text-center">
                <Th rowSpan={2} className="text-nowrap align-middle">
                  No
                </Th>
                <Th rowSpan={2} className="text-nowrap align-middle">
                  Cabang
                </Th>
                <Th rowSpan={2} className="text-nowrap align-middle">
                  Nama
                </Th>
                <Th rowSpan={2} className="text-nowrap align-middle">
                  No Surat
                </Th>
                <Th rowSpan={2} className="text-nowrap align-middle" colSpan={2}>
                  Jurusan & Jumlah
                </Th>
                <Th className="text-nowrap align-middle" colSpan={6}>
                  Peserta
                </Th>
                <Th rowSpan={2} className="text-nowrap align-middle">
                  Surat
                </Th>
                <Th rowSpan={2} className="text-nowrap align-middle">
                  Update Surat
                </Th>
                <Th rowSpan={2} className="text-nowrap align-middle">
                  Dari/sampai
                </Th>
                <Th rowSpan={2} className="text-nowrap align-middle">
                  Keterangan
                </Th>
                <Th rowSpan={2} className="text-nowrap align-middle">
                  Cetak
                </Th>
                <Th rowSpan={2} className="text-nowrap align-middle">
                  <i className="fa fa-fw fa-cog"></i>
                </Th>
              </Tr>
              <Tr className="text-center">
                <Th>NIM</Th>
                <Th>Nama</Th>
                <Th>Jurusan</Th>
                <Th>No HP</Th>
                <Th>Kelompok</Th>
                <Th>-</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <Tr>
                      <Td rowSpan={2} className="text-center">
                        {items.from + index}
                      </Td>
                      <Td rowSpan={2} className="text-center">
                        {i.perusahaan.cabang}
                      </Td>
                      <Td rowSpan={2}>{i.perusahaan.nama}</Td>
                      <Td rowSpan={2}>{i.no_surat}</Td>
                      <Td rowSpan={2} className="text-nowrap">
                        {i.pojt_jurusan &&
                          i.pojt_jurusan.length > 0 &&
                          i.pojt_jurusan.map((pj, index) => {
                            return (
                              <div
                                key={index}
                                className={`rounded ${getColorForJurusanMahasiswa(
                                  i,
                                  pj
                                )}`}
                              >
                                <div className="mb-1 px-1 text-center">{getJurusanShort(parseInt(pj.jurusan_id))}</div>
                              </div>
                            );
                          })}
                      </Td>
                      <Td rowSpan={2} className="text-nowrap text-center">
                        {i.pojt_jurusan &&
                          i.pojt_jurusan.length > 0 &&
                          i.pojt_jurusan.map((pj, index) => {
                            return (
                              <div
                                key={index}
                                className={`rounded ${getColorForJurusanMahasiswa(
                                  i,
                                  pj
                                )}`}
                              >
                                <div className="mb-1 px-1 text-center">{parseInt(pj.jumlah_mhs)}</div>
                              </div>
                            );
                          })}
                      </Td>
                      <Td className="text-nowrap text-center">
                        {i.mhs.length > 0
                          ? i.mhs.map((m, index) => {
                            return (
                              <div
                                key={index}
                                className={`${index % 2 === 0 ? "bg-light" : "bg-white"
                                  } ${i.pojt_jurusan &&
                                    i.pojt_jurusan.filter(
                                      (pj) =>
                                        parseInt(pj.jurusan_id) ===
                                        parseInt(m.jurusan_id)
                                    ).length > 0
                                    ? "text-success"
                                    : "text-danger"
                                  }`}
                                style={{
                                  marginBottom: "0.72rem",
                                  marginTop: "0.45rem",
                                  display: "block",
                                }}
                              >
                                {m.nim}
                              </div>
                            );
                          })
                          : "-"}
                      </Td>
                      <Td className="text-nowrap">
                        {i.mhs.length > 0
                          ? i.mhs.map((m, index) => {
                            return (
                              <div
                                key={index}
                                className={`${index % 2 === 0 ? "bg-light" : "bg-white"
                                  } ${i.pojt_jurusan &&
                                    i.pojt_jurusan.filter(
                                      (pj) =>
                                        parseInt(pj.jurusan_id) ===
                                        parseInt(m.jurusan_id)
                                    ).length > 0
                                    ? "text-success"
                                    : "text-danger"
                                  }`}
                                style={{
                                  marginBottom: "0.72rem",
                                  marginTop: "0.45rem",
                                  display: "block",
                                }}
                              >
                                {m.nama}
                              </div>
                            );
                          })
                          : "-"}
                      </Td>
                      <Td className="text-nowrap text-center">
                        {i.mhs.length > 0
                          ? i.mhs.map((m, index) => {
                            return (
                              <div
                                key={index}
                                className={`${index % 2 === 0 ? "bg-light" : "bg-white"
                                  } ${i.pojt_jurusan &&
                                    i.pojt_jurusan.filter(
                                      (pj) =>
                                        parseInt(pj.jurusan_id) ===
                                        parseInt(m.jurusan_id)
                                    ).length > 0
                                    ? "text-success"
                                    : "text-danger"
                                  }`}
                                style={{
                                  marginBottom: "0.72rem",
                                  marginTop: "0.45rem",
                                  display: "block",
                                }}
                              >
                                {getJurusanShort(parseInt(m.jurusan_id))}
                              </div>
                            );
                          })
                          : "-"}
                      </Td>
                      <Td className="text-nowrap text-center">
                        {i.mhs.length > 0
                          ? i.mhs.map((m, index) => {
                            return (
                              <div
                                key={index}
                                className={`${index % 2 === 0 ? "bg-light" : "bg-white"
                                  } ${i.pojt_jurusan &&
                                    i.pojt_jurusan.filter(
                                      (pj) =>
                                        parseInt(pj.jurusan_id) ===
                                        parseInt(m.jurusan_id)
                                    ).length > 0
                                    ? "text-success"
                                    : "text-danger"
                                  }`}
                                style={{
                                  marginBottom: "0.72rem",
                                  marginTop: "0.45rem",
                                  display: "block",
                                }}
                              >
                                {m.no_hp}
                              </div>
                            );
                          })
                          : "-"}
                      </Td>
                      <Td className="text-nowrap text-center">
                        {i.mhs.length > 0
                          ? i.mhs.map((m, index) => {
                            return (
                              <div
                                key={index}
                                className={`${index % 2 === 0 ? "bg-light" : "bg-white"
                                  } ${i.pojt_jurusan &&
                                    i.pojt_jurusan.filter(
                                      (pj) =>
                                        parseInt(pj.jurusan_id) ===
                                        parseInt(m.jurusan_id)
                                    ).length > 0
                                    ? "text-success"
                                    : "text-danger"
                                  }`}
                                style={{
                                  marginBottom: "0.72rem",
                                  marginTop: "0.45rem",
                                  display: "block",
                                }}
                              >
                                {m.pivot && m.pivot.kelompok_nim ? (
                                  m.pivot.kelompok_nim
                                ) : (
                                  <span className="text-danger">No Team</span>
                                )}
                              </div>
                            );
                          })
                          : "-"}
                      </Td>
                      <Td className="text-nowrap">
                        {i.mhs.length > 0
                          ? i.mhs.map((m, index) => {
                            return (
                              <div
                                key={index}
                                className={`${i.pojt_jurusan &&
                                    i.pojt_jurusan.filter(
                                      (pj) =>
                                        parseInt(pj.jurusan_id) ===
                                        parseInt(m.jurusan_id)
                                    ).length > 0
                                    ? "text-success"
                                    : "text-danger"
                                  }`}
                              >
                                <KelompokOjt
                                  mhs_pojt={m.pivot}
                                  mhs={i.mhs}
                                  onFetchData={fetchData}
                                />
                              </div>
                            );
                          })
                          : "-"}
                      </Td>
                      <Td rowSpan={2} className="text-center text-nowrap">
                        {i.perusahaan &&
                          i.perusahaan.cabang.toLowerCase() ===
                          user.cabang.toLowerCase() ? (
                          <>
                            {i.status === null && (
                              <>
                                <button
                                  className="btn btn-sm btn-warning-2 fw-medium w-100"
                                  onClick={() =>
                                    handleUpdate(i.id, 1, "Diberikan")
                                  }
                                >
                                  Diberikan
                                </button>
                              </>
                            )}
                            {i.status === 1 && (
                              <>
                                <button
                                  className="btn btn-sm btn-dark border-0 w-100"
                                  onClick={() => handleUpdate(i.id, 2, "Kembali")}
                                >
                                  Kembali
                                </button>
                              </>
                            )}
                            {i.status === 2 && (
                              <>
                                <div className="text-success fw-medium"><i className="fa fa-fw fa-check" /> Selesai</div>
                              </>
                            )}
                          </>
                        ) : (
                          "-"
                        )}
                      </Td>
                      <Td rowSpan={2} className="text-nowrap text-center">
                        {i.approved ? (
                          <div className="text-success">Oleh : {i.approved}</div>
                        ) : (
                          "-"
                        )}
                      </Td>
                      <Td rowSpan={2} className="text-nowrap">
                        <div className="d-flex align-items-center">
                          <span className="fw-semibold me-2">2 Bulan OJT :</span>
                          {i.dari_2 && i.sampai_2 ?
                            <>
                              <p className="mb-0">{formatDate(i.dari_2)}</p>
                              <p className="mb-0 mx-2">-</p>
                              <p className="mb-0">{formatDate(i.sampai_2)}</p>
                            </>
                            :
                            <span className="text-secondary text-sm">Kosong</span>
                          }
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-semibold me-2">3 Bulan OJT :</span>
                          {i.dari_4 && i.sampai_4 ?
                            <>
                              <p className="mb-0">{formatDate(i.dari_4)}</p>
                              <p className="mb-0 mx-2">-</p>
                              <p className="mb-0">{formatDate(i.sampai_4)}</p>
                            </>
                            :
                            <span className="text-secondary text-sm">Kosong</span>
                          }
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="fw-semibold me-2">6 Bulan OJT :</span>
                          {i.dari_6 && i.sampai_6 ?
                            <>
                              <p className="mb-0">{formatDate(i.dari_6)}</p>
                              <p className="mb-0 mx-2">-</p>
                              <p className="mb-0">{formatDate(i.sampai_6)}</p>
                            </>
                            :
                            <span className="text-secondary text-sm">Kosong</span>
                          }
                        </div>
                      </Td>
                      <Td rowSpan={2}>{i.penempatan}</Td>
                      <Td rowSpan={2} className="text-nowrap">
                        <select
                          className="border-silver rounded-3 form-select-custom"
                          value="opsi"
                          onChange={(e) => window.open(e.target.value)}
                        >
                          <option value="opsi" disabled>
                            Opsi
                          </option>
                          <option value={`/pojt-pkk/print-surat/${i.id}`}>
                            Surat
                          </option>
                          <option value={`/pojt-pkk/print-proposal/${i.id}`}>
                            Proposal
                          </option>
                        </select>
                      </Td>
                      <Td rowSpan={2} className="text-center">
                        {i.status !== 1 ?
                          <div className="text-nowrap">
                            <PojtEdit
                              perusahaan={i.perusahaan}
                              pojt={i}
                              jurusan={dataJurusan}
                              onFetchData={fetchData}
                            />
                            <button
                              type="button"
                              className="btn btn-sm btn-danger-2 text-sm py-0 px-1"
                              onClick={() => {
                                if (
                                  user.cabang === "pusat" ||
                                  i.perusahaan?.cabang.toLowerCase() === user.cabang.toLowerCase()
                                ) {
                                  saConfirm(
                                    "warning",
                                    "Yakin hapus dari aktif OJT ?"
                                  ).then((res) => {
                                    if (res.isConfirmed) {
                                      deletePojt(i.id)
                                        .then((res) => {
                                          if (res.data === "success") {
                                            fetchData();
                                            saAlert(
                                              "success",
                                              "Berhasil hapus dari aktif OJT !"
                                            );
                                          }
                                        })
                                        .catch((err) => console.log(err));
                                    }
                                  });
                                } else {
                                  saAlert(
                                    "warning",
                                    "Tidak dapat mengubah data cabang lain."
                                  );
                                }
                              }}
                            >
                              <i className="fa fa-fw fa-sm fa-trash-alt" />
                            </button>
                          </div>
                          :
                          <div>
                            <p className="mb-0 text-secondary">Tidak dapat mengedit ketika surat telah diberikan.</p>
                          </div>
                        }
                      </Td>
                    </Tr>
                    <Tr className={index % 2 === 0 ? "bg-light" : "bg-white"}>
                      <Td colSpan={6} className="text-center">
                        <PojtAddMahasiswa
                          perusahaan={i.perusahaan}
                          pojt_id={i.id}
                          tahun={tahunAjaran}
                          onFetchData={(res) => fetchData(res)}
                        />
                      </Td>
                    </Tr>
                  </React.Fragment>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <div className="my-3 p-3 rounded text-black" style={{backgroundColor: 'rgb(255, 0, 0, 0.3)'}}>
            <i className="fa fa-fw fa-circle-exclamation"/> Tidak ada data perusahaan.
          </div>
        )
      ) : (
        <div className='py-5'>
          <LoadingBasic />
        </div>
      )}
      <SectionPagination
        links={items.links}
        onChangePage={(res) => setPage(res)}
      />
    </>
  );
};

export default PojtPkk;
