import React, { useState, useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deleteLinkSurvei, fetchLinkSurvei } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterCabang,
  FilterSearch,
} from "../../components/layouts/TopContent";
import SectionPagination from "../../components/sections/Pagination";
import { saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import LinkSurveiCreate from "./Create";
import LinkSurveiEdit from "./Edit";

const LinkSurvei = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);

  useEffect(() => setTitle("Link Survei"), [setTitle]);

  const [linkSurvei, setLinkSurvei] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchLinkSurvei(
        page,
        `search=${search}&cabang=${cabang}`
      );
      if (res.data && res.data.link_survei) {
        setLinkSurvei(res.data.link_survei);
      }
    } catch (err) {
      console.error(err);
    }
  }, [page, search, cabang]);

  useEffect(() => fetchData(), [fetchData]);

  return (
    <>
      <LinkSurveiCreate onFetchData={fetchData} />
      {/* Filter 1 */}
      <div className="row mb-3">
        <div className="col-md-4">
          {user.cabang === "pusat" && (
            <FilterCabang
              pusat={true}
              onChange={(res) => {
                setCabang(res);
                setPage(1);
              }}
            />
          )}
        </div>
        <div className="col-md-4">
          <FilterSearch
            onChange={(res) => {
              setSearch(res);
              setPage(1);
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-5">
          <LayoutTable>
            <LayoutThead>
              <th>no</th>
              <th>cabang</th>
              <th>judul</th>
            </LayoutThead>
            <tbody>
              {linkSurvei.data &&
                linkSurvei.data.length > 0 &&
                linkSurvei.data.map((ls, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle text-center">
                        {linkSurvei.from + index}
                      </td>
                      <td className="align-middle text-nowrap text-center">
                        {ls.cabang}
                      </td>
                      <td className="align-middle text-nowrap">{ls.judul}</td>
                    </tr>
                  );
                })}
            </tbody>
          </LayoutTable>
        </div>
        <div className="col-6">
          <LayoutTable>
            <LayoutThead>
              <th>link</th>
            </LayoutThead>
            <tbody>
              {linkSurvei.data &&
                linkSurvei.data.length > 0 &&
                linkSurvei.data.map((ls, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle text-nowrap">
                        <span
                          type="button"
                          className="alert-link"
                          onClick={() => window.open(ls.link)}
                        >
                          {ls.link}
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </LayoutTable>
        </div>
        <div className="col-1">
          <LayoutTable>
            <LayoutThead>
              <th>
                <i className="fa fa-bars" />
              </th>
            </LayoutThead>
            <tbody>
              {linkSurvei.data &&
                linkSurvei.data.length > 0 &&
                linkSurvei.data.map((ls, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle text-center text-nowrap">
                        <LinkSurveiEdit
                          linkSurvei={ls}
                          onFetchData={fetchData}
                        />
                        <i className="mr-1" />
                        <DeleteLinkSurvei
                          linkSurvei={ls}
                          onFetchData={fetchData}
                        />
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </LayoutTable>
        </div>
      </div>
      {linkSurvei.links && (
        <SectionPagination
          from={linkSurvei.from}
          to={linkSurvei.to}
          total={linkSurvei.total}
          links={linkSurvei.links}
          onChangePage={(res) => setPage(res)}
        />
      )}
    </>
  );
};

const DeleteLinkSurvei = ({ linkSurvei, onFetchData }) => {
  const handleDelete = async () => {
    const res = await saConfirm("warning", "Yakin hapus link survei ?");
    if (res.isConfirmed) {
      try {
        const res = await deleteLinkSurvei(linkSurvei.id);
        if (res.data && res.data.msg === "success") {
          onFetchData();
          saAlert("success", "Berhasil hapus data link survei !");
        }
      } catch (err) {
        console.error(err.response);
      }
    }
  };

  return (
    <span type="button" className="badge badge-danger" onClick={handleDelete}>
      <i className="fa fa-trash-alt" />
    </span>
  );
};

export default LinkSurvei;
