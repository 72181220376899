import React from "react";
import { deletePengumuman } from "../../api";
import { saAlert, saConfirm } from "../../helpers";

const PengumumanDelete = ({ pengumuman, onFetchData }) => {
  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus pengumuman ?").then(async (res) => {
      if (res.isConfirmed) {
        const res = await deletePengumuman(pengumuman.id);
        if (res.data && res.data.status) {
          onFetchData();
          saAlert(res.data.status, res.data.msg);
        }
      }
    });
  };

  return (
    <button className="btn btn-sm btn-danger-2 text-sm py-0 px-1 mx-1" type="button" onClick={handleDelete}>
      <i className="fa fa-fw fa-sm fa-trash-alt"></i>
    </button>
  );
};

export default PengumumanDelete;
