import React, { useRef, useState } from "react";
import { postDosen } from "../../api";
import FormInput from "../../components/forms/Input";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import { saAlert } from "../../helpers";

const DosenCreate = (props) => {
  const { onFetchData } = props;

  const tagForm = useRef();
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    if (tagForm && tagForm.current) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postDosen(form)
      .then((res) => {
        console.log(res.data);
        if (res.data === "success") {
          onFetchData();
          handleClose();
          saAlert("success", "Berhasil tambah data Dosen !");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      });
  };

  return (
    <>
      <button onClick={() => setModal(true)} type="button" className='w-100 mb-3 btn btn-sm btn-warning-2 fw-medium text-black rounded-3 px-4 me-1'><i className='fa fa-fw fa-pen'/> Tambah Dosen</button>

      <LayoutModal
        title="Tambah Data Dosen"
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
          <p className="text-danger">
            Password default dosen yang baru dibuat adalah Kode Dosen
          </p>
          <FormInput
            label="Nama Dosen"
            name="nama"
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.nama}
          />
          <div className="row">
            <div className="col-md">
              <FormInput
                label="No HP"
                name="no_hp"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.no_hp}
              />
            </div>
            <div className="col-md">
              <FormInput
                label="Email"
                name="email"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.email}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInput
                label="Honor KRS"
                name="honor"
                type="number"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Honor Kursus"
                name="honor_kursus"
                type="number"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor_kursus}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Honor Membimbing LPK"
                name="honor_membimbing_lpk"
                type="number"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor_membimbing_lpk}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Honor Membimbing LTA"
                name="honor_membimbing_lta"
                type="number"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor_membimbing_lta}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Honor Membimbing TA"
                name="honor_membimbing_ta"
                type="number"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor_membimbing_ta}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Honor Menguji"
                name="honor_menguji"
                type="number"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor_menguji}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Honor Remidi"
                name="honor_remidi"
                type="number"
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor_remidi}
              />
            </div>
          </div>
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success">
              Tambah
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={handleClose}
            >
              Tutup
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default DosenCreate;
