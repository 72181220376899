import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../axios";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterBulan,
  defaultFilterTahun,
  FilterBulan,
  FilterTahun,
} from "../../components/layouts/TopContent";
import LoadingPage from "../../components/pages/Loading";
import NoDataPage from "../../components/pages/NoData";
import { formatDate, formatDiffDate, saAlert, saConfirm } from "../../helpers";
import { tokenState } from "../../storage/auth";
import { titleState } from "../../storage/pages";

const CutiApproval = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState(false);

  const [items, setItems] = useState();
  const [page] = useState("all");
  const [bulan, setBulan] = useState(defaultFilterBulan);
  const [tahun, setTahun] = useState(defaultFilterTahun);

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(
        "/api/cuti/show" +
          "?page=" +
          page +
          "&bulan=" +
          bulan +
          "&tahun=" +
          tahun +
          "&approval=true",
        { headers: { Authorization: "Bearer " + token } }
      );
      if (res.data) {
        setItems(res.data);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [page, bulan, tahun, token]);

  useEffect(() => {
    setTitle("Approve Cuti");

    fetchData();
  }, [setTitle, fetchData]);

  const handleUpdate = (id, status, message) => {
    // saConfirm("warning", `Yakin ${message} Cuti ?`).then((res) => {
    //   if (res.isConfirmed) {
    axios
      .patch(
        "/api/cuti/" + id,
        { status },
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((res) => {
        fetchData();
        // saAlert("success", `Berhasil ${message} Cuti.`);
      })
      .catch((err) => console.log(err));
    //   }
    // });
  };

  if (isLoaded) {
    return (
      <>
        <div className="row mb-2">
          <div className="col-md">
            <FilterBulan onChange={(res) => setBulan(res)} />
          </div>
          <div className="col-md">
            <FilterTahun onChange={(res) => setTahun(res)} />
          </div>
        </div>

        <div className="text-white bg-gradient-primary border-bottom-warning rounded-3 p-4 text-justify my-3">
        <div className="position-relative border border-secondary rounded p-3 mb-3">
          <div className="text-animate position-absolute bg-gradient-warning text-black rounded fw-semibold px-3" style={{rotate: '-4deg', left: '-15px', top: '-10px'}}><i className="fa fa-fw fa-bell fa-shake"/> Informasi Penting !</div>
          <h6 className="mb-0 mt-2">
            " Diharapkan untuk seluruh Atasan yang melakukan approval terhadap cuti
            untuk memastikan apakah karyawan yang mengajukan cuti sudah sesuai
            dengan aturan yang sudah ditetapkan! "
          </h6>
        </div>
        <h6 className="text-warning fw-semibold">Cuti yang termasuk ke Cuti Alasan Penting :</h6>
        <ul>
          <li>
            Pernikahan pegawai tetap yang lajang = <span className="text-warning">3 hari/Tahun</span>
          </li>
          <li>
            Pernikahan anak pegawai tetap = <span className="text-warning">3 Hari/Tahun</span>
          </li>
          <li>
            Istri pegawai melahirkan atau keguguran = <span className="text-warning">3 Hari/Tahun</span>
          </li>
          <li>
            Keluarga inti meninggal dunia = <span className="text-warning">3 Hari/Tahun</span>
          </li>
          <li>
            Keluarga / kerabat yang tinggal serumah meniggal dunia = <span className="text-warning">1 Hari/Tahun</span>
          </li>
        </ul>
        <h6 className="text-warning fw-semibold">Note :</h6>
        <ul>
          <li>
            Cuti alasan penting tidak akan memotong <span className="text-warning">cuti</span> tahunan.
          </li>
          <li>
            Jika ingin mengajukan lebih dari ketentuan diatas harus ada surat tertulis dari <span className="text-warning">Direktur Utama</span>
          </li>
        </ul>
      </div>

        {items.cuti && items.cuti.length > 0 ? (
          <LayoutTable>
            <LayoutThead>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Cabang</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">NIP</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Nama</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Dari</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Sampai</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Lama</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Keperluan</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Status</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Oleh</th>
            </LayoutThead>
            <tbody>
              {items.cuti.map((i, index) => {

                const jenisCuti = i.keperluan.split(":")[0].trim();

                return (
                  <tr key={index}>
                    <td className="py-1 border-silver text-center align-middle">{1 + index}</td>
                    <td className="py-1 border-silver text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.cabang}
                    </td>
                    <td className="py-1 border-silver text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.nip}
                    </td>
                    <td className="py-1 border-silver text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.nama}
                    </td>
                    <td className="py-1 border-silver text-nowrap text-center align-middle">
                      {formatDate(i.dari)}
                    </td>
                    <td className="py-1 border-silver text-nowrap text-center align-middle">
                      {formatDate(i.sampai)}
                    </td>
                    <td className="py-1 border-silver text-nowrap text-center align-middle">
                      {formatDiffDate(i.jumlah)}
                    </td>
                    <td className="py-1 border-silver align-middle">
                      {jenisCuti === "Cuti Tahunan" &&
                        <p className="mb-0 text-center fw-medium text-success">{i.keperluan}</p>
                      }
                      {jenisCuti === "Cuti Bersama" &&
                        <p className="mb-0 text-center fw-medium text-primary-2">{i.keperluan}</p>
                      }
                      {jenisCuti === "Cuti Bersalin" &&
                        <p className="mb-0 text-center fw-medium text-purple">{i.keperluan}</p>
                      }
                      {jenisCuti === "Cuti Alasan Penting" &&
                        <span className="fw-medium text-warning-2">{i.keperluan.split(":")[0]} <span className="fw-normal text-black">: {i.keperluan.split(":")[1]}</span></span>
                      }
                    </td>
                    <td className="py-1 border-silver text-center align-middle">
                      {parseInt(i.status) === 1 && (
                        <span className="text-success-2 fw-semibold">Approved</span>
                      )}
                      {parseInt(i.status) === 2 && (
                        <span className="text-danger-2 fw-semibold">Rejected</span>
                      )}
                      {!parseInt(i.status) && (
                        <div>
                          <button
                            onClick={() => handleUpdate(i.id, 1, "Approve")}
                            type="button"
                            className="btn btn-sm btn-success-2 text-sm w-100 py-0 mb-1"
                          >
                            Menyetujui
                          </button>
                          <button
                            onClick={() => handleUpdate(i.id, 2, "Reject")}
                            type="button"
                            className="btn btn-sm btn-danger-2 text-sm w-100 py-0"
                          >
                            Tolak
                          </button>
                        </div>
                      )}
                    </td>
                    <td className="py-1 border-silver text-nowrap text-center align-middle">
                      {i.atasan.nama}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </LayoutTable>
        ) : (
          <NoDataPage />
        )}
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

export default CutiApproval;
