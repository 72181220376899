import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import axios from "../../axios"
import { saAlert, saConfirm } from "../../helpers"
import { tokenState } from "../../storage/auth"

const GroupSectionBtn = props => {

  const token = useRecoilValue(tokenState)

  return (
    <div className="btn-group">
      {props.print && <Link to={props.print} className="btn border btn-sm btn-primary"><i className="fa fa-fw fa-print"></i></Link>}
      {props.show && <Link to={props.show} className="btn border btn-sm btn-info text-white" title="show"><i className="fa fa-fw fa-eye"></i></Link>}
      {props.edit && <Link to={props.edit} className="btn border btn-sm btn-success" title="edit"><i className="fa fa-fw fa-edit"></i></Link>}
      {props.editModal && <button type="button" onClick={props.onClickEditModal} className="btn border btn-sm btn-success" title="edit"><i className="fa fa-fw fa-edit"></i></button>}
      {props.delete && <button className="btn border btn-sm btn-danger" title="delete"
        onClick={() => {
          saConfirm('warning', 'Yakin hapus data ?', 'Data yang dihapus tidak dapat dikembalikan')
          .then(res => {
            if (res.isConfirmed) {
              axios.delete(props.delete, { headers: { 'Authorization': 'Bearer ' + token } })
              .then(res => {
                if (res.status === 200) {
                  props.onConfirmDelete()
                  saAlert('success', 'Berhasil hapus data')
                }
              })
            }
          })
        }}
      >
        <i className="fa fa-fw fa-trash-alt"></i>
      </button>}
    </div>
  )
}

export default GroupSectionBtn