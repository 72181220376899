import React from "react";
import { Helmet } from "react-helmet";
import { useRecoilValue } from "recoil";
import { userState } from "../../storage/auth";
import { getOrigin } from "../../helpers";

const PrintHeader = (props) => {
  const { type = "potrait", fontSize = 17 } = props;

  const user = useRecoilValue(userState);

  const getImgWidth = () => {
    if (type === "potrait") {
      return "w-75";
    } else if (type === "landscape") {
      return "w-50";
    }
  };

  return (
    <>
      <Helmet>
        <body className="bg-white" />
        <style type="text/css">
          {`
            * {
              font-size: ${fontSize};
            }
            @media print{
              @page {
                size: ${type};
              }
            }
            * {
              font-family: 'Cambria', sans-serif;
              color: #000000;
            }
          `}
        </style>
      </Helmet>

      <div className="container-fluid">
        <div className="row pb-3 mb-3 border-bottom border-dark">
          <div className="col-4 text-center">
            <img
              src={`${getOrigin()}/img/alfa-prima-logo-tulisan.png`}
              className={`img-fluid mt-3 ${getImgWidth()}`}
              alt=""
            />
          </div>
          <div className="col-8 d-flex align-items-center">
            <div className="text-center">
              <h2>ALFA PRIMA</h2>
              <p>
                {user.cabang === "pusat" && (
                  <>
                    Jl. Hayam Wuruk No.186, Sumerta Kelod, Kec. Denpasar Timur{" "}
                    <br /> Kota Denpasar, Bali 80239, Telepon 0361-232422 <br />{" "}
                    <i>www.alfaprima.id || Email: info@alfaprima.id</i>
                  </>
                )}

                {user.cabang === "denpasar" && (
                  <>
                    Jl. Hayam Wuruk No.186, Sumerta Kelod, Kec. Denpasar Timur{" "}
                    <br /> Kota Denpasar, Bali 80239, Telepon 0361-232422 <br />{" "}
                    <i>www.alfaprima.id || Email: info@alfaprima.id</i>
                  </>
                )}
                {user.cabang === "singaraja" && (
                  <>
                    Jalan Serma Karma No.88, Baktiseraga, Buleleng <br />
                    Kabupaten Buleleng, Bali 81119, Telepon 0362-3435453 <br />{" "}
                    <i>www.alfaprima.id || Email: info@alfaprima.id</i>
                  </>
                )}
                {user.cabang === "karangasem" && (
                  <>
                    Jalan Nenas, Subagan, Karangasem <br /> Kabupaten Karangasem, Bali
                    80811, Telepon 0363-2787499 <br />{" "}
                    <i>www.alfaprima.id || Email: info@alfaprima.id</i>
                  </>
                )}
              </p>
            </div>
          </div>
          <div className="col-2"></div>
        </div>
      </div>
    </>
  );
};

export default PrintHeader;
