import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { fetchBukuSakuKegiatan } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterJenjang,
  FilterPagination,
  FilterSearch,
  FilterStatusGeneral,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { getThName, namaBulan, saAlert } from "../../helpers";
import { titleState } from "../../storage/pages";
import BukuSakuKegiatanCreate from "./Create";
import BukuSakuKegiatanEdit from "./Edit";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const BukuSakuKegiatan = () => {
  const setTitle = useSetRecoilState(titleState);

  const [isLoaded, setIsLoaded] = useState(false);
  const [bukuSakuKegiatan, setBukuSakuKegiatan] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [jenjang, setJenjang] = useState("D1");
  const [pagination, setPagination] = useState(10);
  const [status, setStatus] = useState("");

  const fetchData = useCallback(async () => {
    if (jenjang === "") {
      setBukuSakuKegiatan({});
      saAlert("warning", "Jenjang tidak boleh kosong !");
    } else {
      try {
        const res = await fetchBukuSakuKegiatan(
          page,
          `search=${search}&jenjang=${jenjang}&pagination=${pagination}&status=${status}`
        );
        if (res.data && res.data.buku_saku_kegiatan) {
          setBukuSakuKegiatan(res.data.buku_saku_kegiatan);
          setIsLoaded(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [page, search, jenjang, pagination, status]);

  useEffect(() => {
    setTitle("Data Kegiatan Buku Saku");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        <BukuSakuKegiatanCreate onFetchData={fetchData} />

        {/* Filter 1 */}
        <div className="row mb-3">
          <div className="col-md-3">
            <FilterStatusGeneral
              onChange={(res) => {
                setStatus(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-3">
            <FilterJenjang
              value={jenjang}
              onChange={(res) => {
                setJenjang(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-3">
            <FilterPagination
              onChange={(res) => {
                setPagination(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-3">
            <FilterSearch
              onChange={(res) => {
                setPage(1);
                setSearch(res);
              }}
            />
          </div>
        </div>

        {bukuSakuKegiatan.data && bukuSakuKegiatan.data.length > 0 ? (
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>{getThName("no")}</Th>
                <Th>{getThName("judul")}</Th>
                <Th>{getThName("jenjang")}</Th>
                <Th>{getThName("bulan")}</Th>
                <Th>{getThName("status")}</Th>
                <Th>{getThName("keterangan")}</Th>
                <Th>
                  <span className="fa fa-bars" />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {bukuSakuKegiatan.data.map((bsk, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center">
                      {bukuSakuKegiatan.from + index}
                    </Td>
                    <Td className="text-nowrap">{bsk.judul}</Td>
                    <Td className="text-nowrap text-center">{bsk.jenjang}</Td>
                    <Td className="text-nowrap text-center">
                      {namaBulan[parseInt(bsk.bulan) - 1]}
                    </Td>
                    <Td className="text-nowrap text-center">
                      {parseInt(bsk.status) === 1 && (
                        <span className="fw-semibold text-success">Aktif</span>
                      )}
                      {parseInt(bsk.status) === 9 && (
                        <span className="fw-semibold text-danger">Nonaktif</span>
                      )}
                    </Td>
                    <Td className="text-nowrap text-center">
                      {bsk.keterangan}
                    </Td>
                    <Td className="text-nowrap text-center">
                      <BukuSakuKegiatanEdit
                        bukuSakuKegiatan={bsk}
                        onFetchData={fetchData}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={bukuSakuKegiatan.links}
          from={bukuSakuKegiatan.from}
          to={bukuSakuKegiatan.to}
          total={bukuSakuKegiatan.total}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default BukuSakuKegiatan;
