import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchDataControl, fetchJabatan, patchPegawi } from "../api";
import axios from "../axios";
import { saAlert, saConfirm } from "../helpers";
import {
  kacabState,
  loginMasterState,
  tokenState,
  userState,
} from "../storage/auth";
import {
  periodeOjtState,
  semesterAjaranState,
  tahunAjaranState,
} from "../storage/mhs";
import { angkatanState } from "../storage/pages";
import LoadingBasic from "../components/pages/LoadingBasic";

const IsPegawai = (props) => {
  const setToken = useSetRecoilState(tokenState);
  const [isLoaded, setIsLoaded] = useState(false);

  const setUser = useSetRecoilState(userState);
  const setKacab = useSetRecoilState(kacabState);
  const setTahunAjaran = useSetRecoilState(tahunAjaranState);
  const setAngkatan = useSetRecoilState(angkatanState);
  const setPeriodeOjt = useSetRecoilState(periodeOjtState);
  const setSemesterAjaran = useSetRecoilState(semesterAjaranState);

  const setLoginMaster = useSetRecoilState(loginMasterState);

  const history = useHistory();

  useEffect(() => {
    console.log("isPegawai");

    const token = localStorage.getItem("_token");
    const loginMaster = localStorage.getItem("_loginMaster");

    const loginError = () => {
      if (window.location.pathname !== "/") {
        saAlert("warning", "Perhatian", "Silahkan melakukan login ulang.");
        history.push("/");
      }
      setIsLoaded(true);
    };

    const checkLogin = async () => {
      if (token) {
        try {
          const res = await fetchDataControl(async () => {
            return await axios.get(
              `/api/check?login_master=${loginMaster ? loginMaster : ""}`,
              {
                headers: { Authorization: "Bearer " + token },
              }
            );
          });
          setToken(token);
          if (res.data) {
            setUser(res.data.user);
            setKacab(res.data.kacab);
            setTahunAjaran(res.data.tahun_ajaran);
            setAngkatan(res.data.angkatan);
            setPeriodeOjt(res.data.periode_ojt);
            setSemesterAjaran(res.data.semester_ajaran);
            if (res.data.login_master) {
              setLoginMaster(res.data.login_master);
            }
          }
          // localStorage.setItem('user', JSON.stringify(res.data))

          if (window.location.pathname === "/") {
            history.push("/dashboard");
            saAlert(
              "success",
              "Login Berhasil",
              "Berhasil login dengan sesi sebelumnya."
            );
          }
          setIsLoaded(true);
        } catch (error) {
          loginError();
        }
      } else {
        loginError();
      }
    };

    checkLogin();
  }, [
    history,
    setToken,
    setUser,
    setKacab,
    setTahunAjaran,
    setAngkatan,
    setPeriodeOjt,
    setSemesterAjaran,
    setLoginMaster,
  ]);

  if (isLoaded) {
    return <SwitchAccount>{props.children}</SwitchAccount>;
  } else {
    return (
      <div className="bg-gradient-primary d-flex flex-column justify-content-center align-items-center vh-100 text-white p-3">
        <h6 className="mb-2">Authorization. . .</h6>
        <span className="loader-awesome-3 mb-3"></span>
      </div>
    );
  }
};

const SwitchAccount = (props) => {
  const { children } = props;
  const user = useRecoilValue(userState);

  if (user.id && !user.jabatan_id) {
    return <ShowJabatan user={user} />;
  } else {
    return children;
  }
};

const ShowJabatan = (props) => {
  const { user } = props;
  const [jabatan, setJabatan] = useState([]);

  const token = useRecoilValue(tokenState);
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(true);
      const res = await fetchJabatan("all", `pegawai_id=${user.id}`);
      if (res.data && res.data.jabatan && res.data.jabatan.length > 0) {
        setJabatan(res.data.jabatan);
        setIsLoaded(false);
      } else {
        setIsLoaded(false);
      }
    };

    fetchData();
  }, [user]);

  // const [form, setForm] = useState({});

  const handleSubmit = async (jabatan_id) => {
    try {
      const res = await patchPegawi(user.id, {
        ...jabatan_id,
        set_default_jabatan: true,
      });
      if (res.data && res.data.status && res.data.status === "success") {
        // saAlert(res.data.status, res.data.msg);
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    } catch (err) {}
  };

  const handleLogout = () => {
    axios.post(
      "/api/logout",
      { id: localStorage.getItem("_id") },
      { headers: { Authorization: "Bearer " + token } }
    )
    .then((res) => {
      localStorage.clear();
      history.push("/");
      window.location.reload();
    })
    .catch((err) => console.log(err));
  };

  const checkExpiredJabatan = (tanggal) => {
    const currentDate = new Date();
    const parsedActiveDate = new Date(tanggal);
    return parsedActiveDate > currentDate;
  };

  return (
    <>
      <div className="d-flex align-items-center vh-100">
        <div className="col-lg-4 mx-auto">
          <div className="bg-gradient-primary text-white rounded-bottom-4 rounded-top-5 shadow-lg">
            <div className="bg-gradient-primary border-bottom-warning rounded-4 p-4">
              <h5 className="mb-0 text-center">Silahkan pilih jabatan</h5>
            </div>
            <div className="p-4">
              {!isLoaded ?
                jabatan.length > 0 ? jabatan.map((j) => {
                  return user.jabatan_.filter((uj) => uj.id === j.id).map((uj, index) => {
                      return (uj.pivot.tanggal_berakhir === null || checkExpiredJabatan(uj.pivot.tanggal_berakhir)) &&
                        <div 
                          key={index} 
                          onClick={() => {
                            saConfirm("warning", `Login dengan jabatan ${j.nama}`).then((res) => {
                              if (res.isConfirmed) {
                                handleSubmit({jabatan: j.id});
                              }
                            });
                          }}
                          className="border border-dark btn-primary-4 cursor-pointer hover-border-info rounded-3 text-center p-3 mb-3"
                        >
                          <h6 className="mb-0">{j.nama}</h6>
                        </div>
                  })
                  }) : 
                    <>
                      <div className="border border-dark bg-primary rounded-3 text-center p-3 mb-3">
                        <i className="fa fa-fw fa-2xl fa-circle-exclamation text-warning"/>
                        <p className="mt-2 text-gray-500 mb-0">Jabatan anda belum ditentukan <br /> silahkan menghubungi bagian HRD.</p>
                      </div>

                      <div className="d-flex justify-content-center">
                        <button
                          className="btn btn-sm btn-primary-4 rounded text-sm mb-2"
                          onClick={handleLogout}
                        >
                          <i className="fa fa-fw fa-sm fa-arrow-left" />
                          <span>Kembali ke halaman login</span>
                        </button>
                      </div>
                    </>
                    :
                  <LoadingBasic/>
              }
              <p className="mb-0 mt-1 text-sm text-center text-secondary">Copyright © Alfa Prima 2020 - {new Date().getFullYear()}</p>
            </div>
          </div>
        </div>
      </div>



    {/* <div className="bg-white py-5">
      <h3>Pilih Login Sebagai :</h3>
      <FormSelect
        label="Jabatan"
        name="jabatan"
        onChange={(e) => setForm({ [e.target.name]: e.target.value })}
      >
        <option value="">Pilih</option>
        {jabatan.map((j, index) => {
          return (
            <React.Fragment key={index}>
              <option value={j.id}>{j.nama}</option>
            </React.Fragment>
          );
        })}
      </FormSelect>
      <button
        type="button"
        onClick={handleSubmit}
        className="btn btn-sm btn-primary"
      >
        Save
      </button>
    </div> */}
    </>
  );
};

export default IsPegawai;