import React, { useRef, useState } from "react";
import { postKs } from "../../api";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable } from "../../components/layouts/Table";
import { getOrigin, saAlert } from "../../helpers";

const KsEdit = (props) => {
  const { ks, onFetchData } = props;

  const [modal, setModal] = useState(false);
  const tagForm = useRef();

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("tindak_lanjut_manajemen", true);
    form.id && formData.append("id", form.id);
    form.tindak_lanjut && formData.append("tindak_lanjut", form.tindak_lanjut);
    form.butki_tindak_lanjut &&
      formData.append("butki_tindak_lanjut", form.butki_tindak_lanjut);

    try {
      const res = await postKs(formData);

      console.log(res.data);

      if (res.data === "success") {
        onFetchData();
        saAlert("success", "Berhasil menambah data tindak lanjut !");
      }
    } catch (err) {
      if (err.response && err.response.data) {
        setErrors(err.response.data.errors);
      }
    }
  };

  const handleOpen = () => {
    setForm(ks);
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setForm({});
    setErrors({});
    setModal(false);
  };

  return (
    <>
      <button type="button" className="btn btn-sm btn-success-2 text-sm py-0 px-1" onClick={handleOpen}>
        <i className="fa fa-fw fa-sm fa-pen" />
      </button>
      <LayoutModal
        title="Tindak Lanjuti Kritik Saran"
        modal={modal}
        onClose={handleClose}
      >
        {/* <div className="row">
          <div className="col-lg-6 mb-3">
              <table className="table table-sm table-hover table-bordered">
                <tbody>
                  <tr>
                    <td className="py-1 border-silver bg-silver fw-semibold">Cabang</td>
                    <td className="py-1 border-silver" style={{ textTransform: "capitalize" }}>{form.cabang}</td>
                  </tr>
                  <tr>
                    <td className="py-1 border-silver bg-silver fw-semibold">No HP</td>
                    <td className="py-1 border-silver">{form.no_hp}</td>
                  </tr>
                  <tr>
                    <td className="py-1 border-silver bg-silver fw-semibold">Bagian</td>
                    <td className="py-1 border-silver">{form.bagian}</td>
                  </tr>
                  <tr>
                    <td className="py-1 border-silver bg-silver fw-semibold">Judul</td>
                    <td className="py-1 border-silver">{form.judul}</td>
                  </tr>
                  <tr>
                    <td className="py-1 border-silver bg-silver fw-semibold">Isi</td>
                    <td className="py-1 border-silver">{form.isi}</td>
                  </tr>
                </tbody>
              </table>

              <form onSubmit={handleSubmit} ref={tagForm}>
                {ks.tindak_lanjut ? (
                  <div className="text-black rounded-3 p-3 mb-3" style={{backgroundColor: 'rgb(240, 240, 240)'}}>
                    <h6 className="fw-semibold text-primary">Tindak Lanjut</h6>
                    <p className="mb-0">{ks.tindak_lanjut}</p>
                  </div>
                ) : (
                  <FormTextarea
                    label="Tindak Lanjut"
                    name="tindak_lanjut"
                    onChange={(e) =>
                      setForm((prevState) => {
                        return { ...prevState, [e.target.name]: e.target.value };
                      })
                    }
                    error={errors.tindak_lanjut}
                  />
                )}

                {ks.butki_tindak_lanjut ? (
                  <div>
                    <h6 className="fw-semibold text-primary">Bukti Tindak Lanjut</h6>
                    <img
                      className="img-fluid w-50"
                      src={`${getOrigin()}/storage/ks/tindak_lanjut/${
                        ks.butki_tindak_lanjut
                      }`}
                      type="button"
                      onClick={(e) => window.open(e.target.src)}
                      alt=""
                    />
                  </div>
                ) : (
                  <FormInput
                    className="form-control form-control-sm form-control-custom rounded-3"
                    label="Butki Tindak Lanjut"
                    name="butki_tindak_lanjut"
                    type="file"
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.files[0],
                        };
                      })
                    }
                    error={errors.butki_tindak_lanjut}
                  />
                )}

                <div className="rounded-3 d-flex justify-content-center align-items-center py-5 mb-3" style={{backgroundColor: 'rgb(225, 225, 225)'}}>
                  <img
                    className="img-fluid"
                    src={`${getOrigin()}/storage/ks/tindak_lanjut/${
                      ks.butki_tindak_lanjut
                    }`}
                    type="button"
                    onClick={(e) => window.open(e.target.src)}
                    alt="Foto bukti tindak lanjut."
                  />
                </div>

                <div className="btn-group">
                  {!ks.tindak_lanjut && !ks.butki_tindak_lanjut && (
                    <button className="btn btn-sm btn-success-2" type="submit">
                      Simpan
                    </button>
                  )}
                  <button
                    className="btn btn-sm btn-dark border-0"
                    type="button"
                    onClick={handleClose}
                  >
                    <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
                  </button>
                </div>
              </form>
          </div>
          <div className="col-lg-6">
            <div className="rounded-3 d-flex justify-content-center align-items-center py-5" style={{backgroundColor: 'rgb(225, 225, 225)'}}>
              <img
                src={`${getOrigin()}/storage/ks/${form.foto}`}
                className="img-fluid"
                type="button"
                onClick={(e) => window.open(e.target.src)}
                alt="Tidak ada foto."
              />
            </div>
          </div>
        </div> */}

        <table className="table table-sm table-bordered">
          <tbody>
            <tr>
              <td className="py-1 border-silver bg-silver fw-semibold">Cabang</td>
              <td className="py-1 border-silver" style={{ textTransform: "capitalize" }}>{form.cabang}</td>
            </tr>
            <tr>
              <td className="py-1 border-silver bg-silver fw-semibold">No HP</td>
              <td className="py-1 border-silver">{form.no_hp}</td>
            </tr>
            <tr>
              <td className="py-1 border-silver bg-silver fw-semibold">Bagian</td>
              <td className="py-1 border-silver">{form.bagian}</td>
            </tr>
            <tr>
              <td className="py-1 border-silver bg-silver fw-semibold">Judul</td>
              <td className="py-1 border-silver">{form.judul}</td>
            </tr>
            <tr>
              <td className="py-1 border-silver bg-silver fw-semibold">Isi</td>
              <td className="py-1 border-silver">{form.isi}</td>
            </tr>
            <tr>
              <td className="py-1 border-silver bg-silver fw-semibold">Foto</td>
              <td className="py-1 border-silver text-center">
                {form.foto && (
                  <div>
                    <img
                      src={`${getOrigin()}/storage/ks/${form.foto}`}
                      className="img-fluid w-25"
                      type="button"
                      onClick={(e) => window.open(e.target.src)}
                      alt=""
                    />
                    {form.foto && <p className="mb-0 fw-semibold text-sm text-dark mt-2">(Click image to view)</p>}
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </table>

        {/* {ks.butki_tindak_lanjut && (
          <img
            src={`${getOrigin()}/storage/ks/butki_tindak_lanjut/${form.foto}`}
            className="img-fluid w-25"
            alt=""
          />
        )} */}

        <form onSubmit={handleSubmit} ref={tagForm}>
          {ks.tindak_lanjut ? (
            <div className="bg-silver rounded-3 p-3 text-black mb-3">
              <h6 className="fw-semibold text-primary"><i className="fa fa-fw fa-circle-check text-success-2"/> Tindak Lanjut</h6>
              <p className="mb-0">{ks.tindak_lanjut}</p>
            </div>
          ) : (
            <FormTextarea
              label="Tindak Lanjut"
              name="tindak_lanjut"
              onChange={(e) =>
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                })
              }
              error={errors.tindak_lanjut}
            />
          )}

          {ks.butki_tindak_lanjut ? (
            <div className="bg-silver rounded-3 text-center p-3 mb-3">
              <h6 className="mb-3 fw-semibold text-primary"><i className="fa fa-fw fa-image text-success-2"/> Bukti Tindak Lanjut</h6>
                <img
                  className="img-fluid rounded-3 w-25"
                  src={`${getOrigin()}/storage/ks/tindak_lanjut/${
                    ks.butki_tindak_lanjut
                  }`}
                  type="button"
                  onClick={(e) => window.open(e.target.src)}
                  alt="bukti tidak lanjut"
                />
                <p className="mb-0 mt-2 fw-semibold text-sm text-dark">(Click image to view)</p>
            </div>
          ) : (
            <div className="row mb-3">
              <div className="col-lg-6">
                <label className="fw-semibold text-primary"><i className="fa fa-fw fa-image text-dark"/> Butki Tindak Lanjut</label>
                <input
                  className={`form-control form-control-sm form-control-custom text-black rounded-3 ${
                    errors.butki_tindak_lanjut ? "is-invalid" : "border-silver"
                  }`}
                  name="butki_tindak_lanjut"
                  type="file"
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.files[0],
                      };
                    })
                  }
                  error={errors.butki_tindak_lanjut}
                />
                <div className="invalid-feedback">{errors.butki_tindak_lanjut}</div>
              </div>
            </div>
          )}

          <div className="btn-group">
            {!ks.tindak_lanjut && !ks.butki_tindak_lanjut && (
              <button className="btn btn-sm btn-success-2" type="submit">
                Simpan perubahaan
              </button>
            )}
            <button
              className="btn btn-sm btn-dark border-0"
              type="button"
              onClick={handleClose}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default KsEdit;
