import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { LayoutTable, LayoutThead } from "../../../components/layouts/Table";
import { CeklistMonitoringContext } from "../Routes";
import { deleteCeklistMonitoringValue } from "../../ceklist-monitoring-value/apis";
import { saAlert, saConfirm } from "../../../helpers";
import { useRecoilValue } from "recoil";
import { userState } from "../../../storage/auth";

const Report = ({ onFetchData }) => {
  const { id } = useParams();
  const user = useRecoilValue(userState);

  const { ceklistMonitoring, cabang, departemen, auditor } = useContext(
    CeklistMonitoringContext
  );

  const [auditee, setAuditee] = useState([]);

  useEffect(() => {
    let data = ceklistMonitoring.data.filter(
      (cm) => parseInt(cm.id) === parseInt(id)
    );

    if (data.length > 0) {
      data = data[0].auditee.map((a) => parseInt(a.id));
      setAuditee(data);
    }
  }, [setAuditee, ceklistMonitoring.data, id]);

  const [form, setForm] = useState([]);

  useEffect(() => {
    let data = ceklistMonitoring.data.filter(
      (cm) =>
        parseInt(cm.id) === parseInt(id) &&
        cm.ceklist_monitoring_list &&
        cm.ceklist_monitoring_list.length > 0
    );

    if (data.length > 0) {
      data = data[0].ceklist_monitoring_list.map((cml) => cml);
      setForm(data);
    }
  }, [setForm, ceklistMonitoring.data, id]);

  const getStatus = (number) => {
    let no = parseInt(number);
    if (no === 1) {
      return (
        <p className="mb-0 fw-semibold text-success-2"> Yes</p>
      );
    } else if (no === 9) {
      return (
        <p className="mb-0 fw-semibold text-danger-2"> No</p>
      );
    } else {
      return "-";
    }
  };

  const handleDelete = (id) => {
    saConfirm("warning", "Yakin hapus ceklist monitoring value report").then(
      async (res) => {
        if (res.isConfirmed) {
          try {
            const res = await deleteCeklistMonitoringValue(id);
            if (res.data) {
              onFetchData();
              saAlert(res.data.status, res.data.msg);
            }
          } catch (err) {
            console.error(err.response);
          }
        }
      }
    );
  };

  return (
    <div>
      <h1 className="fs-4 text-dark fw-semibold pb-2 mb-2 mx-3 text-center border-bottom border-silver"><i className="fa fa-fw fa-pencil"/> Report</h1>
        <div className="row">
          <div className="col-md-8">
            <h5 className="fw-semibold text-dark"><i className="fa fa-fw fa-folder"/>Ceklist Monitoring</h5>
            <LayoutTable>
              <LayoutThead className="text-nowrap">
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Cabang</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Departemen</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Auditor</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Tahun</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Tahap</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Kode Dokumen</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Revisi</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Tanggal Efektif</th>
              </LayoutThead>
              <tbody>
                {ceklistMonitoring.data &&
                  ceklistMonitoring.data.length > 0 &&
                  ceklistMonitoring.data
                    .filter((cm) => parseInt(cm.id) === parseInt(id))
                    .map((cm, index) => {
                      return (
                        <tr key={index}>
                          <td className="py-1 border-silver text-nowrap text-center">{index + 1}</td>
                          <td className="py-1 border-silver text-nowrap text-center">
                            {cabang.length > 0 &&
                              cabang
                                .filter(
                                  (c) => parseInt(c.id) === parseInt(cm.cabang_id)
                                )
                                .map((c) => c.nama)}
                          </td>
                          <td className="py-1 border-silver text-nowrap">
                            {departemen.length > 0 &&
                              departemen
                                .filter(
                                  (d) =>
                                    parseInt(d.id) === parseInt(cm.departemen_id)
                                )
                                .map((c) => c.nama)}
                          </td>
                          <td className="py-1 border-silver text-nowrap">
                            {auditor.length > 0 &&
                              auditor
                                .filter(
                                  (a) =>
                                    parseInt(a.id) === parseInt(cm.auditor_id)
                                )
                                .map((c) => c.nama)}
                          </td>
                          <td className="py-1 border-silver text-nowrap text-center">{cm.tahun}</td>
                          <td className="py-1 border-silver text-nowrap text-center">{cm.tahap}</td>
                          <td className="py-1 border-silver text-nowrap text-center">{cm.kode_dokumen}</td>
                          <td className="py-1 border-silver text-nowrap text-center">{cm.revisi}</td>
                          <td className="py-1 border-silver text-nowrap text-center">{cm.tanggal_efektif}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </LayoutTable>
          </div>
          <div className="col-md-4">
            <h5 className="fw-semibold text-dark"><i className="fa fa-fw fa-folder"/>Auditee</h5>
            <LayoutTable>
              <LayoutThead>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Nama</th>
              </LayoutThead>
              <tbody>
                {auditor.length > 0 &&
                  auditor
                    .filter((a) => auditee.includes(parseInt(a.id)))
                    .map((a, index) => {
                      return (
                        <tr key={index}>
                          <td className="py-1 border-silver text-center">{index + 1}</td>
                          <td className="py-1 border-silver">{a.nama}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </LayoutTable>
          </div>
        </div>

        <LayoutTable>
          <LayoutThead tr={true}>
            <tr className="text-center">
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle" rowSpan={2}>
                No
              </th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle" rowSpan={2}>
                Klausul
              </th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle" rowSpan={2}>
                Deskripsi
              </th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle" rowSpan={2}>
                Refrensi Audit
              </th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle" colSpan={3}>
                Metode
              </th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle" rowSpan={2}>
                Catatan
              </th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle" colSpan={3}>
                Kesimpulan
              </th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle" rowSpan={2}>
                CPAR
              </th>
              {user.jabatan === "IT Programmer" && (
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle" rowSpan={2}>
                  <i className="fa fa-fw fa-cog"></i>
                </th>
              )}
            </tr>
            <tr className="text-center text-nowrap">
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Observasi</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Wawancara</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Penilaian Berkas</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Sesuai</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Tidak Sesuai</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Saran</th>
            </tr>
          </LayoutThead>
          <tbody>
            {form.map((cml, index) => {
              return (
                <tr key={index}>
                  <td className="align-middle py-1 border-silver text-center">{index + 1}</td>
                  <td className="align-middle py-1 border-silver">{cml.klausul}</td>
                  <td className="align-middle py-1 border-silver">{cml.deskripsi}</td>
                  <td className="align-middle py-1 border-silver">{cml.refrensi_audit}</td>
                  <td className="align-middle py-1 border-silver text-center">{getStatus(cml.pivot.o, false)}</td>
                  <td className="align-middle py-1 border-silver text-center">{getStatus(cml.pivot.w, false)}</td>
                  <td className="align-middle py-1 border-silver text-center">{getStatus(cml.pivot.pb, false)}</td>
                  <td className="align-middle py-1 border-silver">{cml.pivot.catatan || "-"}</td>
                  <td className="align-middle py-1 border-silver text-center">{getStatus(cml.pivot.s, false)}</td>
                  <td className="align-middle py-1 border-silver text-center">{getStatus(cml.pivot.ts, false)}</td>
                  <td className="align-middle py-1 border-silver text-center">{getStatus(cml.pivot.sr, false)}</td>
                  <td className="py-1 border-silver align-middle text-center"><i className="fa fa-fw fa-circle-exclamation text-warning"/> <p className="mb-0 text-secondary">Fitur belum tersedia.</p></td>
                  {user.jabatan === "IT Programmer" && (
                    <td className="align-middle py-1 border-silver">
                      <i
                        type="button"
                        className="fa fa-trash text-danger"
                        onClick={() => handleDelete(cml.pivot.id)}
                      ></i>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </LayoutTable>
    </div>
  );
};

export default Report;
