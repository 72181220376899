import React, { useEffect, useRef, useState } from "react";
import LayoutModal from "../../components/layouts/Modal";
import FormInput from "../../components/forms/Input";
import {
  deleteMorningReportList,
  fetchDailyReport,
  fetchMorningReportList,
  postMorningReportList,
} from "../../api";
import { formatDate, saAlert, saConfirm } from "../../helpers";
import FormSelect from "../../components/forms/Select";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import FormTextarea from "../../components/forms/Textarea";
import { userState } from "../../storage/auth";
import { useRecoilValue } from "recoil";
import { FilterSearch } from "../../components/layouts/TopContent";

const MorningReportEdit = ({ kpi, morningReport, onFetchData }) => {
  const tagForm = useRef(null);
  const user = useRecoilValue(userState);

  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const resetForm = () => {
    tagForm.current && tagForm.current.reset();
    setForm({});
    setErrors({});
  };

  const handleClose = ({ isClose = true }) => {
    isClose ? onFetchData() : onFetchData({ isReset: false });
    resetForm();
    isClose && setModal(false);
  };

  const hanldeOpen = () => {
    setModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await postMorningReportList({
        morning_report_id: morningReport.id,
        ...form,
      });
      if (res.data && res.data.status === "success") {
        handleClose({ isClose: false });
        saAlert("success", res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  const handleDeleteMorningReportList = (mrl_id) => {
    saConfirm("warning", "Yakin hapus data ?").then(async (res) => {
      if (res.isConfirmed) {
        const res = await deleteMorningReportList(mrl_id);
        if (res.data.status && res.data.status === "success") {
          onFetchData({ isReset: false });
          saAlert("success", res.data.msg);
        }
      }
    });
  };

  return (
    <>
      <button 
        className="btn btn-sm btn-success-2 py-0 text-sm mx-1" 
        onClick={hanldeOpen}
        // data-bs-toggle="modal" 
        // data-bs-target="#tambahList"
      >
        <i className="fa fa-fw fa-plus fa-sm"/> Tambah List
      </button>

      <LayoutModal
        id="tambahList"
        title="Tambah Daily Report List"
        modal={modal}
        onClose={handleClose}
      >
        <LayoutTable>
          <LayoutThead className="text-nowrap">
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">No</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">ID</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">KPI</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">Judul</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">Keterangan</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">Melanjutkan ID</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle">
              <i className="fa fa-fw fa-cog"></i>
            </th>
          </LayoutThead>
          <tbody>
            {morningReport.morning_report_list &&
              morningReport.morning_report_list.length > 0 &&
              morningReport.morning_report_list.map((mrl, index) => {
                return (
                  <tr key={index}>
                    <td className="align-middle py-1 border-silver text-center">{index + 1}</td>
                    <td className="align-middle py-1 border-silver text-center">{mrl.id}</td>
                    <td className="align-middle py-1 border-silver">{mrl.kpi && mrl.kpi.nama}</td>
                    <td className="align-middle py-1 border-silver">{mrl.judul}</td>
                    <td className="align-middle py-1 border-silver">{mrl.keterangan}</td>
                    <td className="align-middle py-1 border-silver text-center">
                      {mrl.melanjutkan_id === mrl.id ? "-" : mrl.melanjutkan_id}
                    </td>
                    <td className="align-middle py-1 border-silver text-center">
                      <button
                        type="button"
                        className="btn btn-sm btn-danger py-0 px-1"
                        onClick={() => handleDeleteMorningReportList(mrl.id)}
                      >
                        <i className="fa fa-fw fa-trash-alt fa-sm"/>
                      </button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </LayoutTable>

        <div className="bg-primary text-white text-sm rounded-3 p-3 mb-3">
          <h6 className="mb-1 text-info"><i className="fa fa-fw fa-circle-exclamation"/> Sekedar Informasi.</h6>
          <p className="mb-0">
            Report ini berguna untuk mempermudah proses monitoring atasan terhadap
            bawahannya. Selain itu kedepannya dengan data ini akan mempermudah
            proses perhitungan Analisis Beban Kerja (ABK) sehingga dapat
            meminimalisir staff dengan pekerjaan melebihi kapasitasnya.
          </p>
        </div>
        <form className="row" onSubmit={handleSubmit} ref={tagForm}>
          <div className="col-lg-6">
            <div>
              <FormInput label="Nama" readOnly={true} value={user.nama} />
            </div>
            <div>
              <FormSelect
                label="KPI yang Diacu"
                name="kpi_id"
                onChange={handleChange}
                error={errors.kpi_id}
              >
                <option value="">Pilih</option>
                {kpi.length > 0 &&
                  kpi.map((k, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option
                          value={k.id}
                          className="d-flex justify-content-between"
                        >
                          Bobot {k.bobot}% ({k.nama})
                        </option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div>
              <FormInput
                label="Judul Kegiatan"
                name="judul"
                onChange={handleChange}
                error={errors.judul}
              />
            </div>
            <div>
              <FormTextarea
                label="Keterangan Kegiatan"
                name="keterangan"
                onChange={handleChange}
                error={errors.keterangan}
              />
            </div>
          </div>
          <div className="col-lg-6">
            {form.melanjutkan_id ? (
              <div className="bg-primary text-white rounded-3 p-3 mb-3">
                {console.log(form)}
                <h5 className="text-warning">Yakin melanjutkan pekerjaan dengan ID {form.melanjutkan_id} ?</h5>
                <p className="text-sm text-gray-400">Pekerjaan yang berstatus dilanjutkan akan bersisi ID melanjutkan pada tabel di atas.</p>
                <button
                  className="btn btn-sm btn-danger-2 text-sm py-1 px-3"
                  type="button"
                  onClick={() => {
                    setForm((prevState) => {
                      return { ...prevState, melanjutkan_id: null };
                    });
                  }}
                >
                  <i className="fa fa-fw fa-sm fa-trash-alt"/> Batalkan melanjutkan
                </button>
              </div>
            ) : (
              <div className="bg-primary text-white rounded-3 p-3 mb-3">
                <h5>Ingin melanjutkan pekerjaan?</h5>
                <p className="mb-0 text-gray-400 text-sm">
                  Ketik judul daily report pada kolom pencarian dibawah untuk
                  melanjutkan pekerjaan, judul daily report dapat dilihat pada tabel diatas.
                </p>
              </div>
            )}
            <Melanjutkan user={user} setForm={setForm} />
            <div className="mt-3">
              <div className="col-lg-6 mx-auto px-0">
                <button type="submit" className="btn btn-sm btn-success-2 rounded-3 w-100">
                  <i className="fa fa-fw fa-sm fa-download"/> Simpan list report
                </button>
              </div>
            </div>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

const Melanjutkan = ({ user, setForm }) => {
  const [search, setSearch] = useState("");

  const [morningReportList, setMorningReportList] = useState([]);

  useEffect(() => {
    if (search === "") {
      setMorningReportList([]);
    } else {
      const fetchData = async () => {
        try {
          const res = await fetchMorningReportList(
            "all",
            `pegawai_id=${user.id}&limit=10&search=${search}`
          );
          setMorningReportList(res.data.morning_report_list);
        } catch (err) {
          //
        }
      };
      fetchData();
    }
  }, [search, user]);

  return (
    <>
      {/* <p>
        Gunakan search ini untuk menambahkan pekerjaan yang ingin dilanjutkan,
        tulis judul keperjaan yg ingin anda lanjutkan
      </p> */}
      <FilterSearch label="Cari Judul Pekerjaan" onChange={(res) => setSearch(res)} />
      {morningReportList.length > 0 && (
        <div className="mt-3">
          <LayoutTable>
            <LayoutThead>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">KPI diacu</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Tanggal</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Judul</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Keterangan</th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                <i className="fa fa-bars"></i>
              </th>
            </LayoutThead>
            <tbody>
              {morningReportList.length > 0 &&
                morningReportList.map((mrl, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{mrl.kpi && mrl.kpi.nama}</td>
                      <td>
                        {mrl.morning_report &&
                          formatDate(mrl.morning_report.tanggal)}
                      </td>
                      <td>{mrl.judul}</td>
                      <td>{mrl.keterangan}</td>
                      <td className="text-center">
                        <button
                          type="button"
                          className="btn btn-sm btn-success-2 text-sm"
                          onClick={() => {
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                melanjutkan_id: mrl.melanjutkan_id
                                  ? mrl.melanjutkan_id
                                  : mrl.id,
                              };
                            });
                            setSearch("");
                          }}
                        >
                          Lanjutkan
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </LayoutTable>
        </div>
      )}
    </>
  );
};

export default MorningReportEdit;
