import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { showKelas } from "../../api";
import { getOrigin } from "../../helpers";
import PrintHeaderOld from "../../components/print/Header";
import PrintLayout, { PrintButton, PrintHeader } from "../../components/layouts/PrintLayout";

const KelasBeritaAcaraUas = () => {
  const { id } = useParams();
  const [print, setPrint] = useState(false);

  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await showKelas(id);
        setItems(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  return (
    <>
      <PrintLayout>
        <PrintHeader type="potrait" fontSize="20pt"/>
        <div contentEditable>
          <h1 className="fs-4 text-center fw-semibold mb-5">
            BERITA ACARA
            <br />
            PENYELENGGARAAN UJIAN AKHIR SEMESTER
            <br />
            TAHUN AJARAN {items.tahun_ajaran}/{parseInt(items.tahun_ajaran) + 1}
          </h1>
          <ul className="print-ul">
            <li>
              <p>Pada hari ini . . . . . . . . . . . . . . . . . . . . . . . . . , tahun{" "}{new Date().getFullYear()}</p>

              <ol type="a">
                <li className="mb-3">
                  <p>
                    Telah dilaksanakan Ujian dari pukul . . . . . . . . . . sampai
                    pukul . . . . . . . . . .
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td>Mata kuliah</td>
                        <td className="px-3">:</td>
                        <td>{items.matkul && items.matkul.nama}</td>
                      </tr>
                      <tr>
                        <td>Ruang</td>
                        <td className="px-3">:</td>
                        <td>{items.ruangan && items.ruangan.nama}</td>
                      </tr>
                      <tr>
                        <td>Kelas</td>
                        <td className="px-3">:</td>
                        <td>{items.kode}</td>
                      </tr>
                      <tr>
                        <td>Dosen</td>
                        <td className="px-3">:</td>
                        <td>{items.dosen && items.dosen.nama}</td>
                      </tr>
                      <tr>
                        <td>Jumlah peserta seharusnya</td>
                        <td className="px-3">:</td>
                        <td>{items.mhs && items.mhs.filter((i) => parseInt(i.status) !== 9 ).length} Orang</td>
                      </tr>
                      <tr>
                        <td>Jumlah peserta yang tidak hadir</td>
                        <td className="px-3">:</td>
                        <td>. . . . Orang</td>
                      </tr>
                      <tr>
                        <td>Jumlah peserta yang hadir</td>
                        <td className="px-3">:</td>
                        <td>. . . . Orang</td>
                      </tr>
                    </tbody>
                  </table>
                </li>
                <li>
                  <p className="mb-1">Catatan selama pelaksanaan Ujian</p>
                  <p className="mb-3 text-secondary">
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . 
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . 
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . 
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . 
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . 
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . 
                    . . . . . . . . . . . . . . . . . .
                  </p>
                </li>
              </ol>

              <p className="ps-4">Berita acara ini dibuat dengan sesungguhnya.</p>

              <div className="row align-items-end g-5">
                <div className="col-7" style={{fontSize: '17px'}}>
                  <div style={{width: '75%'}}>
                    <p className="mb-1">* Diisi apabila terjadi antara lain hal-hal sebagai berikut :</p>
                    <ol>
                      <li>Mencatat nama mahasiswa yang melakukan kecurangan</li>
                      <li>Mencatat segala apapun selama proses ujian</li>
                      <li>Pelanggaran tata tertib oleh peserta ujian, dan lain-lain</li>
                    </ol>
                  </div>
                </div>
                <div className="col-5">
                  <p className="pb-5">Yang membuat berita acara <br /> Pengawas</p>
                  <p>Lembaga Pendidikan Manajemen dan Informatika Alfa Prima</p>
                  <p className="pb-5">Nama</p>
                  <p className="mb-0">Tanda tangan</p>
                </div>
              </div>

            </li>
          </ul>
        </div>
        <PrintButton />
      </PrintLayout>
    </>
  );
};

export default KelasBeritaAcaraUas;
