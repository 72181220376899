import React from "react";
import { patchMhs } from "../../api";
import { saAlert, saConfirm } from "../../helpers";

const MhsResetPassword = (props) => {
  const { mhs, onFetchData } = props;

  const handleResetPassword = () => {
    saConfirm(
      "warning",
      `Reset password akun  ? ${mhs.nama}`,
      "Fitur ini dapat digunakan ketika mahasiswa lupa password pada SIA."
    ).then((res) => {
      if (res.isConfirmed) {
        patchMhs(mhs.id, { reset_pw: true }).then((res) => {
          if (res.data === "reset_pw") {
            onFetchData();
            saAlert("success", "Berhasil reset password Mhs !");
          }
        });
      }
    });
  };
  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-warning-2 text-sm py-0 px-1 me-1"
        onClick={handleResetPassword}
      >
        <i className="fa fa-fw fa-sm fa-key"/>
      </button>
    </>
  );
};

export default MhsResetPassword;
