import { useState } from "react";
import { fetchDataControl } from "../api";
import axios from "../axios";
import { saAlert } from "../helpers";

const Login = () => {
  const [isLoaded, setIsLoaded] = useState(true);

  const [username, setUsername] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();

    const loginError = () => {
      setIsLoaded(true);
      saAlert(
        "error",
        "Login Gagal",
        "Pastikan anda memasukkan username dan password yang valid."
      );
    };

    const login = async () => {
      setIsLoaded(false);
      fetchDataControl(async () => {
        try {
          const res = await axios.post("/api/login", { username, password });
          let _token = res.data._token.split("|")[1];
          // let user = res.data.user;

          localStorage.setItem("_token", _token);
          // localStorage.setItem('user', JSON.stringify(user))

          // setToken(_token);
          // setUser(user);

          // saAlert("success", "Berhasil Login");
          // history.push("/dashboard");

          window.location.reload();
        } catch (err) {
          loginError();
        }
      });
      setIsLoaded(true);
    };

    login();
  };

  if (isLoaded) {
    return (
      <div className="container">
        <div className="row align-items-center vh-100">
          <div className="col-lg-6 mx-auto">
            <div className="bg-white rounded-bottom-4 rounded-top-5 shadow-lg">
              <div className="bg-gradient-primary border-bottom-warning shadow rounded-4 py-5 px-2">
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div>
                      <img src="https://dev.alfaprima.id/assets/img/logo/alfaprima_white.png" alt="" width={128}/>
                  </div>
                </div>
                <h6 className="mb-0 mt-2 text-center text-gray-500">Sistem Informasi Manajemen <span className="text-nowrap">Alfa Prima</span></h6>
              </div>
              <div className="px-4 pt-4 pb-3">
                <form
                  method="post"
                  onSubmit={handleSubmit}
                >
                  <div className="input-group border-0 mb-2">
                    <span className="input-group-text bg-white text-dark rounded-start-3"><i className="fa fa-fw fa-user fa-sm"/></span>
                    <input 
                      type="text" 
                      name="username"
                      onKeyUp={(e) => setUsername(e.target.value)} 
                      className="form-control form-control-custom rounded-end-3 shadow-none" 
                      placeholder="NIP"
                    />
                  </div>
                  <div className="input-group border-0 mb-4">
                    <span className="input-group-text bg-white text-dark rounded-start-3"><i className="fa fa-fw fa-key fa-sm"/></span>
                    <input 
                      type="password" 
                      name="password"
                      onKeyUp={(e) => setPassword(e.target.value)}
                      className="form-control form-control-custom rounded-end-3 shadow-none" 
                      placeholder="Password"
                    />
                  </div>
                  <button type="submit" className="btn btn-primary-4 py-2 rounded-3 w-100 mb-2">Login</button>
                </form>
                <p className="mb-0 mt-1 text-sm text-center text-secondary">Copyright © Alfa Prima 2020 - {new Date().getFullYear()}</p>
              </div>
            </div>
          </div>
        </div>
      </div>










      // <div className="d-flex justify-content-center align-items-center vh-100 text-white">
      //   <div className="container">
      //     <div className="row justify-content-center">
      //       <div className="col-xl-7 col-lg-9 col-md-6">
      //         <div className="card o-hidden border-0 shadow-lg">
      //           <div className="card-body p-0">
      //             <div className="p-5">
      //               <div className="text-center">
      //                 <div className="text-center">
      //                   <img
      //                     src="./logo.jpg"
      //                     className="img-fluid w-25"
      //                     alt=""
      //                   />
      //                 </div>
      //                 <h1 className="h4 text-gray-900 mb-4">
      //                   SIM - Alfa Prima
      //                 </h1>
      //               </div>
      //               <form
      //                 className="user"
      //                 method="post"
      //                 onSubmit={handleSubmit}
      //               >
      //                 <div className="form-group">
      //                   <input
      //                     type="text"
      //                     name="username"
      //                     onKeyUp={(e) => setUsername(e.target.value)}
      //                     className="form-control form-control-user"
      //                     placeholder="NIP . . ."
      //                   />
      //                 </div>
      //                 <div className="form-group">
      //                   <input
      //                     type="password"
      //                     name="password"
      //                     onKeyUp={(e) => setPassword(e.target.value)}
      //                     className="form-control form-control-user"
      //                     placeholder="Password"
      //                   />
      //                 </div>
      //                 <button
      //                   type="submit"
      //                   className="btn btn-primary btn-user btn-block"
      //                 >
      //                   Login
      //                 </button>
      //                 {/* <Link to="dashboard">Dashboard</Link> */}
      //               </form>
      //             </div>
      //           </div>
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100 text-white">
        <h1>Authenticating . . .</h1>
      </div>
    );
  }
};

export default Login;
