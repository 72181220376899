import React, { useEffect, useRef, useState } from 'react'
import { LayoutAddBtn } from '../../components/layouts/TopContent'
import LayoutModal, { NewSubmitButton } from '../../components/layouts/Modal';
import FormInput from '../../components/forms/Input';
import { patchArusKas, postArusKas } from '../../api';
import { saAlert } from '../../helpers';
import SmallButton from '../../components/forms/SmallButton';

function ArusKasEdit( {fetchdata, arusKas} ) {

  const [modal, setModal] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const tagForm = useRef(null);

  const handleOpen = () => {
    setModal(true);
    setForm(arusKas);
  }

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
    setForm({});
    setErrors({});
  }

  const handleChange = (e) => {
    setForm((prevState) => {
      return {...prevState,[e.target.name]:e.target.value};
    });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    try {
      const res = await patchArusKas(form.id, form);
      console.log(res.data);
      saAlert(res.data.status, res.data.msg);
      handleClose();
      fetchdata();
    } catch (error) {
      setErrors(error.response && error.response.data && error.response.data.errors);
    }
  }

  return (
    <>
      <SmallButton onClick={handleOpen} margin='me-1' type='edit'/>

      <LayoutModal
        size='sm'
        title='Edit Arus kas'
        modal={modal} 
        onClose={handleClose}
      >

        <form onSubmit={handleSubmit} ref={tagForm}>
          <FormInput
            label='Tanggal'
            type='date'
            name='tanggal'
            value={form.tanggal}
            onChange={handleChange}
            error={errors.tanggal}
          />
          <NewSubmitButton widthFull onClose={handleClose}/>
        </form>
      </LayoutModal>
    </>
  )
}

export default ArusKasEdit