import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  fetchDosen,
  fetchJamkul,
  fetchKelas,
  fetchMatkul,
  fetchMhs,
  fetchRuangan,
  postPengajaran,
} from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterSearch,
  FilterTahunAjaran,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { getHari, saAlert, saConfirm } from "../../helpers";
import { semesterAjaranState, tahunAjaranState } from "../../storage/mhs";
import { angkatanState, titleState } from "../../storage/pages";
import PengajaranList from "../pengajaran/List";
import KelasAddMhs from "./AddMhs";
import KelasAngketDosenDetail from "./AngketDosenDetail";
import KelasCreate from "./Create";
import KelasEdit from "./Edit";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import SmallButton from "../../components/forms/SmallButton";

const KelasAkademik = () => {
  const { tanggalPengajaran } = useParams();
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);
  const tA = useRecoilValue(tahunAjaranState);
  const sA = useRecoilValue(semesterAjaranState);

  useEffect(() => setTitle("Kelas Perkuliahan"), [setTitle]);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [tahunAjaran, setTahunAjaran] = useState(tA);
  const [semesterAjaran, setSemesterAjaran] = useState(sA);

  // Table Sorting
  const [tableKode, setTableKode] = useState("");
  const [tableMatkul, setTableMatkul] = useState("");
  const [tableDosen, setTableDosen] = useState("");
  const [tableJamkul, setTableJamkul] = useState("");

  const handleResetFilter = () => {
    setTableKode("");
    setTableMatkul("");
    setTableDosen("");
    setTableJamkul("");
  };

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchKelas(
        page,
        `search=${search}&tahun_ajaran=${tahunAjaran}&semester_ajaran=${semesterAjaran}&table_kode=${tableKode}&table_matkul=${tableMatkul}&table_dosen=${tableDosen}&table_jamkul=${tableJamkul}`
      );
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [
    page,
    search,
    tahunAjaran,
    semesterAjaran,
    tableKode,
    tableMatkul,
    tableDosen,
    tableJamkul,
  ]);

  useEffect(() => {
    setTitle("Kelas Perkuliahan");
    fetchData();
  }, [setTitle, fetchData]);

  // Bahan
  // Matkul
  const [matkul, setMatkul] = useState({});
  useEffect(() => {
    const fetchDataMatkul = async () => {
      try {
        const res = await fetchMatkul("all");
        res.data && res.data.matkul && setMatkul(res.data.matkul);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDataMatkul();
  }, []);

  // Dosen
  const [dosen, setDosen] = useState({});
  useEffect(() => {
    const fetchDataDosen = async () => {
      try {
        const res = await fetchDosen("all");
        setDosen(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDataDosen();
  }, []);

  // Ruangan
  const [ruangan, setRuangan] = useState({});
  useEffect(() => {
    const fetchDataRuangan = async () => {
      try {
        const res = await fetchRuangan("all");
        setRuangan(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDataRuangan();
  }, []);

  // Jamkul
  const [jamkul, setJamkul] = useState({});
  useEffect(() => {
    const fetchDataJamkul = async () => {
      try {
        const res = await fetchJamkul("all");
        setJamkul(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDataJamkul();
  }, []);

  const defAngkatan = useRecoilValue(angkatanState);

  // mhs
  const [dataMhs, setDataMhs] = useState({});
  const [pageMhs, setPageMhs] = useState(1);
  const [paginationMhs, setPaginationMhs] = useState(10);
  const [searchMhs, setSearchMhs] = useState("");
  const [angkatanMhs, setAngkatanMhs] = useState(defAngkatan);
  const [jenjangMhs, setJenjangMhs] = useState("");
  const [jurusanMhs, setJurusanMhs] = useState("");

  const getMhs = useCallback(async () => {
    try {
      const res = await fetchMhs(
        pageMhs,
        `pagination=${paginationMhs}&search=${searchMhs}&angkatan=${angkatanMhs}&jenjang=${jenjangMhs}&jurusan=${jurusanMhs}`
      );
      if (res.data && res.data.mhs) {
        setDataMhs(res.data.mhs);
      }
    } catch (err) {
      console.log(err);
    }
  }, [pageMhs, paginationMhs, searchMhs, angkatanMhs, jenjangMhs, jurusanMhs]);

  useEffect(() => {
    getMhs();
  }, [getMhs]);

  const handleAddToPengajaran = (kelas_id, tahun_ajaran, semester, tanggal) => {
    saConfirm("warning", "Yakin tambah kelas ini kedalam kelas perkuliahan ?")
      .then((res) => {
        if (res.isConfirmed) {
          postPengajaran({
            add_one: true,
            kelas_id,
            tahun_ajaran,
            semester,
            tanggal,
          }).then((res) => {
            if (res.data === "success") {
              window.history.go(-1);
              saAlert(
                "success",
                "Berhasil menambahkan kelas Custom pada pengajaran."
              );
            }
          });
        }
      })
      .catch((err) => console.log(err));
  };

  const countAbsensi = (i) => {
    let absensi =
      i.pengajaran &&
      i.pengajaran.length > 0 &&
      i.pengajaran.filter((p) => p.status !== 9 && p.absensi.length > 0).length;
    return (
      absensi < 14 && (
        <span>(<i className="text-primary fa fa-fw fa-sm fa-user"/> Absensi - {14 - absensi} Pertemuan)</span>
      )
    );
  };

  const countPembahasan = (i) => {
    let pembahasan = i.pengajaran.filter(
      (p) => p.status !== 9 && p.pembahasan !== null
    ).length;
    return (
      pembahasan < 14 && (
        <span>(<i className="text-primary fa fa-fw fa-sm fa-bullhorn"/> Pembahasan - {14 - pembahasan})</span>
      )
    );
  };

  const countTugas = (i) => {
    let tugas = i.pengajaran.filter(
      (p) => p.status !== 9 && p.tugas !== null
    ).length;
    return (
      tugas < 12 && <span>(<i className="text-primary fa fa-fw fa-sm fa-receipt"/> Tugas - {12 - tugas})</span>
    );
  };

  const countHambatan = (i) => {
    let hambatan = i.pengajaran.filter(
      (p) => p.status !== 9 && p.hambatan !== null
    ).length;
    return (
      hambatan < 12 && (
        <span>(<i className="text-primary fa fa-fw fa-sm fa-down-long"/> Hambatan - {12 - hambatan})</span>
      )
    );
  };

  const countBuktiPengajaran = (i) => {
    let bukti_pengajaran = i.pengajaran.filter(
      (p) => p.status !== 9 && p.bukti_pengajaran !== null
    ).length;
    return (
      bukti_pengajaran < 12 && <span>(<i className="text-primary fa fa-fw fa-sm fa-image"/> Bukti Pengajaran - {12 - bukti_pengajaran})</span>
    );
  };

  if (isLoaded) {
    return (
      <>
        <KelasCreate
          onFetchData={fetchData}
          matkul={matkul}
          dosen={dosen}
          ruangan={ruangan}
          jamkul={jamkul}
        />
        <div className="row mb-3">
          <div className="col-6">
            <FilterTahunAjaran
              onChangeTahun={(res) => setTahunAjaran(res)}
              onChangeSemester={(res) => setSemesterAjaran(res)}
              valueTahun={tahunAjaran}
              valueSemester={semesterAjaran}
            />
          </div>
          <div className="col-6">
            <FilterSearch
              onChange={(val) => {
                setPage(1);
                setSearch(val);
              }}
            />
          </div>
        </div>
        {items.data.length >= 1 ? (
          <div className="row">
            <div className={tanggalPengajaran ? "col-3 pe-0" : "col-2 pe-0"}>
              <TableNew>
                <Thead>
                  <Tr className="text-center">
                    <Th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">No</Th>
                    <Th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">
                      <select
                        className="form-select form-select-sm form-select-custom py-0 text-sm fw-semibold rounded-3 w-auto mx-auto"
                        value={tableKode}
                        onChange={(e) => setTableKode(e.target.value)}
                      >
                        <option value="">Kode</option>
                        <option value="1">Kode ++</option>
                        <option value="0">Kode --</option>
                      </select>
                    </Th>
                    {tanggalPengajaran && <Th><i className="fa fa-fw fa-cog"/></Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="py-1 border-silver text-center text-nowrap">
                          {items.from + index}
                        </Td>
                        <Td className="py-1 border-silver text-center text-nowrap">{i.kode}</Td>
                        {tanggalPengajaran && (
                          <Td className="text-center text-nowrap">
                            {/* <span
                              className="badge badge-success"
                              type="button"
                              
                            >
                              Add to Pengajaran
                            </span> */}

                            <SmallButton
                              type="custom"
                              buttonColor="primary-3"
                              icon="plus"
                              text="Tambah ke pengajaran"
                              onClick={() =>
                                handleAddToPengajaran(
                                  i.id,
                                  tahunAjaran,
                                  semesterAjaran,
                                  tanggalPengajaran
                                )
                              }
                            />
                          </Td>
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className={tanggalPengajaran ? "col-6 px-0" : "col-7 px-0"}>
              <LayoutTable>
                <LayoutThead>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap text-center">Cabang</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">Tahun Ajaran</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">
                    <select
                      className="form-select form-select-sm form-select-custom py-0 text-sm fw-semibold rounded-3 w-auto mx-auto"
                      value={tableMatkul}
                      onChange={(e) => setTableMatkul(e.target.value)}
                    >
                      <option value="">Mata Kuliah</option>
                      <option value="1">Mata Kuliah ++</option>
                      <option value="0">Mata Kuliah --</option>
                    </select>
                  </th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">Semester</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">Jumlah Pertemuan</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">
                    <select
                      className="form-select form-select-sm form-select-custom py-0 text-sm fw-semibold rounded-3 w-auto mx-auto"
                      value={tableDosen}
                      onChange={(e) => setTableDosen(e.target.value)}
                    >
                      <option value="">Dosen</option>
                      <option value="1">Dosen ++</option>
                      <option value="0">Dosen --</option>
                    </select>
                  </th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">Ruangan</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">
                    <select
                      className="form-select form-select-sm form-select-custom py-0 text-sm fw-semibold rounded-3 w-auto mx-auto"
                      value={tableJamkul}
                      onChange={(e) => setTableJamkul(e.target.value)}
                    >
                      <option value="">Jam Kuliah</option>
                      <option value="1">Jam Kuliah ++</option>
                      <option value="0">Jam Kuliah --</option>
                    </select>
                  </th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">Jadwal</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">Projector</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">Group WA</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">% Kehadiran</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">% Tugas</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">% Uts</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">% Uas</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">% Revisi</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">Tugas</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">UTS</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">UAS</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">Keterangan</th>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver fw-normal text-nowrap">Angket Dosen</th>
                </LayoutThead>
                <tbody>
                  {items.data.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td className="py-1 border-silver text-nowrap text-center">{i.cabang}</td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {i.tahun_ajaran}
                        </td>
                        <td className="py-1 border-silver text-nowrap">
                          {i.matkul && i.matkul.nama}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {i.semester}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {i.jumlah_pertemuan}
                        </td>
                        <td className="py-1 border-silver text-nowrap">
                          {i.dosen && i.dosen.nama}
                        </td>
                        <td className="py-1 border-silver text-nowrap">
                          {i.ruangan && i.ruangan.nama}
                        </td>
                        <td className="py-1 border-silver text-nowrap">
                          {i.jamkul &&
                            getHari(i.jamkul.hari) +
                              " || " +
                              i.jamkul.dari +
                              "-" +
                              i.jamkul.sampai}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {parseInt(i.jadwal) === 1 ? "Pagi" : "Sore"}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {parseInt(i.projector) === 1 ? "Ya" : "Tidak"}
                        </td>
                        <td className="py-1 border-silver text-nowrap">{i.group_whatsapp}</td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {i.persentasi_kehadiran}%
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {i.persentasi_tugas}%
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {i.persentasi_uts}%
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {i.persentasi_uas}%
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {i.persentasi_revisi}%
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center fw-semibold">
                          <span className={`
                            ${i.krs.filter((k) => k.nilai && k.nilai.tugas).length === 0 ? 'text-danger-2' : ''}
                            ${i.krs.filter((k) => k.nilai && k.nilai.tugas).length < i.krs.length ? 'text-warning' : ''}
                            ${i.krs.filter((k) => k.nilai && k.nilai.tugas).length === i.krs.length ? 'text-success-2' : ''}
                          `}>
                            {i.krs &&
                              i.krs.length > 0 &&
                              i.krs.filter((k) => k.nilai && k.nilai.tugas)
                                .length}
                          </span>
                          {`/`}
                          {i.krs && i.krs.length > 0 && i.krs.length}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center fw-semibold">
                          <span className={`
                            ${i.krs.filter((k) => k.nilai && k.nilai.uts).length === 0 ? 'text-danger-2' : ''}
                            ${i.krs.filter((k) => k.nilai && k.nilai.uts).length < i.krs.length ? 'text-warning' : ''}
                            ${i.krs.filter((k) => k.nilai && k.nilai.uts).length === i.krs.length ? 'text-success-2' : ''}
                          `}>
                            {i.krs &&
                              i.krs.length > 0 &&
                              i.krs.filter((k) => k.nilai && k.nilai.uts).length}
                          </span>
                          {`/`}
                          {i.krs && i.krs.length > 0 && i.krs.length}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center fw-semibold">
                          <span className={`
                            ${i.krs.filter((k) => k.nilai && k.nilai.uas).length === 0 ? 'text-danger-2' : ''}
                            ${i.krs.filter((k) => k.nilai && k.nilai.uas).length < i.krs.length ? 'text-warning' : ''}
                            ${i.krs.filter((k) => k.nilai && k.nilai.uas).length === i.krs.length ? 'text-success-2' : ''}
                          `}>
                            {i.krs &&
                              i.krs.length > 0 &&
                              i.krs.filter((k) => k.nilai && k.nilai.uas).length}
                          </span>
                          {`/`}
                          {i.krs && i.krs.length > 0 && i.krs.length}
                        </td>
                        <td className="py-1 border-silver text-nowrap">
                          {countAbsensi(i)}
                          {countPembahasan(i)}
                          {countTugas(i)}
                          {countHambatan(i)}
                          {countBuktiPengajaran(i)}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center fw-semibold">
                          <KelasAngketDosenDetail
                            kelas={i}
                            krs={i.krs}
                            penilaian_dosen={i.penilaian_dosen}
                            mhs={i.mhs}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
            <div className="col-3 ps-0">
              <LayoutTable>
                <LayoutThead>
                  <th className="py-1 bg-primary text-white align-middle border-bottom border-silver text-nowrap fw-normal">
                    <button onClick={handleResetFilter} className="px-3 btn btn-sm btn-light fw-semibold border-0 py-0 text-sm rounded mx-1">
                      <i className="fa fa-fw fa-sm fa-repeat"/> Reset filter tabel
                    </button>
                  </th>
                </LayoutThead>
                <tbody>
                  {items.data.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td className="border-silver text-nowrap text-center" style={{padding: '4.7px 0px'}}>
                          <div className="d-flex justify-content-center">
                            <KelasAddMhs
                              customClass="btn btn-sm btn-primary-3 border-0 py-0 px-1 text-sm mx-1"
                              customIcon="fa fa-fw fa-user-plus fa-sm"
                              customName=""
                              customTitle="Tambah Mahsiswa"
                              kelas={i}
                              mhs={dataMhs}
                              angkatan={angkatanMhs}
                              onChangePageMhs={(res) => setPageMhs(res)}
                              onChangePaginationMhs={(res) =>
                                setPaginationMhs(res)
                              }
                              onChangeSearchMhs={(res) => setSearchMhs(res)}
                              onChangeAngkatanMhs={(res) => setAngkatanMhs(res)}
                              onChangeJenjangMhs={(res) => setJenjangMhs(res)}
                              onChangeJurusanMhs={(res) => setJurusanMhs(res)}
                              onFetchData={() => {
                                fetchData();
                                getMhs();
                              }}
                            />
                            <KelasEdit
                              customClass="btn btn-sm btn-success-2 border-0 py-0 px-1 text-sm mx-1"
                              customIcon="fa fa-fw fa-pen"
                              customName=""
                              customTitle="Ubah Data Kelas"
                              onFetchData={fetchData}
                              kelas={i}
                              matkul={matkul}
                              dosen={dosen}
                              ruangan={ruangan}
                              jamkul={jamkul}
                            />
                            <PengajaranList
                              kelas={i}
                              pengajaran={
                                i.pengajaran &&
                                i.pengajaran.length > 0 &&
                                i.pengajaran[0]
                              }
                              tahunAjaran={tahunAjaran}
                              semesterAjaran={semesterAjaran}
                            />
                            <i className="mr-1" />
                            <select
                              className="form-select form-select-sm form-select-custom py-0 text-sm fw-semibold border-silver rounded w-auto mx-auto"
                              value="opsi"
                              onChange={(e) => window.open(e.target.value)}
                            >
                              <option value="opsi" disabled>
                                Opsi
                              </option>
                              <option value={`/kelas-absensi/${i.id}`}>
                                Cetak Absensi
                              </option>
                              <option value={`/kelas-jurnal/${i.id}`}>
                                Cetak Jurnal
                              </option>
                              <option value={`/kelas-berita-acara-uts/${i.id}`}>
                                Berita Acara UTS
                              </option>
                              <option value={`/kelas-berita-acara-uas/${i.id}`}>
                                Berita Acara UAS
                              </option>
                              <option value={`/nilai-uts/${i.id}`}>
                                Cetak Nilai UTS
                              </option>
                              <option value={`/nilai-uas/${i.id}`}>
                                Cetak Nilai UAS
                              </option>
                              <option value={`/daftar-nilai/${i.id}`}>
                                Daftar Nilai
                              </option>
                              <option value={`/kelas-angket-dosen/${i.id}`}>
                                Angket Dosen
                              </option>
                            </select>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
          </div>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default KelasAkademik;
