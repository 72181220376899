import React from "react";
import { SinggleMenu } from "./Sidebar";

const It = (props) => {
  const { user } = props;

  return (
    <>
      {user.jabatan === "IT Programmer" ? (
        <>
          <hr className="border-0 my-2" />

          <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
            It
          </div>

          <hr className="sidebar-divider border-0 mt-2 mb-0" />

          <SinggleMenu title="Cabang" link="/cabang" fa="fa-cogs" />
        </>
      ) : null}
    </>
  );
};

export default It;
