import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import {
  deleteProgkam,
  fetchBidang,
  fetchPenyelenggara,
  fetchProgkam,
} from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { FilterSearch } from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { saAlert, saConfirm } from "../../helpers";
import { titleState } from "../../storage/pages";
import ProgkamCreateLepasan from "./CreateLepasan";
import ProgkamEditLepasan from "./EditLepasan";

const ProgkamLepasan = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const setTitle = useSetRecoilState(titleState);

  useEffect(() => {
    setTitle("Data Program Lepasan");
  }, [setTitle]);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchProgkam(page, `lepasan=true&search=${search}`);
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Penyelenggara
  const [itemsPenyelenggara, setItemsPenyelenggara] = useState({});

  useEffect(() => {
    const fetchDataPenyelenggara = async () => {
      try {
        const res = await fetchPenyelenggara("all");
        setItemsPenyelenggara(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDataPenyelenggara();
  }, []);
  // End Penyelenggara

  // Bidang
  const [itemsBidang, setItemsBidang] = useState({});

  useEffect(() => {
    const fetchDataBidang = async () => {
      try {
        const res = await fetchBidang("all");
        setItemsBidang(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDataBidang();
  }, []);
  // End Bidang

  // Delete
  const handleDelete = async (id) => {
    saConfirm("warning", "Yakin hapus data Program Lepasan ?").then((res) => {
      if (res.isConfirmed) {
        deleteProgkam(id)
          .then((res) => {
            if (res.data && res.data === "success") {
              fetchData();
              saAlert("success", "Berhasil hapus data Program Lepasan !");
            }
          })
          .catch((err) => console.error(err));
      }
    });
  };
  // End Delete

  if (isLoaded) {
    return (
      <>
        <ProgkamCreateLepasan
          penyelenggara={itemsPenyelenggara}
          bidang={itemsBidang}
          onFetchData={fetchData}
        />
        <div className="row mb-2">
          <div className="col-6"></div>
          <div className="col-6">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {items.data && items.data.length > 0 ? (
          <LayoutTable>
            <LayoutThead>
              <th className="align-middle">No</th>
              <th className="align-middle">Cabang</th>
              <th className="align-middle">NIM Mahasiswa</th>
              <th className="align-middle">Nama Mahasiswa</th>
              <th className="align-middle">Nomor Kegiatan</th>
              <th className="align-middle">Nama Kegiatan</th>
              <th className="align-middle">Penyelenggara</th>
              <th className="align-middle">Bidang</th>
              <th className="align-middle">Tanggal</th>
              <th className="align-middle">Tempat</th>
              <th className="align-middle">Nilai</th>
              <th className="align-middle">
                <i className="fa fa-bars"></i>
              </th>
            </LayoutThead>
            <tbody>
              {items.data.map((i, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center align-middle">
                      {items.from + index}
                    </td>
                    <td className="text-center align-middle">{i.cabang}</td>
                    <td className="text-center align-middle">{i.nim_mhs}</td>
                    <td className="text-center align-middle">
                      {i.mhs_lep && i.mhs_lep.nama}
                    </td>
                    <td className="text-center align-middle">{i.nomor}</td>
                    <td className="align-middle">{i.nama}</td>
                    <td className="align-middle">
                      {i.penyelenggara && i.penyelenggara.nama}
                    </td>
                    <td className="align-middle">
                      {i.bidang && i.bidang.nama}
                    </td>
                    <td className="text-center align-middle text-nowrap">
                      {i.tanggal}
                    </td>
                    <td className="align-middle">{i.tempat}</td>
                    <td className="text-center align-middle text-nowrap">
                      {i.nilai ?? "-"}
                    </td>
                    <td className="text-center align-middle text-nowrap">
                      <ProgkamEditLepasan
                        progkam={i}
                        penyelenggara={itemsPenyelenggara}
                        bidang={itemsBidang}
                        onFetchData={fetchData}
                      />
                      <i className="mr-1" />
                      <span
                        className="badge badge-danger"
                        type="button"
                        onClick={() => handleDelete(i.id)}
                      >
                        <i className="fa fa-trash-alt" />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </LayoutTable>
        ) : (
          <PageNoData />
        )}

        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default ProgkamLepasan;
