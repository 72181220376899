import React, { useEffect, useRef, useState } from "react";
import { fetchMhs, postBiaya } from "../../api";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { FilterSearch } from "../../components/layouts/TopContent";
import { getCurrency, getJurusan, saAlert } from "../../helpers";
import SmallButton from "../../components/forms/SmallButton";
import FormInputCurrency from "../../components/forms/InputCurrency";

const PendaftarBiaya = (props) => {
  const { onFetchData, pendaftar } = props;
  const tagForm = useRef();
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});
    postBiaya({ ...form, save_update: true })
      .then((res) => {
        if (res.data === "success") {
          onFetchData();
          handleClose();
          saAlert("success", "Berhasil update data !");
        }

        if (res.data === "mhs_id") {
          saAlert(
            "warning",
            "ID mahasiswa sudah digunakan, silahkan hapus ID mahasiswa sebelumnya."
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          setErrors(err.response.data.errors);
        }
      });
  };

  const [searchNama, setSearchNama] = useState("");
  const [resultSearch, setResultSearch] = useState({});

  useEffect(() => {
    const handleSearchId = () => {
      fetchMhs("all", `search=${searchNama}&limit=5`).then((res) => {
        if (res.data && res.data.mhs) {
          setResultSearch(res.data.mhs);
        }
      });
    };
    if (modal) {
      handleSearchId();
    }
  }, [modal, searchNama]);

  const handleOpen = () => {
    setForm((prevState) => {
      return {
        ...prevState,
        ...pendaftar.biaya,
        pendaftar_id: pendaftar.id,
      };
    });
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setForm({});
    setErrors({});
    setSearchNama("");
    setResultSearch({});
    setModal(false);
  };

  return (
    <>
      <SmallButton onClick={handleOpen} margin='me-1' type='custom' icon='sack-dollar' buttonColor='warning-2'/>
      <LayoutModal
        title={`Biaya Pendaftaran ${pendaftar.nama}`}
        modal={modal}
        onClose={handleClose}
      >
        <form ref={tagForm} onSubmit={handleSubmit}>
          <div className="bg-gradient-primary rounded-3 shadow p-3 mb-3 h-100">
            <div className="row align-items-center mb-3">
              <div className="col-lg-1">
                <div className="d-flex justify-content-center align-items-center">
                  <div className="d-inline-block px-3 py-4 bg-black rounded">
                    <i className="fa fa-fw fa-2xl fa-user"/>
                  </div>
                </div>
              </div>
              <div className="col-lg-11">
                <div className="text-white">
                  <p className="mb-0 text-warning text-sm">Pendaftar</p>
                  <h6 className="mb-0 fs-5">{pendaftar.nama}</h6>
                  <p className="mb-0 text-gray-400">{pendaftar.prodi}</p>
                </div>
              </div>
            </div>

            <hr className="border-silver"/>

            <h6 className="fw-semibold text-warning"><i className="fa fa-fw fa-bullhorn"/> Perhatian</h6>
            <ul className="mb-0 ps-3 text-white text-sm text-justify">
              <li>
                Mengisi ID Mahasiswa hanya dibutuhkan oleh Pendaftar{" "}
                <span className="text-warning">Angkatan 2021</span> kebawah
              </li>
              <li>
                Pendaftar <span className="text-warning">Angkatan 2022</span>{" "}
                keatas akan dibuatkan ID Mahasiswa secara otomatis oleh sistem
              </li>
              <li>
                Silahkan gunakan kolom <span className="text-warning">pencarian</span> disebelah kanan untuk mendapatkan ID mahasiswa
              </li>
              <li>
                Pastikan detail Mahasiswa sudah benar agar <span className="text-warning">tidak salah</span> menghubungkan ID Mahasiswa
              </li>
              <li>
                Satu ID Mahasiswa hanya dapat digunakan oleh <span className="text-warning">satu orang</span> Pendaftar
              </li>
            </ul>
          </div>
          <div className="row">
                <div className="col-lg-6">
                  <FilterSearch
                    label="Nama Pendaftar"
                    placeholder="Nama Pendaftar . . . "
                    onChange={(res) => setSearchNama(res)}
                  />
                </div>

                <div className="col-lg-6">
                  <FormInput
                    label="ID Mahasiswa"
                    name="mhs_id"
                    type="text"
                    value={form.mhs_id}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return { ...prevState, [e.target.name]: e.target.value };
                      })
                    }
                    error={errors.mhs_id}
                  />
                </div>

                <div className="col-lg-12">
                  {searchNama.length > 5 &&
                    resultSearch &&
                    resultSearch.length > 0 &&
                    resultSearch.length <= 5 ? (
                      <LayoutTable>
                        <LayoutThead>
                          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">ID Mhs</th>
                          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">NIM</th>
                          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Nama</th>
                          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Angkatan</th>
                          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Jenjang</th>
                          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Jurusan</th>
                        </LayoutThead>
                        <tbody>
                          {resultSearch.map((i, index) => {
                            return (
                              <tr key={index}>
                                <td className="border-silver text-center align-middle text-warning bg-primary-4">
                                  {i.id}
                                </td>
                                <td className="border-silver text-center align-middle">
                                  {i.nim}
                                </td>
                                <td className="border-silver align-middle">{i.nama}</td>
                                <td className="border-silver text-center align-middle">
                                  {i.angkatan}
                                </td>
                                <td className="border-silver text-center align-middle">
                                  {i.jenjang}
                                </td>
                                <td className="border-silver align-middle">
                                  {getJurusan(i.jurusan_id)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </LayoutTable>
                    ) :
                      <div className="text-center border border-silver rounded-3 p-4 mb-3">
                        <div className="col-lg-9 mx-auto">
                          <i className="fa fa-fw fa-search fa-2xl mb-4"/>
                          <p className="mb-0">Silahkan melakukan pencarian dengan memasukan nama pendaftar untuk mendapatkan ID Mahasiswa dan Data Pencarian ID Mahasiswa akan muncul disini.</p>
                        </div>
                      </div>  
                    }
                </div>
              </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-12 mb-3">
                  <FormInputCurrency
                    label='Biaya Pendidikan'
                    name="biaya_pendidikan"
                    type="text"
                    value={form.biaya_pendidikan}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return { ...prevState, [e.target.name]: e.target.value };
                      })
                    }
                    error={errors.biaya_pendidikan}
                  />
                  {/* <div className="bg-primary text-white rounded p-2">
                    <h6 className="mb-0 text-sm">Biaya Pendidikan</h6>
                    <h6 className="fw-semibold text-success-2">Rp. {form.biaya_pendidikan ? getCurrency(form.biaya_pendidikan,0) : 0}</h6>
                    <FormInput
                      className="bg-primary-4 text-white border-0"
                      placeholder="Ketik disini. . ."
                      marginBottom="mb-0"
                      name="biaya_pendidikan"
                      type="text"
                      value={form.biaya_pendidikan}
                      onChange={(e) =>
                        setForm((prevState) => {
                          return { ...prevState, [e.target.name]: e.target.value };
                        })
                      }
                      error={errors.biaya_pendidikan}
                    />
                  </div> */}
                </div>
                <div className="col-lg-12 mb-3">
                  <FormInputCurrency
                    label='Subsidi'
                    name="subsidi"
                    type="text"
                    value={form.subsidi}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return { ...prevState, [e.target.name]: e.target.value };
                      })
                    }
                    error={errors.subsidi}
                  />
                  {/* <div className="bg-primary text-white rounded p-2">
                    <h6 className="mb-0 text-sm">Subsidi</h6>
                    <h6 className="fw-semibold text-success-2">Rp. {form.subsidi ? getCurrency(form.subsidi,0) : 0}</h6>
                    <FormInput
                      className="bg-primary-4 text-white border-0"
                      placeholder="Ketik disini. . ."
                      marginBottom="mb-0"
                      name="subsidi"
                      type="text"
                      value={form.subsidi}
                      onChange={(e) =>
                        setForm((prevState) => {
                          return { ...prevState, [e.target.name]: e.target.value };
                        })
                      }
                      error={errors.subsidi}
                    />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-6 mb-3">
              <div className="bg-gradient-primary text-white rounded-4 px-3 py-5 h-100 d-flex justify-content-center align-items-center">
                <div className="text-center">
                  <h6 className="mb-2 text-sm fw-semibold text-warning">Biaya Setelah Subsidi</h6>
                  <div className="position-relative">
                    <h6 className="mb-0 fs-4 fw-semibold">Rp. {form.subsidi ? getCurrency(
                      parseInt(form.biaya_pendidikan) - parseInt(form.subsidi),
                      0
                    ) : 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 mb-3">
              <FormInputCurrency
                label='Biaya Kelas Malam'
                name="biaya_malam"
                type="text"
                value={form.biaya_malam}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.biaya_malam}
              />
              {/* <div className="bg-primary text-white rounded p-2">
                <h6 className="mb-0 text-sm">Kelas Malam (Optional)</h6>
                <h6 className="fw-semibold text-success-2">Rp. {form.biaya_malam ? getCurrency(form.biaya_malam,0) : 0}</h6>
                <FormInput
                  className="bg-primary-4 text-white border-0"
                  placeholder="Ketik disini. . ."
                  marginBottom="mb-0"
                  name="biaya_malam"
                  type="text"
                  value={form.biaya_malam}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return { ...prevState, [e.target.name]: e.target.value };
                    })
                  }
                  error={errors.biaya_malam}
                />
              </div> */}
            </div>
            <div className="col-lg-3 mb-3">
              <FormInputCurrency
                label='Biaya Daftar (seharusnya)'
                name="biaya_daftar"
                type="text"
                value={form.biaya_daftar}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.biaya_daftar}
              />
              {/* <div className="bg-primary text-white rounded p-2">
                <h6 className="mb-0 text-sm">Biaya Daftar (Normal)</h6>
                <h6 className="fw-semibold text-success-2">Rp. {form.biaya_daftar ? getCurrency(form.biaya_daftar,0) : 0}</h6>
                <FormInput
                  className="bg-primary-4 text-white border-0"
                  placeholder="Ketik disini. . ."
                  marginBottom="mb-0"
                  name="biaya_daftar"
                  type="text"
                  value={form.biaya_daftar}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return { ...prevState, [e.target.name]: e.target.value };
                    })
                  }
                  error={errors.biaya_daftar}
                />
              </div> */}
            </div>
            <div className="col-lg-3 mb-3">
              <FormInputCurrency
                label='Biaya Daftar (setelah promo)'
                name="daftar"
                type="text"
                value={form.daftar}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.daftar}
              />
              {/* <div className="bg-primary text-white rounded p-2">
                <h6 className="mb-0 text-sm">Biaya Daftar (Setelah Promo)</h6>
                <h6 className="fw-semibold text-success-2">Rp. {form.daftar ? getCurrency(form.daftar,0) : 0}</h6>
                <FormInput
                  className="bg-primary-4 text-white border-0"
                  placeholder="Ketik disini. . ."
                  marginBottom="mb-0"
                  name="daftar"
                  type="text"
                  value={form.daftar}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return { ...prevState, [e.target.name]: e.target.value };
                    })
                  }
                  error={errors.daftar}
                />
              </div> */}
            </div>
            <div className="col-lg-3 mb-3">
              <div className="bg-gradient-primary text-white rounded-4 p-3 h-100 d-flex justify-content-center align-items-end">
                <div className="text-center w-100">
                  <h6 className="mb-2 text-sm fw-semibold text-warning">Tanggal Daftar</h6>
                  <FormInput
                    className="border-0"
                    marginBottom="mb-0"
                    name="tanggal_daftar"
                    type="date"
                    value={form.tanggal_daftar}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return { ...prevState, [e.target.name]: e.target.value };
                      })
                    }
                    error={errors.tanggal_daftar}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 mb-3">
              <FormInputCurrency
                label='Biaya DPP'
                name="biaya_dpp"
                type="text"
                value={form.biaya_dpp}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.biaya_dpp}
              />
              {/* <FormInput
                label={`Biaya Daftar Ulang (DPP) <span class="text-success">Rp ${getCurrency(
                  form.biaya_dpp,
                  0
                )}</span>`}
                name="biaya_dpp"
                type="text"
                value={form.biaya_dpp}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.biaya_dpp}
              /> */}
            </div>
            <div className="col-lg-6 mb-3">
              <FormInputCurrency
                label='Diskon DPP'
                name="diskon_dpp"
                type="text"
                value={form.diskon_dpp}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.diskon_dpp}
              />
              {/* <FormInput
                label={`Diskon Daftar Ulang (DPP) <span class="text-success">Rp ${getCurrency(
                  form.diskon_dpp,
                  0
                )}</span>`}
                name="diskon_dpp"
                type="text"
                value={form.diskon_dpp}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.diskon_dpp}
              /> */}
            </div>
            <div className="col-lg-6 mb-3">
              <div className="bg-gradient-primary text-white rounded-4 px-3 py-5 h-100 d-flex justify-content-center align-items-center">
                <div className="text-center">
                  <h6 className="mb-2 text-sm fw-semibold text-warning">DPP setelah Diskon</h6>
                  <div className="position-relative">
                    <h6 className="mb-0 fs-4 fw-semibold">Rp. {form.biaya_dpp ? 
                    getCurrency(
                      (Number.isInteger(parseInt(form.biaya_dpp))
                        ? parseInt(form.biaya_dpp)
                        : 0) -
                        (Number.isInteger(parseInt(form.diskon_dpp))
                          ? parseInt(form.diskon_dpp)
                          : 0),
                      0
                    ) 
                    : 0}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-6 text-primary bg-light">
              <p className="mb-2 font-weight-bold">DPP setelah Diskon :</p>
              <span className="text-success font-weight-bold">
                Rp{" "}
                {getCurrency(
                  (Number.isInteger(parseInt(form.biaya_dpp))
                    ? parseInt(form.biaya_dpp)
                    : 0) -
                    (Number.isInteger(parseInt(form.diskon_dpp))
                      ? parseInt(form.diskon_dpp)
                      : 0),
                  0
                )}
              </span>
            </div> */}
            <div className="col-lg-6 mb-3">
              <div className="bg-gradient-primary text-white rounded-4 px-3 py-5 h-100 d-flex justify-content-center align-items-center">
                <div className="text-center">
                  <h6 className="mb-2 text-sm fw-semibold text-warning">Total yang sudah dibayarkan</h6>
                  <div className="position-relative">
                    <h6 className="mb-0 fs-4 fw-semibold">Rp. {form.du_1 ? 
                    getCurrency(
                      (Number.isInteger(parseInt(form.du_1))
                        ? parseInt(form.du_1)
                        : 0) +
                        (Number.isInteger(parseInt(form.du_2))
                          ? parseInt(form.du_2)
                          : 0) +
                        (Number.isInteger(parseInt(form.du_3))
                          ? parseInt(form.du_3)
                          : 0),
                      0
                    )
                    : 0}
                    </h6>
                  </div>
                </div>
              </div>
              {/* <p className="mb-2 font-weight-bold">
                Total yang sudah dibayarkan :
              </p>
              <p className="text-success font-weight-bold">
                Rp{" "}
                {getCurrency(
                  (Number.isInteger(parseInt(form.du_1))
                    ? parseInt(form.du_1)
                    : 0) +
                    (Number.isInteger(parseInt(form.du_2))
                      ? parseInt(form.du_2)
                      : 0) +
                    (Number.isInteger(parseInt(form.du_3))
                      ? parseInt(form.du_3)
                      : 0),
                  0
                )}
              </p> */}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 mb-3">
              <FormInputCurrency
                label='DU 1 (Pertama)'
                name="du_1"
                type="text"
                value={form.du_1}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.du_1}
              />
              {/* <FormInput
                label={`DU 1 <span class="text-success">Rp ${getCurrency(
                  form.du_1,
                  0
                )}</span>`}
                name="du_1"
                type="text"
                value={form.du_1}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.du_1}
              /> */}
            </div>
            <div className="col-lg-4">
              <FormInputCurrency
                label='DU 2 (Kedua)'
                name="du_2"
                type="text"
                value={form.du_2}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.du_2}
              />
              {/* <FormInput
                label={`DU 2 <span class="text-success">Rp ${getCurrency(
                  form.du_2,
                  0
                )}</span>`}
                name="du_2"
                type="text"
                value={form.du_2}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.du_2}
              /> */}
            </div>
            <div className="col-lg-4">
              <FormInputCurrency
                label='DU 3 (Ketiga)'
                name="du_3"
                type="text"
                value={form.du_3}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.du_3}
              />
              {/* <FormInput
                label={`DU 3 <span class="text-success">Rp ${getCurrency(
                  form.du_3,
                  0
                )}</span>`}
                name="du_3"
                type="text"
                value={form.du_3}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.du_3}
              /> */}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 mb-3">
              <div className="bg-gradient-primary text-white rounded-4 p-3 h-100 d-flex justify-content-center align-items-end">
                <div className="text-center w-100">
                  <h6 className="mb-2 text-sm fw-semibold text-warning">Tanggal DU 1 (Pertama)</h6>
                  <FormInput
                    className="border-0"
                    marginBottom="mb-0"
                    name="tanggal_du_1"
                    type="date"
                    value={form.tanggal_du_1}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return { ...prevState, [e.target.name]: e.target.value };
                      })
                    }
                    error={errors.tanggal_du_1}
                  />
                </div>
              </div>
              {/* <FormInput
                label="Tanggal DU 1"
                name="tanggal_du_1"
                type="date"
                value={form.tanggal_du_1}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.tanggal_du_1}
              /> */}
            </div>
            <div className="col-lg-4 mb-3">
              <div className="bg-gradient-primary text-white rounded-4 p-3 h-100 d-flex justify-content-center align-items-end">
                <div className="text-center w-100">
                  <h6 className="mb-2 text-sm fw-semibold text-warning">Tanggal DU 2 (Kedua)</h6>
                  <FormInput
                    className="border-0"
                    marginBottom="mb-0"
                    name="tanggal_du_2"
                    type="date"
                    value={form.tanggal_du_2}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return { ...prevState, [e.target.name]: e.target.value };
                      })
                    }
                    error={errors.tanggal_du_2}
                  />
                </div>
              </div>
              {/* <FormInput
                label="Tanggal DU 2"
                name="tanggal_du_2"
                type="date"
                value={form.tanggal_du_2}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.tanggal_du_2}
              /> */}
            </div>
            <div className="col-lg-4 mb-3">
              <div className="bg-gradient-primary text-white rounded-4 p-3 h-100 d-flex justify-content-center align-items-end">
                <div className="text-center w-100">
                  <h6 className="mb-2 text-sm fw-semibold text-warning">Tanggal DU 3 (Ketiga)</h6>
                  <FormInput
                    className="border-0"
                    marginBottom="mb-0"
                    name="tanggal_du_3"
                    type="date"
                    value={form.tanggal_du_3}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return { ...prevState, [e.target.name]: e.target.value };
                      })
                    }
                    error={errors.tanggal_du_3}
                  />
                </div>
              </div>
              {/* <FormInput
                label="Tanggal DU 3"
                name="tanggal_du_3"
                type="date"
                value={form.tanggal_du_3}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.tanggal_du_3}
              /> */}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 mb-3">
              <FormInputCurrency
                label='Angsuran'
                name="angsuran"
                type="text"
                value={form.angsuran}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.angsuran}
              />
              {/* <FormInput
                label={`Angsuran <span class="text-success">Rp ${getCurrency(
                  form.angsuran,
                  0
                )}</span>`}
                name="angsuran"
                type="text"
                value={form.angsuran}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.angsuran}
              /> */}
            </div>
            <div className="col-lg-6 mb-3">
              <FormInputCurrency
                label='Diskon Angsuran'
                name="diskon_angsuran"
                type="text"
                value={form.diskon_angsuran}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.diskon_angsuran}
              />
              {/* <FormInput
                label={`Diskon Angsuran <span class="text-success">Rp ${getCurrency(
                  form.diskon_angsuran,
                  0
                )}</span>`}
                name="diskon_angsuran"
                type="text"
                value={form.diskon_angsuran}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.diskon_angsuran}
              /> */}
            </div>
          </div>

          <FormTextarea
            label="Catatan"
            name="catatan"
            value={form.catatan}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.catatan}
          />

          <div className="row">
            <NewSubmitButton onClose={handleClose}/>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default PendaftarBiaya;
