import React, { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { getOrigin } from "../../helpers";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import PegawaiEdit from "./Edit";
import PegawaiEditPassword from "./EditPassword";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const PegawaiShow = () => {
  const user = useRecoilValue(userState);
  const setTitle = useSetRecoilState(titleState);

  useEffect(() => setTitle("Profile"), [setTitle]);

  return (
    <>
      {/* <div className="row mb-2">
        <h3 className="col-md text-primary text-center">Informasi</h3>
        <div className="col-md btn-group">
          <PegawaiEdit customClass="btn btn-sm btn-success" user={user} />
          <PegawaiEditPassword
            customClass="btn btn-sm btn-danger"
            user={user}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md d-flex align-items-center justify-content-center">
          <div className="text-center">
            <p className="my-0">
              Sebagai : <span className="text-success">{user.nama}</span> (
              <span className="text-primary">{user.jabatan}</span>)
            </p>
            {user.atasan2 ? (
              <p className="my-0">
                Approval :{" "}
                <span className="text-success">{user.atasan2.nama}</span> (
                <span className="text-primary">{user.atasan2.jabatan}</span>)
              </p>
            ) : (
              ""
            )}
            {user.atasan1 ? (
              <p className="my-0">
                Approval :{" "}
                <span className="text-success">{user.atasan1.nama}</span> (
                <span className="text-primary">{user.atasan1.jabatan}</span>)
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col text-center">
          <p className="m-0 text-warning">
            (Apabila terjadi kesalahan pada informasi segera hubungi IT
            Programmer)
          </p>
        </div>
      </div>
      <hr className="bg-primary" />

      <div className="row">
        <div className="col-md-3">
          <h3>Profile {user.nama}</h3>
          <div className="table-responsive">
            <table className="table table-sm table-bordered table-striped">
              <tbody>
                <tr>
                  <td>NIP</td>
                  <td>{user.nip}</td>
                </tr>
                <tr>
                  <td>Grade</td>
                  <td>{user.grade}</td>
                </tr>
                <tr>
                  <td>Jabatan</td>
                  <td>{user.jabatan}</td>
                </tr>
                <tr>
                  <td>No Hp</td>
                  <td>0{user.no_hp}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{user.email}</td>
                </tr>
                <tr>
                  <td>Ttd</td>
                  <td>
                    <img
                      className="img-fluid w-50"
                      src={getOrigin() + "/storage/pegawai/ttd/" + user.ttd}
                      alt="TTD"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md">
          <h3>KPI</h3>
          <div className="table-responsive">
            <table className="table table-sm table-bordered table-striped">
              <thead>
                <tr>
                  <th className="text-center text-nowrap">Jabatan</th>
                  <th colSpan={8} className="text-center text-nowrap">
                    Data KPI
                  </th>
                </tr>
              </thead>
              <tbody>
                {console.log(user)}
                {user.pegawai_jabatan &&
                  user.pegawai_jabatan.length > 0 &&
                  user.pegawai_jabatan.map((pj, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr>
                          <td
                            rowSpan={
                              pj.jabatan &&
                              pj.jabatan.kpi &&
                              pj.jabatan.kpi.length + 1
                            }
                            className="text-nowrap text-center align-middle"
                          >
                            {pj.jabatan && pj.jabatan.nama}
                          </td>
                          <th
                            className="text-center"
                            style={{ textTransform: "capitalize" }}
                          >
                            no
                          </th>
                          <th
                            className="text-center"
                            style={{ textTransform: "capitalize" }}
                          >
                            nama
                          </th>
                          <th
                            className="text-center"
                            style={{ textTransform: "capitalize" }}
                          >
                            keterangan
                          </th>
                          <th
                            className="text-center"
                            style={{ textTransform: "capitalize" }}
                          >
                            capaian
                          </th>
                          <th
                            className="text-center"
                            style={{ textTransform: "capitalize" }}
                          >
                            target
                          </th>
                          <th
                            className="text-center"
                            style={{ textTransform: "capitalize" }}
                          >
                            satuan
                          </th>
                          <th
                            className="text-center"
                            style={{ textTransform: "capitalize" }}
                          >
                            bobot
                          </th>
                          <th
                            className="text-center"
                            style={{ textTransform: "capitalize" }}
                          >
                            status
                          </th>
                        </tr>
                        {pj.jabatan &&
                          pj.jabatan.kpi &&
                          pj.jabatan.kpi.length > 0 &&
                          pj.jabatan.kpi.map((k, index) => {
                            return (
                              <tr key={index}>
                                <td className="text-center">{index + 1}</td>
                                <td className="text-center">{k.nama}</td>
                                <td className="text-center">{k.keterangan}</td>
                                <td className="text-center">{k.capaian}</td>
                                <td className="text-center">{k.target}</td>
                                <td className="text-center">{k.satuan}</td>
                                <td className="text-center">{k.bobot}</td>
                                <td className="text-center">{k.status || '-'}</td>
                              </tr>
                            );
                          })}
                      </React.Fragment>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}

          <div className="bg-gradient-primary border-bottom-warning text-white border border-silver rounded-top-4 rounded-bottom-3 mb-3">
            <div className="bg-primary-2 rounded-top-3 row shadow-lg py-1 mx-0">
              {(user.atasan1 || user.atasan2) ?
                  <>
                  <div className="col-lg-6 text-center">
                    <p className="mb-0">Atasan I : {user.atasan1 ? user.atasan1.nama : 'Belum ditentukan'}</p>
                  </div>
                  <div className="col-lg-6 text-center">
                    <p className="mb-0">Atasan II : {user.atasan2 ? user.atasan2.nama : 'Belum ditentukan'}</p>
                  </div>
                  </>
              :
                <>
                <div className="col-lg-12 text-center">
                  <p className="mb-0">Bila anda memiliki atasan, nama atasan akan terlihat disini.</p>
                </div>
                </>
              }
            </div>
            <div className="p-3 pb-3 pt-4">
              <div className="d-flex justify-content-center align-items-center mb-3">
                <div className="position-relative d-inline-block bg-black text-white rounded-pill" style={{width: '96px', height: '96px'}}>
                  <i className="position-absolute start-50 top-50 fa-2xl fa fa-fw fa-user" style={{transform: 'translateX(-50%) translateY(-50%)'}}/>
                </div>
              </div>
              <div className="mb-3">
                <h6 className="mb-0 fw-semibold text-center">{user.nama ? user.nama : 'Tidak diketahui'}</h6>
                <p className="text-gray-400 text-center">{user.jabatan ? user.jabatan : 'Jabatan belum ditentukan.'}</p>
                <div className="row justify-content-center">
                  <div className="col-lg-4 w-100 btn-group">
                    <PegawaiEdit customClass="btn btn-sm btn-primary-2" user={user} />
                    <PegawaiEditPassword
                      customClass="btn btn-sm btn-warning-2"
                      user={user}
                    />
                  </div>
                </div>
              </div>
              <p>Informasi detail</p>
              <table className="table table-sm table-bordered table-striped mb-0">
                <tbody>
                  <tr>
                    <td className="border-dark text-gray-400">NIP</td>
                    <td className="border-dark text-white">{user.nip ? user.nip : '-'}</td>
                  </tr>
                  <tr>
                    <td className="border-dark text-gray-400">Nama Panggilan</td>
                    <td className="border-dark text-white">{user.nama_panggilan ? user.nama_panggilan : '-'}</td>
                  </tr>
                  <tr>
                    <td className="border-dark text-gray-400">Grade</td>
                    <td className="border-dark text-white">{user.grade ? user.grade : '-'}</td>
                  </tr>
                  <tr>
                    <td className="border-dark text-gray-400">Jabatan</td>
                    <td className="border-dark text-white">{user.jabatan ? user.jabatan : '-'}</td>
                  </tr>
                  <tr>
                    <td className="border-dark text-gray-400">No Hp</td>
                    <td className="border-dark text-white">0{user.no_hp ? user.no_hp : '-'}</td>
                  </tr>
                  <tr>
                    <td className="border-dark text-gray-400">Email</td>
                    <td className="border-dark text-white">{user.email ? user.email : '-'}</td>
                  </tr>
                  <tr>
                    <td className="border-dark text-gray-400">Tempat Lahir</td>
                    <td className="border-dark text-white">{user.tempat_lahir ? user.tempat_lahir : '-'}</td>
                  </tr>
                  <tr>
                    <td className="border-dark text-gray-400">Tanggal Lahir</td>
                    <td className="border-dark text-white">{user.tanggal_lahir ? user.tanggal_lahir : '-'}</td>
                  </tr>
                  <tr>
                    <td className="border-dark text-gray-400">Alamat</td>
                    <td className="border-dark text-white">{user.alamat ? user.alamat : '-'}</td>
                  </tr>
                  <tr>
                    <td className="border-dark text-gray-400">Ttd</td>
                    <td className="border-dark text-white">
                      <img
                        className="img-fluid w-50"
                        src={getOrigin() + "/storage/pegawai/ttd/" + user.ttd}
                        alt="TTD"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        <div>
          <h5 className="mb-0 fw-semibold">KPI</h5>
          <TableNew copy={false}>
            <Thead>
              <Tr>
                <Th className="text-center text-nowrap">Jabatan</Th>
                <Th colSpan={8} className="text-center text-nowrap">
                  Data KPI
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {console.log(user)}
              {user.pegawai_jabatan &&
                user.pegawai_jabatan.length > 0 &&
                user.pegawai_jabatan.map((pj, index) => {
                  return (
                    <React.Fragment key={index}>
                      <Tr>
                        <Td
                          rowSpan={
                            pj.jabatan &&
                            pj.jabatan.kpi &&
                            pj.jabatan.kpi.length + 1
                          }
                          className="text-nowrap text-center align-middle"
                        >
                          {pj.jabatan && pj.jabatan.nama}
                        </Td>
                        <Th
                          className="text-center"
                          style={{ textTransform: "capitalize" }}
                        >
                          no
                        </Th>
                        <Th
                          className="text-center"
                          style={{ textTransform: "capitalize" }}
                        >
                          nama
                        </Th>
                        <Th
                          className="text-center"
                          style={{ textTransform: "capitalize" }}
                        >
                          keterangan
                        </Th>
                        <Th
                          className="text-center"
                          style={{ textTransform: "capitalize" }}
                        >
                          capaian
                        </Th>
                        <Th
                          className="text-center"
                          style={{ textTransform: "capitalize" }}
                        >
                          target
                        </Th>
                        <Th
                          className="text-center"
                          style={{ textTransform: "capitalize" }}
                        >
                          satuan
                        </Th>
                        <Th
                          className="text-center"
                          style={{ textTransform: "capitalize" }}
                        >
                          bobot
                        </Th>
                        <Th
                          className="text-center"
                          style={{ textTransform: "capitalize" }}
                        >
                          status
                        </Th>
                      </Tr>
                      {pj.jabatan &&
                        pj.jabatan.kpi &&
                        pj.jabatan.kpi.length > 0 &&
                        pj.jabatan.kpi.map((k, index) => {
                          return (
                            <Tr key={index}>
                              <Td className="text-center">{index + 1}</Td>
                              <Td className="text-center">{k.nama}</Td>
                              <Td className="text-center">{k.keterangan}</Td>
                              <Td className="text-center">{k.capaian}</Td>
                              <Td className="text-center">{k.target}</Td>
                              <Td className="text-center">{k.satuan}</Td>
                              <Td className="text-center">{k.bobot}</Td>
                              <Td className="text-center">{k.status || '-'}</Td>
                            </Tr>
                          );
                        })}
                    </React.Fragment>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
    </>
  );
};

export default PegawaiShow;
