import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  fetchKelas,
  fetchPeminjamanRuangan,
  fetchRuangan,
  patchPeminjamanRuangan,
} from "../../api";
import SectionPagination from "../../components/sections/Pagination";
import { formatDate, getHari, saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import { semesterAjaranState, tahunAjaranState } from "../../storage/mhs";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const PeminjamanRuangan = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);

  const [page, setPage] = useState(1);
  const [peminjamanRuangan, setPeminjamanRuangan] = useState({});

  const fetchData = useCallback(async () => {
    const res = await fetchPeminjamanRuangan(page);
    if (res.data) {
      setPeminjamanRuangan(res.data.peminjaman_ruangan);
      setTitle(res.data.title);
    }
  }, [page, setTitle]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [ruangan, setRuangan] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchRuangan("all");
      if (res.data) {
        setRuangan(res.data);
      }
    };

    fetchData();
  }, []);

  const handleApproveUpt = (id) => {
    saConfirm("warning", "Yakin ubah status menjadi Approve UPT").then(
      async (res) => {
        if (res.isConfirmed) {
          const res = await patchPeminjamanRuangan(id, {
            mengetahui: user.nama,
          });
          if (res.data) {
            fetchData();
            saAlert(res.data.status, res.data.msg);
          }
        }
      }
    );
  };

  const handleApproveKacab = (id) => {
    saConfirm("warning", "Yakin ubah status menjadi Approve Kacab").then(
      async (res) => {
        if (res.isConfirmed) {
          const res = await patchPeminjamanRuangan(id, {
            menyetujui: user.nama,
          });
          if (res.data) {
            fetchData();
            saAlert(res.data.status, res.data.msg);
          }
        }
      }
    );
  };

  const handleReject = (id) => {
    saConfirm("warning", "Yakin ubah status menjadi Reject").then(
      async (res) => {
        if (res.isConfirmed) {
          const res = await patchPeminjamanRuangan(id, { rejected: user.nama });
          if (res.data) {
            fetchData();
            saAlert(res.data.status, res.data.msg);
          }
        }
      }
    );
  };

  const getClass = (pr) => {
    if (pr.rejected) {
      return "text-danger";
    } else if (pr.menyetujui) {
      return "text-success";
    } else if (pr.mengetahui) {
      return "text-warning";
    }
    return "";
  };

  const tA = useRecoilValue(tahunAjaranState);
  const sA = useRecoilValue(semesterAjaranState);

  const [tahunAjaran] = useState(tA);
  const [semesterAjaran] = useState(sA);

  const [items, setItems] = useState([]);

  useEffect(() => {
    const fetchDataKelas = async () => {
      try {
        const res = await fetchKelas(
          "all",
          `tahun_ajaran=${tahunAjaran}&semester_ajaran=${semesterAjaran}&table_jamkul=1`
        );
        console.log(res);
        setItems(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDataKelas();
  }, [tahunAjaran, semesterAjaran]);

  return (
    <>
      <h3>Kode Warna :</h3>
      <ol>
        <li className="text-danger">Peminjaman Ruangan Ditolak</li>
        <li className="text-success">
          Peminjaman Ruangan Diterima oleh Kepala Cabang
        </li>
        <li className="text-warning">Peminjaman Ruangan Diterima oleh UPT</li>
        <li>Peminjaman Ruangan Diajukan</li>
      </ol>
      <TableNew>
        <Thead>
          <Tr className="text-center">
            <Th className="text-nowrap">no</Th>
            <Th className="text-nowrap">cabang</Th>
            <Th className="text-nowrap">ruangan</Th>
            <Th className="text-nowrap">mhs</Th>
            <Th className="text-nowrap">pegawai</Th>
            <Th className="text-nowrap">dari</Th>
            <Th className="text-nowrap">sampai</Th>
            <Th className="text-nowrap">no_telp</Th>
            <Th className="text-nowrap">keperluan</Th>
            <Th className="text-nowrap">mengetahui</Th>
            <Th className="text-nowrap">menyetujui</Th>
            <Th className="text-nowrap">rejected</Th>
            <Th className="text-nowrap">
              <span className="fa fa-bars"></span>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {peminjamanRuangan.data &&
            peminjamanRuangan.data.length > 0 &&
            peminjamanRuangan.data.map((pr, index) => {
              return (
                <Tr key={index} className={getClass(pr)}>
                  <Td>{parseInt(peminjamanRuangan.from) + index}</Td>
                  <Td>{pr.cabang}</Td>
                  <Td>
                    {ruangan.length > 0 &&
                      ruangan
                        .filter(
                          (r) => parseInt(r.id) === parseInt(pr.ruangan_id)
                        )
                        .map((r) => r.nama)}
                  </Td>
                  <Td>{pr.mhs ? pr.mhs.nama : "-"}</Td>
                  <Td>{pr.pegawai ? pr.pegawai.nama : "-"}</Td>
                  <Td className="text-nowrap">{formatDate(pr.dari, false)}</Td>
                  <Td className="text-nowrap">
                    {formatDate(pr.sampai, false)}
                  </Td>
                  <Td>{pr.no_telp}</Td>
                  <Td>{pr.keperluan}</Td>
                  <Td className="text-center">
                    {pr.mengetahui ? pr.mengetahui : "-"}
                  </Td>
                  <Td className="text-center">
                    {pr.menyetujui ? pr.menyetujui : "-"}
                  </Td>
                  <Td className="text-center">
                    {pr.rejected ? pr.rejected : "-"}
                  </Td>
                  <Td>
                    <div className="btn-group">
                      {!pr.mengetahui && (
                        <span
                          type="button"
                          onClick={() => handleApproveUpt(pr.id)}
                          className="btn btn-sm btn-warning"
                        >
                          Approve UPT
                        </span>
                      )}
                      {!pr.menyetujui && (
                        <span
                          type="button"
                          onClick={() => handleApproveKacab(pr.id)}
                          className="btn btn-sm btn-success"
                        >
                          Approve Kacab
                        </span>
                      )}
                      {!pr.rejected && (
                        <span
                          type="button"
                          onClick={() => handleReject(pr.id)}
                          className="btn btn-sm btn-danger"
                        >
                          Reject
                        </span>
                      )}
                    </div>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </TableNew>
      <SectionPagination
        links={peminjamanRuangan.links}
        onChangePage={(res) => setPage(res)}
      />

      {items.length >= 1 && (
        <div className="row">
          <div className="col-2">
            <TableNew>
              <Thead>
                <Tr className="text-center">
                  <Th className="text-nowrap">No</Th>
                  <Th className="text-nowrap">Kode</Th>
                </Tr>
              </Thead>
              <Tbody>
                {items.map((i, index) => {
                  return (
                    <Tr key={index}>
                      <Td className="text-center text-nowrap">{index + 1}</Td>
                      <Td className="text-center text-nowrap">{i.kode}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </TableNew>
          </div>
          <div className="col-10">
            <TableNew>
              <Thead>
                <Tr className="text-center">
                  <Th className="text-nowrap text-center">Cabang</Th>
                  <Th className="text-nowrap">Ruangan</Th>
                  <Th className="text-nowrap">Jam Kuliah</Th>
                </Tr>
              </Thead>
              <Tbody>
                {items.map((i, index) => {
                  return (
                    <Tr key={index}>
                      <Td className="text-nowrap text-center">{i.cabang}</Td>
                      <Td className="text-nowrap">
                        {i.ruangan && i.ruangan.nama}
                      </Td>
                      <Td className="text-nowrap">
                        {i.jamkul &&
                          getHari(i.jamkul.hari) +
                            " || " +
                            i.jamkul.dari +
                            "-" +
                            i.jamkul.sampai}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </TableNew>
          </div>
        </div>
      )}
    </>
  );
};

export default PeminjamanRuangan;
