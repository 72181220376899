import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { showKelas } from "../../api";
import PageLoading from "../../components/pages/Loading";
import { getHari, getJurusan, getGrade, getOrigin } from "../../helpers";
import PrintFooter from "../../components/print/Footer";
import PrintBtnPrint from "../../components/print/BtnPrint";
import PrintHeader from "../../components/print/Header";

const KelasAbsensi = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState({});

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await showKelas(id, `generate_nilai=1`);
        setItems(res.data);
        setIsLoaded(true);
        console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [id]);

  const getNilai = (kelas, mhs, jenis) => {
    return parseFloat(
      kelas.krs &&
        kelas.krs.length > 0 &&
        kelas.krs
          .filter((k) => parseInt(k.mhs_id) === parseInt(mhs.id) && k.nilai)
          .map((k) => k.nilai[jenis])
    ).toFixed(2);
  };

  const absensiCount = (mhs) => {
    return (
      items.pengajaran &&
      items.pengajaran.length > 0 &&
      items.pengajaran.filter((p) => {
        return (
          parseInt(p.status) !== 9 &&
          String(p.pertemuan) !== "UTS" &&
          String(p.pertemuan) !== "UAS" &&
          p.absensi.filter(
            (a) =>
              parseInt(a.mhs_id) === parseInt(mhs.id) &&
              parseInt(a.status) === 1
          ).length > 0
        );
      }).length
    );
  };

  const getAngkaAbsensi = (mhs) => {
    return ((absensiCount(mhs) / 12) * 100).toFixed(2);
  };

  const getPersentasiAbsensi = (mhs, persentasi) => {
    let data = ((absensiCount(mhs) / 12) * persentasi).toFixed(2);
    return !isNaN(data) ? data : 0;
  };

  const getPersentasiTugas = (mhs) => {
    let data = (
      (getNilai(items, mhs, "tugas") * parseInt(items.persentasi_tugas)) /
      100
    ).toFixed(2);
    return !isNaN(data) ? data : 0;
  };

  const getPersentasiUts = (mhs) => {
    let data = (
      (getNilai(items, mhs, "uts") * parseInt(items.persentasi_uts)) /
      100
    ).toFixed(2);
    return !isNaN(data) ? data : 0;
  };

  const getPersentasiUas = (mhs) => {
    let data = (
      (getNilai(items, mhs, "uas") * parseInt(items.persentasi_uas)) /
      100
    ).toFixed(2);
    return !isNaN(data) ? data : 0;
  };

  // const getNilaiTotal = (mhs) => {
  //   return (
  //     parseFloat(getPersentasiAbsensi(mhs, items.persentasi_kehadiran ?? 10)) +
  //     parseFloat(getPersentasiTugas(mhs)) +
  //     parseFloat(getPersentasiUts(mhs)) +
  //     parseFloat(getPersentasiUas(mhs))
  //   ).toFixed(2);
  // };

  if (isLoaded) {
    return (
      <>
        <div className="container-fluid">
          <PrintHeader type="landscape" fontSize="11pt"/>
          <h2 className="text-center">DAFTAR NILAI MAHASISWA</h2>
          <div className="row mb-3">
            <div className="col">
              <table>
                <tbody>
                  <tr>
                    <td>Kode Kelas</td>
                    <td>{` : `}</td>
                    <td>{items.kode}</td>
                  </tr>
                  <tr>
                    <td>Hari / Jam (WITA)</td>
                    <td>{` : `}</td>
                    <td>{`${getHari(items.jamkul.hari)} / ${
                      items.jamkul.dari
                    } - ${items.jamkul.sampai}`}</td>
                  </tr>
                  <tr>
                    <td>Kelas/Ruangan</td>
                    <td>{` : `}</td>
                    <td>{items.ruangan.nama}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col justify-content-end d-flex">
              <table>
                <tbody>
                  <tr>
                    <td>Dosen</td>
                    <td>{` : `}</td>
                    <td>{items.dosen && items.dosen.nama}</td>
                  </tr>
                  <tr>
                    <td>Mata Kuliah</td>
                    <td>{` : `}</td>
                    <td>{items.matkul.nama}</td>
                  </tr>
                  <tr>
                    <td>Semester</td>
                    <td>{` : `}</td>
                    <td>{items.semester}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <table className="table table-sm table-bordered">
            <thead className="text-center bg-light">
              <tr className="">
                <th rowSpan="2" className="align-middle">
                  No
                </th>
                <th rowSpan="2" className="align-middle">
                  NIM
                </th>
                <th rowSpan="2" className="align-middle">
                  Nama
                </th>
                <th rowSpan="2" className="align-middle">
                  Jurusan
                </th>
                <th colSpan="3">Kehadiran</th>
                <th colSpan="2">Tugas</th>
                <th colSpan="2">UTS</th>
                <th colSpan="2">UAS</th>
                <th colSpan="2">Nilai</th>
              </tr>
              <tr>
                <th>
                  <span className="px-2">Jumlah</span>
                </th>
                <th>
                  <span className="px-2">Angka</span>
                </th>
                <th>
                  <span className="px-2">{items.persentasi_kehadiran}%</span>
                </th>
                <th>
                  <span className="px-2">Angka</span>
                </th>
                <th>
                  <span className="px-2">{items.persentasi_tugas}%</span>
                </th>
                <th>
                  <span className="px-2">Angka</span>
                </th>
                <th>
                  <span className="px-2">{items.persentasi_uts}%</span>
                </th>
                <th>
                  <span className="px-2">Angka</span>
                </th>
                <th>
                  <span className="px-2">{items.persentasi_uas}%</span>
                </th>
                <th>
                  <span className="px-2">Total</span>
                </th>
                <th>
                  <span className="px-2">Huruf</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {items.mhs &&
                items.mhs.filter((i) => parseInt(i.status) !== 9 ).map((i, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{i.nim}</td>
                      <td>{i.nama}</td>
                      <td>{getJurusan(i.jurusan_id)}</td>
                      <td className="text-center">{absensiCount(i)}</td>
                      <td className="text-center">{getAngkaAbsensi(i)}</td>
                      <td className="text-center">
                        {getPersentasiAbsensi(
                          i,
                          items.persentasi_kehadiran ?? 10
                        )}
                      </td>
                      <td className="text-center">
                        {getNilai(items, i, "tugas")}
                      </td>
                      <td className="text-center">{getPersentasiTugas(i)}</td>
                      <td className="text-center">
                        {getNilai(items, i, "uts")}
                      </td>
                      <td className="text-center">{getPersentasiUts(i)}</td>
                      <td className="text-center">
                        {getNilai(items, i, "uas")}
                      </td>
                      <td className="text-center">{getPersentasiUas(i)}</td>
                      {/* <td className="text-center">{getNilaiTotal(i)}</td> */}
                      <td className="text-center">
                        {getNilai(items, i, "total")}
                      </td>
                      {/* <td className="text-center">
                        {parseFloat(getNilaiTotal(i)) >= 85 && "A"}
                        {parseFloat(getNilaiTotal(i)) < 85 &&
                          parseFloat(getNilaiTotal(i)) >= 75 &&
                          "B"}
                        {parseFloat(getNilaiTotal(i)) < 75 &&
                          parseFloat(getNilaiTotal(i)) >= 65 &&
                          "C"}
                        {parseFloat(getNilaiTotal(i)) < 65 &&
                          parseFloat(getNilaiTotal(i)) >= 45 &&
                          "D"}
                        {parseFloat(getNilaiTotal(i)) < 45 &&
                          parseFloat(getNilaiTotal(i)) >= 0 &&
                          "E"}
                      </td> */}
                      <td className="text-center">
                        {getGrade(parseInt(getNilai(items, i, "grade")))}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <p>
            Catatan: ANGKA KEHADIRAN = Jumlah Kehadiran / Jumlah Pertemuan * 100
          </p>

          <div className="row mb-3">
            <div className="col p-3">
              <table className="table table-sm">
                <thead className="bg-light">
                  <tr>
                    <th colSpan="3">Keterangan</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>85 s/d 100</td>
                    <td>:</td>
                    <td>A (Sangat Memuaskan)</td>
                  </tr>
                  <tr>
                    <td>{`75 s/d 84`}</td>
                    <td>:</td>
                    <td>B (Memuaskan)</td>
                  </tr>
                  <tr>
                    <td>{`65 s/d 74`}</td>
                    <td>:</td>
                    <td>C (Cukup)</td>
                  </tr>
                  <tr>
                    <td>{`45 s/d 64`}</td>
                    <td>:</td>
                    <td>D (Kurang Memuaskan)</td>
                  </tr>
                  <tr>
                    <td>{`0 s/d 44`}</td>
                    <td>:</td>
                    <td>E (Sangat Kurang)</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col p-3">
              <table className="table table-sm">
                <thead className="bg-light">
                  <tr>
                    <th colSpan="3">Bobot Nilai</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nilai Absensi</td>
                    <td>:</td>
                    <td>{items.persentasi_kehadiran}%</td>
                  </tr>
                  <tr>
                    <td>Nilai Tugas</td>
                    <td>:</td>
                    <td>{items.persentasi_tugas}%</td>
                  </tr>
                  <tr>
                    <td>UTS</td>
                    <td>:</td>
                    <td>{items.persentasi_uts}%</td>
                  </tr>
                  <tr>
                    <td>UAS</td>
                    <td>:</td>
                    <td>{items.persentasi_uas}%</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col p-3 text-center">
              <p className="mb-5 pb-5">
                <span style={{ textTransform: "capitalize" }}>
                  {items.cabang}
                </span>
                , <span style={{ marginRight: '11rem' }}></span>
                {/* ...........................................
                {items.tahun_ajaran} */}
              </p>
              <p className="m-0">{items.dosen && items.dosen.nama}</p>
              <p className="m-0">Ttd Dosen</p>
            </div>
          </div>

          <PrintFooter />

          <PrintBtnPrint />
        </div>
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default KelasAbsensi;
