import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import {
  LayoutTable,
  LayoutThead,
  TableNoData,
} from "../../../components/layouts/Table";
import { LayoutAddBtn } from "../../../components/layouts/TopContent";
import SectionPagination from "../../../components/sections/Pagination";
import { saAlert, saConfirm } from "../../../helpers";
import { deleteDepartemen } from "../apis";
import { DepartemenContext } from "../Routes";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../../components/layouts/TableNew";
import SmallButton from "../../../components/forms/SmallButton";

const Departemen = ({ mainUrl, onFetchData }) => {
  const { departemen, setPage } = useContext(DepartemenContext);

  const handleDelete = (id) => {
    saConfirm("warning", "Yakin hapus data ?").then(async (res) => {
      if (res.isConfirmed) {
        const res = await deleteDepartemen(id);
        if (res.data && res.data.msg) {
          onFetchData();
          saAlert("success", res.data.msg);
        }
      }
    });
  };

  return (
    <>
      <LayoutAddBtn add="Tambah Departemen" link={`${mainUrl}/create`} />
      <TableNew>
        <Thead>
          <Tr className="text-center">
            <Th className="text-nowrap">no</Th>
            <Th className="text-nowrap">nama</Th>
            <Th className="text-nowrap">bars</Th>
          </Tr>
        </Thead>
        <Tbody>
          {departemen.data && departemen.data.length > 0 ? (
            departemen.data.map((d, index) => {
              return (
                <Tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td className="text-center">{d.nama}</Td>
                  <Td className="text-center">
                    <SmallButton to={`${mainUrl}/${d.id}/edit`} margin="me-1" type="edit"/>
                    <SmallButton onClick={() => handleDelete(d.id)} type="delete"/>
                  </Td>
                </Tr>
              );
            })
          ) : (
            <TableNoData col={3} />
          )}
        </Tbody>
      </TableNew>
      <SectionPagination
        links={departemen.links}
        from={departemen.from}
        to={departemen.to}
        total={departemen.total}
        onChangePage={(res) => setPage(res)}
      />
    </>
  );
};

export default Departemen;
