import { Link } from "react-router-dom"

const SectionBtnSubmit = props => {
  return <div className="btn-group">
    {props.submit && <button type="submit" className="btn btn-success btn-sm">{props.submit}</button>}
    {props.back && <Link to={props.backLink} className="btn btn-danger btn-sm">{props.back}</Link>}
    {props.dismiss && <button onClick={props.onDismiss} type="button" className="btn btn-secondary btn-sm">{props.dismiss}</button>}
  </div>
}

export default SectionBtnSubmit