import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { LayoutAddBtn } from "../../../components/layouts/TopContent";
import { saAlert, saConfirm } from "../../../helpers";
import { deleteCabang } from "../apis";
import { CabangContext } from "../Routes";

const Cabang = ({ mainUrl, onFetchData }) => {
  const { cabang } = useContext(CabangContext);

  const handleDelete = (id) => {
    saConfirm("warning", "Yakin hapus data ?").then(async (res) => {
      if (res.isConfirmed) {
        const res = await deleteCabang(id);
        if (res.data && res.data.msg) {
          onFetchData();
          saAlert("success", res.data.msg);
        }
      }
    });
  };

  return (
    <>
    <LayoutAddBtn add="Tambah Cabang" link={`${mainUrl}/create`} />

      <div className="row">
        {cabang && cabang.length > 0 ? (
          cabang.map((c, index) => {
            return (
              <div className="col-lg-3 mb-3" key={index}>
                <div className="bg-gradient-primary text-white rounded-3 shadow p-3">
                  <h6 className="mb-1 text-warning fw-semibold">{c.nama}</h6>
                  <p className="text-gray-400 text-sm">Alfa Prima hadir lebih dekat, semangat kami menyebarkan pendidikan yang merata di pulau Dewata.</p>
                  <div className="btn-group w-100">
                  <Link
                    className="btn btn-sm btn-success-2 py-0"
                    to={`${mainUrl}/${c.id}/edit`}
                  >
                    <i className="fa fa-fw fa-sm fa-pen"/> Edit
                  </Link>
                  <button
                    type="button"
                    className="btn btn-sm btn-danger-2 py-0"
                    onClick={() => handleDelete(c.id)}
                  >
                    <i className="fa fa-fw fa-sm fa-trash-alt"/> Hapus
                  </button>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="col-lg-12">
            <div className="rounded-3 py-5 text-center" style={{backgroundColor: 'rgb(240, 240, 240)'}}>
              <i className="fa fa-fw fa-circle-exclamation text-warning"/>
              <p className="mb-0">Tidak ada data cabang.</p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Cabang;
