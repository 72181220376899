import React, { useCallback, useEffect, useState } from "react";
import { deleteJabatan, fetchAspekBsc, fetchDepartemen, fetchDirektorat, fetchDivisi, fetchJabatan, fetchPelaksana } from "../../api";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { titleState } from "../../storage/pages";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import JabatanCreate from "./Create";
import {
  FilterCabang,
  FilterSearch,
  FilterTahun,
} from "../../components/layouts/TopContent";
import JabatanEdit from "./Edit";
import { fetchCabang } from "../../modules/cabang/apis";
import JabatanAddKpi from "./AddKpi";
import PageLoading from "../../components/pages/Loading";
import { tahunAjaranState } from "../../storage/mhs";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import SmallButton from "../../components/forms/SmallButton";
import { saAlert, saConfirm } from "../../helpers";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";
import LoadingBasic from "../../components/pages/LoadingBasic";

const Jabatan = () => {
  const setTitle = useSetRecoilState(titleState);
  // const [isLoaded, setIsLoaded] = useState(false);

  const tA = useRecoilValue(tahunAjaranState);

  const [page] = useState("all");
  const [search, setSearch] = useState("");
  const [cabang, setCabang] = useState("");
  const [tahun, setTahun] = useState(tA);

  const [jabatan, setJabatan] = useState([]);
  const [onHide, setOnHide] = useState(false);

  const fetchData = useCallback(
    async (reset = true) => {
      // if (reset) {
      //   setIsLoaded(false);
      // }
      try {
        const res = await fetchJabatan(page, `search=${search}&tahun=${tahun}&with_trashed=1`);
        setTitle(res.data.title);
        setJabatan(res.data.jabatan);
        // setIsLoaded(true);
      } catch (err) {
        //
      }
    },
    [page, search, tahun, setTitle]
  );

  // DEFAULT
  const [dataCabang, setDataCabang] = useState([]);
  const [aspekBsc, setAspekBsc] = useState([]);
  // NEW CATUR KEMAHAKUASAAN
  const [direktorat, setDirektorat] = useState([]);
  const [divisi, setDivisi] = useState([]);
  const [departemen, setDepartemen] = useState([]);
  const [pelaksana, setPelaksana] = useState([]);

  useEffect(() => {
    const fetchDataCabang = async () => {
      const res = await fetchCabang("all");
      setDataCabang(res.data.cabang);
    };

    const fetchDataAspekBsc = async () => {
      const res = await fetchAspekBsc("all");
      setAspekBsc(res.data.aspek_bsc);
    };

    // ============================

    const fetchDataDirektorat = async () => {
      const res = await fetchDirektorat("all");
      setDirektorat(res.data.direktorat);
    };

    const fetchDataDivisi = async () => {
      const res = await fetchDivisi("all");
      setDivisi(res.data.divisi);
    };

    const fetchDataDepartemen = async () => {
      const res = await fetchDepartemen("all");
      setDepartemen(res.data.departemen);
    };

    const fetchDataPelaksana = async () => {
      const res = await fetchPelaksana("all");
      setPelaksana(res.data.pelaksana);
    };

    fetchData();

    fetchDataCabang();
    fetchDataAspekBsc();

    fetchDataDirektorat();
    fetchDataDivisi();
    fetchDataDepartemen();
    fetchDataPelaksana();
  }, [fetchData]);

  const handleDelete = (id) => {
    saConfirm("warning", "Nonaktifkan jabatan?").then((res) => {
      if (res.isConfirmed) {
        deleteJabatan(id).then((res) => {
          fetchData();
          saAlert(res.data.status, 'Jabatan di nonaktifkan.');
        })
      }
    });
  }

  const handleUndoDelete = (id) => {
    saConfirm("warning", "Aktifkan kembali jabatan?").then((res) => {
      if (res.isConfirmed) {
        deleteJabatan(id).then((res) => {
          fetchData();
          saAlert(res.data.status, 'Jabatan di aktifkan kembali.');
        })
      }
    });
  }

  const copyToClipboard = (kpi) => {
    navigator.clipboard.writeText(JSON.stringify(kpi))
      .then(() => saAlert('success', 'Data telah disalin ke clipboard'))
      .catch((error) => console.error(error));
  };

  return (
    <>
      <JabatanCreate
        dataJabatan={jabatan}
        dataCabang={dataCabang}
        onFetchData={fetchData}
        direktorat={direktorat}
        divisi={divisi}
        departemen={departemen}
        pelaksana={pelaksana}
      />

      <div className="row justify-content-end mb-4">
        <div className="col-md-4">
          <FilterCabang onChange={(res) => setCabang(res)} pusat={1} />
        </div>
        <div className="col-md-4">
          <FilterSearch onChange={(res) => setSearch(res)} />
        </div>
        <div className="col-md-4">
          <FilterTahun value={tahun} onChange={(res) => setTahun(res)} />
        </div>
      </div>

      <TextOnOffMhs>
        <p className="mb-0">Data jabatan berwarna <span className="text-danger-2">merah</span> artinya jabatan dinonaktifkan</p>
      </TextOnOffMhs>

      <SmallButton onClick={() => setOnHide(!onHide)} margin="mb-3 py-1 px-2" type="custom" buttonColor={onHide ? "primary-2" : "danger-2"} icon="fa fa-fw fa-eye" text={`${onHide ? 'Tampilkan' : 'sembunyikan'} nonaktif`}/>

      <TableNew>
        <Thead>
          <Tr className="text-center">
            <Th>No</Th>
            <Th>Grade</Th>
            <Th>Jabatan</Th>
            <Th>Cabang</Th>
            <Th><i className="fa fa-fw fa-cog"></i></Th>
          </Tr>
        </Thead>
        <Tbody>
          {jabatan.length > 0 ?
            jabatan
              .filter((j) => {
                if (cabang !== "") {
                  if (j.cabang && j.cabang.nama.toUpperCase() === cabang.toUpperCase()) {
                    return true;
                  }
                  return false;
                }
                return true;
              })
              .filter((j) => { return onHide ? j.deleted_at === null && true : true })
              .map((j, index) => {
                return (
                  <Tr key={index} className={j.deleted_at ? 'bg-invalid' : ''}>
                    <Td className="text-center align-middle">{index + 1}</Td>
                    <Td className="text-center align-middle">{j.grade}</Td>
                    <Td className="text-center align-middle">{j.nama}</Td>
                    <Td className="text-center align-middle">{j.cabang && j.cabang.nama}</Td>
                    <Td className="text-center align-middle">
                      {/* <SmallButton onClick={() => copyToClipboard(j.kpi)} margin="me-1" type="create" text="Copy Array"/> */}
                      <JabatanEdit
                        jabatan={j}
                        dataJabatan={jabatan}
                        dataCabang={dataCabang}
                        onFetchData={fetchData}
                        direktorat={direktorat}
                        divisi={divisi}
                        departemen={departemen}
                        pelaksana={pelaksana}
                      />
                      <JabatanAddKpi
                        jabatan={j}
                        aspekBsc={aspekBsc}
                        onFetchData={fetchData}
                      />
                      {j.deleted_at ? (
                        <SmallButton onClick={() => handleUndoDelete(j.id)} icon="rotate" buttonColor="success-2" type="custom" text="Aktifkan" />
                      ) : (
                        <SmallButton onClick={() => handleDelete(j.id)} icon="ban" buttonColor="danger-2" type="custom" text="Nonaktifkan" />
                      )}
                    </Td>
                  </Tr>
                );
              })
            :
            <Tr>
              <Td colSpan={5} className='text-center py-5'>Tidak ada data.</Td>
            </Tr>
          }
        </Tbody>
      </TableNew>

      {/* <SectionPagination
            links={jabatan.links}
            onChangePage={(res) => setPage(res)}
          /> */}
    </>
  );
};

export default Jabatan;
