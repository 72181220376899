import React from "react";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { formatDate } from "../../helpers";
import { getStatusMou } from "./Helper";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const PerusahaanTableDetail = ({ perusahaan }) => {
  return (
    <>
      <TableNew>
        <Thead>
          <Tr className="text-center text-nowrap">
            <Th>No</Th>
            <Th>Cabang</Th>
            <Th>Status</Th>
            <Th>Mou dari</Th>
            <Th>Mou sampai</Th>
            <Th>Nomor Mou</Th>
            <Th>Mou ke</Th>
            <Th>Jurusan</Th>
            <Th>Nama</Th>
            <Th>Alamat</Th>
            <Th>No Telepon</Th>
            <Th>No Hp</Th>
            <Th>Email</Th>
            <Th>PIC</Th>
            <Th>Ultah</Th>
            <Th>Kuota</Th>
          </Tr>
        </Thead>
        <Tbody>
          {perusahaan.data.map((p, index) => {
            return (
              <Tr key={index}>
                <Td className="text-center">{perusahaan.from + index}</Td>
                <Td className="text-center">{p.cabang}</Td>
                <Td className="text-center">{getStatusMou(p.status)}</Td>
                <Td className="text-center">{formatDate(p.mou_dari)}</Td>
                <Td className="text-center">{formatDate(p.mou_sampai)}</Td>
                <Td>{p.nomor_mou}</Td>
                <Td>{p.mou_ke}</Td>
                <Td>{p.jurusan}</Td>
                <Td>{p.nama}</Td>
                <Td>{p.alamat}</Td>
                <Td className="text-center">{p.no_telf}</Td>
                <Td className="text-center">{p.no_hp}</Td>
                <Td>{p.email}</Td>
                <Td>{p.pic}</Td>
                <Td>{formatDate(p.ultah)}</Td>
                <Td className="text-center">{p.kuota}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </TableNew>
    </>
  );
};

export default PerusahaanTableDetail;
