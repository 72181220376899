import React from 'react'

function CustomCard({ children }) {
  return (
    <div className="position-relative text-black rounded-3 p-3" style={{backgroundColor: 'rgb(240, 240, 240)', border: '1px solid rgb(220, 220, 220)'}}>
        {children}
    </div>
  )
}

export default CustomCard