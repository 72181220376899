import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { titleState } from '../../storage/pages';
import { LayoutAddBtn } from '../../components/layouts/TopContent';
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../components/layouts/TableNew';
import { deleteArusKas, fetchArusKas, fetchArusKasList, fetchRkat, fetchRkatList } from '../../api';
import ArusKasCreate from './ArusKasCreate';
import SmallButton from '../../components/forms/SmallButton';
import { saAlert, saConfirm } from '../../helpers';
import ArusKasEdit from './ArusKasEdit';
import KasHarian from './KasHarian';
import ArusKasList from './ArusKasList';
import { userState } from '../../storage/auth';

function ArusKas() {

  const [title, setTitle] = useRecoilState(titleState);
  const user = useRecoilValue(userState);
  const [arusKas, setAruskas] = useState([]);
  const [arusKasList, setAruskasList] = useState([]);
  const [rkatList, setRkatList] = useState([]);

  const fetchdata = async () => {
    try {
      const res = await fetchArusKas('all');
      setAruskas(res.data.arus_kas);
    } catch (error) {
      console.log(error);
    }
  }

  const fetchdataRl = async () => {
    try {
      const res = await fetchRkatList("all", `jabatan_id=${user.jabatan_id}`);
      setRkatList(res.data.rkat_lists);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchdata();
    fetchdataRl();
    setTitle('Arus Kas');
  }, []);

  const handleDelete = (id) => {
    saConfirm('warning', 'Yakin hapus arus kas ini?').then( async (res) => {
      if (res.isConfirmed) {
        try {
          const res = await deleteArusKas(id);
          fetchdata();
          saAlert(res.data.status, res.data.msg);
        } catch (error) {
          console.log(error);
        }
      }
    })
  }

  return (
    <>
      <ArusKasCreate fetchdata={fetchdata}/>
      <TableNew>
        <Thead>
          <Tr className='text-center'>
            <Th>No</Th>
            <Th>Cabang</Th>
            <Th>Tanggal</Th>
            <Th><i className='fa fa-fw fa-cog'/></Th>
          </Tr>
        </Thead>
        <Tbody>
          {arusKas.length > 0 && arusKas.map((ak, index) => {
            return (
              <Tr className='text-center' key={index}>
                <Td>{index + 1}</Td>
                <Td>{ak.cabang.nama}</Td>
                <Td>{ak.tanggal}</Td>
                <Td>
                  <ArusKasList fetchdata={fetchdata} arusKas={ak} rkatList={rkatList}/>
                  <KasHarian fetchdata={fetchdata} arusKas={ak}/>
                  <ArusKasEdit fetchdata={fetchdata} arusKas={ak}/>
                  <SmallButton onClick={() => handleDelete(ak.id)} type='delete'/>
                </Td>
              </Tr>
            )
          })}
        </Tbody>
      </TableNew>
    </>
  )
}

export default ArusKas