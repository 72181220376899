import React, { useState, useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { LayoutTable, LayoutThead } from "../../../components/layouts/Table";
import { saAlert } from "../../../helpers";
import { patchCeklistMonitoring } from "../apis";
import { CeklistMonitoringContext } from "../Routes";

const List = ({ mainUrl, onFetchData }) => {
  const { id } = useParams();
  const {
    ceklistMonitoring,
    cabang,
    departemen,
    auditor,
    ceklistMonitoringList,
  } = useContext(CeklistMonitoringContext);

  const [form] = useState(
    ceklistMonitoring.data && ceklistMonitoring.data.length > 0
      ? ceklistMonitoring.data.filter(
          (cm) => parseInt(cm.id) === parseInt(id)
        )[0]
      : {}
  );

  const [ceklistMonitoringListSelected, setCeklistMonitoringListSelected] =
    useState(
      form ? form.ceklist_monitoring_list.map((cml) => parseInt(cml.id)) : []
    );

  const handleSetCMLS = (id) => {
    setCeklistMonitoringListSelected([
      ...ceklistMonitoringListSelected,
      parseInt(id),
    ]);
  };

  const handleRemoveCMLS = (id) => {
    setCeklistMonitoringListSelected(
      ceklistMonitoringListSelected.filter((a) => parseInt(a) !== parseInt(id))
    );
  };

  const history = useHistory();

  const handleSubmit = async () => {
    try {
      const res = await patchCeklistMonitoring(id, {
        ...form,
        ceklist_monitoring_list_row: ceklistMonitoringListSelected,
      });
      if (res.data && res.data.msg) {
        onFetchData();
        history.push(mainUrl);
        saAlert("success", res.data.msg);
      }
    } catch (err) {
      console.error(err.response);
    }
  };

  return (
    <>
      <table className="table table-sm">
        <thead>
          <tr>
            <th>cabang</th>
            <th>departemen</th>
            <th>auditor</th>
            <th>kode_dokumen</th>
            <th>revisi</th>
            <th>tanggal_efektif</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              {cabang.length > 0 &&
                cabang
                  .filter((c) => parseInt(c.id) === parseInt(form.cabang_id))
                  .map((c) => c.nama)}
            </td>
            <td>
              {departemen.length > 0 &&
                departemen
                  .filter(
                    (d) => parseInt(d.id) === parseInt(form.departemen_id)
                  )
                  .map((c) => c.nama)}
            </td>
            <td>
              {auditor.length > 0 &&
                auditor
                  .filter((a) => parseInt(a.id) === parseInt(form.auditor_id))
                  .map((c) => c.nama)}
            </td>
            <td>{form.kode_dokumen}</td>
            <td>{form.revisi}</td>
            <td>{form.tanggal_efektif}</td>
          </tr>
        </tbody>
      </table>

      <div className="mb-2">
        <h3>Ceklist Monitoring List Active</h3>
        <LayoutTable>
          <LayoutThead>
            <th>no</th>
            <th>klausul</th>
            <th>deskripsi</th>
            <th>refrensi_audit</th>
            <th>bars</th>
          </LayoutThead>
          <tbody>
            {ceklistMonitoringList.length > 0 &&
              ceklistMonitoringList
                .filter((cml) =>
                  ceklistMonitoringListSelected.includes(parseInt(cml.id))
                )
                .map((cmls, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{cmls.klausul}</td>
                      <td>{cmls.deskripsi}</td>
                      <td>{cmls.refrensi_audit}</td>
                      <td className="text-center">
                        <span
                          type="button"
                          className="badge badge-danger"
                          onClick={() => handleRemoveCMLS(cmls.id)}
                        >
                          Hapus
                        </span>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </LayoutTable>
      </div>

      <div className="mb-2">
        <h3>Ceklist Monitoring List Nonactive</h3>
        <LayoutTable>
          <LayoutThead>
            <th>no</th>
            <th>klausul</th>
            <th>deskripsi</th>
            <th>refrensi_audit</th>
            <th>bars</th>
          </LayoutThead>
          <tbody>
            {ceklistMonitoringList.length > 0 &&
              ceklistMonitoringList
                .filter(
                  (cml) =>
                    !ceklistMonitoringListSelected.includes(parseInt(cml.id))
                )
                .map((cmls, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{cmls.klausul}</td>
                      <td>{cmls.deskripsi}</td>
                      <td>{cmls.refrensi_audit}</td>
                      <td className="text-center">
                        <span
                          type="button"
                          className="badge badge-primary"
                          onClick={() => handleSetCMLS(cmls.id)}
                        >
                          Pilih
                        </span>
                      </td>
                    </tr>
                  );
                })}
          </tbody>
        </LayoutTable>
      </div>

      <div className="btn-group">
        <button
          type="button"
          className="btn btn-sm btn-success"
          onClick={handleSubmit}
        >
          Simpan
        </button>
        <Link type="button" className="btn btn-sm btn-secondary" to={mainUrl}>
          Tutup
        </Link>
      </div>
    </>
  );
};

export default List;
