import React, { useRef } from "react";
import { useEffect } from "react";
import { saAlert } from "../../helpers";

export const LayoutTable = ({ tableResponsive = true, children, copy = true, striped = true }) => {
  const tableTag = useRef();
  const copyToClipboard = () => {
    const elTable = tableTag.current;

    let range, sel;

    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }

    sel.removeAllRanges();

    saAlert(
      "success",
      "Berhasil copy tabel ke papan clip",
      "Data dapat dipaste ke excel maupun word !"
    );
  };

  return (
    <div className="pt-3">
      <div className="position-relative">
        {copy && (
          <button
            className="position-absolute btn btn-warning-2 btn-sm text-sm rounded-top rounded-bottom-0 px-3 py-0 fw-semibold"
            style={{left: '0px', top: '-17px'}}
            onClick={copyToClipboard}
            title="Copy data ke clipboard."
          >
            <i className="fa fa-fw fa-copy me-1"></i>
            Copy
          </button>
        )}
        <div className={`${tableResponsive && 'table-responsive'} mb-3`}>
          <table
            className={`table table-sm table-bordered ${
              striped && "table-striped"
            }`}
            ref={tableTag}
          >
            {children}
          </table>
        </div>
      </div>
    </div>
  );
};

export const LayoutThead = ({className, children, tr = false }) => {
  useEffect(() => {
    const allTh = document.querySelectorAll("th");

    if (allTh.length > 0) {
      allTh.forEach((element) => {
        if (element.innerHTML === "bars") {
          element.innerHTML = '<span class="fa fa-bars"></span>';
        }
      });
    }
  }, []);

  return (
    <thead className={`${className}`}>
      {tr ? children : <tr className="text-center">{children}</tr>}
    </thead>
  );
};

export const TableNoData = ({ col, isLoaded = true }) => {
  return (
    <tr className="text-center">
      <td colSpan={col} className="py-5 align-middle">
        <h4 className="mb-0 py-5 text-dark">{isLoaded ? "Data tabel kosong." : "Loading . . ."}</h4>
      </td>
    </tr>
  );
};
