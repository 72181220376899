import { useRef, useState } from "react";
import { deleteKrs, postKrs } from "../../api";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterAngkatan,
  FilterJenjang,
  FilterJurusan,
  FilterPagination,
  FilterSearch,
} from "../../components/layouts/TopContent";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { getHari, getJenjang, getJurusan, getOnOffMhs, saAlert, saConfirm } from "../../helpers";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";

const KelasAddMhs = (props) => {
  const [modal, setModal] = useState(false);

  // props Data
  const {
    customClass,
    customIcon,
    customName,
    customTitle,
    mhs,
    kelas,
    angkatan,
    onChangePageMhs,
    onChangePaginationMhs,
    onChangeSearchMhs,
    onChangeAngkatanMhs,
    onChangeJenjangMhs,
    onChangeJurusanMhs,
    onFetchData,
  } = props;

  const handleClose = () => {
    setModal(false);
  };

  const handleAddMhs = (id, semester) => {
    saConfirm("warning", `Yakin tambah mahasiswa ke kelas ${kelas.kode}`).then(
      (res) => {
        if (res.isConfirmed) {
          postKrs({ mhs_id: id, kelas_id: kelas.id, semester })
            .then((res) => {
              if (res.data === "success") {
                onFetchData();
                saAlert("success", "Berhasil ditambahkan ke dalam kelas !");
              }
            })
            .catch((err) => console.log(err));
        }
      }
    );
  };

  const handleRemoveMhs = (krs_id) => {
    saConfirm(
      "warning",
      `Yakin hapus mahasiswa dari kelas ${kelas.kode} ?`
    ).then((res) => {
      if (res.isConfirmed) {
        deleteKrs(krs_id)
          .then((res) => {
            if (res.data === "success") {
              onFetchData();
              saAlert("success", "Berhasil dihapus dari kelas !");
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const formNimTag = useRef();
  const [mhsRow, setMhsRow] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    saConfirm(
      "warning",
      "Yakin sudah memasukkan data dengan format yang benar ?",
      "Kesalahan pada format penulisan akan menyebabkan kegagalan pada saat penginputan"
    ).then((res) => {
      if (res.isConfirmed) {
        postKrs({ kelas_id: kelas.id, mhs_row: mhsRow })
          .then((res) => {
            if (res.data) {
              onFetchData();
              saAlert(
                res.data.mhs.length ? "success" : "warning",
                res.data.mhs.length
                  ? `Berhasil menambahkan ${res.data.mhs.length} mahasiswa kedalam kelas !`
                  : `Tidak ada mahasiswa yang dimasukkan kedalam kelas`
              );
              if (formNimTag && formNimTag.current) {
                formNimTag.current.reset();
              }
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  return (
    <>
      <button
        onClick={() => setModal(true)}
        type="button"
        title={customTitle}
        className={customClass}
      >
        <i className={customIcon}/>
        {customName ?? "Tambah Mhs"}
      </button>
      <LayoutModal
        title={`Data Mahasiswa Kelas ${props.kelas.kode}`}
        modal={modal}
        onClose={handleClose}
      >
        <TextOnOffMhs/>

        <div className="row">
          {/* Mahasiswa Kelas Ini */}
          <div className="col-lg-6">
            {kelas.mhs && kelas.mhs.length >= 1 ? (
              <>
                <div className="bg-gradient-primary border-bottom-warning rounded-3 text-white p-3 mb-3 table-responsive">
                  <table className="table table-sm table-borderless mb-0">
                    <tbody>
                      <tr>
                        <td className="py-0 text-warning">Dosen</td>
                        <td className="py-0 text-warning">:</td>
                        <td className="py-0 text-white">{kelas.dosen && kelas.dosen.nama}</td>
                      </tr>
                      <tr>
                        <td className="py-0 text-warning">Ruangan</td>
                        <td className="py-0 text-warning">:</td>
                        <td className="py-0 text-white">{kelas.ruangan && kelas.ruangan.nama}</td>
                      </tr>
                      <tr>
                        <td className="py-0 text-warning">Waktu</td>
                        <td className="py-0 text-warning">:</td>
                        <td className="py-0 text-white">
                          {kelas.jamkul &&
                          getHari(kelas.jamkul.hari) +
                            " || " +
                            kelas.jamkul.dari +
                            "-" +
                            kelas.jamkul.sampai
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="py-0 text-warning">Mahasiswa</td>
                        <td className="py-0 text-warning">:</td>
                        <td className="py-0 text-white">{kelas.mhs.length} Orang</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <TableNew>
                  <Thead>
                    <Tr className="tetx-center">
                      <Th><i className="fa fa-fw fa-cog"/></Th>
                      <Th>No</Th>
                      <Th>NIM</Th>
                      <Th>Nama</Th>
                      <Th>Jenjang</Th>
                      <Th>Jurusan</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {kelas.mhs.map((i, index) => {
                      return (
                        <Tr key={index} className={getOnOffMhs(i)}>
                          <Td className="text-center">
                            {i.pivot && (
                              <button
                                onClick={() => handleRemoveMhs(i.pivot.id)}
                                type="button"
                                className="btn btn-sm btn-danger-2 text-sm py-0 px-1"
                              >
                                <i className="fa fa-fw fa-sm fa-trash-alt"/>
                              </button>
                            )}
                          </Td>
                          <Td className="text-nowrap text-center">
                            {index + 1}
                          </Td>
                          <Td className="text-nowrap text-center">{i.nim}</Td>
                          <Td className="text-nowrap">{i.nama}</Td>
                          <Td className="text-nowrap">{getJenjang(i)}</Td>
                          <Td className="text-nowrap">
                            {getJurusan(i.jurusan_id)}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </TableNew>
              </>
            ) : (
              <PageNoData />
            )}
          </div>
          {/* Mahasiswa Total */}
          <div className="col-lg-6">
            <form
              onSubmit={handleSubmit}
              className="p-3 border rounded-3 shadow mb-4"
              ref={formNimTag}
            >
              <h4 className="fw-semibold text-dark"> Tambah Berdasarkan NIM</h4>
              <FormTextarea
                row="10"
                // placeholder="Contoh : 2101010001,2101010002,2101010003,2101010004,2101010005 (Baca instruksi dibawah untuk lebih jelas)"
                placeholder="Ikuti intruksi dibawah sebelum memasukan NIM mahasiswa disini. . ."
                onChange={(e) => setMhsRow(e.target.value)}
              />
              <ul class="text-justify text-success">
                <li>NIM diurutkan dalam satu baris dan dipisahkan dengan tanda koma " , "</li>
                <li>Rumus excel sebagai berikut, =TEXTJOIN(",";TRUE;B2:B10) "Sesuaikan B2 dan B10 dengan baris dari NIM yang ada pada data excel. Jika rumus tidak berhasil silahkan update Excel ke versi terbaru (Saya uji coba di excel 2019 berjalan normal)."</li>
                <li>Pastikan tidak ada tanda baca lain selain koma. Tanpa Enter, Spasi, Tab, dll.</li>
                <li>Contoh : 2101010001,2101010002,2101010003</li>
              </ul>
              <button className="btn btn-sm btn-success-2"><i className="fa fa-fw fa-paper-plane"/> Submit NIM</button>
            </form>
            
            <div className="border rounded-3 shadow p-3">
              <h4 className="fw-semibold text-dark">Tambah Berdasarkan Orang</h4>
                {/* Filter 1 */}
                  <div className="row mb-3">
                    {modal && (
                      <div className="col-md">
                        <FilterJurusan onChange={(res) => onChangeJurusanMhs(res)} />
                      </div>
                    )}
                    <div className="col-md">
                      <FilterSearch onChange={(res) => onChangeSearchMhs(res)} />
                    </div>
                  </div>
                  {/* Filter 2 */}
                  <div className="row mb-3">
                    <div className="col-md">
                      <FilterAngkatan
                        value={angkatan}
                        onChange={(res) => onChangeAngkatanMhs(res)}
                      />
                    </div>
                    <div className="col-md">
                      <FilterJenjang onChange={(res) => onChangeJenjangMhs(res)} />
                    </div>
                  </div>
                  {/* Filter 3 */}
                  <div className="row mb-3">
                    <div className="col-md">
                      <FilterPagination
                        onChange={(res) => onChangePaginationMhs(res)}
                      />
                    </div>
                    <div className="col-md"></div>
                  </div>
                  {mhs.data && mhs.data.length >= 1 ? (
                    <>
                      <h6 className="text-primary">Data Semua Mahasiswa</h6>
                      <TableNew>
                        <Thead>
                          <Tr className="text-center">
                            <Th><i className="fa fa-fw fa-cog"/></Th>
                            <Th>No</Th>
                            <Th>NIM</Th>
                            <Th>Nama</Th>
                            <Th>Jenjang</Th>
                            <Th>Jurusan</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {mhs.data.map((i, index) => {
                            return (
                              <Tr key={index}>
                                <Td className="text-center text-nowrap">
                                  {i.kelas.filter((j) => j.id === kelas.id).length >
                                  0 ? (
                                    <span key={index} className="btn btn-sm bg-success-2 text-white text-sm py-0 px-1">
                                      <i className="fa fa-fw fa-sm fa-check"/>
                                    </span>
                                  ) : (
                                    <button
                                      onClick={() => handleAddMhs(i.id, i.semester)}
                                      type="button"
                                      className="btn btn-sm btn-primary-3 text-sm py-0 px-1"
                                    >
                                      <i className="fa fa-fw fa-sm fa-plus"/>
                                    </button>
                                  )}
                                </Td>
                                <Td className="text-nowrap text-center">
                                  {mhs.from + index}
                                </Td>
                                <Td className="text-nowrap text-center">{i.nim}</Td>
                                <Td className="text-nowrap">{i.nama}</Td>
                                <Td className="text-nowrap text-center">{getJenjang(i)}</Td>
                                <Td className="text-nowrap">
                                  {getJurusan(i.jurusan_id)}
                                </Td>
                              </Tr>
                            );
                          })}
                        </Tbody>
                      </TableNew>
                      <SectionPagination
                        links={mhs.links}
                        onChangePage={(res) => onChangePageMhs(res)}
                      />
                    </>
                  ) : (
                    <PageNoData />
                  )}
                </div>
            </div>
        </div>
        <button onClick={handleClose} className="btn btn-sm btn-dark border-0">
          <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
        </button>
      </LayoutModal>
    </>
  );
};

export default KelasAddMhs;
