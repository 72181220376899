import React from "react";
import { fetchingData } from "../../api";

export const FilterTahap = (props) => {
  const { onChange } = props;

  return (
    <div className="bg-light p-1 rounded">
      <label htmlFor="">Tahap</label>
      <select
        className="form-control"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="">Pilih</option>
        <option value="1">1</option>
        <option value="2">2</option>
      </select>
    </div>
  );
};
