import { fetchDataControl, fetchingData } from "../../api";
import axios from "../../axios";

// Departemen
// index
export const fetchDepartemen = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/departemen?page=${page}&${custom}`);
  });
};
// show
export const showDepartemen = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/departemen/${id}?${custom}`);
  });
};
// store
export const postDepartemen = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/departemen`, data);
  });
};
// update
export const patchDepartemen = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/departemen/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteDepartemen = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/departemen/${id}&${custom}`);
  });
};
