import React, { useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { patchPegawi } from "../../api";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";
import { userState } from "../../storage/auth";

const PegawaiEdit = (props) => {
  const { customClass, customBtn, user } = props;
  const [modal, setModal] = useState(false);
  const tagForm = useRef();

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const setUser = useSetRecoilState(userState);

  const handleOpen = () => {
    setForm(user);
    setModal(true);
  };

  const handleClose = () => {
    if (tagForm && tagForm.current) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    patchPegawi(form.id, form, true)
      .then((res) => {
        console.log(res.data);
        if (res.data && res.data.nip) {
          setUser(res.data);
          handleClose();
          saAlert("success", "Berhasil update Profile");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      });
  };

  return (
    <>
      <button className={customClass} onClick={handleOpen}>
        {customBtn ?? "Update Profile"}
      </button>
      <LayoutModal title="Update Profile" modal={modal} onClose={handleClose}>
        <form onSubmit={handleSubmit} ref={tagForm}>
          <div className="row">
            <div className="col-md">
              <FormInput
                label="Nama Panggilan"
                name="nama_panggilan"
                value={form.nama_panggilan}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.nama_panggilan}
              />
            </div>
            <div className="col-md">
              <FormInput
                label="Agama"
                name="agama"
                value={form.agama}
                readOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <FormInput
                label="Tempat Lahir"
                name="tempat_lahir"
                value={form.tempat_lahir}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.tempat_lahir}
              />
            </div>
            <div className="col-md">
              <FormInput
                label="Tanggal Lahir"
                type="date"
                name="tanggal_lahir"
                value={form.tanggal_lahir}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.tanggal_lahir}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <FormInput
                label="Email"
                name="email"
                value={form.email}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.email}
              />
            </div>
            <div className="col-md">
              <FormInput
                label="No HP"
                name="no_hp"
                customFront="+62"
                value={form.no_hp}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.no_hp}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInput
                label="Ttd (Upload file PNG dengan background transparant atau seperti contoh diatas)"
                type="file"
                name="ttd"
                value={form.ttd}
                onChange={(e) => {
                  if (e.target && e.target.files) {
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.files[0],
                      };
                    });
                  }
                }}
                error={errors.ttd}
              />
            </div>
          </div>
          <FormTextarea
            label="Alamat"
            name="alamat"
            value={form.alamat}
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.alamat}
          />
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success">
              Ubah
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={handleClose}
            >
              Tutup
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default PegawaiEdit;
