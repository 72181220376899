import React from "react";
import { useRecoilValue } from "recoil";
import { userState } from "../../storage/auth";

const PrintFooter = () => {
  const user = useRecoilValue(userState);

  return (
    <table className="table table-sm table-bordered">
      <thead className="text-center">
        <tr>
          <th>
            <span>Lembaga Pendidikan Alfa Prima</span>
            {user.cabang === 'pusat' && <span className="mx-3">Jl. Hayam Wuruk 186, Denpasar Tlp:</span>}

            {user.cabang === 'denpasar' && <span className="mx-3">Jl. Hayam Wuruk 186, Denpasar Tlp:</span>}
            {user.cabang === 'singaraja' && <span className="mx-3">Jalan Serma Karma No.88, Singaraja Tlp:</span>}
            {user.cabang === 'karangasem' && <span className="mx-3">Jalan Nenas, Karangasem Tlp:</span>}
            
            {user.cabang === 'pusat' && <span>(0361)232422 Web: www.alfaprima.id</span>}

            {user.cabang === 'denpasar' && <span>(0361)232422 Web: www.alfaprima.id</span>}
            {user.cabang === 'singaraja' && <span>(0362)3435453 Web: www.alfaprima.id</span>}
            {user.cabang === 'karangasem' && <span>(0363)2787499 Web: www.alfaprima.id</span>}
          </th>
        </tr>
      </thead>
    </table>
  );
};

export default PrintFooter;
