import React, { useState, useEffect, createContext, useContext } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { fetchMatkul, findMhs } from "../../../api";
import PrintBtnPrint from "../../../components/print/BtnPrint";
import { getGrade, namaBulan } from "../../../helpers";

const TranskripContext = createContext();

const NilaiPrintTranskripReguler = () => {
  let no = 1;
  const { mhs_id } = useParams();

  const [mhs, setMhs] = useState({});
  const [matkul, setMatkul] = useState({});
  const [nilaiAkhir, setNilaiAkhir] = useState({});
  const [nilaiOjt, setNilaiOjt] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await findMhs(
          mhs_id,
          `with_krs=1&with_nilai_ojt=1&generate_nilai=1`
        );
        if (res.data && res.data.mhs) {
          setMhs(res.data.mhs);
          setNilaiAkhir(res.data.mhs.nilai_akhir);
          setNilaiOjt(res.data.mhs.nilai_ojt);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();

    const fetchDataMatkul = async () => {
      try {
        const res = await fetchMatkul("all", "only_transkrip=1");
        if (res.data && res.data.matkul) {
          setMatkul(res.data.matkul);
        }
      } catch (err) {
        console.error(err.response);
      }
    };

    fetchDataMatkul();
  }, [mhs_id]);

  const countKreditKeahlianUmum = () => {
    return (
      mhs.krs &&
      mhs.krs.length > 0 &&
      mhs.krs
        .filter((k) => k.matkul && parseInt(k.matkul.kelompok_matkul_id) === 2)
        .map((k) => k.matkul && k.matkul.sks)
        .reduce((prev, current) => parseInt(prev) + parseInt(current))
    );
  };

  const countAngkaMutuKeahlianUmum = () => {
    return (
      mhs.krs &&
      mhs.krs.length > 0 &&
      mhs.krs
        .filter((k) => k.matkul && parseInt(k.matkul.kelompok_matkul_id) === 2)
        .map((k) => k.nilai && k.nilai.angka_mutu)
        .reduce((prev, current) => parseInt(prev) + parseInt(current))
    );
  };

  const countKreditKeahlianKhusus = () => {
    return (
      mhs.krs &&
      mhs.krs.length > 0 &&
      mhs.krs
        .filter((k) => k.matkul && parseInt(k.matkul.kelompok_matkul_id) === 6)
        .map((k) => k.matkul && k.matkul.sks)
        .reduce((prev, current) => parseInt(prev || 0) + parseInt(current || 0))
    );
  };

  const countAngkaMutuKeahlianKhusus = () => {
    return (
      mhs.krs &&
      mhs.krs.length > 0 &&
      mhs.krs
        .filter((k) => k.matkul && parseInt(k.matkul.kelompok_matkul_id) === 6)
        .map((k) => k.nilai && k.nilai.angka_mutu)
        .reduce((prev, current) => parseInt(prev || 0) + parseInt(current || 0))
    );
  };

  // D1
  const [angkaMutuOjt, setAngkaMutuOjt] = useState(0);
  const [angkaMutuTugasAkhir1, setAngkaMutuTugasAkhir1] = useState(0);
  const [totalAngkaMutuD1, setTotalAngkaMutuD1] = useState(0);

  const [totalKreditD1, setTotalKreditD1] = useState(0);

  useEffect(() => {
    // ojt 2 = 8
    // tugas akhir = 10
    setTotalKreditD1(
      matkul.length > 0 &&
        matkul
          .filter((m) => parseInt(m.id) === 8 || parseInt(m.id) === 10)
          .map((m) => m.sks)
          .reduce((prev, curr) => parseInt(prev) + parseInt(curr))
    );
  }, [matkul]);

  useEffect(() => {
    setTotalAngkaMutuD1(
      parseInt(angkaMutuOjt || 0) + parseInt(angkaMutuTugasAkhir1 || 0)
    );
  }, [angkaMutuOjt, angkaMutuTugasAkhir1]);

  useEffect(() => {
    let ojt = nilaiOjt.length > 0 && nilaiOjt.map((o) => o.angka_mutu)[0];
    setAngkaMutuOjt(ojt);
  }, [nilaiOjt]);

  useEffect(() => {
    let tugasAkhir = nilaiAkhir.angka_mutu_laporan_tugas_akhir;
    setAngkaMutuTugasAkhir1(tugasAkhir);
  }, [nilaiAkhir]);
  // End D1

  // D2
  const [angkaMutuOjt1, setAngkaMutuOjt1] = useState(0);
  const [angkaMutuOjt2, setAngkaMutuOjt2] = useState(0);
  const [angkaMutuProyekKhusus, setAngkaMutuProyekKhusus] = useState(0);
  const [angkaMutuTugasAkhir2, setAngkaMutuTugasAkhir2] = useState(0);
  const [totalAngkaMutuD2, setTotalAngkaMutuD2] = useState(0);

  const [totalKreditD2, setTotalKreditD2] = useState(0);

  useEffect(() => {
    // ojt 1 = 7
    // ojt 2 = 8
    // proyek khusus = 9
    // tugas akhir = 10
    setTotalKreditD2(
      matkul.length > 0 &&
        matkul
          .filter(
            (m) =>
              parseInt(m.id) === 7 ||
              parseInt(m.id) === 8 ||
              parseInt(m.id) === 9 ||
              parseInt(m.id) === 10
          )
          .map((m) => m.sks)
          .reduce((prev, curr) => parseInt(prev) + parseInt(curr))
    );
  }, [matkul]);

  useEffect(() => {
    setTotalAngkaMutuD2(
      parseInt(angkaMutuOjt1 || 0) +
        parseInt(angkaMutuOjt2 || 0) +
        parseInt(angkaMutuProyekKhusus || 0) +
        parseInt(angkaMutuTugasAkhir2 || 0)
    );
  }, [
    angkaMutuOjt1,
    angkaMutuOjt2,
    angkaMutuProyekKhusus,
    angkaMutuTugasAkhir2,
  ]);

  useEffect(() => {
    let ojt1 =
      nilaiOjt.length > 0 &&
      nilaiOjt
        .filter((o) => parseInt(o.ojt_ke) === 1)
        .map((o) => o.angka_mutu)[0];
    setAngkaMutuOjt1(ojt1 || 0);
  }, [nilaiOjt]);

  useEffect(() => {
    let ojt2 =
      nilaiOjt.length > 0 &&
      nilaiOjt
        .filter((o) => parseInt(o.ojt_ke) === 2)
        .map((o) => o.angka_mutu)[0];
    setAngkaMutuOjt2(ojt2 || 0);
  }, [nilaiOjt]);

  useEffect(() => {
    let proyekKhusus = nilaiAkhir.angka_mutu_proyek_khusus;
    setAngkaMutuProyekKhusus(proyekKhusus || 0);
  }, [nilaiAkhir]);

  useEffect(() => {
    let tugasAkhir = nilaiAkhir.angka_mutu_tugas_akhir;
    setAngkaMutuTugasAkhir2(tugasAkhir || 0);
  }, [nilaiAkhir]);
  // End D2

  const getPredikatKelulusan = (ipk) => {
    if (ipk >= 2.0 && ipk <= 2.5) {
      return "Cukup";
    }
    if (ipk >= 2.51 && ipk <= 3.0) {
      return "Memuaskan";
    }
    if (ipk >= 3.01 && ipk <= 3.5) {
      return "Sangat Memuaskan";
    }
    if (ipk >= 3.51 && ipk <= 4.0) {
      return "Cum Laude";
    }
    return "Tidak Lulus";
  };

  return (
    <TranskripContext.Provider
      value={{
        // D1
        angkaMutuOjt,
        angkaMutuTugasAkhir1,
        totalKreditD1,
        totalAngkaMutuD1,
        // D2
        angkaMutuOjt1,
        angkaMutuOjt2,
        angkaMutuProyekKhusus,
        angkaMutuTugasAkhir2,
        totalKreditD2,
        totalAngkaMutuD2,
      }}
    >
      <Helmet>
        <body className="bg-white" />
        <style type="text/css">
          {`
            @media print{
              @page {
                size: 13.0 in 8.27in;
                size: portrait;
                margin: 1mm 18mm 1mm 18mm;
              }
            }
            * {
              font-family: 'Cambria', sans-serif;
              color: #000000;
            }
            table {
              border: 1px solid blue;
              width: 100%;
            }
            table > thead > tr > th{
              border:1px solid black;
            }
            table > tbody > tr > td{
              border:1px solid black;
            }
            table > tfoot > tr > th{
              border:1px solid black;
            }
            .dont-show {
              padding-top: 5px;
              border-right: 1px solid white;
              border-left: 1px solid white;
            }
          `}
        </style>
      </Helmet>

      <div className="container-fluid">
        <div className="py-5"></div>
        <br />
        <br />
        <br />
        <h2 className="text-center mt-5 pt-5 font-weight-bold mb-3">
          <u>TRANSKRIP NILAI SEMENTARA</u>
        </h2>

        <h5 className="mb-2 d-flex justify-content-center">
          <div className="font-weight-bold w-75">
            <div className="row mb-2">
              <div className="col-4">Nomor</div>
              <div className="col-8">: {nilaiAkhir.no_ijasah}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4">Nama</div>
              <div className="col-8">: {mhs.nama}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4">Nomor Induk</div>
              <div className="col-8">: {mhs.nim}</div>
            </div>
            <div className="row mb-2">
              <div className="col-4">Tempat / Tanggal Lahir</div>
              <div className="col-8">
                : {mhs.tempat},{" "}
                {mhs.tanggal &&
                  mhs.tanggal
                    .split("-")
                    .reverse()
                    .map((t, index) => {
                      if (index === 1) {
                        return ` ${namaBulan[t - 1]} `;
                      } else {
                        return t;
                      }
                    })}
              </div>
            </div>
          </div>
        </h5>

        <h5 className="font-weight-bold mb-0 pb-0 text-justify">
          Telah dinyatakan Lulus Program{" "}
          {mhs.jenjang && mhs.jenjang.split("D").join("")} Tahun <br />
          Jurusan {mhs.jurusan_ && mhs.jurusan_.nama} pada Tanggal{" "}
          {nilaiAkhir.tanggal_tugas_akhir
            ? nilaiAkhir.tanggal_tugas_akhir
              ? nilaiAkhir.tanggal_tugas_akhir
                  .split("-")
                  .reverse()
                  .map((t, index) => {
                    if (index === 1) {
                      return ` ${namaBulan[t - 1]} `;
                    } else {
                      return t;
                    }
                  })
              : "-"
            : nilaiAkhir.tanggal_laporan_tugas_akhir
            ? nilaiAkhir.tanggal_laporan_tugas_akhir
                .split("-")
                .reverse()
                .map((t, index) => {
                  if (index === 1) {
                    return ` ${namaBulan[t - 1]} `;
                  } else {
                    return t;
                  }
                })
            : "-"}
        </h5>

        <table className="my-2">
          <thead className="text-center" style={{ textTransform: "uppercase" }}>
            <tr>
              <th className="align-middle" rowSpan={2}>
                No
              </th>
              <th className="align-middle" rowSpan={2}>
                Kode
              </th>
              <th className="align-middle" rowSpan={2}>
                Nama Mata Kuliah
              </th>
              <th className="align-middle" rowSpan={2}>
                Kredit
              </th>
              <th className="align-middle" colSpan={2}>
                Nilai
              </th>
            </tr>
            <tr>
              <th className="align-middle">Huruf Mutu</th>
              <th className="align-middle">Angka Mutu</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={6} className="dont-show"></td>
            </tr>

            {/* keahlian umum */}

            <tr>
              <td colSpan={6} style={{ textTransform: "uppercase" }}>
                Mata Kuliah Keahlian Umum
              </td>
            </tr>
            {mhs.krs &&
              mhs.krs.length > 0 &&
              mhs.krs
                .filter(
                  (k) => k.matkul && parseInt(k.matkul.kelompok_matkul_id) === 2
                )
                .map((k, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{no++}</td>
                      <td className="text-center">
                        {k.matkul && k.matkul.kode}
                      </td>
                      <td>{k.matkul && k.matkul.nama}</td>
                      <td className="text-center">
                        {k.matkul && k.matkul.sks}
                      </td>
                      <td className="text-center">
                        {k.nilai && getGrade(k.nilai.grade)}
                      </td>
                      <td className="text-center">
                        {k.nilai && k.nilai.angka_mutu ? k.nilai.angka_mutu : 0}
                      </td>
                    </tr>
                  );
                })}
            <tr>
              <td colSpan={3} style={{ textTransform: "uppercase" }}>
                Sub Jumlah
              </td>
              <td className="text-center">{countKreditKeahlianUmum()}</td>
              <td></td>
              <td className="text-center">{countAngkaMutuKeahlianUmum()}</td>
            </tr>

            {/* End keahlian umum */}

            <tr>
              <td colSpan={6} className="dont-show"></td>
            </tr>

            {/* Mata kuliah keahlian khusus */}

            <tr>
              <td colSpan={6} style={{ textTransform: "uppercase" }}>
                Mata Kuliah Keahlian Khusus
              </td>
            </tr>
            {mhs.krs &&
              mhs.krs.length > 0 &&
              mhs.krs
                .filter(
                  (k) => k.matkul && parseInt(k.matkul.kelompok_matkul_id) === 6
                )
                .map((k, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{no++}</td>
                      <td className="text-center">
                        {k.matkul && k.matkul.kode}
                      </td>
                      <td>{k.matkul && k.matkul.nama}</td>
                      <td className="text-center">
                        {k.matkul && k.matkul.sks}
                      </td>
                      <td className="text-center">
                        {k.nilai && getGrade(k.nilai.grade)}
                      </td>
                      <td className="text-center">
                        {k.nilai && k.nilai.angka_mutu ? k.nilai.angka_mutu : 0}
                      </td>
                    </tr>
                  );
                })}
            <tr>
              <td colSpan={3} style={{ textTransform: "uppercase" }}>
                Sub Jumlah
              </td>
              <td className="text-center">{countKreditKeahlianKhusus()}</td>
              <td></td>
              <td className="text-center">{countAngkaMutuKeahlianKhusus()}</td>
            </tr>

            {/* End Mata kuliah keahlian khusus */}

            <tr>
              <td colSpan={6} className="dont-show"></td>
            </tr>

            {matkul && matkul.length > 0 && (
              <>
                {/* Materi Akhir */}
                {mhs && matkul && nilaiAkhir && nilaiOjt && (
                  <>
                    {mhs.jenjang === "D1" && (
                      <MateriAkhirD1
                        mhs={mhs}
                        matkul={matkul}
                        nilaiAkhir={nilaiAkhir}
                        nilaiOjt={nilaiOjt}
                        no={no}
                      />
                    )}

                    {mhs.jenjang === "D2" && (
                      <MateriAkhirD2
                        mhs={mhs}
                        matkul={matkul}
                        nilaiAkhir={nilaiAkhir}
                        nilaiOjt={nilaiOjt}
                        no={no}
                      />
                    )}
                  </>
                )}

                {/* Materi Akhir */}
              </>
            )}

            <tr>
              <td colSpan={6} className="dont-show"></td>
            </tr>
          </tbody>

          <tfoot>
            {mhs.jenjang === "D1" && (
              <>
                <tr>
                  <th colSpan={3} style={{ textTransform: "uppercase" }}>
                    Jumlah Total
                  </th>
                  <th className="text-center">
                    {parseInt(countKreditKeahlianUmum()) +
                      parseInt(countKreditKeahlianKhusus()) +
                      (totalKreditD1 ? parseInt(totalKreditD1) : 0)}
                  </th>
                  <th></th>
                  <th className="text-center">
                    {parseInt(countAngkaMutuKeahlianUmum()) +
                      parseInt(countAngkaMutuKeahlianKhusus()) +
                      (totalAngkaMutuD1 ? parseInt(totalAngkaMutuD1) : 0)}
                  </th>
                </tr>
                <tr>
                  <th colSpan={3} style={{ textTransform: "uppercase" }}>
                    Indeks Prestasi Kumulatif
                  </th>
                  <th colSpan={3} className="text-center">
                    {(
                      Number(
                        parseInt(countAngkaMutuKeahlianUmum()) +
                          parseInt(countAngkaMutuKeahlianKhusus()) +
                          (totalAngkaMutuD1 ? parseInt(totalAngkaMutuD1) : 0)
                      ) /
                      (parseInt(countKreditKeahlianUmum()) +
                        parseInt(countKreditKeahlianKhusus()) +
                        (totalKreditD1 ? parseInt(totalKreditD1) : 0))
                    ).toFixed(2)}
                  </th>
                </tr>
                <tr>
                  <th colSpan={3} style={{ textTransform: "uppercase" }}>
                    Predikat Kelulusan
                  </th>
                  <th
                    colSpan={3}
                    style={{ textTransform: "uppercase" }}
                    className="text-center font-weight-bold"
                  >
                    {getPredikatKelulusan(
                      parseFloat(
                        Number(
                          parseInt(countAngkaMutuKeahlianUmum()) +
                            parseInt(countAngkaMutuKeahlianKhusus()) +
                            (totalAngkaMutuD1 ? parseInt(totalAngkaMutuD1) : 0)
                        ) /
                          (parseInt(countKreditKeahlianUmum()) +
                            parseInt(countKreditKeahlianKhusus()) +
                            (totalKreditD1 ? parseInt(totalKreditD1) : 0))
                      ).toFixed(2)
                    )}
                  </th>
                </tr>
              </>
            )}

            {mhs.jenjang === "D2" && (
              <>
                <tr>
                  <th colSpan={3} style={{ textTransform: "uppercase" }}>
                    Jumlah Total
                  </th>
                  <th className="text-center">
                    {parseInt(countKreditKeahlianUmum()) +
                      parseInt(countKreditKeahlianKhusus()) +
                      (totalKreditD2 ? parseInt(totalKreditD2) : 0)}
                  </th>
                  <th></th>
                  <th className="text-center">
                    {parseInt(countAngkaMutuKeahlianUmum()) +
                      parseInt(countAngkaMutuKeahlianKhusus()) +
                      (totalAngkaMutuD2 ? parseInt(totalAngkaMutuD2) : 0)}
                  </th>
                </tr>
                <tr>
                  <th colSpan={3} style={{ textTransform: "uppercase" }}>
                    Indeks Prestasi Kumulatif
                  </th>
                  <th colSpan={3} className="text-center">
                    {parseFloat(
                      Number(
                        parseInt(countAngkaMutuKeahlianUmum()) +
                          parseInt(countAngkaMutuKeahlianKhusus()) +
                          (totalAngkaMutuD2 ? parseInt(totalAngkaMutuD2) : 0)
                      ) /
                        (parseInt(countKreditKeahlianUmum()) +
                          parseInt(countKreditKeahlianKhusus()) +
                          (totalKreditD2 ? parseInt(totalKreditD2) : 0))
                    ).toFixed(2)}
                  </th>
                </tr>
                <tr>
                  <th colSpan={3} style={{ textTransform: "uppercase" }}>
                    Predikat Kelulusan
                  </th>
                  <th
                    colSpan={3}
                    style={{ textTransform: "uppercase" }}
                    className="text-center font-weight-bold"
                  >
                    {getPredikatKelulusan(
                      parseFloat(
                        Number(
                          parseInt(countAngkaMutuKeahlianUmum()) +
                            parseInt(countAngkaMutuKeahlianKhusus()) +
                            (totalAngkaMutuD2 ? parseInt(totalAngkaMutuD2) : 0)
                        ) /
                          (parseInt(countKreditKeahlianUmum()) +
                            parseInt(countKreditKeahlianKhusus()) +
                            (totalKreditD2 ? parseInt(totalKreditD2) : 0))
                      ).toFixed(2)
                    )}
                  </th>
                </tr>
              </>
            )}
          </tfoot>
        </table>
        <p className="font-weight-bold mb-0 pb-2 text-justify">
          Judul Tugas Akhir :{" "}
          {nilaiAkhir.judul_tugas_akhir
            ? nilaiAkhir.judul_tugas_akhir
            : nilaiAkhir.judul_laporan_tugas_akhir}
        </p>

        <b>Keterangan : </b>
        <div className="row mb-5 pb-3">
          <div className="col-6">
            <p className="p-0 m-0">IPK : 2.00 - 2.50 : Cukup</p>
            <p className="p-0 m-0">IPK : 2.51 - 3.00 : Memuaskan</p>
          </div>
          <div className="col-6">
            <p className="p-0 m-0">IPK : 3.01 - 3.50 : Sangat Memuaskan</p>
            <p className="p-0 m-0">IPK : 3.51 - 4.00 : Cum Laude</p>
          </div>
        </div>

        <div className="row d-flex justify-content-end">
          <div className="col-6 font-weight-bold text-center">
            <p className="m-0 p-0">
              <span style={{ textTransform: "capitalize" }}>{mhs.cabang}</span>,
              26 Oktober {new Date().getFullYear()}
            </p>
            <p className="m-0 p-0">
              Lembaga Pendidikan Manajemen
              <br />
              dan Informatika Alfa Prima
            </p>
            <br />
            <br />
            <br />
            <p className="m-0 p-0">
              <u>Putu Trisna Hady Permana S., S.Pd., M.Kom</u>
            </p>
            <p className="m-0 p-0 font-weight-normal">
              Koordinator Direktur
            </p>
          </div>
        </div>
      </div>

      <PrintBtnPrint />
    </TranskripContext.Provider>
  );
};

const MateriAkhirD1 = ({
  mhs = false,
  matkul = false,
  nilaiAkhir = false,
  nilaiOjt = false,
  no,
}) => {
  const {
    angkaMutuOjt,
    angkaMutuTugasAkhir1,
    totalKreditD1,
    totalAngkaMutuD1,
  } = useContext(TranskripContext);

  if (mhs && matkul && nilaiAkhir && nilaiOjt) {
    return (
      <>
        {mhs.jenjang === "D1" && (
          <>
            <tr>
              <td colSpan={6} style={{ textTransform: "uppercase" }}>
                Materi Akhir
              </td>
            </tr>
            {matkul
              // Ojt 2
              .filter((m) => parseInt(m.id) === 8)
              .map((m, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{no++}</td>
                    <td className="text-center">{m.kode}</td>
                    <td>On The Job Training (OJT)</td>
                    <td className="text-center">{m.sks}</td>
                    <td className="text-center">
                      {nilaiOjt.length > 0
                        ? nilaiOjt.map((o) => getGrade(parseInt(o.grade)))
                        : getGrade(0)}
                    </td>
                    <td className="text-center">{angkaMutuOjt}</td>
                  </tr>
                );
              })}
            {matkul
              // Tugas Akhir
              .filter((m) => parseInt(m.id) === 10)
              .map((m, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{no++}</td>
                    <td className="text-center">{m.kode}</td>
                    <td>{m.nama}</td>
                    <td className="text-center">{m.sks}</td>
                    <td className="text-center">
                      {getGrade(parseInt(nilaiAkhir.grade_laporan_tugas_akhir))}
                    </td>
                    <td className="text-center">{angkaMutuTugasAkhir1}</td>
                  </tr>
                );
              })}
            <tr>
              <td colSpan={3} style={{ textTransform: "uppercase" }}>
                Sub Jumlah
              </td>
              <td className="text-center">{totalKreditD1}</td>
              <td></td>
              <td className="text-center">{totalAngkaMutuD1}</td>
            </tr>
          </>
        )}
      </>
    );
  } else {
    return "";
  }
};

const MateriAkhirD2 = ({
  mhs = false,
  matkul = false,
  nilaiAkhir = false,
  nilaiOjt = false,
  no,
}) => {
  const {
    angkaMutuOjt1,
    angkaMutuOjt2,
    angkaMutuProyekKhusus,
    angkaMutuTugasAkhir2,
    totalKreditD2,
    totalAngkaMutuD2,
  } = useContext(TranskripContext);

  if (mhs && matkul && nilaiAkhir && nilaiOjt) {
    return (
      <>
        {mhs.jenjang === "D2" && (
          <>
            <tr>
              <td colSpan={6} style={{ textTransform: "uppercase" }}>
                Materi Akhir
              </td>
            </tr>
            {matkul
              // Ojt 1
              .filter((m) => parseInt(m.id) === 7)
              .map((m, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{no++}</td>
                    <td className="text-center">{m.kode}</td>
                    <td>{m.nama}</td>
                    <td className="text-center">{m.sks}</td>
                    <td className="text-center">
                      {nilaiOjt.length > 0
                        ? nilaiOjt
                            .filter((o) => parseInt(o.ojt_ke) === 1)
                            .map((o) => getGrade(parseInt(o.grade)))
                        : getGrade(0)}
                    </td>
                    <td className="text-center">{angkaMutuOjt1}</td>
                  </tr>
                );
              })}
            {matkul
              // Ojt 2
              .filter((m) => parseInt(m.id) === 8)
              .map((m, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{no++}</td>
                    <td className="text-center">{m.kode}</td>
                    <td>{m.nama}</td>
                    <td className="text-center">{m.sks}</td>
                    <td className="text-center">
                      {nilaiOjt.length > 0
                        ? nilaiOjt
                            .filter((o) => parseInt(o.ojt_ke) === 2)
                            .map((o) => getGrade(parseInt(o.grade)))
                        : getGrade(0)}
                    </td>
                    <td className="text-center">{angkaMutuOjt2}</td>
                  </tr>
                );
              })}
            {matkul
              // Proyek Khusus
              .filter((m) => parseInt(m.id) === 9)
              .map((m, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{no++}</td>
                    <td className="text-center">{m.kode}</td>
                    <td>{m.nama}</td>
                    <td className="text-center">{m.sks}</td>
                    <td className="text-center">
                      {getGrade(parseInt(nilaiAkhir.grade_proyek_khusus))}
                    </td>
                    <td className="text-center">{angkaMutuProyekKhusus}</td>
                  </tr>
                );
              })}
            {matkul
              // Tugas Akhir
              .filter((m) => parseInt(m.id) === 10)
              .map((m, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{no++}</td>
                    <td className="text-center">{m.kode}</td>
                    <td>{m.nama}</td>
                    <td className="text-center">{m.sks}</td>
                    <td className="text-center">
                      {getGrade(parseInt(nilaiAkhir.grade_tugas_akhir))}
                    </td>
                    <td className="text-center">{angkaMutuTugasAkhir2}</td>
                  </tr>
                );
              })}
            <tr>
              <td colSpan={3} style={{ textTransform: "uppercase" }}>
                Sub Jumlah
              </td>
              <td className="text-center">{totalKreditD2}</td>
              <td></td>
              <td className="text-center">{totalAngkaMutuD2}</td>
            </tr>
          </>
        )}
      </>
    );
  } else {
    return "";
  }
};

export default NilaiPrintTranskripReguler;
