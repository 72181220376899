import React, { useEffect, useState, useCallback } from "react";
import {
  patchPengajaran,
  postPengajaran,
  deleteAbsensi,
  postAbsensi,
  showPengajaran,
} from "../../api";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import { getOrigin, saAlert, saConfirm } from "../../helpers";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { Absen } from "./Absensi";
import LoadingBasic from "../../components/pages/LoadingBasic";

const PengajaranEditPengajaranDosen = ({
  pengajaran,
  onFetchData,
  onSetPengajaranToEdit,
}) => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      const res = await showPengajaran(pengajaran.id);
      setForm(res.data);
      setIsLoaded(true);
      onFetchData();
    } catch (err) {
      console.log(err);
    }
  }, [pengajaran.id, onFetchData]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleCancle = () => {
    onSetPengajaranToEdit({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors({});

    // update Bap
    const upBap = async () => {
      try {
        const res = await patchPengajaran(form.id, {
          ...form,
          update_bap: true,
        });
        if (res.data === "success") {
          if (form.bukti_pengajaran === pengajaran.bukti_pengajaran) {
            setErrors({});
            onFetchData();
            saAlert("success", "Berhasil update Bap.");
          }
        }
      } catch (err) {
        err.response &&
          err.response.status === 422 &&
          setErrors((prevState) => {
            return { ...prevState, ...err.response.data.errors };
          });
      }
    };

    const data = new FormData();
    data.append("bukti_pengajaran", form.bukti_pengajaran);

    // up bukti
    const upImage = async () => {
      try {
        const res = await postPengajaran(data, `pengajaran_id=${form.id}`);
        if (res.data === "success") {
          onFetchData();
          setForm({ bukti_pengajaran: false });
          saAlert("success", "Berhasil upload Bukti Pengajaran.");
        }
      } catch (err) {
        console.log(err);
      }
    };

    if (form.bukti_pengajaran !== pengajaran.bukti_pengajaran) {
      upImage();
    }
    upBap();
  };

  if (isLoaded) {
    return (
      <>
        <AbsensiMahasiswa pengajaran={form} onFetchData={fetchData} />
        <form className="mb-3" onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-12">
              <FormTextarea
                label="Pembahasan"
                name="pembahasan"
                value={form.pembahasan}
                onChange={handleChange}
                error={errors.pembahasan}
              />
            </div>
            <div className="col-lg-6">
              <FormTextarea
                label="Tugas"
                name="tugas"
                value={form.tugas}
                onChange={handleChange}
                error={errors.tugas}
              />
            </div>
            <div className="col-lg-6">
              <FormTextarea
                label="Hambatan"
                name="hambatan"
                value={form.hambatan}
                onChange={handleChange}
                error={errors.hambatan}
              />
            </div>
              {/* {form.bukti_pengajaran ? (
                <>
                  <img
                    className="img-fluid"
                    src={`${getOrigin()}/storage/bukti_pengajaran/${
                      form.bukti_pengajaran
                    }`}
                    alt="Gambar Bukti Pengajaran"
                  />
                  <p>Gambar Bukti Pengajaran {form.bukti_pengajaran}</p>
                </>
              ) : (
                <FormInput
                  type="file"
                  label="Bukti Pengajaran"
                  name="bukti_pengajaran"
                  value={form.bukti_pengajaran}
                  onChange={handleChange}
                  error={errors.bukti_pengajaran}
                />
              )} */}


              {form.bukti_pengajaran ? (
                <div className="col-lg-6">
                  <div className="border bg-light rounded-3 mb-3">
                    <img
                      className="ratio ratio-16x9 rounded-top-3"
                      src={`${getOrigin()}/storage/bukti_pengajaran/${
                        form.bukti_pengajaran
                      }`}
                      alt="Gambar Bukti Pengajaran"
                    />
                    <div className="p-2">
                      <h6 className="mb-0 text-dark">Gambar Bukti Pengajaran</h6>
                      <p className="mb-2">{form.bukti_pengajaran}</p>
              
                    </div>
                  </div>
                </div>
              ) : (
                <>
                <div className='col-lg-6'>
                  <label htmlFor="cariBook" className="fw-semibold text-primary">Bukti Pengajaran <span className="text-secondary text-sm">* .jpg / .png , max 10mb</span></label>
                    <div className="rounded-3 custom-file-button p-1" 
                      onClick={() => document.querySelector(".input-foto").click()}>
                      <div className="box-image-2 py-5 rounded-3">
                        <div className="text-center py-4">
                          <i className="fa fa-fw fa-image fs-1" />
                          <p className="my-0 text-sm mt-2">Klik disini untuk upload foto.</p>
                        </div>
                      </div>
                    </div>

                    <FormInput
                      className="input-foto"
                      hidden={true}
                      name="bukti_pengajaran"
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleChange}
                      error={errors.foto}
                    />
                </div>
                </>
              )}
          </div>
          <div>
            <button className="btn btn-sm btn-primary-3 rounded-3 border-0" type="submit">
              Simpan BAP
            </button>
            <button
              className="btn btn-sm btn-dark rounded-3 border-0 ms-1"
              type="button"
              onClick={handleCancle}
            >
              Tutup BAP
            </button>
          </div>
        </form>
      </>
    );
  } else {
    return <LoadingBasic/>
  }
};

const AbsensiMahasiswa = ({ pengajaran, onFetchData }) => {
  const [items, setItems] = useState({});

  useEffect(() => {
    setItems(pengajaran);
  }, [pengajaran]);

  // Handle Absen
  const handleUpdate = (mhs, pengajaran_id, pertemuan, status) => {
    postAbsensi({ mhs_id: mhs.id, pengajaran_id, pertemuan, status })
      .then((res) => {
        if (res.data === "success") {
          onFetchData();
          // saAlert("success", "Berhasil melakukan absen !");
        }
      })
      .catch((err) => console.log(err));
  };

  // Delete Absen
  const handleCancleDelete = (a) => {
    saConfirm("warning", "Yakin hapus absensi ?").then((res) => {
      if (res.isConfirmed) {
        deleteAbsensi(a.id).then((res) => {
          if (res.data === "success") {
            onFetchData();
            saAlert("success", "Berhasil hapus absensi !");
          }
        });
      }
    });
  };

  // Handle Absen Row
  const handleAbsenSemua = () => {
    saConfirm("success", "Yakin ubah status menjadi hadir semua ?").then(
      (res) => {
        if (res.isConfirmed) {
          const id_row = items.mhs
            .filter((i) => i.status !== 9)
            .map((i) => i.id);

          const data = {
            id_row,
            pengajaran_id: pengajaran.id,
            pertemuan: items.pertemuan,
          };

          console.log(data);

          postAbsensi(data)
            .then((res) => {
              if (res.data === "success") {
                onFetchData();
                saAlert("success", "Berhasil melakukan absen !");
              }
            })
            .catch((err) => console.log(err));
        }
      }
    );
  };

  return (
    <>
      <div className="row mx-0">
        <div className="col-lg-6 px-2 mb-3">
          <div className="h-100 border p-4 bg-light shadow rounded-3">
            <p className="mb-0 text-primary fw-semibold">Kode Kelas :</p>
            <h2 className="fw-semibold text-dark">{pengajaran && pengajaran.kelas && pengajaran.kelas.kode}</h2>
            <div className="d-flex align-items-center">
              <h6 className="mb-0 text-dark fw-semibold">Ubah Data :</h6>
              <p className="ms-2 mb-0 fs-6 bg-primary fw-semibold text-warning rounded-3 px-3 py-0">Pertemuan {pengajaran.pertemuan}</p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 px-2 mb-3">
          <div className="h-100 border p-4 bg-light shadow rounded-3">
            <p className="mb-2">Klik tombol dibawah untuk mengubah semua status menjadi hadir.</p>
            <button className="btn btn-sm btn-success-2 rounded-3 px-4" onClick={handleAbsenSemua}>
              <i className="fa fa-fw fa-circle-check"/> Hadir Semua
            </button>
          </div>
        </div>
      </div>
          <p className="text-center"><i className="fa fa-fw fa-circle-exclamation"/> Mahasiswa dengan table berwarna merah = <span className="text-danger">tidak aktif</span></p>
      <LayoutTable>
        <LayoutThead>
          <th className="py-1 border-bottom border-silver fw-semibold">No</th>
          <th className="py-1 border-bottom border-silver fw-semibold">NIM</th>
          <th className="py-1 border-bottom border-silver fw-semibold">Nama</th>
          <th className="py-1 border-bottom border-silver fw-semibold">Status</th>
        </LayoutThead>
        <tbody>
          {items.mhs &&
            items.mhs.length > 0 &&
            items.mhs.map((i, index) => {
              return (
                <tr
                  key={index}
                  className={
                    parseInt(i.status) === 9 ? "invalid-color" : ""
                  }
                  // style={{
                  //   backgroundColor: parseInt(i.status) === 9 ? 'rgba(200, 0, 0, 0.5)' : '',
                  //   color: parseInt(i.status) === 9 ? 'white' : ''
                  // }}
                >
                  <td className="py-1 border-silver align-middle text-center" style={{color: parseInt(i.status) === 9 ? 'white' : ''}}>{index + 1}</td>
                  <td className="py-1 border-silver align-middle text-center" style={{color: parseInt(i.status) === 9 ? 'white' : ''}}>{i.nim}</td>
                  <td className="py-1 border-silver align-middle" style={{color: parseInt(i.status) === 9 ? 'white' : ''}}>{i.nama}</td>
                  <td className="py-1 px-0 border-silver align-middle text-center w-25" style={{color: parseInt(i.status) === 9 ? 'white' : ''}}>
                    {items.absensi && items.absensi.length > 0 ? (
                      items.absensi.filter((a) => {
                        if (String(a.pertemuan) === String(items.pertemuan)) {
                          return parseInt(a.mhs_id) === parseInt(i.id);
                        }
                        return false;
                      }).length > 0 ? (
                        items.absensi.map((a, index) => {
                          if (String(a.pertemuan) === String(items.pertemuan)) {
                            if (parseInt(a.mhs_id) === parseInt(i.id)) {
                              return (
                                <div
                                  className="px-0 mx-0 position-relative head-item-hover d-flex justify-content-center align-items-center"
                                  key={index}
                                >
                                  {parseInt(a.status) === 1 && (
                                    <div>
                                      <span className="text-success fw-semibold">
                                        <i className="fa fa-fw fa-circle-check fa-sm"/> Hadir
                                      </span>
                                    </div>
                                  )}
                                  {parseInt(a.status) === 2 && (
                                    <div>
                                      <span className="text-warning fw-semibold">
                                      <i className="fa fa-fw fa-circle-exclamation fa-sm"/> Izin
                                      </span>
                                    </div>
                                  )}
                                  {parseInt(a.status) === 3 && (
                                    <div>
                                      <span className="text-warning fw-semibold">
                                      <i className="fa fa-fw fa-circle-exclamation fa-sm"/> Sakit
                                      </span>
                                    </div>
                                  )}
                                  {parseInt(a.status) === 4 && (
                                    <div>
                                      <span className="text-danger fw-semibold">
                                      <i className="fa fa-fw fa-circle-xmark fa-sm"/> Alpa
                                      </span>
                                    </div>
                                  )}
                                  <div className="position-absolute top-0 child-item-hover me-2">
                                    <button
                                      className="mx-0 py-0 px-4 border-0 btn btn-sm btn-danger rounded text-sm text-nowrap"
                                      title="Hapus absensi."
                                      type="button"
                                      onClick={() =>
                                        handleCancleDelete(a)
                                      }
                                    >
                                      <i className="fa fa-fw fa-trash-alt fa-sm" /> Hapus
                                    </button>
                                  </div>
                                </div>
                              );
                            }
                          }
                          return false;
                        })
                      ) : (
                        <Absen
                          i={i}
                          items={items}
                          handleUpdate={handleUpdate}
                        />
                      )
                    ) : (
                      <Absen i={i} items={items} handleUpdate={handleUpdate} />
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </LayoutTable>
    </>
  );
};

export default PengajaranEditPengajaranDosen;
