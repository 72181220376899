import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import axios from "../../axios";
import LoadingPage from "../../components/pages/Loading";
import PortalHead from "../../components/portals/Head";
import { tokenState } from "../../storage/auth";

const PendaftarPrint = () => {
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState();
  const [items, setItems] = useState();

  const [print, setPrint] = useState(true);
  const params = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/api/pendaftar/" + params.id, {
          headers: { Authorization: "Bearer " + token },
        });
        setItems(res.data);
        setIsLoaded(true);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [token, params]);

  const [promo, setPromo] = useState();

  const handleUpdate = () => {
    setPrint(false);
    axios
      .patch(
        "/api/pendaftar/" + params.id,
        { promo },
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((res) => {
        window.print();
        setPrint(true);
      })
      .catch((err) => console.log(err));
  };

  if (isLoaded) {
    return (
      <>
        <PortalHead>
          <style
            dangerouslySetInnerHTML={{
              __html: `
            * {
              font-family: "Times New Roman", Times, serif;
            }
          `,
            }}
          />
        </PortalHead>
        <div className="container bg-white" id="read_only">
          <div className="row justify-content-center">
            <div className="col-3">
              <img
                src="https://manajemen-alfaprima.com/img/Alfa Prima copy.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col text-center">
              <h3>FORMULIR PENDAFTARAN</h3>
              <h5>MAHASISWA BARU</h5>
              <h5>LEMBAGA PENDIDIKAN MANAJEMEN &amp; INFORMATIKA</h5>
              <h5>TAHUN AKADEMIK {items.tahun_ajaran}</h5>
            </div>
            <div className="col-1">
              <h5 className="float-right">{items.no_surat}</h5>
            </div>
          </div>
          <br />
          <table className="table table-sm">
            <tbody>
              <tr>
                <td style={{ textTransform: "capitalize" }}>cabang</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.cabang}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>nama</td>
                <td style={{ textTransform: "capitalize" }}>= {items.nama}</td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>tempat lahir</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.tempat_lahir}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>tanggal lahir</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.tanggal_lahir}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>nama ayah</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.nama_ayah}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>nama ibu</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.nama_ibu}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>alamat ortu</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.alamat_ortu}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>no hp</td>
                <td style={{ textTransform: "capitalize" }}>= {items.no_hp}</td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>no hp ortu</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.no_hp_ortu}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>email</td>
                <td>= {items.email}</td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>fb</td>
                <td>= {items.fb}</td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>ig</td>
                <td>= {items.ig}</td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>jenis kelamin</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.jenis_kelamin}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>agama</td>
                <td style={{ textTransform: "capitalize" }}>= {items.agama}</td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>asal sekolah</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.asal_sekolah}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>tahun tamat</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.tahun_tamat}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>jurusan</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.jurusan}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>waktu belajar</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.waktu_belajar}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>informasi</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.informasi}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>
                  keputusan kuliah
                </td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.keputusan_kuliah}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>refrensi</td>
                <td style={{ textTransform: "capitalize" }}>
                  = {items.refrensi}
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>Promo</td>
                <td>
                  ={" "}
                  <input
                    onChange={(e) => setPromo(e.target.value)}
                    id="promo"
                    style={{
                      border: "none",
                      width: "90%",
                      textTransform: "uppercase",
                    }}
                    type="text"
                    placeholder="Klik untuk tambah promo"
                    defaultValue={items.promo}
                  />
                </td>
              </tr>
              <tr>
                <td style={{ textTransform: "capitalize" }}>Pilihan Jurusan</td>
                <td style={{ textTransform: "capitalize" }}>= {items.prodi}</td>
              </tr>
            </tbody>
          </table>
          <h4>Ketentuan Administrasi Calon Mahasiswa Baru Alfa Prima :</h4>
          <div className="row">
            <div className="col text-justify">
              <ol>
                <li>
                  Calon Mahasiswa/i wajib melakukan <i>daftar ulang</i> sesuai
                  jurusan yang sudah dipilih sampai <b>batas waktu</b> yang
                  ditentukan. Jika calon mahasiswa/i tidak melakukan daftar
                  ulang sampai batas akhir yang ditentukan Alfa Prima berhak
                  menggantikannya dengan calon mahasiswa/i cadangan.
                </li>
                <li>
                  Jika dalam 1 (satu) kelas jumlah peserta kurang dari{" "}
                  <i>20 (dua puluh)</i> orang maka pilihan waktu belajar akan
                  ditentukan oleh pihak Lembaga Alfa Prima.
                </li>
                <li>
                  Uang yang disetorkan tidak dapat dikembalikan, kecuali
                  diterima melalui jalur SMPTN dan SBMPTN.
                </li>
              </ol>
            </div>
          </div>
          <br />
          <div className="row justify-content-center">
            <div className="col text-center">
              <p className="mb-5">Pendaftar</p>
              <p className="pt-4">({items.nama})</p>
            </div>
            <div className="col text-center">
              <p className="mb-5">Penerima Pendaftaran</p>
              <p className="pt-4 mb-0">
                (................................................................)
              </p>
              <p className="m-0 p-0">
                {new Date().getDate()}-
                {new Date().getMonth() + 1}-
                {new Date().getFullYear()}
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col mt-3">
              <br />
              <br />
              <p>
                Biaya Pendaftaran : Rp. ........................................
              </p>
            </div>
          </div>
          <div
            className="text-center"
            style={print ? { display: "" } : { display: "none" }}
          >
            <br />
            <div className="btn-group">
              <button
                onClick={handleUpdate}
                className="btn btn-primary"
                id="print"
                title="Print"
              >
                <i className="fa fa-print"></i>
              </button>
              <Link
                to="/pendaftar-fo"
                className="btn btn-danger"
                title="Kembali"
              >
                <i className="fa fa-arrow-alt-circle-left"></i>
              </Link>
            </div>
            <br />
            <br />
          </div>
        </div>
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

export default PendaftarPrint;
