import React from "react";

const FormCheckbox = (props) => {
  const { inline, error, name, value, id, checked, onChange, label } = props;
  return (
    <>
      <div className={`form-check ${inline && "form-check-inline"}`}>
        <input
          className={`form-check-input ${error && "is-invalid"}`}
          name={name}
          type="checkbox"
          value={value}
          id={id}
          checked={checked}
          onChange={onChange}
        />
        {label && (
          <label
            className="form-check-label font-weight-bold text-primary"
            htmlFor={id}
          >
            {label}
          </label>
        )}
        {error && <div className="invalid-feedback">{error}</div>}
      </div>
    </>
  );
};

export default FormCheckbox;
