import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deleteIjin, postIjin, showIjin } from "../../api";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal, { ModalBtn } from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterBulan,
  defaultFilterTahun,
  FilterBulan,
  FilterTahun,
  LayoutAddBtn,
} from "../../components/layouts/TopContent";
import LoadingPage from "../../components/pages/Loading";
import NoDataPage from "../../components/pages/NoData";
import { formatDate, formatDiffDate, saAlert, saConfirm } from "../../helpers";
import { loginMasterState, userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import FormRadio from "../../components/forms/Radio";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const IjinShow = () => {
  const setTitle = useSetRecoilState(titleState);
  const loginMaster = useRecoilValue(loginMasterState);

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState();

  const [page] = useState("all");
  const [bulan, setBulan] = useState(defaultFilterBulan);
  const [tahun, setTahun] = useState(defaultFilterTahun);

  const fetchData = useCallback(async () => {
    try {
      const res = await showIjin(
        page,
        `page=all&bulan=${bulan}&tahun=${tahun}`
      );
      if (res.data && res.data.ijin) {
        setItems(res.data.ijin);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err.response);
    }
  }, [page, bulan, tahun]);

  useEffect(() => {
    fetchData();
    setTitle("Lihat Data Ijin");
  }, [fetchData, setTitle]);

  if (isLoaded) {
    return (
      <>
        <CreateIjin onFetchData={fetchData} />
        <div className="row mb-2">
          <div className="col-md">
            <FilterBulan all={true} onChange={(res) => setBulan(res)} />
          </div>
          <div className="col-md">
            <FilterTahun onChange={(res) => setTahun(res)} />
          </div>
        </div>

        {items && items.length > 0 ? (
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>Jenis</Th>
                <Th>Dari</Th>
                <Th>Sampai</Th>
                <Th>Jumlah</Th>
                <Th>Alamat</Th>
                <Th>Keperluan</Th>
                <Th>Status</Th>
                <Th>Oleh</Th>
                <Th><i className="fa fa-fw fa-cog"/></Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center align-middle">{1 + index}</Td>
                    <Td className="text-nowrap text-center align-middle">
                      {parseInt(i.status_keterangan) === 1 && (
                        <span className="fw-semibold text-danger">Sakit</span>
                      )}
                      {parseInt(i.status_keterangan) === 2 && (
                        <span className="fw-semibold text-warning-2">Keperluan Lain</span>
                      )}
                    </Td>
                    <Td className="text-center text-nowrap align-middle">
                      {i.dari && formatDate(i.dari)}
                    </Td>
                    <Td className="text-center text-nowrap align-middle">
                      {i.sampai && formatDate(i.sampai)}
                    </Td>
                    <Td className="text-center text-nowrap align-middle">
                      {formatDiffDate(i.jumlah)}
                    </Td>
                    <Td className="align-middle">{i.alamat}</Td>
                    <Td className="align-middle">{i.keperluan_lain}</Td>
                    <Td className="text-center align-middle">
                      {i.status === null && (
                        <span className="fw-semibold text-dark">Menunggu...</span>
                      )}
                      {parseInt(i.status) === 1 && (
                        <span className="fw-semibold text-success-2">Disetujui</span>
                      )}
                      {parseInt(i.status) === 2 && (
                        <span className="fw-semibold text-danger-2">Ditolak</span>
                      )}
                    </Td>
                    <Td className="align-middle">
                      {i.atasan && i.atasan.nama}
                    </Td>
                    <Td className="text-nowrap align-middle">
                      {i.status === null ? (
                        <DeleteIjin id={i.id} onFetchData={fetchData} />
                      ) : (
                        <>
                          {loginMaster ? (
                            <DeleteIjin id={i.id} onFetchData={fetchData} />
                          ) : (
                            <i className="fa fa-fw fa-circle-check text-success-2"/>
                          )}
                        </>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <NoDataPage />
        )}
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

const CreateIjin = (props) => {
  const tagForm = useRef();
  const [modal, setModal] = useState(false);
  const user = useRecoilValue(userState);
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const handleClose = () => {
    if (tagForm) {
      tagForm.current.reset();
    }
    setForm({});
    setFormErrors({});
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await postIjin(form);
      if (res.data && res.data.msg === "success") {
        saAlert("success", res.data.desc);
        props.onFetchData();
        handleClose();
      }

      if (res.data.msg === "fail") {
        saAlert("warning", res.data.message);
        props.onFetchData();
      }
    } catch (err) {
      let res = err.response;
      if (res.status === 422) {
        setFormErrors(res.data.errors);
      }
    }
  };
  return (
    <>
      <LayoutAddBtn onClick={() => setModal(true)} add="Buat Ijin Baru" />
      <LayoutModal
        size="md"
        title="Buat Ijin Baru Baru"
        modal={modal}
        onClose={handleClose}
      >
        <form ref={tagForm} onSubmit={handleSubmit}>
          <FormInput label="NIP" readOnly={true} value={user.nip} />
          <FormInput label="Nama" readOnly={true} value={user.nama} />
          <label className="fw-semibold text-primary">Jenis</label>
          <div className="text-dark mb-3">
            <FormRadio
              inline={1}
              error={formErrors.status_keterangan}
              name="status_keterangan"
              value="1"
              id="status_keterangan1"
              onChange={(e) =>
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                })
              }
              label="Ijin Sakit"
            />
            <FormRadio
              inline={1}
              error={formErrors.status_keterangan}
              name="status_keterangan"
              value="2"
              id="status_keterangan2"
              onChange={(e) =>
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                })
              }
              label="Ijin Keperluan Lain"
            />
          </div>
          <FormInput
            label="Dari"
            type="date"
            name="dari"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={formErrors.dari}
          />
          <FormInput
            label="Sampai"
            type="date"
            name="sampai"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={formErrors.sampai}
          />
          <FormTextarea
            label="Alamat"
            name="alamat"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={formErrors.alamat}
          />
          <FormInput
            label="No HP"
            name="no_hp"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={formErrors.no_hp}
          />
          {/* <FormCr
            label="Keperluan"
            type="radio"
            inline={true}
            name="keperluan"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            option={[
              { value: "Cuti Tahunan" },
              { value: "Cuti Bersalin" },
              { value: "Cuti Alasan Penting" },
            ]}
            error={formErrors.keperluan}
          /> */}
          <FormTextarea
            label="Keperluan"
            name="keperluan_lain"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={formErrors.keperluan_lain}
          />

          <div className="my-4">
            <p className="mb-0 text-center fw-semibold text-primary">Approval ijin oleh :</p>
            <p className="mb-0 text-center fw-semibold text-dark">
              {(() => {
                if (user.atasan1) {
                  return user.atasan1.nama;
                } else {
                  if (user.atasan2) {
                    return user.atasan2.nama;
                  }
                }
              })()}
            </p>
          </div>
          
          <ModalBtn width="full" onClose={handleClose} />
        </form>
      </LayoutModal>
    </>
  );
};

const DeleteIjin = (props) => {
  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus data cuti ?").then((res) => {
      if (res.isConfirmed) {
        deleteIjin(props.id).then((res) => {
          if (res.data && res.data.msg === "success") {
            props.onFetchData();
            saAlert("success", "Berhasil hapus cuti !");
          }
        });
      }
    });
  };
  return (
    <button
      onClick={handleDelete}
      className="btn btn-sm btn-danger-2 py-0 px-1"
    >
      <i className="fa fa-fw fa-sm fa-trash-alt"/>
    </button>
  );
};

export default IjinShow;
