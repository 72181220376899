import React from "react";
import { Link } from "react-router-dom";

export const getStatusMou = (number) => {
  let no = parseInt(number);
  if (no === 1) {
    return <span className="bg-warning fw-semibold rounded text-nowrap text-sm px-2">Baru</span>;
  }
  if (no === 2) {
    return <span className="bg-success text-white rounded text-nowrap text-sm px-2">Perpanjang</span>;
  }
  if (no === 3) {
    return <span className="bg-dark text-white rounded text-nowrap text-sm px-2">Belum MoU</span>;
  }
  if (no === 4) {
    return <span className="bg-primary-2 text-white text-sm rounded text-nowrap px-2">Berita Acara</span>;
  }
};

export const BtnForPerusahaan = () => {
  return (
    <div className="row">
      <div className="col-lg-6">
        <div className="btn-group btn-group-sm w-100 mb-3">
          <Link to="/perusahaan-pkk" className="btn btn-primary-3 rounded-start-3">
            Perusahaan
          </Link>
          <Link to="/pojt-pkk" className="btn btn-warning-2 fw-semibold rounded-end-3">
            Perusahaan OJT
          </Link>
        </div>
      </div>
    </div>
  );
};
