import React, { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { postPojt } from "../../api";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";
import { userState } from "../../storage/auth";
import { JurusanSelect } from "./Components";

const PerusahaanAktifOjt = (props) => {
  const user = useRecoilValue(userState);

  const { jurusan, perusahaan, tahunAjaran, onFetchData } = props;

  const [modal, setModal] = useState(false);

  const [jurusanSelect, setJurusanSelect] = useState([]);
  const [form, setForm] = useState({
    perihal: 'Permohonan Pelaksanaan On the Job Training (OJT)',
    ditujukan: perusahaan.pic,
  });

  const [errors, setErrors] = useState({});

  const tagForm = useRef();

  const handleClose = () => {
    tagForm.current.reset();
    setForm({});
    setJurusanSelect([]);
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postPojt({
      ...form,
      perusahaan_id: perusahaan.id,
      tahun: tahunAjaran,
      jurusan_select: jurusanSelect,
    })
      .then((res) => {
        if (res.data === "success") {
          tagForm.current.reset();
          setForm({});
          setModal(false);
          onFetchData();
          saAlert(
            "success",
            "Berhasil jadikan perusahan sebagai perusahaan OJT"
          );
        }
      })
      .catch((err) => {
        err.response &&
          err.response.status === 422 &&
          setErrors(err.response.data.errors);
      });
  };

  return (
    <>
      <button
        onClick={() => {
          if (
            user.cabang === "pusat" ||
            perusahaan.cabang.toLowerCase() === user.cabang.toLowerCase()
          ) {
            setModal(true);
          } else {
            saAlert("warning", "Tidak dapat mengubah data cabang lain.");
          }
        }}
        className="btn btn-sm btn-success-2 border-0 py-0 text-sm rounded mx-1"
      >
        <span className="text-nowrap">
          <i className="fa fa-fw fa-sm fa-star fa-regular" /> Aktifkan
        </span>
      </button>
      <LayoutModal
        title="Tambah Perusahaan OJT"
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
          <div className="row">
            <div className="col-lg-12 mb-3">
              <div className="bg-gradient-primary text-white rounded shadow p-3">
                <div>
                  <h5 className="mb-0">{perusahaan.nama}</h5>
                  <p className="mb-2 text-gray-400">{perusahaan.alamat}</p>
                  <p className="mb-0 d-inline-block bg-primary-4 rounded-3 shadow py-1 px-2">
                    <span className="text-gray-400">PIC :</span>{" "}
                    <i className="fa fa-fw fa-sm fa-user" /> {perusahaan.pic}
                  </p>
                </div>

                <div className="mt-2">
                  <span>
                    <i className="fa fa-fw fa-phone text-success-2 me-1" />{" "}
                    {perusahaan.no_telf ? (
                      perusahaan.no_telf
                    ) : (
                      <span className="text-gray-400">Tidak tersedia.</span>
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mb-2">
              <div className="row">
                <div className="col-lg-6">
                  <FormInput
                    label="Nomor Surat"
                    name="no_surat"
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    error={errors.no_surat}
                  />
                </div>
                <div className="col-lg-6">
                  <FormInput
                    label="Perihal"
                    name="perihal"
                    value={form.perihal}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    error={errors.perihal}
                  />
                </div>
                <div className="col-lg-6">
                  <FormInput
                    label="Ditujukan"
                    name="ditujukan"
                    value={form.ditujukan}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    error={errors.ditujukan}
                  />
                </div>
                <div className="col-lg-6">
                  <FormInput
                    label="Tahun Ajaran"
                    name="tahun"
                    value={tahunAjaran}
                    placeholder="Contoh : 2023/2024"
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    error={errors.tahun}
                  />
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="bg-light border border-silver rounded-3 p-3 mt-3">
                    <h6 className="text-center bg-primary rounded-3 text-white py-1">
                      2 Bulan OJT
                    </h6>
                    <div className="row">
                      <div className="col-lg-6">
                        <FormInput
                          label="Dari"
                          type="date"
                          name="dari_2"
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                          error={errors.dari_2}
                        />
                      </div>
                      <div className="col-lg-6">
                        <FormInput
                          label="Sampai"
                          type="date"
                          name="sampai_2"
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                          error={errors.sampai_2}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="bg-light border border-silver rounded-3 p-3 mt-3">
                    <h6 className="text-center bg-primary rounded-3 text-white py-1">
                      3 Bulan OJT
                    </h6>
                    <div className="row">
                      <div className="col-lg-6">
                        <FormInput
                          label="Dari"
                          type="date"
                          name="dari_4"
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                          error={errors.dari_4}
                        />
                      </div>
                      <div className="col-lg-6">
                        <FormInput
                          label="Sampai"
                          type="date"
                          name="sampai_4"
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                          error={errors.sampai_4}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 mb-3">
                  <div className="bg-light border border-silver rounded-3 p-3 mt-3">
                    <h6 className="text-center bg-primary rounded-3 text-white py-1">
                      6 Bulan OJT
                    </h6>
                    <div className="row">
                      <div className="col-lg-6">
                        <FormInput
                          label="Dari"
                          type="date"
                          name="dari_6"
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                          error={errors.dari_6}
                        />
                      </div>
                      <div className="col-lg-6">
                        <FormInput
                          label="Sampai"
                          type="date"
                          name="sampai_6"
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                          error={errors.sampai_6}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <FormTextarea
                    label="Keterangan"
                    row={5}
                    name="penempatan"
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    error={errors.penempatan}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              {/* Jurusan Optional */}

              <JurusanSelect
                jurusan={jurusan}
                onSetJurusanSelect={(res) => setJurusanSelect(res)}
              />

              {/* End Jurusan Optional */}
            </div>
          </div>

          <div className="row mt-2">
            <NewSubmitButton onClose={handleClose} />
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default PerusahaanAktifOjt;