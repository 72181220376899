import { atom } from "recoil";

const tokenState = atom({
  key: "tokenState",
  default: "",
});

const userState = atom({
  key: "userState",
  default: {},
});

const kacabState = atom({
  key: "kacabState",
  default: {},
});

const loginMasterState = atom({
  key: "loginMasterState",
  default: false,
});

export { tokenState, userState, kacabState, loginMasterState };
