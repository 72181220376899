import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { userState } from "../../storage/auth";
import { useRecoilValue } from "recoil";
import TopbarDropdownBap from "./TopbarDropdown.js/Bap";
import TopbarDropdownAbsensi from "./TopbarDropdown.js/Absensi";
import TopbarDropdownMouPerusahaan from "./TopbarDropdown.js/MouPerusahaan";
import TopbarDropdownUlangTahunPerusahaan from "./TopbarDropdown.js/UlangTahunPerusahaan";
import { fetchJabatan } from "../../api";

const FooterTopMain = () => {
  const user = useRecoilValue(userState);

  // const [showMaster, setShowMaster] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [jabatanId, setJabatanId] = useState([]);

  // const toggleMaster = () => {
  //   setShowMaster(!showMaster);
  // }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoaded(true);
      const res = await fetchJabatan("all", `pegawai_id=${user.id}`);
      if (res.data && res.data.jabatan && res.data.jabatan.length > 0) {
        setJabatanId(res.data.jabatan);
        setIsLoaded(false);
      } else {
        setIsLoaded(false);
      }
    };

    fetchData();
  }, [user]);
  
  return (
    <div className=" mx-3 mt-3">
      <div className="row gy-0 my-0">
        <div className="col-lg-12">
          <ul className="d-flex flex-wrap mobile-justify-content-center align-items-center list-unstyled mb-0">
          {/* {user.jabatan === "IT Programmer" && (
            <li className="nav-item dropdown no-arrow">
              <button
                className="nav-link btn btn-sm btn-primary-4 rounded-3 py-1 mx-1 mb-2 dropdown-toggle"
                onClick={toggleMaster}
              >
                <i className="fa fa-crown text-warning me-0 me-sm-1" />
                <span className="d-none d-sm-inline">Master</span>
                <i className={`align-middle fa fa-xs ${showMaster ? 'fa-chevron-down' : 'fa-chevron-right'} ms-1`} />
              </button>
              <div
                className={`${showMaster ? 'show' : ''} dropdown-menu dropdown-menu-left shadow animated--grow-in`}
              >
                <Link className="dropdown-item" onClick={(e) => setShowMaster(false)} to="/pegawai-master">
                  <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Pegawai
                </Link>
                <Link className="dropdown-item" onClick={(e) => setShowMaster(false)} to="/import-export">
                  <i className="fas fa-file fa-sm fa-fw mr-2 text-gray-400"></i>
                  Import Export
                </Link>
              </div>
            </li>
          )} */}
          {user.jabatan === "IT Programmer" && (
            <Link 
              className="nav-link btn btn-sm btn-primary-4 rounded-3 py-1 mx-1 mb-2" 
              // onClick={(e) => setShowMaster(false)} 
              to="/pegawai-master">
              <i className="fa fa-crown text-warning me-0 me-sm-1" />
              <span className="d-none d-sm-inline">Master</span>
            </Link>
          )}

          {user.group === "Akademik" && (
            <>
              <TopbarDropdownBap />

              <TopbarDropdownAbsensi />
            </>
          )}

          {user.jabatan === "IT Programmer" ||
          user.jabatan === "Dir. Akademik & Kemahasiswaan" ||
          user.jabatan === "Dir. Pusat Karir dan Kerjasama" ||
          user.jabatan === "Kepala Cabang Denpasar" ||
          user.jabatan === "Kepala Cabang Singaraja" ||
          user.jabatan === "Kepala Cabang Karangasem" ||
          user.jabatan === "Ka. Bag. Pusat Karier & Alumni" ||
          user.jabatan === "Ka. Unit Kerjasama" ||
          user.jabatan === "Ka. Unit Karier & Alumni" ||
          user.jabatan === "Ka. Bag. Pusat Karier & Kerjasama" ||
          user.group === "Akademik" ? (
            <>
              <TopbarDropdownMouPerusahaan />

              <TopbarDropdownUlangTahunPerusahaan />
            </>
          ) : (
            ""
          )}
          </ul>
        </div>
        <div className="col-lg-12">
          <div className="d-flex mobile-justify-content-center">
            <div className="bg-primary d-sm-inline-block text-white rounded-3 py-2 px-3">
              <h6 className="text-sm mb-0">
                <span className="text-warning"><span className="d-sm-inline d-none">Login dengan</span> Jabatan</span> :{" "}
                {!isLoaded ? jabatanId.filter((j) => parseInt(j.id) === parseInt(user.jabatan_id)).map((j, index) => {
                  return (
                    <span key={index}>{j.nama}</span>
                  )
                }) :
                  <span><i className="ms-1 fa fa-spin fa-spinner"/> Loading...</span>
                }
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterTopMain;
