import React from "react";
import { SinggleMenu } from "./Sidebar";

const Fo = ({ user }) => {
  return (
    <>
      {user.jabatan === "IT Programmer" ||
      user.jabatan === "Dir. Humas & Pemasaran" ||
      user.jabatan === "Internet Marketing" ||
      user.jabatan === "Kepala Cabang Denpasar" ||
      user.jabatan === "Kepala Cabang Singaraja" ||
      user.jabatan === "Kepala Cabang Karangasem" ||
      user.jabatan === "Ka. Bag. Humas & Pemasaran" ||
      user.jabatan === "Bagian Humas & Pemasaran" ||
      user.jabatan === "Ka. Unit Resepsionis" ||
      user.jabatan === "Resepsionis" ? (
        <>
          <hr className="border-0 my-2" />

          <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
            FO
          </div>

          <hr className="sidebar-divider border-0 mt-2 mb-0" />

          <SinggleMenu title="Pendaftar" fa="fa-users" link="/pendaftar-fo" />
          <SinggleMenu title="Mahasiswa Baru" fa="fa-users" link="/mhs-fo" />
          <SinggleMenu title="Dosen" fa="fa-users" link="/dosen-akademik" />
        </>
      ) : null}
    </>
  );
};

export default Fo;
