import React, { useState } from 'react'
import LayoutModal from '../../components/layouts/Modal'
import SmallButton from '../../components/forms/SmallButton';
import { getOrigin } from '../../helpers';

function BTShow({bt}) {

    const [modal, setModal] = useState(false);
    const [item, setItem] = useState({});

    const handleOpen = () => {
        setModal(true);
        setItem(bt);
    };

    const handleClose = () => {
        setModal(false);
        setItem({});
    };

  return (
    <>
        <SmallButton onClick={handleOpen} margin='me-1' type='show'/>

        <LayoutModal
            title='Detail postingan'
            modal={modal}
            onClose={handleClose}
        >
            <div className='row'>
                <div className='col-lg-6'>
                    <img src={`${getOrigin()}/storage/berita-terkini/picture/${item.picture}`} loading='lazy' alt={item.picture} className='img-fluid rounded-3'/>
                </div>
                <div className='col-lg-6'>
                    <h6 className='pt-3 pt-lg-0 mb-3 fs-2 fw-semibold text-primary'>{item.title}</h6>
                    <p className='mb-3 text-black'>{item.caption}</p>
                    <div className='d-flex justify-content-between align-items-center text-black'>
                        <p className='mb-0'><i className='fa fa-fw fa-eye'/> {item.berita_terkini_status && item.berita_terkini_status.views}</p>
                        <p className='mb-0'><i className='fa fa-fw fa-heart'/> {item.berita_terkini_status && item.berita_terkini_status.likes}</p>
                    </div>
                </div>
            </div>
        </LayoutModal>
    </>
  )
}

export default BTShow