import React, { useState } from 'react'
import SmallButton from '../../components/forms/SmallButton'
import LayoutModal from '../../components/layouts/Modal';
import FormInput from '../../components/forms/Input';

function HrdBerkas() {

    const [modal, setModal] = useState(false);

    const handleOpen = () => {
        setModal(true);
    }

    const handleClose = () => {
        setModal(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        try {
            alert("masih dalam pengembangan");
        } catch (error) {
            console.log(error);
        }
    }

  return (
    <>
        <SmallButton margin="me-1" type="custom" icon="folder" buttonColor="primary-3" onClick={handleOpen} text="Berkas"/>

        <LayoutModal
            modal={modal}
            onClose={handleClose}
            title="Berkas Pegawai"
        >
            <form onSubmit={handleSubmit}>
                <div className='row'>
                    <div className='col-lg-4 mb-3'>
                        <FormInput 
                            label="File KPI"
                            type="file"
                        />
                    </div>
                    <div className='col-lg-4 mb-3'>
                        <FormInput 
                            label="File Report"
                            type="file"
                        />
                    </div>
                    <div className='col-lg-4 mb-3'>
                        <FormInput 
                            label="File Kontrak"
                            type="file"
                        />
                    </div>
                </div>
                <p className='mb-0 text-danger-2 text-center'>Fitur masih dalam tahap pengembangan!.</p>
            </form>
        </LayoutModal>
    </>
  )
}

export default HrdBerkas