import { fetchDataControl, fetchingData } from "../../api";
import axios from "../../axios";

// Ceklis Monitoring
// index
export const fetchCeklistMonitoringList = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/ceklist-monitoring-list?page=${page}&${custom}`);
  });
};
// show
export const showCeklistMonitoringList = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/ceklist-monitoring-list/${id}?${custom}`);
  });
};
// store
export const postCeklistMonitoringList = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/ceklist-monitoring-list`, data);
  });
};
// update
export const patchCeklistMonitoringList = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/ceklist-monitoring-list/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteCeklistMonitoringList = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/ceklist-monitoring-list/${id}&${custom}`);
  });
};
