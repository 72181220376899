import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchKpi, fetchMorningReport } from "../../api";
import { titleState } from "../../storage/pages";
import MorningReportCreate from "./Create";
import { FilterSearch } from "../../components/layouts/TopContent";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import MorningReportEdit from "./Edit";
import SectionPagination from "../../components/sections/Pagination";
import MorningReportDelete from "./Delete";
import { formatDate } from "../../helpers";
import { userState } from "../../storage/auth";

const MorningReportHrd = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [isLoaded, setIsLoaded] = useState(false);

  const [morningReport, setMorningReport] = useState({});

  const fetchData = useCallback(
    async ({ isReset = true } = true) => {
      {
        isReset && setIsLoaded(false);
      }
      try {
        const res = await fetchMorningReport(page, `search=${search}&hrd=1`);
        setIsLoaded(true);
        setTitle(res.data.title);
        setMorningReport(res.data.morning_report);
      } catch (err) {
        //
      }
    },
    [page, search, setTitle]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [kpi, setKpi] = useState({});

  useEffect(() => {
    const fetchDataKpi = async () => {
      try {
        const res = await fetchKpi("all", `pegawai_id=${user.id}`);
        setKpi(res.data.kpi);
        console.log(res.data.kpi);
      } catch (err) {
        //
      }
    };
    fetchDataKpi();
  }, [user]);

  return (
    <>
      <div className="row justify-content-end mb-2">
        <div className="col-md-4">
          <FilterSearch onChange={(res) => setSearch(res)} />
        </div>
      </div>

      {isLoaded && (
        <LayoutTable>
          <LayoutThead tr={true}>
            <tr className="text-center">
              <th className="align-middle" rowSpan={2}>
                No
              </th>
              <th className="align-middle" rowSpan={2}>
                Nama
              </th>
              <th className="align-middle" rowSpan={2}>
                Tanggal
              </th>
              <th className="align-middle" colSpan={4}>
                List
              </th>
            </tr>
            <tr className="text-center">
              <th className="align-middle">No List</th>
              <th className="align-middle">KPI</th>
              <th className="align-middle">Judul</th>
              <th className="align-middle">Keterangan</th>
            </tr>
          </LayoutThead>
          <tbody>
            {morningReport.data &&
              morningReport.data.length > 0 &&
              morningReport.data.map((mr, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center align-middle text-nowrap">
                      {index + morningReport.from}
                    </td>
                    <td className="text-center align-middle text-nowrap">
                      {mr.pegawai && mr.pegawai.nama}
                    </td>
                    <td className="text-center align-middle">
                      {formatDate(mr.tanggal)}
                    </td>
                    <td className="text-nowrap">
                      <ol style={{ listStyleType: "none" }}>
                        {mr.morning_report_list &&
                          mr.morning_report_list.map((mrl, index) => {
                            return <li key={index}>{index + 1}</li>;
                          })}
                      </ol>
                    </td>
                    <td className="text-nowrap">
                      <ol style={{ listStyleType: "none" }}>
                        {mr.morning_report_list &&
                          mr.morning_report_list.map((mrl, index) => {
                            return (
                              <li key={index}>{mrl.kpi && mrl.kpi.nama}</li>
                            );
                          })}
                      </ol>
                    </td>
                    <td className="text-nowrap">
                      <ol style={{ listStyleType: "none" }}>
                        {mr.morning_report_list &&
                          mr.morning_report_list.map((mrl, index) => {
                            return <li key={index}>{mrl.judul}</li>;
                          })}
                      </ol>
                    </td>
                    <td className="text-nowrap">
                      <ol style={{ listStyleType: "none" }}>
                        {mr.morning_report_list &&
                          mr.morning_report_list.map((mrl, index) => {
                            return <li key={index}>{mrl.keterangan}</li>;
                          })}
                      </ol>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </LayoutTable>
      )}

      <SectionPagination
        links={morningReport.links}
        onChangePage={(res) => setPage(res)}
      />
    </>
  );
};

export default MorningReportHrd;
