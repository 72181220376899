import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchAlumni, fetchPerusahaanAlumni, patchPerusahaanAlumni } from "../../api";
import { LayoutTable, LayoutThead, TableNoData } from "../../components/layouts/Table";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { titleState } from "../../storage/pages";
import {
  FilterCabang,
  FilterSearch,
  LayoutAddBtn,
} from "../../components/layouts/TopContent";
import { userState } from "../../storage/auth";
import TableNew, {
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "../../components/layouts/TableNew";
import SmallButton from "../../components/forms/SmallButton";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import FormSelect from "../../components/forms/Select";
import FormInput from "../../components/forms/Input";
import { saAlert } from "../../helpers";

const AlumniPkk = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);
  const user = useRecoilValue(userState);

  const [search, setSearch] = useState("");
  const [cabang, setCabang] = useState("");
  const [page, setPage] = useState(1);

  const [items, setItems] = useState();

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchAlumni(page, `search=${search}&cabang=${cabang}`);
      setItems(res.data.alumni);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page, search, cabang]);

  useEffect(() => {
    setTitle("AlumniPkk");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        <div className="row justify-content-end">
          {user.cabang === "pusat" && (
            <div className="col-lg-4 mb-3">
              <FilterCabang
                onChange={(res) => {
                  setCabang(res);
                  setPage(1);
                }}
              />
            </div>
          )}
          <div className="col-lg-4 mb-3">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {items.data.length >= 1 ? (
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>Cabang</Th>
                <Th>NIM</Th>
                <Th>Nama</Th>
                <Th><i className="fa fa-fw fa-cog"/></Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center">{items.from + index}</Td>
                    <Td className="text-center text-capitalize">{i.cabang}</Td>
                    <Td className="text-center">{i.nim}</Td>
                    <Td>{i.nama}</Td>
                    <Td className="text-center">
                      <ListPerusahaan item={i}/>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

const ListPerusahaan = ({item}) => {

  const [modal, setModal] = useState(false);
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [perusahaan, setPerusahaan] = useState([]);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const fetchPerusahaan = async () => {
    try {
        const res = await fetchPerusahaanAlumni("all");
        setPerusahaan(res.data.perusahaan_alumni);
        setLoading(false);
    } catch (error) {
        console.log(error.response);
        setLoading(false);
    }
  }

  const handleOpen = () => {
    fetchPerusahaan();
    setModal(true);
  }

  const handleClose = () => {
    setModal(false);
  }

  const handleOpenCreate = () => {
    setForm((prevState) => ({...prevState, mhs_id: `${item.id}`}))
    setCreate(true);
    handleCloseEdit();
  }

  const handleOpenEdit = (data) => {
    setTimeout(() => {
      if (data) {
        setForm(data);
      }
    }, 500);
    setEdit(true);
    handleCloseCreate();
  }

  const handleCloseEdit = () => {
    setEdit(false);
  }

  const handleCloseCreate = () => {
    setForm({});
    setErrors({});
    setCreate(false);
  }

  const handleChange = (e) => {
    setForm((prevState) => ({...prevState, [e.target.name] : e.target.value}))
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await patchPerusahaanAlumni(form.perusahaan_alumni_id, form);
      if (res.data.status === "success") {
        saAlert(res.data.status, res.data.message);
        handleCloseCreate();
        handleCloseEdit();
        fetchPerusahaan();
      }
    } catch (error) {
      setErrors(error.response?.data?.errors);
    }
  }

  return (
    <>
      <SmallButton onClick={handleOpen} type="show" text="List Perusahaan"/>

      <LayoutModal title="List Perusahaan" modal={modal} onClose={handleClose}>
        <div className="position-sticky bg-gradient-primary text-white rounded-3 border-bottom-warning top-0 mb-4" style={{zIndex: '1'}}>
          <div className="row border-bottom border-silver mb-3 mx-0 p-2">
            <div className="col-lg-6">
              <span className="fw-semibold text-info">Alumni :</span>
              <h6 className="text-white">{item.nama}</h6>
            </div>
            <div className="col-lg-3">
              <span className="fw-semibold text-info">NIM :</span>
              <h6 className="text-white">{item.nim}</h6>
            </div>
            <div className="col-lg-3">
              <span className="fw-semibold text-info">Cabang :</span>
              <h6 className="text-white">{item.cabang}</h6>
            </div>
          </div>
          <div className="px-3">
            <LayoutAddBtn onClick={handleOpenCreate} add="Tambahkan ke perusahaan"/>
          </div>
        </div>
        <div>
          <div className="row">
            <div className={`${(create || edit) ? 'col-lg-8' : 'col-lg-12'}`}>
              {!loading ?
                perusahaan.length > 0 ? (
                  <div className="row">
                    <div className="col-11 pe-0">
                      <TableNew>
                        <Thead>
                          <Tr className="text-center">
                            <Th className="text-nowrap">No</Th>
                            <Th className="text-nowrap">Nama</Th>
                            <Th className="text-nowrap">Dari</Th>
                            <Th className="text-nowrap">Sampai</Th>
                            {/* <Th className="text-nowrap">No Telepon</Th>
                            <Th className="text-nowrap">No HP</Th>
                            <Th className="text-nowrap">Email</Th>
                            <Th className="text-nowrap">PIC</Th>
                            <Th className="text-nowrap">Alamat</Th> */}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {perusahaan.filter((p) => p.mhs.some((m) => parseInt(m.id) === parseInt(item.id))).map((p, index) => {
                            return (
                              <Tr key={index}>
                                <Td className="text-nowrap text-center">{index + 1}</Td>
                                <Td className="text-nowrap text-start">{p.nama}</Td>
                                <Td className="text-nowrap text-center">{p.mhs.filter((m) => parseInt(m.id) === parseInt(item.id)).map((m) => m.pivot.dari)}</Td>
                                <Td className="text-nowrap text-center">{p.mhs.filter((m) => parseInt(m.id) === parseInt(item.id)).map((m) => m.pivot.sampai)}</Td>
                                {/* <Td className="text-nowrap text-center">{p.no_telf ? p.no_telf : '-'}</Td>
                                <Td className="text-nowrap text-center">{p.no_hp ? p.no_hp : '-'}</Td>
                                <Td className="text-nowrap text-start">{p.email ? p.email : '-'}</Td>
                                <Td className="text-nowrap text-start">{p.pic ? p.pic : '-'}</Td>
                                <Td className="text-nowrap text-start">{p.alamat}</Td> */}
                              </Tr>
                            )
                          })}
                        </Tbody>
                      </TableNew>
                    </div>
                    <div className="col-1 ps-0">
                      <TableNew copy={false}>
                        <Thead>
                          <Tr className="text-center">
                            <Th className="text-nowrap"><i className='fa fa-fw fa-cog' /></Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {perusahaan.filter((p) => p.mhs.some((m) => parseInt(m.id) === parseInt(item.id))).map((p, index) => {
                            return (
                              <Tr key={index}>
                                <Td className="text-nowrap text-center">
                                  <SmallButton 
                                    onClick={() => {
                                      const selectedMhs = p.mhs.find((m) => parseInt(m.id) === parseInt(item.id));
                                      if (selectedMhs) {
                                        handleOpenEdit(selectedMhs.pivot);
                                      }
                                    }}
                                    margin="me-1" 
                                    type="edit"
                                  />
                                  <SmallButton onClick={() => alert("Masih dalam tahap pengembangan.")} type="delete"/>
                                </Td>
                              </Tr>
                            )
                          })}
                        </Tbody>
                      </TableNew>
                    </div>
                  </div>
                ) : (
                  <div className='py-5 text-center'>
                    <p><i className='fa fa-fw fa-circle-exclamation text-info' /> Belum ada data perusahaan alumni bekerja.</p>
                  </div>
                )
                : (
                  <PageLoading />
                )}
            </div>
            {create &&
              <div className="col-lg-4">
                <div className="rounded-3 bg-gradient-primary text-white border-bottom-warning p-3">
                <h6>Tambah Perusahaan</h6>
                  <form onSubmit={handleSubmit}>
                    <FormSelect
                      labelClassName="text-info"
                      label="Pilih Perusahaan"
                      name="perusahaan_alumni_id"
                      onChange={handleChange}
                      error={errors.perusahaan_alumni_id}
                      required
                    >
                      <option value="">Pilih</option>
                      {perusahaan.length > 0 &&
                        perusahaan.map((p, index) => {
                          return (
                            <option key={index} value={p.id}>{p.nama}</option>
                          )
                      })}
                    </FormSelect>
                    <FormInput
                      labelClassName="text-info"
                      label="Dari"
                      type="date"
                      name="dari"
                      onChange={handleChange}
                      error={errors.dari}
                    />
                    <FormInput
                      labelClassName="text-info"
                      label="Sampai"
                      type="date"
                      name="sampai"
                      onChange={handleChange}
                      error={errors.sampai}
                    />
                    <NewSubmitButton className="mt-2" widthFull onClose={handleCloseCreate}/>
                  </form>
                </div>
              </div>
            }
            {edit &&
              <div className="col-lg-4">
                <div className="rounded-3 bg-gradient-primary text-white border-bottom-warning p-3">
                  <h6>Edit Perusahaan</h6>
                  <form onSubmit={handleSubmit}>
                    <FormSelect
                      labelClassName="text-info"
                      label="Pilih Perusahaan"
                      name="perusahaan_alumni_id"
                      onChange={handleChange}
                      error={errors.perusahaan_alumni_id}
                      value={form.perusahaan_alumni_id}
                      disabled
                    >
                      <option value="">Pilih</option>
                      {perusahaan.length > 0 &&
                        perusahaan.map((p, index) => {
                          return (
                            <option key={index} value={p.id}>{p.nama}</option>
                          )
                      })}
                    </FormSelect>
                    <FormInput
                      labelClassName="text-info"
                      label="Dari"
                      type="date"
                      name="dari"
                      onChange={handleChange}
                      error={errors.dari}
                      value={form.dari}
                    />
                    <FormInput
                      labelClassName="text-info"
                      label="Sampai"
                      type="date"
                      name="sampai"
                      onChange={handleChange}
                      error={errors.sampai}
                      value={form.sampai}
                    />
                    <NewSubmitButton className="mt-2" widthFull onClose={handleCloseEdit}/>
                  </form>
                </div>
              </div>
            }
          </div>
        </div>
      </LayoutModal>
    </>
  );
}

export default AlumniPkk;