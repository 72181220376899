import React, { useEffect, useState, useCallback } from "react";
import { useRecoilValue } from "recoil";
import { fetchPengajaran } from "../../api";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import { getStatusPengajaran } from "../../helpers";
import { userState } from "../../storage/auth";
import PengajaranEditPengajaranDosen from "./EditPengajaranDosen";
import LoadingBasic from "../../components/pages/LoadingBasic";
import SmallButton from "../../components/forms/SmallButton";

const PengajaranList = ({
  pengajaran,
  kelas,
  tahunAjaran = "",
  semesterAjaran = "",
  onFetchData = false,
}) => {
  const user = useRecoilValue(userState);

  const [modal, setModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([]);

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    if (onFetchData) {
      onFetchData();
    }
    setItems([]);
    setModal(false);
  };

  const fetchData = useCallback(async () => {
    if (modal) {
      try {
        const res = await fetchPengajaran(
          "all",
          `kelas_id=${pengajaran.kelas_id}&tahun_ajaran=${tahunAjaran}&semester_ajaran=${semesterAjaran}`
        );
        if (res.data && res.data.pengajaran) {
          setIsLoading(true);
          setItems(res.data.pengajaran);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [modal, pengajaran.kelas_id, tahunAjaran, semesterAjaran]);

  useEffect(() => {
    fetchData();

    return () => {
      setItems([]);
    };
  }, [fetchData]);

  const [pengajaranToEdit, setPengajaranToEdit] = useState({});

  return (
    <>
      <SmallButton margin='me-1' type='show' onClick={handleOpen}/>
      <LayoutModal
        // title={`Data Pengajaran ${
        //   pengajaran.kelas ? pengajaran.kelas.kode : ""
        // } ${kelas ? kelas.kode : ""}`}
        title={`Data Pengajaran ${
          pengajaran.kelas ? pengajaran.kelas.kode : ""
        }`}
        modal={modal}
        onClose={handleClose}
      >
        {pengajaranToEdit.id ? (
          <PengajaranEditPengajaranDosen
            pengajaran={pengajaranToEdit}
            onFetchData={fetchData}
            onSetPengajaranToEdit={(res) => setPengajaranToEdit(res)}
          />
        ) : (
          <>
            {isLoading ? (
              <>
                {items && items.length > 0 ? (
                  <>
                    <LayoutTable>
                      <LayoutThead>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">No</th>
                        {user.group === "Akademik" && (
                          <th className="border-bottom border-silver fw-semibold text-nowrap">
                            <i className="fa fa-bars" />
                          </th>
                        )}
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Pertemuan</th>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Status</th>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Jumlah Mhs</th>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Mhs Hadir</th>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Dari</th>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Sampai</th>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Tanggal</th>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Ruangan</th>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Dosen</th>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Matkul</th>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Materi yang diberikan</th>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Tugas</th>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Hambatan</th>
                        <th className="border-bottom border-silver fw-semibold text-nowrap">Alasan Batal</th>
                      </LayoutThead>
                      <tbody>
                        {items.map((i, index) => {
                          return (
                            <tr key={index}>
                              <td className="py-1 border-silver align-middle text-center text-nowrap">
                                {index + 1}
                              </td>
                              {user.group === "Akademik" && (
                                <td className="py-1 border-silver align-middle text-center">
                                  {parseInt(i.status) !== 9 ? (
                                    <button
                                      type="button"
                                      className="btn btn-sm btn-primary-3 py-0 text-sm rounded text-nowrap"
                                      onClick={() => setPengajaranToEdit(i)}
                                    >
                                      <i className="fa fa-fw fa-sm fa-edit" /> Isi BAP dan
                                      Absensi
                                    </button>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                              )}
                              <td className="py-1 border-silver align-middle text-center text-nowrap">
                                {i.pertemuan !== "UTS" &&
                                  i.pertemuan !== "UAS" &&
                                  "Ke -"}{" "}
                                {i.pertemuan}
                              </td>
                              <td className="py-1 border-silver align-middle text-center text-nowrap">
                                {getStatusPengajaran(i, true)}
                              </td>
                              <td className="py-1 border-silver align-middle text-nowrap text-center">
                                {i.krs ? i.krs.length : "-"}
                              </td>
                              <td className="py-1 border-silver align-middle text-nowrap text-center">
                                <span
                                  className="bg-dark text-white text-sm rounded px-3"
                                >
                                  {/* {i.absensi && i.absensi.length > 0
                                ? i.absensi.filter((a) => {
                                    if (
                                      parseInt(a.status) === 1 &&
                                      parseInt(i.pertemuan) === parseInt(a.pertemuan)
                                    ) {
                                      if (i.krs && i.krs.length > 0) {
                                        if (
                                          i.krs.filter((k) => k.mhs_id === a.mhs_id)
                                            .length > 0
                                        ) {
                                          return a;
                                        }
                                      }
                                    }
                                    return false;
                                  }).length
                                : "-"} */}
                                  {i.absensi && i.absensi.length > 0
                                    ? i.absensi.filter(
                                        (a) =>
                                          parseInt(a.status) === 1 &&
                                          String(a.pertemuan) ===
                                            String(i.pertemuan)
                                      ).length
                                    : "-"}
                                </span>
                              </td>
                              <td className="py-1 border-silver align-middle text-center text-nowrap">
                                {i.dari}
                              </td>
                              <td className="py-1 border-silver align-middle text-center text-nowrap">
                                {i.sampai}
                              </td>
                              <td className="py-1 border-silver align-middle text-center text-nowrap">
                                {i.tanggal}
                              </td>
                              <td className="py-1 border-silver align-middle text-center text-nowrap">
                                {i.ruangan && i.ruangan.nama}
                              </td>
                              <td className="py-1 border-silver align-middle text-center text-nowrap">
                                {i.dosen && i.dosen.nama}
                              </td>
                              <td className="py-1 border-silver align-middle text-center text-nowrap">
                                {i.matkul && i.matkul.nama}
                              </td>
                              <td className="py-1 border-silver align-middle text-center text-nowrap">
                                {i.pembahasan}
                              </td>
                              <td className="py-1 border-silver align-middle text-center text-nowrap">
                                {i.tugas}
                              </td>
                              <td className="py-1 border-silver align-middle text-center text-nowrap">
                                {i.hambatan}
                              </td>
                              <td className="py-1 border-silver align-middle text-center text-nowrap text-danger">
                                {i.keterangan}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </LayoutTable>
                    <button
                      type="button"
                      className="btn btn-sm btn-dark rounded"
                      onClick={handleClose}
                    >
                      Kembali
                    </button>
                  </>
                ) : (
                  <PageNoData />
                )}
              </>
            ) : (
              <LoadingBasic />
            )}
          </>
        )}
      </LayoutModal>
    </>
  );
};

export default PengajaranList;
