import React, { useState } from 'react'
import LayoutModal, { NewSubmitButton } from '../../components/layouts/Modal';
import { LayoutAddBtn } from '../../components/layouts/TopContent';
import FormInput from '../../components/forms/Input';
import FormSelect from '../../components/forms/Select';
import SmallButton from '../../components/forms/SmallButton';
import Radio from '../../components/forms/Radio';
import { patchRkat, postRkat } from '../../api';
import { saAlert } from '../../helpers';
import { useRef } from 'react';

function RkatEdit(props) {

    const { rkat, jabatan, fetchData } = props;
    const tagForm = useRef(null);

    const [modal, setModal] = useState(false);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});

    const handleOpen = () => {
        setForm(rkat);
        setModal(true);
    }

    const handleClose = () => {
        fetchData();
        tagForm.current && tagForm.current.reset();
        setForm({});
        setModal(false);
    }

    const handleChange = (e) => {
        setForm((prevState) => {
            return { ...prevState, [e.target.name]: e.target.value };
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await patchRkat(form.id, form);
            if (res.data && res.data.msg) {
                saAlert("success", res.data.msg);
                handleClose();
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 422) {
                    setErrors(error.response.data.errors);
                }
            }
        }
    }

    return (
        <>
            <SmallButton margin="me-1" type="edit" text="Edit" onClick={handleOpen} />

            <LayoutModal
                size="md"
                title="Edit RKAT"
                modal={modal}
                onClose={handleClose}
            >
                <form ref={tagForm} onSubmit={handleSubmit}>
                    <div className='row'>
                        <div className=''>
                            <FormSelect
                                label="Tahun"
                                name="tahun"
                                value={form.tahun}
                                onChange={handleChange}
                                error={errors.tahun}
                            >
                                <option value="">Pilih</option>
                                <option value="2021/2022">2021/2022</option>
                                <option value="2022/2023">2022/2023</option>
                                <option value="2023/2024">2023/2024</option>
                                <option value="2024/2025">2024/2025</option>
                                <option value="2025/2026">2025/2026</option>
                                <option value="2026/2027">2026/2027</option>
                                <option value="2027/2028">2027/2028</option>
                                <option value="2029/2030">2029/2030</option>
                            </FormSelect>
                        </div>
                        <div className=''>
                            <FormSelect
                                label="Jabatan"
                                type="text"
                                name="jabatan_id"
                                value={form.jabatan_id}
                                onChange={handleChange}
                                error={errors.jabatan_id}
                            >
                                <option value="">Pilih</option>

                                {jabatan.map((j, index) => {
                                    return (
                                        <option key={index} value={j.id}>[{j.cabang && j.cabang.nama}] - {j.nama}</option>
                                    )
                                })}
                            </FormSelect>
                        </div>
                        <div className='my-2'>
                            <Radio inline={1} name="type" value="1" id="type1" onChange={handleChange} error={errors.type} checked={parseInt(form.type) === 1 ? true : false} label="Pendapatan"/>
                            <Radio inline={1} name="type" value="2" id="type2" onChange={handleChange} error={errors.type} checked={parseInt(form.type) === 2 ? true : false} label="Pengeluaran"/>
                        </div>
                    </div>
                    <div className='my-2'>
                        <NewSubmitButton widthFull onClose={handleClose} />
                    </div>
                </form>
            </LayoutModal>
        </>
    )
}

export default RkatEdit