import React, { useState, useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchPendaftarIntership, patchPendaftarIntership } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterAngkatan,
  FilterAngkatan,
  FilterCabang,
  FilterJenjang,
  FilterJurusan,
  FilterPagination,
  FilterSearch,
  FilterStatusInternship,
} from "../../components/layouts/TopContent";
import SectionPagination from "../../components/sections/Pagination";
import { saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import { angkatanState, titleState } from "../../storage/pages";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const PendaftarIntership = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const defAngkatan = useRecoilValue(angkatanState);

  useEffect(() => setTitle("Nilai Akhir"), [setTitle]);

  const [pendaftarIntership, setPendaftarIntership] = useState({});

  const [page, setPage] = useState(1);
  const [status, setStatus] = useState("");
  const [pagination, setPagination] = useState(10);
  const [search, setSearch] = useState("");
  const [angkatan, setAngkatan] = useState(defAngkatan);
  const [jenjang, setJenjang] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchPendaftarIntership(
        page,
        `only_daftar=1&pagination=${pagination}&search=${search}&angkatan=${angkatan}&jenjang=${jenjang}&jurusan=${jurusan}&cabang=${cabang}`
      );
      if (res.data && res.data.pendaftar_intership) {
        setPendaftarIntership(res.data.pendaftar_intership);
      }
    } catch (err) {
      console.error(err);
    }
  }, [page, pagination, search, angkatan, jenjang, jurusan, cabang]);

  useEffect(() => fetchData(), [fetchData]);

  return (
    <>
      {/* Filter 1 */}
      <div className="row mb-3">
        <div className="col-md-4">
          <FilterJurusan
            onChange={(res) => {
              setJurusan(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterJenjang
            onChange={(res) => {
              setJenjang(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterSearch
            onChange={(res) => {
              setSearch(res);
              setPage(1);
            }}
          />
        </div>
      </div>
      {/* Filter 2 */}
      <div className="row mb-3">
        <div className="col-md-4">
          <FilterStatusInternship
            value={status}
            onChange={(res) => {
              setStatus(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterAngkatan
            value={angkatan}
            onChange={(res) => {
              setAngkatan(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md-4">
          <FilterPagination
            onChange={(res) => {
              setPagination(res);
              setPage(1);
            }}
          />
        </div>
      </div>
      {/* Filter 3 */}
      {user.cabang === "pusat" && (
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterCabang
              onChange={(res) => {
                setCabang(res);
                setPage(1);
              }}
            />
          </div>
        </div>
      )}
      <div className="row">
        <div className="col-3">
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>Nim</Th>
              </Tr>
            </Thead>
            <Tbody>
              {pendaftarIntership.data &&
                pendaftarIntership.data.length > 0 &&
                pendaftarIntership.data.map((pi, index) => {
                  return (
                    <Tr key={index}>
                      <Td className="text-center">
                        {pendaftarIntership.from + index}
                      </Td>
                      <Td className="text-center">{pi.nim}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
        <div className="col-9">
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th className="text-nowrap">Nama</Th>
                <Th className="text-nowrap">Jenjang</Th>
                <Th><i className="fa fa-fw fa-cog"/></Th>
              </Tr>
            </Thead>
            <Tbody>
              {pendaftarIntership.data &&
                pendaftarIntership.data.length > 0 &&
                pendaftarIntership.data.map((pi, index) => {
                  return (
                    <Tr key={index}>
                      <Td className="text-nowrap">{pi.nama}</Td>
                      <Td className="text-nowrap text-center">
                        {pi.mhs && pi.mhs.jenjang}
                      </Td>
                      <Td className="text-center">
                        <Update pi={pi} onFetchData={fetchData} />
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
      </div>
      {pendaftarIntership.links && (
        <SectionPagination
          from={pendaftarIntership.from}
          to={pendaftarIntership.to}
          total={pendaftarIntership.total}
          links={pendaftarIntership.links}
          onChangePage={(res) => setPage(res)}
        />
      )}
    </>
  );
};

const Update = ({ pi, onFetchData }) => {
  const handleUpdate = (status) => {
    saConfirm(
      "warning",
      `Yakin ubah status menjadi ${status === 1 ? "Approve" : "Reject"} ?`
    ).then(async (res) => {
      if (res.isConfirmed) {
        try {
          const res = await patchPendaftarIntership(pi.id, { status });
          if (res.data && res.data.message && res.data.message === "success") {
            onFetchData();
            saAlert("success", "Berhasil update status !");
          }
        } catch (err) {
          console.error(err.response);
        }
      }
    });
  };

  return (
    <>
      <span
        type="button"
        className="badge badge-success"
        onClick={() => handleUpdate(1)}
      >
        Approve
      </span>
      <i className="px-1" />
      <span
        type="button"
        className="badge badge-danger"
        onClick={() => handleUpdate(0)}
      >
        Reject
      </span>
    </>
  );
};

export default PendaftarIntership;
