import React, { useRef, useState } from "react";
import { postBukuPerpustakaan } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import { saAlert } from "../../helpers";

const BukuPerpustakaanCreate = (props) => {
  const { jurusan, onFetchData } = props;
  const [modal, setModal] = useState(false);
  const [img, setImg] = useState("");
  const formTag = useRef();

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e, file = false) => {
    if (file) {
      setForm((prevState) => {
        return { ...prevState, [e.target.name]: e.target.files[0] };
      });
    } else {
      setForm((prevState) => {
        return { ...prevState, [e.target.name]: e.target.value };
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    let formData = new FormData();

    form.kode && formData.append("kode", form.kode);
    form.judul && formData.append("judul", form.judul);
    form.cover && formData.append("cover", form.cover);
    form.jurusan_id && formData.append("jurusan_id", form.jurusan_id);
    form.penerbit && formData.append("penerbit", form.penerbit);
    form.pengarang && formData.append("pengarang", form.pengarang);
    form.halaman && formData.append("halaman", form.halaman);

    try {
      const res = await postBukuPerpustakaan(formData);
      if (res.data === "success") {
        onFetchData();
        handleClose();
        saAlert("success", "Berhasil tambah fata buku perpustakaan !");
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  const handleClose = () => {
    if (formTag && formTag.current) {
      formTag.current.reset();
    }

    setForm({});
    setImg("");
    setErrors({});
    setModal(false);
  };

  return (
    <>
      <LayoutAddBtn add="Tambah Data" onClick={() => setModal(true)} />
      <LayoutModal
        modal={modal}
        title="Tambah Data Buku Perpustakaan"
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={formTag}>
          <div className="row">
            <div className="col-6 d-flex align-items-end">
              <div className="w-100">
                <FormInput
                  label="Kode"
                  name="kode"
                  onChange={(e) => handleChange(e)}
                  error={errors.kode}
                />
                {errors.kode}
              </div>
            </div>
            <div className="col-6">
              {img && (
                <img
                  src={URL.createObjectURL(img)}
                  alt=""
                  className="img-fluid"
                />
              )}
              <FormInput
                label="Cover"
                type="file"
                name="cover"
                onChange={(e) => {
                  handleChange(e, true);
                  setImg(e.target.files[0]);
                }}
                error={errors.cover}
              />
            </div>
            <div className="col-6">
              <FormInput
                label="Judul"
                name="judul"
                onChange={handleChange}
                error={errors.judul}
              />
            </div>
            <div className="col-6">
              <FormSelect
                label="Jurusan"
                name="jurusan_id"
                onChange={handleChange}
                error={errors.jurusan_id}
              >
                <option value="">Pilih</option>
                {jurusan &&
                  jurusan.length > 0 &&
                  jurusan.map((i, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={i.id}>{i.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col-6">
              <FormInput
                label="penerbit"
                name="penerbit"
                onChange={handleChange}
                error={errors.penerbit}
              />
            </div>
            <div className="col-6">
              <FormInput
                label="pengarang"
                name="pengarang"
                onChange={handleChange}
                error={errors.pengarang}
              />
            </div>
            <div className="col-6">
              <FormInput
                label="halaman"
                name="halaman"
                onChange={handleChange}
                error={errors.halaman}
              />
            </div>
          </div>
          <div className="row mt-3">
            <NewSubmitButton onClose={handleClose}/>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default BukuPerpustakaanCreate;
