import React from "react";
import { useRecoilValue } from "recoil";
import { userState } from "../../storage/auth";

const PrintGradeNilai = (props) => {
  const { items } = props;

  const user = useRecoilValue(userState);

  return (
    <div className="row mb-3 justify-content-end">
      <div className="col-6 p-3">
        <table className="table table-sm">
          <thead className="bg-light">
            <tr>
              <th colSpan="3">Keterangan</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>85 s/d 100</td>
              <td>:</td>
              <td>A (Sangat Memuaskan)</td>
            </tr>
            <tr>
              <td>75 s/d 84</td>
              <td>:</td>
              <td>B (Memuaskan)</td>
            </tr>
            <tr>
              <td>65 s/d 74</td>
              <td>:</td>
              <td>C (Cukup)</td>
            </tr>
            <tr>
              <td>45 s/d 64</td>
              <td>:</td>
              <td>D (Kurang Memuaskan)</td>
            </tr>
            <tr>
              <td>0 s/d 44</td>
              <td>:</td>
              <td>E (Sangat Kurang)</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-6 p-3">
        <table className="table table-sm">
          <thead className="bg-light">
            <tr>
              <th colSpan="3">Bobot Nilai</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Nilai Absensi</td>
              <td>:</td>
              <td>{items.persentasi_kehadiran}%</td>
            </tr>
            <tr>
              <td>Nilai Tugas</td>
              <td>:</td>
              <td>{items.persentasi_tugas}%</td>
            </tr>
            <tr>
              <td>UTS</td>
              <td>:</td>
              <td>{items.persentasi_uts}%</td>
            </tr>
            <tr>
              <td>UAS</td>
              <td>:</td>
              <td>{items.persentasi_uas}%</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col-6 p-3 text-center">
        <p className="mb-5 pb-5">
          <span style={{ textTransform: "capitalize" }}>{user.cabang}</span>,
          <span style={{ marginLeft: "11rem" }}></span>
          {/* ........................................... {items.tahun_ajaran} */}
        </p>
        <p className="m-0">{items.dosen && items.dosen.nama}</p>
        <p className="m-0">Ttd Dosen</p>
      </div>
    </div>
  );
};

export default PrintGradeNilai;
