import React from "react";
import { useRecoilValue } from "recoil";
import { patchPendaftar } from "../../api";
import { saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import PendaftarSetMhs from "./SetMhs";

const PendaftarUpdateStatus = (props) => {
  const user = useRecoilValue(userState);
  const { pendaftar, onFetchData } = props;

  const handleUpdate = (id, status, message) => {
    const runingIn = () => {
      patchPendaftar(id, { status }).then((res) => {
        if (res.data === "success") {
          onFetchData();
          if (user.nama !== "Ni Luh Evy Rahayu, S.Pd.") {
            saAlert("success", `Berhasil ubah status menjadi ${message}`);
          }
        }
      });
    };

    if (user.nama === "Ni Luh Evy Rahayu, S.Pd.") {
      runingIn();
    } else {
      saConfirm("success", `Yakin ubah status menjadi ${message} ?`).then(
        (res) => {
          if (res.isConfirmed) {
            runingIn();
          }
        }
      );
    }
  };

  return (
    <>
      {!pendaftar.status && (
        <button 
          className="btn btn-sm btn-success-2 border-0 py-0 text-sm rounded"
          onClick={() => handleUpdate(pendaftar.id, 1, "Sudah Daftar")}
        >
          <span><i className="fa fa-fw fa-sm fa-up-long"/> Sudah Daftar</span>
        </button>
      )}
      {parseInt(pendaftar.status) === 1 && (
        <button 
          className="btn btn-sm btn-info border-0 py-0 text-sm rounded"
          onClick={() => handleUpdate(pendaftar.id, 2, "Sudah DU")}
        >
          <span><i className="fa fa-fw fa-sm fa-up-long"/> Sudah DU</span>
        </button>
      )}
      {parseInt(pendaftar.status) === 2 && !pendaftar.afiliasi_id && (
        <PendaftarSetMhs mhs={pendaftar} onFetchData={onFetchData} />
      )}
      {parseInt(pendaftar.status) === 2 &&
        pendaftar.afiliasi_id &&
        "Cairkan data afiliator."}
      {parseInt(pendaftar.status) === 3 && pendaftar.afiliasi_id && (
        <PendaftarSetMhs mhs={pendaftar} onFetchData={onFetchData} />
      )}
      {parseInt(pendaftar.status) === 4 && "-"}
    </>
  );
};

export default PendaftarUpdateStatus;
