import React from "react";
import { SinggleMenu } from "./Sidebar";

const Kacab = (props) => {
  const { user } = props;

  return (
    <>
      {user.jabatan === "IT Programmer" ||
      user.jabatan === "Dir. Akademik & Kemahasiswaan" ||
      user.jabatan === "Dir. Pusat Karir dan Kerjasama" ||
      user.jabatan === "Dir. Humas & Pemasaran" ||
      user.jabatan === "Dir. Keu & Sumber Daya" ||
      user.jabatan === "Penjaminan Mutu" ||
      user.jabatan === "Kepala Cabang Denpasar" ||
      user.jabatan === "Kepala Cabang Singaraja" ||
      user.jabatan === "Kepala Cabang Karangasem" ? (
        <>
          <hr className="border-0 my-2" />

          <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
            Kacab
          </div>

          <hr className="sidebar-divider border-0 mt-2 mb-0" />

          <SinggleMenu
            title="Kritik & Saran"
            link="/ks"
            fa="fa-theater-masks"
          />
        </>
      ) : null}
    </>
  );
};

export default Kacab;
