import React, { createContext, useCallback, useState } from "react";
import { Route, Switch } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { titleState } from "../../storage/pages";
import { fetchPurchaseRequest } from "./apis";
import Edit from "./pages/Edit";
import Create from "./pages/Create";
import PurchaseRequest from "./pages/PurchaseRequest";
import Approval from "./pages/Approval";
import Show from "./pages/Show";
import Pusat from "./pages/Pusat";

export const PurchaseRequestContext = createContext();

const PurchaseRequestRoutes = () => {
  const setTitle = useSetRecoilState(titleState);
  const mainUrl = "/purchase-request";

  const [page, setPage] = useState(1);

  const [purchaseRequest, setPurchaseRequest] = useState({});

  const [isLoaded, setIsLoaded] = useState(false);

  const fetchData = useCallback(
    async (data = {}) => {
      const { pegawai_id = "", approval = "" } = data;
      setIsLoaded(false);
      try {
        const res = await fetchPurchaseRequest(
          page,
          `approval=${approval}&pegawai_id=${pegawai_id}`
        );
        if (res.data && res.data.purchase_request) {
          setPurchaseRequest(res.data.purchase_request);
          setTitle(res.data.title);
        }
        setIsLoaded(true);
      } catch (err) {
        console.error(err.response);
      }
    },
    [setTitle, page]
  );

  return (
    <PurchaseRequestContext.Provider
      value={{
        isLoaded,
        setIsLoaded,
        purchaseRequest,
        setPage,
      }}
    >
      <Switch>
        <Route exact path={mainUrl}>
          <PurchaseRequest mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
        <Route path={`${mainUrl}/create`}>
          <Create mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
        <Route path={`${mainUrl}/pusat`}>
          <Pusat mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
        <Route path={`${mainUrl}/:id/edit`}>
          <Edit mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
        <Route path={`${mainUrl}/approval`}>
          <Approval mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
        <Route path={`${mainUrl}/:id`}>
          <Show mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
      </Switch>
    </PurchaseRequestContext.Provider>
  );
};

export default PurchaseRequestRoutes;
