import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import {
  deleteProgkam,
  fetchBidang,
  fetchPegawai,
  fetchPenyelenggara,
  fetchProgkam,
} from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { FilterSearch } from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { saAlert, saConfirm } from "../../helpers";
import { titleState } from "../../storage/pages";
import ProgkamAddMhs from "./AddMhs";
import ProgkamCreate from "./Create";
import ProgkamEdit from "./Edit";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import SmallButton from "../../components/forms/SmallButton";

const ProgkamAkademik = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const setTitle = useSetRecoilState(titleState);

  useEffect(() => {
    setTitle("Data Program Kampus");
  }, [setTitle]);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchProgkam(page, `progkam=true&search=${search}`);
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page, search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Penyelenggara
  const [itemsPenyelenggara, setItemsPenyelenggara] = useState({});

  useEffect(() => {
    const fetchDataPenyelenggara = async () => {
      try {
        const res = await fetchPenyelenggara("all");
        setItemsPenyelenggara(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDataPenyelenggara();
  }, []);
  // End Penyelenggara

  // Bidang
  const [itemsBidang, setItemsBidang] = useState({});

  useEffect(() => {
    const fetchDataBidang = async () => {
      try {
        const res = await fetchBidang("all");
        setItemsBidang(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchDataBidang();
  }, []);
  // End Bidang

  // Pegawai
  const [itemsPegawai, setItemsPegawai] = useState({});

  useEffect(() => {
    const fetchDataPegawai = async () => {
      try {
        const res = await fetchPegawai("all");
        setItemsPegawai(res.data.pegawai);
      } catch (err) {
        console.log(err);
      }
    };

    let set = true;
    if (set) {
      fetchDataPegawai();
    }

    return () => (set = false);
  }, []);
  // End Pegawai

  // Delete
  const handleDelete = async (id) => {
    saConfirm("warning", "Yakin hapus data Program Kampus ?").then((res) => {
      if (res.isConfirmed) {
        deleteProgkam(id)
          .then((res) => {
            if (res.data && res.data === "success") {
              fetchData();
              saAlert("success", "Berhasil hapus data Program Kampus !");
            }
          })
          .catch((err) => console.error(err));
      }
    });
  };
  // End Delete

  if (isLoaded) {
    return (
      <>
        <ProgkamCreate
          penyelenggara={itemsPenyelenggara}
          bidang={itemsBidang}
          pegawai={itemsPegawai}
          onFetchData={fetchData}
        />
        <div className="row mb-2">
          <div className="col-6"></div>
          <div className="col-6">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {items.data && items.data.length > 0 ? (
          <TableNew>
            <Thead>
              <Tr className='text-center'>
                <Th className="align-middle">No</Th>
                <Th className="align-middle">Cabang</Th>
                <Th className="align-middle">Nomor Kegiatan</Th>
                <Th className="align-middle">Nama Kegiatan</Th>
                <Th className="align-middle">Penyelenggara</Th>
                <Th className="align-middle">Bidang</Th>
                <Th className="align-middle">Tanggal</Th>
                <Th className="align-middle">Tempat</Th>
                <Th className="align-middle">Pegawai</Th>
                <Th className="align-middle">Jumlah Mahasiswa</Th>
                <Th className="align-middle">
                  <i className="fa fa-bars"></i>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center align-middle">
                      {items.from + index}
                    </Td>
                    <Td className="text-center align-middle">{i.cabang}</Td>
                    <Td className="text-center align-middle">{i.nomor}</Td>
                    <Td className="align-middle">{i.nama}</Td>
                    <Td className="align-middle">
                      {i.penyelenggara && i.penyelenggara.nama}
                    </Td>
                    <Td className="align-middle">
                      {i.bidang && i.bidang.nama}
                    </Td>
                    <Td className="text-center align-middle text-nowrap">
                      {i.tanggal}
                    </Td>
                    <Td className="align-middle">{i.tempat}</Td>
                    <Td className="align-middle">
                      {i.pegawai && i.pegawai.nama}
                    </Td>
                    <Td className="text-center align-middle">
                      {i.mhs && i.mhs.length}
                    </Td>
                    <Td className="text-center align-middle text-nowrap">
                      <ProgkamEdit
                        progkam={i}
                        penyelenggara={itemsPenyelenggara}
                        bidang={itemsBidang}
                        pegawai={itemsPegawai}
                        onFetchData={fetchData}
                      />
                      <ProgkamAddMhs progkam={i} onFetchData={fetchData} />
                      <SmallButton type='delete' onClick={() => handleDelete(i.id)}/>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <PageNoData />
        )}

        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default ProgkamAkademik;
