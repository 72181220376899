import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchJurusan, fetchMhs, patchMhs } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import {
  defaultFilterAngkatan,
  FilterAngkatan,
  FilterCabang,
  FilterJenjang,
  FilterJurusan,
  FilterPagination,
  FilterSearch,
  FilterStatus,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import ShowHideDetail from "../../components/ShowHideDetail";
import { capitalizeFirstLetter, getJenjang, getOnOffMhs, getOrigin, saAlert } from "../../helpers";
import { userState } from "../../storage/auth";
import { angkatanState, titleState } from "../../storage/pages";
import ChangeNim from "./ChangeNim";
import MahasiswaNonaktifkan from "./Nonaktifkan";
import MhsResetPassword from "./ResetPassword";
import MhsTableDetail from "./TableDetail";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";

const MhsAkademik = () => {
  const setTitle = useSetRecoilState(titleState);
  const defAngkatan = useRecoilValue(angkatanState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState({});
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(10);
  const [search, setSearch] = useState("");
  const [angkatan, setAngkatan] = useState(defAngkatan);
  const [jenjang, setJenjang] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [status, setStatus] = useState("");
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchMhs(
        page,
        `pagination=${pagination}&search=${search}&angkatan=${angkatan}&jenjang=${jenjang}&jurusan=${jurusan}&status=${status}&cabang=${cabang}`
      );
      if (res.data && res.data.mhs) {
        setItems(res.data.mhs);
        setIsLoaded(true);
      }
    } catch (error) {}
  }, [page, pagination, search, angkatan, jenjang, jurusan, status, cabang]);

  useEffect(() => {
    setTitle("Data Mahasiswa");
    fetchData();
  }, [setTitle, fetchData]);

  // user state
  const user = useRecoilValue(userState);

  // get Jurusan
  const [getJurusan, setGetJurusan] = useState({});

  useEffect(() => {
    const fetchDataJurusan = async () => {
      try {
        const res = await fetchJurusan("all");
        setGetJurusan(res.data);
      } catch (error) {}
    };

    fetchDataJurusan();
  }, []);

  const [detail, setDetail] = useState(false);

  if (isLoaded) {
    return (
      <>
        {/* Filter 1 */}
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterJurusan
              onChange={(res) => {
                setJurusan(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterJenjang
              onChange={(res) => {
                setJenjang(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {/* Filter 2 */}
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterStatus
              onChange={(res) => {
                setStatus(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterAngkatan
              value={angkatan}
              onChange={(res) => {
                setAngkatan(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterPagination
              onChange={(res) => {
                setPagination(res);
                setPage(1);
              }}
            />
          </div>
        </div>

        {/* filter 3 */}
        {user.cabang === "pusat" && (
          <div className="row mb-3">
            <div className="col-md-4">
              <FilterCabang
                onChange={(res) => {
                  setCabang(res);
                  setPage(1);
                }}
              />
            </div>
          </div>
        )}

        <TextOnOffMhs/>
        {/* <h4 className="fw-semibold">
          <span className="text-danger">Merah nonaktif Akademik</span> ||{" "}
          <span className="text-warning">Kuning nonaktif Keuangan</span>
        </h4> */}
        <ShowHideDetail detail={detail} onClick={(res) => setDetail(res)} />
        {items.data.length >= 1 ? (
          detail ? (
            <MhsTableDetail mhs={items} />
          ) : (
            <div className="row">
              <div className="col-4 pe-0">
                <TableNew>
                  <Thead>
                    <Tr className="text-center">
                      <Th className="text-nowrap">No</Th>
                      <Th className="text-nowrap">NIM</Th>
                      <Th className="text-nowrap">Nama</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items.data.map((i, index) => {
                      return (
                        <Tr key={index} className={getOnOffMhs(i)}>
                          <Td className="bg-none text-center">{items.from + index}</Td>
                          <Td className="bg-none text-nowrap">{i.nim}</Td>
                          <Td className="bg-none text-nowrap">{i.nama}</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </TableNew>
              </div>
              <div className="col-6 px-0">
                <TableNew>
                  <Thead>
                    <Tr className="text-center text-nowrap">
                      <Th>Cabang</Th>
                      <Th>Tempat Lahir</Th>
                      <Th>Tanggal Lahir</Th>
                      <Th>Jenis Kelamin</Th>
                      <Th>Agama</Th>
                      <Th>Angkatan</Th>
                      <Th>Jenjang</Th>
                      <Th>Jurusan</Th>
                      <Th>Pilihan Waktu Belajar</Th>
                      <Th>Semester</Th>
                      <Th>Nomor KTP</Th>
                      <Th>Nama Ayah</Th>
                      <Th>Nama Ibu</Th>
                      <Th>Alamat Orang Tua</Th>
                      <Th>No HP Orang Tua</Th>
                      <Th>No HP</Th>
                      <Th>Email</Th>
                      <Th>Asal Sekolah</Th>
                      <Th>Tahun Tamat</Th>
                      <Th>Jurusan SMA</Th>
                      <Th>Informasi Dari</Th>
                      <Th>Pembimbing Kuliah</Th>
                      <Th>Tanggal Pendaftaran</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items.data.map((i, index) => {
                      return (
                        <Tr
                          key={index}
                          className={getOnOffMhs(i)}
                        >
                          <Td className="bg-none text-nowrap text-center text-capitalize">{i.cabang}</Td>
                          <Td className="bg-none text-nowrap">{i.tempat}</Td>
                          <Td className="bg-none text-nowrap text-center">{i.tanggal}</Td>
                          <Td className="bg-none text-nowrap text-center">{i.jenis}</Td>
                          <Td className="bg-none text-nowrap text-center">{i.agama}</Td>
                          <Td className="bg-none text-nowrap text-center">{i.angkatan}</Td>
                          <Td className="bg-none text-nowrap text-center">{getJenjang(i)}</Td>
                          <Td className="bg-none text-nowrap">{i.jurusan_ && i.jurusan_.nama}</Td>
                          <Td className="bg-none text-nowrap text-center">{i.waktu_belajar}</Td>
                          <Td className="bg-none text-nowrap text-center">{i.semester}</Td>
                          <Td className="bg-none text-nowrap text-center">{i.ktp}</Td>
                          <Td className="bg-none text-nowrap">{i.nama_ayah}</Td>
                          <Td className="bg-none text-nowrap">{i.nama_ibu}</Td>
                          <Td className="bg-none text-nowrap">{i.alamat_ortu}</Td>
                          <Td className="bg-none text-nowrap">{i.no_hp_ortu}</Td>
                          <Td className="bg-none text-nowrap">{i.no_hp}</Td>
                          <Td className="bg-none text-nowrap">{i.email}</Td>
                          <Td className="bg-none text-nowrap">{i.asal_sekolah}</Td>
                          <Td className="bg-none text-nowrap text-center">{i.tahun_tamat}</Td>
                          <Td className="bg-none text-nowrap">{i.jurusan_sma}</Td>
                          <Td className="bg-none text-nowrap">{i.informasi}</Td>
                          <Td className="bg-none text-nowrap text-center">-</Td>
                          <Td className="bg-none text-nowrap text-center">{i.tanggal_bergabung}</Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </TableNew>
              </div>
              <div className="col-2 ps-0">
                <TableNew copy={false}>
                  <Thead>
                    <Tr className="text-center">
                      <Th>Options</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items.data.map((i, index) => {
                      return (
                        <Tr key={index} className={getOnOffMhs(i)}>
                          <Td className="bg-none text-center text-nowrap">
                            {user.jabatan === "IT Programmer" && (
                              <MhsResetPassword
                                mhs={i}
                                onFetchData={fetchData}
                              />
                            )}
                            <EditMhs
                              user={user}
                              mhs={i}
                              jurusan={getJurusan}
                              onFetchData={fetchData}
                            />
                            <ChangeNim
                              user={user}
                              mhs={i}
                              jurusan={getJurusan}
                              onFetchData={fetchData}
                            />
                            <MahasiswaNonaktifkan
                              btnClassName={parseInt(i.status) === 9 ? "btn-danger-2" : "btn-success-2"}
                              btnText={parseInt(i.status) === 9 ? "Off" : "On"}
                              mhs={i}
                              onFetchData={fetchData}
                            />
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </TableNew>
              </div>
            </div>
          )
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          from={items.from}
          to={items.to}
          total={items.total}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

const EditMhs = (props) => {
  const [modal, setModal] = useState(false);
  const tagForm = useRef();

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    if (tagForm.current) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    patchMhs(form.id, form)
      .then((res) => {
        if (res.data === "success") {
          props.onFetchData();
          handleClose();
          saAlert("success", "Berhasil update data Mahasiswa !");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            setErrors(err.response.data.errors);
          }
        }
      });
  };

  return (
    <>
      <button
        onClick={() => {
          setForm(props.mhs);
          setModal(true);
        }}
        type="button"
        className="btn btn-sm btn-success-2 text-sm py-0 px-1 me-1"
      >
        <i className="fa fa-fw fa-sm fa-pen"/>
      </button>
      <LayoutModal
        modal={modal}
        onClose={handleClose}
        title="Data Mahasiswa Baru"
      >
        <form ref={tagForm} onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-3">
              <div className="d-flex justify-content-center mb-3">
                <img
                  src={`${getOrigin()}/storage/foto_mahasiswa/${form.cabang}/${
                    form.angkatan
                  }/${form.nim}.jpg`}
                  alt="Foto Mahasiswa"
                  className="img-fluid rounded-3"
                />
              </div>
            </div>
            <div className="col-lg-5">
              {props.user && props.user.jabatan === "IT Programmer" && (
                <FormInput
                  label="Status"
                  name="status"
                  value={form.status}
                  onChange={(e) => {
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    });
                  }}
                  error={errors.status}
                />
              )}
                <FormInput
                  label="Cabang"
                  value={form.cabang && capitalizeFirstLetter(form.cabang)}
                  readOnly={true}
                />
                <FormInput label="NIM" value={form.nim} readOnly={true} />
                <FormInput
                  label="Nama"
                  name="nama"
                  value={form.nama}
                  onChange={(e) => {
                    setForm((prevState) => {
                      return { ...prevState, [e.target.name]: e.target.value };
                    });
                  }}
                  error={errors.nama}
                />
                <FormInput
                  label="Tempat Lahir"
                  name="tempat"
                  value={form.tempat}
                  onChange={(e) => {
                    setForm((prevState) => {
                      return { ...prevState, [e.target.name]: e.target.value };
                    });
                  }}
                  error={errors.tempat}
                />
              </div>
            <div className="col-lg-4">
              <FormInput
                label="Tanggal Lahir"
                type="date"
                name="tanggal"
                value={form.tanggal}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.tanggal}
              />
              <FormSelect
                label="Jenis Kelamin"
                name="jenis"
                value={form.jenis}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.jenis}
              >
                <option value="Laki-laki">Laki-laki</option>
                <option value="Perempuan">Perempuan</option>
              </FormSelect>
              <FormSelect
                label="Agama"
                name="agama"
                value={form.agama}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.agama}
              >
                <option value="Islam">Islam</option>
                <option value="Katolik">Katolik</option>
                <option value="Protestan">Protestan</option>
                <option value="Hindu">Hindu</option>
                <option value="Budha">Budha</option>
              </FormSelect>
              <FormInput
                label="Angkatan"
                value={form.angkatan}
                readOnly={true}
              />
              <FormSelect
                label="Jenjang"
                name="jenjang"
                value={form.jenjang}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.jenjang}
                disabled={true}
              >
                <option value="D1">Program 1 Tahun</option>
                <option value="D2">Program 2 Tahun</option>
              </FormSelect>
            </div>
          </div>
          
          <div className="row">
            <div className="col-lg-4">
              {form.jurusan_id && (
                <FormSelect
                  label="Jurusan"
                  name="jurusan_id"
                  value={form.jurusan_id}
                  onChange={(e) => {
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    });
                  }}
                  error={errors.jurusan_id}
                  disabled={true}
                >
                  {props.jurusan &&
                    props.jurusan.length >= 1 &&
                    props.jurusan.map((i, index) => {
                      return (
                        <React.Fragment key={index}>
                          <option value={i.id}>{i.nama}</option>
                        </React.Fragment>
                      );
                    })}
                </FormSelect>
              )}
            </div>
            <div className="col-lg-4">
              <FormSelect
                label="Waktu Belajar"
                name="waktu_belajar"
                value={form.waktu_belajar}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.waktu_belajar}
              >
                <option value="Pagi">Pagi</option>
                <option value="Sore">Sore</option>
              </FormSelect>
            </div>
            <div className="col-lg-4">
              <FormInput
                label="Semester"
                value={form.semester}
                readOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <FormInput
                label="No HP"
                name="no_hp"
                value={form.no_hp}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.no_hp}
              />
            </div>
            <div className="col-lg-6">
              <FormInput
                label="No HP Ortu"
                name="no_hp_ortu"
                value={form.no_hp_ortu}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.no_hp_ortu}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <FormInput
                label="Nama Ayah"
                name="nama_ayah"
                value={form.nama_ayah}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.nama_ayah}
              />
            </div>
            <div className="col-lg-6">
              <FormInput
                label="Nama Ibu"
                name="nama_ibu"
                value={form.nama_ibu}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.nama_ibu}
              />
            </div>
          </div>
          <FormTextarea
            label="Alamat Ortu"
            name="alamat_ortu"
            value={form.alamat_ortu}
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.alamat_ortu}
          />
          <div className="row">
            <div className="col-lg-4">
              <FormInput label="Email" value={form.email} readOnly={true} />
            </div>
            <div className="col-lg-4">
              <FormInput label="Facebook" value={form.fb} readOnly={true} />
            </div>
            <div className="col-lg-4">
              <FormInput label="Instagram" value={form.ig} readOnly={true} />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <FormInput
                label="Asal Sekolah"
                value={form.asal_sekolah}
                readOnly={true}
              />
            </div>
            <div className="col-lg-4">
              <FormInput
                label="Tahun Tamat"
                value={form.tahun_tamat}
                readOnly={true}
              />
            </div>
            <div className="col-lg-4">
              <FormInput
                label="Jurusan SMA"
                value={form.jurusan_sma}
                readOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <FormInput
                label="Informasi"
                value={form.informasi}
                readOnly={true}
              />
            </div>
            <div className="col-lg-4">
              <FormInput
                label="Keputusan Kuliah"
                value={form.keputusan_kuliah}
                readOnly={true}
              />
            </div>
            <div className="col-lg-4">
              <FormInput
                label="Refrensi"
                value={form.refrensi}
                readOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4">
              <FormInput
                label="Tanggal Bergabung"
                value={form.tanggal_bergabung}
                readOnly={true}
              />
            </div>
            <div className="col-lg-4">
              <FormInput
                label="KDBB"
                value={form.kdbb === 1 ? "Iya" : "Tidak"}
                readOnly={true}
              />
            </div>
          </div>
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success-2">
              Simpan perubahaan
            </button>
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-sm btn-dark border-0"
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default MhsAkademik;
