import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  deleteMeninggalkanTugas,
  postMeninggalkanTugas,
  showMeninggalkanTugas,
} from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterBulan,
  defaultFilterTahun,
  FilterBulan,
  FilterTahun,
  LayoutAddBtn,
} from "../../components/layouts/TopContent";
import { formatDate, formatDiffDate, saAlert, saConfirm } from "../../helpers";
import { titleState } from "../../storage/pages";
import NoDataPage from "../../components/pages/NoData";
import LoadingPage from "../../components/pages/Loading";
import { loginMasterState, userState } from "../../storage/auth";
import LayoutModal from "../../components/layouts/Modal";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import FormRadio from "../../components/forms/Radio";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const MeninggalkanTugas = () => {
  const setTitle = useSetRecoilState(titleState);
  const loginMaster = useRecoilValue(loginMasterState);

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState();

  const [page] = useState("all");
  const [bulan, setBulan] = useState(defaultFilterBulan);
  const [tahun, setTahun] = useState(defaultFilterTahun);

  const fetchData = useCallback(async () => {
    try {
      const res = await showMeninggalkanTugas(
        page,
        `page=all&bulan=${bulan}&tahun=${tahun}`
      );
      console.log(res.data);
      if (res.data.meninggalkanTugas) {
        setItems(res.data.meninggalkanTugas);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err.response);
    }
  }, [page, bulan, tahun]);

  useEffect(() => {
    fetchData();
    setTitle("Lihat data meninggalkan tugas");
  }, [fetchData, setTitle]);

  if (isLoaded) {
    return (
      <>
        <CreateMeninggalkanTugas onFetchData={fetchData} />
        <div className="row mb-2">
          <div className="col-md">
            <FilterBulan onChange={(res) => setBulan(res)} />
          </div>
          <div className="col-md">
            <FilterTahun onChange={(res) => setTahun(res)} />
          </div>
        </div>

        {items && items.length >= 1 ? (
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>Jenis</Th>
                <Th>Dari</Th>
                <Th>Sampai</Th>
                <Th>Lama</Th>
                <Th>Pekerjaan</Th>
                <Th>Alasan</Th>
                <Th>Status</Th>
                <Th>Oleh</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center align-middle">{1 + index}</Td>
                    <Td className="text-nowrap text-center align-middle">
                      {parseInt(i.status_keterangan) === 1 && (
                        <span className="fw-semibold text-warning-2">Setengah Hari</span>
                      )}
                      {parseInt(i.status_keterangan) === 2 && (
                        <span className="fw-semibold text-success-2">Beberapa Jam</span>
                      )}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatDate(i.dari)}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatDate(i.sampai)}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatDiffDate(i.lama)}
                    </Td>
                    <Td className="align-middle">{i.pekerjaan}</Td>
                    <Td className="align-middle">{i.keterangan}</Td>
                    <Td className="text-center align-middle">
                      {i.status === null && (
                        <span className="fw-semibold text-dark">Menunggu...</span>
                      )}
                      {parseInt(i.status) === 1 && (
                        <span className="fw-semibold text-success-2">Disetujui</span>
                      )}
                      {parseInt(i.status) === 2 && (
                        <span className="fw-semibold text-danger-2">Ditolak</span>
                      )}
                    </Td>
                    <Td className="text-center align-middle">
                      {i.atasan && i.atasan.nama}
                    </Td>
                    <Td className="text-center align-middle">
                      {i.status === null ? (
                        <DeleteLembur id={i.id} onFetchData={fetchData} />
                      ) : (
                        <>
                          {loginMaster ? (
                            <DeleteLembur id={i.id} onFetchData={fetchData} />
                          ) : (
                            <i className="fa fa-fw fa-circle-check text-success-2"/>
                          )}
                        </>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <NoDataPage />
        )}
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

const DeleteLembur = (props) => {
  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus Meninggalkan Tugas ?").then((res) => {
      if (res.isConfirmed) {
        deleteMeninggalkanTugas(props.id).then((res) => {
          if (res.data && res.data.msg === "success") {
            props.onFetchData();
            saAlert("success", "Berhasil hapus data Meninggalkan Tugas !");
          }
        });
      }
    });
  };
  return (
    <button
      onClick={handleDelete}
      className="btn btn-sm btn-danger-2 py-0 px-1"
    >
      <i className="fa fa-fw fa-sm fa-trash-alt"/>
    </button>
  );
};

const CreateMeninggalkanTugas = (props) => {
  const user = useRecoilValue(userState);
  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const handleClose = () => {
    setForm({});
    setFormErrors({});
    tagForm.current.reset();
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postMeninggalkanTugas(form)
      .then((res) => {
        if (res.data && res.data.msg === "success") {
          props.onFetchData();
          setForm({});
          setFormErrors({});
          tagForm.current.reset();
          setModal(false);
          saAlert("success", "Berhasil tambahkan data meninggalkan tugas !");
        }
      })
      .catch((err) => {
        let res = err.response;
        if (res) {
          if (res.status === 422) {
            setFormErrors(res.data.errors);
          }
        }
      });
  };

  return (
    <>
      <LayoutAddBtn
        onClick={() => setModal(true)}
        add="Buat Meninggalkan Tugas Baru"
      />
      <LayoutModal
        title="Buat Meninggalkan Tugas Baru"
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
          <FormInput label="NIP" value={user.nip} readOnly={true} />
          <FormInput label="Nama" value={user.nama} readOnly={true} />
          <div className="row">
            <div className="col-md-4">
              <label className="font-weight-bold text-primary">Jenis</label>
              <br />
              <FormRadio
                inline={1}
                error={formErrors.status_keterangan}
                name="status_keterangan"
                value="1"
                id="status_keterangan1"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                label="Setengah Hari"
              />
              <FormRadio
                inline={1}
                error={formErrors.status_keterangan}
                name="status_keterangan"
                value="2"
                id="status_keterangan2"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                label="Beberapa Jam"
              />
            </div>
            <div className="col-md-4">
              <FormInput
                label="Dari"
                name="dari"
                type="datetime-local"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={formErrors.dari}
              />
            </div>
            <div className="col-md-4">
              <FormInput
                label="Sampai"
                name="sampai"
                type="datetime-local"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={formErrors.sampai}
              />
            </div>
          </div>
          {/* <FormTextarea
            label="Pekerjaan"
            name="pekerjaan"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={formErrors.pekerjaan}
          /> */}
          <FormTextarea
            label="Keterangan"
            name="keterangan"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={formErrors.keterangan}
          />
          <hr />
          <p className="text-center">
            Approval meninggalkan tugas oleh :{" "}
            {(() => {
              if (user.atasan1) {
                return user.atasan1.nama;
              } else {
                if (user.atasan2) {
                  return user.atasan2.nama;
                }
              }
            })()}
          </p>
          <hr />
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success">
              Ajukan
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-sm btn-secondary"
            >
              Tutup
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default MeninggalkanTugas;
