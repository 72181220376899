import React, { useRef, useState } from "react";
import FormCr from "../../components/forms/Cr";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import { useRecoilValue } from "recoil";
import { userState } from "../../storage/auth";
import { patchPengajaran, showPengajaran } from "../../api";
import { saAlert, saConfirm } from "../../helpers";
import PageLoading from "../../components/pages/Loading";
import SmallButton from "../../components/forms/SmallButton";

const PengajaranEdit = (props) => {
  const { pengajaran, ruangan, onFetchData } = props;
  const tagForm = useRef();
  const user = useRecoilValue(userState);
  const [modal, setModal] = useState(false);

  const [items, setItems] = useState({});
  const [form, setForm] = useState({});

  const fetchData = async () => {
    try {
      const res = await showPengajaran(pengajaran.id);
      setItems(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpen = () => {
    fetchData();
    setModal(true);
  };

  const handleClose = (closed = true) => {
    setForm({});
    tagForm && tagForm.current && tagForm.current.reset();
    if (closed) {
      setForm({});
      setModal(false);
    } else {
      setForm({});
    }
  };

  const handleSubmit = () => {
    patchPengajaran(pengajaran.id, form)
      .then((res) => {
        if (res.data === "success") {
          onFetchData();
          handleClose(false);
          saAlert("success", "Berhasil ubah status !");
        } else if (res.data === "fail") {
          onFetchData();
          handleClose(false);
          saAlert(
            "warning",
            "Gagal ubah data !",
            "Ini bisa diakibatkan ada Pertemuan yang sama pada pengajaran ini."
          );
        }
      })
      .catch((err) => console.log(err));
  };

  const [alasanBatal, setAlasanBatal] = useState("");

  const handleBatalMulai = (status) => {
    saConfirm(
      "warning",
      `Yakin ${status === 9 ? "batalkan" : "mulai"} kelas ?`
    ).then((res) => {
      if (res.isConfirmed) {
        patchPengajaran(pengajaran.id, {
          is_batal: true,
          status,
          alasan_batal: alasanBatal,
        })
          .then((res) => {
            if (res.data === "success") {
              onFetchData();
              handleClose(false);
              saAlert(
                "success",
                `Berhasil ${status === 9 ? "batalkan" : "mulai"} kelas.`
              );
            } else if (res.data === "fail") {
              onFetchData();
              handleClose(false);
              saAlert(
                "warning",
                "Gagal ubah data !",
                "Ini bisa diakibatkan ada Pertemuan yang sama pada pengajaran ini."
              );
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  return (
    <>
      {ruangan && (
        <SmallButton
          margin='me-1'
          type='edit'
          onClick={() => {
            handleOpen();
          }}
        />
      )}
      <LayoutModal
        title={`Kelas ${items.kelas && items.kelas.kode}`}
        modal={modal}
        onClose={handleClose}
      >
        {items && items.id ? (
          <form onSubmit={(e) => e.preventDefault()} ref={tagForm}>
            <div className="text-center mb-4">
              <h3 className="text-black fw-semibold">Aktivitas Pengajaran</h3>
              <span className="text-black">Lembaga Pendidikan Manajemen & Informatika Alfa Prima</span>
            </div>
            <h5 className="text-black fw-semibold border-bottom border-dark pb-2">Info MK</h5>
            <hr className="my-0" />
            <div className="row">
              <div className="col-lg-6">
                <table className="table table-sm table-borderless">
                  <tbody>
                    <tr>
                      <td>Kode</td>
                      <td>:</td>
                      <td>{items.kelas && items.kelas.kode}</td>
                    </tr>
                    <tr>
                      <td>Mata Kuliah</td>
                      <td>:</td>
                      <td>{items.matkul && items.matkul.nama}</td>
                    </tr>
                    <tr>
                      <td>Dosen/Instruktur</td>
                      <td>:</td>
                      <td>{items.dosen && items.dosen.nama}</td>
                    </tr>
                    <tr>
                      <td>Tanggal</td>
                      <td>:</td>
                      <td>{items.tanggal}</td>
                    </tr>
                    <tr>
                      <td>Pertemuan</td>
                      <td>:</td>
                      <td>
                        <FormSelect
                          divClassName="mb-0 w-100"
                          name="pertemuan"
                          value={form.pertemuan ? form.pertemuan : items.pertemuan}
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                          disabled={user.group === "Akademik" ? false : true}
                        >
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="UTS">UTS</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="UAS">UAS</option>
                        </FormSelect>
                      </td>
                    </tr>
                    <tr>
                      <td>Ruangan</td>
                      <td>:</td>
                      <td>
                        <FormSelect
                          divClassName="mb-0 w-100"
                          name="ruangan_id"
                          value={
                            form.ruangan_id ? form.ruangan_id : items.ruangan_id
                          }
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                          disabled={user.group === "Akademik" ? false : true}
                        >
                          {ruangan &&
                            ruangan.length > 0 &&
                            ruangan.map((i, index) => {
                              return (
                                <React.Fragment key={index}>
                                  <option value={i.id}>{i.nama}</option>
                                </React.Fragment>
                              );
                            })}
                        </FormSelect>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-6">
              <table className="table table-sm table-borderless">
                  <tbody>
                    <tr>
                      <td>Status Aktivitas</td>
                      <td>:</td>
                      <td className="fw-semibold">
                        {parseInt(items.status) === 1 && <span>Created</span>}
                        {parseInt(items.status) === 2 && (
                          <span className="text-warning">Waiting...</span>
                        )}
                        {parseInt(items.status) === 3 && (
                          <span className="text-primary">Ready</span>
                        )}
                        {parseInt(items.status) === 4 && (
                          <span className="text-info">Running...</span>
                        )}
                        {parseInt(items.status) === 5 && (
                          <span className="text-success">Success</span>
                        )}
                        {parseInt(items.status) === 9 && (
                          <span className="text-danger">
                            Cancelled = {items.keterangan}
                          </span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>Dari (WITA)</td>
                      <td>:</td>
                      <td>
                        <FormInput
                          marginBottom="mb-0"
                          type="time"
                          name="dari"
                          value={items.dari}
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                          readOnly={user.group === "Akademik" ? false : true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Sampai (WITA)</td>
                      <td>:</td>
                      <td>
                        <FormInput
                          marginBottom="mb-0"
                          type="time"
                          name="sampai"
                          value={items.sampai}
                          onChange={(e) =>
                            setForm((prevState) => {
                              return {
                                ...prevState,
                                [e.target.name]: e.target.value,
                              };
                            })
                          }
                          readOnly={user.group === "Akademik" ? false : true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Jumlah Mahasiswa</td>
                      <td>:</td>
                      <td>{items.krs && items.krs.length}</td>
                    </tr>
                    {(user.group === "Akademik" || user.group === "Kacab") &&
                      <tr>
                        <td>Pengaturan</td>
                        <td>:</td>
                        <td>
                          {(user.group === "Akademik" || user.group === "Kacab") && parseInt(items.status) === 9 ? (
                            <SmallButton onClick={() => handleBatalMulai(1)} type="custom" buttonColor="success-2" icon="arrow-right" text="Mulai kelas"/>
                          ) : (
                            <>
                              <FormInput
                                placeholder="Alasan batal . . . "
                                onChange={(e) => setAlasanBatal(e.target.value)}
                                readOnly={(user.group === "Akademik" || user.group === "Kacab") ? false : true}
                              />
                              {(user.group === "Akademik" || user.group === "Kacab") ? (
                                <SmallButton onClick={() => handleBatalMulai(9)} type="delete" text="Batalkan"/>
                              ) : (
                                ''
                              )}
                            </>
                          )}
                        </td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
            {user.group === "Akademik"
              ? user.group === "Kacab" && (
                  <div className="my-2 bg-light">
                    <p className="text-danger m-0 p-0">
                      Tekan tombol Update Data apabila anda mengubah Pertemuan,
                      Ruangan, Dari, atau Sampai
                    </p>
                    <button
                      className="btn btn-sm btn-success"
                      onClick={handleSubmit}
                    >
                      Update Data
                    </button>
                  </div>
                )
              : ""}
            <h5 className="text-black fw-semibold border-bottom border-dark pb-2">Persiapan</h5>
            <div className="row mt-0">
              <div className="col-lg-6">
                <div className="p-3">
                  <h5 className="mb-0 text-black">Akademik</h5>
                  <p className="text-dark">Permintaan pertemuan sebelumnya atau persiapan lain :</p>
                  <FormTextarea
                    name="catatan_akademik"
                    placeholder="Catatan Akademik . . ."
                    row={18}
                    value={items.catatan_akademik}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    readOnly={user.group === "Akademik" ? false : true}
                  />
                  {user.group === "Akademik" && parseInt(items.status) !== 9 && (
                    <button
                      className="mb-2 btn btn-sm btn-success-2"
                      onClick={handleSubmit}
                    >
                      Konfirmasi Akademik
                    </button>
                  )}
                  {items.akademik_at && (
                    <p className="text-black">
                      Last update : {items.akademik_at} WITA <br />
                      By {items.akademik && items.akademik.nama}
                    </p>
                  )}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="p-3">
                  <h5 className="mb-3 text-black">UPT</h5>
                  <FormCr
                    label="Kesiapan dan Kerapian Ruangan"
                    name="kesiapan_ruangan"
                    type="radio"
                    inline={true}
                    defaultValue={items.kesiapan_ruangan}
                    option={[
                      {
                        name: "Yes",
                        value: 1,
                      },
                      {
                        name: "No",
                        value: 0,
                      },
                    ]}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    disabled={user.group === "SDM" ? false : true}
                  />
                  <FormCr
                    label="Kebersihan"
                    name="kebersihan_ruangan"
                    type="radio"
                    inline={true}
                    defaultValue={items.kebersihan_ruangan}
                    option={[
                      {
                        name: "Yes",
                        value: 1,
                      },
                      {
                        name: "No",
                        value: 0,
                      },
                    ]}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    disabled={user.group === "SDM" ? false : true}
                  />
                  <FormCr
                    label="Spidol & Penghapus"
                    name="spidol_ruangan"
                    type="radio"
                    inline={true}
                    defaultValue={items.spidol_ruangan}
                    option={[
                      {
                        name: "Yes",
                        value: 1,
                      },
                      {
                        name: "No",
                        value: 0,
                      },
                    ]}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    disabled={user.group === "SDM" ? false : true}
                  />
                  <FormCr
                    label="Projector Screen"
                    name="projector_ruangan"
                    type="radio"
                    inline={true}
                    defaultValue={items.projector_ruangan}
                    option={[
                      {
                        name: "Yes",
                        value: 1,
                      },
                      {
                        name: "No",
                        value: 0,
                      },
                    ]}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    disabled={user.group === "SDM" ? false : true}
                  />
                  <FormCr
                    label="Lampu & Pendingin Ruangan"
                    name="lampu_ruangan"
                    type="radio"
                    inline={true}
                    defaultValue={items.lampu_ruangan}
                    option={[
                      {
                        name: "Yes",
                        value: 1,
                      },
                      {
                        name: "No",
                        value: 0,
                      },
                    ]}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    disabled={user.group === "SDM" ? false : true}
                  />
                  <FormCr
                    label="Request Akademik"
                    name="req_ruangan"
                    type="radio"
                    inline={true}
                    defaultValue={items.req_ruangan}
                    option={[
                      {
                        name: "Yes",
                        value: 1,
                      },
                      {
                        name: "No",
                        value: 0,
                      },
                    ]}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                    disabled={user.group === "SDM" ? false : true}
                  />
                  {user.group === "SDM" && items.status !== 9 && (
                    <button
                      className="btn btn-sm btn-success"
                      onClick={handleSubmit}
                    >
                      Konfirmasi UPT
                    </button>
                  )}

                  {items.upt_at && (
                    <p>
                      Last update : {items.upt_at} WITA <br />
                      By {items.upt && items.upt.nama}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </form>
        ) : (
          <PageLoading />
        )}
      </LayoutModal>
    </>
  );
};

export default PengajaranEdit;
