import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { fetchPengajaran } from "../../../api";

const TopbarDropdownBap = () => {
  const [pengajaran, setPengajaran] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchPengajaran(
        "all",
        "return_arr=1&notification_akademik=1&length=1"
      );
      if (res.data) {
        setPengajaran(res.data.pengajaran);
      }
    } catch (err) {
      console.error(err.response);
    }
  }, []);

  useEffect(() => fetchData(), [fetchData]);

  return (
    <li className="nav-item no-arrow">
      <Link className="nav-link btn btn-sm btn-primary-4 rounded-3 py-1 mx-1 mb-2" to="/pengajaran-bap-kurang">
        <i className="fa fa-b text-warning me-0 me-sm-1" />
        <span className="d-none d-sm-inline">BAP</span>
        <span className="ms-1 badge badge-danger fw-normal">{Number(pengajaran) ? pengajaran : ""}</span>
      </Link>
    </li>
  );
};

export default TopbarDropdownBap;
