import React from "react";
import { fetchingData } from "../../../api";

// cabang
export const FilterJenis = (props) => {
  const { onChange } = props;

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">Pilih Jenis</label>
      <select
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
      >
        <option value="">Semua</option>
        <option value="1">Monitoring</option>
        <option value="2">AMI</option>
      </select>
    </div>
  );
};
