import React, { useState } from 'react'
import SmallButton from '../../components/forms/SmallButton'
import LayoutModal from '../../components/layouts/Modal'
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../components/layouts/TableNew';
import { formatDate, getCurrency, saConfirm, saToast } from '../../helpers';
import { patchArusKas } from '../../api';
import angkaTerbilangJs from '@develoka/angka-terbilang-js';

function KasHarian(props) {

    const {fetchdata, arusKas} = props;

    const [modal, setModal] = useState(false);
    const [unsave, setUnsave] = useState(false);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});

    const handleOpen = () => {
        setModal(true);
        setForm(arusKas);
    }

    const handleClose = () => {
        setModal(false);
        setForm({});
        setErrors({});
        fetchdata();

        // if (unsave) {
        //     saConfirm("warning", "Anda belum melakukan save", "Yakin keluar tanpa menyimpan data?").then((res) => {
        //         if (res.isConfirmed) {
        //             setModal(false);
        //             setForm({});
        //             setErrors({});
        //         }
        //     })
        // } else {
        //     setModal(false);
        //     setForm({});
        //     setErrors({});
        // }
    }

    const handleChange = (e) => {
        setForm((prevState) => ({...prevState,[e.target.name]:e.target.value}));
        setUnsave(true);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors({});
        try {
          const res = await patchArusKas(form.id, form);
          console.log(res.data);
          saToast(res.data.status, res.data.msg);
          setUnsave(false);
          fetchdata();
        } catch (error) {
          setErrors(error.response && error.response.data && error.response.data.errors);
        }
    }

    const onEnterSubmit = (e) => {
        if (e.key === "Enter") {
            handleSubmit(e);
        }
    }

  return (
    <>
        <SmallButton onClick={handleOpen} margin='me-1' type='custom' icon='plus' buttonColor='primary-2' text='Laporan Kas Harian'/>

        <LayoutModal
            modal={modal}
            title='Laporan Kas harian'
            onClose={handleClose}
        >
            <div className='bg-gradient-primary border-bottom-warning rounded-4 p-3 mb-3'>
                <h4 className='mb-0 text-center text-white fw-semibold'>LAPORAN KAS HARIAN</h4>
                <p className='mb-0 fs-6 text-gray-400 text-center'>Pada tanggal : {form.tanggal}</p>
            </div>

            {/* <table className='mb-3'>
                <tbody>
                    <tr>
                        <td>Saldo kas sebesar</td>
                        <td className='px-2'>:</td>
                        <td className='text-black'>Rp 15.750.500</td>
                    </tr>
                    <tr>
                        <td>Terbilang</td>
                        <td className='px-2'>:</td>
                        <td className='text-black'>{angkaTerbilangJs(15750500)}</td>
                    </tr>
                </tbody>
            </table> */}

            <TableNew hover={false} striped={false}>
                <Thead>
                    <Tr className='text-center'>
                        <Th>No</Th>
                        <Th>Keterangan</Th>
                        <Th>Pecahan</Th>
                        <Th>Jumlah <span className='text-gray-400 text-sm'>( Klik kolom untuk ubah )</span></Th>
                        <Th>Total</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td className='text-center align-middle' rowSpan={7}>I</Td>
                        <Td className='text-center align-middle fw-semibold' rowSpan={7}><i className='fa fa-fw fa-money-bill-wave'/><br />UANG<br />KERTAS</Td>
                        <Td className='text-center'>100.000</Td>
                        <Td className='p-0'>
                            <input type="text" onBlur={handleSubmit} onKeyUp={onEnterSubmit} onChange={handleChange} name='seratus_ribu_kertas' value={form.seratus_ribu_kertas ? form.seratus_ribu_kertas : ''} className='w-100 border-0 text-center p-0 m-0' placeholder='ketik disini...'/>
                        </Td>
                        <Td className='text-end'>Rp. {getCurrency(form.seratus_ribu_kertas ? form.seratus_ribu_kertas * 100000 : 0)}</Td>
                    </Tr>
                    <Tr>
                        <Td className='text-center'>50.000</Td>
                        <Td className='p-0'>
                            <input type="text" onBlur={handleSubmit} onKeyUp={onEnterSubmit} onChange={handleChange} name='lima_puluh_ribu_kertas' value={form.lima_puluh_ribu_kertas ? form.lima_puluh_ribu_kertas : ''} className='w-100 border-0 text-center p-0 m-0' placeholder='ketik disini...'/>
                        </Td>
                        <Td className='text-end'>Rp. {getCurrency(form.lima_puluh_ribu_kertas ? form.lima_puluh_ribu_kertas * 50000 : 0)}</Td>
                    </Tr>
                    <Tr>
                        <Td className='text-center'>20.000</Td>
                        <Td className='p-0'>
                            <input type="text" onBlur={handleSubmit} onKeyUp={onEnterSubmit} onChange={handleChange} name='dua_puluh_ribu_kertas' value={form.dua_puluh_ribu_kertas ? form.dua_puluh_ribu_kertas : ''} className='w-100 border-0 text-center p-0 m-0' placeholder='ketik disini...'/>
                        </Td>
                        <Td className='text-end'>Rp. {getCurrency(form.dua_puluh_ribu_kertas ? form.dua_puluh_ribu_kertas * 20000 : 0)}</Td>
                    </Tr>
                    <Tr>
                        <Td className='text-center'>10.000</Td>
                        <Td className='p-0'>
                            <input type="text" onBlur={handleSubmit} onKeyUp={onEnterSubmit} onChange={handleChange} name='sepuluh_ribu_kertas' value={form.sepuluh_ribu_kertas ? form.sepuluh_ribu_kertas : ''} className='w-100 border-0 text-center p-0 m-0' placeholder='ketik disini...'/>
                        </Td>
                        <Td className='text-end'>Rp. {getCurrency(form.sepuluh_ribu_kertas ? form.sepuluh_ribu_kertas * 10000 : 0)}</Td>
                    </Tr>
                    <Tr>
                        <Td className='text-center'>5.000</Td>
                        <Td className='p-0'>
                            <input type="text" onBlur={handleSubmit} onKeyUp={onEnterSubmit} onChange={handleChange} name='lima_ribu_kertas' value={form.lima_ribu_kertas ? form.lima_ribu_kertas : ''} className='w-100 border-0 text-center p-0 m-0' placeholder='ketik disini...'/>
                        </Td>
                        <Td className='text-end'>Rp. {getCurrency(form.lima_ribu_kertas ? form.lima_ribu_kertas * 5000 : 0)}</Td>
                    </Tr>
                    <Tr>
                        <Td className='text-center'>2.000</Td>
                        <Td className='p-0'>
                            <input type="text" onBlur={handleSubmit} onKeyUp={onEnterSubmit} onChange={handleChange} name='dua_ribu_kertas' value={form.dua_ribu_kertas ? form.dua_ribu_kertas : ''} className='w-100 border-0 text-center p-0 m-0' placeholder='ketik disini...'/>
                        </Td>
                        <Td className='text-end'>Rp. {getCurrency(form.dua_ribu_kertas ? form.dua_ribu_kertas * 2000 : 0)}</Td>
                    </Tr>
                    <Tr>
                        <Td className='text-center'>1.000</Td>
                        <Td className='p-0'>
                            <input type="text" onBlur={handleSubmit} onKeyUp={onEnterSubmit} onChange={handleChange} name='seribu_kertas' value={form.seribu_kertas ? form.seribu_kertas : ''} className='w-100 border-0 text-center p-0 m-0' placeholder='ketik disini...'/>
                        </Td>
                        <Td className='text-end'>Rp. {getCurrency(form.seribu_kertas ? form.seribu_kertas * 1000 : 0)}</Td>
                    </Tr>
                    <Tr>
                        <Td className='text-center align-middle' rowSpan={4}>II</Td>
                        <Td className='text-center align-middle fw-semibold' rowSpan={4}><i className='fa fa-fw fa-coins'/><br />UANG<br />LOGAM</Td>
                        <Td className='text-center'>1.000</Td>
                        <Td className='p-0'>
                            <input type="text" onBlur={handleSubmit} onKeyUp={onEnterSubmit} onChange={handleChange} name='seribu' value={form.seribu ? form.seribu : ''} className='w-100 border-0 text-center p-0 m-0' placeholder='ketik disini...'/>
                        </Td>
                        <Td className='text-end'>Rp. {getCurrency(form.seribu ? form.seribu * 1000 : 0)}</Td>
                    </Tr>
                    <Tr>
                        <Td className='text-center'>500</Td>
                        <Td className='p-0'>
                            <input type="text" onBlur={handleSubmit} onKeyUp={onEnterSubmit} onChange={handleChange} name='lima_ratus' value={form.lima_ratus ? form.lima_ratus : ''} className='w-100 border-0 text-center p-0 m-0' placeholder='ketik disini...'/>
                        </Td>
                        <Td className='text-end'>Rp. {getCurrency(form.lima_ratus ? form.lima_ratus * 500 : 0)}</Td>
                    </Tr>
                    <Tr>
                        <Td className='text-center'>200</Td>
                        <Td className='p-0'>
                            <input type="text" onBlur={handleSubmit} onKeyUp={onEnterSubmit} onChange={handleChange} name='dua_ratus' value={form.dua_ratus ? form.dua_ratus : ''} className='w-100 border-0 text-center p-0 m-0' placeholder='ketik disini...'/>
                        </Td>
                        <Td className='text-end'>Rp. {getCurrency(form.dua_ratus ? form.dua_ratus * 200 : 0)}</Td>
                    </Tr>
                    <Tr>
                        <Td className='text-center'>100</Td>
                        <Td className='p-0'>
                            <input type="text" onBlur={handleSubmit} onKeyUp={onEnterSubmit} onChange={handleChange} name='seratus' value={form.seratus ? form.seratus : ''} className='w-100 border-0 text-center p-0 m-0' placeholder='ketik disini...'/>
                        </Td>
                        <Td className='text-end'>Rp. {getCurrency(form.seratus ? form.seratus * 100 : 0)}</Td>
                    </Tr>
                </Tbody>
            </TableNew>
        </LayoutModal>
    </>
  )
}

export default KasHarian