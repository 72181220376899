import { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { fetchPendaftar, patchPendaftar } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterPagination,
  FilterSearch,
  FilterStatusPendaftar,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import ShowHideDetail from "../../components/ShowHideDetail";
import { saConfirm } from "../../helpers";
import { titleState } from "../../storage/pages";
import PendaftarEdit from "./Edit";
import PendaftarTableDetail from "./TableDetail";
import PendaftarUpdateKdbb from "./UpdateKdbb";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const PendaftarHp = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [pagination, setPagination] = useState(10);

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchPendaftar(
        page,
        `search=${search}&status=${status}&pagination=${pagination}`
      );
      if (res.data) {
        setItems(res.data.pendaftar);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [page, search, status, pagination]);

  useEffect(() => {
    setTitle("Data Pendaftar");
    fetchData();
  }, [setTitle, fetchData]);

  const [detail, setDetail] = useState(false);

  return (
    <>
      <div className="row mb-3">
        <div className="col-md">
          <FilterStatusPendaftar
            onChange={(res) => {
              setStatus(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md">
          <FilterPagination
            onChange={(res) => {
              setPagination(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-md">
          <FilterSearch
            onChange={(res) => {
              setSearch(res);
              setPage(1);
            }}
          />
        </div>
      </div>

      <ShowHideDetail detail={detail} onClick={(res) => setDetail(res)} />

      {!isLoaded ? (
        <PageLoading />
      ) : items.data.length >= 1 ? (
        detail ? (
          <PendaftarTableDetail pendaftar={items} />
        ) : (
          <div className="row">
            <div className="col-3 pe-0">
              <TableNew>
                <Thead>
                  <Tr className="text-center">
                    <Th>No</Th>
                    <Th>Nama</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-center text-nowrap">
                          {items.from + index}
                        </Td>
                        <Td className="text-nowrap">
                          <span 
                          className={`d-block ${
                            i.biaya && i.biaya.status
                              ? "bg-success text-white rounded-pill py-0 ps-1 pe-2"
                              : ""
                          }`}
                          // style={{padding: i.biaya && i.biaya.status && '0px 2px'}}
                          >
                            {
                              i.biaya && i.biaya.status
                                ? <i className="fa fa-fw fa-sm fa-circle-check me-1"/>
                                : ""
                            }
                            {i.nama}
                          </span>
                        </Td>
                        {/* <Td
                          className={`text-nowrap ${
                            i.biaya && i.biaya.status
                              ? "bg-success text-white"
                              : ""
                          }`}
                        >
                          {i.nama}
                        </Td> */}
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-6 px-0">
              <TableNew>
                <Thead>
                  <Tr className="text-center">
                    <Th className="text-nowrap">Cabang</Th>
                    <Th className="text-nowrap">Afiliasi</Th>
                    <Th className="text-nowrap">Status Afiliasi</Th>
                    <Th className="text-nowrap">HP</Th>
                    <Th className="text-nowrap">KDBB</Th>
                    <Th className="text-nowrap">Tanggal Daftar</Th>
                    <Th className="text-nowrap">Tanggal Update</Th>
                    <Th className="text-nowrap">Last Update By</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {i.cabang}
                        </Td>
                        <Td className="text-center text-nowrap">
                          {i.afiliasi_id ? (
                            <span className="text-success">
                              {parseInt(i.status) === 2
                                ? i.afiliasi_id && (
                                    <BtnCetakInvoice
                                      pendaftar={i}
                                      onFetchData={fetchData}
                                    />
                                  )
                                : i.afiliasi_id}
                            </span>
                          ) : (
                            "-"
                          )}
                        </Td>
                        <Td className="text-center text-nowrap">
                          {i.afiliasi ? (
                            <>
                              {parseInt(i.afiliasi.status) === 1 && (
                                <span>Afiliasi</span>
                              )}
                              {parseInt(i.afiliasi.status) === 17 && (
                                <span>Mahasiswa</span>
                              )}
                              {parseInt(i.afiliasi.status) === 18 && (
                                <span>Alumni</span>
                              )}
                              {parseInt(i.afiliasi.status) === 19 && (
                                <span>Guru</span>
                              )}
                            </>
                          ) : (
                            "-"
                          )}
                        </Td>
                        <Td className="text-center text-nowrap">{i.no_hp}</Td>
                        <Td className="text-center text-nowrap">
                          <PendaftarUpdateKdbb
                            pendaftar={i}
                            onFetchData={fetchData}
                          />
                        </Td>
                        <Td className="text-nowrap">
                          {i.created_at}
                        </Td>
                        <Td className="text-nowrap">
                          {i.updated_at}
                        </Td>
                        <Td className="text-nowrap">
                          {i.updated_by && (
                            <p className="m-0">{i.updated_by}</p>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-3 ps-0">
              <TableNew>
                <Thead>
                  <Tr className="text-center">
                    <Th className="text-nowrap">Status</Th>
                    <Th className="text-nowrap">Angkatan</Th>
                    <Th className="text-nowrap">
                      <i className="fa fa-bars"></i>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-center text-nowrap">
                          {!i.status && (
                            <div className="text-secondary">
                              <i className="fa fa-fw fa-sm fa-ban me-1"/>
                              <span className="fw-semibold">Belum Daftar</span>
                            </div>
                          )}
                          {parseInt(i.status) === 1 && (
                            // <span className="fw-semibold text-warning">Sudah Daftar</span>
                            <div className="text-success">
                              <i className="fa fa-fw fa-sm fa-circle-check me-1"/>
                              <span className="fw-semibold">Sudah Daftar</span>
                            </div>
                          )}
                          {parseInt(i.status) === 2 && (
                            // <span className="fw-semibold text-success">Sudah DU</span>
                            <div className="text-info">
                              <i className="fa fa-fw fa-sm fa-circle-check me-1"/>
                              <span className="fw-semibold">Sudah DU</span>
                            </div>
                          )}
                          {parseInt(i.status) === 3 && (
                            // <span className="fw-semibold text-info">Sudah Cair</span>
                            <div className="text-purple">
                              <i className="fa fa-fw fa-sm fa-sack-dollar me-1"/>
                              <span className="fw-semibold">Sudah Cair</span>
                            </div>
                          )}
                          {parseInt(i.status) === 4 && (
                            // <span className="fw-semibold text-primary">Mhs Aktif</span>
                            <div className="text-primary-2">
                              <i className="fa fa-fw fa-sm fa-circle-user me-1"/>
                              <span className="fw-semibold">Mhs Aktif</span>
                            </div>
                          )}
                          {parseInt(i.status) === 9 && (
                            // <span className="fw-semibold text-danger">Batal Daftar</span>
                            <div className="text-danger">
                              <i className="fa fa-fw fa-sm fa-circle-xmark me-1"/>
                              <span className="fw-semibold">Batal Daftar</span>
                            </div>
                          )}
                        </Td>
                        <Td className="text-center text-nowrap">
                          {i.tahun_ajaran}
                        </Td>
                        <Td className="text-center text-nowrap">
                          <PendaftarEdit
                            pendaftar_id={i.id}
                            onFetchData={fetchData}
                            pendaftar={i}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
          </div>
        )
      ) : (
        <PageNoData />
      )}

      <SectionPagination
        links={items.links}
        onChangePage={(res) => setPage(res)}
      />
    </>
  );
};

const BtnCetakInvoice = ({ pendaftar, onFetchData }) => {
  const handleUpdate = async (id) => {
    try {
      const res = await patchPendaftar(id, { is_cetak_invoice: true });
      console.log(res);
      if (res.data === "success") {
        onFetchData();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <span
      type="button"
      className="badge badge-primary"
      onClick={() => {
        saConfirm(
          "warning",
          `Yakin cetak Invoice pendaftar a.n ${pendaftar.nama} ?`,
          "Cetak invoice hanya dapat dilakukan sebanyak satu kali, mohon cek sekali lagi apakah nama pendaftar sudah sesuai atau belum"
        ).then((res) => {
          if (res.isConfirmed) {
            handleUpdate(pendaftar.id);
            window.open(`/cairkan-dana-afiliasi/${pendaftar.id}`);
          }
        });
      }}
    >
      Cairkan {pendaftar.afiliasi_id}
    </span>
  );
};

export default PendaftarHp;
