import React, { createContext, useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { fetchPegawai } from "../../api";
import CeklistMonitoringValue from "./pages/CeklistMonitoringValue";

export const CeklistMonitoringValueContext = createContext();

const CeklistMonitoringValueRoutes = () => {
  const mainUrl = "/ceklist-monitoring-value";

  const [pegawai, setPegawai] = useState([]);

  useEffect(() => {
    const fetchDataPegawai = async () => {
      const res = await fetchPegawai("all");
      setPegawai(res.data.pegawai);
    };

    fetchDataPegawai();
  }, []);

  return (
    <CeklistMonitoringValueContext.Provider value={{ pegawai }}>
      <Switch>
        <Route exact path={mainUrl}>
          <CeklistMonitoringValue mainUrl={mainUrl} />
        </Route>
      </Switch>
    </CeklistMonitoringValueContext.Provider>
  );
};

export default CeklistMonitoringValueRoutes;
