import React from "react";
import { saAlert, saConfirm } from "../../helpers";
import { deleteMorningReport } from "../../api";

const MorningReportDelete = ({ morningReport, onFetchData }) => {
  const handleDeleteMorningReport = (mr_id) => {
    saConfirm("warning", "Yakin hapus data ?").then(async (res) => {
      if (res.isConfirmed) {
        const res = await deleteMorningReport(mr_id);
        if (res.data.status && res.data.status === "success") {
          onFetchData();
          saAlert("success", res.data.msg);
        }
      }
    });
  };

  return (
    <>
      <button
        className="btn btn-danger-2 btn-sm py-0 text-sm mx-1"
        onClick={() => handleDeleteMorningReport(morningReport.id)}
      >
        <i className="fa fa-fw fa-trash-alt fa-sm"/> Hapus
      </button>
    </>
  );
};

export default MorningReportDelete;
