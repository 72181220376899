import React, { useEffect, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { titleState } from '../../storage/pages'
import SmallButton from '../../components/forms/SmallButton';
import { LayoutAddBtn } from '../../components/layouts/TopContent';
import { fetchBeritaTerkini } from '../../api';
import BTCreate from './BTCreate';
import BTEdit from './BTEdit';
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../components/layouts/TableNew';
import { formatDate } from '../../helpers';
import BTDelete from './BTDelete';
import BTShow from './BTShow';
import { userState } from '../../storage/auth';
import VirtualView from './VirtualView';

function BeritaTerkini() {

    const user = useRecoilValue(userState);
    const [title, setTitle] = useRecoilState(titleState);
    const [beritaTerkini, setBeritaTerkini] = useState([]);

    const fetchdata = async () => {
        try {
            const res = await fetchBeritaTerkini("all");
            setBeritaTerkini(res.data.berita_terkini);
        } catch (error) {
            console.log(error.response);
        }
    }

    useEffect(() => {
        fetchdata();
    }, []);

    useEffect(() => {
        setTitle("Berita terkini");
    });

  return (
    <>
        <VirtualView beritaTerkini={beritaTerkini}/>

        {user.cabang !== "pusat" ? <BTCreate fetchdata={fetchdata}/> : <p className='text-end'><i className='fa fa-fw fa-circle-exclamation text-info'/> Cabang pusat tidak memiliki akses membuat postingan.</p>}
        {/* <div className='row'>
            <div className='col-lg-4'>
                <h6 className='mb-2 fw-semibold'>Grade 1</h6>
                <p className='ms-3'>Postingan dengan grade 1 adalah postingan utama dengan kolom besar, ini biasanya digunakan untuk postingan yang memiliki bobot lebih penting dari postingan dengan grade 2 dan 3.</p>
            </div>
            <div className='col-lg-4'>
                <h6 className='mb-2 fw-semibold'>Grade 2</h6>
                <p className='ms-3'>Postingan dengan grade 2 adalah postingan kedua dengan 4 kolom, postingan ini memiliki bobot kepentingan di bawah postingan dengan grade 1.</p>
            </div>
            <div className='col-lg-4'>
                <h6 className='mb-2 fw-semibold'>Grade 3</h6>
                <p className='ms-3'>Postingan dengan grade 3 adalah postingan ketiga dengan 4 kolom, postingan ini memiliki bobot kepentingan di paling bawah dari postingan dengan grade 1 dan 2.</p>
            </div>
        </div> */}

        <div className='mt-2'>
            <TableNew>
                <Thead>
                    <Tr className='text-center'>
                        <Th>No</Th>
                        {user.cabang === "pusat" && <Th>Cabang</Th>}
                        <Th>Judul</Th>
                        <Th>Deskripsi</Th>
                        <Th>Grade</Th>
                        <Th>Dari</Th>
                        <Th>Sampai</Th>
                        <Th><i className='fa fa-fw fa-cog'/></Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {beritaTerkini.length > 0 &&
                        beritaTerkini.map((bt, index) => {
                            return (
                                <Tr key={index}>
                                    <Td className='align-middle text-center'>{index + 1}</Td>
                                    {user.cabang === "pusat" && <Td className='text-center'>{bt.cabang}</Td>}
                                    <Td>{bt.title}</Td>
                                    <Td>{bt.caption}</Td>
                                    <Td className='align-middle text-center fw-semibold text-primary'>{bt.priority}</Td>
                                    <Td className='align-middle text-center text-nowrap'>{formatDate(bt.from)}</Td>
                                    <Td className='align-middle text-center text-nowrap'>{formatDate(bt.to)}</Td>
                                    <Td className='align-middle text-center text-nowrap'>
                                        <BTShow bt={bt}/>
                                        <BTEdit fetchdata={fetchdata} bt={bt}/>
                                        <BTDelete fetchdata={fetchdata} bt={bt}/>
                                    </Td>
                                </Tr>
                            )
                        })

                    }
                </Tbody>
            </TableNew>
        </div>
    </>
  )
}

export default BeritaTerkini