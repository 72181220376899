import { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { fetchAfiliasi, patchAfiliasi } from "../../api";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  LayoutAddBtn,
  LayoutTopFilter,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { saAlert, saConfirm } from "../../helpers";
import { titleState } from "../../storage/pages";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const AfiliasiHp = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [items, setItems] = useState({});

  const fetchData = useCallback(async () => {
    setIsLoaded(false);
    try {
      const res = await fetchAfiliasi(page, `search=${search}`);
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page, search]);

  useEffect(() => {
    setTitle("Data Afiliasi");
    fetchData();
  }, [setTitle, fetchData]);

  let timer = 0;
  const handleSearch = (val) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearch(val);
      setPage(1);
    }, 800);
  };

  return (
    <>
      <TopContent onFetchData={fetchData} />

      <LayoutTopFilter onChangeSearch={(e) => handleSearch(e.target.value)} />

      {!isLoaded ? (
        <PageLoading />
      ) : items.data.length >= 1 ? (
        <TableNew>
          <Thead>
            <Tr className='text-center'>
              {/* <Th>No</Th> */}
              <Th>Kode</Th>
              <Th>Nama</Th>
              <Th>Status</Th>
              <Th>Alamat</Th>
              <Th>No KTP</Th>
              <Th>HP</Th>
              <Th>Email</Th>
              <Th>No Req</Th>
              <Th>Nama Bank</Th>
              <Th>Mendaftar Pada</Th>
              <Th>Cabang</Th>
              <Th>Pendaftar</Th>
              <Th>
                <i className="fa fa-bars"></i>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.data.map((i, index) => {
              return (
                <Tr key={index}>
                  {/* <td className="text-center">{items.from + index}</td> */}
                  <Td className="text-center">{i.kode}</Td>
                  <Td>{i.nama}</Td>
                  <Td className="text-center">
                    <StatusAfiliator afiliasi={i} onFetchData={fetchData} />
                  </Td>
                  <Td>{i.alamat}</Td>
                  <Td>{i.no_ktp}</Td>
                  <Td className="text-center">{i.no_hp}</Td>
                  <Td>{i.email}</Td>
                  <Td>{i.no_req}</Td>
                  <Td>{i.bank}</Td>
                  <Td>{i.created_at}</Td>
                  <Td
                    className="text-center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {i.cabang}
                  </Td>
                  <Td className="text-center">
                    {i.pendaftar.length === 0 ? (
                      <span className="text-danger">{i.pendaftar.length}</span>
                    ) : (
                      <span className="text-success">{i.pendaftar.length}</span>
                    )}{" "}
                    Orang
                  </Td>
                  <Td className="text-center">
                    {i.pendaftar.length >= 1 && (
                      <Pendaftar nama={i.nama} pendaftar={i.pendaftar} />
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </TableNew>
      ) : (
        <PageNoData />
      )}

      <SectionPagination
        links={items.links}
        onChangePage={(res) => setPage(res)}
      />
    </>
  );
};

const TopContent = (props) => {
  const [modal, setModal] = useState(false);
  const [items, setItems] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchAfiliasi("", "approve=true");
      setItems(res.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleUpdate = (id, status, message) => {
    saConfirm("warning", `Yakin ingin ${message} Afiliator ?`).then((res) => {
      if (res.isConfirmed) {
        patchAfiliasi(id, { status }).then((res) => {
          if (res.data === "success") {
            props.onFetchData();
            fetchData();
            saAlert("success", `Berhasil ${message} Afiliator !`);
          }
        });
      }
    });
  };

  return (
    <>
      <LayoutAddBtn
        add={`Approve Afiliasi ${`<span class="text-white bg-danger fa-fade rounded text-sm py-0 px-2">${items.length}</span>`}`}
        onClick={() => setModal(true)}
      />
      <LayoutModal
        title="Approve Afiliator"
        modal={modal}
        onClose={() => setModal(false)}
      >
        {items.length >= 1 ? (
          <TableNew>
            <Thead>
              <Tr className='text-center'>
                <Th>No</Th>
                <Th>Nama</Th>
                <Th>Email</Th>
                <Th>HP</Th>
                <Th>KTP</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td>{index + 1}</Td>
                    <Td>{i.nama}</Td>
                    <Td>{i.email}</Td>
                    <Td>{i.no_hp}</Td>
                    <Td>{i.no_ktp}</Td>
                    <Td>
                      <div className="btn-group">
                        <button
                          onClick={() =>
                            handleUpdate(i.id, "approve", "Approve")
                          }
                          className="btn btn-success-2 btn-sm"
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => handleUpdate(i.id, "reject", "Reject")}
                          className="btn btn-danger-2 btn-sm"
                        >
                          Reject
                        </button>
                      </div>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <PageNoData />
        )}
      </LayoutModal>
    </>
  );
};

const StatusAfiliator = (props) => {
  const { afiliasi, onFetchData } = props;

  const handleChange = async (e) => {
    try {
      const res = await patchAfiliasi(afiliasi.id, {
        status: e.target.value,
      });
      if (res.data === "success") {
        onFetchData();
        saAlert("success", "Berhasil update data !");
      }
    } catch (err) {
      console.error(err.response);
    }
  };

  return (
    <select
      name=""
      id=""
      className="form-select form-select-sm form-select-custom py-0 w-auto"
      onChange={handleChange}
      defaultValue={afiliasi.status ? afiliasi.status : ""}
    >
      <option value="">Pilih</option>
      <option value="1">Umum</option>
      <option value="17">Mahasiswa</option>
      <option value="18">Alumni</option>
      <option value="19">Guru</option>
    </select>
  );
};

const Pendaftar = ({ nama, pendaftar }) => {
  const [modal, setModal] = useState(false);

  return (
    <>
      <button onClick={() => setModal(true)} className="btn btn-sm btn-primary-2">
        Lihat Pendaftar
      </button>
      <LayoutModal
        title={"Data Pendaftar " + nama}
        modal={modal}
        onClose={() => setModal(false)}
      >
        {pendaftar && (
          <TableNew>
            <Thead>
              <Tr className='text-center'>
                <Th>No</Th>
                <Th>Cabang</Th>
                <Th>Nama</Th>
                <Th>HP</Th>
                <Th>Status</Th>
                <Th className="text-nowrap">Tanggal Daftar</Th>
                <Th className="text-nowrap">Tanggal Update</Th>
                <Th>Last Updated By</Th>
              </Tr>
            </Thead>
            <Tbody>
              {pendaftar.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center text-nowrap">{index + 1}</Td>
                    <Td style={{ textTransform: "capitalize" }}>{i.cabang}</Td>
                    <Td className="text-nowrap">{i.nama}</Td>
                    <Td className="text-center text-nowrap">{i.no_hp}</Td>
                    <Td className="text-center text-nowrap">
                      {!i.status && (
                        <span className="text-danger">Belum Daftar</span>
                      )}
                      {parseInt(i.status) === 1 && (
                        <span className="text-warning">Sudah Daftar</span>
                      )}
                      {parseInt(i.status) === 2 && (
                        <span className="text-success">Sudah DU</span>
                      )}
                      {parseInt(i.status) === 3 && (
                        <span className="text-info">Sudah Cair</span>
                      )}
                      {parseInt(i.status) === 4 && (
                        <span className="text-primary">Mhs Aktif</span>
                      )}
                    </Td>
                    <Td className="text-nowrap text-center text-success">
                      {i.created_at}
                    </Td>
                    <Td className="text-nowrap text-center text-warning">
                      {i.updated_at}
                    </Td>
                    <Td className="text-nowrap">
                      {i.updated_by && (
                        <p className="m-0 text-info">{i.updated_by}</p>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        )}
        <button
          className="btn btn-secondary"
          type="button"
          onClick={() => setModal(false)}
        >
          Tutup
        </button>
      </LayoutModal>
    </>
  );
};

export default AfiliasiHp;
