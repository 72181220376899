import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchJabatan, fetchPegawai, patchPegawi } from "../../api";
import TableNew, { Thead, Tbody, Tr, Th, Td } from "../../components/layouts/TableNew";
import {
  FilterCabang,
  FilterPagination,
  FilterSearch,
  FilterTahun,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { tahunAjaranState } from "../../storage/mhs";
import { titleState } from "../../storage/pages";
import PegawaiCreate from "./Create";
import { fetchCabang } from "../../modules/cabang/apis";
import PegawaiHandleNonaktif from "./HandleNonaktif";
import PegawaiAddJabatan from "./AddJabatan";
import { saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import instance from "../../axios";
import EditHrd from "./EditHrd";
import HrdBerkas from "./HrdBerkas";
import SlipGajiPegawaiCreate from "../files/SlipGajiPegawaiCreate";

const PegawaiHrd = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const user = useRecoilValue(userState);

  const [dataPegawai, setDataPegawai] = useState({});

  const tA = useRecoilValue(tahunAjaranState);

  const [page, setPage] = useState(1);
  const [cabang, setCabang] = useState("");
  const [tahun, setTahun] = useState(tA);
  const [pagination, setPagination] = useState("10");
  const [search, setSearch] = useState("");

  const setTitle = useSetRecoilState(titleState);

  useEffect(() => {
    setTitle("Data Pegawai");
  }, [setTitle]);

  const fetchData = useCallback(
    async (reset = true) => {
      if (reset) {
        setIsLoaded(false);
      }
      try {
        const res = await fetchPegawai(
          page,
          `search=${search}&cabang=${cabang}&tahun=${tahun}&pagination=${pagination}`
        );
        setDataPegawai(res.data.pegawai);
        // console.log(res.data.pegawai);
        setIsLoaded(true);
      } catch (err) {
        console.log(err);
      }
    },
    [page, cabang, tahun, pagination, search]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dataCabang, setDataCabang] = useState([]);

  useEffect(() => {
    const fetchDataCabang = async () => {
      const res = await fetchCabang("all");
      setDataCabang(res.data.cabang);
    };
    fetchDataCabang();
  }, []);

  const [dataJabatan, setDataJabatan] = useState([]);

  useEffect(() => {
    const fetchDataJabatan = async () => {
      const res = await fetchJabatan("all");
      setDataJabatan(res.data.jabatan);
    };
    fetchDataJabatan();
  }, []);

  const handleSetLimitCutiTahunan = (e, pegawai_id) => {
    e.preventDefault();
    saConfirm("warning", "Yakin set jumlah cuti ?").then(async (res) => {
      if (res.isConfirmed) {
        let formData = new FormData(e.target);
        const res = await instance.post(
          `/api/cuti-count/${pegawai_id}/${tahun}`,
          formData
        );
        if (res.data && res.data.status) {
          fetchData(true);
          saAlert(res.data.status, res.data.msg);
        }
      }
    });
  };

  const toggleCutiTahunan = async (pegawai_id, status) => {
    try {
      const res = await instance.post(`/api/cuti-count/${pegawai_id}/${tahun}`, {on_tahunan: `${status}`});
      if (res.data && res.data.status) {
        fetchData(true);
        saAlert(res.data.status, res.data.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleAlasanPenting = async (pegawai_id, status) => {
    try {
      const res = await instance.post(`/api/cuti-count/${pegawai_id}/${tahun}`, {on_alasan_penting: `${status}`});
      if (res.data && res.data.status) {
        fetchData(true);
        saAlert(res.data.status, res.data.msg);
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (user.cabang === "pusat") {
    return (
      <>
        <div className="row justify-content-end">
          {(user.jabatan === "IT Programmer" || user.jabatan === "Bagian SDM") &&
            <div className="col-lg-3">
              <SlipGajiPegawaiCreate
                onFetchData={fetchData}
              />
            </div>
          }
          {(user.jabatan === "IT Programmer" || user.jabatan === "Bagian SDM") &&
            <div className="col-lg-3">
              <PegawaiCreate
                dataCabang={dataCabang}
                dataJabatan={dataJabatan}
                onFetchData={fetchData}
              />
            </div>
          }
        </div>

        <div className="row mb-2">
          <div className="col-3">
            <FilterTahun
              value={tahun}
              onChange={(res) => {
                setTahun(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-3">
            <FilterCabang
              pusat={true}
              onChange={(res) => {
                setCabang(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-3">
            <FilterPagination
              onChange={(res) => {
                setPagination(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-3">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {isLoaded && dataPegawai.data && dataPegawai.data.length > 0 ? (
          <div className="row mt-3">
            <div className="col-3">
              <TableNew>
                <Thead>
                  <Tr>
                    <Th colSpan={3} className="bg-primary text-white border-bottom border-silver fw-normal">Data Tabel</Th>
                  </Tr>
                  <Tr className="text-center">
                    <Th className="bg-primary text-white border-bottom border-silver fw-normal">No</Th>
                    <Th className="bg-primary text-white border-bottom border-silver fw-normal">NIP</Th>
                    <Th className="bg-primary text-white border-bottom border-silver fw-normal">Nama</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataPegawai.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="border-silver text-center">
                          {dataPegawai.from + index}
                        </Td>
                        <Td className="border-silver text-nowrap text-center">{i.nip}</Td>
                        <Td className="border-silver text-nowrap">{i.nama}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-9">
              <TableNew>
                <Thead>
                  <Tr className="text-center">
                    <Th rowSpan={2} className="bg-primary text-white border-bottom border-silver fw-normal align-middle">
                      Cabang
                    </Th>
                    <Th rowSpan={2} className="bg-primary text-secondary border-bottom border-silver fw-normal align-middle">
                      Jabatan lama
                    </Th>
                    <Th rowSpan={2} className="bg-primary text-white border-bottom border-silver fw-normal align-middle">
                      Jabatan sekarang
                    </Th>
                    <Th rowSpan={2} className="bg-primary text-white border-bottom border-silver fw-normal align-middle">
                      Group
                    </Th>
                    <Th rowSpan={2} className="bg-primary text-white border-bottom border-silver fw-normal align-middle">
                      Grade
                    </Th>
                    <Th rowSpan={2} className="bg-primary text-white border-bottom border-silver fw-normal align-middle text-nowrap">
                      Is Auditor
                    </Th>
                    <Th colSpan={5} className="bg-primary text-white border-bottom border-silver fw-normal align-middle">Cuti Total</Th>
                    <Th rowSpan={2} className="bg-primary text-white border-bottom border-silver fw-normal align-middle">
                      <i className="fa fa-fw fa-cog"></i>
                    </Th>
                  </Tr>
                  <Tr className="text-center text-nowrap">
                    <Th className="bg-primary text-white border-bottom border-silver fw-normal">Cuti Tahunan</Th>
                    <Th className="bg-primary text-white border-bottom border-silver fw-normal">Cuti Bersama</Th>
                    <Th className="bg-primary text-white border-bottom border-silver fw-normal">Total Cuti</Th>
                    <Th className="bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Alasan Penting</Th>
                    <Th className="bg-primary text-white border-bottom border-silver fw-normal">Bersalin</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {dataPegawai.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="border-silver text-center">{i.cabang}</Td>
                        <Td className="border-silver text-nowrap">{i.jabatan}</Td>
                        <Td className="border-silver text-nowrap">
                          {i.pegawai_jabatan && i.pegawai_jabatan.length > 0
                            ? i.pegawai_jabatan.map((pj, index) => {
                                if (i.pegawai_jabatan.length === index + 1) {
                                  return (
                                    pj.jabatan &&
                                    `${pj.jabatan.nama} (${
                                      pj.jabatan.cabang &&
                                      pj.jabatan.cabang.nama
                                    })`
                                  );
                                }
                                return (
                                  pj.jabatan &&
                                  `${pj.jabatan.nama} (${
                                    pj.jabatan.cabang && pj.jabatan.cabang.nama
                                  }) || `
                                );
                              })
                            : "-"}
                        </Td>
                        <Td className="border-silver text-center">{i.group}</Td>
                        <Td className="border-silver text-center">{i.grade}</Td>
                        <Td className="border-silver text-center text-nowrap">
                          <IsAuditor pegawai={i} onFetchData={fetchData} />
                        </Td>
                        <Td className="border-silver text-center align-middle">
                          <div className="w-full d-flex justify-content-between align-items-center">
                            <p className="mb-0">
                              {i.cuti_count && i.cuti_count.map((cc) => cc.cuti_tahunan ? cc.cuti_tahunan : 0)}
                            </p>
                            {i.cuti_count && i.cuti_count.map((cc, index) => {
                              if (parseInt(cc.on_tahunan) === 9 || cc.on_tahunan === null) {
                                return (
                                  <button key={index} onClick={() => toggleCutiTahunan(i.id, 1)} className="btn btn-sm btn-danger-2 text-sm py-0 px-1" type="button">
                                    Off
                                  </button>
                                );
                              } else {
                                return (
                                  <button key={index} onClick={() => toggleCutiTahunan(i.id, 9)} className="btn btn-sm btn-success-2 text-sm py-0 px-1" type="button">
                                    On
                                  </button>
                                );
                              }
                            })}
                          </div>
                        </Td>
                        <Td className="border-silver text-center align-middle">
                          <div className="w-full d-flex justify-content-between align-items-center">
                            <p className="mb-0">
                              {i.cuti_count && i.cuti_count.map((cc) => cc.cuti_bersama ? cc.cuti_bersama : 0)}
                            </p>
                            {/* {i.cuti_count && i.cuti_count.map((cc, index) => {
                              if (parseInt(cc.on_tahunan) === 9 || cc.on_tahunan === null) {
                                return (
                                  <button key={index} onClick={() => toggleCutiTahunan(i.id, 1)} className="btn btn-sm btn-danger-2 text-sm py-0 px-1" type="button">
                                    Off
                                  </button>
                                );
                              } else {
                                return (
                                  <button key={index} onClick={() => toggleCutiTahunan(i.id, 9)} className="btn btn-sm btn-success-2 text-sm py-0 px-1" type="button">
                                    On
                                  </button>
                                );
                              }
                            })} */}
                          </div>
                        </Td>
                        {/* <Td className="border-silver">
                          <form
                            onSubmit={(e) => handleSetLimitCutiTahunan(e, i.id)}
                            className="d-flex justify-content-between"
                          >
                            <input
                              type="text"
                              name="limit_cuti_tahunan"
                              style={{ all: "unset" }}
                              defaultValue={
                                i.cuti_count
                                  ? i.cuti_count.map(
                                      (cc) => cc.limit_cuti_tahunan
                                    )
                                  : "0"
                              }
                              placeholder="Klik untuk ubah . . ."
                            />
                            <button className="btn btn-sm btn-primary-3 text-sm py-0 px-1" type="submit">
                              <i className="fa fa-save"></i>
                            </button>
                          </form>
                        </Td> */}
                        <Td>
                          {
                            i.cuti_count ? i.cuti_count.map((cc, index) => {
                              const cuti_tahunan = cc.cuti_tahunan !== null ? parseInt(cc.cuti_tahunan) : 0;
                              const cuti_bersama = cc.cuti_bersama !== null ? parseInt(cc.cuti_bersama) : 0;

                              const total = cuti_tahunan + cuti_bersama;

                              return (
                                <span key={index}>{total}</span>
                              );
                            }) : "0"
                          }
                        </Td>
                        <Td className="border-silver text-center align-middle">
                          <div className="w-full d-flex justify-content-between align-items-center">
                            <p className="mb-0">
                            {i.cuti_count
                              && i.cuti_count.map((cc) => cc.cuti_alasan_penting ? cc.cuti_alasan_penting : "0")}
                            </p>
                            {i.cuti_count && i.cuti_count.map((cc, index) => {
                              if (parseInt(cc.on_alasan_penting) === 9 || cc.on_alasan_penting === null) {
                                return (
                                  <button key={index} onClick={() => toggleAlasanPenting(i.id, 1)} className="btn btn-sm btn-danger-2 text-sm py-0 px-1" type="button">
                                    Off
                                  </button>
                                );
                              } else {
                                return (
                                  <button key={index} onClick={() => toggleAlasanPenting(i.id, 9)} className="btn btn-sm btn-success-2 text-sm py-0 px-1" type="button">
                                    On
                                  </button>
                                );
                              }
                            })}
                          </div>
                        </Td>
                        <Td className="border-silver text-center align-middle">
                          {i.cuti_count
                            && i.cuti_count.map((cc) => cc.cuti_bersalin ? cc.cuti_bersalin : "0")}
                        </Td>
                        <Td className="border-silver text-nowrap text-center">
                          <HrdBerkas/>
                          <PegawaiAddJabatan
                            pegawai={i}
                            dataJabatan={dataJabatan}
                            onFetchData={fetchData}
                          />
                          {/* <PegawaiDetail pegawai={i} onFetchData={fetchData} /> */}
                          <EditHrd
                            pegawai={i}
                            dataCabang={dataCabang}
                            dataJabatan={dataJabatan}
                            onFetchData={fetchData}
                          />
                          <PegawaiHandleNonaktif
                            pegawai={i}
                            onFetchData={fetchData}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
          </div>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={dataPegawai.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

const IsAuditor = ({ pegawai, onFetchData }) => {
  const handleUpdate = async (val) => {
    const res = await patchPegawi(pegawai.id, { is_auditor: val });
    if (res.data) {
      onFetchData();
      saAlert(res.data.status, res.data.msg);
    }
  };

  if (pegawai.is_auditor) {
    return (
      <span
        className="btn btn-sm btn-danger-2 text-sm py-0"
        type="button"
        onClick={() => handleUpdate("no")}
      >
        <i className="fa fa-xmark mr-1"></i>
        Hapus
      </span>
    );
  } else {
    return (
      <button
        className="btn btn-sm btn-success-2 text-sm py-0"
        type="button"
        onClick={() => handleUpdate("yes")}
      >
        <i className="fa fa-plus mr-1"></i>
        Tambah
      </button>
    );
  }
};

export default PegawaiHrd;
