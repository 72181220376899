import React from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import {
  LayoutTable,
  LayoutThead,
  TableNoData,
} from "../../../components/layouts/Table";
import { LayoutAddBtn } from "../../../components/layouts/TopContent";
import SectionPagination from "../../../components/sections/Pagination";
import { saAlert, saConfirm } from "../../../helpers";
import { deleteCeklistMonitoringList } from "../apis";
import { CeklistMonitoringListContext } from "../Routes";

const CeklistMonitoringList = ({ mainUrl, onFetchData }) => {
  const { ceklistMonitoringList, setPage } = useContext(
    CeklistMonitoringListContext
  );

  const handleDelete = (id) => {
    saConfirm("warning", "Yakin hapus data ?").then(async (res) => {
      if (res.isConfirmed) {
        const res = await deleteCeklistMonitoringList(id);
        if (res.data && res.data.msg) {
          onFetchData();
          saAlert("success", res.data.msg);
        }
      }
    });
  };

  return (
    <>
      <LayoutAddBtn
        add="Tambah Ceklist Monitoring List"
        link={`${mainUrl}/create`}
      />
      <LayoutTable>
        <LayoutThead>
          <th>no</th>
          <th>klausul</th>
          <th>deskripsi</th>
          <th>refrensi_audit</th>
          <th>bars</th>
        </LayoutThead>
        <tbody>
          {ceklistMonitoringList.data &&
          ceklistMonitoringList.data.length > 0 ? (
            ceklistMonitoringList.data.map((cml, index) => {
              return (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td>{cml.klausul}</td>
                  <td>{cml.deskripsi}</td>
                  <td>{cml.refrensi_audit}</td>
                  <td className="text-center">
                    <Link
                      className="text-success fa fa-edit mx-1"
                      to={`${mainUrl}/${cml.id}/edit`}
                    ></Link>
                    <span
                      type="button"
                      className="text-danger fa fa-trash-alt mx-1"
                      onClick={() => handleDelete(cml.id)}
                    ></span>
                  </td>
                </tr>
              );
            })
          ) : (
            <TableNoData col={8} />
          )}
        </tbody>
      </LayoutTable>
      <SectionPagination
        links={ceklistMonitoringList.links}
        from={ceklistMonitoringList.from}
        to={ceklistMonitoringList.to}
        total={ceklistMonitoringList.total}
        onChangePage={(res) => setPage(res)}
      />
    </>
  );
};

export default CeklistMonitoringList;
