import { Link, useHistory } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { saAlert, saConfirm } from "../../helpers";
import { tokenState, userState } from "../../storage/auth";
import axios from "../../axios";
import { sidebarState } from "../../storage/pages";
import React, { useState } from "react";
import { useEffect } from "react";
import { patchPegawi } from "../../api";

const DashboardTopbar = (props) => {
  const token = useRecoilValue(tokenState);
  const user = useRecoilValue(userState);
  const [sidebar, setSidebar] = useRecoilState(sidebarState);
  const history = useHistory();

  const handleLogout = () => {
    saConfirm("warning", "Yakin logout ?").then((res) => {
      if (res.isConfirmed) {
        axios
          .post(
            "/api/logout",
            { id: localStorage.getItem("_id") },
            { headers: { Authorization: "Bearer " + token } }
          )
          .then((res) => {
            localStorage.clear();
            saAlert("success", "Berhasil logout !");
            history.push("/");
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const [showProfile, setShowProfile] = useState(false);

  const toggleProfile = () => {
    setShowProfile(!showProfile);
  };

  const defaultPosition = 1;

  const [position, setPosition] = useState(defaultPosition);

  useEffect(() => {
    window.parent.document.body.style.zoom = position;
  }, [position]);

  const handleChangeAccount = () => {
    saConfirm("warning", "Yakin untuk mengganti jabatan?", "Mengganti jabatan akan mengubah menu menyesuaikan dengan jabatan yang terpilih, perlu diketahui fitur ini masih dalam tahap pengembangan, untuk saat ini menu belum mengalami perubahaan.").then( async (res) => {
      if (res.isConfirmed) {
        try {
          const res = await patchPegawi(user.id, {
            jabatan: null,
            set_default_jabatan: true,
          });
          if (res.data && res.data.status && res.data.status === "success") {
            window.location.href = "/dashboard";
          }
        } catch (err) {}
      }
    })
  };

  return (
    <nav className="navbar bg-gradient-primary border-bottom-warning navbar-expand navbar-light bg-white topbar mx-3 mt-3 static-top shadow rounded-4">
      <button
        id="sidebarToggleTop"
        onClick={() => setSidebar(!sidebar)}
        className="d-md-none btn-transparent link-white border-0 rounded-circle mr-3"
      >
        <i className="fa fa-bars"></i>
      </button>

      <h6 className="d-none d-sm-block mb-0 fw-semibold text-white text-sm">
        TO BE THE <span className="text-warning">FIRST CHOICE</span> OF INDUSTRY
      </h6>

      <ul className="navbar-nav ml-auto">
        <li className="nav-item d-none d-sm-block">
          <button
            onClick={() => setPosition(position + 0.1)}
            title="Zoom In"
            className="nav-link link-white text-sm"
          >
            <i className="fa fa-magnifying-glass-plus fa-lg" />
          </button>
        </li>
        <li className="nav-item d-none d-sm-block">
          <button
            onClick={() => setPosition(position - 0.1)}
            title="Zoom Out"
            className="nav-link link-white text-sm"
          >
            <i className="fa fa-magnifying-glass-minus fa-lg" />
          </button>
        </li>
        <li className="nav-item d-none d-sm-block">
          <button
            onClick={() => setPosition(defaultPosition)}
            title="Zoom Reset"
            className="nav-link link-white text-sm"
          >
            <i className="fa fa-rotate-right fa-lg" />
          </button>
        </li>
        <li className="nav-item">
          <button
            onClick={() => {
              window.open("https://dev.alfaprima.id/musik");
            }}
            title="Musik"
            className="nav-link link-white text-sm"
          >
            <i className="fa fa-music fa-lg" />
          </button>
        </li>

        <div className="topbar-divider d-none d-sm-block" />
        <li className="nav-item dropdown">
          <button className="nav-link link-white" onClick={toggleProfile}>
            <i className="fa fa-fw fa-user-circle fa-lg me-2" />
            <span className="mr-2 d-none d-lg-inline text-sm">{user.nama}</span>

            <i
              className={`fa fa-fw ${
                showProfile ? "fa-chevron-down" : "fa-chevron-right"
              }`}
            />
          </button>
          <div
            className={`${
              showProfile ? "show" : ""
            } dropdown-menu dropdown-menu-right shadow animated--grow-in bg-gradient-primary`}
          >
            <Link
              className="dropdown-item"
              onClick={(e) => setShowProfile(false)}
              to="/profile"
            >
              <i className="fas fa-user-edit fa-sm fa-fw mr-2 text-gray-500" />
              <span className="text-gray-500">Profile</span>
            </Link>
            <div className="dropdown-divider border-secondary" />
            <button
              className="dropdown-item"
              onClick={(e) => {
                handleChangeAccount();
                setShowProfile(false);
              }}
            >
              <i className="fas fa-sync fa-sm fa-fw mr-2 text-warning" />
              <span className="text-warning">Pilih Jabatan</span>
            </button>
            <button
              className="dropdown-item"
              onClick={(e) => {
                handleLogout();
                setShowProfile(false);
              }}
            >
              <i className="fas fa-power-off fa-sm fa-fw mr-2 text-danger" />
              <span className="text-danger fw-semibold">Logout</span>
            </button>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default DashboardTopbar;