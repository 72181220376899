import { useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { saAlert } from "../helpers";
import { userState } from "../storage/auth";
import { titleState } from "../storage/pages";

const AlfaPrima = () => {
  const user = useRecoilValue(userState);
  const [title, setTitle] = useRecoilState(titleState);

  useEffect(() => {
    title === "No Title" &&
      saAlert("success", "Selamat datang!", user.nama);
    setTitle("Alfa Prima");
  }, [title, user.nama, setTitle]);

  return (
    <div className="row">
      <div className="col-lg-6 mb-3 mb-sm-0">
        <div className="city-background text-white rounded-3 d-flex flex-column justify-content-center align-items-center h-100 py-5">
          <h3 className="text-center fw-semibold text-warning">BUDAYA PRIMA</h3>
        </div>
      </div>
      <div className="col-lg-6">
        <div>
          <ul className="list-unstyled">
            <li className="mb-2">
              <p className="mb-0 text-primary fw-semibold fs-5"><span className="fs-1 text-warning">P</span>ROFESIONAL</p>
              <p className="mb-0">Berkomitmen, percaya diri, semangat dan disiplin Deal Done, menyelesaikan pekerjaan dengan kualitas tinggi dan tepat waktu</p>
              <p className="mb-0 text-primary fw-semibold fs-5"><span className="fs-1 text-warning">R</span>ESPECT</p>
              <p className="mb-0">Team Work yang saling menghargai dan berkoordinasi Berbicara solusi, saling terbuka terhadap masukan, tidak BEJ dan tidak mengeluh</p>
              <p className="mb-0 text-primary fw-semibold fs-5"><span className="fs-1 text-warning">I</span>NTEGRITY</p>
              <p className="mb-0">Bersikap jujur, bertanggung jawab dan dapat dipercaya Memegang terguh prinsip-prinsip kebenaran, etika dan moral</p>
              <p className="mb-0 text-primary fw-semibold fs-5"><span className="fs-1 text-warning">M</span>ISSION ORIENTED</p>
              <p className="mb-0">Mengutamakan customer Berfokus kepada goals yang telah ditetapkan</p>
              <p className="mb-0 text-primary fw-semibold fs-5"><span className="fs-1 text-warning">A</span>GILITY</p>
              <p className="mb-0">Tanggap terhadap perubahan, open minded dan inovatif Perbaikan berkesinambungan dan belajar setiap hari</p>
            </li>
          </ul>
        </div>
      </div>
    </div>       
  );
};

export default AlfaPrima;
