import React, { useState, useEffect, useCallback, useRef } from 'react'
import { titleState } from '../../storage/pages';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../components/layouts/TableNew';
import { deleteTutorial, fetchTutorial, patchTutorial, postDivisi, postTutorial } from '../../api';
import PageLoading from '../../components/pages/Loading';
import { FilterSearch, LayoutAddBtn } from '../../components/layouts/TopContent';
import LayoutModal from '../../components/layouts/Modal';
import FormInput from '../../components/forms/Input';
import { getOrigin, saAlert, saConfirm } from '../../helpers';
import FormTextarea from '../../components/forms/Textarea';
import FormSelect from '../../components/forms/Select';
import { fetchCabang } from '../../modules/cabang/apis';
import SmallButton from '../../components/forms/SmallButton';
import { userState } from '../../storage/auth';

function TutorialSistem() {
    const setTitle = useSetRecoilState(titleState);
    const user = useRecoilValue(userState);
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    // const [search, setSearch] = useState("");

    useEffect(() => {
        setTitle("Tutorial Penggunaan Sistem");
    }, [setTitle]);

    // const fetchData = useCallback(
    //     async (reset = true) => {
    //     if (reset) {
    //         setIsLoaded(false);
    //     }
    //     try {
    //         const res = await fetchTutorial("all", `&search=${search}`);
    //         setItems(res.data.tutorial);
    //         setIsLoaded(true);
    //         } catch (err) {
    //         //
    //     }
    // }, [search]);

    const fetchData = async () =>  {
        try {
            const res = await fetchTutorial("all");
            setItems(res.data.tutorial);
            setIsLoaded(true);
        } catch (error) {
            console.log(error.response);
        }
    }

    const [dataCabang, setDataCabang] = useState([]);

    const fetchDataCabang = async () => {
        const res = await fetchCabang("all");
        if (res.data && res.data.cabang) {
            setDataCabang(res.data.cabang);
        }
    };

    // useEffect(() => {
    //     fetchDataCabang();
    // }, []);

    // useEffect(() => {
    //     fetchData();
    // }, [fetchData]);

    useEffect(() => {
        fetchDataCabang();
        fetchData();
    }, []);

    return (
        <div>
            {/* <div className="row justify-content-end">
                <div className="col-md-4 mb-3">
                    <FilterSearch onChange={(res) => setSearch(res)} />
                </div>
            </div> */}
            {user.jabatan === "IT Programmer" && <TambahTutorial onFetchData={fetchData} dataCabang={dataCabang}/>}
            {isLoaded ?
            <TableNew>
                <Thead>
                    <Tr className="text-center text-nowrap">
                        <Th>No</Th>
                        <Th>Cabang</Th>
                        <Th>Judul</Th>
                        <Th>Deskripsi</Th>
                        <Th>Link Tutorial</Th>
                        {user.jabatan === "IT Programmer" && <Th><i className='fa fa-fw fa-cog'/></Th>}
                    </Tr>
                </Thead>
                <Tbody>
                    {items.data.length > 0 ? 
                    items.data.map((i, index) => {
                        return (
                        <Tr key={index}>
                            <Td className="text-nowrap align-middle text-center">{index + 1}</Td>
                            <Td className="text-nowrap text-center">
                                {dataCabang.filter((dc) => parseInt(dc.id) === parseInt(i.cabang_id)).map((dc) => dc.nama)}
                            </Td>
                            <Td className="text-nowrap align-middle">{i.judul}</Td>
                            <Td className="text-nowrap align-middle">{i.deskripsi}</Td>
                            <Td className="text-nowrap align-middle"><SmallButton onClick={() => window.open(i.link, "_blank")} type="show" text="Buka Video Tutorial"/></Td>
                            {user.jabatan === "IT Programmer" &&
                                <Td className="text-nowrap align-middle text-center">
                                    <EditTutorial item={i} onFetchData={fetchData} dataCabang={dataCabang}/>
                                    <DeleteTutorial item={i} onFetchData={fetchData}/>
                                </Td>
                            }
                        </Tr>
                        )
                    }) :
                        <Tr>
                            <Td colSpan="6" className="text-center p-4">Tidak ada data yang tersedia.</Td>
                        </Tr> 
                    }
                </Tbody>
            </TableNew> : <PageLoading/>
            }
        </div>
      )
}

export const TambahTutorial = (props) => {
    const {onFetchData, dataCabang} = props;
    const tagForm = useRef(null);
    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});

    const handleOpen = () => {
        setModal(true);
    }

    const handleClose = () => {
        tagForm.current && tagForm.current.reset();
        setForm({});
        setErrors({});
        setModal(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const res = await postTutorial(form);
          if (res.data && res.data.status === "success") {
            onFetchData();
            handleClose();
            saAlert("success", res.data.message);
          }
        } catch (err) {
          err.response.status === 422 && setErrors(err.response.data.errors);
        }
    };

    return (
        <>
        <LayoutAddBtn onClick={handleOpen} add="Tambah Tutorial" />

        <LayoutModal
            size="md"
            title="Tambah Tutorial"
            modal={modal}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} ref={tagForm}>
                <FormSelect
                    label="Cabang"
                    name="cabang_id"
                    onChange={(e) => 
                        setForm((prevState) => {
                            return { ...prevState, [e.target.name]: e.target.value };
                        })
                    }
                    error={errors.cabang_id}
                >
                    <option value="">Pilih</option>
                    {dataCabang &&
                        dataCabang.length > 0 &&
                        dataCabang.map((dc, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <option value={dc.id}>{dc.nama}</option>
                                </React.Fragment>
                            );
                        })}
                </FormSelect>
                <FormInput
                    label="Judul"
                    name="judul"
                    onChange={(e) => 
                        setForm((prevState) => {
                            return { ...prevState, [e.target.name]: e.target.value };
                        })
                    }
                    error={errors.nama}
                />
                <FormInput
                    label="Deskripsi"
                    name="deskripsi"
                    onChange={(e) => 
                        setForm((prevState) => {
                            return { ...prevState, [e.target.name]: e.target.value };
                        })
                    }
                    error={errors.nama}
                />
                <FormTextarea
                    label="Link Tutorial"
                    name="link"
                    onChange={(e) => 
                        setForm((prevState) => {
                            return { ...prevState, [e.target.name]: e.target.value };
                        })
                    }
                    error={errors.nama}
                />
                
                <div className="btn-group w-100">
                    <button type="submit" className="btn btn-sm btn-success-2">
                    Simpan
                    </button>
                    <button
                    type="button"
                    className="btn btn-sm btn-dark border-0"
                    onClick={handleClose}
                    >
                    <i className='fa fa-fw fa-sm fa-arrow-left'/> Kembali
                    </button>
                </div>
            </form>
        </LayoutModal>
        </>
    )
}

export const DeleteTutorial = (props) => {
    const {item, onFetchData} = props

    const handleDelete = () => {
        saConfirm("warning", `Hapus tutorial ${item.judul} ?`).then(async (res) => {
            if (res.isConfirmed) {
            const res = await deleteTutorial(item.id);
            if (res.data.status === "success") {
                onFetchData();
                saAlert("success", res.data.message);
            }
            }
        });
    }

    return (
        <SmallButton onClick={handleDelete} type="delete"/>
    )
}

export const EditTutorial = (props) => {
    const {item, onFetchData, dataCabang} = props;
    const tagForm = useRef(null);
    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});

    const handleOpen = () => {
        setModal(true);
        setForm(item);
    }

    const handleClose = () => {
        tagForm.current && tagForm.current.reset();
        setForm({});
        setErrors({});
        setModal(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          const res = await patchTutorial(form.id, form);
          if (res.data && res.data.status === "success") {
            onFetchData();
            handleClose();
            saAlert("success", res.data.message);
          }
        } catch (err) {
          err.response.status === 422 && setErrors(err.response.data.errors);
        }
    };

    return (
        <>
        <SmallButton onClick={handleOpen} margin="me-1" type="edit" />

        <LayoutModal
            size="md"
            title="Edit Tutorial"
            modal={modal}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} ref={tagForm}>
                <FormSelect
                    label="Cabang"
                    name="cabang_id"
                    value={form.cabang_id}
                    onChange={(e) => 
                        setForm((prevState) => {
                            return { ...prevState, [e.target.name]: e.target.value };
                        })
                    }
                    error={errors.cabang_id}
                >
                    <option value="">Pilih</option>
                    {dataCabang &&
                        dataCabang.length > 0 &&
                        dataCabang.map((dc, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <option value={dc.id}>{dc.nama}</option>
                                </React.Fragment>
                            );
                        })}
                </FormSelect>
                <FormInput
                    label="Judul"
                    name="judul"
                    value={form.judul}
                    onChange={(e) => 
                        setForm((prevState) => {
                            return { ...prevState, [e.target.name]: e.target.value };
                        })
                    }
                    error={errors.nama}
                />
                <FormInput
                    label="Deskripsi"
                    name="deskripsi"
                    value={form.deskripsi}
                    onChange={(e) => 
                        setForm((prevState) => {
                            return { ...prevState, [e.target.name]: e.target.value };
                        })
                    }
                    error={errors.nama}
                />
                <FormTextarea
                    label="Link Tutorial"
                    name="link"
                    value={form.link}
                    onChange={(e) => 
                        setForm((prevState) => {
                            return { ...prevState, [e.target.name]: e.target.value };
                        })
                    }
                    error={errors.nama}
                />
                
                <div className="btn-group w-100">
                    <button type="submit" className="btn btn-sm btn-success-2">
                    Simpan perubahaan
                    </button>
                    <button
                    type="button"
                    className="btn btn-sm btn-dark border-0"
                    onClick={handleClose}
                    >
                    <i className='fa fa-fw fa-sm fa-arrow-left'/> Kembali
                    </button>
                </div>
            </form>
        </LayoutModal>
        </>
    )
}

export default TutorialSistem