import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  LayoutTable,
  LayoutThead,
  TableNoData,
} from "../../../components/layouts/Table";
import SectionPagination from "../../../components/sections/Pagination";
import { formatDate } from "../../../helpers";
import { userState } from "../../../storage/auth";
import { getStatusItems } from "../helpers";
import { PurchaseRequestContext } from "../Routes";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../../components/layouts/TableNew";
import SmallButton from "../../../components/forms/SmallButton";

const Pusat = ({ mainUrl, onFetchData }) => {
  const { isLoaded, purchaseRequest, setPage } = useContext(
    PurchaseRequestContext
  );

  const user = useRecoilValue(userState);

  useEffect(() => {
    onFetchData();
  }, [onFetchData]);

  if (user.cabang === "pusat") {
    return (
      <>
        <TableNew>
          <Thead>
            <Tr className="text-center">
              <Th>No</Th>
              <Th>Nama</Th>
              <Th>Keperluan</Th>
              <Th>Tanggal Request</Th>
              <Th>Tanggal Dibutuhkan</Th>
              <Th>Jumlah</Th>
              <Th>Approval Atasan</Th>
              <Th>Approval Kacab</Th>
              <Th>Approval Dir</Th>
              <Th>Items</Th>
              <Th>bars</Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoaded &&
            purchaseRequest.data &&
            purchaseRequest.data.length > 0 ? (
              purchaseRequest.data.map((pr, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center">
                      {purchaseRequest.from + index}
                    </Td>
                    <Td className="text-center">
                      {pr.pegawai && pr.pegawai.nama}
                    </Td>
                    <Td className="text-center">{pr.untuk}</Td>
                    <Td className="text-center">
                      {formatDate(pr.tanggal_request)}
                    </Td>
                    <Td className="text-center">
                      {formatDate(pr.tanggal_dibutuhkan)}
                    </Td>
                    <Td className="text-center">{pr.jumlah}</Td>
                    <Td className="text-center">
                      {parseInt(pr.status) === 1 && (
                        <span className="text-success">Approved</span>
                      )}
                      {parseInt(pr.status) === 9 && (
                        <span className="text-danger">Reject</span>
                      )}
                      {!pr.status && "-"}
                    </Td>
                    <Td className="text-center">
                      {parseInt(pr.approval_kacab) === 1 && (
                        <span className="text-success">Approved</span>
                      )}
                      {parseInt(pr.approval_kacab) === 9 && (
                        <span className="text-danger">Reject</span>
                      )}
                      {!pr.approval_kacab && "-"}
                    </Td>
                    <Td className="text-center">
                      {parseInt(pr.approval_dir) === 1 && (
                        <span className="text-success">Approved</span>
                      )}
                      {parseInt(pr.approval_dir) === 9 && (
                        <span className="text-danger">Reject</span>
                      )}
                      {!pr.approval_dir && "-"}
                    </Td>
                    <Td className="text-center">
                      {getStatusItems(pr.purchase_request_list)}
                    </Td>
                    <Td className="text-center">
                      <SmallButton to={`${mainUrl}/${pr.id}`} type="show"/>
                    </Td>
                  </Tr>
                );
              })
            ) : (
              <TableNoData col={11} isLoaded={isLoaded} />
            )}
          </Tbody>
        </TableNew>
        <SectionPagination
          links={purchaseRequest.links}
          from={purchaseRequest.from}
          to={purchaseRequest.to}
          total={purchaseRequest.total}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return "";
  }
};

export default Pusat;
