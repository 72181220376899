import React, { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { deletePendaftar, patchPendaftar } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import { saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";

const PendaftarEdit = (props) => {
  const { pendaftar_id, onFetchData, pendaftar } = props;
  const user = useRecoilValue(userState);
  const [modal, setModal] = useState(false);
  const tagForm = useRef();
  const [form, setForm] = useState({});

  const handleClose = () => {
    setForm({});
    if (tagForm.current) {
      tagForm.current.reset();
    }
    setModal(false);
  };

  const handleBatal = (id) => {
    saConfirm("warning", "Yakin batal kuliah ?").then((res) => {
      if (res.isConfirmed) {
        patchPendaftar(id, { status: 9 }).then((res) => {
          if (res.data === "success") {
            onFetchData();
            saAlert("success", "Berhasil ubah status menjadi batal daftar");
          }
        });
      }
    });
  };

  const handleDelete = (id) => {
    saConfirm("warning", "Yakin hapus pendaftar ?").then((res) => {
      if (res.isConfirmed) {
        deletePendaftar(id).then((res) => {
          if (res.data === "success") {
            onFetchData();
            saAlert("success", "Berhasil hapus pendaftar !");
          }
        });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    saConfirm("warning", "Yakin update data pendaftar ?").then((res) => {
      if (res.isConfirmed) {
        patchPendaftar(pendaftar.id, form).then((res) => {
          if (res.data === "success") {
            onFetchData();
            handleClose();
            saAlert("success", "Berhasil update data pendaftar");
          }
        });
      }
    });
  };

  const toCapitalizeText = (text = "") => {
    if (text !== "") {
      let toLowerCase = text.toLowerCase();
      let capitalizeText =
        toLowerCase.charAt(0).toUpperCase() + toLowerCase.slice(1);
      return capitalizeText;
    }
  };

  return (
    <>
      <button 
        className="btn btn-sm btn-success-2 border-0 py-0 text-sm rounded mx-1"
        onClick={() => {
          setForm(pendaftar);
          setModal(true);
        }}
      >
      <i className="fa fa-fw fa-sm fa-pen"/>
      </button>
      <LayoutModal
        title={`Edit data ${pendaftar.nama}`}
        modal={modal}
        onClose={handleClose}
      >
        {form && (
          <form onSubmit={handleSubmit} ref={tagForm}>
            {user.jabatan === "IT Programmer" && (
              <div className="row">
                <div className="col-md">
                  <FormInput
                    label="Afiliasi"
                    name="afiliasi_id"
                    value={form.afiliasi_id}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-md">
                  <FormInput
                    label="Status"
                    name="status"
                    value={form.status}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-md">
                  <FormSelect
                    label="Cabang"
                    name="cabang"
                    value={form.cabang}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  >
                    <option value="denpasar">Denpasar</option>
                    <option value="singaraja">Singaraja</option>
                    <option value="karangasem">Karangasem</option>
                  </FormSelect>
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md-6">
                <FormInput
                  label="Nama Pendaftar"
                  name="nama"
                  value={form.nama}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                />
              </div>
              <div className="col-md-6 bg-light">
                <FormInput
                  type="number"
                  label="Tahun Ajaran"
                  name="tahun_ajaran"
                  value={form.tahun_ajaran}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return { ...prevState, [e.target.name]: e.target.value };
                    })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <FormInput
                  label="Tempat Lahir"
                  name="tempat_lahir"
                  value={form.tempat_lahir}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                />
              </div>
              <div className="col-md">
                <FormInput
                  label="Tanggal Lahir"
                  name="tanggal_lahir"
                  type="date"
                  value={form.tanggal_lahir}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <FormInput
                  label="Nama Ayah"
                  name="nama_ayah"
                  value={form.nama_ayah}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                />
              </div>
              <div className="col-md">
                <FormInput
                  label="Nama Ibu"
                  name="nama_ibu"
                  value={form.nama_ibu}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                />
              </div>
            </div>
            <FormTextarea
              label="Alamat Orang Tua"
              name="alamat_ortu"
              value={form.alamat_ortu}
              onChange={(e) =>
                setForm((prevState) => {
                  return {
                    ...prevState,
                    [e.target.name]: e.target.value,
                  };
                })
              }
            />
            <div className="row">
              <div className="col-md">
                <FormInput
                  label="No HP"
                  name="no_hp"
                  value={form.no_hp}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                />
              </div>
              <div className="col-md">
                <FormInput
                  label="No HP Orang Tua"
                  name="no_hp_ortu"
                  value={form.no_hp_ortu}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <FormInput
                  label="Email"
                  name="email"
                  type="email"
                  value={form.email}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                />
              </div>
              <div className="col-md">
                <FormInput
                  label="Facebook"
                  name="fb"
                  value={form.fb}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                />
              </div>
              <div className="col-md">
                <FormInput
                  label="Instagram"
                  name="ig"
                  value={form.ig}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <FormSelect
                  label="Jenis Kelamin"
                  name="jenis_kelamin"
                  value={toCapitalizeText(form.jenis_kelamin)}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                >
                  <option value="Laki-laki">Laki-Laki</option>
                  <option value="Perempuan">Perempuan</option>
                </FormSelect>
              </div>
              <div className="col-md">
                <FormSelect
                  label="Agama"
                  name="agama"
                  value={toCapitalizeText(form.agama)}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                >
                  <option value="Islam">Islam</option>
                  <option value="Katolik">Katolik</option>
                  <option value="Protestan">Protestan</option>
                  <option value="Hindu">Hindu</option>
                  <option value="Budha">Budha</option>
                </FormSelect>
              </div>
            </div>
            {user.jabatan === "IT Programmer" && (
              <div className="row">
                <div className="col-12">
                  <FormInput
                    label="Asal Sekolah"
                    name="asal_sekolah"
                    value={form.asal_sekolah}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-md-6">
                  <FormInput
                    label="Tahun Tamat"
                    name="tahun_tamat"
                    value={form.tahun_tamat}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-md">
                  <FormInput
                    label="Jurusan"
                    name="jurusan"
                    value={form.jurusan}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div className="col-md">
                <FormSelect
                  label="Waktu Belajar"
                  name="waktu_belajar"
                  value={toCapitalizeText(form.waktu_belajar)}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                >
                  <option value="Reguler">Reguler</option>
                  <option value="Karyawan">Karyawan</option>
                </FormSelect>
              </div>
              <div className="col-md">
                <FormSelect
                  label="Prodi"
                  name="prodi"
                  value={form.prodi}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.value,
                      };
                    })
                  }
                >
                  <option
                    value={"Manajemen Administrasi Rumah Sakit | 1 Tahun (D1)"}
                  >
                    Manajemen Administrasi Rumah Sakit | 1 Tahun (D1)
                  </option>
                  <option
                    value={"Manajemen Administrasi Rumah Sakit | 2 Tahun (D2)"}
                  >
                    Manajemen Administrasi Rumah Sakit | 2 Tahun (D2)
                  </option>
                  <option
                    value={"Manajemen Administrasi Bisnis | 1 Tahun (D1)"}
                  >
                    Manajemen Administrasi Bisnis | 1 Tahun (D1)
                  </option>
                  <option
                    value={"Manajemen Administrasi Bisnis | 2 Tahun (D2)"}
                  >
                    Manajemen Administrasi Bisnis | 2 Tahun (D2)
                  </option>
                  <option
                    value={"Komputer Akuntansi & Perpajakan | 1 Tahun (D1)"}
                  >
                    Komputer Akuntansi & Perpajakan | 1 Tahun (D1)
                  </option>
                  <option
                    value={"Komputer Akuntansi & Perpajakan | 2 Tahun (D2)"}
                  >
                    Komputer Akuntansi & Perpajakan | 2 Tahun (D2)
                  </option>
                  <option
                    value={"Manajemen Informatika & Komputer | 1 Tahun (D1)"}
                  >
                    Manajemen Informatika & Komputer | 1 Tahun (D1)
                  </option>
                  <option
                    value={"Manajemen Informatika & Komputer | 2 Tahun (D2)"}
                  >
                    Manajemen Informatika & Komputer | 2 Tahun (D2)
                  </option>
                  <option
                    value={"Desain Grafis, Multimedia & Animasi | 1 Tahun (D1)"}
                  >
                    Desain Grafis, Multimedia & Animasi | 1 Tahun (D1)
                  </option>
                  <option
                    value={"Desain Grafis, Multimedia & Animasi | 2 Tahun (D2)"}
                  >
                    Desain Grafis, Multimedia & Animasi | 2 Tahun (D2)
                  </option>
                  <option
                    value={"Manajemen Keuangan & Perbankan | 2 Tahun (D2)"}
                  >
                    Manajemen Keuangan & Perbankan | 2 Tahun (D2)
                  </option>
                  <option value={"Bisnis Digital | 1 Tahun (D1)"}>
                    Bisnis Digital | 1 Tahun (D1)
                  </option>
                  <option value={"Bisnis Digital | 2 Tahun (D2)"}>
                    Bisnis Digital | 2 Tahun (D2)
                  </option>
                </FormSelect>
              </div>
            </div>
            {user.jabatan === "IT Programmer" && (
              <div className="row">
                <div className="col-md-4">
                  <FormInput
                    label="Informasi"
                    name="informasi"
                    value={form.informasi}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    label="Keputusan Kuliah"
                    name="keputusan_kuliah"
                    value={form.keputusan_kuliah}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    label="Refrensi"
                    name="refrensi"
                    value={form.refrensi}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    label="Promo"
                    name="promo"
                    value={form.promo}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  ></FormInput>
                </div>
                <div className="col-md-4">
                  <FormInput
                    label="Nomor Surat"
                    name="no_surat"
                    value={form.no_surat}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  />
                </div>
                <div className="col-md-4">
                  <FormInput
                    label="KDBB"
                    name="kdbb"
                    value={form.kdbb}
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.value,
                        };
                      })
                    }
                  />
                </div>
              </div>
            )}
            <div className="border border-silver rounded-3 text-center p-4 mb-3 mt-2">
              <p className="mb-2">
                Klik tombol dibawah ini apabila pendaftar batal kuliah.
              </p>
              <button
                onClick={() => handleBatal(pendaftar.id)}
                className="btn btn-danger btn-sm text-nowrap px-4 mx-1"
                type="button"
              >
                <i className="fa fa-fw fa-xmark"/> Batal Kuliah
              </button>
              <button
                onClick={() => handleDelete(pendaftar_id)}
                className="btn btn-danger btn-sm mx-1"
                type="button"
              >
                <i className="fa fa-fw fa-trash-alt"></i> Hapus Pendaftar
              </button>
            </div>
            <div>
              <button className="btn btn-sm btn-primary-3 mx-1">Simpan Perubahan</button>
              <button
                type="button"
                onClick={handleClose}
                className="btn btn-sm btn-dark mx-1"
              >
                <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
              </button>
            </div>
          </form>
        )}
      </LayoutModal>
    </>
  );
};

export default PendaftarEdit;
