import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchDosen } from "../../api";
import {
  FilterCabang,
  FilterSearch,
  FilterTahunAjaran,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { userState } from "../../storage/auth";
import { semesterAjaranState, tahunAjaranState } from "../../storage/mhs";
import { titleState } from "../../storage/pages";
import DosenCreate from "./Create";
import CreateSkDosen from "./CreateSkDosen";
import DosenDelete from "./Delete";
import DosenEdit from "./Edit";
import DosenResetPw from "./ResetPw";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";
import ShowHonor from "./ShowHonor";
import SlipCreate from "../files/SlipCreate";
import AngketCreate from "../files/AngketCreate";
import HasilMonitoringCreate from "../files/HasilMonitoringCreate";
import ShowAngket from "./ShowAngket";
import ShowHasilMonitoring from "./ShowHasilMonitoring";

const DosenAkademik = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const tA = useRecoilValue(tahunAjaranState);
  const sA = useRecoilValue(semesterAjaranState);

  const [isLoaded, setIsLoaded] = useState(false);

  const [items, setItems] = useState({});
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [cabang, setCabang] = useState("");
  const [tahunAjaran, setTahunAjaran] = useState(tA);
  const [semesterAjaran, setSemesterAjaran] = useState(sA);

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchDosen(
        page,
        `with_sk_dosen=1&search=${search}&cabang=${cabang}&tahunAjaran=${tahunAjaran}&semesterAjaran=${semesterAjaran}`
      );
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.error(err.response);
    }
  }, [page, search, cabang, tahunAjaran, semesterAjaran]);

  useEffect(() => {
    setTitle("Data Dosen");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        <div className="row">
          {user.group === "Akademik" && <div className="col-lg-3"><DosenCreate onFetchData={fetchData} /></div>}
          {user.group === "Akademik" && <div className="col-lg-3"><SlipCreate onFetchData={fetchData}/></div>}
          {user.group === "Akademik" && <div className="col-lg-3"><AngketCreate onFetchData={fetchData}/></div>}
          {user.group === "Akademik" && <div className="col-lg-3"><HasilMonitoringCreate onFetchData={fetchData}/></div>}
        </div>
        <div className="row mb-2">
          <div className="col-lg-6">
            <FilterTahunAjaran
              onChangeTahun={(res) => setTahunAjaran(res)}
              onChangeSemester={(res) => setSemesterAjaran(res)}
              valueTahun={tahunAjaran}
              valueSemester={semesterAjaran}
            />
          </div>
          <div className="col-lg-6">
            <FilterSearch onChange={(res) => setSearch(res)} />
          </div>
        </div>
        {user.cabang === "pusat" && (
          <div className="row mb-4">
            <div className="col-lg-6">
              <FilterCabang onChange={(res) => setCabang(res)} />
            </div>
          </div>
        )}
        <TextOnOffMhs>
          <p className="mb-0 fw-semibold">Untuk melihat SK Dosen silahkan pilih tahun ajaran pada menu filter diatas.</p>
        </TextOnOffMhs>
        {items.data.length > 0 ? (
          <div className="row">
            <div className="col-2 pe-0">
              <TableNew>
                <Thead>
                  <Tr className="text-center">
                    <Th className="text-nowrap">No</Th>
                    <Th className="text-nowrap">Kode Dosen</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-center text-nowrap">
                          {items.from + index}
                        </Td>
                        <Td className="text-center text-nowrap">{i.nip}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-7 px-0">
              <TableNew>
                <Thead>
                  <Tr className="text-center">
                    <Th className="text-nowrap">Nama</Th>
                    <Th className="text-nowrap">Cabang</Th>
                    <Th className="text-nowrap">No HP</Th>
                    <Th className="text-nowrap">Email</Th>
                    <Th className="text-nowrap">Honor KRS</Th>
                    <Th className="text-nowrap">Honor Kursus</Th>
                    <Th className="text-nowrap">Honor Membimbing LPK</Th>
                    <Th className="text-nowrap">Honor Membimbing LTA</Th>
                    <Th className="text-nowrap">Honor Membimbing TA</Th>
                    <Th className="text-nowrap">Honor Menguji</Th>
                    <Th className="text-nowrap">Honor Remidi</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-nowrap">{i.nama}</Td>
                        <Td className="text-nowrap">{i.cabang}</Td>
                        <Td className="text-nowrap">{i.no_hp}</Td>
                        <Td className="text-nowrap">{i.email}</Td>
                        <Td className="text-nowrap">{i.honor}</Td>
                        <Td className="text-nowrap">{i.honor_kursus}</Td>
                        <Td className="text-nowrap">
                          {i.honor_membimbing_lpk}
                        </Td>
                        <Td className="text-nowrap">
                          {i.honor_membimbing_lta}
                        </Td>
                        <Td className="text-nowrap">{i.honor_membimbing_ta}</Td>
                        <Td className="text-nowrap">{i.honor_menguji}</Td>
                        <Td className="text-nowrap">{i.honor_remidi}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-3 ps-0">
              <TableNew>
                <Thead>
                  <Tr className="text-center">
                    <Th className="text-nowrap">SK Dosen</Th>
                    {user.group === "Akademik" && (
                      <Th className="text-nowrap">
                        <i className="fa fa-fw fa-cog" />
                      </Th>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-nowrap text-center">
                          {tahunAjaran && semesterAjaran ? (
                            <>
                              <CreateSkDosen
                                dosen={i}
                                tahunAjaran={tahunAjaran}
                                semesterAjaran={semesterAjaran}
                                has_sk_dosen={
                                  i.sk_dosen && i.sk_dosen.length > 0
                                    ? true
                                    : false
                                }
                                onFetchData={fetchData}
                              />
                              {i.sk_dosen && i.sk_dosen.length > 0 && (
                                <PrintSkDosen
                                  dosen={i}
                                  tahunAjaran={tahunAjaran}
                                  semesterAjaran={semesterAjaran}
                                />
                              )}
                            </>
                          ) : (
                            "-"
                          )}
                        </Td>
                        {user.group === "Akademik" && (
                          <Td className="text-nowrap text-center">
                            {user.group === "Akademik" && (
                              <DosenResetPw dosen={i} />
                            )}
                            <ShowHonor dosen={i} tahunAjaran={tahunAjaran}/>
                            <ShowAngket dosen={i} tahunAjaran={tahunAjaran} semester={semesterAjaran}/>
                            <ShowHasilMonitoring dosen={i} tahunAjaran={tahunAjaran} semester={semesterAjaran}/>
                            <DosenEdit dosen={i} onFetchData={fetchData} />
                            <DosenDelete dosen={i} onFetchData={fetchData} />
                          </Td>
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
          </div>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

const PrintSkDosen = ({ dosen = {}, tahunAjaran, semesterAjaran }) => {
  return (
    <>
      <button
        type="button"
        title="Print SK Dosen"
        className="btn btn-sm btn-primary-2 text-sm py-0 px-1 mx-2"
        onClick={() =>
          window.open(
            `/print-sk-dosen/${
              dosen.sk_dosen &&
              dosen.sk_dosen
                .filter(
                  (sd) =>
                    parseInt(sd.semester) === parseInt(semesterAjaran) &&
                    parseInt(sd.tahun_ajaran) === parseInt(tahunAjaran)
                )
                .map((sd) => sd.id)
            }`
          )
        }
      >
        <i className="fa fa-fw fa-sm fa-print" />
      </button>
      <button
        type="button"
        title="Lampiran Print SK Dosen"
        className="btn btn-sm btn-dark border-0 text-sm py-0 px-1"
        onClick={() =>
          window.open(
            `/print-lampiran-sk-dosen/${
              dosen.sk_dosen &&
              dosen.sk_dosen
                .filter(
                  (sd) =>
                    parseInt(sd.semester) === parseInt(semesterAjaran) &&
                    parseInt(sd.tahun_ajaran) === parseInt(tahunAjaran)
                )
                .map((sd) => sd.id)
            }`
          )
        }
      >
        <i className="fa fa-fw fa-sm fa-print" />
      </button>
    </>
  );
};

export default DosenAkademik;
