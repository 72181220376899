import React from "react";
import Swal from "sweetalert2";

export const getOrigin = () => {
  return window.location.origin === "https://sim.alfaprima.id"
    ? "https://dev.alfaprima.id"
    // : "http://127.0.0.1:7777";
    : "http://10.10.250.126:7777";
};

export const isWindowView = () => {
  if (window.innerWidth >= 768) {
    return true;
  } else {
    return false;
  }
};

export const getCurrency = (val, n = 2, x = 0) => {
  if (val) {
    let num = parseInt(val);
    let re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
    return num.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$&.");
  }
  return val;
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const getThName = (name) => {
  return (
    <span style={{ textTranform: "capitalize" }}>
      {name.split("_").join(" ")}
    </span>
  );
};

export const getJurusan = (id) => {
  let number = parseInt(id);
  if (number === 1) {
    return "Manajemen Administrasi Rumah Sakit";
  } else if (number === 2) {
    return "Manajemen Administrasi Bisnis";
  } else if (number === 3) {
    return "Manajemen Informatika dan Komputer";
  } else if (number === 4) {
    return "Komputer Akuntansi dan Perpajakan";
  } else if (number === 5) {
    return "Desain Grafis, Multemedia, dan Animasi";
  } else if (number === 8) {
    return "Manajemen Keuangan dan Perbankan";
  } else if (number === 10) {
    return "Digital Marketing dan Komunikasi";
  }
};

export const getJurusanShort = (id) => {
  let number = parseInt(id);
  if (number === 1) {
    return "MARS";
  } else if (number === 2) {
    return "MAB";
  } else if (number === 3) {
    return "MIK";
  } else if (number === 4) {
    return "KAP";
  } else if (number === 5) {
    return "DG";
  } else if (number === 8) {
    return "MKP";
  } else if (number === 10) {
    return "DMK";
  }
};

export const getJenisWaktu = (id) => {
  let number = parseInt(id);
  if (number === 1) {
    return "Pagi";
  } else {
    return "Sore";
  }
};

export const getHari = (id) => {
  let number = parseInt(id);
  if (number === 1) {
    return "Senin";
  } else if (number === 2) {
    return "Selasa";
  } else if (number === 3) {
    return "Rabu";
  } else if (number === 4) {
    return "Kamis";
  } else if (number === 5) {
    return "Jumat";
  } else if (number === 6) {
    return "Sabtu";
  } else if (number === 0) {
    return "Minggu";
  }
};

export const getBulan = (bulan) => {
  let number = parseInt(bulan);
  if (number === 1) {
    return 'Januari';
  } else if (number === 2) {
    return 'Februari';
  } else if (number === 3) {
    return 'Maret';
  } else if (number === 4) {
    return 'April';
  } else if (number === 5) {
    return 'Mei';
  } else if (number === 6) {
    return 'Juni';
  } else if (number === 7) {
    return 'Juli';
  } else if (number === 8) {
    return 'Agustus';
  } else if (number === 9) {
    return 'September';
  } else if (number === 10) {
    return 'Oktober';
  } else if (number === 11) {
    return 'November';
  } else if (number === 12) {
    return 'Desember';
  }
}

export const getGrade = (nilai = undefined) => {
  let data = parseInt(nilai);
  if (data) {
    if (data === 1) {
      return "D";
    } else if (data === 2) {
      return "C";
    } else if (data === 3) {
      return "B";
    } else if (data === 4) {
      return "A";
    }
  } else {
    return "E";
  }
};

export const getHuruf = (nilai = undefined) => {
  let data = parseFloat(nilai);
  if (data) {
    if (data >= 85) {
      return "A";
    } else if (data >= 75) {
      return "B";
    } else if (data >= 65) {
      return "C";
    } else if (data >= 45) {
      return "D";
    }
  } else {
    return "E";
  }
};

export const getStatusPengajaran = (pengajaran, text = false) => {
  if (text) {
    if (parseInt(pengajaran.status) === 9) {
      return <span className="text-danger"><i className="fa fa-fw fa-sm fa-circle-xmark"/> Cancelled</span>;
    } else if (pengajaran.selesai_id) {
      return <span className="text-success"><i className="fa fa-fw fa-sm fa-circle-check"/> Success</span>;
    } else if (pengajaran.mulai_id) {
      return <span className="text-warning"><i className="fa fa-fw fa-sm fa-circle-pause fa-fade"/> Runing...</span>;
    } else if (pengajaran.upt_id) {
      return <span className="text-primary"><i className="fa fa-fw fa-sm fa-circle-play"/> Ready</span>;
    } else if (pengajaran.akademik_id) {
      return <span className="text-black"><i className="fa fa-fw fa-sm fa-spinner fa-spin"/> Waiting...</span>;
    } else {
      return <span className="text-secondary">Created</span>;
    }

    // ISO
    // if (pengajaran.status === 9) {
    //   return <span className="text-danger">"Cancelled"</span>;
    // } else if (pengajaran.selesai_id) {
    //   return <span className="text-success">"Success"</span>;
    // } else if (pengajaran.akademik_id) {
    //   return <span className="text-warning">"Waiting"</span>;
    // } else {
    //   return <span>"Created"</span>;
    // }
    // End ISO
  } else {
    if (parseInt(pengajaran.status) === 9) {
      return "text-danger";
    } else if (pengajaran.selesai_id) {
      return "text-success";
    } else if (pengajaran.mulai_id) {
      return "text-warning";
    } else if (pengajaran.upt_id) {
      return "text-primary";
    } else if (pengajaran.akademik_id) {
      return "text-black";
    } else {
      return "";
    }

    // ISO
    // if (pengajaran.status === 9) {
    //   return "text-danger";
    // } else if (pengajaran.selesai_id) {
    //   return "text-success";
    // } else if (pengajaran.akademik_id) {
    //   return "text-warning";
    // } else {
    //   return "";
    // }
    // End ISO

    // if (pengajaran.status === 9) {
    //   return "text-danger";
    // } else {
    //   return "text-success";
    // }
  }
};
export const getIconStatusPengajaran = (pengajaran, tooltip = false, top, right) => {
    if (parseInt(pengajaran.status) === 9) {
      return (
        <div className={`position-relative d-inline-block ${tooltip && 'parent'}`}>
          <i className="align-middle fa fa-lg fa-fw fa-circle-xmark text-danger"/>
          <div className="position-absolute child bg-primary text-white rounded px-4" style={{top: `${top}`, right: `${right}`}}>Kelas dibatalkan</div>
        </div>
      );
    } else if (pengajaran.selesai_id) {
      return (
        <div className={`position-relative d-inline-block ${tooltip && 'parent'}`}>
          <i className="align-middle fa fa-lg fa-fw fa-circle-check text-success"/>
          <div className="position-absolute child bg-primary text-white rounded px-4" style={{top: `${top}`, right: `${right}`}}>Kelas selesai</div>
        </div>
      );
    } else if (pengajaran.mulai_id) {
      return (
        <div className={`position-relative d-inline-block ${tooltip && 'parent'}`}>
          <i className="align-middle fa fa-lg fa-fw fa-circle-pause fa-fade text-info"/>
          <div className="position-absolute child bg-primary text-white rounded px-4" style={{top: `${top}`, right: `${right}`}}>Kelas sedang berlangsung</div>
        </div>
      );
    } else if (pengajaran.upt_id) {
      return (
        <div className={`position-relative d-inline-block ${tooltip && 'parent'}`}>
          <i className="align-middle fa fa-lg fa-fw fa-circle-up text-warning"/>
          <div className="position-absolute child bg-primary text-white rounded px-4" style={{top: `${top}`, right: `${right}`}}>Diperbarui oleh UPT</div>
        </div>
      );
    } else if (pengajaran.akademik_id) {
      return (
        <div className={`position-relative d-inline-block ${tooltip && 'parent'}`}>
          <i className="align-middle fa fa-lg fa-fw fa-circle-up text-primary"/>
          <div className="position-absolute child bg-primary text-white rounded px-4" style={{top: `${top}`, right: `${right}`}}>Diperbarui oleh Akademik</div>
        </div>
      );
    } else {
      return (
        <div className={`position-relative d-inline-block ${tooltip && 'parent'}`}>
          <i className="align-middle fa fa-lg fa-fw fa-circle text-secondary"/>
          <div className="position-absolute child bg-primary text-white rounded px-4" style={{top: `${top}`, right: `${right}`}}>Kelas baru</div>
        </div>
      );
    }
};

// Date Time
export const namaBulan = [
  "Januari",
  "Februari",
  "Maret",
  "April",
  "Mei",
  "Juni",
  "Juli",
  "Agustus",
  "September",
  "Oktober",
  "November",
  "Desember",
];

export const getGenapGanjil = (id) => {
  let number = parseInt(id);
  if (number === 1) {
    return "Ganjil";
  } else if (number === 2) {
    return "Genap";
  }
};

export const tahunAngkatan = [
  2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012,
  2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025,
];

export const formDate = (date) => {
  let arr = date.split(":");
  return `${arr[0]}:${arr[1]}`;
};

export const formatDateTimestap = (date, enter = true) => {
  if (date) {
    let fullDate = date.split("T")[0];
    let fullTime = date.split("T")[1];
    fullTime = fullTime.split(".")[0];
    return (
      <>
        {fullDate.split("-").reverse().join("-")}
        {enter ? <br /> : ` || `}
        {fullTime &&
          fullTime.split(":")[0] + "." + fullTime.split(":")[1] + " WITA"}
      </>
    );
  } else {
    return "";
  }
};

export const formatDate = (date, enter = true) => {
  if (date) {
    let d = String(date);
    let fullDateFull = d.split(" ")[0];
    let fullTime = d.split(" ")[1];

    let dateSplit = fullDateFull.split("-");

    let fullDate = `${dateSplit[0].slice(2, 4)}-${dateSplit[1]}-${
      dateSplit[2]
    }`;

    return (
      <>
        {fullDate.split("-").reverse().join("-")}
        {enter ? <br /> : ` || `}
        {fullTime &&
          fullTime.split(":")[0] + "." + fullTime.split(":")[1] + " WITA"}
      </>
    );
  } else {
    return "";
  }
};

export const formatDiffDate = (date, enter = true) => {
  if (date) {
    let datesplit = date.split("|");
    return datesplit.map((i, index) => {
      return (
        <React.Fragment key={index}>
          {i} {datesplit.length - 1 !== index && (enter ? <br /> : ` || `)}
        </React.Fragment>
      );
    });
  } else {
    return "";
  }
};

// Alert
export const saConfirm = (
  icon = "warning",
  title = "Apakah kamu yakin?",
  text = "Tekan enter untuk melanjutkan.",
  showCancelButton = true
) => {
  return Swal.fire({
    title,
    text,
    icon,
    showCancelButton,
    iconColor: "#ffb901",
    confirmButtonText: "Lanjut",
    cancelButtonText: "Batal",
    customClass: {
      popup: 'custom-popup-class',
      title: 'custom-title-class',
      htmlContainer: 'custom-text-class',
      confirmButton: 'custom-confirm-btn-class',
      cancelButton: 'custom-cancel-btn-class',
    },
    showClass: {
      popup: `
        animate__animated
        animate__fadeIn
        animate__faster
      `
    },
    hideClass: {
      popup: `
        animate__animated
        animate__fadeOut
        animate__faster
      `
    }
  });
};

export const saAlert = (icon = "", title = "", text = "") => {
  Swal.fire({
    icon,
    title,
    text,
    confirmButtonText: 'Oke',
    // iconColor: "#00c569",
    customClass: {
      popup: 'custom-popup-class',
      title: 'custom-title-class',
      htmlContainer: 'custom-text-class',
      confirmButton: 'custom-confirm-btn-class',
    },
    showClass: {
      popup: `
        animate__animated
        animate__fadeIn
        animate__faster
      `
    },
    hideClass: {
      popup: `
        animate__animated
        animate__fadeOut
        animate__faster
      `
    }
  });
};

export const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  iconColor: "#00c569",
  customClass: {
    popup: 'custom-popup-class',
    title: 'custom-title-class',
    progress: 'custom-progress',
  },
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
  showClass: {
    popup: `
      animate__animated
      animate__fadeIn
      animate__faster
    `
  },
  hideClass: {
    popup: `
      animate__animated
      animate__fadeOut
      animate__faster
    `
  }
});

export const saToast = (icon = "", title = "") => {
  Toast.fire({
    icon,
    title,
  });
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getOnOffMhs = (mhs) => {
    if (parseInt(mhs.status) === 9) {
      if (parseInt(mhs.nonaktif_by) === 1) {
        return "bg-invalid";
      }
      if (parseInt(mhs.nonaktif_by) === 2) {
        return "bg-invalid-2";
      }
    }
}

export const getJenjang = (mhs) => {
  if (mhs.jenjang === "D1") {
    return "Program 1 Tahun";
  }
  if (mhs.jenjang === "D2") {
    return "Program 2 Tahun";
  }
}

export const getAlamatCabang = (pegawai) => {
  if (pegawai.cabang === "denpasar" || pegawai.cabang === "pusat") {
    return "Jl.Hayam Wuruk No.186, Denpasar";
  }
  if (pegawai.cabang === "singaraja") {
    return "Jl.Serma Karma No.88, Baktiseraga, Buleleng";
  }
  if (pegawai.cabang === "karangasem") {
    return "Jl.Nenas, Subagan, Amlapura, Karangasem";
  }
}
export const getNoHpCabang = (pegawai) => {
  if (pegawai.cabang === "denpasar" || pegawai.cabang === "pusat") {
    return "0361-232422";
  }
  if (pegawai.cabang === "singaraja") {
    return "0362-3435453";
  }
  if (pegawai.cabang === "karangasem") {
    return "0363-2787499";
  }
}

export const formatTimeStamp = (value) => {
  const dateObject = new Date(value);

  return `${dateObject.getDate()} ${dateObject.toLocaleString('id-ID', { month: 'long' })} ${dateObject.getFullYear()} ${dateObject.toLocaleTimeString()}`;
}