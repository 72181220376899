import React, { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { patchPerusahaan, postPerusahaan } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import { getOrigin, saAlert } from "../../helpers";
import { userState } from "../../storage/auth";

const PerusahaanEdit = (props) => {
  const { perusahaan, onFetchData } = props;
  const tagForm = useRef();
  const user = useRecoilValue(userState);
  const [modal, setModal] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [fileMou, setFileMou] = useState("");
  const [fotoMou, setFotoMou] = useState("");

  const handleOpen = () => {
    setForm(perusahaan);
    setModal(true);
    setFileMou(perusahaan.bukti_mou);
    setFotoMou(perusahaan.foto_mou);
  }

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleChangeFile = (e) => {

    const file = e.target.files[0];

    if (e.target.name === "bukti_mou") {
      setForm((prevState) => {
        return { ...prevState, [e.target.name]: file };
      })
      setFileMou(form.bukti_mou);
    }

    if (e.target.name === "foto_mou") {
      setForm((prevState) => {
        return { ...prevState, [e.target.name]: file };
      })
      setFotoMou(form.foto_mou);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    form.nama && formData.append("nama", form.nama);
    form.status && formData.append("status", form.status);
    form.mou_dari && formData.append("mou_dari", form.mou_dari);
    form.mou_sampai && formData.append("mou_sampai", form.mou_sampai);
    form.nomor_mou && formData.append("nomor_mou", form.nomor_mou);
    form.alamat && formData.append("alamat", form.alamat);
    form.no_telf && formData.append("no_telf", form.no_telf);
    form.no_hp && formData.append("no_hp", form.no_hp);
    form.pic && formData.append("pic", form.pic);
    form.ultah && formData.append("ultah", form.ultah);
    form.email && formData.append("email", form.email);
    form.bukti_mou && formData.append("bukti_mou", form.bukti_mou);
    form.foto_mou && formData.append("foto_mou", form.foto_mou);

    if (
      user.cabang === "pusat" ||
      perusahaan.cabang.toLowerCase() === user.cabang.toLowerCase()
    ) {
      try {
        const res = await patchPerusahaan(perusahaan.id, formData);
        if (res.data.status === "success") {
          onFetchData();
          tagForm.current && tagForm.current.reset();
          setForm({});
          setErrors({});
          setModal(false);
          saAlert("success", "Berhasil ubah data perusahaan !");
        }
      } catch (error) {
        setErrors(error.response.data.errors); 
      }
    } else {
      saAlert("warning", "Tidak dapat mengubah data cabang lain.");
    }
  };

  return (
    <>
      <button 
        onClick={handleOpen} 
        className="btn btn-sm btn-success-2 border-0 py-0 text-sm rounded mx-1"
      >
        <i className="fa fa-fw fa-sm fa-pen"/> Edit
      </button>
      <LayoutModal
        title="Ubah Data Perusahaan"
        modal={modal}
        onClose={handleClose}
      >
        <form ref={tagForm} onSubmit={handleSubmit}>
          <div className="row">
            {user.cabang === "pusat" && (
              <div className="col-md">
                <FormSelect
                  label="Cabang"
                  name="cabang"
                  value={form.cabang ? form.cabang : ""}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return { ...prevState, [e.target.name]: e.target.value };
                    })
                  }
                >
                  <option value="">Pilih</option>
                  <option value="pusat">Pusat</option>
                  <option value="denpasar">Denpasar</option>
                  <option value="singaraja">Singaraja</option>
                  <option value="karangasem">Karangasem</option>
                </FormSelect>
              </div>
            )}
            <div className="col-md">
              <FormInput
                label="Nama"
                name="nama"
                value={form.nama}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="col-md">
              <FormSelect
                label="Status MoU"
                name="status"
                value={form.status ? form.status : ""}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              >
                <option value="">Pilih</option>
                <option value="1">Baru</option>
                <option value="2">Perpanjang</option>
                <option value="3">Belum MoU</option>
                <option value="4">Berita Acara</option>
                <option value="9">Nonaktif</option>
              </FormSelect>
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <FormInput
                type="date"
                label="MoU Dari"
                name="mou_dari"
                value={form.mou_dari}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="col-md">
              <FormInput
                label="MoU Sampai"
                type="date"
                name="mou_sampai"
                value={form.mou_sampai}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInput
                label="Nomor MoU"
                name="nomor_mou"
                value={form.nomor_mou}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
              />
            </div>
            {/* <div className="col-md-6">
              <FormInput
                label="MoU ke -"
                type="number"
                name="mou_ke"
                value={form.mou_ke}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
              />
            </div> */}
          </div>
          {/* <FormTextarea
            label="Jurusan"
            name="jurusan"
            value={form.jurusan}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
          /> */}

          <FormTextarea
            label="Alamat"
            name="alamat"
            value={form.alamat}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
          />
          <div className="row">
            <div className="from-group col-md">
              <FormInput
                label="No Telfon"
                name="no_telf"
                value={form.no_telf}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="from-group col-md">
              <FormInput
                label="No HP"
                name="no_hp"
                value={form.no_hp}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <FormInput
                label="PIC"
                name="pic"
                value={form.pic}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="col-md">
              <FormInput
                label="Ulang Tahun"
                type="date"
                name="ultah"
                value={form.ultah}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <FormInput
                label="Email"
                name="email"
                value={form.email}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div>
            <div className="col-md-6">
              {fileMou ? (
                <button onClick={() => window.open(`${getOrigin()}/api/storage/perusahaan/bukti_mou/${fileMou}`)} type="button" className="mb-3 btn btn-sm btn-primary-2 rounded-3 px-4">Buka file bukti moU</button>
              ) : (
                <button disabled type="button" className="mb-3 btn btn-sm btn-dark rounded-3 px-4">Tidak ada bukti moU</button>
              )}
              <FormInput
                label="Bukti MoU"
                type="file"
                name="bukti_mou"
                error={errors.bukti_mou}
                accept="application/pdf"
                onChange={(e) => handleChangeFile(e)}
              />
            </div>
            <div className="col-md-6">
              {fotoMou ? (
                <div className="ratio ratio-16x9 bg-silver text-center rounded-3 mb-3">
                  <img src={`${getOrigin()}/api/storage/perusahaan/foto_mou/${fotoMou}`} alt={fotoMou} className="object-fit-contain" />
                </div>
              ) : (
                <div className="ratio ratio-16x9 bg-silver text-center rounded-3 mb-3">
                  <div className="d-flex align-items-center justify-content-center">
                    <p>Belum ada bukti moU.</p>
                  </div>
                </div>
              )}
              <FormInput
                label="Foto MoU"
                type="file"
                name="foto_mou"
                error={errors.foto_mou}
                accept=".jpg, .jpeg, .png"
                onChange={(e) => handleChangeFile(e)}
              />
            </div>
            {/* <div className="col-md">
              <FormInput
                label="Kuota"
                name="kuota"
                value={form.kuota}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
              />
            </div> */}
          </div>
          <div className="row mt-2">
            <NewSubmitButton submitText="Simpan Perubahaan" onClose={handleClose}/>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default PerusahaanEdit;
