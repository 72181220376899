import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { fetchPegawai, loginByThis, resetPw } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { LayoutTopFilter } from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
// import SectionPagination from "../../components/sections/Pagination";
import { isWindowView, saAlert, saConfirm } from "../../helpers";
import { titleState } from "../../storage/pages";
import EditMasterPegawai from "./EditMaster";
import { useHistory } from "react-router-dom";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import SmallButton from "../../components/forms/SmallButton";

const PegawaiMaster = (props) => {
  const { user = {} } = props;
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [itemsNoFilter, setItemsNoFilter] = useState({});
  const [search, setSearch] = useState("");
  const [title, setTitle] = useRecoilState(titleState);

  useEffect(() => {
    setTitle("Data Pegawai");
  }, [setTitle]);

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchPegawai('all', `search=${search}`);
      const secondRes = await fetchPegawai('all');
      setItems(res.data.pegawai);
      setItemsNoFilter(secondRes.data.pegawai);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [search]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  let timer = 0;
  const handleSearch = (e) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      setSearch(e.target.value);
    }, 800);
  };



  const tableTag = useRef();
  const copyToClipboard = () => {
    const elTable = tableTag.current;

    let range, sel;

    // Ensure that range and selection are supported by the browsers
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();

      try {
        range.selectNodeContents(elTable);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(elTable);
        sel.addRange(range);
      }

      document.execCommand("copy");
    }

    sel.removeAllRanges();

    saAlert(
      "success",
      "Berhasil copy tabel ke papan clip",
      "Data dapat dipaste ke excel maupun word !"
    );
  };


  const [ukuranLayar, setUkuranLayar] = useState(true);

  useEffect(() => {
    const handleUkuranLayar = () => {
      if (window.innerWidth > 992) {
        setUkuranLayar(true);
      } else {
        setUkuranLayar(false);
      }
    }

    window.addEventListener('resize', handleUkuranLayar);
    handleUkuranLayar();

    return () => {
      window.addEventListener('resize', handleUkuranLayar);  
    }
  }, [])

  if (isLoaded) {
    return (
          <div>
            <LayoutTopFilter onChangeSearch={handleSearch} />
            {(user.jabatan === "IT Programmer" || user.group === "SDM") && items.length > 0 ? (
              <>
                <div className="row">
                  <div className="col-lg-8 pe-0">
                    <TableNew>
                      <Thead>
                        <Tr className="text-center text-nowrap">
                          <Th>No</Th>
                          <Th>ID</Th>
                          <Th>Cabang</Th>
                          <Th>NIP</Th>
                          <Th>Nama</Th>
                          <Th>Jabatan</Th>
                          <Th>Atasan I</Th>
                          <Th>Atasan II</Th>

                          {!ukuranLayar &&
                            <>
                              <Th>Group</Th>
                              <Th>Grade</Th>
                              <Th><i className="fa fa-fw fa-sm fa-cog"/></Th>
                            </>
                          }
                        </Tr>
                      </Thead>
                      <Tbody>
                        {items.map((i, index) => {
                          return (
                            <Tr className="text-nowrap" key={index}>
                              <Td className="text-center">{1 + index}</Td>
                              <Td className="text-center">{i.id}</Td>
                              <Td className="text-center">{i.cabang}</Td>
                              <Td className="text-center">{i.nip}</Td>
                              <Td>{i.nama}</Td>
                              <Td>
                                {i.pegawai_jabatan.length > 0 ? 
                                  i.pegawai_jabatan.map((pj, index) => {
                                  return (
                                    
                                    <span key={index}>
                                      {index > 0 ? ' || ' : ''}
                                      {pj.jabatan && pj.jabatan.nama}
                                    </span>
                                  )
                                }) 
                                : 
                                "-"}
                              </Td>
                              <Td>{i.atasan1 ? i.atasan1.nama : '-'}</Td>
                              <Td>{i.atasan2 ? i.atasan2.nama : '-'}</Td>
                              {!ukuranLayar &&
                                <>
                                  <Td className="text-center">{i.group}</Td>
                                  <Td className="text-center">{i.grade}</Td>
                                  <Td className="text-nowrap align-middle">
                                    <EditMasterPegawai pegawai={i} items={itemsNoFilter} onFetchData={fetchData} />
                                    {user.jabatan === "IT Programmer" &&
                                      <>
                                        <ResetPw pegawai={i} />
                                        <LoginByThis pegawai={i} />
                                      </>
                                    }
                                  </Td>
                                </>
                              }
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </TableNew>
                  </div>
                  <div className="d-none d-lg-block col-4 ps-0">
                    <TableNew>
                      <Thead>
                        <Tr className="text-center">
                          <Th>Group</Th>
                          <Th>Grade</Th>
                          <Th><i className="fa fa-fw fa-sm fa-cog"/></Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {items.map((i, index) => {
                          return (
                            <Tr className="text-nowrap" key={index}>
                              <Td className="text-center">{i.group}</Td>
                              <Td className="text-center">{i.grade}</Td>
                              <Td className="text-nowrap align-middle">
                                <EditMasterPegawai pegawai={i} items={itemsNoFilter} onFetchData={fetchData} />
                                {user.jabatan === "IT Programmer" &&
                                  <>
                                    <ResetPw pegawai={i} />
                                    <LoginByThis pegawai={i} />
                                  </>
                                }
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </TableNew>
                  </div>
                </div>
              </>
            ) : (
              <PageNoData />
            )}
  
          </div>
    );
  } else {
    return <PageLoading />;
  }
};

const LoginByThis = (props) => {
  const history = useHistory();

  const handleLogin = () => {
    saConfirm("warning", `Yakin login dengan akun : <br/> ${props.pegawai.nama}`).then((res) => {
      if (res.isConfirmed) {
        loginByThis(props.pegawai.id)
          .then((res) => {
            let _token = res.data._token.split("|")[1];
            localStorage.setItem("_token", _token);
            saAlert("success", "Berhasil ganti akun !");
            history.push('/dashboard');
            window.location.reload();
          })
          .catch((err) => console.log(err));
      }
    });
  };

  return (
    <SmallButton margin="me-1" type="custom" icon="repeat" buttonColor="primary-3" onClick={handleLogin} text="Login"/>
  );
};

const ResetPw = (props) => {
  const { pegawai } = props;

  const handleResetPw = () => {
    saConfirm("warning", `Yakin reset password akun : <br/> ${pegawai.nama}`).then((res) => {
      if (res.isConfirmed) {
        resetPw(pegawai.id)
          .then((res) => {
            if (res.data === "success") {
              saAlert("success", "Berhasil reset Password !");
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  return (
    <SmallButton margin="me-1" type="custom" icon="key" buttonColor="danger-2" onClick={handleResetPw} text="Reset Password"/>
  );
};

export default PegawaiMaster;
