import React, { useCallback, useEffect, useState } from 'react'
import RkatCreate from './RkatCreate'
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../components/layouts/TableNew'
import { deleteRkat, fetchJabatan, fetchRkat } from '../../api'
import Loading from '../../components/pages/Loading'
import RkatEdit from './RkatEdit'
import SmallButton from '../../components/forms/SmallButton'
import { getCurrency, saAlert, saConfirm } from '../../helpers'
import { useRecoilState } from 'recoil'
import { titleState } from '../../storage/pages'
import RkatList from './RkatList'

const Rkat = () => {

  const [rkat, setRkat] = useState([]);
  const [jabatan, setJabatan] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [title, setTitle] = useRecoilState(titleState);

  const getRkatAndJabatan = useCallback(async (jabatan = false) => {
    try {
      const resRkat = await fetchRkat("all");
      setRkat(resRkat.data.rkat);

      if (jabatan) {
        const resJabatan = await fetchJabatan("all");
        setJabatan(resJabatan.data.jabatan);
      }

      setIsLoaded(false);
    } catch (error) {
      console.log(error);
      setIsLoaded(false);
    }
  }, [])

  useEffect(() => {
    setTitle("RKAT");
    getRkatAndJabatan(true);
  }, [getRkatAndJabatan, setTitle]);

  const handleDelete = (id) => {
    saConfirm("warning", "Yakin untuk menghapus RKAT?").then( async (res) => {
      if (res.isConfirmed) {
        const res = await deleteRkat(id);
        if (res.data && res.data.msg) {
          getRkatAndJabatan();
          saAlert("success", res.data.msg);
        }
      }
    })
  }

  return (
    <>
      {!isLoaded ?
        <>
        <RkatCreate jabatan={jabatan} fetchData={getRkatAndJabatan}/>
      
        <TableNew>
          <Thead>
            <Tr className="text-center text-nowrap">
              <Th>No</Th>
              <Th>ID</Th>
              <Th>Jabatan</Th>
              <Th>Tahun</Th>
              <Th>Tipe</Th>
              <Th>Total Biaya</Th>
              <Th>Total Realisasi</Th>
              <Th>Sisa Biaya</Th>
              <Th><i className='fa fa-fw fa-cog'/></Th>
            </Tr>
          </Thead>
          <Tbody>
            {rkat.length > 0 ?
              rkat.map((r, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-nowrap text-center">{index + 1}</Td>
                    <Td className="text-nowrap text-center text-primary">{r.id}</Td>
                    <Td className="text-nowrap">
                      {
                        jabatan.filter((j) => parseInt(j.id) === parseInt(r.jabatan_id)).map((j) => {
                          return `[${j.cabang && j.cabang.nama}] - ${j.nama}`;
                        })
                      }
                    </Td>
                    <Td className="text-nowrap text-center">{r.tahun}</Td>
                    <Td className="text-nowrap text-center">
                      {r.type === 1 && <span className='fw-semibold text-success'><i className='fa fa-fw fa-sm fa-arrow-right'/> Pendapatan</span>}
                      {r.type === 2 && <span className='fw-semibold text-danger'><i className='fa fa-fw fa-sm fa-arrow-left'/> Pengeluaran</span>}
                    </Td>
                    <Td className="text-nowrap text-end">Rp {getCurrency(r.total_biaya)}</Td>
                    <Td className="text-nowrap text-end">Rp {getCurrency(r.total_realisasi)}</Td>
                    <Td className="text-nowrap text-end">Rp {getCurrency(r.sisa_biaya)}</Td>
                    <Td className="text-nowrap text-center">
                      <RkatList rkat={r} jabatan={jabatan} fetchData={getRkatAndJabatan}/>
                      <RkatEdit rkat={r} jabatan={jabatan} fetchData={getRkatAndJabatan}/>
                      <SmallButton type="delete" text="Hapus" onClick={() => handleDelete(r.id)}/>
                    </Td>
                  </Tr>
                )
              })
              :
              <Tr>
                <Td colSpan={5} className="text-secondary text-center p-5">Tidak ada data yang tersedia.</Td>
              </Tr>
            }
          </Tbody>
        </TableNew>
        </>
        :
        <>
          <Loading/>
        </>
      }
    </>
  )
}

export default Rkat