import { useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../axios";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterBulan,
  defaultFilterTahun,
  FilterBulan,
  FilterCabang,
  FilterTahun,
} from "../../components/layouts/TopContent";
import LoadingPage from "../../components/pages/Loading";
import NoDataPage from "../../components/pages/NoData";
import { formatDate, formatTimeStamp } from "../../helpers";
import { tokenState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const IjinHrd = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState();

  const [items, setItems] = useState();
  const [page] = useState("all");
  const [bulan, setBulan] = useState(defaultFilterBulan);
  const [tahun, setTahun] = useState(defaultFilterTahun);
  const [cabang, setCabang] = useState("");

  useEffect(() => {
    setTitle("Semua Data ijin");
    const fetchData = async () => {
      try {
        const res = await axios.get(
          "/api/ijin" +
            "?page=" +
            page +
            "&bulan=" +
            bulan +
            "&tahun=" +
            tahun +
            "&cabang=" +
            cabang,
          { headers: { Authorization: "Bearer " + token } }
        );
        if (res.data) {
          setItems(res.data.ijin);
          setIsLoaded(true);
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [setTitle, page, bulan, tahun, cabang, token]);

  if (isLoaded) {
    return (
      <>
        <div className="row mb-2">
          <div className="col-md">
            <FilterCabang pusat={true} onChange={(res) => setCabang(res)} />
          </div>
          <div className="col-md">
            <FilterBulan all={true} onChange={(res) => setBulan(res)} />
          </div>
          <div className="col-md">
            <FilterTahun onChange={(res) => setTahun(res)} />
          </div>
        </div>

        {items.length >= 1 ? (
          <TableNew>
            <Thead>
              <Tr className="text-center text-nowrap">
                <Th rowSpan={2} className='align-middle'>No</Th>
                <Th rowSpan={2} className='align-middle'>Cabang</Th>
                <Th rowSpan={2} className='align-middle'>NIP</Th>
                <Th rowSpan={2} className='align-middle'>Nama</Th>
                <Th rowSpan={2} className='align-middle'>Jenis</Th>
                <Th rowSpan={2} className='align-middle'>Dari</Th>
                <Th rowSpan={2} className='align-middle'>Sampai</Th>
                <Th colSpan={3} className='align-middle'>Jumlah</Th>
                <Th rowSpan={2} className='align-middle'>Alamat</Th>
                <Th rowSpan={2} className='align-middle'>No HP</Th>
                <Th rowSpan={2} className='align-middle'>Keperluan</Th>
                <Th rowSpan={2} className='align-middle'>Status</Th>
                <Th rowSpan={2} className='align-middle'>Approval</Th>
                <Th rowSpan={2} className='align-middle'>Created at</Th>
                <Th rowSpan={2} className='align-middle'>Updated at</Th>
              </Tr>
              <Tr>
                <Th>Hari</Th>
                <Th>Bulan</Th>
                <Th>Tahun</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center align-middle">{1 + index}</Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.cabang}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.nip}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.nama}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {parseInt(i.status_keterangan) === 1 && (
                        <span className="text-info">Sakit</span>
                      )}
                      {parseInt(i.status_keterangan) === 2 && (
                        <span className="text-primary">Keperluan Lain</span>
                      )}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatDate(i.dari)}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatDate(i.sampai)}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.jumlah && i.jumlah.split(" | ")[0].split(" ")[0]}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.jumlah && i.jumlah.split(" | ")[1].split(" ")[0]}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.jumlah && i.jumlah.split(" | ")[2].split(" ")[0]}
                    </Td>
                    <Td className="align-middle">{i.alamat}</Td>
                    <Td className="text-center align-middle">{i.no_hp}</Td>
                    <Td className="align-middle">{i.keperluan_lain}</Td>
                    <Td className="text-center align-middle">
                      {parseInt(i.status) === 1 && (
                        <span className="text-success">Approved</span>
                      )}
                      {parseInt(i.status) === 2 && (
                        <span className="text-danger">Rejected</span>
                      )}
                      {!parseInt(i.status) && (
                        <span className="text-warning">Waiting</span>
                      )}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {i.atasan && i.atasan.nama}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatTimeStamp(i.created_at)}
                    </Td>
                    <Td className="text-nowrap text-center align-middle">
                      {formatTimeStamp(i.updated_at)}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <NoDataPage />
        )}
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

export default IjinHrd;
