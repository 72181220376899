import React, { useState, useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchMhs } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterAngkatan,
  FilterAngkatan,
  FilterCabang,
  FilterJenjang,
  FilterJurusan,
  FilterPagination,
  FilterSearch,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import SectionPagination from "../../components/sections/Pagination";
import { userState } from "../../storage/auth";
import { angkatanState, titleState } from "../../storage/pages";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";

const NilaiTranskripAkademik = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoading, setIsLoading] = useState(false);
  const user = useRecoilValue(userState);
  const defAngkatan = useRecoilValue(angkatanState);

  useEffect(() => setTitle("Nilai Transkrip"), [setTitle]);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(10);
  const [search, setSearch] = useState("");
  const [angkatan, setAngkatan] = useState(defAngkatan);
  const [jenjang, setJenjang] = useState("D1");
  const [jurusan, setJurusan] = useState("");
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchMhs(
        page,
        `with_nilai_ojt=1&search=${search}&jurusan=${jurusan}&angkatan=${angkatan}&jenjang=${jenjang}&cabang=${cabang}&pagination=${pagination}`
      );
      if (res.data && res.data.mhs) {
        setItems(res.data.mhs);
        setIsLoading(true);
      }
    } catch (err) {
      console.error(err);
    }
  }, [page, search, jurusan, angkatan, jenjang, cabang, pagination]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const checkCabangForOldIpk = (cabang) => {
    if (cabang === 'pusat' || cabang === 'denpasar') {
      return true;
    } else {
      return false;
    }
  }

  if (isLoading) {
    return (
      <>
        {/* Filter 1 */}
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterJurusan
              onChange={(res) => {
                setJurusan(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterJenjang
              notNull={true}
              value={jenjang}
              onChange={(res) => {
                setJenjang(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {/* Filter 2 */}
        <div className="row mb-3">
          <div className="col-md-4">
            {user.cabang === "pusat" && (
              <FilterCabang
                onChange={(res) => {
                  setCabang(res);
                  setPage(1);
                }}
              />
            )}
          </div>
          <div className="col-md-4">
            <FilterAngkatan
              value={angkatan}
              onChange={(res) => {
                setAngkatan(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterPagination
              onChange={(res) => {
                setPagination(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        <TextOnOffMhs>
          <p className="mb-0 fw-semibold"><i className="fa fa-fw fa-circle text-success-2"/> Status mahasiswa berwarna <span className="text-success-2">Hijau</span> artinya aktif.</p>
          <p className="mb-0 fw-semibold"><i className="fa fa-fw fa-circle text-danger-2"/> Status mahasiswa berwarna <span className="text-danger-2">merah</span> artinya tidak aktif.</p>
        </TextOnOffMhs>
        {items.data && items.data.length > 0 && (
          <div className="row">
            <div className="col-2 pe-0">
              <LayoutTable>
                <LayoutThead>
                  <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">Status</th>
                  <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">No</th>
                  <th className="bg-primary text-white border-bottom border-silver fw-normal py-1">NIM</th>
                </LayoutThead>
                <tbody>
                  {items.data.map((m, index) => {
                    return (
                      <tr
                        key={index}
                        className="py-1 border-silver text-white"
                      >
                        <td className="py-1 border-silver text-center"><i className={`fa fa-fw fa-circle ${
                          parseInt(m.status) === 9 ? "text-danger-2" : "text-success-2"
                        }`}/></td>
                        <td className="py-1 border-silver text-center">{items.from + index}</td>
                        <td className="py-1 border-silver text-center">{m.nim}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
            <div className="col-8 px-0">
              <LayoutTable>
                <LayoutThead>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Nama</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">No Ijasah</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Tanggal Lulus</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">On The Job Training</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Tugas Akhir</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">TAK</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">IP Sm 1</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">IP Sm 2</th>
                  {jenjang === "D2" && (
                    <>
                      <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">IP Sm 3</th>
                      <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">IP Sm 4</th>
                    </>
                  )}
                  {checkCabangForOldIpk(user.cabang) && (
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">IPK OLD</th>
                  )}
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">IPK</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Judul Tugas Akhir</th>
                </LayoutThead>
                <tbody>
                  {items.data.map((m, index) => {
                    return (
                      <tr key={index}>
                        <td className="py-1 border-silver text-nowrap">{m.nama}</td>
                        <td className="py-1 border-silver text-nowrap">
                          {m.nilai_akhir && m.nilai_akhir.no_ijasah}
                        </td>
                        <td className="py-1 border-silver text-nowrap">
                          {m.jenjang === "D1" &&
                            m.nilai_akhir &&
                            m.nilai_akhir.tanggal_laporan_tugas_akhir}
                          {m.jenjang === "D2" &&
                            m.nilai_akhir &&
                            m.nilai_akhir.tanggal_tugas_akhir}
                        </td>
                        <td className="py-1 border-silver text-nowrap">
                          {m.nilai_akhir && m.nilai_akhir.ojt}
                        </td>
                        <td className="py-1 border-silver text-nowrap">
                          {m.jenjang === "D1" &&
                            m.nilai_akhir &&
                            m.nilai_akhir.laporan_tugas_akhir}
                          {m.jenjang === "D2" &&
                            m.nilai_akhir &&
                            m.nilai_akhir.tugas_akhir}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {m.nilai_akhir && m.nilai_akhir.tak}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {m.nilai_ip &&
                            m.nilai_ip.length > 0 &&
                            m.nilai_ip
                              .filter((ni) => parseInt(ni.semester) === 1)
                              .map((ni) => ni.nilai)}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {m.nilai_ip &&
                            m.nilai_ip.length > 0 &&
                            m.nilai_ip
                              .filter((ni) => parseInt(ni.semester) === 2)
                              .map((ni) => ni.nilai)}
                        </td>
                        {jenjang === "D2" && (
                          <>
                            <td className="py-1 border-silver text-nowrap text-center">
                              {m.nilai_ip &&
                                m.nilai_ip.length > 0 &&
                                m.nilai_ip
                                  .filter((ni) => parseInt(ni.semester) === 3)
                                  .map((ni) => ni.nilai)}
                            </td>
                            <td className="py-1 border-silver text-nowrap text-center">
                              {m.nilai_ip &&
                                m.nilai_ip.length > 0 &&
                                m.nilai_ip
                                  .filter((ni) => parseInt(ni.semester) === 4)
                                  .map((ni) => ni.nilai)}
                            </td>
                          </>
                        )}
                        {checkCabangForOldIpk(user.cabang) && (
                          <td className="py-1 border-silver text-nowrap text-center">
                            <span
                              className={`${
                                m.nilai_akhir &&
                                m.nilai_akhir.old_ipk !== m.nilai_akhir.ipk
                                  ? "text-danger"
                                  : "text-success"
                              }`}
                            >
                              {m.nilai_akhir && m.nilai_akhir.old_ipk}
                            </span>
                          </td>
                        )}
                        <td className="py-1 border-silver text-nowrap text-center">
                          {m.nilai_akhir && m.nilai_akhir.ipk}
                        </td>
                        <td className="py-1 border-silver text-nowrap">
                          {m.jenjang === "D1" &&
                            m.nilai_akhir &&
                            m.nilai_akhir.judul_laporan_tugas_akhir}
                          {m.jenjang === "D2" &&
                            m.nilai_akhir &&
                            m.nilai_akhir.judul_tugas_akhir}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
            <div className="col-2 ps-0">
              <LayoutTable>
                <LayoutThead>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">
                    <i className="fa fa-fw fa-cog" />
                  </th>
                </LayoutThead>
                <tbody>
                  {items.data.map((m, index) => {
                    return (
                      <tr key={index}>
                        <td className="px-2 border-silver text-center text-nowrap w-75" style={{padding: '4.7px 0px'}}>
                          <select
                            className="form-select form-select-sm form-select-custom py-0 text-sm fw-semibold border-silver rounded w-auto mx-auto"
                            onChange={(e) => window.open(e.target.value)}
                            value="pilih"
                          >
                            <option value="pilih" disabled={true}>
                              Pilih Cetak
                            </option>
                            <option value={`/print-transkrip-reguler-sementara/${m.id}`}>
                              Transkrip Reguler Sementara
                            </option>
                            <option value={`/print-transkrip-reguler/${m.id}`}>
                              Transkrip Reguler
                            </option>
                            <option value={`/print-transkrip-khs/${m.id}/1`}>
                              Transkrip KHS 1
                            </option>
                            <option value={`/print-transkrip-khs/${m.id}/2`}>
                              Transkrip KHS 2
                            </option>
                            <option value={`/print-transkrip-khs/${m.id}/3`}>
                              Transkrip KHS 3
                            </option>
                            <option value={`/print-transkrip-khs/${m.id}/4`}>
                              Transkrip KHS 4
                            </option>
                            <option value={`/print-transkrip-tak/${m.id}`}>
                              Transkrip TAK
                            </option>
                          </select>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
          </div>
        )}

        {items.links && (
          <SectionPagination
            from={items.from}
            to={items.to}
            total={items.total}
            links={items.links}
            onChangePage={(res) => setPage(res)}
          />
        )}
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default NilaiTranskripAkademik;
