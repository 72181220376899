import React from "react";
import { useState } from "react";
import { useRef } from "react";
import * as XLSX from "xlsx";
import { postWisuda } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { saAlert } from "../../helpers";

const WisudaImportData = ({ onFetchData }) => {
  const tagForm = useRef(null);
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);

  const [excelData, setExcelData] = useState({});

  const availableTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const handleChange = (e) => {
    let selectedFile = e.target.files[0];
    setExcelData({});

    if (selectedFile) {
      if (availableTypes.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFileError("Pilih hanya file Excel !");
        setExcelFile(null);
      }
    } else {
      saAlert("warning", "Silahkan pilih file terlebih dahulu !");
    }
  };

  const handleSubmitExcel = async (e, upload = false) => {
    e.preventDefault();

    if (upload) {
      try {
        const res = await postWisuda({
          excel_upload: excelData,
        });
        if (res.data && res.data.msg === "success") {
          tagForm.current && tagForm.current.reset();
          setExcelFile(null);
          setExcelFileError(null);
          setExcelData({});
          onFetchData();
          saAlert(
            "success",
            "Berhasil tambah data program kampus.",
            `${res.data.uploaded} data telah ditambah !`
          );
        }
      } catch (err) {
        if (err.response) {
          console.error(err.response);
        }
      }
    } else {
      if (excelFile !== null) {
        const workbook = XLSX.read(excelFile, { type: "buffer" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);
        setExcelData(data);
      } else {
        setExcelData({});
      }
    }
  };

  return (
    <form onSubmit={handleSubmitExcel} ref={tagForm}>
      {/* <label htmlFor="">
          Upload Excel File. Unduh template Excel{" "}
          <span
            type="button"
            className="text-primary"
            onClick={() => {
              window.open(
                getOrigin() + "/files/contoh_upload_data_program_kampus.xlsx"
              );
            }}
          >
            disini
          </span>
        </label> */}
      <div className="mb-2">
        <input type="file" onChange={handleChange} />
        {excelFileError && (
          <>
            <br />
            <span className="text-danger">{excelFileError}</span>
          </>
        )}
      </div>
      <div className="btn-group mb-2">
        <button className="btn btn-sm btn-primary" type="submit">
          Cek File
        </button>
        {excelData.length > 0 && (
          <button
            className="btn btn-sm btn-success"
            type="button"
            onClick={(e) => {
              handleSubmitExcel(e, true);
            }}
          >
            Upload Data
          </button>
        )}
      </div>
      {excelData.length > 0 && (
        <>
          <h4>{excelData.length} data telah ditemukan dalam file Excel.</h4>
          <LayoutTable copy={false}>
            <LayoutThead>
              <th>No</th>
              <th>NIM</th>
              <th>Tahun</th>
            </LayoutThead>
            <tbody>
              {excelData.map((ed, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{ed.no}</td>
                    <td className="text-center">{ed.nim}</td>
                    <td className="text-center">{ed.tahun}</td>
                  </tr>
                );
              })}
            </tbody>
          </LayoutTable>
        </>
      )}
    </form>
  );
};

export default WisudaImportData;
