import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { titleState } from "../../storage/pages";
import axios from "../../axios";
import { tokenState, userState } from "../../storage/auth";
import LoadingPage from "../../components/pages/Loading";
import TableSection from "../../components/sections/Table";
import { Link } from "react-router-dom";
import { scrollToTop } from "../../helpers";

const PenilaianKompetensi = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const user = useRecoilValue(userState);
  const [isLoaded, setIsLoaded] = useState();
  const [items, setItems] = useState();

  const [currenctJabatan, setCurrentJabatan] = useState({});

  useEffect(() => {
    setCurrentJabatan(
      user.jabatan_
        ?.filter((j) => parseInt(j.id) === parseInt(user.jabatan_id))
        .map((j) => j)[0]
    );
  }, [user]);

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get("/api/penilaian-kompetensi", {
        headers: { Authorization: "Bearer " + token },
      });
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [token, setItems, setIsLoaded]);

  useEffect(() => {
    setTitle("Penilaian Kompetensi");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        <div className="text-white bg-gradient-primary border-bottom-warning rounded-3 p-4 text-justify mb-3">
          <div className="position-relative border border-secondary rounded p-3 mb-3">
            <div
              className="position-absolute bg-gradient-warning text-black rounded fw-semibold px-3"
              style={{ rotate: "-4deg", left: "-15px", top: "-10px" }}
            >
              <i className="fa fa-fw fa-pencil" /> Penilaian Kompetensi
            </div>
            <p className="mb-0">
              <span className="text-warning">
                Penilaian Kompetensi (Attitude) & Culture Fit
              </span>{" "}
              ini digunakan untuk mengukur tingkat{" "}
              <span className="text-warning">kompetensi</span> dari Karyawan
              Alfa Prima. Mohon untuk mengisi penilaian{" "}
              <span className="text-warning">seakurat</span> mungkin dan
              mengedepankan <span className="text-warning">kejujuran</span>,
              agar hasil penilaian dapat dijadikan{" "}
              <span className="text-warning">acuan</span> dalam melakukan
              berbagai macam
              <span className="text-warning">perbaikan</span>. Jika ditemukan
              adanya kesepakatan pemberi{" "}
              <span className="text-warning">nilai</span>, maka nilai akan
              diberikan <span className="text-warning">nol</span>."
            </p>
          </div>
          <h6 className="mb-0 text-warning fw-semibold">Notes</h6>
          <p className="text-sm">
            Harap baca poin-poin dibawah ini terlebih dahulu.
          </p>
          <h6 className="text-warning fw-semibold">Poin 1</h6>
          <ul>
            <li className="text-sm">
              Jabatan anda saat ini adalah {user.nama}, dan anda merupakan staff
              dengan grade {currenctJabatan.grade}
            </li>
            <li className="text-sm">
              Penilaian kompetensi akan dilakukan sesama staff yang berada pada
              cabang masing-masing kecuali Direktur Bagian dan Kepala Cabang
            </li>
            <li className="text-sm">
              Setiap staff dapat melakukan penilaian kepada staff lainnya yang
              berada pada grade yang sama, 1 grade diatasnya, 1 grade
              dibawahnya, dan 1 grade dibawahnya (Dengan jalur kordinasi yang
              sama kecuali Direktur Bagian, Ka.Cab., dan Ka.Bag.)
            </li>
          </ul>
          <h6 className="text-warning fw-semibold">Poin 2</h6>
          <ul>
            <li className="text-sm">
              Penilaian akan dilaksanakan pada tanggal 22-24 September 2022{" "}
            </li>
            <li className="text-sm">
              Harap mengisi setiap poin penilaian dengan nilai yang sebenar
              benarnya (Jika ditemukan adanya kesepakatan pemberi nilai, maka
              nilai akan diberikan nol)
            </li>
            <li className="text-sm">
              Hasil penilaian akan bersifat sangat privasi dan dijamin
              keamanannya
            </li>
          </ul>
          <h6 className="text-warning fw-semibold">Poin 3</h6>
          <ul className="mb-0">
            <li className="text-sm">
              Dibawah ini akan ditampilkan orang-orang yang nantinya akan anda
              beri penilaian
            </li>
            <li className="text-sm">
              Apabila terjadi kesalahan atau kendala pada saat melakukan
              penilaian segera hubungi bagian IT Programmer agar dapat dilakukan
              perbaikan secepatnya
            </li>
          </ul>
        </div>

        <h4 className="fw-semibold">
          Jabatan : {currenctJabatan.nama} (Grade : {currenctJabatan.grade})
        </h4>
        <h6 className="fw-semibold">
          <hr className="p-0 m-0" />
          Berikut ini merupakan nama-nama rekan yang harus anda berikan
          penilaian :
        </h6>
        <TableSection thead={<Thead />} tbody={<Tbody items={items} />} />
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

const Thead = () => {
  return (
    <tr>
      <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
        No
      </th>
      <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
        ID
      </th>
      {/* <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Group</th> */}
      <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
        Nama
      </th>
      <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
        Jabatan (Grade)
      </th>
    </tr>
  );
};

const Tbody = (props) => {
  return props.items.map((i, index) => {
    return (
      <tr key={index}>
        <td className="py-1 border-silver">{index + 1}</td>
        <td className="py-1 border-silver">{i.id}</td>
        {/* <td className="py-1 border-silver">{i.group}</td> */}
        <td className="py-1 border-silver">{i.nama}</td>
        <Td
          pegawai={i}
          penilai={i.penilai ? i.penilai : false}
          id={i.id}
          cabang={i.cabang}
          jabatan={i.jabatan}
          nama={i.nama}
        />
      </tr>
    );
  });
};

const Td = (props) => {
  const user = useRecoilValue(userState);
  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (props.penilai) {
      props.penilai.forEach((i) => {
        if (i.penilai_id === user.id) {
          setStatus(true);
        }
      });
    }
  }, [setStatus, user, props]);

  return (
    <>
      <td className="py-1 border-silver">
        {props.pegawai?.jabatan_.length > 0 &&
          props.pegawai?.jabatan_
            .filter((j) => {
              const grade = parseInt(user.grade);

              if (j.pivot?.tanggal_berakhir) {
                return null;
              }

              if (
                parseInt(j.pivot?.pegawai_id) === parseInt(user.id) &&
                parseInt(j.id) === parseInt(user.jabatan_id)
              ) {
                return null;
              }

              if (grade <= 1) {
                if (parseInt(j.grade) < 3) {
                  return j;
                }
              }

              if (grade === 2) {
                if (parseInt(j.grade) === 2) {
                  return j;
                } else if (
                  parseInt(j.grade) === 3 &&
                  parseInt(j.cabang_id) === parseInt(user.cabang_id)
                ) {
                  return j;
                }
              }

              if (
                grade === 3 &&
                parseInt(j.cabang_id) === parseInt(user.cabang_id)
              ) {
                if (
                  parseInt(j.grade) === 2 &&
                  parseInt(j.divisi_id) === parseInt(user.divisi_id)
                ) {
                  return j;
                }

                if (
                  parseInt(j.grade) === 3 &&
                  parseInt(j.divisi_id) === parseInt(user.divisi_id)
                ) {
                  return j;
                }

                if (
                  parseInt(j.grade) === 4 &&
                  parseInt(j.departemen_id) === parseInt(user.departemen_id)
                ) {
                  return j;
                }
              }

              if (
                grade === 4 &&
                parseInt(j.cabang_id) === parseInt(user.cabang_id)
              ) {
                if (
                  parseInt(j.grade) === 3 &&
                  parseInt(j.departemen_id) === parseInt(user.departemen_id)
                ) {
                  return j;
                }

                if (
                  parseInt(j.grade) === 4 &&
                  parseInt(j.departemen_id) === parseInt(user.departemen_id)
                ) {
                  return j;
                }
              }

              return null;
            })
            .map((j, index) => {
              return (
                <span key={index} className="row">
                  {index > 0 ? <br /> : ""}
                  <div className="col-1 text-center">{index + 1}</div>
                  <div className="col">
                    {j.cabang?.nama} - {j.nama} ({j.grade})
                  </div>
                  <div className="col-3">
                    {props.penilai.filter(
                      (p) =>
                        p.penilai_id === user.id &&
                        p.dinilai_jabatan_id === j.id
                    ).length > 0 ? (
                      <Link
                        to={`/penilaian-kompetensi/${props.id}/${props.cabang}/${props.jabatan}/${props.nama}?jabatan_id=${j.id}&nama_jabatan=${j.nama}`}
                        className="btn btn-sm btn-dark border-0 py-0 text-sm rounded mx-1"
                        onClick={scrollToTop}
                      >
                        <i className="fa fa-fw fa-sm fa-eye" /> Lihat Penilaian
                      </Link>
                    ) : (
                      <Link
                        to={`/penilaian-kompetensi/${props.id}/${props.cabang}/${props.jabatan}/${props.nama}?jabatan_id=${j.id}&nama_jabatan=${j.nama}`}
                        className="btn btn-sm btn-success-2 border-0 py-0 text-sm rounded mx-1 d-block mb-3"
                        onClick={scrollToTop}
                      >
                        <i className="fa fa-fw fa-sm fa-pen" /> Beri Penilaian
                      </Link>
                    )}
                  </div>
                </span>
              );
            })}
      </td>
      {/* {status ? (
        <td className="py-1 border-silver text-center fw-semibold text-success-2">
          <i className="fa fa-fw fa-circle-check" />
        </td>
      ) : (
        <td className="py-1 border-silver text-center text-danger">
          <i className="fa fa-fw fa-circle-xmark" />
        </td>
      )}
      {status ? (
        <td className="py-1 border-silver text-center">
          <Link
            to={`/penilaian-kompetensi/${props.id}/${props.cabang}/${props.jabatan}/${props.nama}?jabatan_id=`}
            className="btn btn-sm btn-dark border-0 py-0 text-sm rounded mx-1"
            onClick={scrollToTop}
          >
            <i className="fa fa-fw fa-sm fa-eye" /> Lihat Penilaian
          </Link>
        </td>
      ) : (
        <td className="py-1 border-silver text-center">
          <Link
            to={`/penilaian-kompetensi/${props.id}/${props.cabang}/${props.jabatan}/${props.nama}`}
            className="btn btn-sm btn-success-2 border-0 py-0 text-sm rounded mx-1"
            onClick={scrollToTop}
          >
            <i className="fa fa-fw fa-sm fa-pen" /> Beri Penilaian
          </Link>
        </td>
      )} */}
    </>
  );
};

export default PenilaianKompetensi;
