import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  LayoutTable,
  LayoutThead,
  TableNoData,
} from "../../../components/layouts/Table";
import SectionPagination from "../../../components/sections/Pagination";
import { formatDate } from "../../../helpers";
import { userState } from "../../../storage/auth";
import { getStatusItems } from "../helpers";
import { PurchaseRequestContext } from "../Routes";

const Approval = ({ mainUrl, onFetchData }) => {
  const { isLoaded, purchaseRequest, setPage } = useContext(
    PurchaseRequestContext
  );

  const user = useRecoilValue(userState);

  useEffect(() => {
    onFetchData({ approval: user.id });
  }, [onFetchData, user.id]);

  return (
    <>
      <LayoutTable>
        <LayoutThead>
          <th>No</th>
          <th>Nama</th>
          <th>Keperluan</th>
          <th>Tanggal Request</th>
          <th>Tanggal Dibutuhkan</th>
          <th>Jumlah</th>
          <th>Approval Atasan</th>
          <th>Approval Kacab</th>
          <th>Approval Dir</th>
          <th>Items</th>
          <th>bars</th>
        </LayoutThead>
        <tbody>
          {isLoaded &&
          purchaseRequest.data &&
          purchaseRequest.data.length > 0 ? (
            purchaseRequest.data.map((pr, index) => {
              return (
                <tr key={index}>
                  <td className="text-center">
                    {purchaseRequest.from + index}
                  </td>
                  <td className="text-center">
                    {pr.pegawai && pr.pegawai.nama}
                  </td>
                  <td className="text-center">{pr.untuk}</td>
                  <td className="text-center">
                    {formatDate(pr.tanggal_request)}
                  </td>
                  <td className="text-center">
                    {formatDate(pr.tanggal_dibutuhkan)}
                  </td>
                  <td className="text-center">{pr.jumlah}</td>
                  <td className="text-center">
                    {parseInt(pr.status) === 1 && (
                      <span className="text-success">Approved</span>
                    )}
                    {parseInt(pr.status) === 9 && (
                      <span className="text-danger">Reject</span>
                    )}
                    {!pr.status && "-"}
                  </td>
                  <td className="text-center">
                    {parseInt(pr.approval_kacab) === 1 && (
                      <span className="text-success">Approved</span>
                    )}
                    {parseInt(pr.approval_kacab) === 9 && (
                      <span className="text-danger">Reject</span>
                    )}
                    {!pr.approval_kacab && "-"}
                  </td>
                  <td className="text-center">
                    {parseInt(pr.approval_dir) === 1 && (
                      <span className="text-success">Approved</span>
                    )}
                    {parseInt(pr.approval_dir) === 9 && (
                      <span className="text-danger">Reject</span>
                    )}
                    {!pr.approval_dir && "-"}
                  </td>
                  <td className="text-center">
                    {getStatusItems(pr.purchase_request_list)}
                  </td>
                  <td className="text-center">
                    <Link
                      className="mx-1 fa fa-eye text-info"
                      to={`${mainUrl}/${pr.id}`}
                    ></Link>
                  </td>
                </tr>
              );
            })
          ) : (
            <TableNoData col={11} isLoaded={isLoaded} />
          )}
        </tbody>
      </LayoutTable>
      <SectionPagination
        links={purchaseRequest.links}
        from={purchaseRequest.from}
        to={purchaseRequest.to}
        total={purchaseRequest.total}
        onChangePage={(res) => setPage(res)}
      />
    </>
  );
};

export default Approval;
