import React, { useState } from "react";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import SmallButton from "../../components/forms/SmallButton";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const PendaftarShow = (props) => {
  const { pendaftar } = props;
  const [modal, setModal] = useState(false);

  const haldeClose = () => setModal(false);

  return (
    <>
      <SmallButton type='show' onClick={() => setModal(true)}/>

      <LayoutModal
        title={`Data Pendaftar ${pendaftar.nama}`}
        modal={modal}
        onClose={haldeClose}
      >
        <TableNew>
          <Thead>
            <Tr className='text-center'>
              <Th>Label</Th>
              <Th>Isi</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>Kode Afiliasi</Td>
              <Td>{pendaftar.afiliasi_id || "-"}</Td>
            </Tr>
            <Tr>
              <Td>Status</Td>
              <Td>
                {!pendaftar.status && (
                  <span className="text-secondary">Belum Daftar</span>
                )}
                {pendaftar.status === 1 && (
                  <span className="text-warning">Sudah Daftar</span>
                )}
                {pendaftar.status === 2 && (
                  <span className="text-success">Sudah DU</span>
                )}
                {pendaftar.status === 3 && (
                  <span className="text-info">Sudah Cair</span>
                )}
                {pendaftar.status === 4 && (
                  <span className="text-primary">Mhs Aktif</span>
                )}
                {pendaftar.status === 9 && (
                  <span className="text-danger">Batal Daftar</span>
                )}
              </Td>
            </Tr>
            <Tr>
              <Td>Cabang</Td>
              <Td>{pendaftar.cabang}</Td>
            </Tr>
            <Tr>
              <Td>Nama</Td>
              <Td>{pendaftar.nama}</Td>
            </Tr>
            <Tr>
              <Td>Tempat Lahir</Td>
              <Td>{pendaftar.tempat_lahir}</Td>
            </Tr>
            <Tr>
              <Td>Tanggal Lahir</Td>
              <Td>{pendaftar.tanggal_lahir}</Td>
            </Tr>
            <Tr>
              <Td>Nama Ayah</Td>
              <Td>{pendaftar.nama_ayah}</Td>
            </Tr>
            <Tr>
              <Td>Nama IBU</Td>
              <Td>{pendaftar.nama_ibu}</Td>
            </Tr>
            <Tr>
              <Td>Alamat Orang Tua</Td>
              <Td>{pendaftar.alamat_ortu}</Td>
            </Tr>
            <Tr>
              <Td>NO HP</Td>
              <Td>{pendaftar.no_hp}</Td>
            </Tr>
            <Tr>
              <Td>NO HP Ortu</Td>
              <Td>{pendaftar.no_hp_ortu}</Td>
            </Tr>
            <Tr>
              <Td>Email</Td>
              <Td>{pendaftar.email}</Td>
            </Tr>
            <Tr>
              <Td>Facebook</Td>
              <Td>{pendaftar.fb}</Td>
            </Tr>
            <Tr>
              <Td>Instagram</Td>
              <Td>{pendaftar.ig}</Td>
            </Tr>
            <Tr>
              <Td>Jenis Kelamin</Td>
              <Td>{pendaftar.jenis_kelamin}</Td>
            </Tr>
            <Tr>
              <Td>Agama</Td>
              <Td>{pendaftar.agama}</Td>
            </Tr>
            <Tr>
              <Td>Asal Sekolah</Td>
              <Td>{pendaftar.asal_sekolah}</Td>
            </Tr>
            <Tr>
              <Td>Tahun Tamat</Td>
              <Td>{pendaftar.tahun_tamat}</Td>
            </Tr>
            <Tr>
              <Td>Jurusan</Td>
              <Td>{pendaftar.jurusan}</Td>
            </Tr>
            <Tr>
              <Td>Waktu Belajar</Td>
              <Td>{pendaftar.waktu_belajar}</Td>
            </Tr>
            <Tr>
              <Td>Informasi</Td>
              <Td>{pendaftar.informasi}</Td>
            </Tr>
            <Tr>
              <Td>Keputusan Kuliah</Td>
              <Td>{pendaftar.keputusan_kuliah}</Td>
            </Tr>
            <Tr>
              <Td>Refrensi</Td>
              <Td>{pendaftar.refrensi}</Td>
            </Tr>
            <Tr>
              <Td>Promo</Td>
              <Td>{pendaftar.promo}</Td>
            </Tr>
            <Tr>
              <Td>Prodi</Td>
              <Td>{pendaftar.prodi}</Td>
            </Tr>
            <Tr>
              <Td>No Surat</Td>
              <Td>{pendaftar.no_surat}</Td>
            </Tr>
            <Tr>
              <Td>KDBB</Td>
              <Td>{pendaftar.kdbb === 1 ? "Ya" : "Tidak"}</Td>
            </Tr>
            <Tr>
              <Td>Tahun Ajaran</Td>
              <Td>{pendaftar.tahun_ajaran}</Td>
            </Tr>
            <Tr>
              <Td>Update Terakhir Oleh</Td>
              <Td>{pendaftar.updated_by}</Td>
            </Tr>
          </Tbody>
        </TableNew>
        <button className="btn btn-sm btn-dark border-0" onClick={haldeClose}>
          <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
        </button>
      </LayoutModal>
    </>
  );
};

export default PendaftarShow;
