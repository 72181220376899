import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { findMhs } from "../../../api";
import PrintBtnPrint from "../../../components/print/BtnPrint";
import PrintFooter from "../../../components/print/Footer";
import PrintHeader from "../../../components/print/Header";
import PrintTtd from "../../../components/print/Ttd";
import { getGrade } from "../../../helpers";
import { kacabState } from "../../../storage/auth";

const NilaiPrintTranskripKhs = () => {
  const { mhs_id, semester } = useParams();

  const kacab = useRecoilValue(kacabState);

  const [mhs, setMhs] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await findMhs(mhs_id, `with_krs=true&generate_nilai=1`);
        if (res.data && res.data.mhs) {
          setMhs(res.data.mhs);
          console.log(res.data.mhs);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, [mhs_id]);

  return (
    <>
      <PrintHeader fontSize={17} />

      <div className="container-fluid">
        <div className="mb-3">
          <div className="row">
            <div className="col-4">Nama</div>
            <div className="col-8">: {mhs.nama}</div>
          </div>
          <div className="row">
            <div className="col-4">NIM</div>
            <div className="col-8">: {mhs.nim}</div>
          </div>
          <div className="row">
            <div className="col-4">Tempat / Tanggal Lahir</div>
            <div className="col-8">
              : {mhs.tempat},{" "}
              {mhs.tanggal && mhs.tanggal.split("-").reverse().join("-")}
            </div>
          </div>
        </div>

        <table className="table table-sm table-bordered">
          <thead className="text-center">
            <tr>
              <th className="align-middle" rowSpan={2}>
                No
              </th>
              <th className="align-middle" rowSpan={2}>
                Kode
              </th>
              <th className="align-middle" rowSpan={2}>
                Nama Mata Kuliah
              </th>
              <th className="align-middle" rowSpan={2}>
                Kredit
              </th>
              <th className="align-middle" colSpan={2}>
                Nilai
              </th>
            </tr>
            <tr>
              <th className="align-middle">Huruf Mutu</th>
              <th className="align-middle">Angka Mutu</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={6}>Semester {semester}</td>
            </tr>
            {mhs.krs &&
              mhs.krs.length > 0 &&
              mhs.krs
                .filter((k) => parseInt(k.semester) === parseInt(semester))
                .map((k, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">
                        {k.matkul && k.matkul.kode}
                      </td>
                      <td>{k.matkul && k.matkul.nama}</td>
                      <td className="text-center">
                        {k.matkul && k.matkul.sks}
                      </td>
                      <td className="text-center">
                        {k.nilai && getGrade(k.nilai.grade)}
                      </td>
                      <td className="text-center">
                        {k.matkul &&
                          k.nilai &&
                          parseInt(k.matkul.sks) * parseInt(k.nilai.grade)}
                      </td>
                    </tr>
                  );
                })}
          </tbody>
          <tfoot>
            <tr>
              <th colSpan={3}>Jumlah Total</th>
              <th className="text-center">
                {mhs.krs &&
                  mhs.krs.length > 0 &&
                  mhs.krs
                    .filter((k) => parseInt(k.semester) === parseInt(semester))
                    .map((k) => k.matkul && k.matkul.sks)
                    .reduce(
                      (prev, curr) => parseInt(prev || 0) + parseInt(curr || 0)
                    )}
              </th>
              <th className="text-center"></th>
              <th className="text-center">
                {mhs.krs &&
                  mhs.krs.length > 0 &&
                  mhs.krs
                    .filter((k) => parseInt(k.semester) === parseInt(semester))
                    .map(
                      (k) =>
                        k.matkul &&
                        k.nilai &&
                        parseInt(k.matkul.sks) * parseInt(k.nilai.grade)
                    )
                    .reduce(
                      (prev, curr) => parseInt(prev || 0) + parseInt(curr || 0)
                    )}
              </th>
            </tr>
            <tr>
              <th colSpan={3}>Indeks Prestasi Semester</th>
              <th colSpan={3} className="text-center">
                {(
                  (mhs.krs &&
                    mhs.krs.length > 0 &&
                    mhs.krs
                      .filter(
                        (k) => parseInt(k.semester) === parseInt(semester)
                      )
                      .map(
                        (k) =>
                          k.matkul &&
                          k.nilai &&
                          parseInt(k.matkul.sks) * parseInt(k.nilai.grade)
                      )
                      .reduce(
                        (prev, curr) =>
                          parseInt(prev || 0) + parseInt(curr || 0)
                      )) /
                  (mhs.krs &&
                    mhs.krs.length > 0 &&
                    mhs.krs
                      .filter(
                        (k) => parseInt(k.semester) === parseInt(semester)
                      )
                      .map((k) => k.matkul && k.matkul.sks)
                      .reduce(
                        (prev, curr) =>
                          parseInt(prev || 0) + parseInt(curr || 0)
                      ))
                ).toFixed(2)}
              </th>
            </tr>
          </tfoot>
        </table>

        <h6 className="font-weight-bold">Keterangan</h6>
        <div className="row mb-4">
          <div className="col">
            IPK : 2,00-2,50 (Cukup) <br /> IPK : 2,51-3,00 (Memuaskan)
          </div>
          <div className="col">
            IPK : 3,01-3,50 (Sangat Memuaskan) <br /> IPK : 3,51-4,00 (Cum
            Laude)
          </div>
        </div>

        <div className="row justify-content-end mb-5">
          <div className="col-6">
            <PrintTtd
              nama={kacab.nama}
              jabatan="Kepala Cabang"
              footer={`NIP : ${kacab.nip}`}
              cabang={kacab.cabang}
              tanggal="default"
            />
          </div>
        </div>

        <PrintFooter />
      </div>

      <PrintBtnPrint />
    </>
  );
};

export default NilaiPrintTranskripKhs;
