import { useState } from "react";

const FormCr = (props) => {
  const {
    label,
    name,
    inline,
    type,
    defaultValue,
    onChange,
    divClass,
    option,
    error,
    disabled,
  } = props;

  const dBlock = "form-check";
  const dInline = "form-check form-check-inline";

  const unixName = (name, index) => {
    let a = name.split(" ");
    return `${a.join("_")}_${index + 1}`;
  };

  const [defVal, setDefVal] = useState(defaultValue);

  const [updateNow, setUpdateNow] = useState(false);

  const updateFunc = () => {
    setTimeout(() => setUpdateNow(!updateNow), 300);
  };

  return (
    <>
      {label && <label className="fw-semibold text-primary">{label}</label>}
      <div className={`form-group ${divClass && divClass}`}>
        {option &&
          option.map((i, index) => {
            return (
              <div className={inline ? dInline : dBlock} key={index}>
                <input
                  className={`form-check-input ${error && "is-invalid"}`}
                  type={type}
                  name={name}
                  value={i.value}
                  onChange={(e) => {
                    setDefVal(i.value);
                    updateFunc();
                    onChange(e);
                  }}
                  checked={i.value === defVal ? true : false}
                  disabled={disabled}
                />
                <label
                  className={`form-check-label ${inline && "mr-2"}`}
                  htmlFor={unixName(name, index)}
                >
                  {i.name ? i.name : i.value}
                </label>
              </div>
            );
          })}
        {props.error && <div className="text-danger">{props.error}</div>}
      </div>
    </>
  );
};

export default FormCr;
