import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router";
import { showKelas } from "../../api";
import PageLoading from "../../components/pages/Loading";
import { getAlamatCabang, getHari, getJurusanShort, getNoHpCabang, getOrigin } from "../../helpers";
import { useRecoilState } from "recoil";
import { userState } from "../../storage/auth";
import PrintHeader from "../../components/print/Header";
import PrintFooter from "../../components/print/Footer";

const KelasAbsensi = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [print, setPrint] = useState(false);
  const [items, setItems] = useState({});
  const [user, getUser] = useRecoilState(userState);

  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await showKelas(id);
        setItems(res.data);
        setIsLoaded(true);
        console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [id]);

  const getAbsensi = (kelas, mhs, pertemuan) => {
    return (
      kelas.pengajaran &&
      kelas.pengajaran.length > 0 &&
      kelas.pengajaran
        .filter((p) => {
          return (
            String(p.pertemuan) === String(pertemuan) &&
            parseInt(p.status) !== 9
          );
        })
        .map((p) => {
          return p.absensi
            .filter((a) => {
              return (
                String(a.pertemuan) === String(pertemuan) &&
                parseInt(a.mhs_id) === parseInt(mhs.id)
              );
            })
            .map((a, index) => {
              if (String(a.pertemuan) === String(pertemuan)) {
                return (
                  <span key={index}>
                    {parseInt(a.status) === 1 && (
                      <span className="text-success">H</span>
                    )}
                    {parseInt(a.status) === 2 && (
                      <span className="text-info">I</span>
                    )}
                    {parseInt(a.status) === 3 && (
                      <span className="text-warning">S</span>
                    )}
                    {parseInt(a.status) === 4 && (
                      <span className="text-danger">A</span>
                    )}
                  </span>
                );
              }
              return null;
            });
        })
    );
  };

  const getTanggal = (kelas, pertemuan) => {
    return (
      kelas.pengajaran &&
      kelas.pengajaran.length > 0 &&
      kelas.pengajaran
        .filter((p) => parseInt(p.status) !== 9)
        .map((p, index) => {
          if (String(p.pertemuan) === String(pertemuan)) {
            let arrTanggal = p.tanggal.split("-");
            return (
              <span key={index}>
                {arrTanggal[0]}/ <br />
                {arrTanggal[1]}/ <br />
                {arrTanggal[2].split("20")[1]} <br />
              </span>
            );
          }
          return null;
        })
    );
  };

  if (isLoaded) {
    return (
      <>
        <div className="container-fluid">
          <PrintHeader type="landscape" fontSize="9pt"/>
          <h2 className="text-center">DAFTAR ABSENSI MAHASISWA</h2>
          <div className="row mb-3">
            <div className="col">
              <table>
                <tbody>
                  <tr>
                    <td>Kode Kelas</td>
                    <td>{` : `}</td>
                    <td>{items.kode}</td>
                  </tr>
                  <tr>
                    <td>Hari / Jam (WITA)</td>
                    <td>{` : `}</td>
                    <td>{`${getHari(items.jamkul.hari)} / ${
                      items.jamkul.dari
                    } - ${items.jamkul.sampai}`}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col d-flex justify-content-center">
              <table>
                <tbody>
                  <tr>
                    <td>Kelas/Ruangan</td>
                    <td>{` : `}</td>
                    <td>{items.ruangan.nama}</td>
                  </tr>
                  <tr>
                    <td>Dosen</td>
                    <td>{` : `}</td>
                    <td>{items.dosen && items.dosen.nama}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col justify-content-end d-flex">
              <table>
                <tbody>
                  <tr>
                    <td>Mata Kuliah</td>
                    <td>{` : `}</td>
                    <td>{items.matkul.nama}</td>
                  </tr>
                  <tr>
                    <td>Semester</td>
                    <td>{` : `}</td>
                    <td>{items.semester}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <table className="table table-sm table-bordered">
            <thead className="text-center bg-light">
              <tr className="">
                <th rowSpan="2" className="align-middle">
                  No
                </th>
                <th rowSpan="2" className="align-middle">
                  NIM
                </th>
                <th rowSpan="2" className="align-middle">
                  Nama
                </th>
                <th rowSpan="2" className="align-middle">
                  Jurusan
                </th>
                <th colSpan="6">Pertemuan Ke</th>
                <th rowSpan="2" className="align-middle">
                  UTS
                </th>
                <th colSpan="6">Pertemuan Ke</th>
                <th rowSpan="2" className="align-middle">
                  UAS
                </th>
              </tr>
              <tr>
                <th>
                  <span className="px-2">1</span>
                </th>
                <th>
                  <span className="px-2">2</span>
                </th>
                <th>
                  <span className="px-2">3</span>
                </th>
                <th>
                  <span className="px-2">4</span>
                </th>
                <th>
                  <span className="px-2">5</span>
                </th>
                <th>
                  <span className="px-2">6</span>
                </th>
                <th>
                  <span className="px-2">7</span>
                </th>
                <th>
                  <span className="px-2">8</span>
                </th>
                <th>
                  <span className="px-2">9</span>
                </th>
                <th>
                  <span className="px-2">10</span>
                </th>
                <th>
                  <span className="px-2">11</span>
                </th>
                <th>
                  <span className="px-2">12</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {items.mhs &&
                items.mhs.filter((i) => parseInt(i.status) !== 9 ).map((i, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-center">{index + 1}</td>
                      <td>{i.nim}</td>
                      <td>{i.nama}</td>
                      <td className="text-center">
                        {getJurusanShort(i.jurusan_id)}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "1")}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "2")}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "3")}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "4")}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "5")}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "6")}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "UTS")}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "7")}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "8")}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "9")}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "10")}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "11")}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "12")}
                      </td>
                      <td className="text-center">
                        {getAbsensi(items, i, "UAS")}
                      </td>
                    </tr>
                  );
                })}
              <tr>
                <th></th>
                <th colSpan="3">Tanggal</th>
                <th>{getTanggal(items, "1")}</th>
                <th>{getTanggal(items, "2")}</th>
                <th>{getTanggal(items, "3")}</th>
                <th>{getTanggal(items, "4")}</th>
                <th>{getTanggal(items, "5")}</th>
                <th>{getTanggal(items, "6")}</th>
                <th>{getTanggal(items, "UTS")}</th>
                <th>{getTanggal(items, "7")}</th>
                <th>{getTanggal(items, "8")}</th>
                <th>{getTanggal(items, "9")}</th>
                <th>{getTanggal(items, "10")}</th>
                <th>{getTanggal(items, "11")}</th>
                <th>{getTanggal(items, "12")}</th>
                <th>{getTanggal(items, "UAS")}</th>
              </tr>
              <tr>
                <th></th>
                <th colSpan="3">Paraf Dosen</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
            </tbody>
          </table>
          <PrintFooter/>
          <div className={`text-center mb-5 ${print && "d-none"}`}>
            <span
              type="button"
              className="text-info fa fa-print fa-lg mx-1"
              onClick={() => {
                setPrint(true);
                window.scrollTo(0, 0);
                setTimeout(() => {
                  window.print();
                  setPrint(false);
                }, 900);
              }}
            >
              Print
            </span>
          </div>
        </div>
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default KelasAbsensi;
