import React from 'react'

function TextOnOffMhs(props) {
  const {children} = props;
  return (
    <div className="text-white bg-gradient-primary border-bottom-warning rounded-4 p-3 text-justify mb-3">
        <div className="position-relative border border-secondary rounded p-3">
        <div className="text-animate position-absolute bg-gradient-warning text-black rounded fw-semibold px-3" style={{rotate: '-4deg', left: '-15px', top: '-5px'}}><i className="fa fa-fw fa-bell fa-shake"/> Informasi Penting !</div>
            <div className="mt-2">
              {children}
              {!children &&
                <>
                  <p className="mb-0 fw-semibold"><i className="fa fa-fw fa-circle text-danger-2"/> Data mahasiswa berwarna <span className="text-danger-2">merah</span> artinya dinonaktifkan oleh <span className="text-danger-2">Akademik</span>.</p>
                  <p className="mb-0 fw-semibold"><i className="fa fa-fw fa-circle text-warning"/> Data mahasiswa berwarna <span className="text-warning">kuning</span> artinya dinonaktifkan oleh <span className="text-warning">Keuangan</span>.</p>
                </>
              }
            </div>
        </div>
    </div>
  )
}

export default TextOnOffMhs