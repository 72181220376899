import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchPendaftar, patchPendaftar } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterCabang,
  FilterKdbb,
  FilterPagination,
  FilterSearch,
  FilterStatusPendaftar,
  FilterTahun,
} from "../../components/layouts/TopContent";
import LoadingPage from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import PaginationSection from "../../components/sections/Pagination";
import ShowHideDetail from "../../components/ShowHideDetail";
import { saAlert } from "../../helpers";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import PendaftarBiaya from "./Biaya";
import PendaftarEdit from "./Edit";
import PendaftarTableDetail from "./TableDetail";
import PendaftarUpdateStatus from "./UpdateStatus";

const PendaftarFo = () => {
  const user = useRecoilValue(userState);
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);

  const [items, setItems] = useState({});
  const [pendaftarStatus, setPendaftarStatus] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [kdbb, setKdbb] = useState("");
  const [tahunAjaran, setTahunAjaran] = useState("2024");
  const [pagination, setPagination] = useState(10);
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    if (tahunAjaran !== "") {
      setIsLoaded(false);
      try {
        const res = await fetchPendaftar(
          page,
          `count_pendaftar_status=true&search=${search}&status=${status}&kdbb=${kdbb}&tahun_ajaran=${tahunAjaran}&pagination=${pagination}&cabang=${cabang}`
        );
        // console.log(res.data);
        if (res.data) {
          setItems(res.data.pendaftar);
          setPendaftarStatus(res.data.count_pendaftar_status);
          setIsLoaded(true);
        }
      } catch (err) {
        console.log(err);
      }
    }
  }, [page, search, status, kdbb, tahunAjaran, pagination, cabang]);

  useEffect(() => {
    setTitle("Data Pendaftar");
    fetchData();
  }, [setTitle, fetchData]);

  const [detail, setDetail] = useState(false);

  return (
    <>
      {/* Filter 1 */}
      <div className="row mb-2">
        <div className="col-lg-4 mb-2">
          <FilterStatusPendaftar
            onChange={(res) => {
              setPage(1);
              setStatus(res);
            }}
          />
        </div>
        <div className="col-lg-4 mb-2">
          <FilterKdbb
            onChange={(res) => {
              setPage(1);
              setKdbb(res);
            }}
          />
        </div>
        <div className="col-lg-4 mb-2">
          <FilterTahun
            onChange={(res) => {
              setPage(1);
              setTahunAjaran(res);
            }}
            value={tahunAjaran}
          />
        </div>
        <div className="col-lg-4 mb-2">
          <FilterPagination
            onChange={(res) => {
              setPage(1);
              setPagination(res);
            }}
          />
        </div>
        {user.cabang === "pusat" && (
          <div className="col-lg-4">
            <FilterCabang
              onChange={(res) => {
                setPage(1);
                setCabang(res);
              }}
            />
          </div>
        )}
        <div className="col-lg-4 mb-2">
          <FilterSearch
            onChange={(res) => {
              setPage(1);
              setSearch(res);
            }}
          />
        </div>
      </div>

      <div className="row mb-2 mt-4">
        <div className="col-lg-12 text-center">
          <h5 className="mb-4 text-dark fw-semibold">Status Data Pendaftar Tahun {tahunAjaran}</h5>
        </div>
        <div className="col-lg mb-3">
          <div className="position-relative text-center bg-gradient-primary text-white rounded-3 shadow border-bottom-dark p-3">
            <div className="position-absolute shadow-sm rounded-pill bg-primary" style={{padding: '10px 6px', left: '-10px', top: '-10px'}}>
              <i className="fa fa-2xl fa-circle-plus text-white"/>
            </div>
            <p className="mb-0 fw-semibold">Pendaftar</p>
            <h3 className="mb-0 fw-semibold">{pendaftarStatus.pendaftar}</h3>
          </div>
        </div>
        <div className="col-lg mb-3">
          <div className="position-relative text-center bg-gradient-warning text-black rounded-3 shadow border-bottom-dark p-3">
            <div className="position-absolute shadow-sm rounded-pill bg-warning" style={{padding: '10px 6px', left: '-10px', top: '-10px'}}>
              <i className="fa fa-2xl fa-circle-check text-black"/>
            </div>
            <p className="mb-0 fw-semibold">Daftar</p>
            <h3 className="mb-0 fw-semibold">{pendaftarStatus.daftar}</h3>
          </div>
        </div>
        <div className="col-lg mb-3">
          <div className="position-relative text-center bg-gradient-success text-white rounded-3 shadow border-bottom-dark p-3">
            <div className="position-absolute shadow-sm rounded-pill bg-success-2" style={{padding: '10px 6px', left: '-10px', top: '-10px'}}>
              <i className="fa fa-2xl fa-rotate-right text-white"/>
            </div>
            <p className="mb-0 fw-semibold">DU</p>
            <h3 className="mb-0 fw-semibold">{pendaftarStatus.du}</h3>
          </div>
        </div>
      </div>

      <ShowHideDetail detail={detail} onClick={(res) => setDetail(res)} />

      <ul>
        <li>
          Untuk pendaftar yang memiliki afiliasi, dana harus dicairkan terlebih
          dahulu sebelum menjadikan mahasiswa aktif.
        </li>
        <li>
          Untuk nama pendaftar dengan background warna{" "}
          <span className="bg-success text-white ps-1 pe-2 py-0 rounded-pill"><i className="fa fa-fw fa-sm fa-circle-check"/> hijau</span>{" "}
          berarti sudah melakukan pelunasan DU
        </li>
      </ul>

      {!isLoaded ? (
        <LoadingPage />
      ) : items.data && items.data.length > 0 ? (
        detail ? (
          <PendaftarTableDetail pendaftar={items} />
        ) : (
          <div className="row">
            <div className="col-3 pe-0">
              <LayoutTable>
                <LayoutThead>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Nama</th>
                </LayoutThead>
                <tbody>
                  {items.data.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-transparent py-1 border-silver text-center">{items.from + index}</td>
                        <td
                          className="text-transparent py-1 border-silver text-nowrap"
                        >
                          <span 
                          className={`d-block ${
                            i.biaya && i.biaya.status
                              ? "bg-success text-white rounded-pill py-0 ps-1 pe-2"
                              : ""
                          }`}
                          // style={{padding: i.biaya && i.biaya.status && '0px 2px'}}
                          >
                            {
                              i.biaya && i.biaya.status
                                ? <i className="fa fa-fw fa-sm fa-circle-check me-1"/>
                                : ""
                            }
                            {i.nama}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
            <div className="col-4 px-0">
              <LayoutTable>
                <LayoutThead>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Cabang</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Status</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">KDBB</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Afiliasi</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">No HP</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Jurusan SMA</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Nama SMA</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Tahun Tamat</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Tanggal Daftar</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Tanggal Update</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Last Updated By</th>
                </LayoutThead>
                <tbody>
                  {items.data.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td
                          style={{ textTransform: "capitalize" }}
                          className="py-1 border-silver text-center"
                        >
                          {i.cabang}
                        </td>
                        <td className="py-1 border-silver text-start text-nowrap px-2">
                          {!i.status && (
                            <div className="text-secondary">
                              <i className="fa fa-fw fa-sm fa-ban me-1"/>
                              <span className="fw-semibold">Belum Daftar</span>
                            </div>
                          )}
                          {parseInt(i.status) === 1 && (
                            // <span className="fw-semibold text-warning">Sudah Daftar</span>
                            <div className="text-success">
                              <i className="fa fa-fw fa-sm fa-circle-check me-1"/>
                              <span className="fw-semibold">Sudah Daftar</span>
                            </div>
                          )}
                          {parseInt(i.status) === 2 && (
                            // <span className="fw-semibold text-success">Sudah DU</span>
                            <div className="text-info">
                              <i className="fa fa-fw fa-sm fa-circle-check me-1"/>
                              <span className="fw-semibold">Sudah DU</span>
                            </div>
                          )}
                          {parseInt(i.status) === 3 && (
                            // <span className="fw-semibold text-info">Sudah Cair</span>
                            <div className="text-purple">
                              <i className="fa fa-fw fa-sm fa-sack-dollar me-1"/>
                              <span className="fw-semibold">Sudah Cair</span>
                            </div>
                          )}
                          {parseInt(i.status) === 4 && (
                            // <span className="fw-semibold text-primary">Mhs Aktif</span>
                            <div className="text-primary-2">
                              <i className="fa fa-fw fa-sm fa-circle-user me-1"/>
                              <span className="fw-semibold">Mhs Aktif</span>
                            </div>
                          )}
                          {parseInt(i.status) === 9 && (
                            // <span className="fw-semibold text-danger">Batal Daftar</span>
                            <div className="text-danger">
                              <i className="fa fa-fw fa-sm fa-circle-xmark me-1"/>
                              <span className="fw-semibold">Batal Daftar</span>
                            </div>
                          )}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {i.kdbb ? 
                            <i className="fa fa-fw fa-circle-check text-success"/>
                            : 
                            <i className="fa fa-fw fa-circle-xmark text-danger"/>
                          }
                        </td>
                        <td className="py-1 border-silver text-center">
                          {i.afiliasi_id ? i.afiliasi_id : "-"}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">{i.no_hp}</td>
                        <td className="py-1 border-silver text-nowrap">{i.jurusan}</td>
                        <td className="py-1 border-silver text-nowrap">{i.asal_sekolah}</td>
                        <td className="py-1 border-silver text-nowrap text-center">{i.tahun_tamat}</td>
                        <td className="py-1 border-silver text-nowrap">
                          {i.created_at}
                        </td>
                        <td className="py-1 border-silver text-nowrap">
                          {i.updated_at}
                        </td>
                        <td className="py-1 border-silver text-nowrap">
                          {i.updated_by ? i.updated_by : '-'}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
            <div className="col-5 ps-0">
              <LayoutTable>
                <LayoutThead className="text-nowrap">
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                    <i className="fa fa-fw fa-cog"></i>
                  </th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Update To</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Jalur Pendaftaran</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal"><i className="fa fa-fw fa-cog"></i></th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Th Ajaran</th>
                </LayoutThead>
                <tbody>
                  {items.data.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td className="py-1 border-silver text-center text-nowrap">
                          <PendaftarEdit
                            pendaftar_id={i.id}
                            onFetchData={fetchData}
                            pendaftar={i}
                          />
                          <i className="mr-1" />
                          <PendaftarBiaya
                            onFetchData={fetchData}
                            pendaftar={i}
                          />
                        </td>
                        <td className="py-1 border-silver text-center text-nowrap">
                          <PendaftarUpdateStatus
                            pendaftar={i}
                            onFetchData={fetchData}
                          />
                        </td>
                        <td className="py-1 border-silver">
                          <OffOn pendaftar={i} onFetchData={fetchData} />
                        </td>
                        <td className="py-1 border-silver text-center text-nowrap">
                          {parseInt(i.status) === 2 ||
                          parseInt(i.status) === 3 ? (
                            <>
                              {/* <PendaftarSetMhs mhs={i} onFetchData={fetchData} /> */}
                              <span>-</span>
                            </>
                          ) : (
                            <>
                              {parseInt(i.status) === 4 ? (
                                <span className="text-success">Done</span>
                              ) : (
                                <Link 
                                  to={"/print-pendaftar/" + i.id}
                                  className="btn btn-sm btn-primary-4 border-0 py-0 text-sm rounded"
                                  >
                                  <i className="fa fa-fw fa-sm fa-print"/>
                                </Link>
                              )}
                            </>
                          )}
                        </td>
                        <td className="py-1 border-silver text-center">
                          {i.tahun_ajaran ? i.tahun_ajaran : "-"}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
          </div>
        )
      ) : (
        <PageNoData />
      )}

      <PaginationSection
        links={items.links}
        onChangePage={(res) => setPage(res)}
      />
    </>
  );
};

const OffOn = (props) => {
  const { pendaftar, onFetchData } = props;

  const handleChange = async (e) => {
    try {
      const res = await patchPendaftar(pendaftar.id, {
        off_on: e.target.value,
      });
      if (res.data === "success") {
        onFetchData();
        saAlert("success", "Berhasil update data !");
      }
    } catch (err) {
      console.error(err.response);
    }
  };

  return (
    <select
      name=""
      id=""
      className="form-select form-select-sm form-select-custom py-0 text-sm fw-semibold border-silver rounded w-100 mx-auto"
      onChange={handleChange}
      defaultValue={pendaftar.off_on ? pendaftar.off_on : ""}
    >
      <option value="">Pilih</option>
      <option value="w">Walkin</option>
      <option value="o">Online</option>
      <option value="r">Referral</option>
    </select>
  );
};

export default PendaftarFo;
