import React, { useRef, useState } from "react";
import { patchPenyelenggara } from "../../api";
import FormInput from "../../components/forms/Input";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";
import SmallButton from "../../components/forms/SmallButton";

const PenyelenggaraEdit = (props) => {
  const { bidang, onFetchData } = props;
  const formRef = useRef();

  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    patchPenyelenggara(form.id, form)
      .then((res) => {
        if (res.data === "success") {
          onFetchData();
          handleClose();
          saAlert("success", "Berhasil ubah data penyelenggara !");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      });
  };

  return (
    <>
      <SmallButton
        type='edit'
        onClick={() => {
          setForm(bidang);
          setModal(true);
        }}
      />
      <LayoutModal
        modal={modal}
        title="Ubah Data Penyelenggara"
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={formRef}>
          <FormInput
            label="Nama Penyelenggara"
            name="nama"
            value={form.nama}
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.nama}
          />
          <div className="row">
            <NewSubmitButton onClose={handleClose}/>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default PenyelenggaraEdit;
