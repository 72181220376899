import React, { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { fetchToGenerateNilai, showToGenerateNilai } from "../../api";
import { saAlert } from "../../helpers";
import { titleState } from "../../storage/pages";

const GenerateNilaiAbsensi = (props) => {
  const { user = {} } = props;
  const setTitle = useSetRecoilState(titleState);

  useEffect(() => setTitle("Generate Nilai Absensi"), [setTitle]);

  const [items, setItems] = useState({});

  useEffect(() => {
    let intervalId = setInterval(() => {
      //assign interval to a variable to clear it.
      const fetchData = async () => {
        try {
          const res = await showToGenerateNilai();
          if (res.data && res.data.nilai) {
            setItems(res.data.nilai);
          }
        } catch (err) {
          console.error(err);
        }
      };

      if (user.jabatan === "IT Programmer") {
        fetchData();
      }
    }, 5000);

    return () => clearInterval(intervalId); //This is important
  }, [user.jabatan]);

  useEffect(() => {
    if (
      items.krs &&
      items.krs.mhs &&
      items.krs.mhs.id &&
      items.krs.kelas &&
      items.krs.kelas.id
    ) {
      const handleSubmit = async () => {
        try {
          const res = await fetchToGenerateNilai(
            items.krs.mhs.id,
            items.krs.kelas.id
          );
          console.log("---------------------------");
          console.log(JSON.stringify(res.data).substr(0, 200));
          console.log(JSON.stringify(res.data.krs.mhs.nilai_ip));
          console.log(JSON.stringify(res.data.krs.mhs.nilai_akhir));
          console.log("---------------------------");
          saAlert(
            "success",
            `Set Nilai to krs with ID ${res.data.id} is successfuly !`
          );
        } catch (err) {
          console.error(err);
        }
      };
      handleSubmit();
    }

    return () => setItems({});
  }, [items.krs]);

  return <h1>This Page Is Looping for 5 seconds</h1>;
};

export default GenerateNilaiAbsensi;
