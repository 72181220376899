import React, { useEffect, useRef, useState } from 'react'
import LayoutModal, { NewSubmitButton } from '../../../components/layouts/Modal'
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../../components/layouts/TableNew';
import { LayoutAddBtn } from '../../../components/layouts/TopContent';
import FormInput from '../../../components/forms/Input';
import FormSelect from '../../../components/forms/Select';
import FormTextarea from '../../../components/forms/Textarea';
import SmallButton from '../../../components/forms/SmallButton';
import { deleteSoalSurveyMhs, fetchSoalSurveyMhs, patchSoalSurveyMhs, postSoalSurveyMhs } from '../../../api';
import { saAlert, saConfirm } from '../../../helpers';
import CustomCard from '../../../components/layouts/CustomCard';

function SMSoalSurvey({surveyMhs, jenisSurvey}) {
    
    const [modal, setModal] = useState(false);
    const [create, setCreate] = useState(false);
    const [edit, setEdit] = useState(false);

    const [soalSurvey, setSoalSurvey] = useState([]);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const formCreateRef = useRef(null);
    const formEditRef = useRef(null);

    const fetchdataSoal = async () => {
        try {
            const res = await fetchSoalSurveyMhs("all");
            setSoalSurvey(res.data.soal_survey);
        } catch (error) {
            console.log(error.response);
        }
    }

    const handleChangeCreate = (e) => {
        setForm((prevState) => {
            return { ...prevState , [e.target.name] : e.target.value };
        });
    }

    const handleSubmitCreate = async (e) => {
        e.preventDefault();
        try {
            const res = await postSoalSurveyMhs(form);
            if (res.data.status === 'success') {
                fetchdataSoal();
                handleCloseCreate();
                saAlert(res.data.status, res.data.msg);
            }
        } catch (error) {
            setErrors(error.response?.data?.errors);
        }
    }

    const handleOpen = () => {
        fetchdataSoal();
        setModal(true);
    }
    const handleClose = () => {
        setModal(false);
        if (formCreateRef.current) {
            formCreateRef.current.reset();
        }
        setForm({});
        setErrors({});
        setCreate(false);
        setEdit(false);
    }

    const handleOpenCreate = () => {
        setForm({jenis_survey_id: jenisSurvey.id});
        setCreate(true);
    }

    const handleCloseCreate = () => {
        if (formCreateRef.current) {
            formCreateRef.current.reset();
        }
        setForm({});
        setErrors({});
        setCreate(false);
    }

    const handleOpenEdit = (data) => {
        setForm(data);
        setTimeout(() => {
            setEdit(true);
        }, 250);
    }

    const handleCloseEdit = () => {
        if (formEditRef.current) {
            formEditRef.current.reset();
        }
        setForm({});
        setErrors({});
        setEdit(false);
    }

    const handleChangeEdit = (e) => {
        setForm((prevState) => {
            return { ...prevState , [e.target.name] : e.target.value };
        });
    }

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        try {
            const res = await patchSoalSurveyMhs(form.id, form);
            if (res.data.status === 'success') {
                fetchdataSoal();
                handleCloseEdit();
                saAlert(res.data.status, res.data.msg);
            }
        } catch (error) {
            setErrors(error.response?.data?.errors);
        }
    }

    const handleDelete = (id) => {
        saConfirm("warning", "Yakin hapus soal survey ini ?").then(async (res) => {
          if (res.isConfirmed) {
            const res = await deleteSoalSurveyMhs(id);
            if (res.data && res.data.status) {
              fetchdataSoal();
              saAlert(res.data.status, res.data.msg);
            }
          }
        });
    };

    const arrayPilihanGanda = [
        {
            id: '1',
            pertama: 'Sangat Tidak Setuju',
            kedua: 'Tidak Setuju',
            ketiga: 'Cukup Setuju',
            keempat: 'Setuju',
            kelima: 'Sangat Setuju',
        },
        {
            id: '2',
            pertama: 'Sangat Buruk',
            kedua: 'Buruk',
            ketiga: 'Cukup',
            keempat: 'Baik',
            kelima: 'Sangat Baik',
        },
        {
            id: '3',
            pertama: 'Sangat Tidak Penting',
            kedua: 'Tidak Penting',
            ketiga: 'Cukup Penting',
            keempat: 'Penting',
            kelima: 'Sangat Penting',
        },
        {
            id: '4',
            pertama: 'Sangat Tidak Puas',
            kedua: 'Tidak Puas',
            ketiga: 'Cukup Puas',
            keempat: 'Puas',
            kelima: 'Sangat Puas',
        },
        {
            id: '5',
            pertama: 'Sangat Tidak Sering',
            kedua: 'Tidak Sering',
            ketiga: 'Cukup Sering',
            keempat: 'Sering',
            kelima: 'Sangat Sering',
        },
    ]

  return (
    <>
        <SmallButton onClick={handleOpen} margin="me-1" type="edit" text="Soal Survey"/>


        <LayoutModal title='Soal Survey' modal={modal} onClose={handleClose}>
            {!(create || edit) ?
                <>
                <p className='mb-0 fw-semibold text-primary'>Nama Survey :</p>
                <h6 className='fw-medium text-black'>{surveyMhs?.name}</h6>
                <p className='mb-0 fw-semibold text-primary'>Jenis survey :</p>
                <h6 className='fw-medium text-black'>{jenisSurvey?.name}</h6>
                <LayoutAddBtn onClick={handleOpenCreate} add='Tambah Soal Survey'/>

                <div className='row'>
                    <div className='col-3 pe-0'>
                        <TableNew>
                            <Thead>
                                <Tr className='text-center'>
                                    <Th>No</Th>
                                    <Th>Kode</Th>
                                    <Th>Type</Th>
                                    <Th>Kode</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {soalSurvey.filter((ss) => parseInt(ss.jenis_survey_id) === parseInt(jenisSurvey.id)).length > 0 ? (
                                    soalSurvey.filter((ss) => parseInt(ss.jenis_survey_id) === parseInt(jenisSurvey.id)).map((ss, index) => {
                                        return (
                                            <Tr key={index}>
                                                <Td className='text-nowrap text-center'>{index + 1}</Td>
                                                <Td className='text-nowrap text-center'>{ss.kode}</Td>
                                                <Td className={`text-nowrap text-center fw-semibold text-primary-2 ${ss.type === 1 ? 'text-primary-2' : ''} ${ss.type === 2 ? 'text-success' : ''}`}>
                                                    {ss.type === 1 ? 'Pilihan Ganda' : ''}
                                                    {ss.type === 2 ? 'Soal Essay' : ''}
                                                </Td>
                                                <Td className='text-nowrap text-center'>{ss.jenis_pilihan_ganda ? ss.jenis_pilihan_ganda : '-'}</Td>
                                            </Tr>
                                        )
                                    })
                                ) : (
                                    <Tr>
                                        <Td colSpan={4} className="text-nowrap">Belum ada soal survey.</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </TableNew>
                    </div>
                    <div className='col-8 px-0'>
                        <TableNew>
                            <Thead>
                                <Tr className='text-center'>
                                    <Th className="text-nowrap">Pertanyaan</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {soalSurvey.filter((ss) => parseInt(ss.jenis_survey_id) === parseInt(jenisSurvey.id)).length > 0 ? (
                                    soalSurvey.filter((ss) => parseInt(ss.jenis_survey_id) === parseInt(jenisSurvey.id)).map((ss, index) => {
                                        return (
                                            <Tr key={index}>
                                                <Td className='text-nowrap'>{ss.pertanyaan}</Td>
                                            </Tr>
                                        )
                                    })
                                ) : (
                                    <Tr>
                                        <Td className="text-nowrap text-center">-</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </TableNew>
                    </div>
                    <div className='col-1 ps-0'>
                        <TableNew  copy={false}>
                            <Thead>
                                <Tr className='text-center'>
                                    <Th className="text-nowrap"><i className='fa fa-fw fa-cog'/></Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {soalSurvey.filter((ss) => parseInt(ss.jenis_survey_id) === parseInt(jenisSurvey.id)).length > 0 ? (
                                    soalSurvey.filter((ss) => parseInt(ss.jenis_survey_id) === parseInt(jenisSurvey.id)).map((ss, index) => {
                                        return (
                                            <Tr key={index}>
                                                <Td className='text-center text-nowrap'>
                                                    <SmallButton onClick={() => handleOpenEdit(ss)} margin="me-1" type="edit"/>
                                                    <SmallButton onClick={() => handleDelete(ss.id)} type="delete"/>
                                                </Td>
                                            </Tr>
                                        )
                                    })
                                ) : (
                                    <Tr>
                                        <Td className="text-nowrap text-center">-</Td>
                                    </Tr>
                                )}
                            </Tbody>
                        </TableNew>
                    </div>
                </div>

                <button onClick={handleClose} className='btn btn-sm btn-dark border-0'><i className='fa fa-fw fa-sm fa-arrow-left'/> Kembali</button>
                </>
                :
                <>
                {create &&
                    <div>
                        <h6 className='text-black fw-semibold'>Tambah Soal Survey</h6>
                        <form ref={formCreateRef} onSubmit={handleSubmitCreate}>
                            <div className='row mb-3'>
                                <div className={`${parseInt(form.type) === 1 ? 'col-lg-8' : 'col-lg-12'}`}>
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <FormInput
                                                label='Kode'
                                                type='text'
                                                name="kode"
                                                onChange={handleChangeCreate}
                                                error={errors.kode}
                                            />
                                        </div>
                                        <div className='col-lg-4'>
                                            <FormSelect
                                                label='Type'
                                                name="type"
                                                onChange={handleChangeCreate}
                                                error={errors.type}
                                            >
                                                <option value="">Pilih</option>
                                                <option value="1">Pilihan ganda</option>
                                                <option value="2">Essay</option>
                                            </FormSelect>
                                        </div>
                                        {parseInt(form?.type) === 1 &&
                                            <div className='col-lg-4'>
                                                <FormSelect
                                                    label='Jenis pilihan ganda'
                                                    name="jenis_pilihan_ganda"
                                                    onChange={handleChangeCreate}
                                                    error={errors.type}
                                                    required
                                                >
                                                    <option value="">Pilih</option>
                                                    {arrayPilihanGanda.length > 0 && arrayPilihanGanda.map((pc, index) => {
                                                        return (
                                                            <option key={index} value={pc.id}>Pilihan {pc.id}</option>
                                                        )
                                                    })}
                                                    {/* <option value="1">Kode 1</option>
                                                    <option value="2">Kode 2</option>
                                                    <option value="3">Kode 3</option> */}
                                                </FormSelect>
                                            </div>
                                        }
                                        <div className='col-lg-12'>
                                            <FormTextarea
                                                label='Pertanyaan'
                                                name="pertanyaan"
                                                onChange={handleChangeCreate}
                                                error={errors.pertanyaan}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={`${parseInt(form.type) === 1 ? 'd-block' : 'd-none'} col-lg-4`}>
                                    {form.jenis_pilihan_ganda ? (
                                        <>
                                            {arrayPilihanGanda.length > 0 && arrayPilihanGanda.map((pc, index) => {
                                                if (parseInt(form?.jenis_pilihan_ganda) === parseInt(pc.id)) {
                                                    return (
                                                        <CustomCard key={index}>
                                                            <h6 className='mb-0 fw-semibold text-primary'>Kode {pc.id}</h6>
                                                            <p className='text-sm text-gray-700'>Skala Likert 1 s/d 5</p>
                                                            <ul className='ps-3 mb-0 text-gray-700' style={{listStyle: 'none'}}>
                                                                <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> {pc.pertama}</li>
                                                                <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> {pc.kedua}</li>
                                                                <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> {pc.ketiga}</li>
                                                                <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> {pc.keempat}</li>
                                                                <li className='pb-1'><i className='fa fa-fw fa-circle text-info me-1'/> {pc.kelima}</li>
                                                            </ul>
                                                        </CustomCard>
                                                    )
                                                }
                                            })}
                                            {/* {parseInt(form?.jenis_pilihan_ganda) === 1 &&
                                                <CustomCard>
                                                    <h6 className='mb-0 fw-semibold text-primary'>Kode 01</h6>
                                                    <p className='text-sm text-gray-700'>Skala Likert 1 s/d 5</p>
                                                    <ul className='ps-3 mb-0 text-gray-700' style={{listStyle: 'none'}}>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Sangat Tidak Setuju</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Tidak Setuju</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Cukup Setuju</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Setuju</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle text-info me-1'/> Sangat Setuju</li>
                                                    </ul>
                                                </CustomCard>
                                            }
                                            {parseInt(form?.jenis_pilihan_ganda) === 2 &&
                                                <CustomCard>
                                                    <h6 className='mb-0 fw-semibold text-primary'>Kode 02</h6>
                                                    <p className='text-sm text-gray-700'>Skala Likert 1 s/d 5</p>
                                                    <ul className='ps-3 mb-0 text-gray-700' style={{listStyle: 'none'}}>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Sangat Buruk</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Buruk</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Cukup</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Baik</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle text-info me-1'/> Sangat Baik</li>
                                                    </ul>
                                                </CustomCard>
                                            }
                                            {parseInt(form?.jenis_pilihan_ganda) === 3 &&
                                                <CustomCard>
                                                    <h6 className='mb-0 fw-semibold text-primary'>Kode 03</h6>
                                                    <p className='text-sm text-gray-700'>Skala Likert 1 s/d 5</p>
                                                    <ul className='ps-3 mb-0 text-gray-700' style={{listStyle: 'none'}}>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Sangat Tidak Penting</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Tidak Penting</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Cukup Penting</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Penting</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle text-info me-1'/> Sangat Penting</li>
                                                    </ul>
                                                </CustomCard>
                                            } */}
                                        </>
                                    ) : (
                                        <CustomCard>
                                            <div className='py-5 h-full d-flex justify-content-center align-items-center text-center'>
                                                <p className='py-3 mb-0 text-gray-600'>Preview jenis pilihan ganda akan muncul disini.</p>
                                            </div>
                                        </CustomCard>
                                    )}
                                </div>
                            </div>

                            <div className='row'>
                                <NewSubmitButton onClose={handleCloseCreate}/>
                            </div>
                        </form>
                    </div>
                }
                {edit &&
                    <div>
                        <h6 className='text-black fw-semibold'>Edit Soal Survey</h6>
                        <form ref={formEditRef} onSubmit={handleSubmitEdit}>
                            <div className='row mb-3'>
                                <div className={`${parseInt(form.type) === 1 ? 'col-lg-8' : 'col-lg-12'}`}>
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <FormInput
                                                label='Kode'
                                                type='text'
                                                name="kode"
                                                value={form.kode}
                                                onChange={handleChangeEdit}
                                                error={errors.kode}
                                            />
                                        </div>
                                        <div className='col-lg-4'>
                                            <FormSelect
                                                label='Type'
                                                name="type"
                                                value={form.type}
                                                onChange={handleChangeEdit}
                                                error={errors.type}
                                            >
                                                <option value="">Pilih</option>
                                                <option value="1">Pilihan ganda</option>
                                                <option value="2">Essay</option>
                                            </FormSelect>
                                        </div>
                                        {parseInt(form?.type) === 1 &&
                                            <div className='col-lg-4'>
                                                <FormSelect
                                                    label='Jenis pilihan ganda'
                                                    name="jenis_pilihan_ganda"
                                                    value={form.jenis_pilihan_ganda}
                                                    onChange={handleChangeEdit}
                                                    error={errors.jenis_pilihan_ganda}
                                                    required
                                                >
                                                    <option value="">Pilih</option>
                                                    {arrayPilihanGanda.length > 0 && arrayPilihanGanda.map((pc, index) => {
                                                        return (
                                                            <option key={index} value={pc.id}>Pilihan {pc.id}</option>
                                                        )
                                                    })}
                                                </FormSelect>
                                            </div>
                                        }
                                        <div className='col-lg-12'>
                                            <FormTextarea
                                                label='Pertanyaan'
                                                name="pertanyaan"
                                                value={form.pertanyaan}
                                                onChange={handleChangeEdit}
                                                error={errors.pertanyaan}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={`${parseInt(form.type) === 1 ? 'd-block' : 'd-none'} col-lg-4`}>
                                    {form.jenis_pilihan_ganda ? (
                                        <>
                                            {arrayPilihanGanda.length > 0 && arrayPilihanGanda.map((pc, index) => {
                                                if (parseInt(form?.jenis_pilihan_ganda) === parseInt(pc.id)) {
                                                    return (
                                                        <CustomCard key={index}>
                                                            <h6 className='mb-0 fw-semibold text-primary'>Kode {pc.id}</h6>
                                                            <p className='text-sm text-gray-700'>Skala Likert 1 s/d 5</p>
                                                            <ul className='ps-3 mb-0 text-gray-700' style={{listStyle: 'none'}}>
                                                                <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> {pc.pertama}</li>
                                                                <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> {pc.kedua}</li>
                                                                <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> {pc.ketiga}</li>
                                                                <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> {pc.keempat}</li>
                                                                <li className='pb-1'><i className='fa fa-fw fa-circle text-info me-1'/> {pc.kelima}</li>
                                                            </ul>
                                                        </CustomCard>
                                                    )
                                                }
                                            })}
                                            {/* {parseInt(form?.jenis_pilihan_ganda) === 1 &&
                                                <CustomCard>
                                                    <h6 className='mb-0 fw-semibold text-primary'>Kode 01</h6>
                                                    <p className='text-sm text-gray-700'>Skala Likert 1 s/d 5</p>
                                                    <ul className='ps-3 mb-0 text-gray-700' style={{listStyle: 'none'}}>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Sangat Tidak Setuju</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Tidak Setuju</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Cukup Setuju</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Setuju</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle text-info me-1'/> Sangat Setuju</li>
                                                    </ul>
                                                </CustomCard>
                                            }
                                            {parseInt(form?.jenis_pilihan_ganda) === 2 &&
                                                <CustomCard>
                                                    <h6 className='mb-0 fw-semibold text-primary'>Kode 02</h6>
                                                    <p className='text-sm text-gray-700'>Skala Likert 1 s/d 5</p>
                                                    <ul className='ps-3 mb-0 text-gray-700' style={{listStyle: 'none'}}>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Sangat Buruk</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Buruk</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Cukup</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Baik</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle text-info me-1'/> Sangat Baik</li>
                                                    </ul>
                                                </CustomCard>
                                            }
                                            {parseInt(form?.jenis_pilihan_ganda) === 3 &&
                                                <CustomCard>
                                                    <h6 className='mb-0 fw-semibold text-primary'>Kode 03</h6>
                                                    <p className='text-sm text-gray-700'>Skala Likert 1 s/d 5</p>
                                                    <ul className='ps-3 mb-0 text-gray-700' style={{listStyle: 'none'}}>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Sangat Tidak Penting</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Tidak Penting</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Cukup Penting</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle fa-regular me-1'/> Penting</li>
                                                        <li className='pb-1'><i className='fa fa-fw fa-circle text-info me-1'/> Sangat Penting</li>
                                                    </ul>
                                                </CustomCard>
                                            } */}
                                        </>
                                    ) : (
                                        <CustomCard>
                                            <div className='py-5 h-full d-flex justify-content-center align-items-center text-center'>
                                                <p className='py-3 mb-0 text-gray-600'>Preview jenis pilihan ganda akan muncul disini.</p>
                                            </div>
                                        </CustomCard>
                                    )}
                                </div>
                            </div>

                            <div className='row'>
                                <NewSubmitButton onClose={handleCloseEdit}/>
                            </div>
                        </form>
                    </div>
                }
                </>
            }

        </LayoutModal>

    </>
  )
}

export default SMSoalSurvey