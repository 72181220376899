import React, { useState } from 'react'
import LayoutModal from '../../../components/layouts/Modal'
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../../components/layouts/TableNew';
import SmallButton from '../../../components/forms/SmallButton';

function SMListMahasiswa(props) {

    const [modal, setModal] = useState(false);

    const handleOpen = () => {
        setModal(true);
    }

    const handleClose = () => {
        setModal(false);
    }

  return (
    <>
        <SmallButton onClick={handleOpen} margin="me-1" type="custom" icon="eye" buttonColor="primary-2" text="Data Collect"/>

        <LayoutModal title='List mahasiswa yang telah mengisi survey' modal={modal} onClose={handleClose}>

            <button>filter cabang nanti disini</button>

            <div className='row'>
                <div className='col-4 pe-0'>
                    <TableNew>
                        <Thead>
                            <Tr className='text-center'>
                                <Th>No</Th>
                                <Th>Nama</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td className='text-center'>1</Td>
                                <Td>Kadek Lanang Lanusa Putera</Td>
                            </Tr>
                        </Tbody>
                    </TableNew>
                </div>
                <div className='col-8 ps-0'>
                    <TableNew>
                        <Thead>
                            <Tr className='text-center'>
                                <Th>A1</Th>
                                <Th>A1</Th>
                                <Th>A1</Th>
                                <Th>A1</Th>
                                <Th>A1</Th>
                                <Th>A1</Th>
                                <Th>A1</Th>
                                <Th>A1</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td className='text-center'>5</Td>
                                <Td className='text-center'>5</Td>
                                <Td className='text-center'>5</Td>
                                <Td className='text-center'>5</Td>
                                <Td className='text-center'>5</Td>
                                <Td className='text-center'>5</Td>
                                <Td className='text-center'>5</Td>
                                <Td className='text-center text-nowrap'>jawaban soal esay ada disini.</Td>
                            </Tr>
                        </Tbody>
                    </TableNew>
                </div>
            </div>

            <button onClick={handleClose} className='btn btn-sm btn-dark border-0'><i className='fa fa-fw fa-sm fa-arrow-left'/> Kembali</button>
        </LayoutModal>
    </>
  )
}

export default SMListMahasiswa