import { atom } from "recoil";
import { isWindowView } from "../helpers";

export const titleState = atom({
  key: "titleState",
  default: "No Title",
});

export const sidebarState = atom({
  key: "sidebarState",
  default: isWindowView(),
});

export const sideMenuState = atom({
  key: "sideMenuState",
  default: false,
});

export const tanggalPengajaranState = atom({
  key: "tanggalPengajaranState",
  default: "",
});

export const angkatanState = atom({
  key: "angkatanState",
  default: "",
})