import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchMhs } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterAngkatan,
  FilterJenjang,
  FilterJurusan,
  FilterSearch,
  FilterCabang,
  defaultFilterAngkatan,
  FilterStatus,
  FilterPagination,
  FilterPagiSore,
  FilterKdbb,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { saAlert, getCurrency, formatDate, getJenjang } from "../../helpers";
import { userState } from "../../storage/auth";
import { angkatanState, titleState } from "../../storage/pages";
import MhsAngsuran from "./Angsuran";
import MahasiswaNonaktifkan from "./Nonaktifkan";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const MhsKeuangan = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);
  const user = useRecoilValue(userState);
  const defAngkatan = useRecoilValue(angkatanState);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [angkatan, setAngkatan] = useState(defAngkatan);
  const [jenjang, setJenjang] = useState("D1");
  const [pagiSore, setPagiSore] = useState("");
  const [pagination, setPagination] = useState(10);
  const [status, setStatus] = useState("");
  const [cabang, setCabang] = useState("");
  const [kdbb, setKdbb] = useState("");

  const fetchData = useCallback(async () => {
    if (jenjang) {
      try {
        const res = await fetchMhs(
          page,
          `pagination=${pagination}&search=${search}&jurusan=${jurusan}&angkatan=${angkatan}&jenjang=${jenjang}&pagi_sore=${pagiSore}&status=${status}&cabang=${cabang}&kdbb=${kdbb}`
        );
        if (res.data && res.data.mhs) {
          // res.data.mhs.data.sort((a, b) => (a.nama < b.nama) ? -1 : 1)
          setItems(res.data.mhs);
          setIsLoaded(true);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      saAlert("warning", "Jenjang tidak boleh kosong !");
    }
  }, [
    page,
    search,
    jurusan,
    angkatan,
    jenjang,
    pagiSore,
    status,
    pagination,
    cabang,
    kdbb,
  ]);

  useEffect(() => {
    setTitle("Data Mahasiswa");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        {/* Filter 1 */}
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterJurusan
              onChange={(res) => {
                setPage(1);
                setJurusan(res);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterSearch
              onChange={(res) => {
                setPage(1);
                setSearch(res);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterStatus
              onChange={(res) => {
                setStatus(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {/* Filter 2 */}
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterAngkatan
              value={angkatan}
              onChange={(res) => {
                setPage(1);
                setAngkatan(res);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterJenjang
              value={jenjang}
              onChange={(res) => {
                setPage(1);
                setJenjang(res);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterPagination
              onChange={(res) => {
                setPagination(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {/* Filter 3 */}
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterPagiSore
              onChange={(res) => {
                setPage(1);
                setPagiSore(res);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterKdbb
              onChange={(res) => {
                setPage(1);
                setKdbb(res);
              }}
            />
          </div>
          {user.cabang === "pusat" && (
            <div className="col-md-4">
              <FilterCabang
                onChange={(res) => {
                  setPage(1);
                  setCabang(res);
                }}
              />
            </div>
          )}
        </div>
        
        <TextOnOffMhs/>

        {jenjang !== "" && items.data && items.data.length >= 1 ? (
          <div className="row">
            <div className="col-4 pe-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center'>
                    <Th>No</Th>
                    <Th>ID</Th>
                    <Th>NIM</Th>
                    <Th>Nama</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr 
                        key={index}
                        className={`
                        ${
                          parseInt(i.status) === 9 &&
                          parseInt(i.nonaktif_by) === 1
                            ? "bg-invalid"
                            : ""
                        } ${
                          parseInt(i.status) === 9 &&
                          parseInt(i.nonaktif_by) === 2
                            ? "bg-invalid-2"
                            : ""
                        }`}
                      >
                        <Td className="text-center">{items.from + index}</Td>
                        <Td>{i.id}</Td>
                        <Td>{i.nim}</Td>
                        <Td className="text-nowrap">{i.nama}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-6 px-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center'>
                    <Th className="text-nowrap">Cabang</Th>
                    <Th className="text-nowrap">Kelas</Th>
                    <Th className="text-nowrap">Angkatan</Th>
                    <Th className="text-nowrap">Jenjang</Th>
                    <Th className="text-nowrap">No HP</Th>
                    <Th className="text-nowrap">Angsuran By FO</Th>
                    <Th className="text-nowrap">Angsuran By Keu.</Th>
                    {pagiSore === "sore" ? (
                      <Th className="text-nowrap">Biaya Kelas Malam</Th>
                    ) : (
                      ""
                    )}
                    <Th className="text-nowrap bg-primary-2">Pembayaran 1</Th>
                    <Th className="text-nowrap">Tanggal</Th>
                    <Th className="text-nowrap bg-primary-2">Pembayaran 2</Th>
                    <Th className="text-nowrap">Tanggal</Th>
                    <Th className="text-nowrap bg-primary-2">Pembayaran 3</Th>
                    <Th className="text-nowrap">Tanggal</Th>
                    <Th className="text-nowrap bg-primary-2">Pembayaran 4</Th>
                    <Th className="text-nowrap">Tanggal</Th>
                    <Th className="text-nowrap bg-primary-2">Pembayaran 5</Th>
                    <Th className="text-nowrap">Tanggal</Th>
                    <Th className="text-nowrap bg-primary-2">Pembayaran 6</Th>
                    <Th className="text-nowrap">Tanggal</Th>
                    <Th className="text-nowrap bg-primary-2">Pembayaran 7</Th>
                    <Th className="text-nowrap">Tanggal</Th>
                    <Th className="text-nowrap bg-primary-2">Pembayaran 8</Th>
                    <Th className="text-nowrap">Tanggal</Th>
                    <Th className="text-nowrap bg-primary-2">Pembayaran 9</Th>
                    <Th className="text-nowrap">Tanggal</Th>
                    <Th className="text-nowrap bg-primary-2">Pembayaran 10</Th>
                    <Th className="text-nowrap">Tanggal</Th>
                    <Th className="text-nowrap bg-primary-2">Pembayaran 11</Th>
                    <Th className="text-nowrap">Tanggal</Th>
                    <Th className="text-nowrap bg-primary-2">Pembayaran 12</Th>
                    <Th className="text-nowrap">Tanggal</Th>
                    {jenjang === "D2" && (
                      <>
                        <Th className="text-nowrap bg-primary-2">Pembayaran 13</Th>
                        <Th className="text-nowrap">Tanggal</Th>
                        <Th className="text-nowrap bg-primary-2">Pembayaran 14</Th>
                        <Th className="text-nowrap">Tanggal</Th>
                        <Th className="text-nowrap bg-primary-2">Pembayaran 15</Th>
                        <Th className="text-nowrap">Tanggal</Th>
                        <Th className="text-nowrap bg-primary-2">Pembayaran 16</Th>
                        <Th className="text-nowrap">Tanggal</Th>
                        <Th className="text-nowrap bg-primary-2">Pembayaran 17</Th>
                        <Th className="text-nowrap">Tanggal</Th>
                        <Th className="text-nowrap bg-primary-2">Pembayaran 18</Th>
                        <Th className="text-nowrap">Tanggal</Th>
                        <Th className="text-nowrap bg-primary-2">Pembayaran 19</Th>
                        <Th className="text-nowrap">Tanggal</Th>
                        <Th className="text-nowrap bg-primary-2">Pembayaran 20</Th>
                        <Th className="text-nowrap">Tanggal</Th>
                        <Th className="text-nowrap bg-primary-2">Pembayaran 21</Th>
                        <Th className="text-nowrap">Tanggal</Th>
                        <Th className="text-nowrap bg-primary-2">Pembayaran 22</Th>
                        <Th className="text-nowrap">Tanggal</Th>
                        <Th className="text-nowrap bg-primary-2">Pembayaran 23</Th>
                        <Th className="text-nowrap">Tanggal</Th>
                        <Th className="text-nowrap bg-primary-2">Pembayaran 24</Th>
                        <Th className="text-nowrap">Tanggal</Th>
                      </>
                    )}
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr
                        key={index}
                        className={`${
                          parseInt(i.status) === 9 &&
                          parseInt(i.nonaktif_by) === 1
                            ? "bg-invalid"
                            : ""
                        } ${
                          parseInt(i.status) === 9 &&
                          parseInt(i.nonaktif_by) === 2
                            ? "bg-invalid-2"
                            : ""
                        }`}
                      >
                        <Td
                          className="text-nowrap"
                          style={{ textTransform: "capitalize" }}
                        >
                          {i.cabang}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {i.waktu_belajar}
                        </Td>
                        <Td className="text-nowrap text-center">
                          {i.angkatan}
                        </Td>
                        <Td className="text-nowrap text-center">{getJenjang(i)}</Td>
                        <Td className="text-nowrap text-center">{i.no_hp}</Td>
                        <Td className="text-nowrap">
                          {i.biaya && getCurrency(i.biaya.angsuran, 0)}
                        </Td>
                        <Td className="text-nowrap">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            getCurrency(i.angsuran_1.angsuran, 0)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            getCurrency(i.angsuran_2.angsuran, 0)}
                        </Td>
                        {pagiSore === "sore" ? (
                          <Td className="text-nowrap">
                            {i.biaya && getCurrency(i.biaya.biaya_malam)}
                          </Td>
                        ) : (
                          ""
                        )}
                        <Td className="text-nowrap bg-none">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            getCurrency(i.angsuran_1.pembayaran_1, 0)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            getCurrency(i.angsuran_2.pembayaran_1, 0)}
                        </Td>
                        <Td className="text-nowrap">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            formatDate(i.angsuran_1.tanggal_1)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            formatDate(i.angsuran_2.tanggal_1)}
                        </Td>
                        <Td className="text-nowrap bg-none">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            getCurrency(i.angsuran_1.pembayaran_2, 0)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            getCurrency(i.angsuran_2.pembayaran_2, 0)}
                        </Td>
                        <Td className="text-nowrap">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            formatDate(i.angsuran_1.tanggal_2)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            formatDate(i.angsuran_2.tanggal_2)}
                        </Td>
                        <Td className="text-nowrap bg-none">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            getCurrency(i.angsuran_1.pembayaran_3, 0)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            getCurrency(i.angsuran_2.pembayaran_3, 0)}
                        </Td>
                        <Td className="text-nowrap">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            formatDate(i.angsuran_1.tanggal_3)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            formatDate(i.angsuran_2.tanggal_3)}
                        </Td>
                        <Td className="text-nowrap bg-none">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            getCurrency(i.angsuran_1.pembayaran_4, 0)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            getCurrency(i.angsuran_2.pembayaran_4, 0)}
                        </Td>
                        <Td className="text-nowrap">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            formatDate(i.angsuran_1.tanggal_4)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            formatDate(i.angsuran_2.tanggal_4)}
                        </Td>
                        <Td className="text-nowrap bg-none">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            getCurrency(i.angsuran_1.pembayaran_5, 0)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            getCurrency(i.angsuran_2.pembayaran_5, 0)}
                        </Td>
                        <Td className="text-nowrap">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            formatDate(i.angsuran_1.tanggal_5)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            formatDate(i.angsuran_2.tanggal_5)}
                        </Td>
                        <Td className="text-nowrap bg-none">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            getCurrency(i.angsuran_1.pembayaran_6, 0)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            getCurrency(i.angsuran_2.pembayaran_6, 0)}
                        </Td>
                        <Td className="text-nowrap">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            formatDate(i.angsuran_1.tanggal_6)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            formatDate(i.angsuran_2.tanggal_6)}
                        </Td>
                        <Td className="text-nowrap bg-none">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            getCurrency(i.angsuran_1.pembayaran_7, 0)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            getCurrency(i.angsuran_2.pembayaran_7, 0)}
                        </Td>
                        <Td className="text-nowrap">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            formatDate(i.angsuran_1.tanggal_7)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            formatDate(i.angsuran_2.tanggal_7)}
                        </Td>
                        <Td className="text-nowrap bg-none">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            getCurrency(i.angsuran_1.pembayaran_8, 0)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            getCurrency(i.angsuran_2.pembayaran_8, 0)}
                        </Td>
                        <Td className="text-nowrap">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            formatDate(i.angsuran_1.tanggal_8)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            formatDate(i.angsuran_2.tanggal_8)}
                        </Td>
                        <Td className="text-nowrap bg-none">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            getCurrency(i.angsuran_1.pembayaran_9, 0)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            getCurrency(i.angsuran_2.pembayaran_9, 0)}
                        </Td>
                        <Td className="text-nowrap">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            formatDate(i.angsuran_1.tanggal_9)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            formatDate(i.angsuran_2.tanggal_9)}
                        </Td>
                        <Td className="text-nowrap bg-none">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            getCurrency(i.angsuran_1.pembayaran_10, 0)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            getCurrency(i.angsuran_2.pembayaran_10, 0)}
                        </Td>
                        <Td className="text-nowrap">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            formatDate(i.angsuran_1.tanggal_10)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            formatDate(i.angsuran_2.tanggal_10)}
                        </Td>
                        <Td className="text-nowrap bg-none">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            getCurrency(i.angsuran_1.pembayaran_11, 0)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            getCurrency(i.angsuran_2.pembayaran_11, 0)}
                        </Td>
                        <Td className="text-nowrap">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            formatDate(i.angsuran_1.tanggal_11)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            formatDate(i.angsuran_2.tanggal_11)}
                        </Td>
                        <Td className="text-nowrap bg-none">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            getCurrency(i.angsuran_1.pembayaran_12, 0)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            getCurrency(i.angsuran_2.pembayaran_12, 0)}
                        </Td>
                        <Td className="text-nowrap">
                          {jenjang === "D1" &&
                            i.angsuran_1 &&
                            formatDate(i.angsuran_1.tanggal_12)}
                          {jenjang === "D2" &&
                            i.angsuran_2 &&
                            formatDate(i.angsuran_2.tanggal_12)}
                        </Td>
                        {jenjang === "D2" && (
                          <>
                            <Td className="text-nowrap bg-none">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                getCurrency(i.angsuran_1.pembayaran_13, 0)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                getCurrency(i.angsuran_2.pembayaran_13, 0)}
                            </Td>
                            <Td className="text-nowrap">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                formatDate(i.angsuran_1.tanggal_13)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                formatDate(i.angsuran_2.tanggal_13)}
                            </Td>
                            <Td className="text-nowrap bg-none">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                getCurrency(i.angsuran_1.pembayaran_14, 0)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                getCurrency(i.angsuran_2.pembayaran_14, 0)}
                            </Td>
                            <Td className="text-nowrap">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                formatDate(i.angsuran_1.tanggal_14)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                formatDate(i.angsuran_2.tanggal_14)}
                            </Td>
                            <Td className="text-nowrap bg-none">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                getCurrency(i.angsuran_1.pembayaran_15, 0)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                getCurrency(i.angsuran_2.pembayaran_15, 0)}
                            </Td>
                            <Td className="text-nowrap">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                formatDate(i.angsuran_1.tanggal_15)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                formatDate(i.angsuran_2.tanggal_15)}
                            </Td>
                            <Td className="text-nowrap bg-none">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                getCurrency(i.angsuran_1.pembayaran_16, 0)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                getCurrency(i.angsuran_2.pembayaran_16, 0)}
                            </Td>
                            <Td className="text-nowrap">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                formatDate(i.angsuran_1.tanggal_16)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                formatDate(i.angsuran_2.tanggal_16)}
                            </Td>
                            <Td className="text-nowrap bg-none">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                getCurrency(i.angsuran_1.pembayaran_17, 0)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                getCurrency(i.angsuran_2.pembayaran_17, 0)}
                            </Td>
                            <Td className="text-nowrap">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                formatDate(i.angsuran_1.tanggal_17)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                formatDate(i.angsuran_2.tanggal_17)}
                            </Td>
                            <Td className="text-nowrap bg-none">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                getCurrency(i.angsuran_1.pembayaran_18, 0)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                getCurrency(i.angsuran_2.pembayaran_18, 0)}
                            </Td>
                            <Td className="text-nowrap">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                formatDate(i.angsuran_1.tanggal_18)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                formatDate(i.angsuran_2.tanggal_18)}
                            </Td>
                            <Td className="text-nowrap bg-none">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                getCurrency(i.angsuran_1.pembayaran_19, 0)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                getCurrency(i.angsuran_2.pembayaran_19, 0)}
                            </Td>
                            <Td className="text-nowrap">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                formatDate(i.angsuran_1.tanggal_19)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                formatDate(i.angsuran_2.tanggal_19)}
                            </Td>
                            <Td className="text-nowrap bg-none">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                getCurrency(i.angsuran_1.pembayaran_20, 0)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                getCurrency(i.angsuran_2.pembayaran_20, 0)}
                            </Td>
                            <Td className="text-nowrap">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                formatDate(i.angsuran_1.tanggal_20)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                formatDate(i.angsuran_2.tanggal_20)}
                            </Td>
                            <Td className="text-nowrap bg-none">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                getCurrency(i.angsuran_1.pembayaran_21, 0)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                getCurrency(i.angsuran_2.pembayaran_21, 0)}
                            </Td>
                            <Td className="text-nowrap">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                formatDate(i.angsuran_1.tanggal_21)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                formatDate(i.angsuran_2.tanggal_21)}
                            </Td>
                            <Td className="text-nowrap bg-none">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                getCurrency(i.angsuran_1.pembayaran_22, 0)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                getCurrency(i.angsuran_2.pembayaran_22, 0)}
                            </Td>
                            <Td className="text-nowrap">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                formatDate(i.angsuran_1.tanggal_22)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                formatDate(i.angsuran_2.tanggal_22)}
                            </Td>
                            <Td className="text-nowrap bg-none">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                getCurrency(i.angsuran_1.pembayaran_23, 0)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                getCurrency(i.angsuran_2.pembayaran_23, 0)}
                            </Td>
                            <Td className="text-nowrap">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                formatDate(i.angsuran_1.tanggal_23)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                formatDate(i.angsuran_2.tanggal_23)}
                            </Td>
                            <Td className="text-nowrap bg-none">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                getCurrency(i.angsuran_1.pembayaran_24, 0)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                getCurrency(i.angsuran_2.pembayaran_24, 0)}
                            </Td>
                            <Td className="text-nowrap">
                              {jenjang === "D1" &&
                                i.angsuran_1 &&
                                formatDate(i.angsuran_1.tanggal_24)}
                              {jenjang === "D2" &&
                                i.angsuran_2 &&
                                formatDate(i.angsuran_2.tanggal_24)}
                            </Td>
                          </>
                        )}
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-2 ps-0">
              <TableNew copy={false}>
                <Thead>
                  <Tr className='text-center'>
                    <Th>Status</Th>
                    <Th>Opsi</Th>
                    <Th>
                      <i className="fa fa-bars" />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr 
                        key={index}
                        className={`
                        ${
                          parseInt(i.status) === 9 &&
                          parseInt(i.nonaktif_by) === 1
                            ? "bg-invalid"
                            : ""
                        } ${
                          parseInt(i.status) === 9 &&
                          parseInt(i.nonaktif_by) === 2
                            ? "bg-invalid-2"
                            : ""
                        }`}
                      >
                        <Td className="text-center text-nowrap">
                          {i.status === 9 && (
                            <span className="text-danger">Nonaktif</span>
                          )}
                          {i.status === null || i.status === 0 ? (
                            <span className="text-success">Aktif</span>
                          ) : null}
                        </Td>
                        <Td className="text-center text-nowrap">
                          {i.status !== 9 && (
                            <MhsAngsuran mhs={i} onFetchData={fetchData} />
                          )}
                        </Td>
                        <Td>
                          <MahasiswaNonaktifkan
                            btnClassName={parseInt(i.status) === 9 ? "btn-danger-2" : "btn-success-2"}
                            btnText={parseInt(i.status) === 9 ? "Off" : "On"}
                            mhs={i}
                            onFetchData={fetchData}
                          />
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
          </div>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default MhsKeuangan;
