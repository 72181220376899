import React, { useRef, useState } from 'react'
import { LayoutAddBtn } from '../../../components/layouts/TopContent'
import LayoutModal, { NewSubmitButton } from '../../../components/layouts/Modal';
import FormInput from '../../../components/forms/Input';
import FormSelect from '../../../components/forms/Select';
import { patchSurveyMhs, postSurveyMhs } from '../../../api';
import SmallButton from '../../../components/forms/SmallButton';
import { saAlert } from '../../../helpers';

function SMEdit({fetchdata, dataCabang, surveyMhs}) {
    
    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const formRef = useRef(null);

    const handleOpen = () => {
        setForm(surveyMhs);
        setModal(true);
    }

    const handleClose = () => {
        if (formRef.current) {
            formRef.current.reset();
        }
        setForm({});
        setErrors({});
        setModal(false);
    }

    const handleChange = (e) => {
        setForm((prevState) => {
            return { ...prevState , [e.target.name] : e.target.value };
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await patchSurveyMhs(form.id, form);
            if (res.data.status === 'success') {
                fetchdata();
                handleClose();
                saAlert(res.data.status, res.data.msg);
            }
        } catch (error) {
            setErrors(error.response?.data?.errors);
        }
    }

  return (
    <>
        <SmallButton onClick={handleOpen} margin="me-1" type="edit"/>

        <LayoutModal
            size="md"
            title='Edit Survey Mahasiswa'
            modal={modal}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} ref={formRef}>
                <FormSelect
                    label="Cabang"
                    name="cabang_id"
                    value={form.cabang_id ? form.cabang_id : ''}
                    onChange={handleChange}
                    error={errors.cabang_id}
                >
                    <option value="">Semua Cabang</option>
                    {dataCabang.length > 0 &&
                        dataCabang.filter((dc) => dc.nama !== "Pusat").map((dc, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <option value={dc.id}>{dc.nama}</option>
                                </React.Fragment>
                            );
                        })}
                </FormSelect>
                <FormInput
                    label="Judul"
                    name="name"
                    value={form.name ? form.name : ''}
                    onChange={handleChange}
                    error={errors.name}
                />
                <NewSubmitButton className="mt-2" widthFull onClose={handleClose}/>
            </form>
        </LayoutModal>
    </>
  )
}

export default SMEdit