import React, { useState } from "react";
import { deleteAbsensi, postAbsensi, showPengajaran } from "../../api";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import PageLoading from "../../components/pages/Loading";
import { saAlert, saConfirm } from "../../helpers";
import SmallButton from "../../components/forms/SmallButton";

const PengajaranAbsensi = (props) => {
  const [modal, setModal] = useState(false);
  const { pengajaran, onFetchData } = props;

  const [items, setItems] = useState({});

  const fetchData = async () => {
    try {
      const res = await showPengajaran(pengajaran.id);
      setItems(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  // Handle Absen
  const handleUpdate = (mhs, pengajaran_id, pertemuan, status) => {
    postAbsensi({ mhs_id: mhs.id, pengajaran_id, pertemuan, status })
      .then((res) => {
        if (res.data === "success") {
          fetchData();
          onFetchData();
          saAlert("success", "Berhasil melakukan absen !");
        }
      })
      .catch((err) => console.log(err));
  };

  // Delete Absen
  const handleCancleDelete = (a) => {
    saConfirm("warning", "Yakin hapus absensi ?").then((res) => {
      if (res.isConfirmed) {
        deleteAbsensi(a.id).then((res) => {
          if (res.data === "success") {
            fetchData();
            onFetchData();
            saAlert("success", "Berhasil hapus absensi !");
          }
        });
      }
    });
  };

  const handleOpen = () => {
    fetchData();
    setModal(true);
  };

  const handleClose = () => {
    setItems({});
    setModal(false);
  };

  return (
    <>
      <SmallButton
        margin='me-1'
        type='custom'
        icon='users'
        buttonColor='primary-2'
        onClick={handleOpen}
      />
      <LayoutModal
        title={`Absensi Kelas ${pengajaran.kelas && pengajaran.kelas.kode}`}
        modal={modal}
        onClose={handleClose}
      >
        {items && items.id ? (
          <>
            <LayoutTable>
              <LayoutThead>
                <th>No</th>
                <th>NIM</th>
                <th>Nama</th>
                <th>Status</th>
              </LayoutThead>
              <tbody>
                {items.mhs &&
                  items.mhs.length > 0 &&
                  items.mhs.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td className="align-middle text-center">
                          {index + 1}
                        </td>
                        <td className="align-middle text-center">{i.nim}</td>
                        <td className="align-middle">{i.nama}</td>
                        <td className="align-middle text-center">
                          {items.absensi && items.absensi.length > 0 ? (
                            items.absensi.filter((a) => {
                              if (
                                String(a.pertemuan) === String(items.pertemuan)
                              ) {
                                return parseInt(a.mhs_id) === parseInt(i.id);
                              }
                              return false;
                            }).length > 0 ? (
                              items.absensi.map((a, index) => {
                                if (
                                  String(a.pertemuan) ===
                                  String(items.pertemuan)
                                ) {
                                  if (parseInt(a.mhs_id) === parseInt(i.id)) {
                                    return (
                                      <React.Fragment key={index}>
                                        {parseInt(a.status) === 1 && (
                                          <span className="text-success">
                                            Hadir
                                          </span>
                                        )}
                                        {parseInt(a.status) === 2 && (
                                          <span className="text-info">
                                            Ijin
                                          </span>
                                        )}
                                        {parseInt(a.status) === 3 && (
                                          <span className="text-warning">
                                            Sakit
                                          </span>
                                        )}
                                        {parseInt(a.status) === 4 && (
                                          <span className="text-danger">
                                            Alpa
                                          </span>
                                        )}
                                        <span
                                          className="fa fa-trash-alt px-3 text-danger"
                                          title="Hapus absensi."
                                          type="button"
                                          onClick={() => handleCancleDelete(a)}
                                        ></span>
                                      </React.Fragment>
                                    );
                                  }
                                }
                                return false;
                              })
                            ) : (
                              <Absen
                                i={i}
                                items={items}
                                handleUpdate={handleUpdate}
                              />
                            )
                          ) : (
                            <Absen
                              i={i}
                              items={items}
                              handleUpdate={handleUpdate}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </LayoutTable>
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-sm btn-secondary"
            >
              Tutup
            </button>
          </>
        ) : (
          <PageLoading />
        )}
      </LayoutModal>
    </>
  );
};

export const Absen = (props) => {
  const { i, items, handleUpdate } = props;
  return (
    <>
      <button
        type="button"
        className="mx-1 border-0 btn btn-sm border-0 py-0 btn-success-3 fw-semibold text-sm"
        onClick={() => handleUpdate(i, items.id, items.pertemuan, 1)}
      >
        Hadir
      </button>
      <button
        type="button"
        className="mx-1 border-0 btn btn-sm border-0 py-0 btn-warning-2 fw-semibold text-sm"
        onClick={() => handleUpdate(i, items.id, items.pertemuan, 2)}
      >
        Izin
      </button>
      <button
        type="button"
        className="mx-1 border-0 btn btn-sm border-0 py-0 btn-warning-2 fw-semibold text-sm"
        onClick={() => handleUpdate(i, items.id, items.pertemuan, 3)}
      >
        Sakit
      </button>
      <button
        type="button"
        className="mx-1 border-0 btn btn-sm border-0 py-0 btn-danger-2 fw-semibold text-sm"
        onClick={() => handleUpdate(i, items.id, items.pertemuan, 4)}
      >
        Alpa
      </button>
    </>
  );
};

export default PengajaranAbsensi;
