import React from "react";
import { deletePengajaran } from "../../api";
import { saAlert, saConfirm } from "../../helpers";
import SmallButton from "../../components/forms/SmallButton";

const PengajaranDelete = (props) => {
  const { pengajaran, onFetchData } = props;

  const handleDelete = () => {
    saConfirm(
      "warning",
      `Apakah anda yakin menghapus pengajaran ${
        pengajaran.kelas && pengajaran.kelas.kode
      } ?`,
      "Pastikan anda tidak melakukan kesalahan dalam melakukan penghapusan data !"
    ).then((res) => {
      if (res.isConfirmed) {
        deletePengajaran(pengajaran.id)
          .then((res) => {
            if (res.data === "success") {
              onFetchData();
              saAlert("success", "Berhasil menghapus pengajaran !");
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  return (
    <SmallButton type='delete' onClick={handleDelete}/>
  );
};

export default PengajaranDelete;
