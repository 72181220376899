import React from "react";
import { saAlert, saConfirm } from "../../helpers";
import { deletePegawai } from "../../api";

const PegawaiHandleNonaktif = ({ pegawai, onFetchData }) => {
  const handleNonaktif = () => {
    saConfirm("warning", "Yakin ubah pegawai menjadi tidak aktif ?").then(
      async (res) => {
        if (res.isConfirmed) {
          const res = await deletePegawai(pegawai.id);
          if (res.data.status === "success") {
            onFetchData();
            saAlert(res.data.status, res.data.msg);
          }
        }
      }
    );
  };
  return (
    <button
      className="btn btn-sm btn-danger-2 text-sm py-0 px-1"
      type="button"
      onClick={handleNonaktif}
    >
      <i className="fa fa-fw fa-sm fa-trash-alt"></i>
    </button>
  );
};

export default PegawaiHandleNonaktif;
