import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchPendaftar } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterTahun,
  FilterCabang,
  FilterPagination,
  FilterSearch,
  FilterTahun,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import SectionPagination from "../../components/sections/Pagination";
import { getCurrency, formatDate } from "../../helpers";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import PendaftarBiaya from "./Biaya";
import PendaftarShow from "./Show";
import PendaftarUpdateStatus from "./UpdateStatus";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const PendaftarKeuangan = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => setTitle("Data Mahasiswa Keuangan"), [setTitle]);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [tahunAjaran, setTahunAjaran] = useState(defaultFilterTahun);
  const [search, setSearch] = useState("");
  const [cabang, setCabang] = useState("");
  const [pagination, setPagination] = useState(10);

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchPendaftar(
        page,
        `tahun_ajaran=${tahunAjaran}&search=${search}&cabang=${cabang}&pagination=${pagination}`
      );
      if (res.data) {
        setItems(res.data.pendaftar);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [page, tahunAjaran, search, cabang, pagination]);

  useEffect(() => fetchData(), [fetchData]);

  if (isLoaded) {
    return (
      <>
        <div className="row mb-3">
          {user.cabang === "pusat" && (
            <div className="col-md">
              <FilterCabang
                value={cabang}
                onChange={(res) => {
                  setPage(1);
                  setCabang(res);
                }}
              />
            </div>
          )}
          <div className="col-md">
            <FilterTahun
              onChange={(res) => {
                setPage(1);
                setTahunAjaran(res);
              }}
            />
          </div>
          <div className="col-md">
            <FilterPagination
              value={pagination}
              onChange={(res) => {
                setPage(1);
                setPagination(res);
              }}
            />
          </div>
          <div className="col-md">
            <FilterSearch
              onChange={(res) => {
                setPage(1);
                setSearch(res);
              }}
            />
          </div>
        </div>
        {items.data && items.data.length > 0 && (
          <div className="row">
            <div className="col-3 pe-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center text-nowrap'>
                    <Th>No</Th>
                    <Th>Mhs ID</Th>
                    <Th>Nama</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-center">{items.from + index}</Td>
                        <Td className="text-center">
                          {i.biaya ? i.biaya.mhs_id : "-"}
                        </Td>
                        <Td className="text-nowrap">{i.nama}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-6 px-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center text-nowrap'>
                    <Th className="text-nowrap">Cabang</Th>
                    <Th className="text-nowrap">Tahun Daftar</Th>
                    <Th className="text-nowrap">Biaya Pendidikan</Th>
                    <Th className="text-nowrap">Subsidi</Th>
                    <Th className="text-nowrap">Setelah Subsidi</Th>
                    <Th className="text-nowrap bg-primary-2">Daftar</Th>
                    <Th className="text-nowrap">Tanggal Daftar</Th>
                    <Th className="text-nowrap">Total DU</Th>
                    <Th className="text-nowrap bg-primary-2">DU 1</Th>
                    <Th className="text-nowrap">Tanggal DU 1</Th>
                    <Th className="text-nowrap bg-primary-2">DU 2</Th>
                    <Th className="text-nowrap">Tanggal DU 2</Th>
                    <Th className="text-nowrap bg-primary-2">DU 3</Th>
                    <Th className="text-nowrap">Tanggal DU 3</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-center text-nowrap">{i.cabang}</Td>
                        <Td className="text-center text-nowrap">
                          {i.tahun_ajaran}
                        </Td>
                        <Td className="text-center text-nowrap">
                          {i.biaya && getCurrency(i.biaya.biaya_pendidikan, 0)}
                        </Td>
                        <Td className="text-center text-nowrap">
                          {i.biaya && getCurrency(i.biaya.subsidi, 0)}
                        </Td>
                        <Td className="text-center text-nowrap">
                          {i.biaya &&
                            getCurrency(
                              i.biaya.biaya_pendidikan - i.biaya.subsidi,
                              0
                            )}
                        </Td>
                        <Td className="text-center text-nowrap bg-silver">
                          {i.biaya && getCurrency(i.biaya.daftar, 0)}
                        </Td>
                        <Td className="text-center text-nowrap">
                          {i.biaya && formatDate(i.biaya.tanggal_daftar)}
                        </Td>
                        <Td className="text-center text-nowrap">
                          {i.biaya &&
                            getCurrency(
                              (Number.isInteger(parseInt(i.biaya.du_1))
                                ? parseInt(i.biaya.du_1)
                                : 0) +
                                (Number.isInteger(parseInt(i.biaya.du_2))
                                  ? parseInt(i.biaya.du_2)
                                  : 0) +
                                (Number.isInteger(parseInt(i.biaya.du_3))
                                  ? parseInt(i.biaya.du_3)
                                  : 0),
                              0
                            )}
                        </Td>
                        <Td className="text-center text-nowrap bg-silver">
                          {i.biaya && getCurrency(i.biaya.du_1, 0)}
                        </Td>
                        <Td className="text-center text-nowrap">
                          {i.biaya && formatDate(i.biaya.tanggal_du_1)}
                        </Td>
                        <Td className="text-center text-nowrap bg-silver">
                          {i.biaya && getCurrency(i.biaya.du_2, 0)}
                        </Td>
                        <Td className="text-center text-nowrap">
                          {i.biaya && formatDate(i.biaya.tanggal_du_2)}
                        </Td>
                        <Td className="text-center text-nowrap bg-silver">
                          {i.biaya && getCurrency(i.biaya.du_3, 0)}
                        </Td>
                        <Td className="text-center text-nowrap">
                          {i.biaya && formatDate(i.biaya.tanggal_du_3)}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-3 ps-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center text-nowrap'>
                    <Th>Status</Th>
                    <Th>Update</Th>
                    <Th>
                      <i className="fa fa-bars" />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-center text-nowrap">
                          {!i.status && (
                            <span className="text-secondary">Belum Daftar</span>
                          )}
                          {parseInt(i.status) === 1 && (
                            <span className="text-warning">Sudah Daftar</span>
                          )}
                          {parseInt(i.status) === 2 && (
                            <span className="text-success">Sudah DU</span>
                          )}
                          {parseInt(i.status) === 3 && (
                            <span className="text-info">Sudah Cair</span>
                          )}
                          {parseInt(i.status) === 4 && (
                            <span className="text-primary">Mhs Aktif</span>
                          )}
                          {parseInt(i.status) === 9 && (
                            <span className="text-danger">Batal Daftar</span>
                          )}
                        </Td>
                        <Td className="text-center text-nowrap">
                          <PendaftarUpdateStatus
                            pendaftar={i}
                            onFetchData={fetchData}
                          />
                        </Td>
                        <Td className="text-nowrap text-center">
                          <PendaftarBiaya
                            onFetchData={fetchData}
                            pendaftar={i}
                          />
                          <PendaftarShow pendaftar={i} />
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
          </div>
        )}
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default PendaftarKeuangan;
