import React, { useEffect, useState } from "react";
import { namaBulan } from "../../helpers";

const Time = (props) => {
  const { onChangeMusic } = props;
  const d = new Date();
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const interval = setTimeout(() => {
      setTime(Date.now());
      if (
        `${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}` === "14:51:20"
      ) {
        onChangeMusic(false);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [time, onChangeMusic]);

  return (
    <>
    <p className="mb-0 mx-3"><i className="fa fw fa-clock me-1"></i> {d.getHours()}:{d.getMinutes()}:{d.getSeconds()}</p>
    <p className="mb-0"><i className="fa fw fa-calendar me-1"></i> {d.getDate()} {namaBulan[d.getMonth()]} {d.getFullYear()}</p>
    </>
  );
};

export default Time;
