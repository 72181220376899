import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import FormInput from "../../../components/forms/Input";
import FormTextarea from "../../../components/forms/Textarea";
import PageLoading from "../../../components/pages/Loading";
import { getCurrency, saAlert, saConfirm } from "../../../helpers";
import { userState } from "../../../storage/auth";
import { patchPurchaseRequest, showPurchaseRequest } from "../apis";
import SectionList from "./partials/SectionList";

const Show = ({ mainUrl }) => {
  const { id } = useParams();
  const user = useRecoilValue(userState);
  const history = useHistory();

  const [form, setForm] = useState({});
  const [dataList, setDataList] = useState([]);
  const [jumlah, setJumlah] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await showPurchaseRequest(id);
        if (res.data && res.data.purchase_request) {
          setForm(res.data.purchase_request);
          setJumlah(res.data.purchase_request.jumlah);
          if (res.data.purchase_request.purchase_request_list) {
            setDataList(res.data.purchase_request.purchase_request_list);
          }
        }
      } catch (err) {
        console.error(err.response);
      }
    };

    fetchData();
  }, [id]);

  const handleUpdateKacab = (approval_kacab) => {
    saConfirm(
      "warning",
      `Yakin ubah approval kacab menjadi ${
        approval_kacab === 9 ? "Rejected" : "Approved"
      } ?`
    ).then(async (res) => {
      if (res.isConfirmed) {
        const res = await patchPurchaseRequest(id, {
          approval_kacab,
          approval: true,
        });
        if (res.data && res.data.msg) {
          history.push(mainUrl + "/approval");
          saAlert("success", res.data.msg);
        }
      }
    });
  };

  const handleUpdateDir = (approval_dir) => {
    saConfirm(
      "warning",
      `Yakin ubah approval dir menjadi ${
        approval_dir === 9 ? "Rejected" : "Approved"
      } ?`
    ).then(async (res) => {
      if (res.isConfirmed) {
        const res = await patchPurchaseRequest(id, {
          approval_dir,
          approval: true,
        });
        if (res.data && res.data.msg) {
          history.push(mainUrl + "/pusat");
          saAlert("success", res.data.msg);
        }
      }
    });
  };

  const handleUpdateStatus = (status) => {
    saConfirm(
      "warning",
      `Yakin ubah status menjadi ${status === 9 ? "Rejected" : "Approved"} ?`
    ).then(async (res) => {
      if (res.isConfirmed) {
        const res = await patchPurchaseRequest(id, { status, approval: true });
        if (res.data && res.data.msg) {
          history.push(mainUrl + "/approval");
          saAlert("success", res.data.msg);
        }
      }
    });
  };

  if (form.id && user.cabang === "pusat") {
    return (
      <div>
        <h4 className="fw-semibold border-bottom border-dark pb-2">Detail Data</h4>
        <div className="row">
          <div className="col-3 d-flex justify-content-between text-black">
            <span>Status Atasan</span>
            <span>:</span>
          </div>
          <div className="col-9">
            {parseInt(form.status) === 1 && (
              <span className="text-success"><i className="fa fa-fw fa-circle-check text-success"/> Disetujui</span>
            )}
            {parseInt(form.status) === 9 && (
              <span className="text-danger"><i className="fa fa-fw fa-circle-xmark text-danger"/> Ditolak</span>
            )}
            {!form.status && "-"}
          </div>
        </div>
        <div className="row">
          <div className="col-3 d-flex justify-content-between">
            <span>Status Kacab</span>
            <span>:</span>
          </div>
          <div className="col-9">
            {parseInt(form.approval_kacab) === 1 && (
              <span className="text-success"><i className="fa fa-fw fa-circle-check text-success"/> Disetujui</span>
            )}
            {parseInt(form.approval_kacab) === 9 && (
              <span className="text-danger"><i className="fa fa-fw fa-circle-xmark text-danger"/> Ditolak</span>
            )}
            {!form.approval_kacab && "-"}
          </div>
        </div>
        <div className="row">
          <div className="col-3 d-flex justify-content-between">
            <span>Status Dir</span>
            <span>:</span>
          </div>
          <div className="col-9">
            {parseInt(form.approval_dir) === 1 && (
              <span className="text-success"><i className="fa fa-fw fa-circle-check text-success"/> Disetujui</span>
            )}
            {parseInt(form.approval_dir) === 9 && (
              <span className="text-danger"><i className="fa fa-fw fa-circle-xmark text-danger"/> Ditolak</span>
            )}
            {!form.approval_dir && "-"}
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-4">
            <FormInput
              label="Pengaju"
              value={form.pegawai && form.pegawai.nama}
              readOnly={true}
            />
          </div>
          <div className="col-4">
            <FormTextarea
              label="Keperluan"
              value={form.untuk}
              readOnly={true}
            />
          </div>
          <div className="col-4">
            <FormInput
              type="date"
              label="Tanggal Dibutuhkan"
              value={form.tanggal_dibutuhkan}
              readOnly={true}
            />
            <FormInput
              type="date"
              label="Tanggal Request"
              value={form.tanggal_request}
              readOnly={true}
            />
          </div>
        </div>
        <SectionList
          dataList={dataList}
          setDataList={(res) => setDataList(res)}
          setJumlah={setJumlah}
          approval={true}
        />
        <h5 className="bg-primary border-bottom-warning text-white rounded-3 py-2 px-3">Jumlah Akhir = Rp {getCurrency(jumlah)}</h5>
        <>
          {user.group === "Kacab" ? (
            <div>
              {!form.approval_kacab && (
                <>
                  <button
                    onClick={() => handleUpdateKacab(1)}
                    className="btn btn-success-2 mb-3"
                  >
                    Approve Pengajuan PR by Kacab
                  </button>
                  <button
                    onClick={() => handleUpdateKacab(9)}
                    className="btn btn-danger-2 mb-3"
                  >
                    Reject Pengajuan PR by Kacab
                  </button>
                </>
              )}
            </div>
          ) : (
            <>
              {form.pegawai.atasan_1 === user.nip ||
              form.pegawai.atasan_2 === user.nip ? (
                <div>
                  {!form.status && (
                    <>
                      <button
                        onClick={() => handleUpdateStatus(1)}
                        className="btn btn-success-2 mb-3"
                      >
                        Approve Pengajuan PR
                      </button>
                      <button
                        onClick={() => handleUpdateStatus(9)}
                        className="btn btn-danger-2 mb-3"
                      >
                        Reject Pengajuan PR
                      </button>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
            </>
          )}
        </>
        {user.cabang === "pusat" && (
          <>
            {user.jabatan === "Dir. Keu & Sumber Daya" ||
            user.jabatan === "IT Programmer" ? (
              <div>
                {!form.approval_dir && (
                  <>
                    <button
                      onClick={() => handleUpdateDir(1)}
                      className="btn btn-success-2 mb-3"
                    >
                      Approve Pengajuan PR by Pak Sudama
                    </button>
                    <button
                      onClick={() => handleUpdateDir(9)}
                      className="btn btn-danger-2 mb-3"
                    >
                      Reject Pengajuan PR by Pak Sudama
                    </button>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    );
  } else {
    return <PageLoading />;
  }
};

export default Show;
