import React, { useEffect, useState, useCallback } from "react";
import { useRecoilState } from "recoil";
import { fetchPengajaran } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import SectionPagination from "../../components/sections/Pagination";
import { getHari, getStatusPengajaran } from "../../helpers";
import { titleState } from "../../storage/pages";
import PengajaranList from "./List";
import PageLoading from "../../components/pages/Loading";
import { FilterSearch } from "../../components/layouts/TopContent";

const PengajaranBapKurang = () => {
  const [title, setTitle] = useRecoilState(titleState);

  useEffect(() => setTitle("Data BAP Belum lengkap"), [setTitle]);

  const [pengajaran, setPengajaran] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchPengajaran(
        page,
        `return_arr=1&notification_akademik=1&search=${search}`
      );
      setIsLoaded(true);
      if (res.data) {
        setPengajaran(res.data.pengajaran);
      }
    } catch (err) {
      console.error(err.response.data);
    }
  }, [page, search]);

  useEffect(() => fetchData(), [fetchData]);

  if (isLoaded) {
    return (
      <div>
        <div className="row justify-content-end mb-3">
          <div className="col-lg-5">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
            <p className="mb-0 text-gray-700 mt-2"><i className="fa fa-fw fa-circle-exclamation text-info"/> Search ini berlaku untuk pencarian nama dosen.</p>
          </div>
        </div>

        <div className="row">
          <div className="col-10 pe-0">
            <LayoutTable>
              <LayoutThead className="text-nowrap">
                <th className="bg-primary text-white py-1 border-bottom border-silver fw-normal">no</th>
                <th className="bg-primary text-white py-1 border-bottom border-silver fw-normal">kode kelas</th>
                <th className="bg-primary text-white py-1 border-bottom border-silver fw-normal">tahun ajaran</th>
                <th className="bg-primary text-white py-1 border-bottom border-silver fw-normal">semester</th>
                <th className="bg-primary text-white py-1 border-bottom border-silver fw-normal">tanggal</th>
                <th className="bg-primary text-white py-1 border-bottom border-silver fw-normal">hari</th>
                <th className="bg-primary text-white py-1 border-bottom border-silver fw-normal">dari</th>
                <th className="bg-primary text-white py-1 border-bottom border-silver fw-normal">sampai</th>
                <th className="bg-primary text-white py-1 border-bottom border-silver fw-normal">pertemuan</th>
                <th className="bg-primary text-white py-1 border-bottom border-silver fw-normal">dosen</th>
                <th className="bg-primary text-white py-1 border-bottom border-silver fw-normal">ruangan</th>
                <th className="bg-primary text-white py-1 border-bottom border-silver fw-normal">status</th>
              </LayoutThead>
              <tbody>
                {pengajaran.data &&
                  pengajaran.data.length > 0 &&
                  pengajaran.data.map((p, index) => {
                    return (
                      <tr key={index}>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {pengajaran.from + index}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {p.kelas && p.kelas.kode}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {p.tahun_ajaran}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">{p.semester}</td>
                        <td className="py-1 border-silver text-nowrap text-center">{p.tanggal}</td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {getHari(p.hari)}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">{p.dari}</td>
                        <td className="py-1 border-silver text-nowrap text-center">{p.sampai}</td>
                        <td className="py-1 border-silver text-nowrap text-center">{p.pertemuan}</td>
                        <td className="py-1 border-silver text-nowrap">{p.dosen && p.dosen.nama}</td>
                        <td className="py-1 border-silver text-nowrap">
                          {p.ruangan && p.ruangan.nama}
                        </td>
                        {/* <td className="py-1 border-silver text-nowrap text-center">{p.status}</td> */}
                        <td className="py-1 border-silver text-nowrap text-center">{getStatusPengajaran(p, true)}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </LayoutTable>
          </div>
          <div className="col-2 ps-0">
            <LayoutTable copy={false}>
              <LayoutThead>
                <th className="bg-primary text-white py-1 border-bottom border-silver text-nowrap">
                  <i className="fa fa-fw fa-sm fa-cog" />
                </th>
              </LayoutThead>
              <tbody>
                {pengajaran.data &&
                  pengajaran.data.length > 0 &&
                  pengajaran.data.map((p, index) => {
                    return (
                      <tr key={index}>
                        <td className="border-silver text-nowrap text-center py-1">
                          <PengajaranList
                            pengajaran={p}
                            kelas={p.kelas && p.kelas}
                            onFetchData={fetchData}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </LayoutTable>
          </div>
        </div>
        {pengajaran.links && (
          <SectionPagination
            from={pengajaran.from}
            to={pengajaran.to}
            total={pengajaran.total}
            links={pengajaran.links}
            onChangePage={(res) => setPage(res)}
          />
        )}
      </div>
    );
  } else {
    return <PageLoading/>
  }
};

export default PengajaranBapKurang;
