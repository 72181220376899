import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchKpi, fetchMorningReport } from "../../api";
import { titleState } from "../../storage/pages";
import MorningReportCreate from "./Create";
import { FilterSearch } from "../../components/layouts/TopContent";
import { LayoutTable, LayoutThead, TableNoData } from "../../components/layouts/Table";
import MorningReportEdit from "./Edit";
import SectionPagination from "../../components/sections/Pagination";
import MorningReportDelete from "./Delete";
import { formatDate, getBulan } from "../../helpers";
import { userState } from "../../storage/auth";
import LayoutModal from "../../components/layouts/Modal";
import PageLoading from "../../components/pages/Loading";

const MorningReport = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const [isLoaded, setIsLoaded] = useState(false);

  const [morningReport, setMorningReport] = useState({});

  const fetchData = useCallback(
    async ({ isReset = true } = true) => {
      isReset && setIsLoaded(false);
      try {
        const res = await fetchMorningReport(
          page,
          `pegawai_id=${user.id}&search=${search}`
        );
        setTitle(res.data.title);
        setMorningReport(res.data.morning_report);
        setIsLoaded(true);
      } catch (err) {
        //
      }
    },
    [user.id, page, search, setTitle]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [kpi, setKpi] = useState({});

  useEffect(() => {
    const fetchDataKpi = async () => {
      try {
        const res = await fetchKpi("all", `pegawai_id=${user.id}`);
        setKpi(res.data.kpi);
      } catch (err) {
        //
      }
    };
    fetchDataKpi();
  }, [user]);

  const [modalListReport, setModalListReport] = useState(false);

  const showListReport = () => {
    setModalListReport(true);
  }
  const hideListReport = () => {
    setModalListReport(false);
  }

  return (
    <div>
      <MorningReportCreate onFetchData={fetchData} />

      <div className="row justify-content-end mb-2">
        <div className="col-md-4">
          <FilterSearch onChange={(res) => setSearch(res)} />
        </div>
      </div>

        {morningReport.data ?
          morningReport.data.length > 0 ?
          morningReport.data.map((mr, index) => {
            return (
              <LayoutTable key={index}>
                <LayoutThead>
                  <th colSpan={4} className="px-2 py-1 bg-primary-4 text-warning border-bottom border-silver fw-semibold text-start"
                  >
                    <span className="me-2">Daily Report : <span className="text-white">{mr.tanggal.split("-")[2]} {getBulan(mr.tanggal.split("-")[1])} {mr.tanggal.split("-")[0]}</span></span> 
                      <MorningReportEdit
                        kpi={kpi}
                        morningReport={mr}
                        onFetchData={fetchData}
                      />
                      <MorningReportDelete
                        morningReport={mr}
                        onFetchData={fetchData}
                      />
                  </th>
                </LayoutThead>
                <LayoutThead>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Nama KPI</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Judul</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Keterangan</th>
                </LayoutThead>
                <tbody>
                  {mr.morning_report_list.length > 0 ?
                    mr.morning_report_list.map((mrl, index) => {
                      return (
                          <tr key={index}>
                            <td className="py-1 border-silver text-center">{index + 1}</td>
                            <td className="py-1 border-silver text-nowrap">{mrl.kpi && mrl.kpi.nama}</td>
                            <td className="py-1 border-silver text-nowrap">{mrl.judul}</td>
                            <td className="py-1 border-silver text-nowrap">{mrl.keterangan}</td>
                          </tr>
                      )
                    }) :
                    <tr>
                      <td colSpan={4}><i className="fa fa-fw fa-circle-exclamation text-warning"/> Tidak ada list report.</td>
                    </tr>
                  }
                </tbody>
              </LayoutTable> 
            )
          }) : 
            <LayoutTable>
              <LayoutThead>
                <th colSpan={4} className="px-2 py-1 bg-primary-4 text-warning border-bottom border-silver fw-semibold text-start"
                >
                  <span className="me-2">Daily Report</span> 
                </th>
              </LayoutThead>
              <LayoutThead>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Nama KPI</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Judul</th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Keterangan</th>
              </LayoutThead>
              <tbody>
                <tr>
                  <td colSpan={4} className="py-5 border-silver text-center">Tidak ada data Daily Report.</td>
                </tr>
              </tbody>
            </LayoutTable> 

        : <PageLoading/>
        }

      <SectionPagination
        links={morningReport.links}
        onChangePage={(res) => setPage(res)}
      />
    </div>
  );
};

export default MorningReport;
