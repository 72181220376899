import React, { useRef, useState } from 'react'
import LayoutModal, { NewSubmitButton } from '../../components/layouts/Modal'
import FormInput from '../../components/forms/Input';
import FormTextarea from '../../components/forms/Textarea';
import FormSelect from '../../components/forms/Select';
import { getOrigin, saAlert } from '../../helpers';
import SmallButton from '../../components/forms/SmallButton';
import { patchBeritaTerkini } from '../../api';

function BTEdit(props) {

    const {fetchdata, bt} = props;
    const tagForm = useRef();
    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});

    const handleOpen = () => {
        setModal(true);
        setForm({
          'id': bt.id,
          'title': bt.title,
          'caption': bt.caption,
          'from': bt.from,
          'to': bt.to,
          'priority': bt.priority,
        });
    };

    const handleClose = () => {
        tagForm.current && tagForm.current.reset();
        setForm({});
        setErrors({});
        setModal(false);
    };

    const handleChange = (e) => {
      setForm((prevState) => {
        return { ...prevState, [e.target.name]: e.target.value };
      })
    };

    const [image, setImage] = useState(null);

    const handleChangeImage = (e) => {
      setForm((prevState) => {
        return {
          ...prevState,[e.target.name]: e.target.files[0],
        };
      })

      const file = e.target.files[0];
      const render = new FileReader();

      render.onload = () => {
        setImage(render.result);
      };

      if (file) {
        render.readAsDataURL(file);
      }
    };

    const handleSubmit = async (e) => {
      e.preventDefault();

      const formData = new FormData();
      formData.append("_method", "PATCH");
      form.title && formData.append("title", form.title);
      form.caption && formData.append("caption", form.caption);
      form.from && formData.append("from", form.from);
      form.to && formData.append("to", form.to);
      form.priority && formData.append("priority", form.priority);
      form.picture && formData.append("picture", form.picture);

      try {
        const res = await patchBeritaTerkini(form.id, formData);

        if (res.data.status === "success") {
          fetchdata();
          saAlert("success", "Berhasil mengubah postingan.");
          handleClose();
        }
      } catch (error) {
        if (error.response && error.response.data) {
          setErrors(error.response.data.errors);
        }
      }
    };

  return (
    <>
        <SmallButton margin='me-1' type='edit' onClick={handleOpen}/>

        <LayoutModal size="md" title="Edit Postingan" modal={modal} onClose={handleClose}>
            <form onSubmit={handleSubmit} ref={tagForm}>
              <FormInput
                label="Judul"
                type="text"
                name="title"
                value={form.title}
                error={errors.title}
                onChange={handleChange}
              />
              <FormTextarea
                label="Deskripsi"
                name="caption"
                value={form.caption}
                error={errors.caption}
                row={10}
                onChange={handleChange}
              />
              <div className='row'>
                <div className='col-lg-6'>
                  <FormInput
                    label="Dari"
                    type="date"
                    name="from"
                    value={form.from}
                    error={errors.from}
                    onChange={handleChange}
                  />
                </div>
                <div className='col-lg-6'>
                  <FormInput
                    label="Sampai"
                    type="date"
                    name="to"
                    value={form.to}
                    error={errors.to}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <FormSelect
                label="Grade"
                name="priority"
                value={form.priority}
                error={errors.priority}
                onChange={handleChange}
              >
                <option value="1">Grade 1</option>
                <option value="2">Grade 2</option>
                <option value="3">Grade 3</option>
              </FormSelect>

              {image ?
                <div className='my-4 w-50'>
                  <img src={image} loading='lazy' alt={bt.picture} className='img-fluid rounded-3'/>
                </div>
                :
                bt.picture ? 
                <div className='my-4 w-50'>
                  <img src={`${getOrigin()}/storage/berita-terkini/picture/${bt.picture}`} loading='lazy' alt={bt.picture} className='img-fluid rounded-3'/>
                </div>
                :
                <div style={{backgroundColor: '#eee'}} className='my-4 ratio ratio-1x1 text-secondary rounded-3 w-50 d-flex justify-content-center align-items-center'>
                  Tidak ada gambar.
                </div>
              }

              <FormInput
                label="Gambar"
                type="file"
                name="picture"
                accept=".jpg, .jpeg, .png"
                error={errors.picture}
                onChange={handleChangeImage}
              />
              <div className='row pt-2'>
                <NewSubmitButton widthFull onClose={handleClose}/>
              </div>
            </form>
        </LayoutModal>
    </>
  )
}

export default BTEdit