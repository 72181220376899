import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { fetchPegawai } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import PageLoading from "../../components/pages/Loading";
import { titleState } from "../../storage/pages";
import axios from "../../axios";
import { saToast } from "../../helpers";

const PenilaianKompetensiStruktur = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchPegawai("all");
      setItems(res.data.pegawai);
      console.log(res.data.pegawai);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    setTitle("Struktur Penilaian");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        <div className="text-danger">
          <h3>Note :</h3>
          <li>
            Pengecekan dilakukan dengan cara login ke semua akun (yang akan
            dilakukan oleh sistem)
          </li>
          <li>
            Pengecekan per akunnya membutuhkan waktu 2 s/d 4 detik, mohon
            bersabar
          </li>
          <li>
            Proses pada halaman ini akan membebani server, jangan sering-sering
            buka page ini ya kakak kakak :)
          </li>
        </div>
        <LayoutTable>
          <LayoutThead>
            <th>No</th>
            <th>NIP</th>
            <th>Nama</th>
            <th>Group</th>
            <th>Grade</th>
            <th>Nama Dinilai</th>
          </LayoutThead>
          <tbody>
            {items &&
              items.length > 0 &&
              items.map((i, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-nowrap">{i.nip}</td>
                    <td className="text-nowrap">{i.nama}</td>
                    <td className="text-nowrap text-center">
                      {i.group ?? "Belum ditentukan"}
                    </td>
                    <td className="text-nowrap text-center">
                      {i.grade ?? "Belum ditentukan"}
                    </td>
                    {i.group !== null && i.grade !== null ? (
                      <td className="text-nowrap">
                        <GetPenilai pegawai={i} setTime={7000 * (index + 1)} />
                      </td>
                    ) : (
                      <td className="text-nowrap">Belum ditentukan</td>
                    )}
                  </tr>
                );
              })}
          </tbody>
        </LayoutTable>
      </>
    );
  } else {
    return (
      <PageLoading title="Proses ini hanya bisa dilakukan di local server karna memungkinkan padatnya trafic" />
    );
  }
};

const GetPenilai = (props) => {
  const { pegawai, setTime } = props;
  const [styleClass, setStyleClass] = useState("");

  const [items, setItems] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(
        `/api/penilaian-kompetensi?pegawai_id=${pegawai.id}`
      );
      saToast("success", `Check akun milik ${pegawai.nama}`);
      setItems(res.data.pegawai);
      if (res.data.max) {
        setStyleClass("text-danger");
      } else {
        setStyleClass("text-success");
      }
    } catch (err) {
      console.log(err);
    }
  }, [pegawai.id, pegawai.nama]);

  useEffect(() => {
    let tO = setTimeout(() => {
      fetchData();
    }, setTime);
    return () => {
      clearTimeout(tO);
    };
  }, [fetchData, setTime]);

  return items.length > 0 ? (
    <ol>
      {items.map((i, index) => {
        return (
          <React.Fragment key={index}>
            <li>
              {i.nama}
              {`  ||  `}
              <span className="text-primary">{i.cabang}</span>
              {`  ||  `}
              <span className="text-success">{i.group}</span>
              {`  ||  `}
              <span className="text-info">Level {i.grade}</span>
              {i.pegawai_penilaian && i.pegawai_penilaian.length > 0 && (
                <ol className={styleClass}>
                  {i.pegawai_penilaian.map((i, index) => {
                    return (
                      i.penilai_id === pegawai.id && (
                        <React.Fragment key={index}>
                          <li>
                            {i.soal_id} = {i.value}
                          </li>
                        </React.Fragment>
                      )
                    );
                  })}
                </ol>
              )}
            </li>
          </React.Fragment>
        );
      })}
    </ol>
  ) : (
    "Waiting . . ."
  );
};

export default PenilaianKompetensiStruktur;
