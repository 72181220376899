import React, { useEffect, useState } from "react";
import { fetchingData } from "../../api";
import FormInput from "../../components/forms/Input";
import { getJurusanShort } from "../../helpers";
import FormTextarea from "../../components/forms/Textarea";

export const JurusanSelect = ({
  jurusan = [],
  pojtJurusan = [],
  onSetJurusanSelect,
}) => {
  const [jurusanSelect, setJurusanSelect] = useState(
    pojtJurusan && pojtJurusan.length > 0
      ? pojtJurusan.map((pj) => {
          return {
            jurusan_id: pj.jurusan_id,
            jumlah_mhs: pj.jumlah_mhs,
            posisi: pj.posisi,
          };
        })
      : []
  );

  useEffect(() => {
    onSetJurusanSelect(jurusanSelect);
  }, [jurusanSelect, onSetJurusanSelect]);

  const handleChange = (e, jurusan_id) => {
    let oldJs = jurusanSelect.filter(
      (js) => parseInt(js.jurusan_id) !== parseInt(jurusan_id)
    );

    let oldVal = jurusanSelect.filter(
      (js) => parseInt(js.jurusan_id) === parseInt(jurusan_id)
    );

    let newJs = oldJs.concat([
      {
        jurusan_id,
        jumlah_mhs:
          e.target.name === "jumlah_mhs"
            ? e.target.value
            : oldVal[0] && oldVal[0].jumlah_mhs,
        posisi:
          e.target.name === "posisi"
            ? e.target.value
            : oldVal[0] && oldVal[0].posisi,
      },
    ]);
    setJurusanSelect(newJs.filter((n) => n.jurusan_id));

    console.log(jurusanSelect);
  };

  return (
    <div className="bg-light border border-silver rounded-3 p-3 mb-3">
      <div className="row">
        <div className="col-lg-12">
          <p className="text-dark fw-semibold mb-0">
            <i className="fa fa-fw fa-circle-exclamation text-info me-1" />
            Jumlah Mahasiswa / Jurusan
          </p>
          <p className="text-dark">
            Isi hanya dijurusan yang diperlukan, tidak harus terisi semua.
          </p>
        </div>
        <div className="row">
          {jurusan.length > 1 &&
            jurusan.map((j, index) => {
              return (
                <React.Fragment key={index}>
                  {/* <div className="">{getJurusanShort(j.id)}</div> */}
                  <div className="col-lg-3">
                    <FormInput
                      label={getJurusanShort(j.id)}
                      name="jumlah_mhs"
                      onChange={(e) => {
                        handleChange(e, j.id);
                      }}
                      value={
                        jurusanSelect.length > 0
                          ? jurusanSelect
                              .filter(
                                (js) =>
                                  parseInt(js.jurusan_id) === parseInt(j.id)
                              )
                              .map((js) => js.jumlah_mhs)
                          : ""
                      }
                      placeholder={`Jumlah ${getJurusanShort(j.id)}`}
                    />
                    <FormTextarea
                      name="posisi"
                      placeholder="Posisi . . ."
                      onChange={(e) => {
                        handleChange(e, j.id);
                      }}
                      value={
                        jurusanSelect.length > 0
                          ? jurusanSelect
                              .filter(
                                (js) =>
                                  parseInt(js.jurusan_id) === parseInt(j.id)
                              )
                              .map((js) => js.posisi)
                          : ""
                      }
                    />
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export const FilterTahunAjaranPerusahaan = (props) => {
  const { tahunAjaran, onChange } = props;

  return (
    <div>
      <label htmlFor="" className="fw-semibold text-primary">
        Tahun Ajaran
      </label>
      <select
        name=""
        className="form-select form-select-sm form-select-custom border-silver rounded-3"
        onChange={(e) => {
          onChange(e.target.value);
          fetchingData();
        }}
        value={tahunAjaran}
      >
        <option value="2020/2021">2020/2021</option>
        <option value="2021/2022">2021/2022</option>
        <option value="2022/2023">2022/2023</option>
        <option value="2023/2024">2023/2024</option>
        <option value="2024/2025">2024/2025</option>
        <option value="2025/2026">2025/2026</option>
        <option value="2026/2027">2026/2027</option>
        <option value="2027/2028">2027/2028</option>
      </select>
    </div>
  );
};
