import React, { useState } from "react";
import { findMhs } from "../../api";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const MhsRekapTak = (props) => {
  const { mhs } = props;
  const [modal, setModal] = useState(false);

  const [items, setItems] = useState({});

  const fetchData = async () => {
    try {
      const res = await findMhs(mhs.id, `with_tak=true`);
      setItems(res.data);
    } catch (err) {
      console.error(err);
    }
  };

  const handleOpen = () => {
    fetchData();
    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  return (
    <>
      <button
        onClick={() => handleOpen()}
        type="button"
        className="btn btn-sm btn-success-2 text-sm py-0 px-1"
      >
        <i className="fa fa-fw fa-sm fa-book"/> Rekap Nilai TAK
      </button>

      <LayoutModal
        title={`Rekap Nilai TAK ${mhs.nim}`}
        modal={modal}
        onClose={handleClose}
      >
        <div className="bg-gradient-primary text-white rounded-3 shadow p-3 mb-3">
          <p className="mb-0 fw-semibold text-warning">Nama Mahasiswa :</p>
          <h5>{mhs.nama}</h5>
          <p className="mb-0 fw-semibold text-warning">Total Nilai :</p>
          <h3 className="mt-1 fw-semibold"><i className="fa fa-fw fa-award"/> {mhs.nilai_akhir ? mhs.nilai_akhir.tak : "-"}</h3>
        </div>

        <h5 className="text-black fw-semibold">Program Kampus</h5>
        <TableNew>
          <Thead>
            <Tr className="text-center">
              <Th>No</Th>
              <Th>Nama</Th>
              <Th>Tanggal</Th>
              <Th>Tempat</Th>
              <Th>Nilai</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.mhs &&
              items.mhs.progkam &&
              items.mhs.progkam.length > 0 &&
              items.mhs.progkam.map((p, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center text-nowrap">{index + 1}</Td>
                    <Td className="text-nowrap">{p.nama}</Td>
                    <Td className="text-nowrap">{p.tanggal}</Td>
                    <Td className="text-nowrap">
                      {p.tempat && p.tempat.length > 40
                        ? p.tempat.substring(0, 40) + ". . ."
                        : p.tempat}
                    </Td>
                    <Td className="text-center text-nowrap">
                      {p.pivot && p.pivot.nilai}
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </TableNew>

        <h5 className="text-black fw-semibold">Program Lepasan</h5>
        <TableNew>
          <Thead>
            <Tr className="text-center">
              <Th>No</Th>
              <Th>Nama</Th>
              <Th>Tanggal</Th>
              <Th>Tempat</Th>
              <Th>Nilai</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.mhs &&
              items.mhs.progkam_lep &&
              items.mhs.progkam_lep.length > 0 &&
              items.mhs.progkam_lep.map((p, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center text-nowrap">{index + 1}</Td>
                    <Td className="text-nowrap">{p.nama}</Td>
                    <Td className="text-nowrap">{p.tanggal}</Td>
                    <Td className="text-nowrap">
                      {p.tempat && p.tempat.length > 40
                        ? p.tempat.substring(0, 40) + ". . ."
                        : p.tempat}
                    </Td>
                    <Td className="text-center text-nowrap">{p.nilai}</Td>
                  </Tr>
                );
              })}
          </Tbody>
        </TableNew>
        <button className="btn btn-sm btn-dark border-0" onClick={handleClose}>
          <i className="fa fa-fw fa-sm fa-arrow-left"/>
        </button>
      </LayoutModal>
    </>
  );
};

export default MhsRekapTak;
