import React from "react";

const FormInput = ({
  label,
  labelClassName,
  type,
  customFront,
  name,
  value,
  placeholder,
  readOnly,
  onChange,
  error,
  className,
  hidden,
  marginBottom,
  disabled,
  onKeyDown,
  accept,
}) => {
  return (
    <div className={`form-group ${marginBottom}`}>
      {label && (
        <>
          <label
            className={`fw-semibold ${labelClassName ? labelClassName : 'text-primary'}`}
            dangerouslySetInnerHTML={{ __html: label }}
          ></label>{" "}
        </>
      )}
      {type === "date" || type === "datetime-local" || type === "time" ? (
        <div className={customFront && "d-flex"}>
          {customFront && <span className="mr-1 mt-1">{customFront}</span>}
          <input
            className={`form-control form-control-sm form-control-custom text-black rounded-3 ${className} ${
              error ? "is-invalid" : "border-silver"
            }`}
            onChange={onChange}
            onFocus={onChange}
            onMouseUp={onChange}
            onPaste={onChange}
            type={type}
            name={name}
            defaultValue={value}
            placeholder={placeholder ? placeholder : 'Ketik disini. . .'}
            readOnly={readOnly}
            hidden={hidden}
            disabled={disabled}
            onKeyDown={onKeyDown}
            accept={accept}
          />
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      ) : (
        <div className={customFront && "d-flex"}>
          {customFront && <span className="mr-1 mt-1">{customFront}</span>}
          <input
            className={`form-control form-control-sm form-control-custom text-black rounded-3 ${error ? "is-invalid" : "border-silver"} ${className}`}
            onKeyUp={onChange}
            onChange={onChange}
            onFocus={onChange}
            onPaste={onChange}
            type={type}
            name={name}
            defaultValue={value}
            placeholder={placeholder ? placeholder : 'Ketik disini. . .'}
            readOnly={readOnly}
            hidden={hidden}
            disabled={disabled}
            accept={accept}
          />
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      )}
    </div>
  );
};

export default FormInput;
