import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../axios";
import ButtonForm from "../../components/forms/Button";
import InputForm from "../../components/forms/Input";
import TextareaForm from "../../components/forms/Textarea";
import { saAlert } from "../../helpers";
import { tokenState, userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";

const LemburCreate = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const user = useRecoilValue(userState);
  const history = useHistory();

  useEffect(() => {
    setTitle("Buat Lembur Baru");
  }, [setTitle]);

  const [dari, setDari] = useState();
  const [sampai, setSampai] = useState();
  const [pekerjaan, setPekerjaan] = useState();
  const [keterangan, setKeterangan] = useState();

  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        "/api/lembur",
        {
          dari,
          sampai,
          pekerjaan,
          keterangan,
        },
        { headers: { Authorization: "Bearer " + token } }
      )
      .then((res) => {
        console.log(res.data);
        if (res.status === 200) {
          saAlert("success", res.data);
          history.push("/lembur/show");
        }
      })
      .catch((err) => {
        let res = err.response;
        if (res.status === 422) {
          setFormErrors(res.data.errors);
        }
      });
  };

  return (
    <>
      <form method="POST" onSubmit={handleSubmit}>
        <InputForm label="NIP" value={user.nip} readOnly={true} />
        <InputForm label="Nama" value={user.nama} readOnly={true} />

        <div className="row">
          <InputForm
            divClass="col"
            label="Dari"
            type="datetime-local"
            onChange={(e) => setDari(e.target.value)}
            error={formErrors.dari}
          />
          <InputForm
            divClass="col"
            label="Sampai"
            type="datetime-local"
            onChange={(e) => setSampai(e.target.value)}
            error={formErrors.sampai}
          />
        </div>

        <TextareaForm
          label="Pekerjaan"
          onChange={(e) => setPekerjaan(e.target.value)}
          error={formErrors.pekerjaan}
        />
        <TextareaForm
          label="Keterangan"
          onChange={(e) => setKeterangan(e.target.value)}
          error={formErrors.keterangan}
        />

        <hr />
        <p className="text-center">
          Approval lembur oleh :{" "}
          {(() => {
            if (user.atasan1) {
              return user.atasan1.nama;
            } else {
              if (user.atasan2) {
                return user.atasan2.nama;
              }
            }
          })()}
        </p>
        <hr />

        <ButtonForm submit="Ajukan" reset="Reset" back="Kembali" />
      </form>
    </>
  );
};

export default LemburCreate;
