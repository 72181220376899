import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  fetchBukuSaku,
  fetchBukuSakuKegiatan,
  fetchMhs,
  postBukuSaku,
} from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterAngkatan,
  FilterJenjang,
  FilterJurusan,
  FilterSearch,
  FilterCabang,
  defaultFilterAngkatan,
  FilterStatus,
  FilterPagination,
  FilterPagiSore,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import ShowHideDetail from "../../components/ShowHideDetail";
import { getJenjang, getJurusanShort, getOnOffMhs, getOrigin, namaBulan, saAlert } from "../../helpers";
import { userState } from "../../storage/auth";
import { angkatanState, titleState } from "../../storage/pages";
import UploadDisc from "../disc/Upload";
import MhsTableDetail from "./TableDetail";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";

const MhsPkk = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);
  const user = useRecoilValue(userState);
  const defAngkatan = useRecoilValue(angkatanState);

  const [mhs, setMhs] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [angkatan, setAngkatan] = useState(defAngkatan);
  const [jenjang, setJenjang] = useState("D1");
  const [pagiSore, setPagiSore] = useState("");
  const [pagination, setPagination] = useState(10);
  const [status, setStatus] = useState("");
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    if (jenjang) {
      try {
        const res = await fetchMhs(
          page,
          `pagination=${pagination}&search=${search}&jurusan=${jurusan}&angkatan=${angkatan}&jenjang=${jenjang}&pagi_sore=${pagiSore}&status=${status}&cabang=${cabang}`
        );
        if (res.data && res.data.mhs) {
          // res.data.mhs.data.sort((a, b) => (a.nama < b.nama) ? -1 : 1)
          setMhs(res.data.mhs);
          setIsLoaded(true);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      saAlert("warning", "Jenjang tidak boleh kosong !");
    }
  }, [
    page,
    search,
    jurusan,
    angkatan,
    jenjang,
    pagiSore,
    status,
    pagination,
    cabang,
  ]);

  useEffect(() => {
    setTitle("Data Mahasiswa");
    fetchData();
  }, [setTitle, fetchData]);

  const [detail, setDetail] = useState(false);

  if (isLoaded) {
    return (
      <>
        {/* Filter 1 */}
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterJurusan
              onChange={(res) => {
                setPage(1);
                setJurusan(res);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterSearch
              onChange={(res) => {
                setPage(1);
                setSearch(res);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterStatus
              onChange={(res) => {
                setStatus(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {/* Filter 2 */}
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterAngkatan
              value={angkatan}
              onChange={(res) => {
                setPage(1);
                setAngkatan(res);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterJenjang
              value={jenjang}
              onChange={(res) => {
                setPage(1);
                setJenjang(res);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterPagination
              onChange={(res) => {
                setPagination(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {/* Filter 3 */}
        <div className="row mb-3">
          <div className="col-md-4">
            <FilterPagiSore
              onChange={(res) => {
                setPage(1);
                setPagiSore(res);
              }}
            />
          </div>
          {user.cabang === "pusat" && (
            <div className="col-md-4">
              <FilterCabang
                onChange={(res) => {
                  setPage(1);
                  setCabang(res);
                }}
              />
            </div>
          )}
        </div>

        <TextOnOffMhs/>

        <ShowHideDetail detail={detail} onClick={(res) => setDetail(res)} />
        {jenjang !== "" && mhs.data && mhs.data.length >= 1 ? (
          detail ? (
            <MhsTableDetail mhs={mhs} />
          ) : (
            <div className="row">
              <div className="col-2 pe-0">
                <TableNew>
                  <Thead>
                    <Tr className="text-center">
                      <Th>No</Th>
                      <Th>NIM</Th>
                      <Th>Foto</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {mhs.data.map((m, index) => {
                      return (
                        <Tr key={index} className={getOnOffMhs(m)}>
                          <Td className="text-center">{mhs.from + index}</Td>
                          <Td className="text-center">{m.nim}</Td>
                          <Td className="text-center">
                            <ShowPicture mhs={m} />
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </TableNew>
              </div>
              <div className="col-7 px-0">
                <TableNew>
                  <Thead>
                    <Tr className="text-center">
                      <Th className="text-nowrap">Cabang</Th>
                      <Th className="text-nowrap">No HP</Th>
                      <Th className="text-nowrap">Nama</Th>
                      <Th className="text-nowrap">Angkatan</Th>
                      <Th className="text-nowrap">Jenjang</Th>
                      <Th className="text-nowrap">Jurusan</Th>
                      <Th className="text-nowrap">Lulus / Blm</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {mhs.data.map((m, index) => {
                      return (
                        <Tr
                          key={index}
                          className={getOnOffMhs(m)}
                        >
                          <Td className="text-center">{m.cabang}</Td>
                          <Td className="text-center text-nowrap">{m.no_hp}</Td>
                          <Td className="text-nowrap">{m.nama}</Td>
                          <Td className="text-center">{m.angkatan}</Td>
                          <Td className="text-center text-nowrap">{getJenjang(m)}</Td>
                          <Td className="text-center">
                            {getJurusanShort(m.jurusan_id)}
                          </Td>
                          <Td className="text-center">
                            {m.wisuda ? <span className="fw-semibold text-primary-2">Lulus</span> : <span className="fw-semibold text-danger-2">Belum</span>}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </TableNew>
              </div>
              <div className="col-3 ps-0">
                <TableNew>
                  <Thead>
                    <Tr className="text-center">
                      <Th className="text-nowrap">DISC_1</Th>
                      <Th className="text-nowrap">DISC_2</Th>
                      <Th className="text-nowrap">Upload</Th>
                      <Th className="text-nowrap">Buku Saku</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {mhs.data.map((m, index) => {
                      return (
                        <Tr key={index} className={getOnOffMhs(m)}>
                          <Td className="text-center text-nowrap">
                            {m.disc && m.disc.disc_1 ? (
                              <button
                                className="btn btn-sm btn-dark border-0 text-sm py-0 px-1"
                                type="button"
                                onClick={() => {
                                  window.open(
                                    `${getOrigin()}/storage/disc_mhs/disc_1_${m.nim}.pdf`
                                  );
                                }}
                              >
                                <i className="fa fa-fw fa-sm fa-eye" />
                              </button>
                            ) : (
                              <span className="text-secondary text-sm">Kosong</span>
                            )}
                          </Td>
                          <Td className="text-center text-nowrap">
                            {m.disc && m.disc.disc_2 ? (
                              <span
                                className="btn btn-sm btn-dark border-0 text-sm py-0 px-1"
                                onClick={() => {
                                  window.open(
                                    `${getOrigin()}/storage/disc_mhs/disc_2_${m.nim}.pdf`
                                  );
                                }}
                              >
                                <i className="fa fa-fw fa-sm fa-eye" />
                              </span>
                            ) : (
                              <span className="text-secondary text-sm">Kosong</span>
                            )}
                          </Td>
                          <Td className="text-center text-nowrap">
                            <UploadDisc mhs={m} onFetchData={fetchData} />
                          </Td>
                          <Td className="text-center text-nowrap">
                            <BukuSakuCreate mhs={m} onFetchData={fetchData} />
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </TableNew>
              </div>
            </div>
          )
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={mhs.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

const BukuSakuCreate = ({ mhs, onFetchData }) => {
  const [modal, setModal] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const tagForm = useRef(null);

  const [bukuSakuKegiatan, setBukuSakuKegiatan] = useState({});

  const getBukuSakuKegiatan = async () => {
    try {
      const res = await fetchBukuSakuKegiatan(
        "all",
        `status=1&jenjang=${mhs.jenjang}&order_by=bulan-asc`
      );
      if (res.data && res.data.buku_saku_kegiatan) {
        setBukuSakuKegiatan(res.data.buku_saku_kegiatan);
        setForm((prevState) => {
          return {
            ...prevState,
            buku_saku_kegiatan: res.data.buku_saku_kegiatan,
          };
        });
        setIsLoaded(true);
      }
    } catch (err) {
      console.error(err.response);
    }
  };

  const getBukuSaku = async () => {
    try {
      const res = await fetchBukuSaku("all", `mhs_id=${mhs.id}`);
      if (res.data && res.data.buku_saku) {
        console.log(res.data.buku_saku);
        res.data.buku_saku.forEach((bs) => {
          setForm((prevState) => {
            return {
              ...prevState,
              [`buku_saku_kegiatan_id_${bs.buku_saku_kegiatan_id}`]:
                bs.buku_saku_kegiatan_id,
              [`status_${bs.buku_saku_kegiatan_id}`]: bs.status,
              [`tanggal_${bs.buku_saku_kegiatan_id}`]: bs.tanggal,
              [`keterangan_${bs.buku_saku_kegiatan_id}`]: bs.keterangan,
            };
          });
        });
      }
    } catch (err) {
      console.error(err.response);
    }
  };

  const [form, setForm] = useState({});

  const handleClose = () => {
    setForm({});
    setBukuSakuKegiatan({});
    tagForm.current && tagForm.current.reset();
    setModal(false);
  };

  const handleOpen = () => {
    getBukuSakuKegiatan();
    getBukuSaku();
    setForm((prevState) => {
      return { ...prevState, mhs_id: mhs.id };
    });
    setModal(true);
  };

  const handleChange = (e, bsk_id = false) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });

    if (bsk_id) {
      setForm((prevState) => {
        return { ...prevState, [`buku_saku_kegiatan_id_${bsk_id}`]: bsk_id };
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = await postBukuSaku(form);
    if (res.data && res.data.msg === "success") {
      saAlert("success", "Berhasil simpan data !");
    }
    try {
    } catch (err) {
      if (err.response) {
        if (err.response.status === 422) {
          console.log(err.response.data.errors);
        }
      }
    }
  };

  return (
    <>
      <button
        onClick={handleOpen}
        type="button"
        className="btn btn-sm btn-success-2 text-sm py-0 px-1"
      >
        <i className="fa fa-fw fa-sm fa-pen"/>
      </button>

      <LayoutModal
        title={`Buku Saku ${mhs.nama}`}
        modal={modal}
        onClose={handleClose}
      >
        {isLoaded && (
          <form onSubmit={handleSubmit} ref={tagForm} onClose={handleClose}>
            <div className="row">
              {bukuSakuKegiatan &&
                bukuSakuKegiatan.length > 0 &&
                bukuSakuKegiatan.map((bsk, index) => {
                  return (
                    <div className="col-lg-3" key={index}>
                      <div className="bg-gradient-primary text-white rounded-3 p-3 h-100">
                        {parseInt(form[`status_${bsk.id}`]) === 9 && (
                          <h6 className="fw-semibold text-danger-2">Ditolak</h6>
                        )}
                        {parseInt(form[`status_${bsk.id}`]) === 1 && (
                          <h6 className="fw-semibold text-info">Diajukan</h6>
                        )}
                        {parseInt(form[`status_${bsk.id}`]) === 2 && (
                          <h6 className="fw-semibold text-success-2">Disetujui</h6>
                        )}
                        <h5 className="border-bottom-warning pb-2">{bsk.judul}</h5>
                        <p className="text-gray-400">
                          Ditetapkan untuk dilaksanakan pada bulan{" "}
                          <span className="text-warning">{namaBulan[parseInt(bsk.bulan)]}</span>
                        </p>
                        <FormSelect
                          label="Status"
                          labelClassName="text-info"
                          name={`status_${bsk.id}`}
                          value={form[`status_${bsk.id}`]}
                          onChange={(e) => {
                            handleChange(e);
                            handleChange(e, bsk.id);
                          }}
                        >
                          <option value="">Belum</option>
                          <option value="1">Diajukan</option>
                          <option value="2">Disetujui</option>
                          <option value="9">Ditolak</option>
                        </FormSelect>
                        <FormInput
                          label="Tanggal Pelaksanaan"
                          labelClassName="text-info"
                          type="date"
                          name={`tanggal_${bsk.id}`}
                          value={form[`tanggal_${bsk.id}`]}
                          onChange={handleChange}
                        />
                        <FormTextarea
                          label="Keterangan"
                          labelClassName="text-info"
                          name={`keterangan_${bsk.id}`}
                          value={form[`keterangan_${bsk.id}`]}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="btn-group">
              <button className="btn btn-sm btn-success" type="submit">
                Simpan
              </button>
              <button
                className="btn btn-sm btn-secondary"
                onClick={handleClose}
                type="button"
              >
                Tutup
              </button>
            </div>
          </form>
        )}
      </LayoutModal>
    </>
  );
};

const ShowPicture = ({ mhs }) => {
  const [modal, setModal] = useState(false);

  const handleClose = () => {
    setModal(false);
  };

  const handleOpen = () => {
    setModal(true);
  };

  return (
    <>
      <span
        onClick={handleOpen}
        type="button"
        className="link-primary"
      >
        <i className="fa fa-fw fa-image"/>
      </span>

      <LayoutModal
        title={`Foto ${mhs.nama}`}
        modal={modal}
        onClose={handleClose}
      >
        <div className="text-center">
          <img
            src={`${getOrigin()}/storage/foto_mahasiswa/${mhs.cabang}/${
              mhs.angkatan
            }/${mhs.nim}.jpg`}
            alt="Foto Mahasiswa"
            className="img-fluid"
          />
        </div>
      </LayoutModal>
    </>
  );
};

export default MhsPkk;
