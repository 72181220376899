import React, { useRef, useState } from "react";
import { postPeminjamanRuangan } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";

const PeminjamanRuanganCreate = ({ fetchData, ruangan }) => {
  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
    setForm({});
    setErrors({});
  };

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      const res = await postPeminjamanRuangan(form);
      if (res.data && res.data.status) {
        fetchData();
        handleClose();
        saAlert(res.data.status, res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <>
      <div className="row mb-2 justify-content-end">
        <div className="col-lg-3">
          <button onClick={handleOpen} className="btn btn-sm btn-warning-2 fw-semibold shadow rounded-3 w-100">
            <i className="fa fa-fw fa-pen fa-sm me-1"></i> Buat peminjaman ruangan
          </button>
        </div>
      </div>
      <LayoutModal
        title="Tambah Data Peminjaman Ruangan"
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
          <div className="row">
            <div className="col-md-4">
              <FormSelect
                label="Ruangan"
                name="ruangan_id"
                onChange={handleChange}
                error={errors.ruangan_id}
              >
                <option value="">Pilih</option>
                {ruangan &&
                  ruangan.length > 0 &&
                  ruangan.map((r, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={r.id}>{r.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col-md-4">
              <FormInput
                label="Dari"
                type="datetime-local"
                name="dari"
                onChange={handleChange}
                error={errors.dari}
              />
            </div>
            <div className="col-md-4">
              <FormInput
                label="Sampai"
                type="datetime-local"
                name="sampai"
                onChange={handleChange}
                error={errors.sampai}
              />
            </div>
            <div className="col-md-4">
              <FormInput
                label="No Telp"
                name="no_telp"
                onChange={handleChange}
                error={errors.no_telp}
              />
            </div>
            <div className="col-md-4">
              <FormTextarea
                label="Keperluan"
                name="keperluan"
                onChange={handleChange}
                error={errors.keperluan}
              />
            </div>
          </div>
          <button className="btn btn-sm btn-success" type="submit">
            Simpan
          </button>
          <button
            className="btn btn-sm btn-secondary"
            type="button"
            onClick={handleClose}
          >
            Kembali
          </button>
        </form>
      </LayoutModal>
    </>
  );
};

export default PeminjamanRuanganCreate;
