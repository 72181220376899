import { fetchDataControl, fetchingData } from "../../api";
import axios from "../../axios";

// Cabang
// index
export const fetchCabang = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/cabang?page=${page}&${custom}`);
  });
};
// show
export const showCabang = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/cabang/${id}?${custom}`);
  });
};
// store
export const postCabang = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/cabang`, data);
  });
};
// update
export const patchCabang = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/cabang/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteCabang = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/cabang/${id}&${custom}`);
  });
};
