import React from "react";
import { MultipleMenu, SinggleMenu } from "./Sidebar";

const Pkk = (props) => {
  const { user } = props;
  return (
    <>
      {user.jabatan === "IT Programmer" ||
      user.jabatan === "Dir. Akademik & Kemahasiswaan" ||
      user.jabatan === "Dir. Pusat Karir dan Kerjasama" ||
      user.jabatan === "Kepala Cabang Denpasar" ||
      user.jabatan === "Kepala Cabang Singaraja" ||
      user.jabatan === "Kepala Cabang Karangasem" ||
      user.jabatan === "Ka. Bag. Pusat Karier & Alumni" ||
      user.jabatan === "Ka. Unit Kerjasama" ||
      user.jabatan === "Ka. Unit Karier & Alumni" ||
      user.jabatan === "Ka. Bag. Pusat Karier & Kerjasama" ||
      // custom
      user.nama === "I Gede Riyan Rahendra, S.Sn." ||
      user.nama === "Ni Made Yulia Tarina, S.KM." ||
      user.group === "Akademik" ? (
        <>
          <hr className="border-0 my-2" />

          <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
            PKK
          </div>

          <hr className="sidebar-divider border-0 mt-2 mb-0" />

          <MultipleMenu
            updateLabel
            title="Perusahaan"
            fa="fa-users"
            links={[
              { title: "Perusahaan", link: "/perusahaan-pkk" },
              { title: "Perusahaan OJT", link: "/pojt-pkk" },
            ]}
          />

          <SinggleMenu title="Mahasiswa" fa="fa-users" link="/mhs-pkk" />
          <SinggleMenu title="Buku Saku" fa="fa-newspaper" link="/buku-saku"/>

          {user.jabatan === "IT Programmer" ||
          user.jabatan === "Dir. Akademik & Kemahasiswaan" ||
          user.jabatan === "Dir. Pusat Karir dan Kerjasama" ? (
            <SinggleMenu
              title="Kegiatan Buku Saku"
              fa="fa-book"
              link="/buku-saku-kegiatan"
              newLabel
            />
          ) : (
            ""
          )}

          <MultipleMenu
            title="Alumni"
            fa="fa-users"
            links={[
              { title: "Perusahaan", link: "/perusahaan-alumni" },
              { title: "Mahasiswa", link: "/alumni-pkk" },
            ]}
          />
        </>
      ) : null}
    </>
  );
};

export default Pkk;
