import React from "react";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { getBulan } from "../../helpers";

const MhsTableDetail = ({ mhs }) => {
  return (
    // <LayoutTable>
    //   <LayoutThead>
    //     <th>no</th>
    //     <th>status</th>
    //     <th>cabang</th>
    //     <th>nim</th>
    //     <th>nama</th>
    //     <th>tempat</th>
    //     <th>tanggal</th>
    //     <th>jenis</th>
    //     <th>agama</th>
    //     <th>angkatan</th>
    //     <th>jenjang</th>
    //     <th>jurusan</th>
    //     <th>waktu_belajar</th>
    //     <th>semester</th>
    //     <th>no_hp</th>
    //     <th>no_hp_ortu</th>
    //     <th>nama_ayah</th>
    //     <th>nama_ibu</th>
    //     <th>alamat_ortu</th>
    //     <th>email</th>
    //     <th>fb</th>
    //     <th>ig</th>
    //     <th>asal_sekolah</th>
    //     <th>tahun_tamat</th>
    //     <th>jurusan_sma</th>
    //     <th>informasi</th>
    //     <th>keputusan_kuliah</th>
    //     <th>refrensi</th>
    //     <th>tanggal_bergabung</th>
    //     <th>kdbb</th>
    //     <th>ktp</th>
    //   </LayoutThead>
    //   <tbody>
    //     {mhs.data.map((m, index) => {
    //       return (
    //         <tr key={index}>
    //           <td className="text-nowrap">{mhs.from + index}</td>
    //           <td className="text-nowrap">{m.status}</td>
    //           <td className="text-nowrap">{m.cabang}</td>
    //           <td className="text-nowrap">{m.nim}</td>
    //           <td className="text-nowrap">{m.nama}</td>
    //           <td className="text-nowrap">{m.tempat}</td>
    //           <td className="text-nowrap">{m.tanggal}</td>
    //           <td className="text-nowrap">{m.jenis}</td>
    //           <td className="text-nowrap">{m.agama}</td>
    //           <td className="text-nowrap">{m.angkatan}</td>
    //           <td className="text-nowrap">{m.jenjang}</td>
    //           <td className="text-nowrap">{m.jurusan_ && m.jurusan_.nama}</td>
    //           <td className="text-nowrap">{m.waktu_belajar}</td>
    //           <td className="text-nowrap">{m.semester}</td>
    //           <td className="text-nowrap">{m.no_hp}</td>
    //           <td className="text-nowrap">{m.no_hp_ortu}</td>
    //           <td className="text-nowrap">{m.nama_ayah}</td>
    //           <td className="text-nowrap">{m.nama_ibu}</td>
    //           <td className="text-nowrap">{m.alamat_ortu}</td>
    //           <td className="text-nowrap">{m.email}</td>
    //           <td className="text-nowrap">{m.fb}</td>
    //           <td className="text-nowrap">{m.ig}</td>
    //           <td className="text-nowrap">{m.asal_sekolah}</td>
    //           <td className="text-nowrap">{m.tahun_tamat}</td>
    //           <td className="text-nowrap">{m.jurusan_sma}</td>
    //           <td className="text-nowrap">{m.informasi}</td>
    //           <td className="text-nowrap">{m.keputusan_kuliah}</td>
    //           <td className="text-nowrap">{m.refrensi}</td>
    //           <td className="text-nowrap">{m.tanggal_bergabung}</td>
    //           <td className="text-nowrap">{m.kdbb}</td>
    //           <td className="text-nowrap">{m.ktp}</td>
    //         </tr>
    //       );
    //     })}
    //   </tbody>
    // </LayoutTable>







    
    <LayoutTable>
      <LayoutThead>
        <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">no</th>
        <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">nama</th>
        <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">nim</th>
        <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Program</th>
        <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">jurusan</th>
        <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">jenis Kelamin</th>
        <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Tempat & Tanggal Lahir</th>
      </LayoutThead>
      <tbody>
        {mhs.data.map((m, index) => {
          return (
            <tr key={index}>
              <td className="py-1 border-silver text-nowrap text-center">{mhs.from + index}</td>
              <td className="py-1 border-silver text-nowrap">{m.nama}</td>
              <td className="py-1 border-silver text-nowrap text-center">{m.nim}</td>
              <td className="py-1 border-silver text-nowrap text-center">{m.jenjang === "D1" && "Program 1 Tahun"} {m.jenjang === "D2" && "Program 2 Tahun"}</td>
              <td className="py-1 border-silver text-nowrap">{m.jurusan_ && m.jurusan_.nama}</td>
              <td className="py-1 border-silver text-nowrap text-center">{m.jenis}</td>
              <td className="py-1 border-silver text-nowrap">{`${m.tempat}, ${m.tanggal.split("-")[2]} ${getBulan(m.tanggal.split("-")[1])} ${m.tanggal.split("-")[0]}`}</td>
            </tr>
          );
        })}
      </tbody>
    </LayoutTable>
  );
};

export default MhsTableDetail;
