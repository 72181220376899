import React, { useState, useEffect, useCallback, useRef } from "react";
import AbsensiCreate from "./Create";
import { useRecoilState, useRecoilValue } from "recoil";
import { loginMasterState, userState } from "../../storage/auth";
import { deleteAbsensiPegawai, patchAbsensiPegawai, showAbsensiPegawai } from "../../api";
import {
  FilterBulan,
  FilterTahun,
  defaultFilterBulan,
  defaultFilterTahun,
} from "../../components/layouts/TopContent";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { formatDate, saAlert, saConfirm } from "../../helpers";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import LayoutModal from "../../components/layouts/Modal";
import FormTextarea from "../../components/forms/Textarea";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import { titleState } from "../../storage/pages";

const AbsensiPegawai = () => {
  const user = useRecoilValue(userState);
  const loginMaster = useRecoilValue(loginMasterState);

  const [title, setTitle] = useRecoilState(titleState);
  const [absensiPegawai, setAbsensiPegawai] = useState([]);

  const [bulan, setBulan] = useState(defaultFilterBulan);
  const [tahun, setTahun] = useState(defaultFilterTahun);

  const fetchData = useCallback(async () => {
    const res = await showAbsensiPegawai(
      user.id,
      `page=all&bulan=${bulan}&tahun=${tahun}`
    );
    setAbsensiPegawai(res.data.absensi_pegawai);
  }, [user.id, bulan, tahun]);

  useEffect(() => {
    setTitle("Lihat Ijin Absensi");
    fetchData();
  }, [fetchData]);

  return (
    <div>
      <AbsensiCreate onFetchData={fetchData} />

      <div className="row mb-2">
        <div className="col-md">
          <FilterBulan all={true} onChange={(res) => setBulan(res)} />
        </div>
        <div className="col-md">
          <FilterTahun onChange={(res) => setTahun(res)} />
        </div>
      </div>

      <div className="row my-3">
        <div className="col-lg-4">
          <h6>Jenis ikon absensi :</h6>
          <table className="table table-sm table-bordered mb-0">
            <tbody>
              <tr>
                <td className="py-0 border-secondary text-center"><i className="fa fa-lg fa-fw fa-right-long text-success-2"/></td>
                <td className="py-0 border-secondary">Absen Datang</td>
              </tr>
              <tr>
                <td className="py-0 border-secondary text-center"><i className="fa fa-lg fa-fw fa-right-long text-danger-2"/></td>
                <td className="py-0 border-secondary">Absen Pulang</td>
              </tr>
              <tr>
                <td className="py-0 border-secondary text-center"><i className="fa fa-lg fa-fw fa-right-long text-success-2"/><i className="fa fa-lg fa-fw fa-left-long text-danger-2"/></td>
                <td className="py-0 border-secondary">Absen Datang & Pulang</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {absensiPegawai && absensiPegawai.length > 0 ? (
        <TableNew>
          <Thead>
            <Tr className="text-center text-nowrap">
              <Th>No</Th>
              {/* <Th>Pegawai</Th> */}
              <Th>Atasan</Th>
              <Th>Jenis</Th>
              <Th>Tanggal</Th>
              <Th>Jam Datang</Th>
              <Th>Jam Pulang</Th>
              <Th>Keterangan</Th>
              <Th>Status</Th>
              <Th>
                <i className="fa fa-fw fa-cog"></i>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {absensiPegawai.map((ap, index) => {
              return (
                <Tr key={index}>
                  <Td className="text-center align-middle">{1 + index}</Td>
                  {/* <Td>{ap.pegawai && ap.pegawai.nama}</Td> */}
                  <Td>{ap.atasan && ap.atasan.nama}</Td>
                  <Td className="align-middle text-center">
                    {parseInt(ap.jenis) === 1 && <span className="fw-semibold text-success-2"><i className="fa fa-lg fa-right-long"/></span>}
                    {parseInt(ap.jenis) === 2 && <span className="fw-semibold text-danger-2"><i className="fa fa-lg fa-left-long"/></span>}
                    {parseInt(ap.jenis) === 3 && <span className="fw-semibold"><i className="fa fa-lg fa-right-long text-success-2"/><i className="fa fa-lg fa-left-long text-danger-2"/></span>}
                  </Td>
                  <Td className="align-middle text-center">{formatDate(ap.tanggal)}</Td>
                  <Td className="align-middle text-center">{ap.jam_datang || '-'}</Td>
                  <Td className="align-middle text-center">{ap.jam_pulang || '-'}</Td>
                  <Td className="align-middle">{ap.keterangan}</Td>
                  <Td className="text-center align-middle">
                    {ap.status === null && (
                      <span className="fw-semibold text-dark">Menunggu...</span>
                    )}
                    {parseInt(ap.status) === 1 && (
                      <span className="fw-semibold text-success-2">Disetujui</span>
                    )}
                    {parseInt(ap.status) === 2 && (
                      <span className="fw-semibold text-danger-2">Ditolak</span>
                    )}
                  </Td>
                  <Td className="text-nowrap align-middle text-center">
                    {ap.status === null ? (
                      <>
                        <EditAbsensiPegawai
                          id={ap.id}
                          ap={ap}
                          onFetchData={fetchData}
                        />
                        <DeleteAbsensiPegawai
                          id={ap.id}
                          onFetchData={fetchData}
                        />
                      </>
                    ) : (
                      <>
                        {loginMaster ? (
                          <>
                            <EditAbsensiPegawai
                              id={ap.id}
                              ap={ap}
                              onFetchData={fetchData}
                            />
                            <DeleteAbsensiPegawai
                              id={ap.id}
                              onFetchData={fetchData}
                            />
                          </>
                        ) : (
                          "Done"
                        )}
                      </>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </TableNew>
      ) : (
        "No Data"
      )}
    </div>
  );
};

const DeleteAbsensiPegawai = (props) => {
  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus data cuti ?").then((res) => {
      if (res.isConfirmed) {
        deleteAbsensiPegawai(props.id).then((res) => {
          if (res.data && res.data.status === "success") {
            props.onFetchData();
            saAlert(res.data.status, res.data.msg);
          }
        });
      }
    });
  };
  return (
    <button
      onClick={handleDelete}
      className="btn btn-sm btn-danger-2 text-sm py-0 px-1"
    >
      <i className="fa fa-fw fa-sm fa-trash-alt"/>
    </button>
  );
};

export const EditAbsensiPegawai = (props) => {
  const {id, ap, onFetchData} = props;
  const tagForm = useRef(null);

  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  // const handleChange = (e) => {
  //   setForm((prevState) => {
  //     return { ...prevState, [e.target.name]: e.target.value };
  //   });
  // };

  const handleOpen = () => {
    setForm(ap);
    setModal(true);
  }

  const handleClose = () => {
    setForm({});
    setErrors({});
    tagForm.current.reset();
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await patchAbsensiPegawai(form.id, { ...form, on_edit: 1 });
      if (res.data && res.data.status === "success") {
        saAlert(res.data.status, res.data.msg);
        props.onFetchData();
        console.log(res.data);
        handleClose();
      }
    } catch (err) {
      let res = err.response;
      if (res) {
        if (res.status === 422) {
          setErrors(res.data.errors);
        }
      }
    }
  };

  return (
    <>
      <button
        className="btn btn-sm btn-success-2 py-0 px-1 text-sm me-1"
        onClick={handleOpen}
      >
        <i className="fa fa-fw fa-sm fa-pen"/>
      </button>
      <LayoutModal
        size="md"
        title="Edit Absensi Pegawai"
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
              <FormSelect
                label="jenis"
                name="jenis"
                value={form.jenis}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.jenis}
              >
                <option value="">Pilih</option>
                <option value="1">Datang</option>
                <option value="2">Pulang</option>
                <option value="3">Datang & Pulang</option>
              </FormSelect>
              <FormInput
                label="tanggal"
                type="date"
                name="tanggal"
                value={form.tanggal}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.tanggal}
              />
            {form.jenis && parseInt(form.jenis) !== 2 && (
                <FormInput
                  label="jam datang"
                  type="time"
                  name="jam_datang"
                  value={form.jam_datang}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return { ...prevState, [e.target.name]: e.target.value };
                    })
                  }
                  error={errors.jam_datang}
                />
            )}
            {form.jenis && parseInt(form.jenis) !== 1 && (
                <FormInput
                  label="jam pulang"
                  type="time"
                  name="jam_pulang"
                  value={form.jam_pulang}
                  onChange={(e) =>
                    setForm((prevState) => {
                      return { ...prevState, [e.target.name]: e.target.value };
                    })
                  }
                  error={errors.jam_pulang}
                />
            )}
              <FormTextarea
                label="keterangan"
                name="keterangan"
                value={form.keterangan}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.keterangan}
              />
            <div className="btn-group w-100">
              <button type="submit" className="btn btn-sm btn-success-2">
                Simpan perubahan
              </button>
              <button
                type="button"
                className="btn btn-sm btn-dark border-0"
                onClick={handleClose}
              >
                <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
              </button>
            </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default AbsensiPegawai;
