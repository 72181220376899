import React, { useState, useRef } from "react";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import FormInput from "../../components/forms/Input";
import { patchJabatan } from "../../api";
import { saAlert } from "../../helpers";
import FormSelect from "../../components/forms/Select";
import FormCheckbox from "../../components/forms/Checkbox";
import SmallButton from "../../components/forms/SmallButton";

const JabatanEdit = (props) => {

  const {jabatan, dataJabatan, dataCabang, onFetchData, direktorat, divisi, departemen, pelaksana} = props;

  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const [isLoaded, setIsLoaded] = useState(false);

  const handleOpen = () => {
    setModal(true);
    setForm(jabatan);
    setGroup(
      jabatan.group_jabatan && jabatan.group_jabatan.length > 0
        ? jabatan.group_jabatan.map((g) => g.group_id)
        : []
    );
    setTimeout(() => {
      setIsLoaded(true);
    }, 500);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
    setForm({});
    setErrors({});
    setGroup([]);
    setIsLoaded(false);
  };

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const [group, setGroup] = useState([]);

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      setGroup((prevState) => {
        return [...prevState, e.target.value];
      });
    } else {
      setGroup((prevState) => {
        return prevState.filter(
          (ps) => parseInt(ps) !== parseInt(e.target.value)
        );
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      const res = await patchJabatan(form.id, { ...form, group });
      if (res.data && res.data.status) {
        onFetchData();
        handleClose();
        saAlert(res.data.status, res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <>
      <SmallButton margin="me-1" type="edit" onClick={handleOpen} text="Edit"/>
      
      <LayoutModal
        title="Ubah Data Jabatan"
        modal={modal}
        onClose={handleClose}
      >
        {isLoaded ? (
          <form onSubmit={handleSubmit} ref={tagForm}>
            <div className="row">
              <div className="col-md-3">
                <FormSelect
                  label="Cabang"
                  name="cabang_id"
                  value={form.cabang_id}
                  onChange={handleChange}
                  error={errors.cabang_id}
                >
                  <option value="">Pilih</option>
                  {dataCabang &&
                    dataCabang.length > 0 &&
                    dataCabang.map((dc, index) => {
                      return (
                        <React.Fragment key={index}>
                          <option value={dc.id}>{dc.nama}</option>
                        </React.Fragment>
                      );
                    })}
                </FormSelect>
              </div>
              <div className="col-md-6">
                <FormInput
                  label="Jabatan"
                  name="nama"
                  value={form.nama}
                  onChange={handleChange}
                  error={errors.nama}
                />
              </div>
              <div className="col-md-3">
                <FormSelect
                  label="Grade"
                  name="grade"
                  value={form.grade}
                  onChange={handleChange}
                  error={errors.grade}
                >
                  <option value="">Pilih</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </FormSelect>
              </div>
              <div className="col-md-4">
              <FormSelect
                label="Direktorat"
                name="direktorat_id"
                value={form.direktorat_id ? form.direktorat_id : ''}
                onChange={handleChange}
                error={errors.direktorat_id}
              >
                <option value="">Pilih</option>
                {direktorat.map((d, index) => {
                  return (
                    <option key={index} value={d.id}>{d.nama}</option>
                  )
                })}
              </FormSelect>
            </div>
            <div className="col-md-4">
              <FormSelect
                label="Divisi"
                name="divisi_id"
                value={form.divisi_id ? form.divisi_id : ''}
                onChange={handleChange}
                error={errors.divisi_id}
              >
                <option value="">Pilih</option>
                {divisi.map((d, index) => {
                  return (
                    <option key={index} value={d.id}>{d.nama}</option>
                  )
                })}
              </FormSelect>
            </div>
            <div className="col-md-4">
              <FormSelect
                label="Departemen"
                name="departemen_id"
                value={form.departemen_id ? form.departemen_id : ''}
                onChange={handleChange}
                error={errors.departemen_id}
              >
                <option value="">Pilih</option>
                {departemen.map((d, index) => {
                  return (
                    <option key={index} value={d.id}>{d.nama}</option>
                  )
                })}
              </FormSelect>
            </div>
            <div className="col-md-4">
              <FormSelect
                label="Pelaksana"
                name="pelaksana_id"
                value={form.pelaksana_id ? form.pelaksana_id : ''}
                onChange={handleChange}
                error={errors.pelaksana_id}
              >
                <option value="">Pilih</option>
                {pelaksana.map((p, index) => {
                  return (
                    <option key={index} value={p.id}>{p.nama}</option>
                  )
                })}
              </FormSelect>
            </div>
              
            </div>
            <div className="row my-2">
              <NewSubmitButton onClose={handleClose}/>
            </div>
          </form>
        ) : (
          "Loading . . ."
        )}
      </LayoutModal>
    </>
  );
};

export default JabatanEdit;
