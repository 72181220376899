import React, { useRef, useState } from 'react'
import { LayoutAddBtn } from '../../components/layouts/TopContent'
import LayoutModal, { NewSubmitButton } from '../../components/layouts/Modal';
import FormTextarea from '../../components/forms/Textarea';
import FormInput from '../../components/forms/Input';
import { postPerusahaanAlumni } from '../../api';
import { saAlert } from '../../helpers';

function CreatePerusahaanAlumni({fetchData}) {

    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const formRef = useRef(null);

    const handleOpen = () => {
        setModal(true);
    }

    const handleClose = () => {
        if (formRef.current) {
            formRef.current.reset();
        }
        setForm({});
        setErrors({});
        setModal(false);
    }

    const handleChange = (e) => {
        setForm((prevState) => ({...prevState, [e.target.name] : e.target.value}))
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await postPerusahaanAlumni(form);
            if (res.data.status === "success") {
                handleClose();
                fetchData();
                saAlert(res.data.status, res.data.message);
            }
        } catch (error) {
            console.log(error.response);
            setErrors(error.response?.data?.errors);
        }
    }

  return (
    <>
        <LayoutAddBtn onClick={handleOpen} add="Tambah Perusahaan"/>

        <LayoutModal title="Tambah Perusahaan" modal={modal} onClose={handleClose}>
            <form ref={formRef} onSubmit={handleSubmit} className='row'>
                <div className='col-lg-6'>
                    <FormInput
                        label='Nama Perusahaan'
                        type='text'
                        name='nama'
                        onChange={handleChange}
                        error={errors.nama}
                    />
                    <FormTextarea
                        label='Alamat'
                        name='alamat'
                        row={5}
                        onChange={handleChange}
                        error={errors.alamat}
                    />
                </div>
                <div className='col-lg-6'>
                    <FormInput
                        label='No Telepon'
                        type='text'
                        name='no_telf'
                        onChange={handleChange}
                        error={errors.no_telf}
                    />
                    <FormInput
                        label='No HP'
                        type='text'
                        name='no_hp'
                        onChange={handleChange}
                        error={errors.no_hp}
                    />
                    <FormInput
                        label='Email'
                        type='email'
                        name='email'
                        onChange={handleChange}
                        error={errors.email}
                    />
                    <FormInput
                        label='PIC'
                        type='text'
                        name='pic'
                        onChange={handleChange}
                        error={errors.pic}
                    />
                </div>
                <NewSubmitButton className="mt-4" onClose={handleClose}/>
            </form>
        </LayoutModal>
    </>
  )
}

export default CreatePerusahaanAlumni