import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import FormInput from "../../../components/forms/Input";
import FormTextarea from "../../../components/forms/Textarea";
import PageLoading from "../../../components/pages/Loading";
import { getCurrency, saAlert } from "../../../helpers";
import { patchPurchaseRequest, showPurchaseRequest } from "../apis";
import { getStatus } from "../helpers";
import SectionList from "./partials/SectionList";

const Edit = ({ mainUrl }) => {
  const { id } = useParams();

  const [form, setForm] = useState({});
  const [dataList, setDataList] = useState([]);
  const [jumlah, setJumlah] = useState(0);

  const [errors, setErrors] = useState({});

  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await showPurchaseRequest(id);
        if (res.data && res.data.purchase_request) {
          setForm(res.data.purchase_request);
          setJumlah(res.data.purchase_request.jumlah);
          if (res.data.purchase_request.purchase_request_list) {
            setDataList(res.data.purchase_request.purchase_request_list);
          }
        }
      } catch (err) {
        console.error(err.response);
      }
    };

    fetchData();
  }, [id]);

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    try {
      const res = await patchPurchaseRequest(id, { ...form, dataList });
      if (res.data && res.data.msg) {
        history.push(mainUrl);
        saAlert("success", res.data.msg);
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  if (form.id) {
    return (
      <form onSubmit={handleSubmit}>
        <h3>Ubah Data</h3>
        <p>Status Request anda saat ini = {getStatus(form.status)}</p>
        <div className="row">
          <div className="col-6">
            <FormTextarea
              label="Keperluan"
              name="untuk"
              value={form.untuk}
              onChange={handleChange}
            />
          </div>
          <div className="col-6">
            <FormInput
              type="date"
              label="Tanggal Dibutuhkkan"
              name="tanggal_dibutuhkan"
              value={form.tanggal_dibutuhkan}
              onChange={handleChange}
            />
          </div>
        </div>

        <SectionList
          errors={errors}
          dataList={dataList}
          setDataList={(res) => setDataList(res)}
          setJumlah={setJumlah}
        />

        <h2>Jumlah Akhir = Rp {getCurrency(jumlah)}</h2>

        <button type="submit" className="btn px-5 btn-success">
          Ubah
        </button>
      </form>
    );
  } else {
    return <PageLoading />;
  }
};

export default Edit;
