import React, { useRef, useState } from "react";
import { patchBukuPerpustakaan } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import { getOrigin, saAlert } from "../../helpers";

const BukuPerpustakaanEdit = (props) => {
  const { bukuPerpustakaan = {}, jurusan, onFetchData } = props;
  const [modal, setModal] = useState(false);
  const [img, setImg] = useState("");
  const formRef = useRef(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setForm(bukuPerpustakaan);
    setModal(true);
  };

  const handleChange = (e, file = false) => {
    if (file) {
      setForm((prevState) => {
        return { ...prevState, [e.target.name]: e.target.files[0] };
      });
    } else {
      setForm((prevState) => {
        return { ...prevState, [e.target.name]: e.target.value };
      });
    }
  };

  const handleClose = () => {
    if (formRef && formRef.current) {
      formRef.current.reset();
    }

    setForm({});
    setImg("");
    setErrors({});
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    let formData = new FormData();

    formData.append("_method", "PATCH");
    form.kode && formData.append("kode", form.kode);
    form.judul && formData.append("judul", form.judul);
    form.cover !== bukuPerpustakaan.cover && formData.append("cover", form.cover);
    form.jurusan_id && formData.append("jurusan_id", form.jurusan_id);
    form.penerbit && formData.append("penerbit", form.penerbit);
    form.pengarang && formData.append("pengarang", form.pengarang);
    form.halaman && formData.append("halaman", form.halaman);

    try {
      const res = await patchBukuPerpustakaan(form.id, formData);
      console.log(res.data);
      if (res.data === "success") {
        onFetchData();
        handleClose();
        saAlert("success", "Berhasil ubah data buku perpustakaan !");
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  return (
    <>
      <button
        className="btn btn-sm btn-success-2 text-sm py-0 px-1 me-1"
        onClick={handleOpen}
        type="button"
      >
        <i className="fa fa-fw fa-sm fa-pen"/>
      </button>
      <LayoutModal
        title={`Ubah data buku perpustakaan ${bukuPerpustakaan.kode}`}
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={formRef}>
          <div className="row">
            <div className="col-6 d-flex align-items-end">
              <div className="w-100">
                <FormInput
                  label="Kode"
                  name="kode"
                  value={form.kode}
                  onChange={(e) => handleChange(e)}
                  error={errors.kode}
                />
              </div>
            </div>
            <div className="col-6">
              {img ? (
                <img
                  src={URL.createObjectURL(img)}
                  alt=""
                  className="img-fluid"
                />
              ) : (
                bukuPerpustakaan.cover && (
                  <img
                    src={`${getOrigin()}/storage/buku_perpustakaan/${
                      bukuPerpustakaan.cover
                    }`}
                    alt=""
                    className="img-fluid"
                  />
                )
              )}
              <FormInput
                label="Cover"
                type="file"
                name="cover"
                onChange={(e) => {
                  handleChange(e, true);
                  setImg(e.target.files[0]);
                }}
                error={errors.cover}
              />
            </div>
            <div className="col-6">
              <FormInput
                label="Judul"
                name="judul"
                value={form.judul}
                onChange={handleChange}
                error={errors.judul}
              />
            </div>
            <div className="col-6">
              <FormSelect
                label="Jurusan"
                name="jurusan_id"
                value={form.jurusan_id}
                onChange={handleChange}
                error={errors.jurusan_id}
              >
                <option value="">Pilih</option>
                {jurusan &&
                  jurusan.length > 0 &&
                  jurusan.map((i, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={i.id}>{i.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col-6">
              <FormInput
                label="penerbit"
                name="penerbit"
                value={form.penerbit}
                onChange={handleChange}
                error={errors.penerbit}
              />
            </div>
            <div className="col-6">
              <FormInput
                label="pengarang"
                name="pengarang"
                value={form.pengarang}
                onChange={handleChange}
                error={errors.pengarang}
              />
            </div>
            <div className="col-6">
              <FormInput
                label="halaman"
                name="halaman"
                value={form.halaman}
                onChange={handleChange}
                error={errors.halaman}
              />
            </div>
          </div>
          <div className="row mt-3">
            <NewSubmitButton submitText="Simpan Perubahaan" onClose={handleClose}/>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default BukuPerpustakaanEdit;
