import React from "react";

const FormTextarea = (props) => {
  const {
    label,
    className,
    labelClassName,
    type,
    name,
    value,
    placeholder,
    readOnly,
    row,
    onChange,
    error,
    notes,
    onKeyDown,
  } = props;

  return (
    <div className="form-group">
      {label && (
        <label
          className={`fw-semibold ${labelClassName ? labelClassName : 'text-primary'}`}
          dangerouslySetInnerHTML={{ __html: label }}
        ></label>
      )}
      <textarea
        className={`form-control form-control-sm form-control-custom text-black rounded-3 ${
          error ? 'is-invalid' : 'border-silver'
        } ${className}`}
        onKeyUp={onChange}
        onFocus={onChange}
        onPaste={onChange}
        type={type}
        name={name}
        defaultValue={value}
        placeholder={placeholder ? placeholder : 'Ketik disini. . .'}
        readOnly={readOnly}
        rows={row ? row : 4}
        onKeyDown={onKeyDown}
      />
      {notes && (
        <span
          className="text-danger"
          dangerouslySetInnerHTML={{ __html: notes }}
        ></span>
      )}
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default FormTextarea;
