import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { showSkDosen } from "../../../api";
import PrintBtnPrint from "../../../components/print/BtnPrint";
import Header2022 from "../../../components/print/Header2022";
import PrintTtd from "../../../components/print/Ttd";
import { namaBulan } from "../../../helpers";
import PrintLayout, { PrintButton } from "../../../components/layouts/PrintLayout";

const DosenPrintSkDosen = () => {
  const { sk_dosen_id } = useParams();

  const [skDosen, setSkDosen] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await showSkDosen(sk_dosen_id);
        if (res.data && res.data.sk_dosen) {
          setSkDosen(res.data.sk_dosen);
        }
      } catch (err) {
        console.error(err.response);
      }
    };

    fetchData();
  }, [sk_dosen_id]);

  return (
    <>
      <PrintLayout header footer>
      <div className="text-center mb-4">
          <h3 className="font-weight-bold mb-0">SURAT KEPUTUSAN</h3>

          <h5 className="font-weight-bold">
            NOMOR : {skDosen.nomor}
            <br />
            <br />
            TENTANG
            <br />
            PENGANGKATAN TENAGA PENGAJAR KONTRAK PADA <br />
            LEMBAGA PENDIDIKAN DAN MANAJEMEN ALFA PRIMA
          </h5>
        </div>

        <div className="row">
          <div className="col-3 d-flex justify-content-between font-weight-bold">
            MENIMBANG <span>:</span>
          </div>
          <div className="col-9">
            <ol className="px-3">
              <li>
                Bahwa untuk kelancaran proses pembelajaran Semester{" "}
                {skDosen.semester && parseInt(skDosen.semester) === 1
                  ? "Ganjil"
                  : "Genap"}{" "}
                Tahun Akademik{" "}
                {skDosen.tahun_ajaran &&
                  ` ${skDosen.tahun_ajaran}/${
                    parseInt(skDosen.tahun_ajaran) + 1
                  }.`}{" "}
                perlu ditetapkan susunan tenaga pengajar (dosen) kontrak.
              </li>
              <li>
                Bahwa Saudara yang namanya tercantum dalam lampiran Keputusan
                ini memenuhi syarat untuk diangkat sebagai tenaga pengajar
                (dosen) kontrak di Lembaga Manajemen dan Informatika Alfa Prima{" "}
                <span className="text-capitalize">{skDosen.cabang}</span>.
              </li>
            </ol>
          </div>
        </div>

        <div className="row">
          <div className="col-3 d-flex justify-content-between font-weight-bold">
            MENGINGAT <span>:</span>
          </div>
          <div className="col-9">
            <ol className="px-3">
              <li>
                Undang - Undang Nomor 20 Tahun 2003 tentang Sistem Pendidikan
                Nasional{" "}
              </li>
              <li>Kalender Akademik</li>
              <li>Kontrak Dosen Alfa Prima</li>
              <li>Tata Tertib dan Kode Etik Dosen Alfa Prima</li>
            </ol>
          </div>
        </div>

        <div className="row mb-4">
          <div className="col-3 d-flex justify-content-between font-weight-bold">
            MEMPERHATIKAN <span>:</span>
          </div>
          <div className="col-9">
            Keputusan Rapat Direktur Akademik dan Kemahasiswaan, Kepala Cabang
            beserta Ketua Program Studi{" "}
            {skDosen.tanggal &&
              `${skDosen.tanggal.split("-")[2]} ${
                namaBulan[parseInt(skDosen.tanggal.split("-")[1]) - 1]
              } ${skDosen.tanggal.split("-")[0]}`}
          </div>
        </div>

        <h4 className="font-weight-bold text-center">MEMUTUSKAN</h4>

        <div className="row">
          <div className="col-3 d-flex justify-content-between font-weight-bold">
            MENETAPKAN <span>:</span>
          </div>
          <div className="col-9 font-weight-bold">
            KEPUTUSAN KEPALA CABANG ALFA PRIMA{" "}
            {skDosen.cabang && skDosen.cabang.toUpperCase()} TENTANG
            PENGANGKATAN TENAGA PENGAJAR (DOSEN) KONTRAK PADA SEMESTER{" "}
            {skDosen.semester && parseInt(skDosen.semester) === 1
              ? "GANJIL"
              : "GENAP"}
            {` `}
            TAHUN AKADEMIK
            {skDosen.tahun_ajaran &&
              ` ${skDosen.tahun_ajaran}/${parseInt(skDosen.tahun_ajaran) + 1}.`}
          </div>
        </div>

        <div className="row">
          <div className="col-3 d-flex justify-content-between">
            Pertama <span>:</span>
          </div>
          <div className="col-9">
            Mengangkat Saudara yang namanya tercantum pada butir (1) sebagai
            Tenaga Pengajar Kontrak dalam Mata kuliah pada butir (2) lampiran
            Keputusan ini, pada Semester{" "}
            {skDosen.semester && parseInt(skDosen.semester) === 1
              ? "Ganjil"
              : "Genap"}{" "}
            Tahun Akademik
            {skDosen.tahun_ajaran &&
              ` ${skDosen.tahun_ajaran}/${parseInt(skDosen.tahun_ajaran) + 1}.`}
          </div>
        </div>

        <div className="row">
          <div className="col-3 d-flex justify-content-between">
            Kedua <span>:</span>
          </div>
          <div className="col-9">
            Memberi hak kepada Saudara dimaksud dalam pertama untuk melaksanakan
            fungsi ilmiahnya sesuai dengan ketentuan dan tata tertib
            penyelengaraan proses pembelajaran di Lembaga Pendidikan Manajemen
            dan Informatika Alfa Prima.
          </div>
        </div>

        <div className="row">
          <div className="col-3 d-flex justify-content-between">
            Ketiga <span>:</span>
          </div>
          <div className="col-9">
            Saudara dimaksud dalam pertama diberikan honorarium sesuai ketentuan
            pada butir (3) lampiran keputusan ini yang berlaku di Lembaga
            Pendidikan Manajemen dan Informatika Alfa Prima.
          </div>
        </div>

        <div className="row">
          <div className="col-3 d-flex justify-content-between">
            Keempat <span>:</span>
          </div>
          <div className="col-9">
            Mewajibkan Saudara dimaksud dalam pertama untuk mematuhi semua tata
            tertib tenaga pengajar kontrak Lembaga Pendidikan Manajemen dan
            Informatika Alfa Prima.
          </div>
        </div>

        <div className="row">
          <div className="col-3 d-flex justify-content-between">
            Kelima <span>:</span>
          </div>
          <div className="col-9">
            Surat Keputusan ini berlaku selama Semester{" "}
            {skDosen.semester && parseInt(skDosen.semester) === 1
              ? "Ganjil"
              : "Genap"}{" "}
            Tahun Akademik
            {skDosen.tahun_ajaran &&
              ` ${skDosen.tahun_ajaran}/${parseInt(skDosen.tahun_ajaran) + 1}.`}
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-3 d-flex justify-content-between">
            Keenam <span>:</span>
          </div>
          <div className="col-9">
            Bilamana dikemudian hari ternyata terdapat kekeliruan dalam
            Keputusan ini, akan diadakan perbaikan seperlunya.
          </div>
        </div>

        <div className="row justify-content-end mb-5">
          <div className="col-6">
            <PrintTtd
              nama={skDosen.nama_kacab}
              jabatan="Kepala Cabang"
              footer={`NIP : ${skDosen.nip_kacab}`}
              cabang={skDosen.cabang}
              tanggal={
                skDosen.tanggal &&
                `${skDosen.tanggal.split("-")[2]} ${
                  namaBulan[parseInt(skDosen.tanggal.split("-")[1]) - 1]
                } ${skDosen.tanggal.split("-")[0]}`
              }
            />
          </div>
        </div>
        <PrintButton/>
      </PrintLayout>
    </>
  );
};

export default DosenPrintSkDosen;
