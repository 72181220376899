import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  LayoutTable,
  LayoutThead,
  TableNoData,
} from "../../../components/layouts/Table";
import { LayoutAddBtn } from "../../../components/layouts/TopContent";
import SectionPagination from "../../../components/sections/Pagination";
import { formatDate } from "../../../helpers";
import { userState } from "../../../storage/auth";
import { getStatusItems } from "../helpers";
import { PurchaseRequestContext } from "../Routes";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../../components/layouts/TableNew";

const PurchaseRequest = ({ mainUrl, onFetchData }) => {
  const { isLoaded, purchaseRequest, setPage } = useContext(
    PurchaseRequestContext
  );

  const user = useRecoilValue(userState);
  const [edit, setEdit] = useState(true);

  useEffect(() => {
    onFetchData({ pegawai_id: user.id });
  }, [onFetchData, user.id]);

  const handleFetchData = (you) => {
    if (you) {
      setEdit(true);
      onFetchData({ pegawai_id: user.id });
    } else {
      setEdit(false);
      onFetchData();
    }
  };

  return (
    <>
      <div>
        <button onClick={() => handleFetchData(0)} className="btn btn-sm rounded-3 btn-primary-3 mx-1">
          <i className="fa fa-fw fa-sm fa-eye"/> Semua Purchase Request
        </button>
        <button onClick={() => handleFetchData(1)} className="btn btn-sm rounded-3 btn-success-2 mx-1">
        <i className="fa fa-fw fa-sm fa-eye"/> Purchase Request Anda
        </button>
      </div>
      <h6 className="mt-3 mb-0">
        Untuk melihat price list yang tersedia silahkan{" "}
        <span
          type="button"
          className="link-primary-decoration"
          onClick={() =>
            window.open(
              "https://docs.google.com/spreadsheets/d/1DFE-7xHdIiAXMeLyFhuVsNF3dXsiv4Tz/edit?usp=sharing&ouid=101259796023283782863&rtpof=true&sd=true"
            )
          }
        >
          klik disini
        </span>
      </h6>
      <LayoutAddBtn add="Tambah Purchase Request" link={`${mainUrl}/create`} />
      <TableNew>
        <Thead>
          <Tr className="text-center">
            <Th>No</Th>
            <Th>Nama</Th>
            <Th>Keperluan</Th>
            <Th>Tanggal Request</Th>
            <Th>Tanggal Dibutuhkan</Th>
            <Th>Jumlah</Th>
            <Th>Approval Atasan</Th>
            <Th>Approval Kacab</Th>
            <Th>Approval Dir</Th>
            <Th>Items</Th>
            {edit && <Th>bars</Th>}
          </Tr>
        </Thead>
        <Tbody>
          {isLoaded &&
          purchaseRequest.data &&
          purchaseRequest.data.length > 0 ? (
            purchaseRequest.data.map((pr, index) => {
              return (
                <Tr key={index}>
                  <Td className="align-middle text-center">
                    {purchaseRequest.from + index}
                  </Td>
                  <Td className="align-middle">
                    {pr.pegawai && pr.pegawai.nama}
                  </Td>
                  <Td className="align-middle">{pr.untuk}</Td>
                  <Td className="align-middle text-center">
                    {formatDate(pr.tanggal_request)}
                  </Td>
                  <Td className="align-middle text-center">
                    {formatDate(pr.tanggal_dibutuhkan)}
                  </Td>
                  <Td className="align-middle text-center">{pr.jumlah}</Td>
                  <Td className="align-middle text-center">
                    {parseInt(pr.status) === 1 && (
                      <span className="fw-semibold text-success-2">Approved</span>
                    )}
                    {parseInt(pr.status) === 9 && (
                      <span className="fw-semibold text-danger-2">Reject</span>
                    )}
                    {!pr.status && "-"}
                  </Td>
                  <Td className="align-middle text-center">
                    {parseInt(pr.approval_kacab) === 1 && (
                      <span className="fw-semibold text-success-2">Approved</span>
                    )}
                    {parseInt(pr.approval_kacab) === 9 && (
                      <span className="fw-semibold text-danger-2">Reject</span>
                    )}
                    {!pr.approval_kacab && "-"}
                  </Td>
                  <Td className="align-middle text-center">
                    {parseInt(pr.approval_dir) === 1 && (
                      <span className="fw-semibold text-success-2">Approved</span>
                    )}
                    {parseInt(pr.approval_dir) === 9 && (
                      <span className="fw-semibold text-danger-2">Reject</span>
                    )}
                    {!pr.approval_dir && "-"}
                  </Td>
                  <Td className="text-center">
                    {getStatusItems(pr.purchase_request_list)}
                  </Td>
                  {edit && (
                    <Td className="align-middle text-center">
                      <Link
                        className="mx-1 btn btn-sm btn-success-2 text-sm py-0 px-1"
                        to={`${mainUrl}/${pr.id}/edit`}
                      >
                        <i className="fa fa-fw fa-sm fa-pen"/>
                      </Link>
                    </Td>
                  )}
                </Tr>
              );
            })
          ) : (
            <TableNoData col={11} isLoaded={isLoaded} />
          )}
        </Tbody>
      </TableNew>
      <SectionPagination
        links={purchaseRequest.links}
        from={purchaseRequest.from}
        to={purchaseRequest.to}
        total={purchaseRequest.total}
        onChangePage={(res) => setPage(res)}
      />
    </>
  );
};

export default PurchaseRequest;
