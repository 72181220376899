import React, { useRef } from "react";
import { useState } from "react";
import { renderNim } from "../../api";
import LayoutModal from "../../components/layouts/Modal";
import PageLoading from "../../components/pages/Loading";
import { saConfirm } from "../../helpers";

const PendaftarSetMhs = (props) => {
  const [modal, setModal] = useState(false);
  const tagForm = useRef();
  const [form, setForm] = useState({});

  const handleClose = () => {
    if (tagForm.current) {
      tagForm.current.reset();
    }
    if (form.nim) {
      props.onFetchData();
    }
    setForm({});
    setModal(false);
  };

  const handleUpdate = (mhs) => {
    saConfirm(
      "warning",
      "Yakin jadikan pendaftar ini menjadi mahasiswa aktif ?",
      "Pilih lanjut untuk membuatkan pendaftar ini NIM baru"
    ).then((res) => {
      if (res.isConfirmed) {
        renderNim({ id: mhs.id })
          .then((res) => {
            console.log(res);
            if (res.data.nim) {
              setForm(res.data);
              setModal(true);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    });
  };

  return (
    <>
      <button 
        className="btn btn-sm btn-primary-3 border-0 py-0 text-sm rounded"
        onClick={() => handleUpdate(props.mhs)}
      >
        <span><i className="fa fa-fw fa-sm fa-turn-up"/> Mhs Aktif</span>
      </button>

      <LayoutModal
        title="Update status menjadi Mhs Aktif"
        modal={modal}
        onClose={handleClose}
      >
        {form.nim ? (
          <form ref={tagForm} onSubmit={handleUpdate} className="row">
            <div className="form-group col-3">
              <label>Cabang</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.cabang}
              />
            </div>
            <div className="form-group col-3">
              <label>NIM (Baru)</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.nim}
              />
            </div>
            <div className="form-group col-6">
              <label>Nama</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.nama}
              />
            </div>
            <div className="form-group col-3">
              <label>Tempat Lahir</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.tempat}
              />
            </div>
            <div className="form-group col-3">
              <label>Tanggal Lahir</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.tanggal}
              />
            </div>
            <div className="form-group col-3">
              <label>Jenis Kelamin</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.jenis}
              />
            </div>
            <div className="form-group col-3">
              <label>Agama</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.agama}
              />
            </div>
            <div className="form-group col-2">
              <label>Angkatan</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.angkatan}
              />
            </div>
            <div className="form-group col-2">
              <label>Jenjang</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.jenjang}
              />
            </div>
            <div className="form-group col-2">
              <label>Jurusan ID</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.jurusan_id}
              />
            </div>
            <div className="form-group col-6">
              <label>Jurusan</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.jurusan}
              />
            </div>
            <div className="form-group col-3">
              <label>Waktu Belajar</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.waktu_belajar}
              />
            </div>
            <div className="form-group col-3">
              <label>Semester</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.semester}
              />
            </div>
            <div className="form-group col-3">
              <label>No HP</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.no_hp}
              />
            </div>
            <div className="form-group col-3">
              <label>No HP Orang Tua</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.no_hp_ortu}
              />
            </div>
            <div className="form-group col-3">
              <label>Nama Ayah</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.nama_ayah}
              />
            </div>
            <div className="form-group col-3">
              <label>Nama Ibu</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.nama_ibu}
              />
            </div>
            <div className="form-group col-6">
              <label>Alamat Orang Tua</label>
              <textarea
                type="text"
                className="form-control"
                readOnly={true}
                rows={3}
                defaultValue={form.alamat_ortu}
              />
            </div>
            <div className="form-group col-4">
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.email}
              />
            </div>
            <div className="form-group col-4">
              <label>Facebook</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.fb}
              />
            </div>
            <div className="form-group col-4">
              <label>Instagram</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.ig}
              />
            </div>
            <div className="form-group col-4">
              <label>Asal Sekolah</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.asal_sekolah}
              />
            </div>
            <div className="form-group col-4">
              <label>Tahun Tamat</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.tahun_tamat}
              />
            </div>
            <div className="form-group col-4">
              <label>Jurusan SMA</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.jurusan_sma}
              />
            </div>
            <div className="form-group col-4">
              <label>Informasi</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.informasi}
              />
            </div>
            <div className="form-group col-4">
              <label>Keputusan Kuliah</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.keputusan_kuliah}
              />
            </div>
            <div className="form-group col-4">
              <label>Refrensi</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.refrensi}
              />
            </div>
            <div className="form-group col-4">
              <label>Tanggal Bergabung</label>
              <input
                type="date"
                className="form-control"
                readOnly={true}
                defaultValue={form.tanggal_bergabung}
              />
            </div>
            <div className="form-group col-4">
              <label>KDBB</label>
              <input
                type="text"
                className="form-control"
                readOnly={true}
                defaultValue={form.kdbb === 1 ? "Iya" : "Tidak"}
              />
            </div>
            <div className="col-12">
              <button
                onClick={handleClose}
                type="button"
                className="btn btn-sm btn-secondary"
              >
                Tutup
              </button>
            </div>
          </form>
        ) : (
          <PageLoading title="Rendering NIM, please wait . . ." />
        )}
      </LayoutModal>
    </>
  );
};

export default PendaftarSetMhs;
