import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FormInput from "../../../components/forms/Input";
import { saAlert } from "../../../helpers";
import { postCabang } from "../apis";

const Create = ({ mainUrl, onFetchData }) => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const history = useHistory();

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await postCabang(form);
      if (res.data && res.data.msg) {
        onFetchData();
        history.push(mainUrl);
        saAlert("success", res.data.msg);
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };
  return (
    <>
      <form className="row justify-content-center" onSubmit={handleSubmit}>
        <div className="col-lg-4">
          <div className="bg-gradient-primary text-white rounded-3 p-3">
            <h5 className="mb-3 fw-semibold text-warning text-center">Tambah Cabang</h5>
            <FormInput
              placeholder="Nama Cabang"
              name="nama"
              onChange={handleChange}
              error={errors.nama}
            />
            <div className="btn-group w-100">
              <button type="submit" className="btn btn-sm btn-success-2 rounded-start-3">
                Simpan
              </button>
              <Link type="button" className="btn btn-sm btn-dark border-0 rounded-end-3" to={mainUrl}>
                <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
              </Link>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Create;
