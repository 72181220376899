import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.min.js'
import './sb-admin-2.min.css'
import 'animate.css/animate.min.css'
import './style.css';
import IsPegawai from "./middleware/IsPegawai";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Routes from "./routes";
import { RecoilRoot } from "recoil";
import Akreditasi from "./views/Akreditasi";
require('dotenv').config()

const App = () => {
  const [isAkreditasi, setIsAkreditasi] = useState(true);

  useEffect(() => {
    if (window.location.pathname === "/akreditasi") {
      setIsAkreditasi(true);
    } else {
      setIsAkreditasi(false);
    }
  }, []);

  return (
    <>
      <RecoilRoot>
        <Router>
          {isAkreditasi ? (
            <Switch>
              <Route exact path="/akreditasi">
                <Akreditasi />
              </Route>
            </Switch>
          ) : (
            <IsPegawai>
              <Routes />
            </IsPegawai>
          )}
        </Router>
      </RecoilRoot>
    </>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
