import React, { useRef, useState } from "react";
import { patchMhs } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";
import SmallButton from "../../components/forms/SmallButton";

const MhsEdit = (props) => {
  const { mhs, user, jurusan, onFetchData } = props;
  const tagForm = useRef();
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    if (tagForm && tagForm.current) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    patchMhs(form.id, form)
      .then((res) => {
        if (res.data === "success") {
          onFetchData();
          handleClose();
          saAlert("success", "Berhasil update data Mahasiswa !");
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            setErrors(err.response.data.errors);
          }
        }
      });
  };

  return (
    <>
      <SmallButton
        margin='me-1'
        type='edit'
        onClick={() => {
          setForm(mhs);
          setModal(true);
        }}
      />
      <LayoutModal
        modal={modal}
        onClose={handleClose}
        title="Data Mahasiswa Baru"
      >
        <form ref={tagForm} onSubmit={handleSubmit}>
          <div className="row">
            {user && user.jabatan === "IT Programmer" && (
              <div className="col">
                <FormInput
                  label="Status"
                  name="status"
                  value={form.status}
                  onChange={(e) => {
                    setForm((prevState) => {
                      return { ...prevState, [e.target.name]: e.target.value };
                    });
                  }}
                  error={errors.status}
                />
              </div>
            )}
            <div className="col">
              <FormInput label="Cabang" value={form.cabang} readOnly={true} />
            </div>
            <div className="col">
              <FormInput label="NIM" value={form.nim} readOnly={true} />
            </div>
          </div>
          <FormInput
            label="Nama"
            name="nama"
            value={form.nama}
            onChange={(e) => {
              if (user.jabatan === "IT Programmer") {
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                });
              }
            }}
            error={errors.nama}
            readOnly={user.jabatan === "IT Programmer" ? false : true}
          />
          <div className="row">
            <div className="col">
              <FormInput
                label="Tempat Lahir"
                name="tempat"
                value={form.tempat}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.tempat}
              />
            </div>
            <div className="col">
              <FormInput
                label="Tanggal Lahir"
                type="date"
                name="tanggal"
                value={form.tanggal}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.tanggal}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormSelect
                label="Jenis Kelamin"
                name="jenis"
                value={form.jenis}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.jenis}
              >
                <option value="Laki-laki">Laki-laki</option>
                <option value="Perempuan">Perempuan</option>
              </FormSelect>
            </div>
            <div className="col">
              <FormSelect
                label="Agama"
                name="agama"
                value={form.agama}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.agama}
              >
                <option value="Islam">Islam</option>
                <option value="Katolik">Katolik</option>
                <option value="Protestan">Protestan</option>
                <option value="Hindu">Hindu</option>
                <option value="Budha">Budha</option>
              </FormSelect>
            </div>
            <div className="col">
              <FormInput
                label="Angkatan"
                value={form.angkatan}
                readOnly={true}
              />
            </div>
            <div className="col">
              <FormSelect
                label="Jenjang"
                name="jenjang"
                value={form.jenjang}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.jenjang}
                disabled={true}
              >
                <option value="D1">D1</option>
                <option value="D2">D2</option>
              </FormSelect>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormSelect
                label="Jurusan"
                name="jurusan_id"
                value={form.jurusan_id}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.jurusan_id}
                disabled={true}
              >
                {jurusan &&
                  jurusan.length >= 1 &&
                  jurusan.map((i, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={i.id}>{i.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col">
              <FormSelect
                label="Waktu Belajar"
                name="waktu_belajar"
                value={form.waktu_belajar}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.waktu_belajar}
              >
                <option value="Pagi">Pagi</option>
                <option value="Sore">Sore</option>
              </FormSelect>
            </div>
            <div className="col">
              <FormInput
                label="Semester"
                value={form.semester}
                readOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormInput
                label="No HP"
                name="no_hp"
                value={form.no_hp}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.no_hp}
              />
            </div>
            <div className="col">
              <FormInput
                label="No HP Ortu"
                name="no_hp_ortu"
                value={form.no_hp_ortu}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.no_hp_ortu}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormInput
                label="Nama Ayah"
                name="nama_ayah"
                value={form.nama_ayah}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.nama_ayah}
              />
            </div>
            <div className="col">
              <FormInput
                label="Nama Ibu"
                name="nama_ibu"
                value={form.nama_ibu}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.nama_ibu}
              />
            </div>
          </div>
          <FormTextarea
            label="Alamat Ortu"
            name="alamat_ortu"
            value={form.alamat_ortu}
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.alamat_ortu}
          />
          <div className="row">
            <div className="col">
              <FormInput label="Email" value={form.email} readOnly={true} />
            </div>
            <div className="col">
              <FormInput label="Facebook" value={form.fb} readOnly={true} />
            </div>
            <div className="col">
              <FormInput label="Instagram" value={form.ig} readOnly={true} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormInput
                label="Asal Sekolah"
                value={form.asal_sekolah}
                readOnly={true}
              />
            </div>
            <div className="col">
              <FormInput
                label="Tahun Tamat"
                value={form.tahun_tamat}
                readOnly={true}
              />
            </div>
            <div className="col">
              <FormInput
                label="Jurusan SMA"
                value={form.jurusan_sma}
                readOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormInput
                label="Informasi"
                value={form.informasi}
                readOnly={true}
              />
            </div>
            <div className="col">
              <FormInput
                label="Keputusan Kuliah"
                value={form.keputusan_kuliah}
                readOnly={true}
              />
            </div>
            <div className="col">
              <FormInput
                label="Refrensi"
                value={form.refrensi}
                readOnly={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <FormInput
                label="Tanggal Bergabung"
                value={form.tanggal_bergabung}
                readOnly={true}
              />
            </div>
            <div className="col">
              <FormSelect
                label="KDBB"
                name="kdbb"
                value={form.kdbb}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.kdbb}
                disabled={user.jabatan === "IT Programmer" ? false : true}
              >
                <option value="1">Iya</option>
                <option value="0">Tidak</option>
              </FormSelect>
            </div>
          </div>
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success">
              Ubah
            </button>
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-sm btn-secondary"
            >
              Tutup
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default MhsEdit;
