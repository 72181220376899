import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { LayoutTable, LayoutThead } from "../../../components/layouts/Table";
import { saAlert, saToast } from "../../../helpers";
import { userState } from "../../../storage/auth";
import { patchCeklistMonitoring } from "../apis";
import { CeklistMonitoringContext } from "../Routes";

const AuditorList = () => {
  const { id } = useParams();
  const user = useRecoilValue(userState);

  const { ceklistMonitoring, cabang, departemen, auditor } = useContext(
    CeklistMonitoringContext
  );

  const [data, setData] = useState({});

  useEffect(() => {
    let data =
      ceklistMonitoring.data &&
      ceklistMonitoring.data.length > 0 &&
      ceklistMonitoring.data.filter(
        (cm) =>
          parseInt(cm.id) === parseInt(id) &&
          cm.ceklist_monitoring_list &&
          cm.ceklist_monitoring_list.length > 0
      );

    if (data.length > 0) {
      setData(data[0]);
    }
  }, [setData, ceklistMonitoring.data, id]);

  const [auditee, setAuditee] = useState([]);

  useEffect(() => {
    let data =
      ceklistMonitoring.data &&
      ceklistMonitoring.data.length > 0 &&
      ceklistMonitoring.data.filter((cm) => parseInt(cm.id) === parseInt(id));

    if (data.length > 0) {
      data = data[0].auditee.map((a) => parseInt(a.id));
      setAuditee(data);
    }
  }, [setAuditee, ceklistMonitoring.data, id]);

  const [form, setForm] = useState([]);

  useEffect(() => {
    let data =
      ceklistMonitoring.data &&
      ceklistMonitoring.data.length > 0 &&
      ceklistMonitoring.data.filter(
        (cm) =>
          parseInt(cm.id) === parseInt(id) &&
          cm.ceklist_monitoring_list &&
          cm.ceklist_monitoring_list.length > 0
      );

    if (data.length > 0) {
      data = data[0].ceklist_monitoring_list.map((cml) => cml);
      setForm(data);
    }
  }, [setForm, ceklistMonitoring.data, id]);

  const [fetchingData, setFetchingData] = useState(false);

  const handleChange = async (e, pivot_id) => {
    // setFetchingData(true);
    try {
      const res = await patchCeklistMonitoring(id, {
        ...data,
        auditor_edited: true,
        pivot_id: pivot_id,
        value_cml: { [e.target.name]: e.target.value },
      });
      if (res.data && res.data.status) {
        saToast(res.data.status, res.data.msg, res.data.detail);
        setFetchingData(false);
      } else {
        saAlert("warning", "Gagal simpan data !");
      }
    } catch (err) {
      //
    }
  };

  let timer = 0;
  const handleChangeTextArea = (e, pivot_id) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      handleChange(e, pivot_id);
    }, 4000);
  };

  if (parseInt(user.id) === parseInt(data.auditor_id)) {
    return (
      <div>
        <h1 className="fs-4 text-dark fw-semibold pb-2 mb-2 mx-3 text-center border-bottom border-silver">
          <i className="fa fa-fw fa-pencil" /> Form Monitoring
        </h1>
        <div className="row">
          <div className="col-md-8">
            <h5 className="fw-semibold text-dark">
              <i className="fa fa-fw fa-folder" /> Ceklist Monitoring
            </h5>
            <LayoutTable>
              <LayoutThead className="text-nowrap">
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                  No
                </th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                  Cabang
                </th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                  Departemen
                </th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                  Auditor
                </th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                  Tahun
                </th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                  Tahap
                </th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                  Kode Dokumen
                </th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                  Revisi
                </th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                  Tanggal Efektif
                </th>
              </LayoutThead>
              <tbody>
                {ceklistMonitoring.data &&
                  ceklistMonitoring.data.length > 0 &&
                  ceklistMonitoring.data
                    .filter((cm) => parseInt(cm.id) === parseInt(id))
                    .map((cm, index) => {
                      return (
                        <tr key={index}>
                          <td className="py-1 border-silver text-nowrap text-center">
                            {index + 1}
                          </td>
                          <td className="py-1 border-silver text-nowrap text-center">
                            {cabang.length > 0 &&
                              cabang
                                .filter(
                                  (c) =>
                                    parseInt(c.id) ===
                                    parseInt(cm.cabang_id)
                                )
                                .map((c) => c.nama)}
                          </td>
                          <td className="py-1 border-silver text-nowrap">
                            {departemen.length > 0 &&
                              departemen
                                .filter(
                                  (d) =>
                                    parseInt(d.id) ===
                                    parseInt(cm.departemen_id)
                                )
                                .map((c) => c.nama)}
                          </td>
                          <td className="py-1 border-silver text-nowrap">
                            {auditor.length > 0 &&
                              auditor
                                .filter(
                                  (a) =>
                                    parseInt(a.id) ===
                                    parseInt(cm.auditor_id)
                                )
                                .map((c) => c.nama)}
                          </td>
                          <td className="py-1 border-silver text-nowrap text-center">
                            {cm.tahun}
                          </td>
                          <td className="py-1 border-silver text-nowrap text-center">
                            {cm.tahap}
                          </td>
                          <td className="py-1 border-silver text-nowrap text-center">
                            {cm.kode_dokumen}
                          </td>
                          <td className="py-1 border-silver text-nowrap text-center">
                            {cm.revisi}
                          </td>
                          <td className="py-1 border-silver text-nowrap text-center">
                            {cm.tanggal_efektif}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </LayoutTable>
          </div>
          <div className="col-md-4">
            <h5 className="fw-semibold text-dark">
              <i className="fa fa-fw fa-folder" /> Auditee
            </h5>
            <LayoutTable>
              <LayoutThead>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                  No
                </th>
                <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                  Nama
                </th>
              </LayoutThead>
              <tbody>
                {auditor.length > 0 &&
                  auditor
                    .filter((a) => auditee.includes(parseInt(a.id)))
                    .map((a, index) => {
                      return (
                        <tr key={index}>
                          <td className="py-1 border-silver text-center">
                            {index + 1}
                          </td>
                          <td className="py-1 border-silver">
                            {a.nama}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </LayoutTable>
          </div>
        </div>

        <h5 className="fw-semibold text-dark">
          <i className="fa fa-fw fa-folder" /> Ceklist Monitoring List
        </h5>

        <LayoutTable>
          <LayoutThead tr={true}>
            <tr className="text-center">
              <th
                className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle"
                rowSpan={2}
              >
                No
              </th>
              <th
                className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle"
                rowSpan={2}
              >
                Klausul
              </th>
              <th
                className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle"
                rowSpan={2}
              >
                Deskripsi
              </th>
              <th
                className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle"
                rowSpan={2}
              >
                Refrensi Audit
              </th>
              <th
                className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle"
                colSpan={3}
              >
                Metode
              </th>
              <th
                className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle"
                rowSpan={2}
              >
                Catatan
              </th>
              <th
                className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle"
                colSpan={3}
              >
                Kesimpulan
              </th>
              <th
                className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle"
                rowSpan={2}
              >
                CPAR
              </th>
            </tr>
            <tr className="text-center text-nowrap">
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                Observasi
              </th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                Wawancara
              </th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                Penilaian Berkas
              </th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                Sesuai
              </th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                Tidak Sesuai
              </th>
              <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                Saran
              </th>
            </tr>
          </LayoutThead>
          <tbody>
            {form.map((cml, index) => {
              return (
                <tr key={index}>
                  <td className="py-1 border-silver align-middle text-center">
                    {index + 1}
                  </td>
                  <td className="py-1 border-silver align-middle">
                    {cml.klausul}
                  </td>
                  <td className="py-1 border-silver align-middle">
                    {cml.deskripsi}
                  </td>
                  <td className="py-1 border-silver align-middle">
                    {cml.refrensi_audit}
                  </td>
                  <td className="py-1 border-silver align-middle text-center">
                    <select
                      className="form-select form-select-sm form-select-custom py-0 text-sm fw-semibold border-silver rounded w-auto mx-auto"
                      name="o"
                      defaultValue={cml.pivot.o || ""}
                      onChange={(e) => handleChange(e, cml.pivot.id)}
                      disabled={fetchingData}
                    >
                      <option value="">Pilih</option>
                      <option value="1">Yes</option>
                      <option value="9">No</option>
                    </select>
                  </td>
                  <td className="py-1 border-silver align-middle text-center">
                    <select
                      className="form-select form-select-sm form-select-custom py-0 text-sm fw-semibold border-silver rounded w-auto mx-auto"
                      name="w"
                      defaultValue={cml.pivot.w || ""}
                      onChange={(e) => handleChange(e, cml.pivot.id)}
                      disabled={fetchingData}
                    >
                      <option value="">Pilih</option>
                      <option value="1">Yes</option>
                      <option value="9">No</option>
                    </select>
                  </td>
                  <td className="py-1 border-silver align-middle text-center">
                    <select
                      className="form-select form-select-sm form-select-custom py-0 text-sm fw-semibold border-silver rounded w-auto mx-auto"
                      name="pb"
                      defaultValue={cml.pivot.pb || ""}
                      onChange={(e) => handleChange(e, cml.pivot.id)}
                      disabled={fetchingData}
                    >
                      <option value="">Pilih</option>
                      <option value="1">Yes</option>
                      <option value="9">No</option>
                    </select>
                  </td>
                  <td className="p-0 border-silver align-middle text-center">
                    <textarea
                      className="form-control form-control-sm form-control-custom py-0 text-black border-0 bg-none rounded-0 h-100 w-auto mx-auto"
                      name="catatan"
                      defaultValue={cml.pivot.catatan || ""}
                      onBlur={(e) => handleChange(e, cml.pivot.id)}
                      onKeyUp={(e) =>
                        handleChangeTextArea(e, cml.pivot.id)
                      }
                      disabled={fetchingData}
                      rows={12}
                    />
                  </td>
                  <td className="py-1 border-silver align-middle text-center">
                    <select
                      className="form-select form-select-sm form-select-custom py-0 text-sm fw-semibold border-silver rounded w-auto mx-auto"
                      name="s"
                      defaultValue={cml.pivot.s || ""}
                      onChange={(e) => handleChange(e, cml.pivot.id)}
                      disabled={fetchingData}
                    >
                      <option value="">Pilih</option>
                      <option value="1">Yes</option>
                      <option value="9">No</option>
                    </select>
                  </td>
                  <td className="py-1 border-silver align-middle text-center">
                    <select
                      className="form-select form-select-sm form-select-custom py-0 text-sm fw-semibold border-silver rounded w-auto mx-auto"
                      name="ts"
                      defaultValue={cml.pivot.ts || ""}
                      onChange={(e) => handleChange(e, cml.pivot.id)}
                      disabled={fetchingData}
                    >
                      <option value="">Pilih</option>
                      <option value="1">Yes</option>
                      <option value="9">No</option>
                    </select>
                  </td>
                  <td className="py-1 border-silver align-middle text-center">
                    <select
                      className="form-select form-select-sm form-select-custom py-0 text-sm fw-semibold border-silver rounded w-auto mx-auto"
                      name="sr"
                      defaultValue={cml.pivot.sr || ""}
                      onChange={(e) => handleChange(e, cml.pivot.id)}
                      disabled={fetchingData}
                    >
                      <option value="">Pilih</option>
                      <option value="1">Yes</option>
                      <option value="9">No</option>
                    </select>
                  </td>
                  <td className="py-1 border-silver align-middle text-center">
                    <i className="fa fa-fw fa-circle-exclamation text-warning" />{" "}
                    <p className="mb-0 text-secondary">
                      Fitur belum tersedia.
                    </p>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </LayoutTable>
      </div>
    );
  } else {
    return <h6 className="mb-0 text-center">Mohon Tunggu. . .</h6>;
  }
};

export default AuditorList;
