import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  fetchPeminjamanRuangan,
  fetchRuangan,
  patchPeminjamanRuangan,
} from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import SectionPagination from "../../components/sections/Pagination";
import { formatDate, saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import PeminjamanRuanganCreate from "./Create";
import PeminjamanRuanganEdit from "./Edit";

const PengajuanPeminjamanRuangan = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);

  const [page, setPage] = useState(1);
  const [peminjamanRuangan, setPeminjamanRuangan] = useState({});

  const fetchData = useCallback(async () => {
    const res = await fetchPeminjamanRuangan(page, `pegawai_id=${user.id}`);
    if (res.data) {
      setPeminjamanRuangan(res.data.peminjaman_ruangan);
      setTitle(res.data.title);
    }
  }, [page, setTitle, user]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [ruangan, setRuangan] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchRuangan("all");
      if (res.data) {
        setRuangan(res.data);
      }
    };

    fetchData();
  }, []);

  const getIcon = (pr) => {
    if (pr.rejected) {
      return "fa-circle-xmark text-danger";
    } else if (pr.menyetujui) {
      return "fa-circle-check text-success";
    } else if (pr.mengetahui) {
      return "fa-circle-check text-warning";
    }
    return "fa-clock-rotate-left text-dark";
  };

  return (
    <>
      <PeminjamanRuanganCreate fetchData={fetchData} ruangan={ruangan} />
      <div className="bg-gradient-primary text-white rounded-3 shadow p-3 my-3">
        <h4 className="fw-semibold">Kode Warna :</h4>
        <ul className="list-unstyled ms-3">
          <li className="text-danger"><i className="fa fa-fw fa-circle-xmark"/> = Peminjaman Ruangan Ditolak</li>
          <li className="text-success-2"><i className="fa fa-fw fa-circle-check"/> = Peminjaman Ruangan Diterima oleh Kepala Cabang</li>
          <li className="text-warning"><i className="fa fa-fw fa-circle-check"/> = Peminjaman Ruangan Diterima oleh UPT</li>
          <li className="text-white"><i className="fa fa-fw fa-clock-rotate-left"/> = Peminjaman Ruangan Diajukan</li>
        </ul>
      </div>
      <LayoutTable>
        <LayoutThead>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Kode</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Cabang</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Ruangan</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Pegawai</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Dari</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Sampai</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No Hp</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">keperluan</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Mengetahui</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Menyetujui</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Ditolak</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
            <span className="fa fa-fw fa-cog"></span>
          </th>
        </LayoutThead>
        <tbody>
          {peminjamanRuangan.data &&
            peminjamanRuangan.data.length > 0 &&
            peminjamanRuangan.data.map((pr, index) => {
              return (
                <tr key={index}>
                  <td className={`py-1 border-silver align-middle text-center`}><i className={`fa fa-fw ${getIcon(pr)}`}/></td>
                  <td className="py-1 border-silver align-middle text-center">{parseInt(peminjamanRuangan.from) + index}</td>
                  <td className="py-1 border-silver align-middle text-center">{pr.cabang}</td>
                  <td className="py-1 border-silver align-middle text-center">
                    {ruangan.length > 0 &&
                      ruangan
                        .filter(
                          (r) => parseInt(r.id) === parseInt(pr.ruangan_id)
                        )
                        .map((r) => r.nama)}
                  </td>
                  <td className="py-1 border-silver align-middle text-center">{pr.pegawai ? pr.pegawai.nama : "-"}</td>
                  <td className="py-1 border-silver align-middle text-nowrap text-center">{formatDate(pr.dari)}</td>
                  <td className="py-1 border-silver align-middle text-nowrap text-center">
                    {formatDate(pr.sampai)}
                  </td>
                  <td className="py-1 border-silver align-middle">{pr.no_telp}</td>
                  <td className="py-1 border-silver align-middle">{pr.keperluan}</td>
                  <td className="py-1 border-silver align-middle text-center">
                    {pr.mengetahui ? pr.mengetahui : "-"}
                  </td>
                  <td className="py-1 border-silver align-middle text-center">
                    {pr.menyetujui ? pr.menyetujui : "-"}
                  </td>
                  <td className="py-1 border-silver align-middle text-center">
                    {pr.rejected ? pr.rejected : "-"}
                  </td>
                  <td className="py-1 border-silver align-middle text-center">
                    {!pr.mengetahui && !pr.menyetujui && !pr.rejected ? (
                      <PeminjamanRuanganEdit
                        ruangan={ruangan}
                        peminjamanRuangan={pr}
                        fetchData={fetchData}
                      />
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </LayoutTable>
      <SectionPagination
        links={peminjamanRuangan.links}
        onChangePage={(res) => setPage(res)}
      />
    </>
  );
};

export default PengajuanPeminjamanRuangan;
