import React, { useRef, useState } from 'react'
import { LayoutAddBtn } from '../../../components/layouts/TopContent'
import LayoutModal, { NewSubmitButton } from '../../../components/layouts/Modal';
import FormInput from '../../../components/forms/Input';
import FormSelect from '../../../components/forms/Select';
import { patchJenisSurveyMhs, postJenisSurveyMhs, postSurveyMhs } from '../../../api';
import { getGenapGanjil, namaBulan, saAlert } from '../../../helpers';
import SmallButton from '../../../components/forms/SmallButton';

function SMJenisEdit({surveyMhs, jenisSurvey, fetchdata}) {
    
    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const formRef = useRef(null);

    const handleOpen = () => {
        setForm(jenisSurvey);
        setModal(true);
    }

    const handleClose = () => {
        if (formRef.current) {
            formRef.current.reset();
        }
        setForm({});
        setErrors({});
        setModal(false);
    }

    const handleChange = (e) => {
        setForm((prevState) => {
            return { ...prevState , [e.target.name] : e.target.value };
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await patchJenisSurveyMhs(jenisSurvey.id, form);
            if (res.data.status === 'success') {
                fetchdata();
                handleClose();
                saAlert(res.data.status, res.data.msg);
            }
        } catch (error) {
            setErrors(error.response?.data?.errors);
        }
    }

  return (
    <>
        <SmallButton onClick={handleOpen} margin="me-1" type="edit"/>

        <LayoutModal
            size="md"
            title='Edit Jenis Survey Mahasiswa'
            modal={modal}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} ref={formRef}>
                <div className='mb-3 pb-3 border-bottom border-silver'>
                    <p className='mb-0 fw-semibold text-primary'>Survey ID :</p>
                    <h6 className='fw-semibold text-black'>{surveyMhs.id}</h6>
                    <p className='mb-0 fw-semibold text-primary'>Nama Survey :</p>
                    <h6 className='fw-semibold text-black'>{surveyMhs.name}</h6>
                </div>
                <FormInput
                    label="Nama Jenis Survey"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                    error={errors.name}
                />
                <FormSelect
                    label="Tahun"
                    name="tahun"
                    value={form.tahun}
                    onChange={handleChange}
                    error={errors.tahun}
                >
                    <option value="">Pilih</option>
                    <option value="2024">2024</option>
                    <option value="2025">2025</option>
                    <option value="2026">2026</option>
                    <option value="2027">2027</option>
                    <option value="2028">2028</option>
                    <option value="2030">2030</option>
                </FormSelect>
                <FormSelect
                    label="Semester"
                    name="semester"
                    value={form.semester}
                    onChange={handleChange}
                    error={errors.semester}
                >
                    <option value="">Pilih</option>
                    <option value="1">{getGenapGanjil(1)}</option>
                    <option value="2">{getGenapGanjil(2)}</option>
                </FormSelect>
                <FormSelect
                    label="Pelaksanaan"
                    name="pelaksanaan"
                    value={form.pelaksanaan}
                    onChange={handleChange}
                    error={errors.pelaksanaan}
                >
                    <option value="">Pilih</option>
                    {namaBulan.map((b, index) => {
                        return (
                            <option value={index + 1} key={index}>{b}</option>
                        )
                    })
                        
                    }
                </FormSelect>
                <FormSelect
                    label="Desiminasi"
                    name="desiminasi"
                    value={form.desiminasi}
                    onChange={handleChange}
                    error={errors.desiminasi}
                >
                    <option value="">Pilih</option>
                    {namaBulan.map((b, index) => {
                        return (
                            <option value={index + 1} key={index}>{b}</option>
                        )
                    })
                        
                    }
                </FormSelect>
                <NewSubmitButton className="mt-2" widthFull onClose={handleClose}/>
            </form>
        </LayoutModal>
    </>
  )
}

export default SMJenisEdit