import React, { useEffect, useState } from "react";
import FormInput from "../../../../components/forms/Input";
import FormTextarea from "../../../../components/forms/Textarea";
import PageLoading from "../../../../components/pages/Loading";
import { getCurrency } from "../../../../helpers";

const SectionList = ({
  errors = {},
  dataList,
  setDataList,
  setJumlah,
  approval = false,
}) => {
  const getTotalJumlah = (dataList) => {
    setTimeout(() => {
      let jumlah = dataList.map((d) => {
        if (d.volume && d.estimasi_harga) {
          return parseInt(d.volume) * parseInt(d.estimasi_harga);
        }
        return 0;
      });

      if (jumlah.length > 0) {
        setJumlah(
          jumlah.filter((j) => !isNaN(j)).reduce((prev, curr) => prev + curr)
        );
      } else {
        setJumlah(0);
      }
    }, 1000);
  };

  const handleChange = (e, index_upd) => {
    setDataList(
      dataList.map((d, index) => {
        if (index === index_upd) {
          let dataUpd = d;
          dataUpd[e.target.name] = e.target.value;
          return dataUpd;
        } else {
          return d;
        }
      })
    );

    getTotalJumlah(dataList);
  };

  const model = {
    description: "",
    volume: "",
    satuan: "",
    estimasi_harga: "",
    anggaran_rka: "",
  };

  const handleTambahList = () => {
    setDataList([...dataList, model]);
  };

  const [isLoaded, setIsLoaded] = useState(true);

  const handleHapusList = (index_del) => {
    setIsLoaded(false);

    setTimeout(() => {
      let newData = dataList.filter((d, index) => index !== index_del);
      setDataList(newData);
      getTotalJumlah(newData);
    }, 250);
  };

  useEffect(() => {
    if (!isLoaded) {
      setTimeout(() => {
        setIsLoaded(true);
      }, 500);
    }
  }, [isLoaded]);

  return (
    <div className="my-4 p-4">
      <h4 className="fw-semibold border-bottom border-dark pb-2 mb-3">List Items</h4>
      {isLoaded ? (
        dataList.map((d, index) => {
          return (
            <div>
              {/* <div className="row bg-white my-3 py-3" key={index}>
                {!approval && (
                  <div className="col-12 text-right">
                    <button
                      className="btn btn-danger btn-sm"
                      type="button"
                      onClick={() => handleHapusList(index)}
                    >
                      Hapus List
                    </button>
                  </div>
                )}
                <div className="col-2">
                  <FormTextarea
                    label="Deskripsi"
                    name="description"
                    value={d.description}
                    onChange={(e) => handleChange(e, index)}
                    error={errors.description}
                    readOnly={approval}
                  />
                </div>
                <div className="col-2">
                  <FormInput
                    type="number"
                    label="Volume || Contoh = 1 / 2 / 3"
                    name="volume"
                    value={d.volume}
                    onChange={(e) => handleChange(e, index)}
                    error={errors.volume}
                    readOnly={approval}
                  />
                </div>
                <div className="col-2">
                  <FormInput
                    label="Satuan || Contoh = kg / unit / pcs"
                    name="satuan"
                    value={d.satuan}
                    onChange={(e) => handleChange(e, index)}
                    error={errors.satuan}
                    readOnly={approval}
                  />
                </div>
                <div className="col-2">
                  <FormInput
                    type="number"
                    label={`Estimasi Harga Rp ${getCurrency(
                      parseInt(d.estimasi_harga)
                    )}`}
                    name="estimasi_harga"
                    value={d.estimasi_harga}
                    onChange={(e) => handleChange(e, index)}
                    error={errors.estimasi_harga}
                    readOnly={approval}
                  />
                </div>
                <div className="col-2">
                  <FormInput
                    type="number"
                    label={`Anggaran RKA Rp ${getCurrency(
                      parseInt(d.anggaran_rka)
                    )}`}
                    name="anggaran_rka"
                    value={d.anggaran_rka}
                    onChange={(e) => handleChange(e, index)}
                    error={errors.anggaran_rka}
                    readOnly={approval}
                  />
                </div>
                <div className="col-2">
                  <h6 className="text-primary fw-semibold">Total Harga</h6>
                  <p className="pb-0 fw-semibold d-block bg-primary text-white rounded-3 p-2">
                    {`Rp. ${getCurrency(
                      parseInt(d.volume) * parseInt(d.estimasi_harga)
                    )}`}
                  </p>
                </div>
              </div> */}

            <div className="row">
              <div className="col-lg-3">
                <div className="bg-gradient-primary rounded-3 p-4">
                  <FormTextarea
                    row={2}
                    labelClassName="text-white"
                    label="Deskripsi"
                    name="description"
                    value={d.description}
                    onChange={(e) => handleChange(e, index)}
                    error={errors.description}
                    readOnly={approval}
                  />
                  <FormInput
                    labelClassName="text-white"
                    type="number"
                    label="Volume <span class='fw-normal text-sm text-gray-400'>( Contoh = 1 / 2 / 3 )</span>"
                    name="volume"
                    value={d.volume}
                    onChange={(e) => handleChange(e, index)}
                    error={errors.volume}
                    readOnly={approval}
                  />
                  <FormInput
                    labelClassName="text-white"
                    label="Satuan <span class='fw-normal text-sm text-gray-400'>( Contoh = kg / unit / pcs )</span>"
                    name="satuan"
                    value={d.satuan}
                    onChange={(e) => handleChange(e, index)}
                    error={errors.satuan}
                    readOnly={approval}
                  />
                  <FormInput
                    labelClassName="text-white"
                    type="number"
                    label={`Estimasi <span class='fw-normal text-sm text-gray-400'>( Rp ${getCurrency(parseInt(d.estimasi_harga))} )</span>`}
                    name="estimasi_harga"
                    value={d.estimasi_harga}
                    onChange={(e) => handleChange(e, index)}
                    error={errors.estimasi_harga}
                    readOnly={approval}
                  />
                  <FormInput
                    labelClassName="text-white"
                    type="number"
                    label={`Anggaran RKA <span class='fw-normal text-sm text-gray-400'>( Rp ${getCurrency(parseInt(d.anggaran_rka))} )</span>`}
                    name="anggaran_rka"
                    value={d.anggaran_rka}
                    onChange={(e) => handleChange(e, index)}
                    error={errors.anggaran_rka}
                    readOnly={approval}
                  />
                  <p className="mb-2 text-white fw-semibold">Total Harga</p>
                  <p className="mb-0 fw-semibold d-block bg-warning text-black rounded-3 p-2">
                    {`Rp. ${getCurrency(
                      parseInt(d.volume) * parseInt(d.estimasi_harga)
                    )}`}
                  </p>
                </div>
              </div>
            </div>
            </div>
          );
        })
      ) : (
        <PageLoading />
      )}

      {isLoaded && !approval && (
        <button
          type="button"
          className="btn btn-primary btn-sm mb-4"
          onClick={handleTambahList}
        >
          Tambah List
        </button>
      )}
    </div>
  );
};

export default SectionList;
