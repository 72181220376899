import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import FormInput from "../../../components/forms/Input";
import { getCurrency, saAlert } from "../../../helpers";
import { postPurchaseRequest } from "../apis";
import SectionList from "./partials/SectionList";

const Create = ({ mainUrl }) => {
  const [form, setForm] = useState({});
  const [dataList, setDataList] = useState([]);
  const [jumlah, setJumlah] = useState(0);

  const [errors, setErrors] = useState({});

  const history = useHistory();

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    try {
      const res = await postPurchaseRequest({ ...form, dataList });
      if (res.data && res.data.msg) {
        history.push(mainUrl);
        saAlert("success", res.data.msg);
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Tambah Data</h3>
      <div className="row">
        <div className="col-6">
          <FormInput
            label="Keperluan"
            name="untuk"
            onChange={handleChange}
            error={errors.untuk}
          />
        </div>
        <div className="col-6">
          <FormInput
            type="date"
            label="Tanggal Dibutuhkan"
            name="tanggal_dibutuhkan"
            onChange={handleChange}
            error={errors.tanggal_dibutuhkan}
          />
        </div>
      </div>

      <SectionList
        errors={errors}
        dataList={dataList}
        setDataList={(res) => setDataList(res)}
        setJumlah={setJumlah}
      />

      <h2>Jumlah Akhir = Rp {getCurrency(jumlah)}</h2>

      <button type="submit" className="btn btn-success px-5">
        Simpan
      </button>
    </form>
  );
};

export default Create;
