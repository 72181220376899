import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deletePojt, fetchPerusahaan } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterCabang,
  FilterSearch,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import { formatDate, saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import { periodeOjtState } from "../../storage/mhs";
import { titleState } from "../../storage/pages";
import PerusahaanAktifOjt from "./AktifOjt";
import CountOnTop from "./CountOnTop";
import PerusahaanEdit from "./Edit";

const PerusahaanPerpanjangMou = () => {
  const setTitle = useSetRecoilState(titleState);
  const periodeOjt = useRecoilValue(periodeOjtState);
  const user = useRecoilValue(userState);

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState();
  const [count, setCount] = useState({});

  const [page] = useState("all");
  const [search, setSearch] = useState("");
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchPerusahaan(
        page,
        `notification_mou=1&search=${search}&cabang=${cabang}`
      );
      setItems(res.data && res.data.perusahaan);
      res.data &&
        res.data.baru_mou &&
        setCount((prevState) => {
          return { ...prevState, baru: res.data.baru_mou };
        });
      res.data &&
        res.data.perpanjang_mou &&
        setCount((prevState) => {
          return { ...prevState, perpanjang: res.data.perpanjang_mou };
        });
      res.data &&
        res.data.belum_mou &&
        setCount((prevState) => {
          return { ...prevState, belum: res.data.belum_mou };
        });
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page, search, cabang]);

  useEffect(() => {
    setTitle("Perusahaan Perpanjang Mou");
    fetchData();
  }, [fetchData, setTitle]);

  if (isLoaded) {
    return (
      <div>
        <div>
          <CountOnTop
            baru={count.baru}
            perpanjang={count.perpanjang}
            belum={count.belum}
          />
          <h6 className="mb-3 fw-semibold text-dark"><i className="fa fa-fw fa-bell text-warning"/> Notifikasi ini akan muncul 45 hari sebelum MoU berakhir.</h6>
          <div className="row mb-2">
            <div className="col-md-6">
              {user.cabang === "pusat" && (
                <FilterCabang onChange={(res) => setCabang(res)} pusat={true} />
              )}
            </div>
            <div className="col-md-6">
              <FilterSearch onChange={(res) => setSearch(res)} />
            </div>
          </div>
          {items.length > 0 ? (
            <div className="row">
              <div className="col-3 pe-0">
                <LayoutTable>
                  <LayoutThead>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">No</th>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Nama</th>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">PIC</th>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">MoU</th>
                  </LayoutThead>
                  <tbody>
                    {items.map((i, index) => {
                      return (
                        <tr key={index}>
                          <td className="py-1 border-silver text-center">{index + 1}</td>
                          <td className="py-1 border-silver text-nowrap">{i.nama}</td>
                          <td className="py-1 border-silver text-nowrap">{i.pic}</td>
                          <td className="py-1 border-silver text-nowrap text-center">
                            {i.status === 1 && (
                              <span className="text-info">Baru</span>
                            )}
                            {i.status === 2 && (
                              <span className="text-success">Perpanjang</span>
                            )}
                            {i.status === 3 && (
                              <span className="text-warning">Belum MoU</span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </LayoutTable>
              </div>
              <div className="col-6 px-0">
                <LayoutTable>
                  <LayoutThead>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Cabang</th>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Dari</th>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Sampai</th>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Alamat</th>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">No Telfon</th>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">No HP</th>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Email</th>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Ultah</th>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Jurusan</th>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">Kuota</th>
                  </LayoutThead>
                  <tbody>
                    {items.map((i, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{ textTransform: "capitalize" }}
                            className="py-1 border-silver text-center"
                          >
                            {i.cabang}
                          </td>
                          <td className="py-1 border-silver text-nowrap">
                            {formatDate(i.mou_dari)}
                          </td>
                          <td className="py-1 border-silver text-nowrap">
                            {formatDate(i.mou_sampai)}
                          </td>
                          <td className="py-1 border-silver text-nowrap">{i.alamat}</td>
                          <td className="py-1 border-silver text-nowrap">{i.no_telf}</td>
                          <td className="py-1 border-silver text-nowrap">{i.no_hp}</td>
                          <td className="py-1 border-silver text-nowrap">{i.email}</td>
                          <td className="py-1 border-silver text-nowrap">{formatDate(i.ultah)}</td>
                          <td className="py-1 border-silver text-nowrap">{i.jurusan}</td>
                          <td className="py-1 border-silver text-nowrap text-center">{i.kuota}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </LayoutTable>
              </div>
              <div className="col-3 ps-0">
                <LayoutTable>
                  <LayoutThead>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal`">OJT</th>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
                      <i className="fa fa-fw fa-cog" />
                    </th>
                  </LayoutThead>
                  <tbody>
                    {items.map((i, index) => {
                      return (
                        <tr key={index}>
                          <td className="py-1 border-silver text-center">
                            {i.pojt && i.pojt.length > 0 ? (
                              i.pojt
                                .filter(
                                  (p) =>
                                    parseInt(p.periode) === parseInt(periodeOjt)
                                )
                                .map((p) => {
                                  return (
                                    <div key={p.id} className="text-nowrap">
                                      <span className="text-success mr-2">
                                        Aktif
                                      </span>
                                      <span
                                        type="button"
                                        className="badge badge-danger"
                                        onClick={() => {
                                          saConfirm(
                                            "warning",
                                            "Yakin hapus dari aktif OJT ?"
                                          ).then((res) => {
                                            if (res.isConfirmed) {
                                              deletePojt(p.id)
                                                .then((res) => {
                                                  if (res === "success") {
                                                    fetchData();
                                                    saAlert(
                                                      "success",
                                                      "Berhasil hapus dari aktif OJT !"
                                                    );
                                                  }
                                                })
                                                .catch((err) => console.log(err));
                                            }
                                          });
                                        }}
                                      >
                                        <i className="fa fa-trash-alt" />
                                      </span>
                                    </div>
                                  );
                                })
                            ) : (
                              <PerusahaanAktifOjt
                                perusahaan={i}
                                onFetchData={fetchData}
                              />
                            )}
                          </td>
                          <td className="py-1 border-silver text-nowrap text-center">
                            <PerusahaanEdit
                              perusahaan={i}
                              onFetchData={fetchData}
                            />
                            <i className="mr-1" />
                            <button 
                              onClick={() => {
                                alert('Perhatian, Saat ini fitur hapus data masih dalam perbaikan.')
                              }} 
                              className="btn btn-sm btn-danger border-0 py-0 text-sm rounded mx-1"
                            >
                              <i className="fa fa-fw fa-sm fa-trash-alt"/> Hapus
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </LayoutTable>
              </div>
            </div>
          ) : (
            <PageNoData />
          )}
        </div>
      </div>
    );
  } else {
    return <PageLoading />;
  }
};

export default PerusahaanPerpanjangMou;
