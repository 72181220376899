import React, { useRef, useState } from "react";
import { patchDosen } from "../../api";
import FormInput from "../../components/forms/Input";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";

const DosenEdit = (props) => {
  const { dosen, onFetchData } = props;

  const tagForm = useRef();
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    if (tagForm && tagForm.current) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    patchDosen(dosen.id, form)
      .then((res) => {
        if (res.data === "success") {
          onFetchData();
          handleClose();
          saAlert("success", "Berhasil edit data Dosen !");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      });
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-success-2 text-sm py-0 px-1 me-1"
        onClick={() => {
          setForm(dosen);
          setModal(true);
        }}
      >
        <i className="fa fa-fw fa-sm fa-pen"/>
      </button>
      <LayoutModal
        title="Tambah Data Dosen"
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
        <TextOnOffMhs>
          <p className="mb-0 fw-semibold">Password default dosen yang baru dibuat adalah <span className="text-warning">NIPnya</span>.</p>
        </TextOnOffMhs>
          <div className="row">
            <div className="col-lg-4">
              <FormInput
                label="Nama Dosen"
                name="nama"
                value={form.nama}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.nama}
              />
            </div>
            <div className="col-lg-4">
              <FormInput
                label="Email"
                name="email"
                value={form.email}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.email}
              />
            </div>
            <div className="col-lg-4">
              <FormInput
                label="No HP"
                name="no_hp"
                value={form.no_hp}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.no_hp}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <FormInput
                label="Honor KRS"
                name="honor"
                type="number"
                value={form.honor}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Honor Kursus"
                name="honor_kursus"
                type="number"
                value={form.honor_kursus}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor_kursus}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Honor Membimbing LPK"
                name="honor_membimbing_lpk"
                type="number"
                value={form.honor_membimbing_lpk}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor_membimbing_lpk}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Honor Membimbing LTA"
                name="honor_membimbing_lta"
                type="number"
                value={form.honor_membimbing_lta}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor_membimbing_lta}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Honor Membimbing TA"
                name="honor_membimbing_ta"
                type="number"
                value={form.honor_membimbing_ta}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor_membimbing_ta}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Honor Menguji"
                name="honor_menguji"
                type="number"
                value={form.honor_menguji}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor_menguji}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Honor Remidi"
                name="honor_remidi"
                type="number"
                value={form.honor_remidi}
                onChange={(e) => {
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  });
                }}
                error={errors.honor_remidi}
              />
            </div>
          </div>
          <div className="row mt-3">
            <NewSubmitButton onClose={handleClose}/>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default DosenEdit;
