import React, { useEffect, useState, useCallback, createContext } from "react";
import { useSetRecoilState } from "recoil";
import { titleState } from "../../storage/pages";
import { Switch, Route } from "react-router-dom";
import { fetchCabang } from "./apis";
import Cabang from "./pages/Cabang";
import Create from "./pages/Create";
import Edit from "./pages/Edit";

export const CabangContext = createContext();

const CabangRoutes = () => {
  const setTitle = useSetRecoilState(titleState);
  const mainUrl = "/cabang";

  const [cabang, setCabang] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchCabang("all");
      if (res.data && res.data.cabang) {
        setCabang(res.data.cabang);
        setTitle(res.data.title);
      }
    } catch (err) {
      console.error(err.response);
    }
  }, [setTitle]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <CabangContext.Provider value={{ cabang }}>
      <Switch>
        <Route exact path={`${mainUrl}`}>
          <Cabang mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
        <Route path={`${mainUrl}/create`}>
          <Create mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
        <Route path={`${mainUrl}/:id/edit`}>
          <Edit mainUrl={mainUrl} onFetchData={fetchData} />
        </Route>
      </Switch>
    </CabangContext.Provider>
  );
};

export default CabangRoutes;
