import { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import axios from "../../axios";
import ButtonForm from "../../components/forms/Button";
import CrForm from "../../components/forms/Cr";
import InputForm from "../../components/forms/Input";
import TextareaForm from "../../components/forms/Textarea";
import { saAlert } from "../../helpers";
import { tokenState, userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";

const CutiCreate = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const user = useRecoilValue(userState);
  const history = useHistory();
  const tagForm = useRef(null);

  useEffect(() => {
    setTitle("Buat Cuti Baru");
  }, [setTitle]);

  const [dari, setDari] = useState();
  const [sampai, setSampai] = useState();
  const [alamat, setAlamat] = useState();
  const [no_hp, setNo_hp] = useState();
  const [keperluan, setKeperluan] = useState();
  const [keperluan_lain, setKeperluan_lain] = useState();

  const [formErrors, setFormErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        "/api/cuti",
        {
          dari,
          sampai,
          alamat,
          no_hp,
          keperluan,
          keperluan_lain,
        },
        {
          headers: { Authorization: "Bearer " + token },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          saAlert("success", res.data);
          history.push("/cuti/show");
        }
      })
      .catch((err) => {
        let res = err.response;
        if (res.status === 422) {
          setFormErrors(res.data.errors);
        }
      });
  };

  return (
    <>
      <form method="POST" onSubmit={handleSubmit} ref={tagForm}>
        <InputForm label="NIP" readOnly={true} value={user.nip} />
        <InputForm label="Nama" readOnly={true} value={user.nama} />
        <div className="row">
          <div className="col">
            <InputForm
              label="Dari"
              type="date"
              onChange={(e) => setDari(e.target.value)}
              error={formErrors.dari}
            />
          </div>
          <div className="col">
            <InputForm
              label="Sampai"
              type="date"
              onChange={(e) => setSampai(e.target.value)}
              error={formErrors.sampai}
            />
          </div>
        </div>
        <TextareaForm
          label="Alamat"
          onChange={(e) => setAlamat(e.target.value)}
          error={formErrors.alamat}
        />
        <InputForm
          label="No HP"
          onChange={(e) => setNo_hp(e.target.value)}
          error={formErrors.no_hp}
        />
        <CrForm
          label="Keperluan"
          type="radio"
          inline={true}
          name="keperuan"
          onChange={(e) => setKeperluan(e.target.value)}
          error={formErrors.keperluan}
          option={[
            { value: "", name: "Pilih" },
            { value: "Cuti Tahunan" },
            { value: "Cuti Bersalin" },
            { value: "Cuti Alasan Penting" },
          ]}
        />
        <TextareaForm
          placeholder="Tulis alasan penting disini !"
          onChange={(e) => setKeperluan_lain(e.target.value)}
          error={formErrors.keperluan_lain}
        />
        <hr />
        <p className="text-center">
          Approval lembur oleh :{" "}
          {(() => {
            if (user.atasan1) {
              return user.atasan1.nama;
            } else {
              if (user.atasan2) {
                return user.atasan2.nama;
              }
            }
          })()}
        </p>
        <hr />
        <ButtonForm submit="Ajukan" reset="Reset" back="Kembali" />
      </form>
    </>
  );
};

export default CutiCreate;
