import React, { useState, useRef } from "react";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import FormInput from "../../components/forms/Input";
import { postPengumuman } from "../../api";
import { saAlert } from "../../helpers";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import FormSelect from "../../components/forms/Select";
import FormTextarea from "../../components/forms/Textarea";
import { useRecoilValue } from "recoil";
import { userState } from "../../storage/auth";

const PengumumanCreate = ({ dataCabang, dataDepartemen, onFetchData }) => {
  const user = useRecoilValue(userState);
  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);

  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      let formData = new FormData(tagForm.current);

      const res = await postPengumuman(formData);
      if (res.data && res.data.status) {
        onFetchData();
        handleClose();
        saAlert(res.data.status, res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <>
      <LayoutAddBtn onClick={handleOpen} add="Tambah Pengumuman" />
      <LayoutModal
        title="Tambah Data Pengumuman"
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
          <div className="row mb-4">
            <div className="col-md-4">
              <FormSelect
                label="Tujuan Pengumuman"
                name="tujuan"
                error={errors.tujuan}
              >
                <option value="">Semua Sistem</option>
                <option value="SIA">SIA</option>
                <option value="SIDOS">SIDOS</option>
              </FormSelect>
            </div>
            <div className="col-md-8">
              <FormInput label="Judul" name="judul" error={errors.judul} />
            </div>
            <div className="col-md-12">
              <FormTextarea label="Isi" name="isi" error={errors.isi} row={10}/>
            </div>
            {user.cabang === "pusat" && (
              <div className="col-md-4">
                <FormSelect
                  label="Cabang"
                  name="cabang_id"
                  error={errors.cabang_id}
                >
                  <option value="">Pilih</option>
                  {dataCabang &&
                    dataCabang.length > 0 &&
                    dataCabang.map((dc, index) => {
                      return (
                        <React.Fragment key={index}>
                          <option value={dc.id}>{dc.nama}</option>
                        </React.Fragment>
                      );
                    })}
                </FormSelect>
              </div>
            )}
            <div className="col-md-4">
              <FormSelect
                label="Departemen"
                name="departemen_id"
                error={errors.departemen_id}
              >
                <option value="">Pilih</option>
                {dataDepartemen &&
                  dataDepartemen.length > 0 &&
                  dataDepartemen.map((dd, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={dd.id}>{dd.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col-md-4">
              <FormSelect label="Jenjang" name="jenjang" error={errors.jenjang}>
                <option value="">Semua Program</option>
                <option value="D1">Program 1 Tahun</option>
                <option value="D2">Program 2 Tahun</option>
              </FormSelect>
            </div>
            <div className="col-md-4">
              <FormSelect
                label="Tahun Angkatan"
                name="tahun_angkatan"
                error={errors.tahun_angkatan}
              >
                <option value="">Semua Angkatan</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
              </FormSelect>
            </div>
            <div className="col-md-4">
              <FormInput
                label="Dari"
                type="date"
                name="dari"
                error={errors.dari}
              />
            </div>
            <div className="col-md-4">
              <FormInput
                label="Sampai"
                type="date"
                name="sampai"
                error={errors.sampai}
              />
            </div>
            <div className="col-md-4">
              <FormInput
                type="file"
                label="File Upload"
                name="file"
                error={errors.file}
              />
            </div>
          </div>
          <div className="row">
            <NewSubmitButton onClose={handleClose}/>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default PengumumanCreate;
