import React, { useState, useRef } from "react";
import LayoutModal from "../../components/layouts/Modal";
import FormInput from "../../components/forms/Input";
import { getPrivateFile, postPegawai } from "../../api";
import { saAlert } from "../../helpers";
import FormSelect from "../../components/forms/Select";
import { useRecoilValue } from "recoil";
import { userState } from "../../storage/auth";
import LoadingBasic from "../../components/pages/LoadingBasic";

const EditHrd = (props) => {

  const {pegawai, dataCabang, dataJabatan, onFetchData} = props;

  const user = useRecoilValue(userState);

  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setModal(true);
    setForm(pegawai);
    if (pegawai.ktp || pegawai.npwp || pegawai.ttd) {
      fetchAllFile();
    }
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
    setForm({});
    setErrors({});
    closeSecondModal();
  };

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };
  const handleChangeFile = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.files[0] };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    // edit_id
    form.id && formData.append("pegawai_id", form.id);
    // text
    form.cabang_id && formData.append("cabang_id", form.cabang_id);
    form.jabatan_id && formData.append("jabatan_id", form.jabatan_id);
    // form.direktorat_id && formData.append("direktorat_id", form.direktorat_id);
    // form.divisi_id && formData.append("divisi_id", form.divisi_id);
    // form.departemen_id && formData.append("departemen_id", form.departemen_id);
    // form.pelaksana_id && formData.append("pelaksana_id", form.pelaksana_id);
    form.cabang && formData.append("cabang", form.cabang);
    form.grade && formData.append("grade", form.grade);
    form.group && formData.append("group", form.group);
    form.atasan_1 && formData.append("atasan_1", form.atasan_1);
    form.atasan_2 && formData.append("atasan_2", form.atasan_2);
    form.nip && formData.append("nip", form.nip);
    form.nama && formData.append("nama", form.nama);
    form.nama_panggilan && formData.append("nama_panggilan", form.nama_panggilan);
    form.tempat_lahir && formData.append("tempat_lahir", form.tempat_lahir);
    form.tanggal_lahir && formData.append("tanggal_lahir", form.tanggal_lahir);
    form.email && formData.append("email", form.email);
    form.no_hp && formData.append("no_hp", form.no_hp);
    form.agama && formData.append("agama", form.agama);
    form.alamat && formData.append("alamat", form.alamat);
    form.bergabung && formData.append("bergabung", form.bergabung);
    // image
    form.ktp && formData.append("ktp", form.ktp);
    form.npwp && formData.append("npwp", form.npwp);
    form.ttd && formData.append("ttd", form.ttd);
    setErrors({});

    try {
      const res = await postPegawai(formData);
      if (res.data && res.data.status) {
        onFetchData();
        handleClose();
        saAlert(res.data.status, res.data.msg);
      }
    } catch (err) {
      console.log(err.response);
      err.response && err.response.status && err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  const [ktpFile, setKtpFile] = useState(false);
  const [npwpFile, setNpwpFile] = useState(false);
  const [ttdFile, setTtdFile] = useState(false);
  
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchAllFile = async () => {
    setIsLoaded(true);
    try {
      const resKtp = await getPrivateFile(`/pegawai/ktp/${pegawai.ktp}`);
      if (resKtp.data) {
        setKtpFile(resKtp.data);
      }

      const resNpwp = await getPrivateFile(`/pegawai/npwp/${pegawai.npwp}`);
      if (resNpwp.data) {
        setNpwpFile(resNpwp.data);
      }

      const resTtd = await getPrivateFile(`/pegawai/ttd/${pegawai.ttd}`);
      if (resTtd.data) {
        setTtdFile(resTtd.data);
      }

      setIsLoaded(false);
    } catch (error) {
      
    }
  };

  const [modalSecond, setModalSecond] = useState(false);
  const [showKtp, setShowKtp] = useState(false);
  const [showNpwp, setShowNpwp] = useState(false);
  const [showTtd, setShowTtd] = useState(false);

  const openSecondModal = (ktp, npwp, ttd) => {
    setModalSecond(true);
    if (ktp) {
      setShowKtp(true);
      // fetchFileKtp();
    }
    if (npwp) {
      setShowNpwp(true);
      // fetchFileNpwp();
    }
    if (ttd) {
      setShowTtd(true);
      // fetchFileTtd();
    }
  }

  const closeSecondModal = () => {
    setModalSecond(false);
    setShowKtp(false);
    setShowNpwp(false);
    setShowTtd(false);
  }

  return (
    <>
      <button type="button" onClick={handleOpen} className="btn btn-sm btn-success-2 text-sm py-0 px-1 me-1"><i className="fa fa-fw fa-sm fa-pen"/> Edit</button>
      <LayoutModal
        title={`${modalSecond ? `${showKtp ? 'Foto KTP' : ''} ${showNpwp ? 'Foto NPWP' : ''} ${showTtd ? 'Foto TTD' : ''}` : 'Edit Data Pegawai'}`}
        modal={modal}
        onClose={handleClose}
      >
        {!modalSecond ?
          <form onSubmit={handleSubmit} ref={tagForm}>
          <div className="row">
            {user.jabatan === "IT Programmer" &&
            <>
              <h4 className="text-primary fw-semibold">Custom</h4>
              <div className="col-md-4">
                <FormInput
                  label="Cabang"
                  name="cabang"
                  value={form.cabang}
                  onChange={handleChange}
                  error={errors.cabang}
                />
              </div>
              <div className="col-md-4">
                <FormSelect
                  label="Grade"
                  name="grade_id"
                  value={form.grade ? form.grade : ''}
                  onChange={handleChange}
                  error={errors.grade_id}
                >
                  <option value="">Pilih</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </FormSelect>
              </div>
              <div className="col-md-4">
                <FormSelect
                  label="Group"
                  name="group_id"
                  value={form.group ? form.group : ''}
                  onChange={handleChange}
                  error={errors.group_id}
                >
                  <option value="">Pilih</option>
                  <option value="Akademik">Akademik</option>
                  <option value="Humas">Humas</option>
                </FormSelect>
              </div>
              <div className="col-md-6">
                <FormInput
                  label="Atasan I"
                  name="atasan_1"
                  value={form.atasan_1}
                  onChange={handleChange}
                  error={errors.atasan_1}
                />
              </div>
              <div className="col-md-6">
                <FormInput
                  label="Atasan II"
                  name="atasan_2"
                  value={form.atasan_2}
                  onChange={handleChange}
                  error={errors.atasan_2}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  label="KTP"
                  name="ktp"
                  // value={form.ktp}
                  onChange={handleChange}
                  error={errors.ktp}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  label="NPWP"
                  name="npwp"
                  // value={form.npwp}
                  onChange={handleChange}
                  error={errors.npwp}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  label="TTD"
                  name="ttd"
                  // value={form.ttd}
                  onChange={handleChange}
                  error={errors.ttd}
                />
              </div>
            </>
            }

            {user.jabatan === "IT Programmer" && <h4 className="text-primary fw-semibold"> Original</h4>}
            <div className="col-md-2">
              <FormSelect
                label="Cabang"
                name="cabang_id"
                value={form.cabang_id ? form.cabang_id : ''}
                onChange={handleChange}
                error={errors.cabang_id}
              >
                <option value="">Pilih</option>
                {dataCabang.length > 0 &&
                  dataCabang.map((dc, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={dc.id}>{dc.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col-md-5">
              <FormSelect
                label="Jabatan"
                name="jabatan_id"
                value={form.jabatan_id ? form.jabatan_id : ''}
                onChange={handleChange}
                error={errors.jabatan_id}
              >
                <option value="">Pilih</option>
                {dataJabatan.length > 0 &&
                  dataJabatan.map((dj, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={dj.id}>{dj.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col-md-2">
              <FormInput
                label="NIP"
                name="nip"
                value={form.nip}
                onChange={handleChange}
                error={errors.nip}
              />
            </div>
            <div className="col-md-3">
              <FormInput
                label="Nama Panggilan"
                name="nama_panggilan"
                value={form.nama_panggilan}
                onChange={handleChange}
                error={errors.nama_panggilan}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Nama Lengkap"
                name="nama"
                value={form.nama}
                onChange={handleChange}
                error={errors.nama}
              />
            </div>
            <div className="col-md-3">
              <FormInput
                label="Tempat Lahir"
                name="tempat_lahir"
                value={form.tempat_lahir}
                onChange={handleChange}
                error={errors.tempat_lahir}
              />
            </div>
            <div className="col-md-3">
              <FormInput
              type="date"
                label="Tanggal Lahir"
                name="tanggal_lahir"
                value={form.tanggal_lahir}
                onChange={handleChange}
                error={errors.tanggal_lahir}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Email"
                name="email"
                value={form.email}
                onChange={handleChange}
                error={errors.email}
              />
            </div>
            <div className="col-md-3">
              <FormInput
                label="No HP"
                name="no_hp"
                value={form.no_hp}
                onChange={handleChange}
                error={errors.no_hp}
              />
            </div>
            <div className="col-md-3">
              <FormInput
                label="Agama"
                name="agama"
                value={form.agama}
                onChange={handleChange}
                error={errors.agama}
              />
            </div>
            <div className="col-md-10">
              <FormInput
                label="Alamat"
                name="alamat"
                value={form.alamat}
                onChange={handleChange}
                error={errors.alamat}
              />
            </div>
            <div className="col-md-2">
              <FormInput
                type="date"
                label="Bergabung pada"
                name="bergabung"
                value={form.bergabung}
                onChange={handleChange}
                error={errors.bergabung}
              />
            </div>
            <div className="col-md-4">
              {form.ktp ? <button type="button" onClick={() => openSecondModal(true, false, false)} className="w-100 btn btn-sm btn-primary-3 rounded-3 mb-2"><i className="fa fa-fw fa-sm fa-eye"/> Lihat KTP</button> : <button className="w-100 btn btn-sm btn-dark border-0 disabled rounded-3 mb-2">Belum ada KTP</button>}
              <FormInput
                type="file"
                label="Ubah KTP"
                name="ktp"
                onChange={handleChangeFile}
                error={errors.ktp}
              />
            </div>
            <div className="col-md-4">
              {form.npwp ? <button type="button" onClick={() => openSecondModal(false, true, false)} className="w-100 btn btn-sm btn-primary-3 rounded-3 mb-2"><i className="fa fa-fw fa-sm fa-eye"/> Lihat NPWP</button> : <button className="w-100 btn btn-sm btn-dark border-0 disabled rounded-3 mb-2">Belum ada NPWP</button>}
              <FormInput
                type="file"
                label="Ubah NPWP"
                name="npwp"
                onChange={handleChangeFile}
                error={errors.npwp}
              />
            </div>
            <div className="col-md-4">
              {form.ttd ? <button type="button" onClick={() => openSecondModal(false, false, true)} className="w-100 btn btn-sm btn-primary-3 rounded-3 mb-2"><i className="fa fa-fw fa-sm fa-eye"/> Lihat TTD</button> : <button className="w-100 btn btn-sm btn-dark border-0 disabled rounded-3 mb-2">Belum ada TTD</button>}
              <FormInput
                type="file"
                label="Ubah TTD"
                name="ttd"
                onChange={handleChangeFile}
                error={errors.ttd}
              />
            </div>
          </div>
          <div className="btn-group">
            <button type="submit" className="btn btn-success-2 btn-sm">
              Simpan
            </button>
            <button
              type="button"
              className="btn btn-dark border-0 btn-sm"
              onClick={handleClose}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"></i> Kembali
            </button>
          </div>
        </form>
        :
        <>
        {showKtp &&
          <>
            {!isLoaded ?
              <img src={`data:${ktpFile.mime_type};base64,${ktpFile.file_contents}`} alt="" className="mb-3 img-fluid"/>
              :
              <LoadingBasic/>
            }
            <button
              type="button"
              className="btn btn-dark border-0 btn-sm"
              onClick={closeSecondModal}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"></i> Kembali
            </button>
          </>
          }

          {showNpwp &&
            <>
              {!isLoaded ?
                <img src={`data:${npwpFile.mime_type};base64,${npwpFile.file_contents}`} alt="" className="mb-3 img-fluid"/>
                :
                <LoadingBasic/>
              }
              <button
                type="button"
                className="btn btn-dark border-0 btn-sm"
                onClick={closeSecondModal}
              >
                <i className="fa fa-fw fa-sm fa-arrow-left"></i> Kembali
              </button>
            </>
          }

          {showTtd &&
            <>
              {!isLoaded ?
                <img src={`data:${ttdFile.mime_type};base64,${ttdFile.file_contents}`} alt="" className="mb-3 img-fluid"/>
                :
                <LoadingBasic/>
              }
              <button
                type="button"
                className="btn btn-dark border-0 btn-sm"
                onClick={closeSecondModal}
              >
                <i className="fa fa-fw fa-sm fa-arrow-left"></i> Kembali
              </button>
            </>
          }
        </>
        }
      </LayoutModal>
    </>
  );
};

export default EditHrd;
