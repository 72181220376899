import React, { useState, useCallback, useEffect } from "react";
import { fetchPerusahaan } from "../../../api";
import { Link } from "react-router-dom";

const TopbarDropdownMouPerusahaan = () => {
  const [perusahaan, setPerusahaan] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchPerusahaan(
        "all",
        "return_arr=1&notification_mou=1&length=1"
      );
      if (res.data) {
        setPerusahaan(res.data.perusahaan);
      }
    } catch (err) {
      console.error(err.response);
    }
  }, []);

  useEffect(() => fetchData(), [fetchData]);

  return (
    <li className="nav-item no-arrow">
      <Link className="nav-link btn btn-sm btn-primary-4 rounded-3 py-1 mx-1 mb-2" to="/perusahaan-perpanjang-mou">
        <i className="fa fa-building text-warning me-0 me-sm-1" />
        <span className="d-none d-sm-inline">MoU Perlu Perpanjangan</span>
        <span className="ms-1 badge badge-danger fw-normal">{Number(perusahaan) ? perusahaan : ""}</span>
      </Link>
    </li>
  );
};

export default TopbarDropdownMouPerusahaan;
