import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { tokenState, userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import axios from "../../axios";
import { saAlert, scrollToTop } from "../../helpers";
import PageLoading from "../../components/pages/Loading";
import { Link } from "react-router-dom";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import LoadingBasic from "../../components/pages/LoadingBasic";

const PenilaianKompetensiCreate = () => {
  // Params
  const { cabang, jabatan, nama } = useParams();

  const setTitle = useSetRecoilState(titleState);

  const [namaJabatan, setNamaJabatan] = useState("");
  const [jabatanId, setJabatanId] = useState(null);

  useEffect(() => {
    setTitle("Form Penilaian");

    const searchParams = new URLSearchParams(document.location.search);
    setNamaJabatan(searchParams.get("nama_jabatan"));
    setJabatanId(searchParams.get("jabatan_id"));
  }, [setTitle]);

  return (
    <>
      <div className="bg-gradient-primary border-bottom-warning text-white p-4 rounded mb-3">
        <div className="d-flex align-items-center">
          <span className="bg-black rounded p-3 me-2">
            <i className="fa fa-2xl fa-user text-white" />
          </span>
          <table className="table table-sm table-borderless mb-0">
            <tbody>
              <tr>
                <td className="py-0 text-gray-500 fs-5">Penilaian kepada</td>
                <td className="py-0 text-gray-500 fs-5">:</td>
                <td className="py-0 text-white w-75 fs-5">{nama}</td>
              </tr>
              <tr>
                <td className="py-0 text-gray-500">Jabatan</td>
                <td className="py-0 text-gray-500">:</td>
                <td className="py-0 text-warning w-75">
                  {namaJabatan} ({cabang})
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <hr className="bg-white" />
        <h6 className="mb-0">
          <i className="fa fa-fw fa-circle-exclamation" /> Petunjuk Penilaian
        </h6>
        <p className="text-gray-500 text-sm">
          Pastikan semua form telah diisi sebelum menyimpan data.
        </p>
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-lg-2 d-flex justify-content-center align-items-center">
            <div>
              <span className="position-relative align-middle bg-gradient-warning text-black fw-semibold text-sm rounded px-2 me-2">
                <span className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center">
                  1
                </span>
              </span>{" "}
              Tidak memuaskan
            </div>
          </div>
          <div className="col-lg-2 d-flex justify-content-center align-items-center">
            <div>
              <span className="position-relative align-middle bg-gradient-warning text-black fw-semibold text-sm rounded px-2 me-2">
                <span className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center">
                  2
                </span>
              </span>{" "}
              Kurang memuaskan
            </div>
          </div>
          <div className="col-lg-2 d-flex justify-content-center align-items-center">
            <div>
              <span className="position-relative align-middle bg-gradient-warning text-black fw-semibold text-sm rounded px-2 me-2">
                <span className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center">
                  3
                </span>
              </span>{" "}
              Memuaskan
            </div>
          </div>
          <div className="col-lg-2 d-flex justify-content-center align-items-center">
            <div>
              <span className="position-relative align-middle bg-gradient-warning text-black fw-semibold text-sm rounded px-2 me-2">
                <span className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center">
                  4
                </span>
              </span>{" "}
              Sangat memuaskan
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card rounded-3 shadow">
            {/* {title &&
              <div className="card-header border-0 bg-white shadow rounded-3 py-3">
                <h6 className="mb-1 fw-semibold text-primary">{title}</h6>
                <p className="mb-0 text-secondary text-sm d-none d-sm-block">Gunakan <i className="fa fa-fw fa-keyboard" /> Shift + <i className="fa fa-fw fa-mouse" /> Mouse Scroll Up / Down untuk menggeser tabel kesamping. Pastikan <i className="fa fa-fw fa-mouse-pointer" /> kursor berada pada tabel yang ingin digeser.</p>
              </div>
            } */}
            <div className="card-body">
              {jabatanId && (
                <HasilPenilaian jabatanId={jabatanId}>
                  <FormPenilaian jabatanId={jabatanId} />
                </HasilPenilaian>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const FormPenilaian = (props) => {
  // Params
  const { id } = useParams();

  const token = useRecoilValue(tokenState);
  const history = useHistory();
  const [soalPenilaian, setSoalPenilaian] = useState(false);

  const user = useRecoilValue(userState);

  const [form, setForm] = useState({
    dinilai_id: id,
    penilai_jabatan_id: user.jabatan_id,
    dinilai_jabatan_id: props.jabatanId,
  });

  const fetchSoalPenilaian = useCallback(async () => {
    try {
      const res = await axios.get("/api/soal-penilaian?penilaian=true", {
        headers: { Authorization: "Bearer " + token },
      });
      setSoalPenilaian(res.data);
    } catch (err) {
      console.log(err);
    }
  }, [token, setSoalPenilaian]);

  useEffect(() => {
    fetchSoalPenilaian();
  }, [fetchSoalPenilaian, setForm]);

  const handleSubmit = async () => {
    try {
      const res = await axios.post("/api/penilaian-kompetensi/store", form, {
        headers: { Authorization: "Bearer " + token },
      });
      if (res.data === "success") {
        history.push("/penilaian-kompetensi");
        scrollToTop();
        saAlert("success", "Berhasil memberi penilaian !");
      } else {
        saAlert(
          "warning",
          "Semua field harus terisi !",
          "Pastikan seluruh field terisi sebelum anda melakukan submit."
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <p className="mb-2">
        <i className="fa fa-fw fa-circle-exclamation text-dark" /> Anda dapat
        memilih jawaban dibawah ini dengan klik tombol angka yang berwarna
        kuning yang ada di dalam tabel.
      </p>
      <LayoutTable>
        <thead>
          <tr className="text-nowrap text-center">
            <th className="bg-primary text-white px-3 py-1 border-bottom border-silver fw-normal">
              No
            </th>
            <th className="bg-primary text-white px-3 py-1 border-bottom border-silver fw-normal">
              Kode
            </th>
            <th className="bg-primary text-white px-3 py-1 border-bottom border-silver fw-normal">
              Tahun
            </th>
            <th className="bg-primary text-white px-3 py-1 border-bottom border-silver fw-normal">
              Aspek Soft Kompetensi
            </th>
            <th className="bg-primary text-white px-3 py-1 border-bottom border-silver fw-normal">
              Kategori & Deskripsi Skor
            </th>
          </tr>
        </thead>
        <tbody>
          {soalPenilaian ? (
            soalPenilaian.map((i, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td className="py-0 border-silver align-middle text-center">
                      {index + 1}
                    </td>
                    <td className="py-0 border-silver align-middle text-center">
                      {i.id}
                    </td>
                    <td className="py-0 border-silver align-middle text-center">
                      {i.tahun}
                    </td>
                    <td className="py-0 border-silver align-middle">
                      {i.judul}
                    </td>
                    <td className="py-0 px-0 border-silver align-middle">
                      <form>
                        <div className="my-1 py-0 px-2 text-nowrap d-flex justify-content-start align-items-center border-bottom border-silver">
                          <div className="form-check d-flex align-items-center">
                            <input
                              id={`penilaian1${i.id}`}
                              type="radio"
                              className="form-check-input border-silver py-0 my-0"
                              name={i.id}
                              value={1}
                              onClick={(e) => {
                                setForm({
                                  ...form,
                                  [e.target.name]: {
                                    soal_id: e.target.name,
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                            <label
                              htmlFor={`penilaian1${i.id}`}
                              className="my-0 py-0 me-2"
                            >
                              <span className="position-relative align-middle bg-gradient-warning text-black fw-semibold text-sm rounded px-3 me-2">
                                <span className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center">
                                  1
                                </span>
                              </span>
                            </label>
                            <label
                              htmlFor={`penilaian1${i.id}`}
                              className="mb-0"
                            >
                              {i.isi.split("||")[0]}
                            </label>
                          </div>
                        </div>
                        <div className="my-1 py-0 px-2 text-nowrap d-flex justify-content-start align-items-center border-bottom border-silver">
                          <div className="form-check d-flex align-items-center">
                            <input
                              id={`penilaian2${i.id}`}
                              type="radio"
                              className="form-check-input border-silver py-0 my-0"
                              name={i.id}
                              value={2}
                              onClick={(e) => {
                                setForm({
                                  ...form,
                                  [e.target.name]: {
                                    soal_id: e.target.name,
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                            <label
                              htmlFor={`penilaian2${i.id}`}
                              className="my-0 py-0 me-2"
                            >
                              <span className="position-relative align-middle bg-gradient-warning text-black fw-semibold text-sm rounded px-3 me-2">
                                <span className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center">
                                  2
                                </span>
                              </span>
                            </label>
                            <label
                              htmlFor={`penilaian2${i.id}`}
                              className="mb-0"
                            >
                              {i.isi.split("||")[1]}
                            </label>
                          </div>
                        </div>
                        <div className="my-1 py-0 px-2 text-nowrap d-flex justify-content-start align-items-center border-bottom border-silver">
                          <div className="form-check d-flex align-items-center">
                            <input
                              id={`penilaian3${i.id}`}
                              type="radio"
                              className="form-check-input border-silver py-0 my-0"
                              name={i.id}
                              value={3}
                              onClick={(e) => {
                                setForm({
                                  ...form,
                                  [e.target.name]: {
                                    soal_id: e.target.name,
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                            <label
                              htmlFor={`penilaian3${i.id}`}
                              className="my-0 py-0 me-2"
                            >
                              <span className="position-relative align-middle bg-gradient-warning text-black fw-semibold text-sm rounded px-3 me-2">
                                <span className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center">
                                  3
                                </span>
                              </span>
                            </label>
                            <label
                              htmlFor={`penilaian3${i.id}`}
                              className="mb-0"
                            >
                              {i.isi.split("||")[2]}
                            </label>
                          </div>
                        </div>
                        <div className="my-1 py-0 px-2 text-nowrap d-flex justify-content-start align-items-center">
                          <div className="form-check d-flex align-items-center">
                            <input
                              id={`penilaian4${i.id}`}
                              type="radio"
                              className="form-check-input border-silver py-0 my-0"
                              name={i.id}
                              value={4}
                              onClick={(e) => {
                                setForm({
                                  ...form,
                                  [e.target.name]: {
                                    soal_id: e.target.name,
                                    value: e.target.value,
                                  },
                                });
                              }}
                            />
                            <label
                              htmlFor={`penilaian4${i.id}`}
                              className="my-0 py-0 me-2"
                            >
                              <span className="position-relative align-middle bg-gradient-warning text-black fw-semibold text-sm rounded px-3 me-2">
                                <span className="position-absolute w-100 h-100 top-0 d-flex justify-content-center align-items-center">
                                  4
                                </span>
                              </span>
                            </label>
                            <label
                              htmlFor={`penilaian4${i.id}`}
                              className="mb-0"
                            >
                              {i.isi.split("||")[3]}
                            </label>
                          </div>
                        </div>
                      </form>
                    </td>
                  </tr>
                </React.Fragment>
              );
            })
          ) : (
            <tr>
              <td colSpan={100} className="py-5">
                <LoadingBasic title="Mempersiapkan pertanyaan . . ." />
              </td>
            </tr>
          )}
        </tbody>
      </LayoutTable>
      <div className="btn-group border-0">
        <button
          onClick={handleSubmit}
          type="button"
          className="btn btn-sm btn-success-3"
        >
          <i className="fa fa-fw fa-sm fa-download" /> Simpan Penilaian
        </button>
        <Link
          to="/penilaian-kompetensi"
          className="btn btn-sm btn-dark border-0"
          onClick={scrollToTop}
        >
          <i className="fa fa-fw fa-sm fa-arrow-left" /> Kembali
        </Link>
      </div>
    </>
  );
};

const HasilPenilaian = (props) => {
  const { id } = useParams();
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState(false);

  const [hasilNilai, setHasilNilai] = useState(false);

  const fetchCurrentAssessments = useCallback(async () => {
    try {
      const res = await axios.get(
        "/api/penilaian-kompetensi/" + id + "/" + props.jabatanId,
        {
          headers: { Authorization: "Bearer " + token },
        }
      );
      if (res.data) {
        if (res.data.length >= 1) {
          setHasilNilai(res.data);
          console.log(res.data);
        }
      }
      if (res.status === 200) {
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, id, setHasilNilai, setIsLoaded]);

  useEffect(() => {
    fetchCurrentAssessments();
  }, [fetchCurrentAssessments]);

  if (isLoaded) {
    if (hasilNilai) {
      return (
        <>
          <div className="mb-3 d-flex align-items-center">
            <i className="fa fa-fw fa-circle-check fa-2xl text-success-2 me-2" />
            <div>
              <h5 className="mb-0 fw-semibold">Penilaian selesai</h5>
              <p className="mb-0">
                Berikut merupakan hasil dari penilaian anda.
              </p>
            </div>
          </div>
          <LayoutTable>
            <thead>
              <tr>
                <th
                  className="bg-primary text-white fw-normal text-nowrap border-bottom border-silver py-1 text-center"
                  style={{ width: "5%" }}
                >
                  No
                </th>
                <th
                  className="bg-primary text-white fw-normal text-nowrap border-bottom border-silver py-1 text-center"
                  style={{ width: "55%" }}
                >
                  Judul
                </th>
                <th
                  className="bg-primary text-white fw-normal text-nowrap border-bottom border-silver py-1 text-center"
                  style={{ width: "40%" }}
                >
                  Point
                </th>
              </tr>
            </thead>
            <tbody>
              {hasilNilai.map((i, index) => {
                return (
                  <tr key={index}>
                    <td className="border-silver text-center">{index + 1}</td>
                    <td className="border-silver">{i.soal && i.soal.judul}</td>
                    <td className="border-silver text-center">
                      {i.value} Poin
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </LayoutTable>
          <Link
            to="/penilaian-kompetensi"
            className="btn btn-sm btn-dark border-0 rounded-3"
          >
            <i className="fa fa-fw fa-arrow-left fa-sm" /> Kembali
          </Link>
        </>
      );
    } else {
      return props.children;
    }
  } else {
    return <PageLoading />;
  }
};

export default PenilaianKompetensiCreate;
