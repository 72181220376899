import React, { useState, useCallback, useEffect } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { fetchNilai } from "../../../api";
import { semesterAjaranState, tahunAjaranState } from "../../../storage/mhs";

const TopbarDropdownAbsensi = () => {
  const [nilai, setNilai] = useState("");
  const tA = useRecoilValue(tahunAjaranState);
  const sA = useRecoilValue(semesterAjaranState);

  const [tahunAjaran] = useState(tA);
  const [semesterAjaran] = useState(sA);

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchNilai(
        "all",
        `notification_akademik=1&length=1&tahun_ajaran=${tahunAjaran}&semester_ajaran=${semesterAjaran}`
      );
      if (res.data) {
        setNilai(res.data.nilai);
      }
    } catch (err) {
      console.error(err.response);
    }
  }, [tahunAjaran, semesterAjaran]);

  useEffect(() => fetchData(), [fetchData]);

  return (
    <li className="nav-item no-arrow">
      <Link className="nav-link btn btn-sm btn-primary-4 rounded-3 py-1 mx-1 mb-2" to="/nilai-absensi-kurang">
        <i className="fa fa-calendar-check text-warning me-0 me-sm-1" />
        <span className="d-none d-sm-inline">Absensi</span>
        <span className="ms-1 badge badge-danger fw-normal">{Number(nilai) ? nilai : ""}</span>
      </Link>
    </li>
  );
};

export default TopbarDropdownAbsensi;
