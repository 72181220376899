import React, { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { titleState } from '../../storage/pages';
import BrosurDigitalCreate from '../files/BrosurDigitalCreate';

function BrosurDigital() {

    const [title, setTitle] = useRecoilState(titleState);

    useEffect(() => {
        setTitle("Brosur Digital");
    }, []);

  return (
    <div>
        <div className='row justify-content-end'>
            <div className='col-lg-3'>
                <BrosurDigitalCreate />
            </div>
        </div>

        <div className='row p-0 m-0 g-2'>
            <div className='col-2 px-1'>
                <div className='border rounded bg-silver' style={{paddingBottom: '16rem'}}></div>
            </div>
            <div className='col-2 px-1'>
                <div className='border rounded bg-silver' style={{paddingBottom: '16rem'}}></div>
            </div>
            <div className='col-2 px-1'>
                <div className='border rounded bg-silver' style={{paddingBottom: '16rem'}}></div>
            </div>
            <div className='col-2 px-1'>
                <div className='border rounded bg-silver' style={{paddingBottom: '16rem'}}></div>
            </div>
            <div className='col-2 px-1'>
                <div className='border rounded bg-silver' style={{paddingBottom: '16rem'}}></div>
            </div>
            <div className='col-2 px-1'>
                <div className='border rounded bg-silver' style={{paddingBottom: '16rem'}}></div>
            </div>
            <div className='col-2 px-1'>
                <div className='border rounded bg-silver' style={{paddingBottom: '16rem'}}></div>
            </div>
            <div className='col-2 px-1'>
                <div className='border rounded bg-silver' style={{paddingBottom: '16rem'}}></div>
            </div>
            <div className='col-2 px-1'>
                <div className='border rounded bg-silver' style={{paddingBottom: '16rem'}}></div>
            </div>
            <div className='col-2 px-1'>
                <div className='border rounded bg-silver' style={{paddingBottom: '16rem'}}></div>
            </div>
        </div>
    </div>
  )
}

export default BrosurDigital