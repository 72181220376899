import React, { useCallback, useEffect, useState } from "react";
import { fetchDepartemen, fetchPengumuman } from "../../api";
import { useSetRecoilState } from "recoil";
import { titleState } from "../../storage/pages";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import SectionPagination from "../../components/sections/Pagination";
import {
  FilterPagination,
  FilterSearch,
} from "../../components/layouts/TopContent";
import PengumumanCreate from "./Create";
import { fetchCabang } from "../../modules/cabang/apis";
import { formatDate, getOrigin } from "../../helpers";
import PengumumanEdit from "./Edit";
import PengumumanDelete from "./Delete";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const Pengumuman = () => {
  const setTitle = useSetRecoilState(titleState);

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState(10);

  const [isLoaded, setIsLoaded] = useState(false);

  const [pengumuman, setPengumuman] = useState({});

  const fetchData = useCallback(async () => {
    setIsLoaded(false);
    try {
      const res = await fetchPengumuman(
        page,
        `search=${search}&pagination=${pagination}`
      );
      setIsLoaded(true);
      setTitle(res.data.title);
      setPengumuman(res.data.pengumuman);
    } catch (err) {
      //
    }
  }, [page, search, setTitle, pagination]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [dataCabang, setDataCabang] = useState([]);

  useEffect(() => {
    const fetchdata = async () => {
      const res = await fetchCabang("all");
      if (res.data && res.data.cabang) {
        setDataCabang(res.data.cabang);
      }
    };

    fetchdata();
  }, []);

  const [dataDepartemen, setDataDepartemen] = useState([]);

  useEffect(() => {
    const fetchdata = async () => {
      const res = await fetchDepartemen("all");
      if (res.data && res.data.departemen) {
        setDataDepartemen(res.data.departemen);
      }
    };

    fetchdata();
  }, []);

  return (
    <>
      <PengumumanCreate
        dataCabang={dataCabang}
        dataDepartemen={dataDepartemen}
        onFetchData={fetchData}
      />

      <div className="row justify-content-end mb-2">
        <div className="col-md-4">
          <FilterPagination onChange={(res) => setPagination(res)} />
        </div>
        <div className="col-md-4">
          <FilterSearch onChange={(res) => setSearch(res)} />
        </div>
      </div>

      {isLoaded ? (
        <>
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>Tujuan</Th>
                <Th>Judul</Th>
                <Th>Isi</Th>
                <Th>Cabang</Th>
                <Th>Departemen</Th>
                <Th>Jenjang</Th>
                <Th>Angkatan</Th>
                <Th>Dari</Th>
                <Th>Sampai</Th>
                <Th>File</Th>
                <Th>
                  <i className="fa fa-bars"></i>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {pengumuman.data &&
                pengumuman.data.length > 0 &&
                pengumuman.data.map((p, index) => {
                  return (
                    <Tr key={index}>
                      <Td className="text-center">{index + pengumuman.from}</Td>
                      <Td className="text-center">{p.tujuan}</Td>
                      <Td>{p.judul}</Td>
                      <Td>{p.isi}</Td>
                      <Td className="text-center">
                        {dataCabang
                          .filter(
                            (dc) => parseInt(dc.id) === parseInt(p.cabang_id)
                          )
                          .map((dc) => dc.nama)}
                      </Td>
                      <Td>
                        {dataDepartemen
                          .filter(
                            (dd) =>
                              parseInt(dd.id) === parseInt(p.departemen_id)
                          )
                          .map((dd) => dd.nama)}
                      </Td>
                      <Td className="text-center">{p.jenjang}</Td>
                      <Td className="text-center">{p.tahun_angkatan}</Td>
                      <Td className="text-nowrap">{formatDate(p.dari)}</Td>
                      <Td className="text-nowrap">{formatDate(p.sampai)}</Td>
                      <Td>
                        {p.file ? (
                          <button
                            onClick={() =>
                              window.open(
                                getOrigin() + "/storage/pengumuman/" + p.file
                              )
                            }
                            className="btn btn-sm btn-success-2"
                          >
                            Open File
                          </button>
                        ) : (
                          <button className="btn btn-sm btn-dark border-0 disabled">
                            No File
                          </button>
                        )}
                      </Td>
                      <Td className="text-center aling-middle">
                        <PengumumanEdit
                          pengumuman={p}
                          dataCabang={dataCabang}
                          dataDepartemen={dataDepartemen}
                          onFetchData={fetchData}
                        />
                        <PengumumanDelete
                          pengumuman={p}
                          onFetchData={fetchData}
                        />
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>

          <SectionPagination
            links={pengumuman.links}
            onChangePage={(res) => setPage(res)}
          />
        </>
      ) : (
        "Waiting"
      )}
    </>
  );
};

export default Pengumuman;
