import React, { useEffect, useState } from "react";
import PrintHeader from "../../../components/print/Header";
import PrintFooter from "../../../components/print/Footer";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchKpi, showPegawai } from "../../../api";
import { LayoutTable, LayoutThead } from "../../../components/layouts/Table";
import { getCurrency } from "../../../helpers";
import PrintBtnPrint from "../../../components/print/BtnPrint";

const PrintLaporanKpi = () => {
  const { pegawai_id, jabatan_id } = useParams();

  const [kpi, setKpi] = useState([]);
  const [pegawai, setPegawai] = useState({});

  useEffect(() => {
    const fetchDataKpi = async () => {
      const res = await fetchKpi("all", `jabatan_id=${jabatan_id}`);
      if (res.data) {
        setKpi(res.data.kpi);
      }
    };

    const findDataPegawai = async () => {
      const res = await showPegawai(pegawai_id);
      if (res.data) {
        setPegawai(res.data);
      }
    };

    fetchDataKpi();
    findDataPegawai();
  }, [pegawai_id, jabatan_id]);

  const getValidator = (k) => {
    if (
      k.kpi_audit &&
      k.kpi_audit.filter(
        (ka) => parseInt(ka.pegawai_id) === parseInt(pegawai.id)
      ).length > 0
    ) {
      if (
        parseInt(
          k.kpi_audit.filter(
            (ka) => parseInt(ka.pegawai_id) === parseInt(pegawai.id)
          )[0].status
        ) === 1
      ) {
        return "Validated";
      } else if (
        parseInt(
          k.kpi_audit.filter(
            (ka) => parseInt(ka.pegawai_id) === parseInt(pegawai.id)
          )[0].status
        ) === 2
      ) {
        return "Invalid";
      }
    }
    return "-";
  };

  return (
    <>
      <PrintHeader></PrintHeader>
      <PrintBtnPrint />
      <div className="px-5">
        <LayoutTable>
          <thead style={{ backgroundColor: "gray", color: "white" }}>
            <tr>
              <th className="text-center align-middle">No</th>
              <th className="text-center align-middle">Pegawai</th>
              <th className="text-center align-middle">Judul</th>
              <th className="text-center align-middle">Keterangan</th>
              <th className="text-center align-middle">Self Assignment</th>
              <th className="text-center align-middle">Capaian</th>
              <th className="text-center align-middle">Target</th>
              <th className="text-center align-middle">Satuan</th>
              <th className="text-center align-middle">Bobot</th>
              <th className="text-center align-middle">Nilai</th>
              <th className="text-center align-middle">Validator</th>
            </tr>
          </thead>
          <tbody>
            {kpi.length > 0 &&
              kpi.map((k, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td>{pegawai.nama}</td>
                    <td>{k.nama}</td>
                    <td>{k.keterangan}</td>
                    <td className="text-center">
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) => ka.self_assesment || "0")}
                    </td>
                    <td>
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) =>
                            ka.satuan === "Rupiah"
                              ? getCurrency(ka.capaian)
                              : ka.capaian
                          )}
                    </td>
                    <td>
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) =>
                            ka.satuan === "Rupiah"
                              ? getCurrency(ka.target)
                              : ka.target
                          )}
                    </td>
                    <td>
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) => ka.satuan)}
                    </td>
                    <td>
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) => ka.bobot)}
                    </td>
                    <td>
                      {k.kpi_audit &&
                        k.kpi_audit
                          .filter(
                            (ka) =>
                              parseInt(ka.pegawai_id) === parseInt(pegawai.id)
                          )
                          .map((ka) => ka.nilai ? (ka.nilai % 1 !== 0 ? ka.nilai.toFixed(2) : ka.nilai) : "0")}
                    </td>
                    <td>{getValidator(k)}</td>
                  </tr>
                );
              })}
          </tbody>
        </LayoutTable>
      </div>
      <PrintFooter></PrintFooter>
    </>
  );
};

export default PrintLaporanKpi;
