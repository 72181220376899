import React, { useRef } from 'react'
import './print.css'

function PrintDebug() {

    const printRef = useRef(null);

    const handlePrint = () => {
        if (printRef.current) {
            window.scrollTo(0,0);
            setTimeout(() => {
                window.print();
            }, 500);
        }
    }

  return (
    <div ref={printRef}>
        <header>Header Content</header>
        <div>
            <div class="page-break">
                <h1>Lorem ipsum dolor sit amet.</h1>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Necessitatibus atque, cupiditate nisi libero unde exercitationem reiciendis autem veritatis sed vero?</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veritatis odit, rerum assumenda iusto ab consequatur cupiditate accusamus tenetur, similique, dolore ipsa. Beatae possimus, soluta architecto quae minus at ipsam tempora mollitia totam dolorem quasi voluptatum itaque reprehenderit dignissimos magni dolore expedita vel esse! Rem soluta, quod excepturi laudantium fugit error.</p>
                <ul>
                    <li>Lorem ipsum dolor sit.</li>
                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing.</li>
                    <li>Lorem, ipsum dolor.</li>
                    <li>Lorem ipsum dolor sit amet consectetur.</li>
                </ul>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laboriosam quos esse officiis reprehenderit pariatur vero nobis enim exercitationem cum. Repellendus eum commodi molestias, ab voluptate voluptas est unde sed culpa veniam sapiente laboriosam harum minus. Mollitia nam culpa eligendi itaque corporis fugiat nobis rerum perferendis adipisci autem cupiditate, molestias iure impedit vel, quaerat quo voluptas, quos voluptatibus reiciendis blanditiis ipsum? Reprehenderit ad error dolorum veniam tempora doloremque dolore repellat atque!</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero laudantium unde natus sit officiis doloremque dolores inventore eveniet ea. Fuga.</p>
            </div>
        </div>
        <footer>Footer Content</footer>
        <button onClick={() => window.print()}>Print basic</button>
        <button onClick={handlePrint}>Print with useRef</button>
    </div>
  )
}

export default PrintDebug