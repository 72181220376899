import React from "react";
import { deleteBeritaTerkini, deletePengumuman } from "../../api";
import { saAlert, saConfirm } from "../../helpers";
import SmallButton from "../../components/forms/SmallButton";

const BTDelete = ({ bt, fetchdata }) => {
  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus postingan ini ?").then(async (res) => {
      if (res.isConfirmed) {
        const res = await deleteBeritaTerkini(bt.id);
        if (res.data && res.data.status) {
          fetchdata();
          saAlert(res.data.status, res.data.msg);
        }
      }
    });
  };

  return (
    <SmallButton onClick={handleDelete} type='delete'/>
  );
};

export default BTDelete;
