import React, { useCallback, useContext, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { LayoutTable, LayoutThead } from "../../../components/layouts/Table";
import {
  FilterCabangDinamis,
  FilterTahun,
} from "../../../components/layouts/TopContent";
import { titleState } from "../../../storage/pages";
import {
  fetchCeklistMonitoringValue,
  patchCeklistMonitoringValue,
  // postCeklistMonitoringValue,
} from "../apis";
import { FilterTahap } from "../Filter";
import { CeklistMonitoringValueContext } from "../Routes";

const CeklistMonitoringValue = () => {
  const setTitle = useSetRecoilState(titleState);

  const [page] = useState("all");
  const [cabang, setCabang] = useState("");
  const [tahun, setTahun] = useState("");
  const [tahap, setTahap] = useState("");

  const [ceklistMonitoringValue, setCeklistMonitoringValue] = useState([]);

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchCeklistMonitoringValue(page, "");
      if (res.data && res.data.ceklist_monitoring_value) {
        setCeklistMonitoringValue(res.data.ceklist_monitoring_value);
        setTitle(res.data.title);
      }
    } catch (err) {
      console.error(err.response);
    }
  }, [setTitle, page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <FilterCabangDinamis
            value={cabang}
            onChange={(res) => setCabang(res)}
          />
        </div>
        <div className="col-md-4">
          <FilterTahun value={tahun} onChange={(res) => setTahun(res)} />
        </div>
        <div className="col-md-4">
          <FilterTahap value={tahap} onChange={(res) => setTahap(res)} />
        </div>
      </div>

      <LayoutTable>
        <LayoutThead>
          <th>no</th>
          <th>Tanggal</th>
          <th>DEPT.</th>
          <th>TS Ref. (No. Referensi)</th>
          <th>NON-CONFORMITIES (Detil Ketidaksesuaian)</th>
          <th>CORRECTION (Koreksi)</th>
          <th>CORRECTIVE ACTIONS PLAN (Rencana Tindakan Perbaikan)</th>
          <th>TARGET DATE (Tgl. Target)</th>
          <th>P.I.C (Penanggung jawab)</th>
          <th>Hasil Tindak Lanjut</th>
          <th>KET.</th>
        </LayoutThead>
        <tbody>
          <TbodyContent
            ceklistMonitoringValue={ceklistMonitoringValue}
            type="ts"
          />
          <TbodyContent
            ceklistMonitoringValue={ceklistMonitoringValue}
            type="sr"
          />
        </tbody>
      </LayoutTable>
    </>
  );
};

const TbodyContent = (props) => {
  const { ceklistMonitoringValue, type } = props;
  const { pegawai } = useContext(CeklistMonitoringValueContext);

  const handleChange = async (e, cmv_id) => {
    console.log(e.target.value + cmv_id);

    try {
      const res = await patchCeklistMonitoringValue(cmv_id, {
        [e.target.name]: e.target.value,
      });
      console.log(res.data);
    } catch (error) {
      //
    }
  };

  return ceklistMonitoringValue
    .filter((cmv) => parseInt(cmv[type]) === 1)
    .map((cmv, index) => {
      return (
        <tr key={index}>
          <td className="text-center">{index + 1}</td>
          <td>
            {cmv.ceklist_monitoring && cmv.ceklist_monitoring.tanggal_efektif}
          </td>
          <td>
            {cmv.ceklist_monitoring && cmv.ceklist_monitoring.departemen_id}
          </td>
          <td>{`SR - ${index + 1}`}</td>
          <td>{cmv.catatan}</td>
          <td>
            <textarea
              name="correction"
              onBlur={(e) => handleChange(e, cmv.id)}
              rows={5}
              className="w-100"
            ></textarea>
          </td>
          <td>
            <textarea
              name="corrective"
              onBlur={(e) => handleChange(e, cmv.id)}
              rows={5}
              className="w-100"
            ></textarea>
          </td>
          <td>
            <input
              type="date"
              name="target_date"
              onChange={(e) => handleChange(e, cmv.id)}
              id=""
              className="w-100"
            />
          </td>
          <td>
            <select name="pegawai_id" onChange={(e) => handleChange(e, cmv.id)}>
              <option value="">Pilih</option>
              {pegawai.length > 0 &&
                pegawai.map((p, index) => {
                  return (
                    <React.Fragment key={index}>
                      <option value={p.id}>{p.nama}</option>
                    </React.Fragment>
                  );
                }, [])}
            </select>
          </td>
          <td>
            <textarea
              name="hasil_tindak_lanjut"
              onBlur={(e) => handleChange(e, cmv.id)}
              rows={5}
              className="w-100"
            ></textarea>
          </td>
          <td></td>
        </tr>
      );
    });
};

export default CeklistMonitoringValue;
