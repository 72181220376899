import React, { useCallback, useEffect, useState } from 'react'
import SmallButton from '../../components/forms/SmallButton';
import LayoutModal, { NewSubmitButton } from '../../components/layouts/Modal';
import { deleteArusKasList, fetchArusKasList, patchArusKasList, postArusKasList } from '../../api';
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../components/layouts/TableNew';
import { FilterSearch, LayoutAddBtn } from '../../components/layouts/TopContent';
import FormSelect from '../../components/forms/Select';
import FormInput from '../../components/forms/Input';
import FormTextarea from '../../components/forms/Textarea';
import FormInputCurrency from '../../components/forms/InputCurrency';
import { getCurrency, saConfirm } from '../../helpers';

function ArusKasList(props) {

    const {fetchdata, arusKas, rkatList} = props;

    const [modal, setModal] = useState(false);
    const [create, setCreate] = useState(false);
    const [edit, setEdit] = useState(false);

    const [arusKasList, setAruskasList] = useState([]);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});
    const [search, setSearch] = useState("");
    
    const fetchdataAkl = useCallback( async () => {
        try {
            const res = await fetchArusKasList('all', `arus_kas_id=${arusKas.id}&search=${search}`);
            setAruskasList(res.data.arus_kas_list);
        } catch (error) {
            console.log(error);
        }
    }, [arusKas.id, search]);

    const [onMount, setOnMount] = useState(false);

    useEffect(() => {
        setOnMount(true);
        if (onMount === true) {
            fetchdataAkl();
        }
    }, [fetchdataAkl])

    const handleOpen = () => {
        setModal(true);
        fetchdataAkl();
    }

    const handleClose = () => {
        setModal(false);
        setCreate(false);
        setForm({});
        setErrors({});
    }

    const handleOpenCreate = () => {
        setCreate(true);
    
    }
    const handleCloseCreate = () => {
        setCreate(false);
        setForm({});
        setErrors({});
    }
    
    const handleOpenEdit = (form) => {
        setEdit(true);
        setForm(form);
    
    }
    const handleCloseEdit = () => {
        setEdit(false);
        setForm({});
        setErrors({});
    }

    const handleChange = (e) => {
        setForm((prevState) => ({...prevState,[e.target.name]:e.target.value}));
    }

    const handleSubmitCreate = async (e) => {
        e.preventDefault();
        setErrors({});
        try {
            const res = await postArusKasList({...form, arus_kas_id: arusKas.id});
            setCreate(false);
            fetchdataAkl();
            setForm({});
            console.log(res.data);
        } catch (error) {
            console.log(error.response);
            setErrors(error.response && error.response.data && error.response.data.errors);
        }
    }

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        setErrors({});
        try {
            const res = await patchArusKasList(form.id, {...form, arus_kas_id: arusKas.id});
            setEdit(false);
            fetchdataAkl();
            console.log(res.data);
            setForm({});
        } catch (error) {
            console.log(error.response);
            setErrors(error.response && error.response.data && error.response.data.errors);
        }
    }

    const handleDelete = (id) => {
        saConfirm("warning", "Yakin hapus Arus kas List ini?").then( async (res) => {
            if (res.isConfirmed) {
                try {
                    const res = await deleteArusKasList(id);
                    console.log(res.data);
                    fetchdataAkl();
                } catch (error) {
                    console.log(error.response);
                }
            }
        })
    }

  return (
    <>
        <SmallButton onClick={handleOpen} margin='me-1' type='custom' icon='list' buttonColor='primary-3' text='Arus Kas List'/>

        <LayoutModal
            title='Arus Kas List'
            modal={modal}
            onClose={handleClose}
        >
            {!(create || edit) ?
                <>
                    <div className='row justify-content-center mb-3'>
                        <div className='col-lg-6'>
                            <FilterSearch hideLabel onChange={(res) => setSearch(res)} />
                        </div>
                    </div>

                    <LayoutAddBtn onClick={handleOpenCreate} add='Tambah Arus Kas List'/>

                    <TableNew>
                        <Thead>
                            <Tr className='text-center'>
                                <Th>No</Th>
                                <Th>RKAT ID</Th>
                                <Th>No Bukti</Th>
                                <Th>Tanda Terima</Th>
                                <Th>Keterangan</Th>
                                <Th>Debet</Th>
                                <Th>Kredit</Th>
                                <Th><i className='fa fa-fw fa-cog'/></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {arusKasList &&
                                arusKasList.map((akl, index) => {
                                    return (
                                        <Tr key={index}>
                                            <Td className='text-center'>{index + 1}</Td>
                                            <Td className='text-center'>{akl.rkat_list_id}</Td>
                                            <Td className='text-center'>{akl.no_bukti}</Td>
                                            <Td>{akl.tt}</Td>
                                            <Td>{akl.keterangan}</Td>
                                            <Td className='text-end'>Rp.{getCurrency(akl.debet)}</Td>
                                            <Td className='text-end'>Rp.{getCurrency(akl.kredit)}</Td>
                                            <Td className='text-center'>
                                                <SmallButton margin='me-1' type='edit' onClick={() => handleOpenEdit(akl)}/>
                                                <SmallButton type='delete' onClick={() => handleDelete(akl.id)}/>
                                            </Td>
                                        </Tr>       
                                    )
                                })
                            }
                        </Tbody>
                    </TableNew>
                </>
                :
                <>
                    {create &&
                        <>
                        <h6>Tambah Arus Kas List</h6>
                        <form onSubmit={handleSubmitCreate}>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <FormSelect
                                        label='RKAT ID'
                                        name='rkat_list_id'
                                        onChange={handleChange}
                                        error={errors.rkat_list_id}
                                    >
                                        <option value="">Pilih</option>
                                        {rkatList &&
                                            rkatList.map((rl, index) => {
                                                return (
                                                    <option key={index} value={rl.id}>{`[${rl.kode}] - ${rl.rencana}`}</option>
                                                )
                                            })
                                        }
                                    </FormSelect>
                                </div>
                                <div className='col-lg-4'>
                                    <FormInput
                                        label='No Bukti'
                                        name='no_bukti'
                                        onChange={handleChange}
                                        error={errors.no_bukti}
                                    />
                                </div>
                                <div className='col-lg-4'>
                                    <FormInput
                                        label='Tanda Terima'
                                        name='tt'
                                        onChange={handleChange}
                                        error={errors.tt}
                                    />
                                </div>
                                <div className='col-lg-6 mb-3'>
                                    <FormInputCurrency
                                        label='debet'
                                        name='debet'
                                        value={form.debet}
                                        onChange={handleChange}
                                        error={errors.debet}
                                    />
                                </div>
                                <div className='col-lg-6 mb-3'>
                                    <FormInputCurrency
                                        label='kredit'
                                        name='kredit'
                                        value={form.kredit}
                                        onChange={handleChange}
                                        error={errors.kredit}
                                    />
                                </div>
                                <div className='col-lg-12 mb-3'>
                                    <FormTextarea
                                        label='Keterangan'
                                        name='keterangan'
                                        onChange={handleChange}
                                        error={errors.keterangan}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <NewSubmitButton onClose={handleCloseCreate}/>
                            </div>
                        </form>
                        </>
                    }
                    {edit &&
                        <>
                        <h6>Edit Arus Kas List</h6>
                        <form onSubmit={handleSubmitEdit}>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    <FormSelect
                                        label='RKAT ID'
                                        name='rkat_list_id'
                                        value={form.rkat_list_id}
                                        disabled
                                        // onChange={handleChange}
                                        error={errors.rkat_list_id}
                                    >
                                        <option value="">Pilih</option>
                                        {rkatList &&
                                            rkatList.map((rl, index) => {
                                                return (
                                                    <option key={index} value={rl.id}>{`[${rl.kode}] - ${rl.rencana}`}</option>
                                                )
                                            })
                                        }
                                    </FormSelect>
                                </div>
                                <div className='col-lg-4'>
                                    <FormInput
                                        label='No Bukti'
                                        name='no_bukti'
                                        value={form.no_bukti}
                                        onChange={handleChange}
                                        error={errors.no_bukti}
                                    />
                                </div>
                                <div className='col-lg-4'>
                                    <FormInput
                                        label='Tanda Terima'
                                        name='tt'
                                        value={form.tt}
                                        onChange={handleChange}
                                        error={errors.tt}
                                    />
                                </div>
                                <div className='col-lg-6 mb-3'>
                                    <FormInputCurrency
                                        label='debet'
                                        name='debet'
                                        value={form.debet}
                                        onChange={handleChange}
                                        error={errors.debet}
                                    />
                                </div>
                                <div className='col-lg-6 mb-3'>
                                    <FormInputCurrency
                                        label='kredit'
                                        name='kredit'
                                        value={form.kredit}
                                        onChange={handleChange}
                                        error={errors.kredit}
                                    />
                                </div>
                                <div className='col-lg-12 mb-3'>
                                    <FormTextarea
                                        label='Keterangan'
                                        name='keterangan'
                                        value={form.keterangan}
                                        onChange={handleChange}
                                        error={errors.keterangan}
                                    />
                                </div>
                            </div>

                            <div className='row'>
                                <NewSubmitButton onClose={handleCloseEdit}/>
                            </div>
                        </form>
                        </>
                    }
                </>
            }
        </LayoutModal>
    </>
  )
}

export default ArusKasList