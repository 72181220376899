import React from "react";

const Akreditasi = (props) => {
  return (
    <div className="h-100 bg-white text-center">
      <h1 className="pt-5">Akreditasi & Surat Ijin Manajemen</h1>
      <img
        src="https://manajemen-alfaprima.com/img/alfaprima/akreditasi.jpeg"
        alt=""
        style={{ transform: "rotate(90deg)" }}
      />

      <img
        src="https://manajemen-alfaprima.com/img/alfaprima/ijin_kampus.jpeg"
        alt=""
      />
    </div>
  );
};

export default Akreditasi;
