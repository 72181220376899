import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchKelas } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterCabang,
  FilterPagination,
  FilterSearch,
  FilterTahunAjaran,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { getHari } from "../../helpers";
import { semesterAjaranState, tahunAjaranState } from "../../storage/mhs";
import { titleState } from "../../storage/pages";
import PengajaranList from "../pengajaran/List";
import KelasAngketDosenDetail from "./AngketDosenDetail";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const KelasPjm = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);
  const tA = useRecoilValue(tahunAjaranState);
  const sA = useRecoilValue(semesterAjaranState);

  useEffect(() => setTitle("Kelas Perkuliahan"), [setTitle]);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [tahunAjaran, setTahunAjaran] = useState(tA);
  const [semesterAjaran, setSemesterAjaran] = useState(sA);
  const [cabang, setCabang] = useState("");
  const [pagination, setPagination] = useState(10);

  // Table Sorting
  const [tableKode, setTableKode] = useState("");
  const [tableMatkul, setTableMatkul] = useState("");
  const [tableDosen, setTableDosen] = useState("");
  const [tableJamkul, setTableJamkul] = useState("");

  const handleResetFilter = () => {
    setTableKode("");
    setTableMatkul("");
    setTableDosen("");
    setTableJamkul("");
  };

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchKelas(
        page,
        `with_nilai_dosen_avg=true&search=${search}&tahun_ajaran=${tahunAjaran}&semester_ajaran=${semesterAjaran}&table_kode=${tableKode}&table_matkul=${tableMatkul}&table_dosen=${tableDosen}&table_jamkul=${tableJamkul}&cabang=${cabang}&pagination=${pagination}`
      );
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [
    page,
    search,
    tahunAjaran,
    semesterAjaran,
    tableKode,
    tableMatkul,
    tableDosen,
    tableJamkul,
    cabang,
    pagination,
  ]);

  useEffect(() => {
    setTitle("Kelas Perkuliahan");
    fetchData();
  }, [setTitle, fetchData]);

  const countAbsensi = (i) => {
    let absensi =
      i.pengajaran &&
      i.pengajaran.length > 0 &&
      i.pengajaran.filter((p) => parseInt(p.status) !== 9 && p.absensi.length > 0).length;
    return (
      absensi < 14 && (
        <span className="text-danger">
          (Absensi - {14 - absensi} Pertemuan)
        </span>
      )
    );
  };

  const countPembahasan = (i) => {
    let pembahasan = i.pengajaran.filter(
      (p) => parseInt(p.status) !== 9 && p.pembahasan !== null
    ).length;
    return (
      pembahasan < 14 && (
        <span className="text-warning">(Pembahasan - {14 - pembahasan})</span>
      )
    );
  };

  const countTugas = (i) => {
    let tugas = i.pengajaran.filter(
      (p) => parseInt(p.status) !== 9 && p.tugas !== null
    ).length;
    return (
      tugas < 12 && <span className="text-info">(Tugas - {12 - tugas})</span>
    );
  };

  const countHambatan = (i) => {
    let hambatan = i.pengajaran.filter(
      (p) => parseInt(p.status) !== 9 && p.hambatan !== null
    ).length;
    return (
      hambatan < 12 && (
        <span className="text-primary">(Hambatan - {12 - hambatan})</span>
      )
    );
  };

  const countBuktiPengajaran = (i) => {
    let bukti_pengajaran = i.pengajaran.filter(
      (p) => parseInt(p.status) !== 9 && p.bukti_pengajaran !== null
    ).length;
    return (
      bukti_pengajaran < 12 && `(BuktiPengajaran - ${12 - bukti_pengajaran}) `
    );
  };

  const getPenilaianDosenDetail = (kelas, jenis) => {
    if (kelas.penilaian_dosen && kelas.penilaian_dosen.length > 0) {
      let data = kelas.penilaian_dosen.map((pd) => pd[jenis]);
      return (
        parseFloat(data.reduce((total, curr) => parseFloat(total) + parseFloat(curr))) /
        parseFloat(data.length)
      ).toFixed(2);
    }
  };

  if (isLoaded) {
    return (
      <>
        <div className="row mb-2">
          <div className="col-4">
            <FilterCabang onChange={(res) => setCabang(res)} />
          </div>
          <div className="col-4">
            <FilterTahunAjaran
              onChangeTahun={(res) => setTahunAjaran(res)}
              onChangeSemester={(res) => setSemesterAjaran(res)}
              valueTahun={tahunAjaran}
              valueSemester={semesterAjaran}
            />
          </div>
          <div className="col-4">
            <FilterSearch
              onChange={(val) => {
                setPage(1);
                setSearch(val);
              }}
            />
          </div>
        </div>
        <div className="row mb-2">
          <div className="col-4">
            <FilterPagination onChange={(res) => setPagination(res)} />
          </div>
        </div>
        {items.data.length > 0 ? (
          <TableNew>
            <Thead>
              <Tr className='text-center'>
                <Th className="text-nowrap">No</Th>
                <Th className="text-nowrap">
                  <select
                    className="border-0 rounded"
                    value={tableKode}
                    onChange={(e) => setTableKode(e.target.value)}
                  >
                    <option value="">Kode</option>
                    <option value="1">Kode ++</option>
                    <option value="0">Kode --</option>
                  </select>
                </Th>
                <Th className="text-nowrap text-center">Cabang</Th>
                <Th className="text-nowrap">Angket Dosen</Th>
                <Th className="text-nowrap text-center">Penampilan</Th>
                <Th className="text-nowrap text-center">Kepribadian</Th>
                <Th className="text-nowrap text-center">Penguasaan Materi</Th>
                <Th className="text-nowrap text-center">Mudah Diterima</Th>
                <Th className="text-nowrap text-center">Semangat Mengajar</Th>
                <Th className="text-nowrap text-center">Ketepatan Waktu</Th>
                <Th className="text-nowrap text-center">Rata - Rata</Th>
                <Th className="text-nowrap">Tahun Ajaran</Th>
                <Th className="text-nowrap">
                  <select
                    className="border-0 rounded"
                    value={tableMatkul}
                    onChange={(e) => setTableMatkul(e.target.value)}
                  >
                    <option value="">Mata Kuliah</option>
                    <option value="1">Mata Kuliah ++</option>
                    <option value="0">Mata Kuliah --</option>
                  </select>
                </Th>
                <Th className="text-nowrap">Semester</Th>
                <Th className="text-nowrap">Jumlah Pertemuan</Th>
                <Th className="text-nowrap">
                  <select
                    className="border-0 rounded"
                    value={tableDosen}
                    onChange={(e) => setTableDosen(e.target.value)}
                  >
                    <option value="">Dosen</option>
                    <option value="1">Dosen ++</option>
                    <option value="0">Dosen --</option>
                  </select>
                </Th>
                <Th className="text-nowrap">Ruangan</Th>
                <Th className="text-nowrap">
                  <select
                    className="border-0 rounded"
                    value={tableJamkul}
                    onChange={(e) => setTableJamkul(e.target.value)}
                  >
                    <option value="">Jam Kuliah</option>
                    <option value="1">Jam Kuliah ++</option>
                    <option value="0">Jam Kuliah --</option>
                  </select>
                </Th>
                <Th className="text-nowrap">Jadwal</Th>
                <Th className="text-nowrap">Projector</Th>
                <Th className="text-nowrap">% Kehadiran</Th>
                <Th className="text-nowrap">% Tugas</Th>
                <Th className="text-nowrap">% Uts</Th>
                <Th className="text-nowrap">% Uas</Th>
                <Th className="text-nowrap">% Revisi</Th>
                <Th className="text-nowrap">Tugas</Th>
                <Th className="text-nowrap">UTS</Th>
                <Th className="text-nowrap">UAS</Th>
                <Th className="text-nowrap">Keterangan</Th>
                <Th className="text-nowrap">
                  <span
                    type="button"
                    onClick={handleResetFilter}
                    className="bg-white px-1 rounded text-success"
                  >
                    Reset Filter Table
                  </span>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center text-nowrap">
                      {items.from + index}
                    </Td>
                    <Td className="text-center text-nowrap">{i.kode}</Td>
                    <Td className="text-nowrap text-center">{i.cabang}</Td>
                    <Td className="text-nowrap text-center">
                      <KelasAngketDosenDetail
                        kelas={i}
                        krs={i.krs}
                        penilaian_dosen={i.penilaian_dosen}
                        mhs={i.mhs}
                      />
                    </Td>
                    <Td className="text-nowrap text-center">
                      {getPenilaianDosenDetail(i, "penampilan")}
                    </Td>
                    <Td className="text-nowrap text-center">
                      {getPenilaianDosenDetail(i, "kepribadian")}
                    </Td>
                    <Td className="text-nowrap text-center">
                      {getPenilaianDosenDetail(i, "penguasaan_materi")}
                    </Td>
                    <Td className="text-nowrap text-center">
                      {getPenilaianDosenDetail(i, "mudah_diterima")}
                    </Td>
                    <Td className="text-nowrap text-center">
                      {getPenilaianDosenDetail(i, "semangat_mengajar")}
                    </Td>
                    <Td className="text-nowrap text-center">
                      {getPenilaianDosenDetail(i, "ketepatan_waktu")}
                    </Td>
                    <Td className="text-nowrap text-center">
                      {i.nilai_dosen_avg && i.nilai_dosen_avg.avg >= 3 && (
                        <span className="text-success">
                          {i.nilai_dosen_avg.avg}
                        </span>
                      )}
                      {i.nilai_dosen_avg && i.nilai_dosen_avg.avg < 3 && (
                        <span className="text-danger">
                          {i.nilai_dosen_avg.avg}
                        </span>
                      )}
                    </Td>
                    <Td className="text-nowrap text-center">
                      {i.tahun_ajaran}
                    </Td>
                    <Td className="text-nowrap">{i.matkul && i.matkul.nama}</Td>
                    <Td className="text-nowrap text-center">{i.semester}</Td>
                    <Td className="text-nowrap text-center">
                      {i.jumlah_pertemuan}
                    </Td>
                    <Td className="text-nowrap">{i.dosen && i.dosen.nama}</Td>
                    <Td className="text-nowrap">
                      {i.ruangan && i.ruangan.nama}
                    </Td>
                    <Td className="text-nowrap">
                      {i.jamkul &&
                        getHari(i.jamkul.hari) +
                          " || " +
                          i.jamkul.dari +
                          "-" +
                          i.jamkul.sampai}
                    </Td>
                    <Td className="text-nowrap text-center">
                      {i.jadwal === 1 ? "Pagi" : "Sore"}
                    </Td>
                    <Td className="text-nowrap text-center">
                      {i.projector === 1 ? "Ya" : "Tidak"}
                    </Td>
                    <Td className="text-nowrap text-center">
                      {i.persentasi_kehadiran}%
                    </Td>
                    <Td className="text-nowrap text-center">
                      {i.persentasi_tugas}%
                    </Td>
                    <Td className="text-nowrap text-center">
                      {i.persentasi_uts}%
                    </Td>
                    <Td className="text-nowrap text-center">
                      {i.persentasi_uas}%
                    </Td>
                    <Td className="text-nowrap text-center">
                      {i.persentasi_revisi}%
                    </Td>
                    <Td className="text-nowrap text-center">
                      {i.krs && i.krs.length > 0 && i.krs.length}
                      {`/`}
                      <span className="text-success">
                        {i.krs &&
                          i.krs.length > 0 &&
                          i.krs.filter((k) => k.nilai && k.nilai.tugas).length}
                      </span>
                    </Td>
                    <Td className="text-nowrap text-center">
                      {i.krs && i.krs.length > 0 && i.krs.length}
                      {`/`}
                      <span className="text-success">
                        {i.krs &&
                          i.krs.length > 0 &&
                          i.krs.filter((k) => k.nilai && k.nilai.uts).length}
                      </span>
                    </Td>
                    <Td className="text-nowrap text-center">
                      {i.krs && i.krs.length > 0 && i.krs.length}
                      {`/`}
                      <span className="text-success">
                        {i.krs &&
                          i.krs.length > 0 &&
                          i.krs.filter((k) => k.nilai && k.nilai.uas).length}
                      </span>
                    </Td>
                    <Td className="text-nowrap">
                      {countAbsensi(i)}
                      {countPembahasan(i)}
                      {countTugas(i)}
                      {countHambatan(i)}
                      {countBuktiPengajaran(i)}
                    </Td>
                    <Td className="text-nowrap text-center d-flex justify-content-center">
                      <PengajaranList
                        kelas={i}
                        pengajaran={
                          i.pengajaran &&
                          i.pengajaran.length > 0 &&
                          i.pengajaran[0]
                        }
                        tahunAjaran={tahunAjaran}
                        semesterAjaran={semesterAjaran}
                      />
                      <i className="mr-1" />
                      <select
                        className="rounded border-silver text-sm"
                        value="opsi"
                        onChange={(e) => window.open(e.target.value)}
                      >
                        <option value="opsi" disabled>
                          Opsi
                        </option>
                        <option value={`/kelas-angket-dosen/${i.id}`}>
                          Angket Dosen
                        </option>
                      </select>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          from={items.from}
          to={items.to}
          total={items.total}
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default KelasPjm;
