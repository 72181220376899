const TableSection = props => {
  return (
    <div className="table-responsive">
      <table className="table table-sm table-bordered table-striped">
        <thead className="bg-primary text-white text-center">
          {props.thead}
        </thead>
        <tbody>
          {props.tbody}
        </tbody>
      </table>
    </div>
  )
}

export default TableSection