import React, { useRef, useState } from "react";
import { patchProgkam } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";

const ProgkamEditLepasan = (props) => {
  const { progkam, bidang, penyelenggara, onFetchData } = props;
  const formRef = useRef();

  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleOpen = () => {
    setForm(progkam);
    setModal(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    patchProgkam(form.id, { ...form, lepasan: true })
      .then((res) => {
        console.log(res.data);
        if (res.data === "success") {
          onFetchData();
          handleClose();
          saAlert("success", "Berhasil ubah data Program Lepasan !");
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      });
  };

  return (
    <>
      <span type="button" className="badge badge-success" title="Ubah Data">
        <i className="fa fa-edit" onClick={handleOpen} />
      </span>
      <LayoutModal
        modal={modal}
        title="Ubah Data Program Lepasan"
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={formRef}>
          <FormInput
            label="NIM Mahasiswa"
            name="nim_mhs"
            value={form.nim_mhs}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.nim_mhs}
          />
          <FormInput
            label="Nama Kegiatan"
            name="nama"
            value={form.nama}
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.nama}
          />
          <div className="row">
            <div className="col-md">
              <FormSelect
                label="Penyelenggara"
                name="penyelenggara_id"
                value={form.penyelenggara_id}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.penyelenggara_id}
              >
                <option value="">Pilih</option>
                {penyelenggara &&
                  penyelenggara.length > 0 &&
                  penyelenggara.map((i, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={i.id}>{i.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col-md">
              <FormSelect
                label="Bidang"
                name="bidang_id"
                value={form.bidang_id}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.bidang_id}
              >
                <option value="">Pilih</option>
                {bidang &&
                  bidang.length > 0 &&
                  bidang.map((i, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={i.id}>{i.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
          </div>
          <div className="row">
            <div className="col-md">
              <FormInput
                label="Tanggal"
                name="tanggal"
                type="date"
                value={form.tanggal}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.tanggal}
              />
            </div>
          </div>
          <FormTextarea
            label="Tempat"
            name="tempat"
            value={form.tempat}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.tempat}
          />
          <div className="row">
            <div className="col-md">
              <FormInput
                label="Mulai"
                name="mulai"
                type="time"
                value={form.mulai}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.mulai}
              />
            </div>
            <div className="col-md">
              <FormInput
                label="Selesai"
                name="selesai"
                type="time"
                value={form.selesai}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.selesai}
              />
            </div>
            <div className="col-md">
              <FormInput
                label="Nilai"
                name="nilai"
                type="number"
                value={form.nilai}
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={errors.nilai}
              />
            </div>
          </div>
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success">
              Ubah
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={handleClose}
            >
              Batal
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default ProgkamEditLepasan;
