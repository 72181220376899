import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { findPendaftar } from "../../api";
import PageLoading from "../../components/pages/Loading";
import { userState } from "../../storage/auth";

const PendaftarInvoiceAfiliasi = () => {
  const user = useRecoilValue(userState);
  const { id } = useParams();
  let d = new Date();

  const [print, setPrint] = useState(false);

  const [items, setItems] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await findPendaftar(id, `cetak_invoice=true`);
        setItems(res.data);
        setIsLoaded(true);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id]);

  if (isLoaded) {
    return (
      <>
        <Helmet>
          <body className="bg-white" />
          <style type="text/css">
            {`
              * {
                font-size: 15pt;
              }
              @media print{
                @page {
                  size: potrait
                }
              }
              * {
                font-family: 'Cambria', sans-serif;
                color: #000000;
              }
            `}
          </style>
        </Helmet>
        <div className="container px-5 py-3 mt-5">
          <div className="row mb-2 border-bottom border-primary border-3">
            <div className="col">
              <img
                src="https://manajemen-alfaprima.com/img/alfa-prima-logo-tulisan.png"
                alt=""
                className="img-fluid w-50"
              />
            </div>
            <div className="col text-right mt-1 mr-3">
              <h3 className="py-0 my-0 text-primary">I N V O I C E</h3>
              {user.cabang === "denpasar" && (
                <p className="py-0 my-0">082-146-645-819</p>
              )}
              {user.cabang === "karangasem" && (
                <p className="py-0 my-0">081-990-101-345</p>
              )}
              {user.cabang === "singaraja" && (
                <p className="py-0 my-0">081-2464-51-216</p>
              )}
              <p style={{ textTransform: "capitalize" }} className="py-0 my-0">
                Alfa Prima - {` `}
                <span style={{ textTransform: "capitalize" }}>
                  {user.cabang}
                </span>
              </p>
              <p className="pt-0 mt-0">JL Alfa Prima</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <h4 className="text-primary">Partner</h4>
              <p style={{ textTransform: "uppercase" }}>
                {items.afiliasi.nama}
                {parseInt(items.afiliasi.status) === 1 && " (Afiliasi)"}
                {parseInt(items.afiliasi.status) === 17 && " (Mahasiswa)"}
                {parseInt(items.afiliasi.status) === 18 && " (Alumni)"}
                {parseInt(items.afiliasi.status) === 19 && " (Guru)"}
              </p>
            </div>
            <div className="col">
              <h4 className="text-primary">Tagihan</h4>
              <p>
                {items.afiliasi &&
                  items.afiliasi.kode +
                    "-" +
                    `${
                      d.getDate().length === 1 ? `0${d.getDate()}` : d.getDate()
                    }${
                      (d.getMonth() + 1).length === 1
                        ? `0${d.getMonth() + 1}`
                        : d.getMonth() + 1
                    }${d.getFullYear()}`}
              </p>
            </div>
            <div className="col">
              <h4 className="text-primary">Total :</h4>
              {/* {items.kdbb ? (
                <h4 className="text-info">Rp 150.000</h4>
              ) : (
                <>
                  {items.afiliasi && (
                    <>
                      {items.afiliasi.status === 1 && (
                        <h4 className="text-info">Rp 200.000</h4>
                      )}
                      {items.afiliasi.status === 17 && (
                        <h4 className="text-info">Rp 300.000</h4>
                      )}
                      {items.afiliasi.status === 18 && (
                        <h4 className="text-info">Rp 300.000</h4>
                      )}
                      {items.afiliasi.status === 19 && (
                        <h4 className="text-info">Rp 300.000</h4>
                      )}
                    </>
                  )}
                </>
              )} */}
              <h4 className="text-info">
                {items.kdbb ? "Rp 150.000" : "Rp 500.000"}
              </h4>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col">
              <h4 className="text-primary">Atas rekomendasi Mahasiswa</h4>
              <p>{items.nama}</p>
            </div>
            <div className="col">
              <h4 className="text-primary">Jumlah</h4>
              <p>{items.kdbb ? "Rp 150.000" : "Rp 500.000"}</p>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col">
              <h4 className="text-primary">Catatan :</h4>
              <p className="mb-0" style={{ textTransform: "uppercase" }}>
                Bank {items.afiliasi && items.afiliasi.bank} {` `}
                {items.afiliasi && `(${items.afiliasi.no_req})`}
              </p>
              <p className="mb-0" style={{ textTransform: "uppercase" }}>
                AN : {items.afiliasi && items.afiliasi.nama}
              </p>
              <p>HP : {items.afiliasi && items.afiliasi.no_hp}</p>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col text-center ml-3">
              <br />
              <p className="mb-0">Mengetahui</p>
              <p>(Ka. Bag. Humas &amp; Pemasaran)</p>
              <br />
              <br />
              ......................................................
            </div>
            <div className="col text-center mr-3">
              <p style={{ textTransform: "capitalize" }} className="mb-0">
                {user.cabang},{" "}
                {`${
                  d.getDate().length === 1 ? `0${d.getDate()}` : d.getDate()
                }-${
                  (d.getMonth() + 1).length === 1
                    ? `0${d.getMonth() + 1}`
                    : d.getMonth() + 1
                }-${d.getFullYear()}`}
              </p>
              <p className="mb-0">Pemohon</p>
              <p>(Partner)</p>
              <br />
              <br />
              ......................................................
            </div>
          </div>
          <div className="row text-center">
            <div className="col">
              <p className="mb-0">Menyetujui</p>
              <p>(Ka. Bag. Keuangan &amp; SDM)</p>
              <br />
              <br />
              ......................................................
            </div>
          </div>
        </div>
        <div className={`text-center mb-5 ${print && "d-none"}`}>
          <span
            type="button"
            className="text-info fa fa-print fa-lg mx-1"
            onClick={() => {
              setPrint(true);
              setTimeout(() => {
                window.print();
              }, 900);
              setTimeout(() => {
                setPrint(false);
              }, 900);
            }}
          >
            Print
          </span>
        </div>
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default PendaftarInvoiceAfiliasi;
