import React, { useState, useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchLinkSop } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { FilterSearch } from "../../components/layouts/TopContent";
import SectionPagination from "../../components/sections/Pagination";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";

const LinkSopShow = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);

  useEffect(() => setTitle("Link SOP"), [setTitle]);

  const [linkSop, setLinkSop] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchLinkSop(
        page,
        `search=${search}&pegawai_id=${user.id}`
      );
      if (res.data && res.data.link_sop) {
        setLinkSop(res.data.link_sop);
      }
    } catch (err) {
      console.error(err);
    }
  }, [page, search, user.id]);

  useEffect(() => fetchData(), [fetchData]);

  return (
    <>
      {/* Filter 1 */}
      <div className="row mb-3">
        <div className="col-md-4">
          <FilterSearch
            onChange={(res) => {
              setSearch(res);
              setPage(1);
            }}
          />
        </div>
      </div>
      <LayoutTable>
        <LayoutThead>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">no</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">cabang</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">judul</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">link</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">devisi</th>
        </LayoutThead>
        <tbody>
          {linkSop.data &&
            linkSop.data.length > 0 &&
            linkSop.data.map((ls, index) => {
              return (
                <tr key={index}>
                  <td className="py-1 border-silver text-center">{linkSop.from + index}</td>
                  <td className="py-1 border-silver text-center">{ls.cabang}</td>
                  <td className="py-1 border-silver">{ls.judul}</td>
                  <td className="py-1 border-silver"><span type="button" className="text-primary" onClick={() => window.open(ls.link)}>{ls.link}</span></td>
                  <td className="py-1 border-silver">
                    {ls.devisi_link_sop &&
                      ls.devisi_link_sop.length > 0 &&
                      ls.devisi_link_sop.map((dls) => dls.devisi).join(" | ")}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </LayoutTable>
      {linkSop.links && (
        <SectionPagination
          from={linkSop.from}
          to={linkSop.to}
          total={linkSop.total}
          links={linkSop.links}
          onChangePage={(res) => setPage(res)}
        />
      )}
    </>
  );
};

export default LinkSopShow;
