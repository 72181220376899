import React, { useState, useRef } from "react";
import { postNilaiOjt } from "../../api";
import FormInput from "../../components/forms/Input";
import LayoutModal, { ModalBtn } from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";

const NilaiCreateOrUpdateOjt = (props) => {
  const { mhs, onFetchData } = props;
  const [modal, setModal] = useState(false);
  const formRef = useRef(false);

  const [form, setForm] = useState();

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleClose = () => {
    formRef.current && formRef.current.reset();
    setForm({});
    setModal(false);
  };

  const handleOpen = () => {
    setForm({ mhs_id: mhs.id });
    setModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await postNilaiOjt(form);
      if (res.data === "success") {
        onFetchData();
        saAlert("success", "Berhasil update nilai OJT !");
        handleClose();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getNilaiOjt = (nilai_ojt, ke) => {
    let nilai =
      nilai_ojt.length > 0 &&
      nilai_ojt
        .filter((n) => parseInt(n.ojt_ke) === parseInt(ke))
        .map((n) => n.nilai);
    if (nilai) {
      return nilai;
    } else {
      return "";
    }
  };

  return (
    <>
      <button className="btn btn-sm btn-success-2 text-sm py-0" type="button" onClick={handleOpen}>
        <i className="fa fa-fw fa-sm fa-pen"/> Beri Nilai
      </button>
      <LayoutModal
        size="sm"
        modal={modal}
        title={`Nilai OJT a.n ${mhs.nama}`}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={formRef}>
          <FormInput
            label="OJT 1"
            name="nilai_1"
            value={mhs.nilai_ojt ? getNilaiOjt(mhs.nilai_ojt, 1) : ""}
            onChange={handleChange}
          />
          {mhs.jenjang === "D2" && (
            <FormInput
              label="OJT 2"
              name="nilai_2"
              value={mhs.nilai_ojt ? getNilaiOjt(mhs.nilai_ojt, 2) : ""}
              onChange={handleChange}
            />
          )}
          {/* <ModalBtn onClose={handleClose} /> */}
          <div className="btn-group w-100 mt-2">
            <button type="submit" className="btn btn-sm btn-success-2">
              Simpan Perubahaan
            </button>
            <button
              type="button"
              className="btn btn-sm btn-dark border-0"
              onClick={handleClose}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default NilaiCreateOrUpdateOjt;
