import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  deleteMhs_pojt,
  fetchDosen,
  fetchingData,
  fetchMhs,
  fetchPojt,
  patchMhs_pojt,
  storeMhs_pojt,
} from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterAngkatan,
  FilterCabang,
  FilterJenjang,
  FilterJurusan,
  FilterMhsOjt,
  FilterPagination,
  FilterSearch,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { getJurusanShort, saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import { periodeOjtState } from "../../storage/mhs";
import { angkatanState, titleState } from "../../storage/pages";
import { FilterTahunAjaranPerusahaan } from "../perusahaan/Components";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const MhsOjt = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);
  const defAngkatan = useRecoilValue(angkatanState);
  const user = useRecoilValue(userState);
  const periodeOjt = useRecoilValue(periodeOjtState);

  const [items, setItems] = useState({});

  const [page, setPage] = useState(1);
  const [angkatan, setAngkatan] = useState(defAngkatan);
  const [jenjang, setJenjang] = useState("");
  const [search, setSearch] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [pagination, setPagination] = useState(10);
  const [hasMhsPojt, setHasMhsPojt] = useState("");
  const [cabang, setCabang] = useState("");
  const [tahun, setTahun] = useState(periodeOjt);

  const fetchData = useCallback(async () => {
    setIsLoaded(false);
    try {
      const res = await fetchMhs(
        page,
        `with_krs=true&with_krs_nilai=true&with_mhs_pojt=true&angkatan=${angkatan}&jenjang=${jenjang}&search=${search}&jurusan=${jurusan}&pagination=${pagination}&has_mhs_pojt_active=${hasMhsPojt}&cabang=${cabang}&tahun=${tahun}&status=0`
      );
      if (res.data && res.data.mhs) {
        setItems(res.data.mhs);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [
    page,
    angkatan,
    jenjang,
    search,
    jurusan,
    pagination,
    hasMhsPojt,
    cabang,
    tahun,
  ]);

  useEffect(() => {
    setTitle("Mhs OJT");
    fetchData();
  }, [setTitle, fetchData]);

  const handleActivedAll = async () => {
    const res = await saConfirm(
      "warning",
      "Yakin aktifkan semua ke OJT ?",
      "Ini membutuhkan proses yang lumayan lama, harap tunggu hingga proses selesai"
    );

    if (res.isConfirmed) {
      fetchingData("success", "Wait a moment until process ends");
      try {
        const res = await fetchMhs(
          "all",
          `actived_all_to_pojt=true&with_krs=true&with_krs_nilai=true&with_mhs_pojt=true&angkatan=${angkatan}&jenjang=${jenjang}&search=${search}&jurusan=${jurusan}&pagination=${pagination}&has_mhs_pojt_active=${hasMhsPojt}&tahun=${tahun}&status=0`
        );
        if (res.data === "success") {
          fetchData();
          saAlert(
            "success",
            `Berhasil mengubah status semua mhs sebagai aktif OJT`
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleNonactivedAll = async () => {
    const res = await saConfirm(
      "warning",
      "Yakin nonaktifkan semua ke OJT ?",
      "Ini membutuhkan proses yang lumayan lama, harap tunggu hingga proses selesai"
    );

    if (res.isConfirmed) {
      fetchingData("success", "Wait a moment until process ends");
      try {
        const res = await fetchMhs(
          "all",
          `nonactived_all_to_pojt=true&with_krs=true&with_krs_nilai=true&with_mhs_pojt=true&angkatan=${angkatan}&jenjang=${jenjang}&search=${search}&jurusan=${jurusan}&pagination=${pagination}&has_mhs_pojt_active=${hasMhsPojt}&tahun=${tahun}&status=0`
        );
        if (res.data === "success") {
          fetchData();
          saAlert(
            "success",
            `Berhasil mengubah status semua mhs sebagai nonaktif OJT`
          );
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getIp = (krs, semester) => {
    if (krs && krs.length > 0) {
      let nilai = krs
        .filter((k) => parseInt(k.semester) === parseInt(semester) && k.nilai)
        .map((k) => k.nilai)
        .filter((n) => n.grade)
        .map((n) => n.grade);

      let count_nilai = nilai.length;

      return nilai.length > 0
        ? parseFloat(
            nilai.reduce((total, curr) => parseInt(total) + parseInt(curr)) / count_nilai
          ).toFixed(2)
        : nilai[0];
    }
  };

  // Dosen
  const [isLoadedDosen, setIsLoadedDosen] = useState(false);
  const [itemsDosen, setItemsDosen] = useState(false);

  useEffect(() => {
    setIsLoadedDosen(false);
    const fetchDataDosen = async () => {
      try {
        const res = await fetchDosen("all");
        if (res.data) {
          setItemsDosen(res.data);
          setIsLoadedDosen(true);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchDataDosen();
  }, []);

  // Mhs
  const [isLoadedMhs, setIsLoadedMhs] = useState(false);
  const [itemsMhs, setItemsMhs] = useState({});

  useEffect(() => {
    setIsLoadedMhs(false);
    const fetchDataMhs = async () => {
      try {
        const res = await fetchMhs(
          "all",
          `has_mhs_pojt_active=yes&tahun=${tahun}&status=0`
        );
        if (res.data && res.data.mhs) {
          setItemsMhs(res.data.mhs);
          setIsLoadedMhs(true);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchDataMhs();
  }, [tahun]);

  // Pojt
  const [isLoadedPojt, setIsLoadedPojt] = useState(false);
  const [itemsPojt, setItemsPojt] = useState({});

  useEffect(() => {
    setIsLoadedPojt(false);
    const fetchDataPojt = async () => {
      try {
        const res = await fetchPojt(
          "all",
          `has_Pojt_pojt_active=yes&tahun=${tahun}`
        );
        if (res.data) {
          setItemsPojt(res.data.pojt);
          setIsLoadedPojt(true);
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchDataPojt();
  }, [tahun]);

  return (
    <>
      <div className="row mb-2">
        <div className="col-4">
          <FilterAngkatan onChange={(res) => setAngkatan(res)} />
        </div>
        <div className="col-4">
          <FilterJenjang onChange={(res) => setJenjang(res)} />
        </div>
        <div className="col-4">
          <FilterSearch onChange={(res) => setSearch(res)} />
        </div>
      </div>
      <div className="row mb-2">
        <div className="col-4">
          <FilterJurusan onChange={(res) => setJurusan(res)} />
        </div>
        <div className="col-4">
          <FilterPagination onChange={(res) => setPagination(res)} />
        </div>
        <div className="col-4">
          <FilterMhsOjt onChange={(res) => setHasMhsPojt(res)} />
        </div>
      </div>
      <div className="row mb-2">
        {user.cabang === "pusat" && (
          <div className="col-4">
            <FilterCabang onChange={(res) => setCabang(res)} />
          </div>
        )}
        <div className="col-4">
          <FilterTahunAjaranPerusahaan
            tahunAjaran={tahun}
            onChange={(res) => setTahun(res)}
          />
        </div>
      </div>
      <div className="text-end my-3">
        <button onClick={handleActivedAll} className="btn btn-sm btn-success-2 rounded-3 px-4 mx-1">
          Aktifkan Semua OJT
        </button>
        <button onClick={handleNonactivedAll} className="btn btn-sm btn-danger-2 rounded-3 px-4 mx-1">
          Nonaktifkan Semua OJT
        </button>
      </div>
      {/* <p><i className="fa fa-fw fa-sm fa-circle-exclamation text-warning"/> Untuk mempermudah pengecekan dan menampilkan detail kelompok serta tempat OJT <br /> anda dapat menggunakan filter yang sudah tersedia diatas.</p> */}
      {/* <p className="text-warning">
        Silahkan mengganti status mhs OJT pada menu filter diatas untuk mempermudah
        pengecekan status dan menampilkan detail kelompok serta tempat OJT.
      </p> */}
      {isLoaded ? (
        <>
          {items.data.length > 0 ? (
            <TableNew hover={false} striped={false}>
              <Thead>
                <Tr className="text-center">
                  <Th className="text-nowrap">No</Th>
                  <Th className="text-nowrap">NIM</Th>
                  <Th className="text-nowrap">Nama/Jenis Kel./Cabang</Th>
                  <Th colSpan={2} className="text-nowrap"><i className="fa fa-fw fa-cog" /></Th>
                  <Th className="text-nowrap">Opsi</Th>
                  <Th className="text-nowrap">IP 1</Th>
                  <Th className="text-nowrap">IP 2</Th>
                  <Th className="text-nowrap">IP 3</Th>
                  <Th className="text-nowrap">IP 4</Th>
                  <Th className="text-nowrap">TAK</Th>
                </Tr>
              </Thead>
              <Tbody>
                {items.data.map((i, index) => {
                  return (
                    <React.Fragment key={index}>
                      {/* 1 */}
                      <Tr>
                        <Td rowSpan={3} className="text-center align-middle">
                          {items.from + index}
                        </Td>
                        <Td rowSpan={3} className="text-nowrap align-middle">
                          {i.nim}
                        </Td>
                        <Td className="text-nowrap align-middle">{i.nama}</Td>
                        {(() => {
                          if (i.mhs_pojt) {
                            if (
                              i.mhs_pojt.length > 0 &&
                              i.mhs_pojt.filter((mp) => parseInt(mp.tahun) === parseInt(tahun))
                                .length > 0
                            ) {
                              return (
                                <>
                                  {isLoadedPojt ? (
                                    <LokasiOjt
                                      mhs_pojt={
                                        i.mhs_pojt.filter(
                                          (mp) => parseInt(mp.tahun) === parseInt(tahun)
                                        )[0]
                                      }
                                      pojt={itemsPojt}
                                      onFetchData={fetchData}
                                    />
                                  ) : (
                                    "Loading . . . "
                                  )}
                                </>
                              );
                            } else {
                              return (
                                <Td colSpan={2} className="text-center">
                                  -
                                </Td>
                              );
                            }
                          }
                        })()}
                        <Td
                          rowSpan={3}
                          className="text-nowrap align-middle text-center"
                        >
                          {i.angkatan}
                          <br />
                          {i.jenjang}
                          <br />
                          {getJurusanShort(i.jurusan_id)}
                        </Td>
                        <Td rowSpan={3} className="text-nowrap align-middle">
                          {getIp(i.krs, 1)}
                        </Td>
                        <Td rowSpan={3} className="text-nowrap align-middle">
                          {getIp(i.krs, 2)}
                        </Td>
                        <Td rowSpan={3} className="text-nowrap align-middle">
                          {getIp(i.krs, 3)}
                        </Td>
                        <Td rowSpan={3} className="text-nowrap align-middle">
                          {getIp(i.krs, 4)}
                        </Td>
                        <Td
                          rowSpan={3}
                          className="text-nowrap text-center align-middle"
                        >
                          {i.nilai_akhir ? i.nilai_akhir.tak : "-"}
                        </Td>
                      </Tr>

                      {/* 2 */}
                      <Tr>
                        <Td
                          style={{ textTransform: "capitalize" }}
                          className="text-nowrap align-middle"
                        >
                          <span><i className={`fa fa-fw fa-${i.jenis === "Laki-laki" ? 'person' : 'person-dress'}`}/> {i.jenis}</span> <span><i className="fa fa-fw fa-sm fa-house"/> {i.cabang}</span>
                        </Td>
                        {(() => {
                          if (i.mhs_pojt) {
                            if (
                              i.mhs_pojt.length > 0 &&
                              i.mhs_pojt.filter((mp) => mp.tahun === tahun)
                                .length > 0
                            ) {
                              return (
                                <>
                                  {isLoadedMhs ? (
                                    <KelompokOjt
                                      mhs={itemsMhs}
                                      mhs_pojt={
                                        i.mhs_pojt.filter(
                                          (mp) => mp.tahun === tahun
                                        )[0]
                                      }
                                      onFetchData={fetchData}
                                    />
                                  ) : (
                                    "Loading . . . "
                                  )}
                                </>
                              );
                            } else {
                              return (
                                <Td colSpan={2} className="text-center">
                                  -
                                </Td>
                              );
                            }
                          }
                        })()}
                      </Tr>

                      {/* 3 */}
                      <Tr>
                        <Td className="text-nowrap">
                          <SwitchOjt
                            mhs={i}
                            tahun={tahun}
                            onFetchData={fetchData}
                          />
                        </Td>
                        {(() => {
                          if (i.mhs_pojt) {
                            if (
                              i.mhs_pojt.length > 0 &&
                              i.mhs_pojt.filter((mp) => parseInt(mp.tahun) === parseInt(tahun))
                                .length > 0
                            ) {
                              return (
                                <>
                                  {isLoadedDosen ? (
                                    <DosenOjt
                                      dosen={itemsDosen}
                                      mhs_pojt={
                                        i.mhs_pojt.filter(
                                          (mp) => parseInt(mp.tahun) === parseInt(tahun)
                                        )[0]
                                      }
                                      onFetchData={fetchData}
                                    />
                                  ) : (
                                    "Loading . . . "
                                  )}
                                </>
                              );
                            } else {
                              return (
                                <Td colSpan={2} className="text-center">
                                  -
                                </Td>
                              );
                            }
                          }
                        })()}
                      </Tr>
                    </React.Fragment>
                  );
                })}
              </Tbody>
            </TableNew>
          ) : (
            <PageNoData />
          )}
          <SectionPagination
            from={items.from}
            to={items.to}
            total={items.total}
            links={items.links}
            onChangePage={(res) => setPage(res)}
          />
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

const SwitchOjt = (props) => {
  const { mhs, tahun, onFetchData } = props;

  const handleChange = (mhs_id, message) => {
    saConfirm("warning", `Yakin ${message} mahasiswa sebagai Aktif OJT ?`).then(
      (res) => {
        if (res.isConfirmed) {
          // add
          if (message === "Tambah") {
            storeMhs_pojt({
              mhs_id,
              tahun: tahun,
              condition: "MhsAktifOjt",
            })
              .then((res) => {
                if (res.data === "success") {
                  onFetchData();
                  saAlert(
                    "success",
                    `Berhasil mengubah status ${mhs.nama} sebagai aktif OJT`
                  );
                }
              })
              .catch((err) => console.error(err));
          }
        }
      }
    );
  };

  const handleDelete = (mhs_pojt_id, message) => {
    saConfirm("warning", `Yakin ${message} mahasiswa sebagai Aktif OJT ?`).then(
      (res) => {
        if (res.isConfirmed) {
          // delete
          if (message === "Hapus") {
            deleteMhs_pojt(mhs_pojt_id, "mhs_id=true")
              .then((res) => {
                if (res.data === "success") {
                  onFetchData();
                  saAlert(
                    "success",
                    `Berhasil mengubah status ${mhs.nama} sebagai non aktif OJT`
                  );
                }
              })
              .catch((err) => console.error(err));
          }
        }
      }
    );
  };

  if (mhs.mhs_pojt) {
    const mhs_pojt_active = mhs.mhs_pojt.filter((mp) => mp.tahun === tahun);

    if (mhs_pojt_active.length > 0) {
      return mhs_pojt_active.map((mp, index) => {
        return (
          <React.Fragment key={index}>
            <button
              type="button"
              className="btn btn-sm btn-danger-2 text-sm py-0"
              onClick={() => handleDelete(mp.id, "Hapus")}
            >
              <i className="fa fa-fw fa-sm fa-ban"/> Nonaktifkan OJT
            </button>
          </React.Fragment>
        );
      });
    } else {
      return (
        <>
          <button
            type="button"
            className="btn btn-sm btn-success-2 text-sm py-0"
            onClick={() => handleChange(mhs.id, "Tambah")}
          >
            <i className="fa fa-fw fa-sm fa-check"/> Aktifkan OJT
          </button>
        </>
      );
    }
  }
};

const LokasiOjt = ({ mhs_pojt, pojt, onFetchData }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      id: mhs_pojt.id,
      pojt_id: mhs_pojt.pojt_id,
    });
  }, [setData, mhs_pojt]);

  const handleUpdate = async () => {
    try {
      const res = await patchMhs_pojt(data.id, data);
      if (res.data === "success") {
        onFetchData();
        saAlert("success", "Berhasil update data.");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus data ?").then(async (res) => {
      if (res.isConfirmed) {
        try {
          const res = await patchMhs_pojt(data.id, { pojt_id: "deleted" });
          if (res.data === "success") {
            onFetchData();
            saAlert("success", "Berhasil hapus data.");
          }
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  return (
    <>
      <td className="py-1 border-silver m-0 align-middle text-nowrap">
        <select
          className="w-100 border-0"
          style={{padding: '0px 10px', fontSize: '13px'}}
          name="pojt_id"
          onChange={(e) =>
            setData((prevState) => {
              return { ...prevState, [e.target.name]: e.target.value };
            })
          }
          value={data.pojt_id ? data.pojt_id : ""}
        >
          <option value="">Pilih Lokasi OJT. . .</option>
          {pojt.length > 0 &&
            pojt.map((p, index) => {
              return (
                <React.Fragment key={index}>
                  <option value={p.id}>
                    {p.perusahaan && p.perusahaan.nama}
                  </option>
                </React.Fragment>
              );
            })}
        </select>
      </td>
      <td className="py-1 border-silver text-center text-nowrap">
        <button
          className="btn btn-sm btn-success-2 text-sm py-0 px-1 me-1"
          onClick={handleUpdate}
          type="button"
        >
          <i className="fa fa-fw fa-square-check" />
        </button>
        <button
          className="btn btn-sm btn-danger-2 text-sm py-0 px-1"
          type="button"
          onClick={() => handleDelete()}
        >
          <i className="fa fa-fw fa-trash-alt" />
        </button>
      </td>
    </>
  );
};

export const KelompokOjt = ({ mhs_pojt, mhs, onFetchData }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      id: mhs_pojt.id,
      kelompok_nim: mhs_pojt.kelompok_nim,
    });
  }, [setData, mhs_pojt]);

  const handleUpdate = async () => {
    try {
      const res = await patchMhs_pojt(data.id, data);
      if (res.data === "success") {
        onFetchData();
        saAlert("success", "Berhasil update data.");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus data ?").then(async (res) => {
      if (res.isConfirmed) {
        try {
          const res = await patchMhs_pojt(data.id, { kelompok_nim: "deleted" });
          if (res.data === "success") {
            onFetchData();
            saAlert("success", "Berhasil hapus data.");
          }
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  return (
    <>
      <div className="d-flex mb-1">
        <select
          className="form-select form-select-sm border-right-0 border-silver rounded-0"
          style={{width: '256px'}}
          name="kelompok_nim"
          onChange={(e) =>
            setData((prevState) => {
              return { ...prevState, [e.target.name]: e.target.value };
            })
          }
          value={data.kelompok_nim ? data.kelompok_nim : ""}
        >
          <option value="">Pilih Kelompok OJT. . .</option>
          {mhs.length > 0 &&
            mhs.map((m, index) => {
              return (
                <React.Fragment key={index}>
                  <option value={m.nim}>
                    {m.nim} - {m.nama}
                  </option>
                </React.Fragment>
              );
            })}
        </select>
        <div className="border border-silver p-1">
          <button
            className="btn btn-sm btn-success-2 text-sm py-0 px-1 me-1"
            onClick={handleUpdate}
            type="button"
          >
            <i className="fa fa-fw fa-sm fa-save" />
          </button>
          <button
            className="btn btn-sm btn-danger-2 text-sm py-0 px-1"
            type="button"
            onClick={() => handleDelete()}
          >
            <i className="fa fa-fw fa-sm fa-trash-alt" />
          </button>
        </div>
      </div>
    </>
  );
};

const DosenOjt = ({ mhs_pojt, dosen, onFetchData }) => {
  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      id: mhs_pojt.id,
      dosen_id: mhs_pojt.dosen_id,
    });
  }, [setData, mhs_pojt]);

  const handleUpdate = async () => {
    try {
      const res = await patchMhs_pojt(data.id, data);
      if (res.data === "success") {
        onFetchData();
        saAlert("success", "Berhasil update data.");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus data ?").then(async (res) => {
      if (res.isConfirmed) {
        try {
          const res = await patchMhs_pojt(data.id, { dosen_id: "deleted" });
          if (res.data === "success") {
            onFetchData();
            saAlert("success", "Berhasil hapus data.");
          }
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  return (
    <>
      <td className="py-1 border-silver m-0 text-center text-nowrap">
        <select
          className="w-100 border-0"
          style={{padding: '0px 10px', fontSize: '13px'}}
          name="dosen_id"
          onChange={(e) =>
            setData((prevState) => {
              return { ...prevState, [e.target.name]: e.target.value };
            })
          }
          value={data.dosen_id ? data.dosen_id : ""}
        >
          <option value="">Pilih Dosen OJT. . .</option>
          {dosen.length > 0 &&
            dosen.map((d, index) => {
              return (
                <React.Fragment key={index}>
                  <option value={d.id}>
                    {d.nip} - {d.nama}
                  </option>
                </React.Fragment>
              );
            })}
        </select>
      </td>
      <td className="py-1 border-silver text-center text-nowrap">
        <button
          className="btn btn-sm btn-success-2 text-sm py-0 px-1 me-1"
          onClick={handleUpdate}
          type="button"
        >
          <i className="fa fa-fw fa-square-check" />
        </button>
        <button
          className="btn btn-sm btn-danger-2 text-sm py-0 px-1"
          type="button"
          onClick={() => handleDelete()}
        >
          <i className="fa fa-fw fa-trash-alt" />
        </button>
      </td>
    </>
  );
};

export default MhsOjt;
