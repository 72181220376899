import { fetchDataControl, fetchingData } from "../../api";
import axios from "../../axios";

const url = "/api/purchase-request";

// Ceklis Monitoring
// index
export const fetchPurchaseRequest = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`${url}?page=${page}&${custom}`);
  });
};
// show
export const showPurchaseRequest = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`${url}/${id}?${custom}`);
  });
};
// store
export const postPurchaseRequest = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`${url}`, data);
  });
};
// update
export const patchPurchaseRequest = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`${url}/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deletePurchaseRequest = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`${url}/${id}&${custom}`);
  });
};
