import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchPenyelenggara } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import PenyelenggaraCreate from "./Create";
import PenyelenggaraEdit from "./Edit";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const PenyelenggaraAkademik = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);

  useEffect(() => {
    setTitle("Data Penyelenggara");
  }, [setTitle]);

  const [page, setPage] = useState(1);
  const [items, setItems] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchPenyelenggara(page);
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoaded) {
    return (
      <>
        <PenyelenggaraCreate onFetchData={fetchData} />
        {items.data && items.data.length > 0 ? (
          <TableNew>
            <Thead>
              <Tr className='text-center'>
                <Th>No</Th>
                <Th>Nama Penyelenggara</Th>
                <Th>
                  <i className="fa fa-bars"></i>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center">{items.from + index}</Td>
                    <Td>{i.nama}</Td>
                    <Td className="text-center">
                      {user.cabang === "pusat" ? (
                        <PenyelenggaraEdit bidang={i} onFetchData={fetchData} />
                      ) : (
                        "-"
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <PageNoData />
        )}

        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default PenyelenggaraAkademik;
