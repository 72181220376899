import React, { useState } from "react";

const PrintBtnPrint = () => {
  const [print, setPrint] = useState(false);

  // const Export2Word = (element, filename = "") => {
  //   var preHtml =
  //     "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
  //   var postHtml = "</body></html>";
  //   var html = preHtml + document.getElementById(element).innerHTML + postHtml;

  //   var blob = new Blob(["\ufeff", html], {
  //     type: "application/msword",
  //   });

  //   // Specify link url
  //   var url =
  //     "data:application/vnd.ms-word;charset=utf-8," + encodeURIComponent(html);

  //   // Specify file name
  //   filename = filename ? filename + ".doc" : "document.doc";

  //   // Create download link element
  //   var downloadLink = document.createElement("a");

  //   document.body.appendChild(downloadLink);

  //   if (navigator.msSaveOrOpenBlob) {
  //     navigator.msSaveOrOpenBlob(blob, filename);
  //   } else {
  //     // Create a link to the file
  //     downloadLink.href = url;

  //     // Setting the file name
  //     downloadLink.download = filename;

  //     //triggering the function
  //     downloadLink.click();
  //   }

  //   document.body.removeChild(downloadLink);
  // };

  return (
    <div className={`text-center mb-5 ${print && "d-none"}`}>
      <button
        type="button"
        className="btn btn-sm btn-success-2 px-4"
        onClick={() => {
          setPrint(true);
          window.scrollTo(0, 0);
          setTimeout(() => {
            window.print();
            setPrint(false);
          }, 900);
        }}
      >
        <i className="fa fa-fw fa-print text-white"/> Print
      </button>
    </div>
  );
};

export default PrintBtnPrint;
