import React from "react";

const PageNoData = (props) => {
  const { isLoaded = true } = props;

  return (
    <p className="d-flex justify-content-center align-items-center py-5 bg-light">
      <span className="my-5 py-5">
        {isLoaded ? "Tidak ada data tersedia." : "Loading . . ."}
      </span>
    </p>
  );
};

export default PageNoData;
