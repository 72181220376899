import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchingData } from "../../api";
import { namaBulan } from "../../helpers";

const SectionTopContent = (props) => {
  SectionTopContent.defaultProps = {
    add: false,
    cabang: false,
    bulan: false,
    search: false,
  };

  const [bulan, setBulan] = useState();

  useEffect(() => {
    let d = new Date();
    let month = d.getMonth() + 1;
    let date = d.getDate();

    if (date > 25) {
      setBulan(month + 1);
    } else {
      setBulan(month);
    }
  }, []);

  const changeBulan = (e) => {
    let val = e.target.value;
    setBulan(val);
    props.onChangeBulan(val);
    fetchingData();
  };

  const [cabang, setCabang] = useState();

  const changeCabang = (e) => {
    let val = e.target.value;
    setCabang(val);
    props.onChangeCabang(val);
    fetchingData();
  };

  const [search, setSearch] = useState("");

  const changeSearch = (e) => {
    let val = e.target.value;
    setSearch(val);
    props.onChangeSearch(val);
    fetchingData();
  };

  return (
    <>
      {props.addModal && (
        <div className="row justify-content-end">
          <div className="col-md-4 mb-3">
            <button
              onClick={props.onClickAddModal}
              className="btn btn-primary w-100"
            >
              <i className="fa fa-plus mr-3"></i>
              {props.addModal}
            </button>
          </div>
        </div>
      )}
      {props.add && (
        <div className="row justify-content-end">
          <div className="col-md-4 mb-3">
            <Link to={props.addLink} className="btn btn-primary w-100">
              <i className="fa fa-plus mr-3"></i>
              {props.add}
            </Link>
          </div>
        </div>
      )}
      <div className="row justify-content-end">
        {props.cabang && (
          <div className="col-md-4 mb-3">
            <select
              onChange={changeCabang}
              className="custom-select"
              value={cabang}
            >
              <option value="">Semua Cabang</option>
              <option value="Pusat">Pusat</option>
              <option value="Denpasar">Denpasar</option>
              <option value="Singaraja">Singaraja</option>
              <option value="Karangasem">Karangasem</option>
            </select>
          </div>
        )}
        {props.bulan && (
          <div className="col-md-4 mb-3">
            <select
              onChange={changeBulan}
              className="custom-select"
              value={bulan}
            >
              {namaBulan.map((i, index) => {
                return (
                  <option key={index} value={index + 1}>
                    {i}
                  </option>
                );
              })}
            </select>
          </div>
        )}
        {props.search && (
          <div className="col-md-4 mb-3">
            <input
              onChange={changeSearch}
              className="form-control"
              placeholder="Search . . ."
              value={search}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default SectionTopContent;
