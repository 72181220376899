import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { fetchPenilaianDosen, showKelas } from "../../api";
import FormTextarea from "../../components/forms/Textarea";
import PrintBtnPrint from "../../components/print/BtnPrint";
import PrintFooter from "../../components/print/Footer";
import PrintHeader from "../../components/print/Header";
import { getHari } from "../../helpers";

const KelasAngketDosen = () => {
  const { id } = useParams();

  const [kelas, setKelas] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await showKelas(id);
        setKelas(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [id]);

  const [nilai, setNilai] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchPenilaianDosen(
          "all",
          `kelas_id=${id}&rata_rata_all=true&nilai_tertinggi_all=true&nilai_terendah_all=true&tahun_ajaran=${kelas.tahun_ajaran}&semester=${kelas.semester}`
        );
        setNilai(res.data && res.data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
  }, [id, kelas.tahun_ajaran, kelas.semester]);

  const getNilai = (nilai, kelas, jenis) => {
    let data =
      nilai &&
      nilai.length > 0 &&
      nilai
        .map((n) => n[jenis])
        .reduce((prev, curr) => parseInt(prev) + parseInt(curr)) /
        // (kelas.krs && kelas.krs.length);
        (nilai && nilai.length);

    if (data) {
      return data;
    } else {
      return 0;
    }
  };

  const [print, setPrint] = useState(false);

  return (
    <>
      <PrintHeader />

      <div className="container-fluid">
        <h4 className="text-center mb-0">
          ANGKET DOSEN KELAS {kelas.kelas && kelas.kelas.kode}
        </h4>

        <div className="row mb-3">
          <div className="col">
            <table>
              <tbody>
                <tr>
                  <td>Kode Kelas</td>
                  <td>{` : `}</td>
                  <td>{kelas.kode}</td>
                </tr>
                <tr>
                  <td>Hari / Jam (WITA)</td>
                  <td>{` : `}</td>
                  <td>{`${getHari(kelas.jamkul && kelas.jamkul.hari)} / ${
                    kelas.jamkul && kelas.jamkul.dari
                  } - ${kelas.jamkul && kelas.jamkul.sampai}`}</td>
                </tr>
                <tr>
                  <td>Kelas/Ruangan</td>
                  <td>{` : `}</td>
                  <td>{kelas.ruangan && kelas.ruangan.nama}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col justify-content-end d-flex">
            <table>
              <tbody>
                <tr>
                  <td>Dosen</td>
                  <td>{` : `}</td>
                  <td>{kelas.dosen && kelas.dosen.nama}</td>
                </tr>
                <tr>
                  <td>Mata Kuliah</td>
                  <td>{` : `}</td>
                  <td>{kelas.matkul && kelas.matkul.nama}</td>
                </tr>
                <tr>
                  <td>Semester</td>
                  <td>{` : `}</td>
                  <td>{kelas.semester}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <h5>Hasil Angket</h5>
        <div className="row mb-2">
          <div className="col-4">Penampilan</div>
          <div className="col-8">
            : {getNilai(nilai.penilaian_dosen, kelas, "penampilan").toFixed(2)}
          </div>
          <div className="col-4">Kepribadian</div>
          <div className="col-8">
            : {getNilai(nilai.penilaian_dosen, kelas, "kepribadian").toFixed(2)}
          </div>
          <div className="col-4">Penguasaan Materi</div>
          <div className="col-8">
            :{" "}
            {getNilai(
              nilai.penilaian_dosen,
              kelas,
              "penguasaan_materi"
            ).toFixed(2)}
          </div>
          <div className="col-4">Mudah Diterima</div>
          <div className="col-8">
            :{" "}
            {getNilai(nilai.penilaian_dosen, kelas, "mudah_diterima").toFixed(
              2
            )}
          </div>
          <div className="col-4">Semangat Mengajar</div>
          <div className="col-8">
            :{" "}
            {getNilai(
              nilai.penilaian_dosen,
              kelas,
              "semangat_mengajar"
            ).toFixed(2)}
          </div>
          <div className="col-4">Ketepatan Waktu</div>
          <div className="col-8">
            :{" "}
            {getNilai(nilai.penilaian_dosen, kelas, "ketepatan_waktu").toFixed(
              2
            )}
          </div>
          <div className="col-4">Rata-rata Nilai evaluasi total</div>
          <div className="col-8">
            {`: `}
            {(
              (getNilai(nilai.penilaian_dosen, kelas, "penampilan") +
                getNilai(nilai.penilaian_dosen, kelas, "kepribadian") +
                getNilai(nilai.penilaian_dosen, kelas, "penguasaan_materi") +
                getNilai(nilai.penilaian_dosen, kelas, "mudah_diterima") +
                getNilai(nilai.penilaian_dosen, kelas, "semangat_mengajar") +
                getNilai(nilai.penilaian_dosen, kelas, "ketepatan_waktu")) /
              6
            ).toFixed(2)}
          </div>
        </div>

        <h5>Statistik Semua Dosen</h5>

        <div className="row mb-2">
          <div className="col-4">Rata-Rata Semua Dosen</div>
          <div className="col-8">
            : {parseFloat(nilai.rata_rata_all).toFixed(2)}
          </div>
          <div className="col-4">Nilai Tertinggi</div>
          <div className="col-8">
            : {parseFloat(nilai.nilai_tertinggi_all).toFixed(2)}
          </div>
          <div className="col-4">Nilai Terendah</div>
          <div className="col-8">
            : {parseFloat(nilai.nilai_terendah_all).toFixed(2)}
          </div>
        </div>

        <h5>Kritik & Saran</h5>

        {nilai.penilaian_dosen &&
          nilai.penilaian_dosen.length &&
          nilai.penilaian_dosen.map((pd, index) => {
            return <KritikSaran hideBy={print} key={index} pd={pd} />;
          })}

        <PrintFooter />
      </div>

      <div className={`text-center mb-5 ${print && "d-none"}`}>
      <button
        type="button"
        className="btn btn-sm btn-success-2 px-4"
        onClick={() => {
          setPrint(true);
          window.scrollTo(0, 0);
          setTimeout(() => {
            window.print();
            setPrint(false);
          }, 900);
        }}
      >
        <i className="fa fa-fw fa-print text-white"/> Print
      </button>
    </div>
    </>
  );
};

const KritikSaran = (props) => {
  const { index, pd, hideBy } = props;
  const [isLoaded, setIsLoaded] = useState(pd.kritik_saran ? true : false);
  const [kritikSaran, setKritikSaran] = useState(pd.kritik_saran);

  const handleChange = (e) => {
    setKritikSaran(e.target.value);
  }

  return (
    <>
      {isLoaded ? (
        <>
          <React.Fragment key={index}>
            {!hideBy ?
              <FormTextarea value={kritikSaran} onChange={handleChange}/>
              :
              <div className="border border-silver rounded p-2 mb-3">
                <p className="mb-0">{kritikSaran}</p>
              </div>
            }
          </React.Fragment>
          {!hideBy &&
            <button
              onClick={() => setIsLoaded(false)}
              className="btn btn-sm btn-danger mb-3 no-print"
            >
              <i className="fa fa-eye-slash mr-1" />
              Sembunyikan
            </button>
          }
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default KelasAngketDosen;
