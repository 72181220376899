import React, { useState } from "react";
import { useContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import FormInput from "../../../components/forms/Input";
import FormSelect from "../../../components/forms/Select";
import { LayoutTable, LayoutThead } from "../../../components/layouts/Table";
import { saAlert } from "../../../helpers";
import { patchCeklistMonitoring } from "../apis";
import { CeklistMonitoringContext } from "../Routes";

const Edit = ({ mainUrl, onFetchData }) => {
  const { id } = useParams();
  const { ceklistMonitoring, cabang, departemen, auditor } = useContext(
    CeklistMonitoringContext
  );

  const [form, setForm] = useState(
    ceklistMonitoring.data && ceklistMonitoring.data.length > 0
      ? ceklistMonitoring.data.filter(
          (cm) => parseInt(cm.id) === parseInt(id)
        )[0]
      : {}
  );

  const [auditee, setAuditee] = useState(
    ceklistMonitoring.data && ceklistMonitoring.data.length > 0
      ? ceklistMonitoring.data
          .filter((cm) => parseInt(cm.id) === parseInt(id))[0]
          .auditee.map((a) => parseInt(a.id))
      : []
  );

  const [errors, setErrors] = useState({});

  const history = useHistory();

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSetAuditee = (id) => {
    setAuditee([...auditee, id]);
  };

  const handleRemoveAuditee = (id) => {
    setAuditee(auditee.filter((a) => parseInt(a) !== parseInt(id)));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await patchCeklistMonitoring(id, {
        ...form,
        auditee_row: auditee,
      });
      if (res.data && res.data.msg) {
        onFetchData();
        history.push(mainUrl);
        saAlert("success", res.data.msg);
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <h3>Ubah Data</h3>

        <div className="row">
          <div className="col-6">
            <FormSelect
              label="cabang"
              name="cabang_id"
              value={form.cabang_id}
              onChange={handleChange}
              error={errors.cabang_id}
            >
              <option value="">Pilih</option>
              {cabang.length > 0 &&
                cabang.map((c, index) => {
                  return (
                    <React.Fragment key={index}>
                      <option value={c.id}>{c.nama}</option>
                    </React.Fragment>
                  );
                })}
            </FormSelect>
          </div>
          <div className="col-6">
            <FormSelect
              label="departemen"
              name="departemen_id"
              value={form.departemen_id}
              onChange={handleChange}
              error={errors.departemen_id}
            >
              <option value="">Pilih</option>
              {departemen.length > 0 &&
                departemen.map((d, index) => {
                  return (
                    <React.Fragment key={index}>
                      <option value={d.id}>{d.nama}</option>
                    </React.Fragment>
                  );
                })}
            </FormSelect>
          </div>
          <div className="col-6">
            <FormSelect
              label="auditor"
              name="auditor_id"
              value={form.auditor_id}
              onChange={handleChange}
              error={errors.auditor_id}
            >
              <option value="">Pilih</option>
              {auditor.length > 0 &&
                auditor.map((a, index) => {
                  return (
                    <React.Fragment key={index}>
                      <option value={a.id}>{a.nama}</option>
                    </React.Fragment>
                  );
                })}
            </FormSelect>
          </div>
          <div className="col-6">
            <FormInput 
              type="date"
              label="tanggal"
              name="tanggal"
              value={form.tanggal}
              onChange={handleChange}
              error={errors.tanggal}
            />
          </div>
          <div className="col-6">
            <FormSelect
              label="bulan"
              name="bulan"
              value={form.bulan}
              onChange={handleChange}
              error={errors.bulan}
            >
              <option value="">Pilih</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </FormSelect>
          </div>
          <div className="col-6">
            <FormSelect
              label="tahun"
              name="tahun"
              value={form.tahun}
              onChange={handleChange}
              error={errors.tahun}
            >
              <option value="">Pilih</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
            </FormSelect>
          </div>
          <div className="col-6">
            <FormSelect
              label="tahap"
              name="tahap"
              value={form.tahap}
              onChange={handleChange}
              error={errors.tahap}
            >
              <option value="">Pilih</option>
              <option value="1">1</option>
              <option value="2">2</option>
            </FormSelect>
          </div>
          <div className="col-6">
            <FormInput
              label="kode_dokumen"
              name="kode_dokumen"
              value={form.kode_dokumen}
              onChange={handleChange}
              error={errors.kode_dokumen}
            />
          </div>
          <div className="col-6">
            <FormInput
              label="revisi"
              name="revisi"
              value={form.revisi}
              onChange={handleChange}
              error={errors.revisi}
            />
          </div>
          <div className="col-6">
            <FormInput
              label="tanggal_efektif"
              name="tanggal_efektif"
              type="date"
              value={form.tanggal_efektif}
              onChange={handleChange}
              error={errors.tanggal_efektif}
            />
          </div>
          <div className="col-6">
            <h3>Pegawai</h3>
            <LayoutTable>
              <LayoutThead>
                <th>no</th>
                <th>nama</th>
                <th>bars</th>
              </LayoutThead>
              <tbody>
                {auditor.length > 0 &&
                  auditor
                    .filter((a) => !auditee.includes(parseInt(a.id)))
                    .filter((a) => {
                      if (form.cabang_id) {
                        let cabang_picked = cabang.filter(
                          (c) => parseInt(c.id) === parseInt(form.cabang_id)
                        )[0].nama;
                        return (
                          a.cabang
                            .toLowerCase()
                            .indexOf(cabang_picked.toLowerCase()) >= 0
                        );
                      } else {
                        return true;
                      }
                    })
                    .map((a, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{a.nama}</td>
                          <td className="text-center">
                            <span
                              type="button"
                              className="badge badge-primary"
                              onClick={() => handleSetAuditee(a.id)}
                            >
                              Pilih
                            </span>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </LayoutTable>
          </div>
          <div className="col-6">
            <h3>Auditee</h3>
            <LayoutTable>
              <LayoutThead>
                <th>no</th>
                <th>nama</th>
                <th>bars</th>
              </LayoutThead>
              <tbody>
                {auditor.length > 0 &&
                  auditor
                    .filter((a) => auditee.includes(parseInt(a.id)))
                    .map((a, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">{index + 1}</td>
                          <td>{a.nama}</td>
                          <td className="text-center">
                            <span
                              type="button"
                              className="badge badge-danger"
                              onClick={() => handleRemoveAuditee(a.id)}
                            >
                              Hapus
                            </span>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </LayoutTable>
          </div>
        </div>

        <div className="btn-group">
          <button type="submit" className="btn btn-sm btn-success">
            Simpan
          </button>
          <Link type="button" className="btn btn-sm btn-secondary" to={mainUrl}>
            Tutup
          </Link>
        </div>
      </form>
    </>
  );
};

export default Edit;
