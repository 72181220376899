import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import FormInput from "../../../components/forms/Input";
import FormTextarea from "../../../components/forms/Textarea";
import { saAlert } from "../../../helpers";
import { postCeklistMonitoringList } from "../apis";

const Create = ({ mainUrl, onFetchData }) => {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const history = useHistory();

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await postCeklistMonitoringList(form);
      if (res.data && res.data.msg) {
        onFetchData();
        history.push(mainUrl);
        saAlert("success", res.data.msg);
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };
  return (
    <>
      <form onSubmit={handleSubmit}>
        <h3>Tambah Data</h3>

        <div className="row">
          <div className="col-6">
            <FormInput
              label="klausul"
              name="klausul"
              onChange={handleChange}
              error={errors.klausul}
            />
          </div>
          <div className="col-6">
            <FormTextarea
              label="deskripsi"
              name="deskripsi"
              onChange={handleChange}
              error={errors.deskripsi}
            />
          </div>
          <div className="col-6">
            <FormInput
              label="refrensi_audit"
              name="refrensi_audit"
              onChange={handleChange}
              error={errors.refrensi_audit}
            />
          </div>
        </div>

        <div className="btn-group">
          <button type="submit" className="btn btn-sm btn-success">
            Simpan
          </button>
          <Link type="button" className="btn btn-sm btn-secondary" to={mainUrl}>
            Tutup
          </Link>
        </div>
      </form>
    </>
  );
};

export default Create;
