import React, { useRef, useState } from "react";
import { patchLinkSop } from "../../api";
import FormCheckbox from "../../components/forms/Checkbox";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import LayoutModal from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";

const LinkSopEdit = (props) => {
  const { linkSop, onFetchData } = props;
  const formRef = useRef();

  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [devisi, setDevisi] = useState([]);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      let value = e.target.value;
      setDevisi((prevState) => {
        return [...prevState, value];
      });
    } else {
      let value = e.target.value;
      setDevisi(devisi.filter((d) => d !== value));
    }
  };

  const handleOpen = () => {
    setModal(true);
    setForm(linkSop);
    if (linkSop.devisi_link_sop && linkSop.devisi_link_sop.length > 0) {
      setDevisi(linkSop.devisi_link_sop.map((d) => d.devisi));
    }
  };

  const handleClose = () => {
    if (formRef.current) {
      formRef.current.reset();
    }
    setForm({});
    setDevisi([]);
    setErrors({});
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await patchLinkSop(form.id, {
        ...form,
        devisi_link_sop: devisi,
      });
      if (res.data && res.data.msg === "success") {
        onFetchData();
        handleClose();
        saAlert("success", "Berhasil ubah data link sop !");
      }
    } catch (err) {
      if (err.response && err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  return (
    <>
      <span type="button" className="badge badge-success" onClick={handleOpen}>
        <i className="fa fa-edit" />
      </span>
      <LayoutModal
        modal={modal}
        title="Tambah Data Penyelenggara"
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={formRef}>
          <div className="row">
            <div className="col-6">
              <FormSelect
                label="cabang"
                name="cabang"
                value={form.cabang}
                onChange={handleChange}
                error={errors.cabang}
              >
                <option value="">Pilih</option>
                <option value="all">All</option>
                <option value="pusat">pusat</option>
                <option value="denpasar">denpasar</option>
                <option value="singaraja">singaraja</option>
                <option value="karangasem">karangasem</option>
              </FormSelect>
            </div>
            <div className="col-12">
              <p className="m-0 p-0 text-primary font-weight-bold">Devisi</p>
              <FormCheckbox
                inline={1}
                label="Dir"
                name="devisi"
                onChange={handleChangeCheckbox}
                value="Dir"
                id="Dir"
                checked={devisi.filter((d) => d === "Dir").length}
              />
              <FormCheckbox
                inline={1}
                label="Kacab"
                name="devisi"
                onChange={handleChangeCheckbox}
                value="Kacab"
                id="Kacab"
                checked={devisi.filter((d) => d === "Kacab").length}
              />
              <FormCheckbox
                inline={1}
                label="Akademik"
                name="devisi"
                onChange={handleChangeCheckbox}
                value="Akademik"
                id="Akademik"
                checked={devisi.filter((d) => d === "Akademik").length}
              />
              <FormCheckbox
                inline={1}
                label="SDM"
                name="devisi"
                onChange={handleChangeCheckbox}
                value="SDM"
                id="SDM"
                checked={devisi.filter((d) => d === "SDM").length}
              />
              <FormCheckbox
                inline={1}
                label="Marketing"
                name="devisi"
                onChange={handleChangeCheckbox}
                value="Marketing"
                id="Marketing"
                checked={devisi.filter((d) => d === "Marketing").length}
              />
              <FormCheckbox
                inline={1}
                label="PM"
                name="devisi"
                onChange={handleChangeCheckbox}
                value="PM"
                id="PM"
                checked={devisi.filter((d) => d === "PM").length}
              />
              <FormCheckbox
                inline={1}
                label="PKK"
                name="devisi"
                onChange={handleChangeCheckbox}
                value="PKK"
                id="PKK"
                checked={devisi.filter((d) => d === "PKK").length}
              />
              <FormCheckbox
                inline={1}
                label="Magang"
                name="devisi"
                onChange={handleChangeCheckbox}
                value="Magang"
                id="Magang"
                checked={devisi.filter((d) => d === "Magang").length}
              />
            </div>
            <div className="col-12">
              <FormInput
                label="judul"
                name="judul"
                value={form.judul}
                onChange={handleChange}
                error={errors.judul}
              />
            </div>
            <div className="col-12">
              <FormInput
                label="link"
                name="link"
                value={form.link}
                onChange={handleChange}
                error={errors.link}
              />
            </div>
          </div>
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success">
              Ubah
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={handleClose}
            >
              Batal
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default LinkSopEdit;
