import React from "react";
import { MultipleMenu, SinggleMenu } from "./Sidebar";

const Akademik = (props) => {
  const { user } = props;
  return (
    <>
      {user.jabatan === "IT Programmer" ||
      user.jabatan === "Dir. Akademik & Kemahasiswaan" ||
      user.jabatan === "Dir. Pusat Karir dan Kerjasama" ||
      user.jabatan === "Kepala Cabang Denpasar" ||
      user.jabatan === "Kepala Cabang Singaraja" ||
      user.jabatan === "Kepala Cabang Karangasem" ||
      user.jabatan === "Ka. Bag. Pusat Karier & Alumni" ||
      user.jabatan === "Ka. Unit Kerjasama" ||
      user.jabatan === "Ka. Unit Karier & Alumni" ||
      user.jabatan === "Ka. Bag. Pusat Karier & Kerjasama" ||
      user.group === "Akademik" ? (
        <>
          <hr className="border-0 my-2" />

          <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
            Akademik
          </div>

          <hr className="sidebar-divider border-0 mt-2 mb-0" />

          <MultipleMenu
            title="Mahasiswa"
            fa="fa-users"
            links={[
              { title: "Data Mahasiswa", link: "/mhs-akademik" },
              { title: "Mahasiswa OJT", link: "/mhs-ojt" },
              { title: "Buku Saku", link: "/mhs-pkk" },
              { title: "Mahasiswa Nilai", link: "/mhs-nilai" },
            ]}
          />

          <MultipleMenu
            title="Internship"
            fa="fa-users"
            links={[
              { title: "Pendaftar", link: "/pendaftar-intership" },
              { title: "Peserta", link: "/peserta-intership" },
            ]}
          />

          <MultipleMenu
            title="Nilai"
            fa="fa-heart"
            links={[
              { header: "Nilai" },
              { title: "Umum", link: "/nilai-umum-akademik" },
              { title: "TAK", link: "/nilai-tak-akademik" },
              { title: "OJT", link: "/nilai-ojt-akademik" },
              { title: "Akhir", link: "/nilai-akhir-akademik" },
              { title: "Transkrip", link: "/nilai-transkrip-akademik" },
            ]}
          />

          <MultipleMenu
            title="Perkuliahan"
            fa="fa-calendar-alt"
            links={[
              { header: "Master Data" },
              { title: "Ruangan Kelas", link: "/ruangan-akademik" },
              {
                title: "Kelompok Mata Kuliah",
                link: "/kelompok-matkul-akademik",
              },
              { title: "Mata Kuliah", link: "/matkul-akademik" },
              { title: "Slot Waktu", link: "/jamkul-akademik" },
              { header: "Reguler" },
              { title: "Kelas Perkuliahan", link: "/kelas-akademik" },
            ]}
          />

          <MultipleMenu
            title="Dosen"
            fa="fa-users"
            links={[
              { header: "Dosen" },
              { title: "Data Dosen", link: "/dosen-akademik" },
              { header: "Honor" },
              { title: "Honor Dosen", link: "/dosen-honor" },
            ]}
          />

          <SinggleMenu title="Buku Perpustakaan" link="/buku-perpustakaan" fa="fa-book" updateLabel/>
          <SinggleMenu title="Data Wisudawan" link="/wisuda" fa="fa-book"/>
          <SinggleMenu title="Pengumuman" link="/pengumuman" fa="fa-calendar" newLabel/>
        </>
      ) : null}
    </>
  );
};

export default Akademik;
