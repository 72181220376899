import { useCallback, useEffect, useRef, useState } from "react";
import { useSetRecoilState } from "recoil";
import { fetchJamkul, patchJamkul, postJamkul } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { getHari, getJenisWaktu, saAlert } from "../../helpers";
import { titleState } from "../../storage/pages";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const JamkulAkademik = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchJamkul(page);
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page]);

  useEffect(() => {
    setTitle("Data Jam Kuliah");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        <CreateJamkul onFetchData={fetchData} />
        {items.data.length >= 1 ? (
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>Cabang</Th>
                <Th>Hari</Th>
                <Th>Dari</Th>
                <Th>Sampai</Th>
                <Th>Status</Th>
                <Th>Ubah</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center">{index + items.from}</Td>
                    <Td className="text-center">{i.cabang}</Td>
                    <Td className="text-center">{getHari(i.hari)}</Td>
                    <Td>{i.dari} WITA</Td>
                    <Td>{i.sampai} WITA</Td>
                    <Td className="text-center">{getJenisWaktu(i.jenis)}</Td>
                    <Td className="text-center">
                      <EditJamkul jamkul={i} onFetchData={fetchData} />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

const CreateJamkul = (props) => {
  const [modal, setModal] = useState(false);
  const tagForm = useRef();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    if (tagForm) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postJamkul(form)
      .then((res) => {
        if (res.data === "success") {
          props.onFetchData();
          handleClose();
          saAlert("success", "Data berhasil diinput kedalam sistem");
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      });
  };

  return (
    <>
      <LayoutAddBtn add="Tambah Data" onClick={() => setModal(true)} />
      <LayoutModal
        size="sm"
        title="Tambah Data Jam Kuliah"
        modal={modal}
        onClose={handleClose}
      >
        <form ref={tagForm} onSubmit={handleSubmit}>
          <FormSelect
            label="Hari"
            name="hari"
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.hari}
          >
            <option value="">Pilih Hari</option>
            <option value="0">Minggu</option>
            <option value="1">Senin</option>
            <option value="2">Selasa</option>
            <option value="3">Rabu</option>
            <option value="4">Kamis</option>
            <option value="5">Jumat</option>
            <option value="6">Sabtu</option>
          </FormSelect>
          <FormInput
            type="time"
            label="Dari"
            name="dari"
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.dari}
          />
          <FormInput
            type="time"
            label="Sampai"
            name="sampai"
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.sampai}
          />
          <FormSelect
            label="Jenis Waktu"
            name="jenis"
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.jenis}
          >
            <option value="">Pilih Jenis</option>
            <option value="1">Pagi</option>
            <option value="2">Sore</option>
          </FormSelect>
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success-2">
              Tambah
            </button>
            <button
              type="button"
              className="btn btn-sm btn-dark border-0"
              onClick={handleClose}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

const EditJamkul = (props) => {
  const [modal, setModal] = useState(false);
  const tagForm = useRef();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    if (tagForm) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    patchJamkul(props.jamkul.id, form).then((res) => {
      if (res.data === "success") {
        props.onFetchData();
        handleClose();
        saAlert("success", "Berhasil ubah data");
      }
    });
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-success-2 text-sm py-0 px-1"
        onClick={() => {
          setForm(props.jamkul);
          setModal(true);
        }}
      >
        <i className="fa fa-fw fa-sm fa-pen"/>
      </button>
      <LayoutModal title="Ubah Jam Kuliah" modal={modal} onClose={handleClose}>
        <form ref={tagForm} onSubmit={handleSubmit}>
          <FormSelect
            label="Hari"
            name="hari"
            value={form.hari ? form.hari : ""}
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.hari}
          >
            <option value="">Pilih Hari</option>
            <option value="Senin">Senin</option>
            <option value="Selasa">Selasa</option>
            <option value="Rabu">Rabu</option>
            <option value="Kamis">Kamis</option>
            <option value="Jumat">Jumat</option>
            <option value="Sabtu">Sabtu</option>
            <option value="Minggu">Minggu</option>
          </FormSelect>
          <FormInput
            type="time"
            label="Dari"
            name="dari"
            value={form.dari}
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.dari}
          />
          <FormInput
            type="time"
            label="Sampai"
            name="sampai"
            value={form.sampai}
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.sampai}
          />
          <FormSelect
            label="Jenis Waktu"
            name="jenis"
            value={form.jenis}
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.jenis}
          >
            <option value="">Pilih Jenis</option>
            <option value="1">Pagi</option>
            <option value="2">Sore</option>
          </FormSelect>
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success">
              Ubah
            </button>
            <button
              type="button"
              className="btn btn-sm btn-secondary"
              onClick={handleClose}
            >
              Tutup
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default JamkulAkademik;
