import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { showPojt } from "../../../api";
import PrintBtnPrint from "../../../components/print/BtnPrint";
import Header2022 from "../../../components/print/Header2022";
import { getJurusan, getOrigin, namaBulan } from "../../../helpers";
import { kacabState, userState } from "../../../storage/auth";
import PrintLayout, {
  PrintButton,
} from "../../../components/layouts/PrintLayout";

const PojtPrintSurat = () => {
  const { id } = useParams(); // pojt id
  const user = useRecoilValue(userState);
  const kacab = useRecoilValue(kacabState);

  const [pojt, setPojt] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const res = await showPojt(id);
      if (res.data && res.data.pojt) {
        setPojt(res.data.pojt);
      }
    };

    fetchData();
  }, [id]);

  let d = new Date();

  const [surat, setSurat] = useState(true);

  const getPojtDua = (pojt) => {
    if (pojt.dari_2 && pojt.sampai_2) {
      return (
        <p contentEditable>
          Lama Waktu OJT 2 Bulan (Tanggal{" "}
          {pojt.dari_2.split("-").reverse().join("-")} s/d Tanggal{" "}
          {pojt.sampai_2.split("-").reverse().join("-")})
        </p>
      );
    }
  };

  const getPojtEmpat = (pojt, brp_tahun_cuk) => {
    if (pojt.dari_4 && pojt.sampai_4) {
      return (
        <>
          <p contentEditable>
            Lama Waktu OJT 3 Bulan (Tanggal{" "}
            {pojt.dari_4.split("-").reverse().join("-")} s/d Tanggal{" "}
            {pojt.sampai_4.split("-").reverse().join("-")})
          </p>
          {brp_tahun_cuk === 2 && getPojtEnam(pojt)}
        </>
      );
    }
  };

  const getPojtEnam = (pojt) => {
    if (pojt.dari_6 && pojt.sampai_6) {
      return (
        <p contentEditable>
          Lama Waktu OJT 6 Bulan (Tanggal{" "}
          {pojt.dari_6.split("-").reverse().join("-")} s/d Tanggal{" "}
          {pojt.sampai_6.split("-").reverse().join("-")})
        </p>
      );
    }
  };

  return (
    <>
      <PrintLayout header footer>
        <div className="btn-group d-print-none mb-4">
          <button
            onClick={() => setSurat(true)}
            className="btn btn-sm btn-success-2"
          >
            Surat
          </button>
          <button
            onClick={() => setSurat(false)}
            className="btn btn-sm btn-primary-2"
          >
            Peserta
          </button>
        </div>
        {surat ? (
          <>
            {/* <div className="row mb-4">
              <div className="col-2">
                <p className="mb-0">Nomor</p>
                <p className="mb-0">Lampiran</p>
                <p className="mb-0">Perihal</p>
              </div>
              <div className="col-1 text-center">
                <p className="mb-0">:</p>
                <p className="mb-0">:</p>
                <p className="mb-0">:</p>
              </div>
              <div className="col-8">
                <p className="mb-0">{pojt.no_surat}</p>
                <p contentEditable className="mb-0">1 Gabung</p>
                <p contentEditable className="mb-0 fw-semibold">Permohonan Pelaksanaan <i>On the Job Training</i> (OJT)</p>
              </div>
            </div> */}

            <table className="mb-4 w-100">
              <tbody>
                <tr>
                  <td>Nomor</td>
                  <td>:</td>
                  <td>{pojt.no_surat}</td>
                </tr>
                <tr>
                  <td>Lampiran</td>
                  <td>:</td>
                  <td contentEditable>1 Gabung</td>
                </tr>
                <tr>
                  <td>Perihal</td>
                  <td>:</td>
                  <td contentEditable className="fw-semibold">
                    Permohonan Pelaksanaan <i>On the Job Training</i> (OJT)
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="mb-0">Kepada Yth.</p>
            <p contentEditable className="mb-0">
              Bapak / Ibu Pimpinan {pojt.perusahaan && pojt.perusahaan.nama}
            </p>
            <p>di</p>
            <p className="ps-5 mb-4">Tempat</p>
            <p className="ps-5">Dengan Hormat,</p>
            <p contentEditable className="text-justify">
              <span className="ps-5">
                Sehubungan dengan akan dilaksanakannya program{" "}
                <i>On the Job Training</i> (OJT) bagi seluruh peserta didik
                Lembaga Pendidikan Manajemen dan Informatika Alfa Prima Tahun
                Ajaran {pojt.tahun}, maka dari itu kami bermaksud untuk
                mengajukan permohonan ijin kepada {`Bapak/ Ibu `}
                {pojt.perusahaan && pojt.perusahaan.pic} untuk memberikan
                kesempatan kepada peserta didik kami melaksanakan{" "}
                <i>On the Job Training</i> (OJT) di{" "}
                {pojt.perusahaan && pojt.perusahaan.nama}. Adapun data peserta
                didik serta waktu pelaksaaan <i>On the Job Training</i> (OJT)
                terlampir.{" "}
              </span>
            </p>
            <p className="mb-4 pb-3">
              <span className="ps-5">
                Demikian surat permohonan ini kami sampaikan, atas perhatian dan
                kerjasamanya kami ucapkan terima kasih.{" "}
              </span>
            </p>
            <p contentEditable className="mb-0">
              <span style={{ textTransform: "capitalize" }}>
                {user.cabang.toLowerCase() === "karangasem"
                  ? "Amlapura"
                  : user.cabang}
              </span>
              ,{` ${d.getDate()} ${namaBulan[d.getMonth()]} ${d.getFullYear()}`}
            </p>
            <p className="mb-0">Hormat Kami, </p>
            <p>
              Kepala Cabang Alfa Prima{" "}
              <span style={{ textTransform: "capitalize" }}>{user.cabang}</span>
            </p>
            <div style={{ position: "relative", height: "4rem" }}>
              {user.cabang && user.cabang.toLowerCase() === "denpasar" && (
                <img
                  src={`${getOrigin()}/ika.png`}
                  alt=""
                  className="img-fluid"
                  style={{
                    width: "13rem",
                    position: "absolute",
                    top: "-4rem",
                    left: "2rem",
                  }}
                />
              )}
              {user.cabang && user.cabang.toLowerCase() === "karangasem" && (
                <img
                  src={`${getOrigin()}/dewi.png`}
                  alt=""
                  className="img-fluid"
                  style={{
                    width: "16rem",
                    position: "absolute",
                    top: "-3.25rem",
                    left: "0rem",
                  }}
                />
              )}
            </div>
            <p>
              <b>({kacab.nama})</b> <br /> NIK. {kacab.nip}
            </p>
          </>
        ) : (
          <>
            <div className="row h-100">
              <div className="col-12">
                <h2 className="fs-4 text-center mb-5" contentEditable>
                  <b>DATA PESERTA ON THE JOB TRAINING (OJT) ALFA PRIMA</b>
                </h2>

                {pojt.mhs &&
                  pojt.mhs.length > 0 &&
                  pojt.mhs.filter((m) => m.jenjang === "D1").length > 0 &&
                  pojt.dari_4 &&
                  pojt.sampai_4 && (
                    <>
                      <b>
                        <p contentEditable>PROGRAM 1 TAHUN</p>
                        {pojt && getPojtEmpat(pojt, 1)}
                      </b>
                      <table className="table table-sm">
                        <thead>
                          <tr className="text-center">
                            <th className="border border-silver">No</th>
                            <th className="border border-silver">NIM</th>
                            <th className="border border-silver">Nama</th>
                            <th className="border border-silver">Jurusan</th>
                          </tr>
                        </thead>
                        <tbody contentEditable>
                          {pojt.mhs &&
                            pojt.mhs.length > 0 &&
                            pojt.mhs
                              .filter((m) => m.jenjang === "D1")
                              .map((m, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="border border-silver text-center">
                                      {index + 1}
                                    </td>
                                    <td className="border border-silver text-center">
                                      {m.nim}
                                    </td>
                                    <td className="border border-silver">
                                      {m.nama}
                                    </td>
                                    <td className="border border-silver">
                                      {getJurusan(m.jurusan_id)}
                                    </td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </>
                  )}

                {pojt.mhs &&
                  pojt.mhs.length > 0 &&
                  pojt.mhs.filter(
                    (m) =>
                      m.jenjang === "D2" &&
                      parseInt(m.angkatan) ===
                        parseInt(new Date().getFullYear()) - 1
                  ).length > 0 &&
                  pojt.dari_2 &&
                  pojt.sampai_2 && (
                    <>
                      <b>
                        <p contentEditable>PROGRAM 2 TAHUN</p>
                        {pojt && getPojtDua(pojt)}
                      </b>
                      <table className="table table-sm">
                        <thead>
                          <tr className="text-center">
                            <th className="border border-silver">No</th>
                            <th className="border border-silver">NIM</th>
                            <th className="border border-silver">Nama</th>
                            <th className="border border-silver">Jurusan</th>
                          </tr>
                        </thead>
                        <tbody contentEditable>
                          {pojt.mhs &&
                            pojt.mhs.length > 0 &&
                            pojt.mhs
                              .filter(
                                (m) =>
                                  m.jenjang === "D2" &&
                                  parseInt(m.angkatan) ===
                                    parseInt(new Date().getFullYear()) - 1
                              )
                              .map((m, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="border border-silver text-center">
                                      {index + 1}
                                    </td>
                                    <td className="border border-silver text-center">
                                      {m.nim}
                                    </td>
                                    <td className="border border-silver">
                                      {m.nama}
                                    </td>
                                    <td className="border border-silver">
                                      {getJurusan(m.jurusan_id)}
                                    </td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </>
                  )}

                {pojt.mhs &&
                  pojt.mhs.length > 0 &&
                  pojt.mhs.filter(
                    (m) =>
                      m.jenjang === "D2" &&
                      parseInt(m.angkatan) <=
                        parseInt(new Date().getFullYear()) - 2
                  ).length > 0 &&
                  pojt.dari_4 &&
                  pojt.sampai_4 && (
                    <>
                      <b>
                        <p contentEditable>PROGRAM 2 TAHUN</p>
                        {pojt && getPojtEmpat(pojt, 2)}
                      </b>
                      <table className="table table-sm">
                        <thead>
                          <tr className="text-center">
                            <th className="border border-silver">No</th>
                            <th className="border border-silver">NIM</th>
                            <th className="border border-silver">Nama</th>
                            <th className="border border-silver">Jurusan</th>
                          </tr>
                        </thead>
                        <tbody contentEditable>
                          {pojt.mhs &&
                            pojt.mhs.length > 0 &&
                            pojt.mhs
                              .filter(
                                (m) =>
                                  m.jenjang === "D2" &&
                                  parseInt(m.angkatan) <=
                                    parseInt(new Date().getFullYear()) - 2
                              )
                              .map((m, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="border border-silver text-center">
                                      {index + 1}
                                    </td>
                                    <td className="border border-silver text-center">
                                      {m.nim}
                                    </td>
                                    <td className="border border-silver">
                                      {m.nama}
                                    </td>
                                    <td className="border border-silver">
                                      {getJurusan(m.jurusan_id)}
                                    </td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </>
                  )}

                {/* {pojt.mhs &&
                  pojt.mhs.length > 0 &&
                  pojt.mhs.filter(
                    (m) =>
                      m.jenjang === "D2" &&
                      parseInt(m.angkatan) <=
                        parseInt(new Date().getFullYear()) - 2
                  ).length > 0 &&
                  pojt.dari_6 &&
                  pojt.sampai_6 && (
                    <>
                      <b>
                        <p>PROGRAM 2 TAHUN</p>
                        {pojt && getPojtEnam(pojt)}
                      </b>
                      <table className="table table-sm">
                        <thead>
                          <tr className="text-center">
                            <th className="border border-silver">No</th>
                            <th className="border border-silver">NIM</th>
                            <th className="border border-silver">Nama</th>
                            <th className="border border-silver">Jurusan</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pojt.mhs &&
                            pojt.mhs.length > 0 &&
                            pojt.mhs
                              .filter(
                                (m) =>
                                  m.jenjang === "D2" &&
                                  parseInt(m.angkatan) <=
                                    parseInt(new Date().getFullYear()) - 2
                              )
                              .map((m, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="border border-silver text-center">
                                      {index + 1}
                                    </td>
                                    <td className="border border-silver text-center">
                                      {m.nim}
                                    </td>
                                    <td className="border border-silver">
                                      {m.nama}
                                    </td>
                                    <td className="border border-silver">
                                      {getJurusan(m.jurusan_id)}
                                    </td>
                                  </tr>
                                );
                              })}
                        </tbody>
                      </table>
                    </>
                  )} */}
              </div>
            </div>
          </>
        )}

        <PrintButton />
      </PrintLayout>
    </>
  );

  // return (
  //   <Header2022
  //     className="text-justify"
  //     fontSize="16pt"
  //     fixed={false}
  //     header={false}
  //     footer={false}
  //     margin="60mm 20mm 60mm 20mm"
  //   >
  //     <div className="btn-group d-print-none">
  //       <button
  //         onClick={() => setSurat(true)}
  //         className="btn btn-sm btn-success"
  //       >
  //         Surat
  //       </button>
  //       <button
  //         onClick={() => setSurat(false)}
  //         className="btn btn-sm btn-primary"
  //       >
  //         Peserta
  //       </button>
  //     </div>
  //     {surat ? (
  //       <>
  //         <div className="row">
  //           <div className="col-2">Nomor</div>
  //           <div className="col-10">: {pojt.no_surat}</div>
  //         </div>
  //         <div className="row">
  //           <div className="col-2">Lampiran</div>
  //           <div className="col-10" contentEditable>
  //             : 1 Gabung
  //           </div>
  //         </div>
  //         <div className="row mb-4">
  //           <div className="col-2">Perihal</div>
  //           <div className="col-10" contentEditable>
  //             :{" "}
  //             <b>
  //               Permohonan Pelaksanaan <i>On the Job Training</i> (OJT)
  //             </b>
  //           </div>
  //         </div>

  //         <p className="m-0">Kepada Yth.</p>
  //         <p className="m-0" contentEditable>
  //           Bapak / Ibu Pimpinan {pojt.perusahaan && pojt.perusahaan.nama}
  //         </p>
  //         <p>di</p>
  //         <p className="ml-5 mb-5">Tempat</p>

  //         <p className="ml-5">Dengan Hormat,</p>
  //         <p contentEditable>
  //           <span className="ml-5">
  //             Sehubungan dengan akan dilaksanakannya program{" "}
  //             <i>On the Job Training</i> (OJT) bagi seluruh peserta didik
  //             Lembaga Pendidikan Manajemen dan Informatika Alfa Prima Tahun
  //             Ajaran {pojt.tahun}, maka dari itu kami bermaksud untuk mengajukan
  //             permohonan ijin kepada {`Bapak/ Ibu `}
  //             {pojt.perusahaan && pojt.perusahaan.pic} untuk memberikan
  //             kesempatan kepada peserta didik kami melaksanakan{" "}
  //             <i>On the Job Training</i> (OJT) di{" "}
  //             {pojt.perusahaan && pojt.perusahaan.nama}. Adapun data peserta
  //             didik serta waktu pelaksaaan <i>On the Job Training</i> (OJT)
  //             terlampir.{" "}
  //           </span>
  //         </p>

  //         <p className="mb-5">
  //           <span className="ml-5">
  //             Demikian surat permohonan ini kami sampaikan, atas perhatian dan
  //             kerjasamanya kami ucapkan terima kasih.{" "}
  //           </span>
  //         </p>
  //         <br />

  //         <p contentEditable>
  //           <span style={{ textTransform: "capitalize" }}>
  //             {user.cabang.toLowerCase() === "karangasem"
  //               ? "Amlapura"
  //               : user.cabang}
  //           </span>
  //           ,{` ${d.getDate()} ${namaBulan[d.getMonth()]} ${d.getFullYear()}`}
  //         </p>
  //         <p>Hormat Kami, </p>
  //         <p>
  //           Kepala Cabang Alfa Prima{" "}
  //           <span style={{ textTransform: "capitalize" }}>{user.cabang}</span>
  //         </p>
  //         <div style={{ position: "relative", height: "6rem" }}>
  //           {user.cabang && user.cabang.toLowerCase() === "denpasar" && (
  //             <img
  //               src={`${getOrigin()}/ika.png`}
  //               alt=""
  //               className="img-fluid ml-5"
  //               style={{ width: "16rem", position: "absolute", top: "-4rem" }}
  //             />
  //           )}
  //         </div>
  //         <p>
  //           <b>({kacab.nama})</b> <br /> NIK. {kacab.nip}
  //         </p>
  //       </>
  //     ) : (
  //       <>
  //         <h4 className="text-center mb-5" contentEditable>
  //           <b>DATA PESERTA ON THE JOB TRAINING (OJT) ALFA PRIMA</b>
  //         </h4>

  //         {pojt.mhs &&
  //           pojt.mhs.length > 0 &&
  //           pojt.mhs.filter((m) => m.jenjang === "D1").length > 0 &&
  //           pojt.dari_4 &&
  //           pojt.sampai_4 && (
  //             <>
  //               <b>
  //                 <p>PROGRAM 1 TAHUN</p>
  //                 {pojt && getPojtEmpat(pojt)}
  //               </b>
  //               <table className="table table-sm">
  //                 <thead>
  //                   <tr>
  //                     <th>No</th>
  //                     <th>NIM</th>
  //                     <th>Nama</th>
  //                     <th>Jurusan</th>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   {pojt.mhs &&
  //                     pojt.mhs.length > 0 &&
  //                     pojt.mhs
  //                       .filter((m) => m.jenjang === "D1")
  //                       .map((m, index) => {
  //                         return (
  //                           <tr key={index}>
  //                             <td>{index + 1}</td>
  //                             <td>{m.nim}</td>
  //                             <td>{m.nama}</td>
  //                             <td>{getJurusan(m.jurusan_id)}</td>
  //                           </tr>
  //                         );
  //                       })}
  //                 </tbody>
  //               </table>
  //             </>
  //           )}

  //         {pojt.mhs &&
  //           pojt.mhs.length > 0 &&
  //           pojt.mhs.filter(
  //             (m) =>
  //               m.jenjang === "D2" &&
  //               parseInt(m.angkatan) === parseInt(new Date().getFullYear()) - 1
  //           ).length > 0 &&
  //           pojt.dari_2 &&
  //           pojt.sampai_2 && (
  //             <>
  //               <b>
  //                 <p>PROGRAM 2 TAHUN</p>
  //                 {pojt && getPojtDua(pojt)}
  //               </b>
  //               <table className="table table-sm">
  //                 <thead>
  //                   <tr>
  //                     <th>No</th>
  //                     <th>NIM</th>
  //                     <th>Nama</th>
  //                     <th>Jurusan</th>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   {pojt.mhs &&
  //                     pojt.mhs.length > 0 &&
  //                     pojt.mhs
  //                       .filter(
  //                         (m) =>
  //                           m.jenjang === "D2" &&
  //                           parseInt(m.angkatan) ===
  //                             parseInt(new Date().getFullYear()) - 1
  //                       )
  //                       .map((m, index) => {
  //                         return (
  //                           <tr key={index}>
  //                             <td>{index + 1}</td>
  //                             <td>{m.nim}</td>
  //                             <td>{m.nama}</td>
  //                             <td>{getJurusan(m.jurusan_id)}</td>
  //                           </tr>
  //                         );
  //                       })}
  //                 </tbody>
  //               </table>
  //             </>
  //           )}

  //         {pojt.mhs &&
  //           pojt.mhs.length > 0 &&
  //           pojt.mhs.filter(
  //             (m) =>
  //               m.jenjang === "D2" &&
  //               parseInt(m.angkatan) <= parseInt(new Date().getFullYear()) - 2
  //           ).length > 0 &&
  //           pojt.dari_4 &&
  //           pojt.sampai_4 && (
  //             <>
  //               <b>
  //                 <p>PROGRAM 2 TAHUN</p>
  //                 {pojt && getPojtEmpat(pojt)}
  //               </b>
  //               <table className="table table-sm">
  //                 <thead>
  //                   <tr>
  //                     <th>No</th>
  //                     <th>NIM</th>
  //                     <th>Nama</th>
  //                     <th>Jurusan</th>
  //                   </tr>
  //                 </thead>
  //                 <tbody>
  //                   {pojt.mhs &&
  //                     pojt.mhs.length > 0 &&
  //                     pojt.mhs
  //                       .filter(
  //                         (m) =>
  //                           m.jenjang === "D2" &&
  //                           parseInt(m.angkatan) <=
  //                             parseInt(new Date().getFullYear()) - 2
  //                       )
  //                       .map((m, index) => {
  //                         return (
  //                           <tr key={index}>
  //                             <td>{index + 1}</td>
  //                             <td>{m.nim}</td>
  //                             <td>{m.nama}</td>
  //                             <td>{getJurusan(m.jurusan_id)}</td>
  //                           </tr>
  //                         );
  //                       })}
  //                 </tbody>
  //               </table>
  //             </>
  //           )}
  //       </>
  //     )}
  //     <PrintBtnPrint />
  //   </Header2022>
  // );
};

export default PojtPrintSurat;
