import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { showKelas } from "../../api";
import PrintBtnPrint from "../../components/print/BtnPrint";
import PrintFooter from "../../components/print/Footer";
import PrintGradeNilai from "../../components/print/GradeNilai";
import PrintHeader from "../../components/print/Header";
import { getHari, getHuruf, getJurusan } from "../../helpers";

const KelasNilaiUts = () => {
  const { id } = useParams();

  const [items, setItems] = useState({});
  const [print, setPrint] = useState(false);
  const [hideNilai, setHideNilai] = useState(true);

  const toggleHideNilai = () => {
    setHideNilai(!hideNilai);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await showKelas(id);
        setItems(res.data);
        console.log(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div>
      <PrintHeader />

      <div className="container-fluid">
        <h2 className="text-center">CETAK NILAI UTS</h2>

        <div className="row mb-3">
          <div className="col">
            <table>
              <tbody>
                <tr>
                  <td>Kode Kelas</td>
                  <td>{` : `}</td>
                  <td>{items.kode}</td>
                </tr>
                <tr>
                  <td>Hari / Jam (WITA)</td>
                  <td>{` : `}</td>
                  <td>{`${getHari(items.jamkul && items.jamkul.hari)} / ${
                    items.jamkul && items.jamkul.dari
                  } - ${items.jamkul && items.jamkul.sampai}`}</td>
                </tr>
                <tr>
                  <td>Kelas/Ruangan</td>
                  <td>{` : `}</td>
                  <td>{items.ruangan && items.ruangan.nama}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col justify-content-end d-flex">
            <table>
              <tbody>
                <tr>
                  <td>Dosen</td>
                  <td>{` : `}</td>
                  <td>{items.dosen && items.dosen.nama}</td>
                </tr>
                <tr>
                  <td>Mata Kuliah</td>
                  <td>{` : `}</td>
                  <td>{items.matkul && items.matkul.nama}</td>
                </tr>
                <tr>
                  <td>Semester</td>
                  <td>{` : `}</td>
                  <td>{items.semester}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <table className="table table-sm table-bordered">
          <thead className="text-center bg-light">
            <tr className="">
              <th rowSpan={2} className="align-middle">
                No
              </th>
              <th rowSpan={2} className="align-middle">
                NIM
              </th>
              <th rowSpan={2} className="align-middle">
                Nama
              </th>
              <th rowSpan={2} className="align-middle">
                Jurusan
              </th>
              <th colSpan={3} className="align-middle">
                Nilai
              </th>
            </tr>
            <tr>
              <th>Angka</th>
              <th>{items.persentasi_uts ? `${items.persentasi_uts}%` : ''}</th>
              <th>Huruf</th>
            </tr>
          </thead>
          <tbody>
            {items.mhs &&
              items.mhs.filter((i) => parseInt(i.status) !== 9 ).map((i, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center align-middle">{index + 1}</td>
                    <td className="align-middle">{i.nim}</td>
                    <td className="align-middle">{i.nama}</td>
                    <td className="align-middle">{getJurusan(i.jurusan_id)}</td>
                    <td className="text-center">
                      {hideNilai &&
                        items.krs &&
                        items.krs &&
                        items.krs.length > 0 &&
                        items.krs
                          .filter(
                            (k) =>
                              parseInt(k.mhs_id) === parseInt(i.id) &&
                              k.nilai &&
                              k.nilai.uts
                          )
                          .map((k) => k.nilai.uts)}
                    </td>
                    <td className="text-center">
                    {hideNilai &&
                      items.krs &&
                        items.krs &&
                        items.krs.length > 0 &&
                        items.krs
                          .filter(
                            (k) =>
                              parseInt(k.mhs_id) === parseInt(i.id) &&
                              k.nilai &&
                              k.nilai.uts
                          )
                          .map((k) => (parseFloat(k.nilai.uts) * parseFloat(items.persentasi_uts) / 100).toFixed(2))}
                    </td>
                    <td className="text-center">
                      {hideNilai &&
                        getHuruf(parseFloat(items.krs &&
                        items.krs &&
                        items.krs.length > 0 &&
                        items.krs
                          .filter(
                            (k) =>
                              parseInt(k.mhs_id) === parseInt(i.id) &&
                              k.nilai &&
                              k.nilai.uts
                          )
                          .map((k) => k.nilai.uts)[0]))}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>

        <PrintGradeNilai items={items} />

        <PrintFooter />
      </div>

        {/* <PrintBtnPrint /> */}

      <div className={`text-center mb-5 ${print && "d-none"}`}>
        <button 
          type="button" 
          className="btn btn-sm btn-primary-3 rounded-3 me-1"
          onClick={toggleHideNilai}
        >
          <i className="fa fa-fw fa-eye-slash text-white" /> Hide Nilai
        </button>
        <button
          type="button"
          className="btn btn-sm btn-success-2 rounded-3 px-4 ms-1"
          onClick={() => {
            setPrint(true);
            window.scrollTo(0, 0);
            setTimeout(() => {
              window.print();
              setPrint(false);
            }, 900);
          }}
        >
          <i className="fa fa-fw fa-print text-white"/> Print
        </button>
      </div>
    </div>
  );
};

export default KelasNilaiUts;
