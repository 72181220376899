import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchKelompokMatkul, fetchMatkul } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterJenisMatkul,
  FilterJurusan,
  FilterSearch,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import MatkulCreate from "./Create";
import MatkulEdit from "./Edit";

const JamkulAkademik = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const [isLoaded, setIsLoaded] = useState(false);

  const [items, setItems] = useState([]);
  const [itemsJurusan, setItemsJurusan] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [jenisMatkul, setJenisMatkul] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchMatkul(
        page,
        `include_jurusan=true&search=${search}&jurusan=${jurusan}&
        jenis_matkul=${jenisMatkul}`
      );
      if (res.data) {
        setItems(res.data.matkul);
        setItemsJurusan(res.data.jurusan);
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page, search, jurusan, jenisMatkul]);

  useEffect(() => {
    setTitle("Data Mata Kuliah");
    fetchData();
  }, [setTitle, fetchData]);

  // to get Kelompok matkul for edit and create matkul
  const [kelompokMatkul, setKelompokMatkul] = useState({});
  useEffect(() => {
    const getKelompokMatkul = async () => {
      try {
        const res = await fetchKelompokMatkul("all");
        setKelompokMatkul(res.data);
      } catch (err) {
        console.log(err);
      }
    };
    getKelompokMatkul();
  }, []);

  if (isLoaded) {
    return (
      <>
        {user.cabang === "pusat" && (
          <MatkulCreate
            onFetchData={fetchData}
            jurusan={itemsJurusan}
            kelompokMatkul={kelompokMatkul}
          />
        )}
        <div className="row mb-2">
          <div className="col-md-4">
            <FilterJurusan
              onChange={(res) => {
                setJurusan(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterJenisMatkul
              onChange={(res) => {
                setJenisMatkul(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md-4">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {items.data.length > 0 ? (
          <div className="row">
            <div className="col-2 pe-0">
              <LayoutTable>
                <LayoutThead>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle text-nowrap">No</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle text-nowrap">Kode MK</th>
                </LayoutThead>
                <tbody>
                  {items.data.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td className="py-1 border-silver text-center text-nowrap">
                          {index + items.from}
                        </td>
                        <td className="py-1 border-silver text-center text-nowrap">{i.kode}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
            <div className="col-9 px-0">
              <LayoutTable>
                <LayoutThead>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle text-nowrap">Nama Mata Kuliah</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle text-nowrap">Kelompok MK</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle text-nowrap">Kredit (SKS)</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle text-nowrap">Jenis Penilaian</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle text-nowrap">Jenis Matkul</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle text-nowrap">Jurusan</th>
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle text-nowrap">Silabus dan RPP</th>
                  {/* <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle text-nowrap">Modul</th> */}
                  <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle text-nowrap">Bahan Ajar</th>
                </LayoutThead>
                <tbody>
                  {items.data.map((i, index) => {
                    return (
                      <tr key={index}>
                        <td className="py-1 border-silver text-nowrap">{i.nama}</td>
                        <td className="py-1 border-silver text-nowrap">
                          {i.kelompok_matkul && i.kelompok_matkul.nama}
                        </td>
                        <td className="py-1 border-silver text-center text-nowrap">{i.sks}</td>
                        <td className="py-1 border-silver text-center text-nowrap">
                          {parseInt(i.jenis_penilaian_id) === 1 && "Umum"}
                          {parseInt(i.jenis_penilaian_id) === 2 && "Per Jenjang"}
                          {parseInt(i.jenis_penilaian_id) === 3 && "Mutlak"}
                          {parseInt(i.jenis_penilaian_id) === 4 && "D2"}
                        </td>
                        <td className="py-1 border-silver text-center text-nowrap">
                          {parseInt(i.jenis_matkul) === 1 && "Praktek"}
                          {parseInt(i.jenis_matkul) === 2 && "Teori"}
                        </td>
                        <td className="py-1 border-silver text-center text-nowrap">
                          {i.jurusan_id
                            ? itemsJurusan.length > 0 &&
                              itemsJurusan
                                .filter((ij) => parseInt(ij.id) === parseInt(i.jurusan_id))
                                .map((ij, index) => {
                                  return (
                                    <React.Fragment key={index}>
                                      {ij.nama}
                                    </React.Fragment>
                                  );
                                })
                            : "Umum"}
                        </td>
                        <td className="py-1 border-silver text-nowrap text-center">
                          {i.rpp_link && (
                            <span
                              type="button"
                              className="text-primary"
                              onClick={() => window.open(i.rpp_link)}
                            >
                              {i.rpp_link && 'Klik disini'}
                            </span>
                          )}
                        </td>
                        {/* <td className="py-1 border-silver text-nowrap text-center">
                          {i.modul_link && (
                            <span
                              type="button"
                              className="text-primary"
                              onClick={() => window.open(i.modul_link)}
                            >
                              {i.modul_link && 'Klik disini'}
                            </span>
                          )}
                        </td> */}
                        <td className="py-1 border-silver text-nowrap text-center">
                          {i.bahan_ajar_link && (
                            <span
                              type="button"
                              className="text-primary"
                              onClick={() => window.open(i.bahan_ajar_link)}
                            >
                              {i.bahan_ajar_link && 'Klik disini'}
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </LayoutTable>
            </div>
            <div className="col-1 ps-0">
              {user.group === "Akademik" && (
                <LayoutTable copy={false}>
                  <LayoutThead>
                    <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal align-middle text-nowrap"><i className="fa fa-fw fa-cog"/></th>
                  </LayoutThead>
                  <tbody>
                    {items.data.map((i, index) => {
                      return (
                        <tr key={index}>
                          <td className="py-1 border-silver py-1 border-silver text-center">
                            <MatkulEdit
                              matkul={i}
                              kelompokMatkul={kelompokMatkul}
                              jurusan={itemsJurusan}
                              onFetchData={fetchData}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </LayoutTable>
              )}
            </div>
          </div>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default JamkulAkademik;
