import React, { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { tokenState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import axios from "../../axios";
import LoadingPage from "../../components/pages/Loading";
import TableSection from "../../components/sections/Table";
import NoDataPage from "../../components/pages/NoData";
import TopContentSection from "../../components/sections/TopContent";
import PaginationSection from "../../components/sections/Pagination";
import BtnGroupSection from "../../components/sections/BtnGroup";
import SectionModalForm from "../../components/sections/ModalForm";
import SectionBtnSubmit from "../../components/sections/BtnSubmit";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import { saAlert } from "../../helpers";
import { FilterSearch, FilterTahun } from "../../components/layouts/TopContent";
import FormSelect from "../../components/forms/Select";
import { fetchingData } from "../../api";

const SoalPenilaian = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);

  const [isLoaded, setIsLoaded] = useState();
  const [items, setItems] = useState();

  const [page, setPage] = useState("");
  const [search, setSearch] = useState("");
  const [tahun, setTahun] = useState(new Date().getFullYear());

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(
        "/api/soal-penilaian?page=" +
          page +
          "&search=" +
          search +
          "&tahun=" +
          tahun,
        { headers: { Authorization: "Bearer " + token } }
      );
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [token, setItems, setIsLoaded, page, search, tahun]);

  useEffect(() => {
    setTitle("Form Penilaian Kompetensi Pegawai");
    fetchData();
  }, [setTitle, fetchData]);

  const [openModal, setOpenModal] = useState(false);
  const [form, setForm] = useState("");
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      fetchingData();
      await axios.post("/api/soal-penilaian", form, {
        headers: { Authorization: "Bearer " + token },
      });
      fetchData();
      setErrors({});
      setForm("");
      e.target.reset();
      saAlert("success", "Berhasil tambah form penilaian !");
      setOpenModal(!openModal);
    } catch (err) {
      if (err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  // this for form
  const tagFormCreate = useRef(null);

  if (isLoaded) {
    return (
      <>
        <TopContentSection
          addModal="Tambah Form Penilaian"
          onClickAddModal={() => setOpenModal(!openModal)}
          // search={true}
          // onChangeSearch={(res) => handleSearch(res)}
        />
        <div className="row mb-2">
          <div className="col-6">
            <FilterTahun
              value={tahun}
              onChange={(res) => {
                setTahun(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-6">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {items.data.length ? (
          <>
            <TableSection
              thead={<Thead />}
              tbody={<Tbody token={token} items={items} onReload={fetchData} />}
            />
          </>
        ) : (
          <NoDataPage />
        )}
        <PaginationSection
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />

        <SectionModalForm
          title="Tambah Form Penilaian"
          openModal={openModal}
          onClose={() => {
            setErrors({});
            setForm({});
            setOpenModal(!openModal);
            tagFormCreate.current && tagFormCreate.current.reset();
          }}
        >
          <form onSubmit={handleSubmit} ref={tagFormCreate}>
            <FormSelect
              label="Tahun"
              name="tahun"
              error={errors.tahun}
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: e.target.value })
              }
            >
              <option value="">Pilih</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2025">2026</option>
              <option value="2025">2027</option>
              <option value="2025">2028</option>
            </FormSelect>
            <FormInput
              label="Judul"
              name="judul"
              error={errors.judul}
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: e.target.value })
              }
            />
            <FormTextarea
              label="Isi"
              name="isi"
              error={errors.isi}
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: e.target.value })
              }
            />
            <p>Gunakan <span className="text-success">||</span> untuk membuat kalimat enter kebawah.</p>
            <SectionBtnSubmit
              submit="Simpan"
              dismiss="Batal"
              onDismiss={() => {
                setErrors({});
                setForm({});
                setOpenModal(!openModal);
                tagFormCreate.current && tagFormCreate.current.reset();
              }}
            />
          </form>
        </SectionModalForm>
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

const Thead = () => {
  return (
    <tr>
      <th>No</th>
      <td>ID</td>
      <td>Tahun</td>
      <th>Judul</th>
      <th>Isi</th>
      <th></th>
    </tr>
  );
};

const Tbody = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [form, setForm] = useState("");
  const refForm = useRef();
  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      fetchingData();
      await axios.patch("/api/soal-penilaian/" + form.id, form, {
        headers: { Authorization: "Bearer " + props.token },
      });
      props.onReload();
      setErrors({});
      setForm("");
      e.target.reset();
      saAlert("success", "Berhasil ubah form penilaian !");
      setOpenModal(!openModal);
    } catch (err) {
      if (err.response.status === 422) {
        setErrors(err.response.data.errors);
      }
    }
  };

  return props.items.data.map((i, index) => {
    return (
      <React.Fragment key={index}>
        <tr>
          <td className="text-center">{props.items.from + index}</td>
          <td>{i.id}</td>
          <td>{i.tahun}</td>
          <td>{i.judul}</td>
          <td>{i.isi}</td>
          <td>
            <BtnGroupSection
              delete={"/api/soal-penilaian/" + i.id}
              onConfirmDelete={props.onReload}
              editModal={true}
              onClickEditModal={() => {
                setForm({
                  id: i.id,
                  tahun: i.tahun,
                  judul: i.judul,
                  isi: i.isi,
                });
                setOpenModal(!openModal);
              }}
            />
          </td>
        </tr>
        <SectionModalForm
          title={"Ubah Data " + i.judul}
          openModal={openModal}
          onClose={() => {
            refForm.current && refForm.current.reset();
            setErrors({});
            setForm({});
            setOpenModal(!openModal);
          }}
        >
          <form method="POST" onSubmit={handleSubmit} ref={refForm}>
            <FormSelect
              label="Tahun"
              name="tahun"
              value={form.tahun}
              error={errors.tahun}
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: e.target.value })
              }
            >
              <option value="">Pilih</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
            </FormSelect>
            <FormInput
              label="Judul"
              name="judul"
              value={form.judul}
              error={errors.judul}
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: e.target.value })
              }
            />
            <FormTextarea
              label="Isi"
              name="isi"
              value={form.isi}
              error={errors.isi}
              onChange={(e) =>
                setForm({ ...form, [e.target.name]: e.target.value })
              }
            />
            <SectionBtnSubmit
              submit="Ubah"
              dismiss="Batal"
              onDismiss={() => {
                refForm.current && refForm.current.reset();
                setErrors({});
                setForm({});
                setOpenModal(!openModal);
              }}
            />
          </form>
        </SectionModalForm>
      </React.Fragment>
    );
  });
};

export default SoalPenilaian;
