import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { CeklistMonitoringListContext } from "../Routes";

const List = ({ mainUrl, onFetchData }) => {
  const { id } = useParams();
  const { ceklistMonitoring, cabang, departemen, auditor } = useContext(
    CeklistMonitoringListContext
  );

  return (
    <>
      <table className="table table-sm">
        <thead>
          <tr>
            <th>cabang</th>
            <th>departemen</th>
            <th>auditor</th>
            <th>kode_dokumen</th>
            <th>revisi</th>
            <th>tanggal_efektif</th>
          </tr>
        </thead>
        <tbody>
          {ceklistMonitoring.data &&
            ceklistMonitoring.data.length > 0 &&
            ceklistMonitoring.data
              .filter((cm) => (cm) => parseInt(cm.id) === parseInt(id))
              .map((cm, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {cabang.length > 0 &&
                        cabang
                          .filter(
                            (c) => parseInt(c.id) === parseInt(cm.cabang_id)
                          )
                          .map((c) => c.nama)}
                    </td>
                    <td>
                      {departemen.length > 0 &&
                        departemen
                          .filter(
                            (d) => parseInt(d.id) === parseInt(cm.departemen_id)
                          )
                          .map((c) => c.nama)}
                    </td>
                    <td>
                      {auditor.length > 0 &&
                        auditor
                          .filter(
                            (a) => parseInt(a.id) === parseInt(cm.auditor_id)
                          )
                          .map((c) => c.nama)}
                    </td>
                    <td>{cm.kode_dokumen}</td>
                    <td>{cm.revisi}</td>
                    <td>{cm.tanggal_efektif}</td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </>
  );
};

export default List;
