import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { titleState } from "../storage/pages";

const NoMatch = () => {
  const setTitle = useSetRecoilState(titleState);
  useEffect(() => {
    setTitle("404");
  }, [setTitle]);

  return <h1>404 Page not found.</h1>;
};

export default NoMatch;
