import React from "react";

const FormInvalid = ({ children }) => {
  return (
    <div
      style={{
        width: "100%",
        marginTop: "0.25rem",
        fontSize: "80%",
        color: "#e74a3b",
      }}
    >
      {children}
    </div>
  );
};

export default FormInvalid;
