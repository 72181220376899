import { useState } from "react";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import { getOnOffMhs } from "../../helpers";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";

const KelasAngketDosenDetail = (props) => {
  const { kelas, krs, penilaian_dosen, mhs } = props;

  const [modal, setModal] = useState(false);
  return (
    <>
      <button className="btn btn-sm btn-primary-3 text-sm fw-semibold py-0" type="button" onClick={() => setModal(true)}>
        <span className={`
          ${penilaian_dosen.length === 0 ? 'text-danger' : ''}
          ${penilaian_dosen.length < mhs.filter(m => parseInt(m.status) !== 9).length ? 'text-warning' : ''}
          ${penilaian_dosen.length === mhs.filter(m => parseInt(m.status) !== 9).length ? 'text-success-2' : ''}
        `}>
          {penilaian_dosen && penilaian_dosen.length}
        </span>
        {"/"}
        {mhs.length > 0 && mhs.filter(m => parseInt(m.status) !== 9).length}
      </button>
      <LayoutModal
        modal={modal}
        title={`Angket Kelas ${kelas.kode}`}
        onClose={() => setModal(false)}
      >
        <TextOnOffMhs/>
        
        <TableNew>
          <Thead>
            <Tr className="text-center">
              <Th>No</Th>
              <Th>NIM</Th>
              <Th>Nama</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {mhs.length > 0 &&
              mhs.map((m, index) => {
                return (
                  <Tr key={index} className={getOnOffMhs(m)}>
                    <Td className="bg-none text-center">{index + 1}</Td>
                    <Td className="bg-none text-center">{m.nim}</Td>
                    <Td className="bg-none text-nowrap">{m.nama}</Td>
                    <Td className="bg-none text-center">
                    {
                      penilaian_dosen.length > 0 &&
                      penilaian_dosen.filter(
                        (p) => parseInt(p.mhs_id) === parseInt(m.id)
                      ).length > 0
                        ? <i className="fa fa-fw fa-circle-check text-success-2"/>
                        : <i className="fa fa-fw fa-circle-xmark text-danger"/>
                    }
                    </Td>
                  </Tr>
                );
              })}
          </Tbody>
        </TableNew>
      </LayoutModal>
    </>
  );
};

export default KelasAngketDosenDetail;
