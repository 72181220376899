import axios from "../../axios";
import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterBulan,
  defaultFilterTahun,
  FilterBulan,
  FilterTahun,
} from "../../components/layouts/TopContent";
import { formatDate, formatDiffDate, saAlert, saConfirm } from "../../helpers";
import { tokenState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import LoadingPage from "../../components/pages/Loading";
import NoDataPage from "../../components/pages/NoData";

const IjinApproval = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState(false);

  const [items, setItems] = useState();
  const [page] = useState("all");
  const [bulan, setBulan] = useState(defaultFilterBulan);
  const [tahun, setTahun] = useState(defaultFilterTahun);

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(
        "/api/ijin/show" +
          "?page=" +
          page +
          "&bulan=" +
          bulan +
          "&tahun=" +
          tahun +
          "&approval=true",
        { headers: { Authorization: "Bearer " + token } }
      );
      if (res.data && res.data.ijin) {
        setItems(res.data.ijin);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [page, bulan, tahun, token]);

  useEffect(() => {
    setTitle("Approve Ijin");

    fetchData();
  }, [setTitle, fetchData]);

  const handleUpdate = (id, status, message) => {
    saConfirm("warning", `Yakin ${message} Ijin ?`).then((res) => {
      if (res.isConfirmed) {
        axios
          .patch(
            "/api/ijin/" + id,
            { status },
            { headers: { Authorization: "Bearer " + token } }
          )
          .then((res) => {
            if (res.data && res.data.msg === "success") {
              fetchData();
              saAlert("success", `Berhasil ${message} Ijin.`);
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  if (isLoaded) {
    return (
      <>
        <div className="row mb-2">
          <div className="col-md">
            <FilterBulan onChange={(res) => setBulan(res)} />
          </div>
          <div className="col-md">
            <FilterTahun onChange={(res) => setTahun(res)} />
          </div>
        </div>

        {items && items.length > 0 ? (
          <LayoutTable>
            <LayoutThead>
              <th>No</th>
              <th>Cabang</th>
              <th>NIP</th>
              <th>Nama</th>
              <th>Jenis</th>
              <th>Dari</th>
              <th>Sampai</th>
              <th>Lama</th>
              <th>Keperluan</th>
              <th>Status</th>
              <th>Oleh</th>
            </LayoutThead>
            <tbody>
              {items.map((i, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center align-middle">{1 + index}</td>
                    <td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.cabang}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.nip}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {i.pegawai && i.pegawai.nama}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {parseInt(i.status_keterangan) === 1 && (
                        <span className="text-info">Sakit</span>
                      )}
                      {parseInt(i.status_keterangan) === 2 && (
                        <span className="text-primary">Keperluan Lain</span>
                      )}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {formatDate(i.dari)}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {formatDate(i.sampai)}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {formatDiffDate(i.jumlah)}
                    </td>
                    <td className="align-middle">{i.keperluan_lain}</td>
                    <td className="text-center align-middle">
                      {parseInt(i.status) === 1 && (
                        <span className="text-success">Approved</span>
                      )}
                      {parseInt(i.status) === 2 && (
                        <span className="text-danger">Rejected</span>
                      )}
                      {!parseInt(i.status) && (
                        <div className="btn-group">
                          <button
                            onClick={() => handleUpdate(i.id, 1, "Approve")}
                            type="button"
                            className="btn btn-sm btn-success"
                          >
                            Approve
                          </button>
                          <button
                            onClick={() => handleUpdate(i.id, 2, "Reject")}
                            type="button"
                            className="btn btn-sm btn-danger"
                          >
                            Reject
                          </button>
                        </div>
                      )}
                    </td>
                    <td className="text-nowrap text-center align-middle">
                      {i.atasan.nama}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </LayoutTable>
        ) : (
          <NoDataPage />
        )}
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

export default IjinApproval;
