import React from "react";
import { fetchingData } from "../../api";

const SectionPagination = ({
  from = false,
  to = false,
  total = false,
  links = [],
  onChangePage,
}) => {
  if (links && links.length > 0) {
    return (
      <nav>
        {from && to && total && (
          <div className="row mb-3">
            <div className="col text-center">
              <p className="mb-0 bg-primary rounded-3 py-1 text-white text-sm">
                Dari <span className="fw-semibold">{from}</span> s/d{" "}
                <span className="fw-semibold">{to}</span>
              </p>
            </div>
            <div className="col text-center">
              <p className="mb-0 bg-primary rounded-3 py-1 text-white text-sm">Total data <span className="fw-semibold">{total}</span></p>
            </div>
          </div>
        )}
        <ul className="pagination pagination-sm d-flex justify-content-center align-items-center">
          {links.length > 0 &&
            links.map((i, index) => {
              return (
                <li
                  key={index}
                  className={i.active ? "page-item bg-primary border-silver" : "page-item"}
                >
                  <button
                    onClick={() => {
                      if (i.url) {
                        let page = i.url.split("?page=");
                        page = page[1];
                        onChangePage(page);
                        fetchingData();
                      }
                      // }} className="page-link" dangerouslySetInnerHTML={{__html: i.label}}></button>
                    }}
                    className={`page-link bg-none ${i.active ? 'border-1 border-dark text-white' : 'border-silver'}`}
                  >
                    {(() => {
                      if (i.label === "&laquo; Previous") {
                        return <span aria-hidden="true"><i className="align-middle fa fa-fw fa-xs fa-arrow-left"/></span>;
                      } else if (i.label === "Next &raquo;") {
                        return <span aria-hidden="true"><i className="align-middle fa fa-fw fa-xs fa-arrow-right"/></span>;
                      } else {
                        return i.label;
                      }
                    })()}
                  </button>
                </li>
              );
            })}
        </ul>
      </nav>
    );
  }
  return "";
};

export default SectionPagination;
