import React, { useRef, useState } from "react";
import { patchKelas } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import LayoutModal from "../../components/layouts/Modal";
import { getHari, saAlert } from "../../helpers";

const KelasEdit = (props) => {
  const tagForm = useRef();

  const [modal, setModal] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const {
    customClass,
    customIcon,
    customName,
    customTitle,
    matkul,
    dosen,
    ruangan,
    jamkul,
    onFetchData,
  } = props;

  const handleClose = () => {
    if (tagForm.current) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    patchKelas(form.id, form)
      .then((res) => {
        if (res.data === "success") {
          saAlert("success", "Berhasil ubah kelas");
          onFetchData();
          handleClose();
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 422) {
            setErrors(err.response.data.errors);
          }
        }
      });
  };

  return (
    <>
      <button
        onClick={() => {
          setForm(props.kelas);
          setModal(true);
        }}
        type="button"
        title={customTitle}
        className={customClass}
      >
        <i className={customIcon}/>
        {customName ?? "Ubah Kelas"}
      </button>
      <LayoutModal
        size="md"
        title={`Ubah Kelas ${props.kelas.kode}`}
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
          <FormInput
            label="Kode"
            name="kode"
            value={form.kode}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.kode}
          />
          <FormInput
            label="Tahun Ajaran"
            name="tahun_ajaran"
            value={form.tahun_ajaran}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.tahun_ajaran}
          />
          <FormSelect
            label="Matkul"
            name="matkul_id"
            value={form.matkul_id}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.matkul_id}
          >
            <option value="">Pilih Matkul</option>
            {matkul.length >= 1 &&
              matkul.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <option value={i.id}>{i.nama}</option>
                  </React.Fragment>
                );
              })}
          </FormSelect>
          <FormSelect
            label="Semester"
            name="semester"
            value={form.semester}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.semester}
          >
            <option value="">Pilih</option>
            <option value="1">Semester 1</option>
            <option value="2">Semester 2</option>
            <option value="3">Semester 3</option>
            <option value="4">Semester 4</option>
          </FormSelect>
          <FormInput
            label="Jumlah Pertemuan"
            name="jumlah_pertemuan"
            value={form.jumlah_pertemuan}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.jumlah_pertemuan}
          />
          <FormSelect
            label="Dosen"
            name="dosen_id"
            value={form.dosen_id}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.dosen_id}
          >
            <option value="">Pilih Dosen</option>
            {dosen.length >= 1 &&
              dosen.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <option value={i.id}>{i.nama}</option>
                  </React.Fragment>
                );
              })}
          </FormSelect>
          <FormSelect
            label="Ruangan"
            name="ruangan_id"
            value={form.ruangan_id}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.ruangan_id}
          >
            <option value="">Pilih Ruangan</option>
            {ruangan.length >= 1 &&
              ruangan.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <option value={i.id}>{i.nama}</option>
                  </React.Fragment>
                );
              })}
          </FormSelect>
          <FormSelect
            label="Jam Kuliah"
            name="jamkul_id"
            value={form.jamkul_id}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.jamkul_id}
          >
            <option value="">Pilih Jam Kuliah</option>
            {jamkul.length >= 1 &&
              jamkul.map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    <option value={i.id}>
                      {getHari(i.hari)} || Jam ({i.dari} - {i.sampai})
                    </option>
                  </React.Fragment>
                );
              })}
          </FormSelect>
          <FormSelect
            label="Jadwal"
            name="jadwal"
            value={form.jadwal}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.jadwal}
          >
            <option value="">Pilih Jadwal</option>
            <option value="1">Pagi</option>
            <option value="0">Sore</option>
          </FormSelect>
          <FormSelect
            label="Projector"
            name="projector"
            value={form.projector}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.projector}
          >
            <option value="">Pilih Projector</option>
            <option value="1">Yes</option>
            <option value="0">No</option>
          </FormSelect>
          <FormInput
            label="Group Whatsapp"
            name="group_whatsapp"
            value={form.group_whatsapp}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.group_whatsapp}
          />
          <FormInput
            label={`Persentasi Kehadiran <span class="text-dark">(Bilangan Bulat || Range 10-20)</span>`}
            name="persentasi_kehadiran"
            type="number"
            value={form.persentasi_kehadiran}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.persentasi_kehadiran}
          />
          <FormInput
            label={`Persentasi Tugas <span class="text-dark">(Bilangan Bulat || Range 20-40)</span>`}
            name="persentasi_tugas"
            type="number"
            value={form.persentasi_tugas}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.persentasi_tugas}
          />
          <FormInput
            label={`Persentasi UTS <span class="text-dark">(Bilangan Bulat || Range 20-30)</span>`}
            name="persentasi_uts"
            type="number"
            value={form.persentasi_uts}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.persentasi_uts}
          />
          <FormInput
            label={`Persentasi UAS / Project <span class="text-dark">(Bilangan Bulat || Range 20-40)</span>`}
            name="persentasi_uas"
            type="number"
            value={form.persentasi_uas}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.persentasi_uas}
          />
          <FormInput
            label={`Persentasi Revisi <span class="text-dark">(Bilangan Bulat || Range 10)</span>`}
            name="persentasi_revisi"
            type="number"
            value={form.persentasi_revisi}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.persentasi_revisi}
          />
          <div className="btn-group w-100">
            <button type="submit" className="btn btn-sm btn-success-2">
              Simpan perubahaan
            </button>
            <button
              onClick={handleClose}
              type="button"
              className="btn btn-sm btn-dark border-0"
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default KelasEdit;
