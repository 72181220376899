import React from "react";
import reactDom from "react-dom";

const LayoutModal = (props) => {
  // const {id, modal, size, btnClose=true, title, icon, onClose, children } = props;
  const {modal, size, title, onClose, children } = props;

  const rootModal = document.getElementById("rootModal");

  return reactDom.createPortal(
    // <Modal
    //     show={modal}
    //     onHide={onClose}
    //     className=""
    //     dialogClassName=""
    //     contentClassName="rounded-bottom-3 rounded-top-4 modal-dark"
    //     centered
    //     size="xl"
    //     backdrop="static"
    //   >
    //     <Modal.Header 
    //       data-bs-theme="dark"
    //       closeButton
    //       className="bg-gradient-primary text-white border-bottom-warning rounded-top-3 d-flex align-items-center py-2"
    //     >
    //       <Modal.Title
    //         className="fs-6"
    //       >
    //         {icon === "edit" && <i className="fa fa-fw fa-sm fa-pen me-2"/>}
    //         {title}
    //       </Modal.Title>
    //     </Modal.Header>
    //     <Modal.Body>
    //       {children}
    //     </Modal.Body>
    //   </Modal>,

    // <div className="modal fade" id={id} data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-hidden="true">
    //   <div className={`${size ? `modal-${size}` : 'modal-xl'} modal-dialog modal-dialog-centered`}>
    //     <div className="modal-content rounded-top-4 rounded-bottom-3">
    //       <div data-bs-theme="dark" className="modal-header align-items-center bg-gradient-primary border-bottom-warning text-white rounded-top-3 py-2">
    //         <h1 className="modal-title fs-5" dangerouslySetInnerHTML={{ __html: title }}></h1>
    //         <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
    //       </div>
    //       <div className="modal-body">
    //         {children}
    //       </div>
    //       {btnClose &&
    //         <div className="modal-footer">
    //           <button type="button" className="btn btn-sm btn-dark border-0 text-sm px-3" data-bs-dismiss="modal"><i className="fa fa-fw fa-sm fa-arrow-left"/> Tutup</button>
    //         </div>
    //       }
    //     </div>
    //   </div>
    // </div>,
    <div
      className={`modal fade ${modal ? "show d-block" : null}`}
      tabIndex="-1"
      aria-hidden="true"
      style={{ backgroundColor: "rgb(0, 0, 0, 0.5)" }}
    >
      <div className={`${size ? `modal-${size}` : 'modal-xl'} modal-dialog modal-dialog-centered modal-dialog-scrollable`}>
        <div className="modal-content rounded-4 h-auto">
          <div data-bs-theme="dark" className="modal-header align-items-center bg-gradient-primary border-bottom-warning text-white rounded-top-4 py-2">
            <h6 className="modal-title" dangerouslySetInnerHTML={{ __html: title }}></h6>
            <button type="button" className="btn-close" onClick={onClose} />
          </div>
          <div className="modal-body">
            {children}
          </div>
        </div>
      </div>
    </div>,


    // <div
    //   className={`modal fade ${modal ? "show d-block" : null}`}
    //   tabIndex="-1"
    //   aria-hidden="true"
    //   style={{ backgroundColor: "rgb(0 0 0 / 70%)" }}
    // >
    //   <div
    //     className="modal-dialog modal-xl modal-dialog-scrollable"
    //     style={{ maxWidth: "80%" }} 
    //   >
    //     <div className="modal-content">
    //       <div className="modal-header bg-primary text-white">
    //         <h6
    //           className="modal-title text-white"
    //           dangerouslySetInnerHTML={{ __html: title }}
    //         ></h6>
    //         <button
    //           onClick={onClose}
    //           type="button"
    //           className="close bg-danger text-white"
    //           data-dismiss="modal"
    //           aria-label="Close"
    //         >
    //           <span aria-hidden="true">×</span>
    //         </button>
    //       </div>
    //       <div className="modal-body">{children}</div>
    //     </div>
    //   </div>
    // </div>,
    rootModal
  );
};

export const ModalBtn = (props) => {
  const { btnSubmit = "Simpan", btnClose = "Kembali", onClose, width } = props;
  return (
    <div className={`btn-group ${width === "full" && 'w-100'}`}>
      <button type="submit" className="btn btn-sm btn-success-2">
        {btnSubmit}
      </button>
      <button
        type="button"
        className="btn btn-sm btn-dark border-0"
        onClick={onClose}
      >
        <i className="fa fa-fw fa-sm fa-arrow-left"/> {btnClose}
      </button>
    </div>
  );
};

export const NewSubmitButton = (props) => {
  const {className, marginAuto,  submitText = "Simpan", closeText = "Kembali", onClose, widthFull, disabled } = props;
  return (
    <div className={`btn-group ${className} ${widthFull ? 'w-100' : 'col-lg-6'} ${marginAuto === "start" && 'me-auto'} ${marginAuto === "end" && 'ms-auto'} ${!marginAuto && 'mx-auto'}`}>
      <button type="submit" className="btn btn-sm btn-success-2 rounded-start-3" disabled={disabled}>
        {submitText}
      </button>
      <button
        type="button"
        className="btn btn-sm btn-dark border-0 rounded-end-3"
        onClick={onClose}
      >
        <i className="fa fa-fw fa-sm fa-arrow-left"/> {closeText}
      </button>
    </div>
  );
};

export default LayoutModal;
