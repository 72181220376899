import React, { useState, useEffect, useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { titleState } from "../../storage/pages";
import { fetchAspekBsc, fetchJabatan } from "../../api";
import { userState } from "../../storage/auth";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import JabatanAddKpi from "../jabatan/AddKpi";
import { tahunAjaranState } from "../../storage/mhs";
import PageLoading from "../../components/pages/Loading";

const KpiShow = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const [isLoaded, setIsLoaded] = useState(1);

  const [jabatan, setJabatan] = useState({});

  const tA = useRecoilValue(tahunAjaranState);
  const [tahun] = useState(tA);

  const fetchData = useCallback(
    async (reset = true) => {
      if (reset) {
        setIsLoaded(false);
      }
      try {
        const res = await fetchJabatan(
          "all",
          `pegawai_id=${user.id}&tahun=${tahun}`
        );
        setTitle(res.data.title);
        setJabatan(res.data.jabatan);
        setIsLoaded(true);
      } catch (err) {
        //
      }
    },
    [setTitle, setJabatan, user.id, tahun]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const [aspekBsc, setAspekBsc] = useState([]);

  useEffect(() => {
    const fetchDataAspekBsc = async () => {
      const res = await fetchAspekBsc("all");
      setAspekBsc(res.data.aspek_bsc);
      setTitle("Data KPI");
    };
    fetchDataAspekBsc();
  }, [setTitle]);

  const checkExpiredJabatan = (tanggal) => {
    const currentDate = new Date();
    const parsedActiveDate = new Date(tanggal);
    return parsedActiveDate < currentDate;
  };

  if (isLoaded) {
    return (
      <div className="row">
        {jabatan &&
          jabatan.length > 0 &&
          jabatan.map((j, index) => {
            return (
              <div className="col-lg-4 mb-3" key={index}>
                <div className="position-relative bg-gradient-primary text-white rounded-4 shadow p-3 h-100">
                  {user.jabatan_.filter((uj) => uj.id === j.id).map((uj, index) => {
                    return uj.pivot.tanggal_berakhir !== null && 
                    checkExpiredJabatan(uj.pivot.tanggal_berakhir) &&
                      <div key={index} className="position-absolute bg-danger rounded text-white px-3" style={{top: '-6px', right: '-12px', rotate: '3deg'}}>Jabatan Berakhir</div>
                  })

                  }
                  <h6 className="d-inline-block mb-2 bg-warning text-black fw-semibold py-1 px-3 rounded-3 shadow">Grade {j.grade}</h6>
                  <p className="mb-0 text-warning text-sm">Jabatan :</p>
                  <h6 className="mb-0">{j.nama}</h6>
                  <p className="mb-0 text-gray-500">Cabang {j.cabang && j.cabang.nama}</p>
                  <hr className="my-2 border-silver"/>
                  <p className="mb-2 text-warning text-sm">Group :</p>
                  <ul className="text-gray-400 ps-0">
                  {j.group_jabatan &&
                    j.group_jabatan.length > 0 ?
                    j.group_jabatan.map((g, index) => {
                      return (
                        <li className="ms-4" key={index}>{g.group && g.group.nama}</li>
                      );
                    }) :
                    <p className="mb-0 text-secondary ps-3">Tidak ada group.</p>
                  }
                  </ul>
                  <JabatanAddKpi
                    showOnly={1}
                    jabatan={j}
                    aspekBsc={aspekBsc}
                    onFetchData={fetchData}
                  />
                </div>
              </div>
            )
          })
        }
      </div>
    );
  } else {
    return <PageLoading/>;
  }
};

export default KpiShow;
