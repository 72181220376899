import React from "react";
import { patchDosen } from "../../api";
import { saAlert, saConfirm } from "../../helpers";

const DosenResetPw = (props) => {
  const { dosen } = props;
  const handleSubmit = () => {
    saConfirm("warning", "Yakin reset Password Dosen ?").then((res) => {
      if (res.isConfirmed) {
        patchDosen(dosen.id, { reset_pw: true })
          .then((res) => {
            if (res.data === "reset_pw") {
              saAlert("success", "Berhasil reset Password Dosen !");
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };
  return (
    <>
      <button className="btn btn-sm btn-warning-2 fw-semibold text-sm py-0 px-1 me-1" onClick={handleSubmit} type="button">
        <i className="fa fa-fw fa-sm fa-key"/> Reset Password
      </button>
    </>
  );
};

export default DosenResetPw;
