import React from "react";
import { deleteDosen } from "../../api";
import { saAlert, saConfirm } from "../../helpers";

const DosenDelete = (props) => {
  const { dosen, onFetchData } = props;
  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus data Dosen ?").then((res) => {
      if (res.isConfirmed) {
        deleteDosen(dosen.id)
          .then((res) => {
            if (res.data === "success") {
              onFetchData();
              saAlert("success", "Berhasil hapus data Dosen !");
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };
  return (
    <button
      type="button"
      className="btn btn-sm btn-danger-2 text-sm py-0 px-1"
      onClick={handleDelete}
    >
      <i className="fa fa-fw fa-sm fa-trash-alt"/>
    </button>
  );
};

export default DosenDelete;
