import React from "react";

const FormSelect = (props) => {
  const { label, className, labelClassName, error, name, value, onChange, disabled, divClassName, children, required } = props;

  return (
    <div className={`form-group ${divClassName && divClassName}`}>
      {label && (
        <label
          className={`fw-semibold ${labelClassName ? labelClassName : 'text-primary'}`}
          dangerouslySetInnerHTML={{ __html: label }}
        ></label>
      )}
      <select
        className={`custom-select custom-select-sm form-select-custom text-black rounded-3 ${
          error ? "is-invalid" : 'border-silver'
        } ${className}`}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onChange}
        onMouseUp={onChange}
        disabled={disabled}
        required={required}
      >
        {children}
      </select>
      {error && <div className="invalid-feedback">{error}</div>}
    </div>
  );
};

export default FormSelect;
