import React, { useRef, useState } from "react";
import { deleteMhsProgkam, patchMhsProgkam, postMhsProgkam } from "../../api";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import { saConfirm, saAlert, getOrigin } from "../../helpers";
import * as XLSX from "xlsx";
import SmallButton from "../../components/forms/SmallButton";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const ProgkamAddMhs = (props) => {
  const { progkam, onFetchData } = props;
  const tagForm = useRef();
  const [modal, setModal] = useState(false);

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   saConfirm("warning", "Yakin sudah mengisi form dengan benar").then(
  //     (res) => {
  //       if (res.isConfirmed) {
  //         postMhsProgkam({ mhs_row: mhsRow, progkam_id: progkam.id })
  //           .then((res) => {
  //             if (res.data) {
  //               onFetchData();
  //               saAlert(
  //                 res.data.mhs.length ? "success" : "warning",
  //                 res.data.mhs.length
  //                   ? `Berhasil menambahkan ${res.data.mhs.length} mahasiswa kedalam program kampus !`
  //                   : `Tidak ada mahasiswa yang dimasukkan kedalam program kampus`
  //               );
  //             }
  //           })
  //           .catch((err) => console.log(err));
  //       }
  //     }
  //   );
  // };

  const handleDelete = (id) => {
    saConfirm("warning", "Yakin hapus mahasiswa ?").then((res) => {
      if (res.isConfirmed) {
        deleteMhsProgkam(id)
          .then((res) => {
            if (res.data === "success") {
              onFetchData();
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
  };

  return (
    <>
      <SmallButton margin='me-1' type='custom' icon='plus' buttonColor='primary-2' onClick={handleOpen}/>
      <LayoutModal
        title={`Tambah Data Program Kampus ${progkam.nama}`}
        modal={modal}
        onClose={handleClose}
      >
        {progkam ? (
          <>
            <div className="bg-light p-2 mb-2">
              {/* <form onSubmit={handleSubmit}>
                <FormTextarea
                  label="Tambah Mahasiswa ke Program Kampus"
                  row="10"
                  placeholder="Contoh : 2101010001,2101010002,2101010003,2101010004,2101010005 (Baca instruksi dibawah untuk lebih jelas)"
                  notes={`
                  <ul class="text-justify">
                    <li>Nim diurutkan dalam satu baris dan dipisahkan dengan tanda koma " , "</li>
                    <li>Rumus excel sebagai berikut, =TEXTJOIN(",";TRUE;B2:B10) "Sesuaikan B2 dan B10 dengan baris dari NIM yang ada pada data excel. Jika rumus tidak berhasil silahkan update Excel ke versi terbaru (Saya uji coba di excel 2019 berjalan normal)."</li>
                    <li>Pastikan tidak ada tanda baca lain selain koma. Tanpa Enter, Spasi, Tab, dll.</li>
                    <li>Contoh : 2101010001,2101010002,2101010003</li>
                  </ol>
                  `}
                  onChange={(e) => setMhsRow(e.target.value)}
                />
                <button type="submit" className="btn btn-primary">
                  Submit NIM
                </button>
              </form> */}

              <SubmitExcel onFetchData={onFetchData} progkam_id={progkam.id} />
            </div>
            {progkam.mhs && progkam.mhs.length > 0 ? (
              <>
                <div className="row mb-2 text-right">
                  <div className="col">
                    <button
                      className="btn btn-sm btn-success-2 rounded-3"
                      onClick={onFetchData}
                    >
                      Refresh Nilai
                    </button>
                  </div>
                </div>
                <TableNew>
                  <Thead>
                    <Tr className='text-center'>
                      <Th>No</Th>
                      <Th>NIM</Th>
                      <Th>Nama</Th>
                      <Th>Jurusan</Th>
                      <Th>Nilai</Th>
                      <Th></Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {progkam.mhs.map((i, index) => {
                      return (
                        <Tr key={index}>
                          <Td className="text-center align-middle">
                            {index + 1}
                          </Td>
                          <Td className="text-center align-middle">{i.nim}</Td>
                          <Td className="align-middle">{i.nama}</Td>
                          <Td className="align-middle">{i.jurusan}</Td>
                          <Td className="text-center align-middle">
                            {i.pivot && i.pivot.nilai}
                          </Td>
                          <Td>
                            <SetNilai
                              pivot={i.pivot ? i.pivot : null}
                              onFetchData={onFetchData}
                            />
                          </Td>
                          <Td>
                            <span
                              className="text-danger fa fa-trash-alt"
                              type="button"
                              onClick={() =>
                                handleDelete(i.pivot && i.pivot.id)
                              }
                            ></span>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </TableNew>
              </>
            ) : (
              <PageNoData />
            )}
          </>
        ) : (
          <PageLoading />
        )}
        <button
          onClick={handleClose}
          type="button"
          className="btn btn-sm btn-dark border-0"
        >
          <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
        </button>
      </LayoutModal>
    </>
  );
};

const SetNilai = (props) => {
  // const { pivot, onFetchData } = props;
  const { pivot } = props;

  const handleSave = async (val) => {
    try {
      const res = await patchMhsProgkam(pivot.id, { nilai: val });
      if (res.data === "success") {
        console.log("success");
        // saAlert("success", "Berhasil update nilai !");
      }
    } catch (err) {
      console.log(err);
    }
  };

  let timer = 0;
  const handleChange = (val) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      handleSave(val);
    }, 1200);
  };

  return (
    <div className="d-flex justify-content-center">
      <input
        type="text"
        defaultValue={pivot.nilai}
        onChange={(e) => handleChange(e.target.value)}
      />
      {/* <span type="button" className="badge badge-success" onClick={handleSave}>
        Save
      </span> */}
    </div>
  );
};

const SubmitExcel = ({ onFetchData, progkam_id }) => {
  const tagForm = useRef(null);
  const [excelFile, setExcelFile] = useState(null);
  const [excelFileError, setExcelFileError] = useState(null);

  const [excelData, setExcelData] = useState({});

  const availableTypes = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const handleChange = (e) => {
    let selectedFile = e.target.files[0];
    setExcelData({});

    if (selectedFile) {
      if (availableTypes.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          setExcelFileError(null);
          setExcelFile(e.target.result);
        };
      } else {
        setExcelFileError("Pilih hanya file Excel !");
        setExcelFile(null);
      }
    } else {
      saAlert("warning", "Silahkan pilih file terlebih dahulu !");
    }
  };

  const handleSubmitExcel = async (e, upload = false) => {
    e.preventDefault();

    if (upload) {
      try {
        const res = await postMhsProgkam({
          excel_upload: excelData,
          progkam_id,
        });
        if (res.data && res.data.status === "success") {
          tagForm.current && tagForm.current.reset();
          setExcelFile(null);
          setExcelFileError(null);
          setExcelData({});
          onFetchData();
          saAlert(
            "success",
            "Berhasil tambah data program kampus.",
            `${res.data.uploaded.length} data telah ditambah !`
          );
        }
      } catch (err) {
        if (err.response) {
          console.error(err.response);
        }
      }
    } else {
      if (excelFile !== null) {
        const workbook = XLSX.read(excelFile, { type: "buffer" });
        const worksheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[worksheetName];
        const data = XLSX.utils.sheet_to_json(worksheet);
        setExcelData(data);
      } else {
        setExcelData({});
      }
    }
  };

  return (
    <form onSubmit={handleSubmitExcel} ref={tagForm}>
      <label htmlFor="">
        Upload Excel File. Unduh template Excel{" "}
        <span
          type="button"
          className="text-primary"
          onClick={() => {
            window.open(
              getOrigin() + "/files/contoh_upload_data_program_kampus.xlsx"
            );
          }}
        >
          disini
        </span>
      </label>
      <div className="mb-2">
        <input type="file" onChange={handleChange} />
        {excelFileError && (
          <>
            <br />
            <span className="text-danger">{excelFileError}</span>
          </>
        )}
      </div>
      <div className="btn-group mb-2">
        <button className="btn btn-sm btn-primary" type="submit">
          Cek File
        </button>
        {excelData.length > 0 && (
          <button
            className="btn btn-sm btn-success"
            type="button"
            onClick={(e) => {
              handleSubmitExcel(e, true);
            }}
          >
            Upload Data
          </button>
        )}
      </div>
      {excelData.length > 0 && (
        <>
          <h4>{excelData.length} data telah ditemukan dalam file Excel.</h4>
          <LayoutTable copy={false}>
            <LayoutThead>
              <th>No</th>
              <th>NIM</th>
              <th>Nilai</th>
            </LayoutThead>
            <tbody>
              {excelData.map((ed, index) => {
                return (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center">{ed.nim}</td>
                    <td className="text-center">{ed.nilai}</td>
                  </tr>
                );
              })}
            </tbody>
          </LayoutTable>
        </>
      )}
    </form>
  );
};

export default ProgkamAddMhs;
