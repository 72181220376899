import { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  deletePerusahaan,
  deletePojt,
  fetchingData,
  fetchJurusan,
  fetchPerusahaan,
} from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterCabang,
  FilterJurusan,
  FilterPagination,
  FilterSearch,
} from "../../components/layouts/TopContent";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import ShowHideDetail from "../../components/ShowHideDetail";
import { formatDate, saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import { periodeOjtState } from "../../storage/mhs";
import { titleState } from "../../storage/pages";
import PojtEdit from "../pojt/Edit";
import PerusahaanAktifOjt from "./AktifOjt";
import { FilterTahunAjaranPerusahaan } from "./Components";
import CountOnTop from "./CountOnTop";
import PerusahaanCreate from "./Create";
import PerusahaanEdit from "./Edit";
import { BtnForPerusahaan, getStatusMou } from "./Helper";
import PerusahaanTableDetail from "./TableDetail";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import LoadingBasic from "../../components/pages/LoadingBasic";
import TextOnOffMhs from "../../components/layouts/TextOnOffMhs";

const PerusahaanPkk = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const periodeOjt = useRecoilValue(periodeOjtState);

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState({});
  const [count, setCount] = useState({});

  const [page, setPage] = useState(1);

  const [tahunAjaran, setTahunAjaran] = useState(periodeOjt);
  const [search, setSearch] = useState("");
  const [mou, setMou] = useState("");
  const [pagination, setPagination] = useState(10);
  const [cabang, setCabang] = useState("");
  const [statusOjt, setStatusOjt] = useState("");
  const [filterJurusan, setFilterJurusan] = useState("");

  const fetchData = useCallback(async () => {
    setIsLoaded(false);
    try {
      const res = await fetchPerusahaan(
        page,
        `search=${search}&pagination=${pagination}&cabang=${cabang}&mou=${mou}&tahun=${tahunAjaran}&status_ojt=${statusOjt}&jurusan=${filterJurusan}`
      );
      if (res.data) {
        setItems(res.data && res.data.perusahaan);

        if (res.data.baru_mou) {
          setCount((prevState) => {
            return { ...prevState, baru: res.data.baru_mou };
          });
        }

        if (res.data.perpanjang_mou) {
          setCount((prevState) => {
            return { ...prevState, perpanjang: res.data.perpanjang_mou };
          });
        }

        if (res.data.belum_mou) {
          setCount((prevState) => {
            return { ...prevState, belum: res.data.belum_mou };
          });
        }
        if (res.data.nonaktif) {
          setCount((prevState) => {
            return { ...prevState, nonaktif: res.data.nonaktif };
          });
        }
      }
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [
    page,
    search,
    cabang,
    mou,
    pagination,
    tahunAjaran,
    statusOjt,
    filterJurusan,
  ]);

  useEffect(() => {
    setTitle("Perusahaan PKK");
    fetchData();
  }, [fetchData, setTitle]);

  const [jurusan, setJurusan] = useState({});

  useEffect(() => {
    const fetchDataJurusan = async () => {
      try {
        const res = await fetchJurusan("all");
        if (res.data) {
          setJurusan(res.data);
        }
      } catch (err) {
        console.error(err.response);
      }
    };
    fetchDataJurusan();
  }, []);

  const [detail, setDetail] = useState(false);

  const handleDeletePerusahaan = (id) => {
    if (user.cabang === "pusat") {
      saConfirm("warning", "Yakin hapus data perusahaan ?").then(
        async (res) => {
          if (res.isConfirmed) {
            try {
              const res = await deletePerusahaan(id);
              if (res.data === "success") {
                fetchData();
                saAlert("success", "Berhasil hapus data perusahaan !");
              }
            } catch (err) {
              console.error(err.response);
            }
          }
        }
      );
    } else {
      saAlert(
        "warning",
        "Hanya akun pusat yang dapat menghapus data perusahaan !"
      );
    }
  };

  return (
    <>
      <PerusahaanCreate onFetchData={fetchData} />
      <CountOnTop
        baru={count.baru}
        perpanjang={count.perpanjang}
        belum={count.belum}
        nonaktif={count.nonaktif}
      />
      <div className="row">
        <div className="col-lg-4 mb-3">
          <div>
            <label htmlFor="" className="text-primary fw-semibold">Status OJT</label>
            <select
              className="form-select form-select-sm form-select-custom rounded-3 border-silver"
              onChange={(e) => {
                setStatusOjt(e.target.value);
                fetchingData();
              }}
              value={statusOjt}
            >
              <option value="">Semua</option>
              <option value="1">Aktif OJT</option>
              <option value="2">Tidak Aktif OJT</option>
            </select>
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <FilterTahunAjaranPerusahaan
            tahunAjaran={tahunAjaran}
            onChange={(res) => setTahunAjaran(res)}
          />
        </div>
        <div className="col-lg-4 mb-3">
          <FilterSearch
            onChange={(res) => {
              setSearch(res);
              setPage(1);
            }}
          />
        </div>
        <div className="col-lg-4 mb-3">
          <FilterJurusan onChange={(res) => setFilterJurusan(res)} />
        </div>
        <div className="col-lg-4 mb-3">
          <div>
            <label htmlFor="" className="text-primary fw-semibold">Status MoU</label>
            <select
              className="form-select form-select-sm form-select-custom border-silver rounded-3"
              onChange={(e) => {
                setMou(e.target.value);
                fetchingData();
              }}
              value={mou}
            >
              <option value="">Semua</option>
              <option value="1">Baru</option>
              <option value="2">Perpanjang</option>
              <option value="3">Belum MoU</option>
              <option value="4">Berita Acara</option>
              <option value="9">Nonaktif MoU</option>
            </select>
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <FilterPagination
            value={pagination}
            onChange={(res) => setPagination(res)}
          />
        </div>
        <div className="col-lg-4 mb-3">
          <FilterCabang onChange={(res) => setCabang(res)} pusat={true} />
        </div>
      </div>

      <ShowHideDetail detail={detail} onClick={(res) => setDetail(res)} />

      <TextOnOffMhs>
        Data perusahaan yang berwarna <span className="text-warning">kuning</span> menandakan bahwa data perusahaan tersebut wajib berisi <span className="text-warning">Bukti MoU</span> dan <span className="text-warning">Foto MoU</span>.
        <p className="mb-0 text-sm text-gray-400">Untuk mengisi Bukti MoU dan Foto MoU dapat dilakukan pada tombol edit di data perusahaan.</p>
      </TextOnOffMhs>

      <BtnForPerusahaan />

      {isLoaded ? (
        items.data.length > 0 ? (
          detail ? (
            <PerusahaanTableDetail perusahaan={items} />
          ) : (
            <div className="row">
              <div className="col-4 pe-0">
                <TableNew>
                  <Thead>
                    <Tr className="text-center">
                      <Th>No</Th>
                      <Th>Nama</Th>
                      <Th>PIC</Th>
                      <Th>MoU</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items.data.map((i, index) => {
                      return (
                        <Tr
                          key={index}
                          className={
                            i.status === 9 ? 'bg-invalid'
                            : (i.status !== 9 && i.status !== 3 && (!i.bukti_mou || !i.foto_mou)) ? 'bg-invalid-2'
                            : ''
                          }
                        >
                          <Td className="text-center">{items.from + index}</Td>
                          <Td className="text-nowrap">{i.nama}</Td>
                          <Td className="text-nowrap">
                            {i.pic ? (
                              i.pic
                            ) : (
                              <span style={{ opacity: "0" }}>-</span>
                            )}
                            {i.perusahaan_jurusan &&
                              i.perusahaan_jurusan.length > 0 &&
                              i.perusahaan_jurusan.map((pj, key) => {
                                if (key === 0) {
                                  return (
                                    <li
                                      style={{ listStyle: "none" }}
                                      key={key}
                                    ></li>
                                  );
                                }
                                return (
                                  <li
                                    style={{ listStyle: "none", opacity: "0" }}
                                    key={key}
                                  >
                                    {`-`}
                                  </li>
                                );
                              })}
                          </Td>
                          <Td className="text-nowrap text-center">
                            {getStatusMou(i.status)}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </TableNew>
              </div>
              <div className="col-6 px-0">
                <TableNew>
                  <Thead>
                    <Tr className="text-center">
                      <Th>Cabang</Th>
                      <Th>Dari</Th>
                      <Th>Sampai</Th>
                      <Th className="text-nowrap">Nomor MoU</Th>
                      <Th>Alamat</Th>
                      <Th className="text-nowrap">No Telfon</Th>
                      <Th className="text-nowrap">No HP</Th>
                      <Th>Email</Th>
                      <Th>Ultah</Th>
                      <Th>Keterangan</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items.data.map((i, index) => {
                      return (
                        <Tr
                          key={index}
                          className={
                            i.status === 9 ? 'bg-invalid'
                            : (i.status !== 9 && i.status !== 3 && (!i.bukti_mou || !i.foto_mou)) ? 'bg-invalid-2'
                            : ''
                          }
                        >
                          <Td
                            style={{ textTransform: "capitalize" }}
                            className="text-center"
                          >
                            {i.cabang}
                          </Td>
                          <Td className="text-nowrap">
                            {formatDate(i.mou_dari)}
                          </Td>
                          <Td className="text-nowrap">
                            {formatDate(i.mou_sampai)}
                          </Td>
                          <Td className="text-nowrap">{i.nomor_mou}</Td>
                          <Td className="text-nowrap">{i.alamat}</Td>
                          <Td className="text-nowrap">{i.no_telf}</Td>
                          <Td className="text-nowrap">{i.no_hp}</Td>
                          <Td className="text-nowrap">{i.email}</Td>
                          <Td className="text-nowrap">{formatDate(i.ultah)}</Td>
                          <Td className="text-nowrap">
                            {i.pojt &&
                              i.pojt.length > 0 &&
                              i.pojt
                                .filter((p) => p.tahun === tahunAjaran)
                                .map((p) => {
                                  return p.penempatan;
                                })}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </TableNew>
              </div>
              <div className="col-2 ps-0">
                <TableNew>
                  <Thead>
                    <Tr className="text-center">
                      <Th className="text-nowrap">Perusahaan OJT</Th>
                      <Th className="text-nowrap">Perusahaan</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {items.data.map((i, index) => {
                      return (
                        <Tr
                          key={index}
                          className={
                            i.status === 9 ? 'bg-invalid'
                            : (i.status !== 9 && i.status !== 3 && (!i.bukti_mou || !i.foto_mou)) ? 'bg-invalid-2'
                            : ''
                          }
                        >
                          <Td className="text-center">
                            {i.pojt && i.pojt.length > 0 ? (
                              i.pojt
                                .filter((p) => p.tahun === tahunAjaran)
                                .map((p) => {
                                  return (
                                    <div key={p.id} className="text-nowrap">
                                      <PojtEdit
                                        perusahaan={i}
                                        pojt={p}
                                        jurusan={jurusan}
                                        onFetchData={fetchData}
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-danger-2 text-sm py-0 px-1"
                                        onClick={() => {
                                          if (
                                            user.cabang === "pusat" ||
                                            i.cabang.toLowerCase() ===
                                            user.cabang.toLowerCase()
                                          ) {
                                            saConfirm(
                                              "warning",
                                              "Yakin hapus dari aktif OJT ?"
                                            ).then((res) => {
                                              if (res.isConfirmed) {
                                                deletePojt(p.id)
                                                  .then((res) => {
                                                    if (res.data === "success") {
                                                      fetchData();
                                                      saAlert(
                                                        "success",
                                                        "Berhasil hapus dari aktif OJT !"
                                                      );
                                                    }
                                                  })
                                                  .catch((err) =>
                                                    console.log(err)
                                                  );
                                              }
                                            });
                                          } else {
                                            saAlert(
                                              "warning",
                                              "Tidak dapat mengubah data cabang lain."
                                            );
                                          }
                                        }}
                                      >
                                        <i className="fa fa-fw fa-sm fa-trash-alt" />
                                      </button>
                                    </div>
                                  );
                                })
                            ) : (
                              <PerusahaanAktifOjt
                                jurusan={jurusan}
                                perusahaan={i}
                                tahunAjaran={tahunAjaran}
                                onFetchData={fetchData}
                              />
                            )}
                          </Td>
                          <Td className="text-nowrap text-center">
                            <PerusahaanEdit
                              perusahaan={i}
                              onFetchData={fetchData}
                            />
                            <i className="mr-1" />
                            <button
                              className="btn btn-sm btn-danger-2 text-sm py-0 px-1"
                              type="button"
                              onClick={() => handleDeletePerusahaan(i.id)}
                            >
                              <i className="fa fa-fw fa-sm fa-trash-alt" />
                            </button>
                            {i.perusahaan_jurusan &&
                              i.perusahaan_jurusan.length > 0 &&
                              i.perusahaan_jurusan.map((pj, key) => {
                                if (key === 0) {
                                  return (
                                    <li
                                      style={{ listStyle: "none" }}
                                      key={key}
                                    ></li>
                                  );
                                }
                                return (
                                  <li
                                    style={{ listStyle: "none", opacity: "0" }}
                                    key={key}
                                  >
                                    {`-`}
                                  </li>
                                );
                              })}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </TableNew>
              </div>
            </div>
          )
        ) : (
          <div className="my-3 p-3 rounded text-black" style={{backgroundColor: 'rgb(255, 0, 0, 0.3)'}}>
            <i className="fa fa-fw fa-circle-exclamation"/> Tidak ada data perusahaan.
          </div>
        )
      ) : (
        <div className="py-5">
          <LoadingBasic />
        </div>
      ) }
      <SectionPagination
        links={items.links}
        onChangePage={(res) => setPage(res)}
      />
    </>
  );
};

export default PerusahaanPkk;
