import React, { useEffect, useState } from 'react'
import SmallButton from '../../components/forms/SmallButton';
import LayoutModal, { NewSubmitButton } from '../../components/layouts/Modal';
import { LayoutAddBtn } from '../../components/layouts/TopContent';
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../components/layouts/TableNew';
import FormTextarea from '../../components/forms/Textarea';
import { deleteRkatList, fetchRkatList, patchRkatList, postRkatList } from '../../api';
import FormInput from '../../components/forms/Input';
import { getCurrency, saAlert, saConfirm } from '../../helpers';
import FormInputCurrency from '../../components/forms/InputCurrency';
import LoadingBasic from '../../components/pages/LoadingBasic';

function RkatList(props) {

    const {rkat, jabatan, fetchData} = props; 

    const [modal, setModal] = useState(false);
    const [createRkat, setCreateRkat] = useState(false);
    const [editRkat, setEditRkat] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rkatList, setRkatList] = useState([]);
    const [errors, setErrors] = useState({});
    const [form, setForm] = useState({});

    const handleOpen = () => {
        onFetchData();
        setModal(true);
    }

    const handleClose = () => {
        fetchData();
        setModal(false);
    }

    const onFetchData = async () => {
        setLoading(true);
        try {
            const res = await fetchRkatList('all', `&rkat_id=${rkat.id}`);
            setRkatList(res.data.rkat_lists);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    }

    // ================ create RKAT LIST ==========================

    const handleOpenCreate = () => {
        setCreateRkat(true);
    }

    const handleCloseCreate = () => {
        setCreateRkat(false);
        setErrors({});
        setForm({});
    }

    const handleChange = (e) => {
        setForm((prevState) => ({...prevState,[e.target.name]:e.target.value}))
    }

    const handleSubmitCreate = async (e) => {
        e.preventDefault();
        setErrors({});

        const combineForm = {...form, rkat_id: rkat.id};

        try {
            const res = await postRkatList(combineForm);
            saAlert(res.data.status, res.data.msg);
            setCreateRkat(false);
            onFetchData();
            setForm({});
        } catch (error) {
            setErrors(error.response && error.response.data && error.response.data.errors);
        }
    }

    // ================ edit RKAT LIST ==========================

    const handleOpenEdit = (rkatList) => {
        setEditRkat(true);
        setForm(rkatList);
    }

    const handleCloseEdit = () => {
        setEditRkat(false);
        setErrors({});
        setForm({});
    }

    const handleSubmitEdit = async (e) => {
        e.preventDefault();
        setErrors({});

        try {
            const res = await patchRkatList(form.id, form);
            saAlert(res.data.status, res.data.msg);
            setEditRkat(false);
            onFetchData();
            setForm({});
        } catch (error) {
            setErrors(error.response && error.response.data && error.response.data.errors);
        }
    }

    // ================ hapus RKAT LIST ==========================

    const handleDeleteRkatList = (id) => {
        saConfirm("warning", "Yakin hapus RKAT list?").then( async (res) => {
            if (res.isConfirmed) {
                try {
                    const res = await deleteRkatList(id);
                    saAlert(res.data.status, res.data.msg);
                    onFetchData();
                } catch (error) {
                    console.log(error);
                }
            }
        })
    }

  return (
    <>
        <SmallButton onClick={handleOpen} margin="me-1" icon="list" buttonColor="primary-2" type="custom" text="RKAT List"/>

        <LayoutModal
            title="RKAT List"
            modal={modal}
            onClose={handleClose}
        >
            <>
                <div className='bg-gradient-primary border-bottom-warning rounded-4 text-white p-4 mb-3'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <h5 className='mb-0'>
                                {rkat.jabatan && rkat.jabatan.nama}
                            </h5>
                            <div className='d-flex align-items-center mt-2'>
                                {rkat.type === 1 && <p className='bg-primary-4 border border-dark shadow-lg rounded-3 text-success-2 fw-semibold py-1 px-3 mb-0 me-2'><i className='fa fa-fw fa-sm fa-right-long'/> Pendapatan</p>}
                                {rkat.type === 2 && <p className='bg-primary-4 border border-dark shadow-lg rounded-3 text-danger-2 fw-semibold py-1 px-3 mb-0 me-2'><i className='fa fa-fw fa-sm fa-left-long'/> Pengeluaran</p>}
                                <p className='mb-0'>Tahun {rkat.tahun}</p>
                            </div>
                        </div>
                        <div className='col-lg-7 mt-lg-0 mt-3'>
                            <div className='row'>
                                <div className='col-xl-4 mb-xl-0 mb-3'>
                                    <div className='bg-primary shadow-lg border-bottom-warning border border-warning rounded-3 p-3'>
                                        <p className='mb-0'>Total Biaya :</p>
                                        <h6 className='mb-0'>{rkat.total_biaya ? `Rp.${getCurrency(rkat.total_biaya)}` : 'Rp.0'}</h6>
                                    </div>
                                </div>
                                <div className='col-xl-4 mb-xl-0 mb-3'>
                                    <div className='bg-primary shadow-lg border-bottom-warning border border-warning rounded-3 p-3'>
                                        <p className='mb-0'>Total Realisasi :</p>
                                        <h6 className='mb-0'>{rkat.total_realisasi ? `Rp.${getCurrency(rkat.total_realisasi)}` : 'Rp.0'}</h6>
                                    </div>
                                </div>
                                <div className='col-xl-4'>
                                    <div className='bg-primary shadow-lg border-bottom-warning border border-warning rounded-3 p-3'>
                                        <p className='mb-0'>Sisa Biaya :</p>
                                        <h6 className='mb-0'>{rkat.sisa_biaya ? `Rp.${getCurrency(rkat.sisa_biaya)}` : 'Rp.0'}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {(createRkat || editRkat) ? 
                    <>
                        {createRkat && 
                            <>
                                <form onSubmit={handleSubmitCreate}>
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <div className='col-lg-12'>
                                                <FormInput
                                                    type="text"
                                                    label="Kode"
                                                    name="kode"
                                                    onChange={handleChange}
                                                    error={errors.kode}
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <FormTextarea
                                                    row={6}
                                                    label="Rencana"
                                                    name="rencana"
                                                    onChange={handleChange}
                                                    error={errors.rencana}
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <FormTextarea
                                                    row={6}
                                                    label="Sub Bidang"
                                                    name="sub_bidang"
                                                    onChange={handleChange}
                                                    error={errors.sub_bidang}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya September"
                                                        name="biaya_sep"
                                                        value={form.biaya_sep}
                                                        onChange={handleChange}
                                                        error={errors.biaya_sep}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Oktober"
                                                        name="biaya_okt"
                                                        value={form.biaya_okt}
                                                        onChange={handleChange}
                                                        error={errors.biaya_okt}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya November"
                                                        name="biaya_nov"
                                                        value={form.biaya_nov}
                                                        onChange={handleChange}
                                                        error={errors.biaya_nov}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Desember"
                                                        name="biaya_des"
                                                        value={form.biaya_des}
                                                        onChange={handleChange}
                                                        error={errors.biaya_des}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Januari"
                                                        name="biaya_jan"
                                                        value={form.biaya_jan}
                                                        onChange={handleChange}
                                                        error={errors.biaya_jan}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Februari"
                                                        name="biaya_feb"
                                                        value={form.biaya_feb}
                                                        onChange={handleChange}
                                                        error={errors.biaya_feb}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Maret"
                                                        name="biaya_mar"
                                                        value={form.biaya_mar}
                                                        onChange={handleChange}
                                                        error={errors.biaya_mar}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya April"
                                                        name="biaya_apr"
                                                        value={form.biaya_apr}
                                                        onChange={handleChange}
                                                        error={errors.biaya_apr}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Mei"
                                                        name="biaya_mei"
                                                        value={form.biaya_mei}
                                                        onChange={handleChange}
                                                        error={errors.biaya_mei}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Juni"
                                                        name="biaya_jun"
                                                        value={form.biaya_jun}
                                                        onChange={handleChange}
                                                        error={errors.biaya_jun}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Juli"
                                                        name="biaya_jul"
                                                        value={form.biaya_jul}
                                                        onChange={handleChange}
                                                        error={errors.biaya_jul}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Agustus"
                                                        name="biaya_ags"
                                                        value={form.biaya_ags}
                                                        onChange={handleChange}
                                                        error={errors.biaya_ags}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <NewSubmitButton onClose={handleCloseCreate}/>
                                    </div>
                                </form>
                            </>
                        }
                        {editRkat &&
                            <>
                                <form onSubmit={handleSubmitEdit}>
                                    <div className='row'>
                                        <div className='col-lg-4'>
                                            <div className='col-lg-12'>
                                                <FormInput
                                                    type="text"
                                                    label="Kode"
                                                    name="kode"
                                                    value={form.kode}
                                                    onChange={handleChange}
                                                    error={errors.kode}
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <FormTextarea
                                                    row={6}
                                                    label="Rencana"
                                                    name="rencana"
                                                    value={form.rencana}
                                                    onChange={handleChange}
                                                    error={errors.rencana}
                                                />
                                            </div>
                                            <div className='col-12'>
                                                <FormTextarea
                                                    row={6}
                                                    label="Sub Bidang"
                                                    name="sub_bidang"
                                                    value={form.sub_bidang}
                                                    onChange={handleChange}
                                                    error={errors.sub_bidang}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya September"
                                                        name="biaya_sep"
                                                        value={form.biaya_sep}
                                                        onChange={handleChange}
                                                        error={errors.biaya_sep}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Oktober"
                                                        name="biaya_okt"
                                                        value={form.biaya_okt}
                                                        onChange={handleChange}
                                                        error={errors.biaya_okt}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya November"
                                                        name="biaya_nov"
                                                        value={form.biaya_nov}
                                                        onChange={handleChange}
                                                        error={errors.biaya_nov}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Desember"
                                                        name="biaya_des"
                                                        value={form.biaya_des}
                                                        onChange={handleChange}
                                                        error={errors.biaya_des}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Januari"
                                                        name="biaya_jan"
                                                        value={form.biaya_jan}
                                                        onChange={handleChange}
                                                        error={errors.biaya_jan}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Februari"
                                                        name="biaya_feb"
                                                        value={form.biaya_feb}
                                                        onChange={handleChange}
                                                        error={errors.biaya_feb}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Maret"
                                                        name="biaya_mar"
                                                        value={form.biaya_mar}
                                                        onChange={handleChange}
                                                        error={errors.biaya_mar}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya April"
                                                        name="biaya_apr"
                                                        value={form.biaya_apr}
                                                        onChange={handleChange}
                                                        error={errors.biaya_apr}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Mei"
                                                        name="biaya_mei"
                                                        value={form.biaya_mei}
                                                        onChange={handleChange}
                                                        error={errors.biaya_mei}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Juni"
                                                        name="biaya_jun"
                                                        value={form.biaya_jun}
                                                        onChange={handleChange}
                                                        error={errors.biaya_jun}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Juli"
                                                        name="biaya_jul"
                                                        value={form.biaya_jul}
                                                        onChange={handleChange}
                                                        error={errors.biaya_jul}
                                                    />
                                                </div>
                                                <div className='col-lg-4 mb-3'>
                                                    <FormInputCurrency
                                                        label="Biaya Agustus"
                                                        name="biaya_ags"
                                                        value={form.biaya_ags}
                                                        onChange={handleChange}
                                                        error={errors.biaya_ags}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row my-3'>
                                        <NewSubmitButton onClose={handleCloseEdit}/>
                                    </div>
                                </form>
                            </>
                        }
                    </> 
                    :
                    <>
                        <LayoutAddBtn onClick={handleOpenCreate} add="Tambahkan List RKAT" />
        
                        {!loading ?
                            <div className='row mt-3'>
                                <div className='col-3 pe-0'>
                                    <TableNew>
                                        <Thead>
                                            <Tr className="text-center">
                                                <Th colSpan={24}><i className='fa fa-fw fa-sm fa-bars'/></Th>
                                            </Tr>
                                            <Tr className="text-center">
                                                <Th rowSpan={2} className="text-nowrap align-middle">No</Th>
                                                <Th rowSpan={2} className="text-nowrap align-middle w-100">Kode</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {rkatList.length > 0 ?
                                                rkatList.map((rl, index) => {
                                                    return (
                                                    <Tr className="text-center" key={index}>
                                                        <Td className="text-nowrap">{index + 1}</Td>
                                                        <Td className="text-start text-nowrap">{rl.kode}</Td>
                                                    </Tr>
                                                    )
                                                })
                                            :
                                                <Tr>
                                                    <Td colSpan={2} className="text-center">no data.</Td>
                                                </Tr>
                                            }
                                        </Tbody>
                                    </TableNew>
                                </div>
                                <div className='col-8 px-0'>
                                    <TableNew>
                                        <Thead>
                                            <Tr className="text-center">
                                                <Th rowSpan={2} className="text-nowrap align-middle">Rencana</Th>
                                                <Th rowSpan={2} className="text-nowrap align-middle">Sub Bidang</Th>
                                                <Th colSpan={16}>Biaya tahun {rkat.tahun.split("/")[0]}</Th>
                                                <Th colSpan={8}>Biaya tahun {rkat.tahun.split("/")[1]}</Th>
                                                <Th rowSpan={2} className="align-middle text-nowrap bg-primary-4 text-gray-500">Total<br />Biaya</Th>
                                                <Th rowSpan={2} className="align-middle text-nowrap bg-primary-4 text-gray-500">Total<br />Realisasi</Th>
                                                <Th rowSpan={2} className="align-middle text-nowrap bg-primary-4 text-gray-500">Sisa<br />Biaya</Th>
                                            </Tr>
                                            <Tr className="text-center">
                                                <Th className="text-nowrap bg-primary-2">September</Th>
                                                <Th className="text-nowrap text-gray-500">Realisasi</Th>
                                                <Th className="text-nowrap bg-primary-2">Oktober</Th>
                                                <Th className="text-nowrap text-gray-500">Realisasi</Th>
                                                <Th className="text-nowrap bg-primary-2">November</Th>
                                                <Th className="text-nowrap text-gray-500">Realisasi</Th>
                                                <Th className="text-nowrap bg-primary-2">Desember</Th>
                                                <Th className="text-nowrap text-gray-500">Realisasi</Th>
                                                <Th className="text-nowrap bg-primary-2">Januari</Th>
                                                <Th className="text-nowrap text-gray-500">Realisasi</Th>
                                                <Th className="text-nowrap bg-primary-2">Februari</Th>
                                                <Th className="text-nowrap text-gray-500">Realisasi</Th>
                                                <Th className="text-nowrap bg-primary-2">Maret</Th>
                                                <Th className="text-nowrap text-gray-500">Realisasi</Th>
                                                <Th className="text-nowrap bg-primary-2">April</Th>
                                                <Th className="text-nowrap text-gray-500">Realisasi</Th>
                                                <Th className="text-nowrap bg-primary-2">Mei</Th>
                                                <Th className="text-nowrap text-gray-500">Realisasi</Th>
                                                <Th className="text-nowrap bg-primary-2">Juni</Th>
                                                <Th className="text-nowrap text-gray-500">Realisasi</Th>
                                                <Th className="text-nowrap bg-primary-2">Juli</Th>
                                                <Th className="text-nowrap text-gray-500">Realisasi</Th>
                                                <Th className="text-nowrap bg-primary-2">Agustus</Th>
                                                <Th className="text-nowrap text-gray-500">Realisasi</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {rkatList.length > 0 ?
                                                rkatList.map((rl, index) => {
                                                    return (
                                                    <Tr className="text-center" key={index}>
                                                        <Td className="text-start"><TextTruncate text={rl.rencana}/></Td>
                                                        <Td className="text-start"><TextTruncate text={rl.sub_bidang}/></Td>
                                                        <Td className="text-nowrap text-end bg-silver">{rl.biaya_sep ? `Rp.${getCurrency(rl.biaya_sep)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.realisasi_sep ? `Rp.${getCurrency(rl.realisasi_sep)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end bg-silver">{rl.biaya_okt ? `Rp.${getCurrency(rl.biaya_okt)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.realisasi_okt ? `Rp.${getCurrency(rl.realisasi_okt)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end bg-silver">{rl.biaya_nov ? `Rp.${getCurrency(rl.biaya_nov)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.realisasi_nov ? `Rp.${getCurrency(rl.realisasi_nov)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end bg-silver">{rl.biaya_des ? `Rp.${getCurrency(rl.biaya_des)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.realisasi_des ? `Rp.${getCurrency(rl.realisasi_des)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.total_biaya ? `Rp.${getCurrency(rl.total_biaya)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.total_realisasi ? `Rp.${getCurrency(rl.total_realisasi)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.sisa_biaya ? `Rp.${getCurrency(rl.sisa_biaya)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end bg-silver">{rl.biaya_jan ? `Rp.${getCurrency(rl.biaya_jan)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.realisasi_jan ? `Rp.${getCurrency(rl.realisasi_jan)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end bg-silver">{rl.biaya_feb ? `Rp.${getCurrency(rl.biaya_feb)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.realisasi_feb ? `Rp.${getCurrency(rl.realisasi_feb)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end bg-silver">{rl.biaya_mar ? `Rp.${getCurrency(rl.biaya_mar)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.realisasi_mar ? `Rp.${getCurrency(rl.realisasi_mar)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end bg-silver">{rl.biaya_apr ? `Rp.${getCurrency(rl.biaya_apr)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.realisasi_apr ? `Rp.${getCurrency(rl.realisasi_apr)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end bg-silver">{rl.biaya_mei ? `Rp.${getCurrency(rl.biaya_mei)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.realisasi_mei ? `Rp.${getCurrency(rl.realisasi_mei)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end bg-silver">{rl.biaya_jun ? `Rp.${getCurrency(rl.biaya_jun)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.realisasi_jun ? `Rp.${getCurrency(rl.realisasi_jun)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end bg-silver">{rl.biaya_jul ? `Rp.${getCurrency(rl.biaya_jul)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.realisasi_jul ? `Rp.${getCurrency(rl.realisasi_jul)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end bg-silver">{rl.biaya_ags ? `Rp.${getCurrency(rl.biaya_ags)}` : '-'}</Td>
                                                        <Td className="text-nowrap text-end">{rl.realisasi_ags ? `Rp.${getCurrency(rl.realisasi_ags)}` : '-'}</Td>
                                                    </Tr>
                                                    )
                                                })
                                            :
                                                <Tr>
                                                    <Td colSpan={27}>no data.</Td>
                                                </Tr>
                                            }
                                        </Tbody>
                                    </TableNew>
                                </div>
                                <div className='col-1 ps-0'>
                                    <TableNew copy={false}>
                                        <Thead>
                                            <Tr className="text-center">
                                                <Th className="bg-primary"><i className='fa fa-fw fa-sm fa-bars'/></Th>
                                            </Tr>
                                            <Tr className="text-center">
                                                <Th rowSpan={2} className="text-nowrap align-middle"><i className='fa fa-fw fa-cog'/></Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {rkatList.length > 0 ?
                                                rkatList.map((rl, index) => {
                                                    return (
                                                    <Tr className="text-center" key={index}>
                                                        <Td className="text-nowrap text-center">
                                                            <SmallButton margin="me-1" type="edit" onClick={() => handleOpenEdit(rl)}/>
                                                            <SmallButton type="delete" onClick={() => handleDeleteRkatList(rl.id)}/>
                                                        </Td>
                                                    </Tr>
                                                    )
                                                })
                                            :
                                                <Tr>
                                                    <Td className="text-center">no data.</Td>
                                                </Tr>
                                            }
                                        </Tbody>
                                    </TableNew>
                                </div>
                            </div>
                        : <LoadingBasic/>
                        }
                    </>
                }
            </>
        </LayoutModal>
    </>
  )
}

export const TextTruncate = ({text}) => {
    const [truncate, setTruncate] = useState(true);

    const toggleTruncate = () => {
        setTruncate(!truncate);
    }

    return (
        <>
        <div title={truncate ? 'Klik untuk tampilkan text lebih panjang.' : 'Klik untuk mengecilkan text.'} style={{width: truncate ? '15em' : 'auto'}} onClick={toggleTruncate} className={`${truncate && 'text-truncate'} cursor-pointer`}>
            {text}
        </div>
        </>
    )
}

export default RkatList