import reactDom from "react-dom"

const SectionModalForm = props => {

  const rootModal = document.getElementById('rootModal')

  return reactDom.createPortal(
    <div className={`modal fade${props.openModal ? ' show d-block' : ''}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ backgroundColor: '#00000095' }}>
      <div className="modal-dialog modal-lg modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
            <button onClick={props.onClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {props.children}
            {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Batal</button> */}
          </div>
        </div>
      </div>
    </div>,
    rootModal
  )
}

export default SectionModalForm