import React, { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { deleteWisuda, fetchWisuda } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterCabang,
  FilterSearch,
  FilterTahun,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import { saAlert, saConfirm } from "../../helpers";
import { loginMasterState } from "../../storage/auth";
import { tahunAjaranState } from "../../storage/mhs";
import WisudaImportData from "./ImportData";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const WisudaAkademik = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const defaultTahun = useRecoilValue(tahunAjaranState);
  const loginMaster = useRecoilValue(loginMasterState);

  const [page] = useState("all");
  const [search, setSearch] = useState("");
  const [tahun, setTahun] = useState(defaultTahun);
  const [cabang, setCabang] = useState("");

  const [wisuda, setWisuda] = useState({});
  const [jumlah, setJumlah] = useState({});

  const fetchData = useCallback(async () => {
    if (tahun !== "") {
      try {
        const res = await fetchWisuda(
          page,
          `jumlah=1&search=${search}&tahun=${tahun}&cabang=${cabang}`
        );
        if (res.data && res.data.wisuda) {
          setWisuda(res.data.wisuda);
          setJumlah(res.data.jumlah);
          setIsLoaded(true);
        }
      } catch (err) {
        console.error(err.response);
      }
    } else {
      saAlert("warning", "Tahun tidak boleh kosong !");
    }
  }, [page, search, tahun, cabang]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDelete = (id) => {
    saConfirm("warning", "Yakin hapus data ?").then(async (res) => {
      if (res.isConfirmed) {
        try {
          const res = await deleteWisuda(id);
          if (res.data && res.data.msg === "success") {
            fetchData();
            saAlert("success", "Berhasil hapus data !");
          }
        } catch (err) {
          console.error(err.response);
        }
      }
    });
  };

  return (
    <>
      {loginMaster && <WisudaImportData onFetchData={fetchData} />}
      <div className="row mb-3">
        <div className="col-4">
          <FilterCabang onChange={(res) => setCabang(res)} />
        </div>
        <div className="col-4">
          <FilterTahun value={tahun} onChange={(res) => setTahun(res)} />
        </div>
        <div className="col-4">
          <FilterSearch onChange={(res) => setSearch(res)} />
        </div>
      </div>
      {isLoaded ? (
        <>
        <div className="row">
          <div className="col-lg-4 mb-3">
            <div className="bg-gradient-primary border-bottom-warning text-white rounded-3 p-3 h-100">
              <h6 className="text-gray-400"><i className="fa fa-fw fa-user-graduate text-warning"/> Total Wisudawan</h6>
              <h2 className="fw-semibold mb-0">{jumlah.total} orang</h2>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <div className="bg-gradient-primary border-bottom-warning text-white rounded-3 p-3 h-100">
              <h6 className="text-gray-400"><i className="fa fa-fw fa-check text-success-2"/> Wisudawan hadir</h6>
              <h2 className="fw-semibold mb-0">{parseInt(jumlah.hadir) + parseInt(jumlah.pulang)} orang</h2>
            </div>
          </div>
          <div className="col-lg-4 mb-3">
            <div className="bg-gradient-primary border-bottom-warning text-white rounded-3 p-3 h-100">
              <h6 className="text-gray-400"><i className="fa fa-fw fa-xmark text-danger-2"/> Wisudawan tidak hadir</h6>
              <h2 className="fw-semibold mb-0">{jumlah.belum} orang</h2>
            </div>
          </div>
        </div>

          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>Index</Th>
                <Th>No Wisuda</Th>
                <Th>NIM</Th>
                <Th>Nama</Th>
                <Th className="nowrap">Kehadiran</Th>
                <Th className="nowrap">Kehadiran Pen 1</Th>
                <Th className="nowrap">Kehadiran Pen 2</Th>
                {loginMaster && (
                  <Th>
                    <i className="fa fa-bars" />
                  </Th>
                )}
              </Tr>
            </Thead>
            <Tbody>
              {wisuda.length > 0 &&
                wisuda.map((w, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        className={`text-nowrap text-center ${
                          parseInt(index + 1) !== parseInt(w.nomor)
                            ? "text-danger-2"
                            : "text-success"
                        }`}
                      >
                        {index + 1}
                      </Td>
                      <Td className="text-nowrap text-center">{w.nomor}</Td>
                      <Td className="text-nowrap text-center">
                        {w.mhs && w.mhs.nim}
                      </Td>
                      <Td className="text-nowrap">{w.mhs && w.mhs.nama}</Td>
                      <Td className="text-nowrap text-center">{w.absensi ? <i className="fa fa-fw fa-circle-check text-success"/> : '-'}</Td>
                      <Td className="text-nowrap text-center">{w.absensi_1}</Td>
                      <Td className="text-nowrap text-center">{w.absensi_2}</Td>
                      {loginMaster && (
                        <Td className="text-nowrap text-center">
                          <span
                            className="fa fa-trash-alt text-danger"
                            type="button"
                            onClick={() => handleDelete(w.id)}
                          ></span>
                        </Td>
                      )}
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default WisudaAkademik;
