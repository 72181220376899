import React, { useCallback, useEffect, useRef, useState } from 'react'
import SmallButton from '../../components/forms/SmallButton'
import LayoutModal from '../../components/layouts/Modal';
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../components/layouts/TableNew';
import { fetchBukuSaku, patchBukuSaku } from '../../api';
import { getJenjang, saAlert, saConfirm } from '../../helpers';
import TextOnOffMhs from '../../components/layouts/TextOnOffMhs';
import { FilterAngkatan, FilterSearch } from '../../components/layouts/TopContent';
import { angkatanState } from '../../storage/pages';
import { useRecoilValue } from 'recoil';

function ListMhsBukuSaku(props) {

  const {id} = props;
  const [modal, setModal] = useState(false);
  const [bukuSaku, setBukuSaku] = useState([]);
  const [search, setSearch] = useState("");
  const [angkatan, setAngkatan] = useState("");
  const searchRef = useRef(null);
  const angkatanRef = useRef(null);
  const defaultAngkatan = useRecoilValue(angkatanState);

  const listMahasiswa = useCallback(async (id) => {
    try {
        const res = await fetchBukuSaku('all', `buku_saku_kegiatan_id=${id}&search=${search}&angkatan=${angkatan}`);
        setBukuSaku(res.data && res.data.buku_saku);
    } catch (error) {
        console.log(error.response);
    }
  }, [search, angkatan]);

  const handleOpen = () => {
    setModal(true);
    listMahasiswa(id);
  }
  const handleClose = () => {
    if (searchRef.current) {
      searchRef.current.value = '';  // Reset input search value
    }
    if (angkatanRef.current) {
      angkatanRef.current.value = `${defaultAngkatan}`;  // Reset select value
    }
    setModal(false);
  }

  const handleAproved = async (bukusaku_id, id, form) => {
    saConfirm("warning", "Yakin mengubah status?").then( async (res) => {
      if (res.isConfirmed) {
        try {
          const res = await patchBukuSaku(id, form);
          console.log(res.data);
          listMahasiswa(bukusaku_id);
          saAlert(res.data.sts, res.data.msg);
        } catch (error) {
          console.log(error.response);
        }
      }
    })
  }

  useEffect(() => {
    if (modal) {
      listMahasiswa(id);
    }
  }, [modal, id, listMahasiswa]);

  return (
    <>
        <SmallButton onClick={handleOpen} margin='me-1' type='show' text='List Mahasiswa'/>

        <LayoutModal
          title='List Mahasiswa'
          modal={modal}
          onClose={handleClose}
        >
          <TextOnOffMhs>
            <h6 className='mb-2'>Deskripsi Status :</h6>
            <p className='mb-0'><i className='fa fa-fw fa-lg fa-circle-check text-success-2'/> = Mahasiswa disetujui mengikuti kegiatan.</p>
            <p className='mb-0'><i className='fa fa-fw fa-lg fa-circle-xmark text-danger-2'/> = Mahasiswa ditolak mengikuti kegiatan.</p>
            {/* <p className='mb-0'><i className='fa fa-fw fa-lg fa-circle-minus text-white'/> = Mahasiswa mengajukan mengikuti kegiatan.</p> */}
          </TextOnOffMhs>

          <div className='row justify-content-end mb-3'>
            <div className='col-lg-4'>
              <FilterSearch
                ref={searchRef}
                onChange={(res) => {
                  setSearch(res);
                }}
              />
            </div>
            <div className='col-lg-4'>
              <FilterAngkatan
                ref={angkatanRef}
                onChange={(res) => {
                  setAngkatan(res);
                }}
              />
            </div>
          </div>

          <TableNew>
            <Thead>
              <Tr className='text-center'>
                <Th>No</Th>
                <Th>NIM</Th>
                <Th>Nama</Th>
                <Th>Jenjang</Th>
                <Th>Status</Th>
                <Th><i className='fa fa-fw fa-cog'/></Th>
              </Tr>
            </Thead>
            <Tbody>
              {bukuSaku.length > 0 ?
                bukuSaku.map((bs, index) => {
                  return (
                    <Tr key={index}>
                      <Td className='text-center'>{index + 1}</Td>
                      <Td className='text-center'>{bs.mhs && bs.mhs.nim}</Td>
                      <Td>{bs.mhs && bs.mhs.nama}</Td>
                      <Td className='text-center'>{getJenjang(bs.mhs)}</Td>
                      <Td className='text-center'>
                        {!(bs.status === 2 || bs.status === 9) && '-'}
                        {bs.status === 2 && <i className='fa fa-fw fa-circle-check text-success'/>}
                        {bs.status === 9 && <i className='fa fa-fw fa-circle-xmark text-danger'/>}
                      </Td>
                      <Td className='text-center'>
                        <SmallButton onClick={() => handleAproved(id, bs.id, {status: 2})} margin='px-3 me-1' type='custom' icon='check' buttonColor='success-2' text='Setujui'/>
                        <SmallButton onClick={() => handleAproved(id, bs.id, {status: 9})} margin='px-3' type='custom' icon='xmark' buttonColor='danger-2' text='Tolak'/>
                      </Td>
                    </Tr>
                  )
                })
                :
                <Tr>
                  <Td colSpan={6} className='text-center'>Tidak ada data.</Td>
                </Tr>
              }
            </Tbody>
          </TableNew>

          <button onClick={handleClose} className='btn btn-sm btn-dark border-0'><i className='fa fa-fw fa-sm fa-arrow-left'/> Kembali</button>
        </LayoutModal>
    </>
  )
}

export default ListMhsBukuSaku