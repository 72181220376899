import React, { useEffect, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { saAlert } from "../../helpers";
import { titleState } from "../../storage/pages";
import FooterTopMain from "./FooterTopMain";
import DashboardSidebar from "./Sidebar/Sidebar";
import DashboardTopbar from "./Topbar";
import DashboardTopMain from "./TopMain";
import { Link, useHistory } from "react-router-dom";

const DashboardMain = (props) => {
  const title = useRecoilValue(titleState);

  const [resize, setResize] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (window.innerWidth < 768) {
        saAlert('info', 'Tips', 'Sekedar informasi penggunaan SIM pada komputer jauh lebih baik.');
      }
    }, 2000)
  }, [])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setResize(true);
      } else {
        setResize(false);
      }
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.addEventListener("resize", handleResize)
    };
  }, []);

  // handle banner maintenance

  const bannerRef = useRef(null);
  const [banner, setbanner] = useState(true);

  const closeBanner = () => {
    if (bannerRef.current) {
      bannerRef.current.classList.add("d-none");
    }
  }

  const [stateBanner, setStateBanner] = useState(true);
  const history = useHistory();

  const handleStateBanner = () => {
    setStateBanner(false);
    history.push('/penilaian-kompetensi');
  }

  return (
    <>
    <div id="wrapper" className="bg-light">
      <div className={resize ? 'position-sticky top-0 vh-100 overflow-x-hidden overflow-y-scroll' : ''}>
        <DashboardSidebar />
      </div>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <DashboardTopbar />
          <FooterTopMain />
          {window.location.pathname !== "/dashboard" && <DashboardTopMain />}
          {/* {window.location.pathname === "/dashboard" && <ReportUpdate />} */}
          {stateBanner &&
            <div className="position-fixed d-flex justify-content-center align-items-center" style={{backgroundColor: 'rgba(0, 0, 0, 0.75)', left: '0', right: '0', zIndex: '50', height: '100vh', top: '0'}}>
              <div className="col-sm-8 text-white px-4">
                <h6 className="fs-1 font-semibold">Sudah mengisi penilaian kompetensi?</h6>
                <p className="text-gray-400">Anda dapat mengisi penilaian kompetensi ini dengan menekan tombol dibawah ini, jangan hiraukan bila Anda sudah melakukan penilaian.</p>
                <button onClick={handleStateBanner} type="button" className="btn btn-primary-3">Isi Penilaian sekarang</button>
              </div>
            </div>
          }
        <div className="mx-3">
          <div className="row gy-2 my-0">
            {/* banner for maintenance */}
            {/* <div ref={bannerRef} className="col-lg-12 my-3">
              <div className="row">
                <div className="col-lg-6">
                  <div className="position-relative text-black rounded-3 p-3" style={{backgroundColor: 'rgb(240, 240, 240)', border: '1px solid rgb(220, 220, 220)'}}>
                    <button onClick={closeBanner} type="button" className="position-absolute top-0 end-0 rounded-3 border-0 p-2"><i className="fa fa-fw fa-xmark"/></button>
                    <h6 className="mb-0 fw-semibold text-primary"><i className="fa fa-fw fa-circle-exclamation"/> Perhatian!</h6>
                    <p className="ps-3 text-sm">Dari : Kadek Lanang Lanusa Putera</p>
                    <p className="mb-0 px-3">Tampilan system bermasalah? ada halaman yang belum terdesain? atau tampilan system kurang interaktif dan sulit untuk dipahami? segera informasikan ke bagian IT <span className="text-primary fw-semibold">Lanang (IT Programmer)</span>.</p>
                  </div>
                </div>
              </div>
            </div>  */}
            {/* banner for maintenance */} 
            <div className="col-lg-12">
              <div className="card rounded-4 shadow">
                <div className="card-header border-0 bg-white shadow rounded-4 py-3">
                  <h6 className="m-0 fw-semibold text-primary">{title}</h6>
                  <p className="mb-0 text-secondary text-sm d-none d-sm-block">Gunakan <i className="fa fa-fw fa-keyboard" /> Shift + <i className="fa fa-fw fa-mouse" /> Mouse Scroll Up / Down untuk menggeser tabel kesamping. Pastikan <i className="fa fa-fw fa-mouse-pointer" /> kursor berada pada tabel yang ingin digeser.</p>
                </div>
                <div className="card-body">
                  {props.children}
                </div>
              </div>
            </div>
            </div>

          {/* {props.children} */}
        </div>

      </div>
        <footer className="sticky-footer bg-white">
          <div className="container my-auto">
            <div className="copyright text-center my-auto text-primary">
              <span>
                Copyright © Alfa Prima 2020 - {new Date().getFullYear()}
              </span>
            </div>
          </div>
        </footer>
      </div>
    </div>




{/* 
<div id="wrapper" className="bg-light">
  <div id="content-wrapper" className="d-flex flex-column">
    <div id="content">
      <div className=" mx-3">
        <div className="row gy-2">
          <div className="col-lg-12">
            <ul className="d-flex flex-wrap mobile-justify-content-center align-items-center list-unstyled mb-0">
              <li><button title="Master" className="nav-link btn btn-sm btn-primary-4 rounded-3 py-1 mx-1 mb-2"><i className="fa fa-crown text-warning me-0 me-sm-1" /> <span className="d-none d-sm-inline">Master</span></button></li>
              <li><button title="BAP" className="nav-link btn btn-sm btn-primary-4 rounded-3 py-1 mx-1 mb-2"><i className="fa fa-b text-warning me-0 me-sm-1" /> <span className="d-none d-sm-inline">BAP</span></button></li>
              <li><button title="Absensi" className="nav-link btn btn-sm btn-primary-4 rounded-3 py-1 mx-1 mb-2"><i className="fa fa-calendar-check text-warning me-0 me-sm-1" /> <span className="d-none d-sm-inline">Absensi</span></button></li>
              <li><button title="MoU Perlu Perpanjangan" className="nav-link btn btn-sm btn-primary-4 rounded-3 py-1 mx-1 mb-2"><i className="fa fa-building text-warning me-0 me-sm-1" /> <span className="d-none d-sm-inline">MoU Perlu Perpanjangan</span></button></li>
              <li><button title="Ulang Tahun Perusahaan" className="nav-link btn btn-sm btn-primary-4 rounded-3 py-1 mx-1 mb-2"><i className="fa fa-cake-candles text-warning me-0 me-sm-1" /> <span className="d-none d-sm-inline">Ulang Tahun Perusahaan</span></button></li>
            </ul>
          </div>
          <div className="col-lg-12">
            <div className="card rounded-3 shadow">
              <div className="card-header border-0 bg-white shadow rounded-3 py-3">
                <h6 className="m-0 fw-semibold text-primary">Dashboard</h6>
                <p className="mb-0 text-secondary text-sm d-none d-sm-block">Gunakan <i className="fa fa-fw fa-keyboard" /> Shift + <i className="fa fa-fw fa-mouse" /> Mouse Scroll Up / Down untuk menggeser tabel kesamping. Pastikan <i className="fa fa-fw fa-mouse-pointer" /> kursor berada pada tabel yang ingin digeser.</p>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 mb-3 mb-sm-0">
                    <div className="city-background text-white rounded-3 d-flex flex-column justify-content-center align-items-center h-100 py-5">
                      <h3 className="text-center fw-semibold text-warning">Selamat Datang!</h3>
                      <h6 className="text-center">Kadek Lanang Lanusa Putera, S.kom, M.kom</h6>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div>
                      <p className="fw-semibold fs-6 border-bottom pb-2 mb-2"><i className="fa fa-fw fa-up-long text-success" /> Bonus Manajemen</p>
                      <ul>
                        <li className="mb-2">Dengan mengucap puji syukur kehadapan Tuhan Yang Maha Esa dan rasa bangga kita kepada Alfa Prima..... Bonus Manajemen sudah ditransfer rekening ke masing masing.</li>
                        <li className="mb-2">Pemberian Bonus Menejemen ini merupakan <span className="text-success-2">Kebijakan (Hadiah)</span> oleh Pimpinan Perusahaan. Besaran bonus manajemen diambil dari keuntungan masing-masing cabang dalam <span className="text-success-2">satu tahun</span> akademik.</li>
                        <li className="mb-2">Perhitungan bonus untuk masing-masing orang ditentukan berdasarkan <span className="text-success-2">penilaian kinerja</span>, jabatan dan masa kerja efektif di tahun akademik lalu sejak diangkat <span className="text-success-2">tetap/kontrak</span>.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="card rounded-3 shadow mb-4">
              <div className="card-body">
                <div className="row gx-0">
                  <div className="col-lg-6">
                    <div className="ratio ratio-1x1">
                      <iframe src="img/sertifikasiProdi.pdf" className="ratio ratio-1x1" />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div>
                      <p className="fw-semibold fs-6 border-bottom pb-2 mb-2"><i className="fa fa-fw fa-exclamation-triangle text-warning" /> Note</p>
                      <ul>
                        <li className="mb-2">Sama halnya dengan Gaji, informasi Bonus bersifat <span className="text-success-2">rahasia</span>. Saling menunjukan atau dengan sengaja memperlihatkan informasi gaji, bonus dan tunjangan lain melanggar <span className="text-success-2">Basic Rules</span> Alfa Prima yang dapat dijatuhkan <span className="text-danger">sanksi</span>.</li>
                        <li className="mb-2">Slip Bonus dapat dilihat di SIM</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer className="sticky-footer bg-white">
      <div className="container my-auto">
        <div className="copyright text-center my-auto">
          <span>Copyright © Alfa Prima 2020 - 2023</span>
        </div>
      </div>
    </footer>
  </div>
</div> */}


    </>
  );
};

export default DashboardMain;
