import React from "react";
import { userState } from "../../storage/auth";
import { useRecoilValue } from "recoil";
import { useState } from "react";
import {
  FilterBulan,
  FilterTahun,
  defaultFilterBulan,
  defaultFilterTahun,
} from "../../components/layouts/TopContent";
import { useCallback } from "react";
import { patchAbsensiPegawai, showAbsensiPegawai } from "../../api";
import { useEffect } from "react";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { formatDate, saAlert, saConfirm } from "../../helpers";

const AbsensiPegawaiApproval = () => {
  const user = useRecoilValue(userState);

  const [absensiPegawai, setAbsensiPegawai] = useState([]);

  const [bulan, setBulan] = useState(defaultFilterBulan);
  const [tahun, setTahun] = useState(defaultFilterTahun);

  const fetchData = useCallback(async () => {
    const res = await showAbsensiPegawai(
      user.id,
      `page=all&bulan=${bulan}&tahun=${tahun}&approval=true`
    );
    setAbsensiPegawai(res.data.absensi_pegawai);
  }, [user.id, bulan, tahun]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleUpdate = (id, status, message) => {
    // saConfirm("warning", `Yakin ${message} Ijin ?`).then((res) => {
    //   if (res.isConfirmed) {
    patchAbsensiPegawai(id, { status }).then((res) => {
      console.log(res.data);
      if (res.data && res.data.status === "success") {
        fetchData();
        // saAlert("success", `Berhasil ${message} Ijin.`);
      }
    });
    //   }
    // });
  };

  return (
    <div>
      <div className="row mb-2">
        <div className="col-md">
          <FilterBulan onChange={(res) => setBulan(res)} />
        </div>
        <div className="col-md">
          <FilterTahun onChange={(res) => setTahun(res)} />
        </div>
      </div>

      {absensiPegawai && absensiPegawai.length > 0 ? (
        <LayoutTable>
          <LayoutThead>
            <th>No</th>
            <th>Pegawai</th>
            <th>Atasan</th>
            <th>Jenis</th>
            <th>Tanggal</th>
            <th>Jam Datang</th>
            <th>Jam Pulang</th>
            <th>Keterangan</th>
            <th>Status</th>
          </LayoutThead>
          <tbody>
            {absensiPegawai.map((ap, index) => {
              return (
                <tr key={index}>
                  <td className="text-center align-middle">{1 + index}</td>
                  <td>{ap.pegawai && ap.pegawai.nama}</td>
                  <td>{ap.atasan && ap.atasan.nama}</td>
                  <td>
                    {parseInt(ap.jenis) === 1 && (
                      <span className="text-info">Datang</span>
                    )}
                    {parseInt(ap.jenis) === 2 && (
                      <span className="text-success">Pulang</span>
                    )}
                    {parseInt(ap.jenis) === 3 && (
                      <span className="text-primary">Datang & Pulang</span>
                    )}
                  </td>
                  <td className="text-center">{formatDate(ap.tanggal)}</td>
                  <td className="text-center">{ap.jam_datang || "-"}</td>
                  <td className="text-center">{ap.jam_pulang || "-"}</td>
                  <td>{ap.keterangan}</td>
                  <td className="text-center align-middle text-center">
                    {parseInt(ap.status) === 1 && (
                      <span className="text-success">Approved</span>
                    )}
                    {parseInt(ap.status) === 2 && (
                      <span className="text-danger">Rejected</span>
                    )}
                    {!parseInt(ap.status) && (
                      <div className="btn-group">
                        <button
                          onClick={() => handleUpdate(ap.id, 1, "Approve")}
                          type="button"
                          className="btn btn-sm btn-success"
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => handleUpdate(ap.id, 2, "Reject")}
                          type="button"
                          className="btn btn-sm btn-danger"
                        >
                          Reject
                        </button>
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </LayoutTable>
      ) : (
        "No Data"
      )}
    </div>
  );
};

export default AbsensiPegawaiApproval;
