import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deleteLembur, patchLembur, postLembur, showLembur } from "../../api";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import FormRadio from "../../components/forms/Radio";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterBulan,
  defaultFilterTahun,
  FilterBulan,
  FilterTahun,
  LayoutAddBtn,
} from "../../components/layouts/TopContent";
import LoadingPage from "../../components/pages/Loading";
import NoDataPage from "../../components/pages/NoData";
import {
  formatDate,
  formatDiffDate,
  namaBulan,
  saAlert,
  saConfirm,
} from "../../helpers";
import { loginMasterState, userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import FormLabel from "../../components/forms/Label";
import FormInvalid from "../../components/forms/Invalid";
import FormSelect from "../../components/forms/Select";

const LemburShow = () => {
  const setTitle = useSetRecoilState(titleState);
  const loginMaster = useRecoilValue(loginMasterState);

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState();

  const [page] = useState("all");
  const [bulan, setBulan] = useState(defaultFilterBulan);
  const [tahun, setTahun] = useState(defaultFilterTahun);

  const fetchData = useCallback(async () => {
    try {
      const res = await showLembur(page, `bulan=${bulan}&tahun=${tahun}`);
      if (res.data) {
        setItems(res.data);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err.response);
    }
  }, [page, bulan, tahun]);

  useEffect(() => {
    fetchData();
    setTitle("Lihat Data Lembur");
  }, [fetchData, setTitle]);

  const getLemburTahunan = () => {
    let totalMenit = items.count_lembur_tahunan
      .map((i) => {
        if (i.lama) {
          let split = i.lama.split(" | ");
          let hari = split[0].split(" Hari")[0] * 24 * 60;
          let jam = split[1].split(" Jam")[0] * 60;
          let menit = split[2].split(" Menit")[0];

          return parseInt(hari) + parseInt(jam) + parseInt(menit);
        }
        return false;
      })
      .reduce((prev, curr) => prev + curr);

    return totalMenit;
  };

  const getLemburBulanan = () => {
    let totalMenit = items.lembur
      .map((i) => {
        if (i.lama) {
          let split = i.lama.split(" | ");
          let hari = split[0].split(" Hari")[0] * 24 * 60;
          let jam = split[1].split(" Jam")[0] * 60;
          let menit = split[2].split(" Menit")[0];

          return parseInt(hari) + parseInt(jam) + parseInt(menit);
        }
        return false;
      })
      .reduce((prev, curr) => prev + curr);

    console.log(totalMenit);

    return totalMenit;
  };

  if (isLoaded) {
    return (
      <>
        <CreateLembur onFetchData={fetchData} />
        <div className="row mb-2">
          <div className="col-md">
            <FilterBulan onChange={(res) => setBulan(res)} />
          </div>
          <div className="col-md">
            <FilterTahun onChange={(res) => setTahun(res)} />
          </div>
        </div>

        {/* <h6>
          Total lembur yang diperoleh tahun {tahun} adalah{" "}
          {items.count_lembur_tahunan && items.count_lembur_tahunan.length > 0
            ? getLemburTahunan()
            : 0}{" "}
          Menit
        </h6>
        <h6>
          Total lembur yang diperoleh bulan {namaBulan[bulan - 1]} {tahun}{" "}
          adalah{" "}
          {items.lembur && items.lembur.length > 0 ? getLemburBulanan() : 0}{" "}
          Menit
        </h6> */}

        <table className="table table-sm table-borderless mt-3">
          <tbody>
            <tr>
              <td className="py-0 fs-6">Total lembur yang diperoleh tahun {tahun}</td>
              <td className="py-0 fs-6">:</td>
              <td className="py-0 fs-6 w-50"><i className="fa fa-fw fa-sm fa-clock me-1"/> {items.count_lembur_tahunan && items.count_lembur_tahunan.length > 0 ? getLemburTahunan() : 0} Menit</td>
            </tr>
            <tr>
              <td className="py-0 fs-6">Total lembur yang diperoleh bulan {namaBulan[bulan - 1]} {tahun}</td>
              <td className="py-0 fs-6">:</td>
              <td className="py-0 fs-6 w-50"><i className="fa fa-fw fa-sm fa-clock me-1"/> {items.lembur && items.lembur.length > 0 ? getLemburBulanan() : 0} Menit</td>
            </tr>
          </tbody>
        </table>

        {items.lembur && items.lembur.length >= 1 ? (
          <LayoutTable>
            <LayoutThead>
              <th className="bg-primary text-white border-bottom border-silver fw-normal text-nowrap">No</th>
              <th className="bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Jenis</th>
              <th className="bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Dari</th>
              <th className="bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Sampai</th>
              <th className="bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Lama</th>
              <th className="bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Pekerjaan</th>
              <th className="bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Keterangan</th>
              <th className="bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Type Lembur</th>
              <th className="bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Status</th>
              <th className="bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Oleh</th>
              <th className="bg-primary text-white border-bottom border-silver fw-normal text-nowrap"><i className="fa fa-fw fa-cog fa-sm"/></th>
            </LayoutThead>
            <tbody>
              {items.lembur.map((i, index) => {
                return (
                  <tr key={index}>
                    <td className="border-silver text-center align-middle">{1 + index}</td>
                    <td className="border-silver text-nowrap text-center align-middle">
                      {parseInt(i.status_keterangan) === 1 && (
                        <span className="text-info">Hari Normal</span>
                      )}
                      {parseInt(i.status_keterangan) === 2 && (
                        <span className="text-primary">Hari Libur</span>
                      )}
                      {parseInt(i.status_keterangan) === 3 && (
                        <span className="text-success">Pagi Hari</span>
                      )}
                    </td>
                    <td className="border-silver text-nowrap text-center align-middle">
                      {formatDate(i.dari)}
                    </td>
                    <td className="border-silver text-nowrap text-center align-middle">
                      {formatDate(i.sampai)}
                    </td>
                    <td className="border-silver text-nowrap text-center align-middle">
                      {formatDiffDate(i.lama)}
                    </td>
                    <td className="border-silver align-middle">{i.pekerjaan}</td>
                    <td className="border-silver align-middle">{i.keterangan}</td>
                    <td className="border-silver text-center align-middle">
                      {parseInt(i.type_lembur) === 1 && "Lembur Jabatan"}
                      {parseInt(i.type_lembur) === 2 &&
                        "Lembur Kegiatan Mahasiswa"}
                      {parseInt(i.type_lembur) === 3 && "Lembur Kepanitiaan"}
                      {parseInt(i.type_lembur) === 4 &&
                        "Lembur Menghadiri Rapat atau Undangan Kegiatan"}
                    </td>
                    <td className="border-silver text-center align-middle">
                      {i.status === null && (
                        <span className="text-warning">Waiting</span>
                      )}
                      {parseInt(i.status) === 1 && (
                        <span className="text-success">Approved</span>
                      )}
                      {parseInt(i.status) === 2 && (
                        <span className="text-danger">Rejected</span>
                      )}
                    </td>
                    <td className="border-silver text-center align-middle">
                      {i.atasan && i.atasan.nama}
                    </td>
                    <td className="border-silver text-center align-middle">
                      {i.status === null ? (
                        <div className="d-flex">
                          <EditLembur lembur={i} onFetchData={fetchData} />
                          <DeleteLembur id={i.id} onFetchData={fetchData} />
                        </div>
                      ) : (
                        <>
                          {loginMaster ? (
                            <div className="d-flex">
                              <EditLembur lembur={i} onFetchData={fetchData} />
                              <DeleteLembur id={i.id} onFetchData={fetchData} />
                            </div>
                          ) : (
                            "Done"
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </LayoutTable>
        ) : (
          <NoDataPage />
        )}
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

const DeleteLembur = (props) => {
  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus lembur ?").then((res) => {
      if (res.isConfirmed) {
        deleteLembur(props.id).then((res) => {
          if (res.data === "success") {
            props.onFetchData();
            saAlert("success", "Berhasil hapus data lembur !");
          }
        });
      }
    });
  };
  return (
    <button
      onClick={handleDelete}
      className="btn btn-sm btn-danger-2 py-0 px-1 text-sm"
    >
      <i className="fa fa-fw fa-sm fa-trash-alt"></i>
    </button>
  );
};

const CreateLembur = (props) => {
  const user = useRecoilValue(userState);
  const tagForm = useRef();
  const [modal, setModal] = useState(false);
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const handleClose = () => {
    setForm({});
    setFormErrors({});
    tagForm.current && tagForm.current.reset();
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postLembur(form)
      .then((res) => {
        if (res.data === "success") {
          props.onFetchData();
          setForm({});
          setFormErrors({});
          tagForm.current.reset();
          setModal(false);
          saAlert("success", "Berhasil tambahkan lembur !");
        }
      })
      .catch((err) => {
        let res = err.response;
        if (res) {
          if (res.status === 422) {
            setFormErrors(res.data.errors);
          }
        }
      });
  };

  return (
    <>
      <LayoutAddBtn onClick={() => setModal(true)} add="Buat Lembur Baru" />
      <LayoutModal size="md" title="Buat Lembur Baru" modal={modal} onClose={handleClose}>
        <form onSubmit={handleSubmit} ref={tagForm}>
          <FormInput label="NIP" value={user.nip} readOnly={true} />
          <FormInput label="Nama" value={user.nama} readOnly={true} />
          <FormSelect
            label="Type Lembur"
            name="type_lembur"
            onChange={(e) => {
              console.log(e.target.value);
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={formErrors.type_lembur}
          >
            <option value="">Pilih</option>
            <option value="1">
              Lembur Jabatan (Menjalankan Fungsi Job Desc)
            </option>
            <option value="2">
              Lembur Kegiatan Mahasiswa (Menjalankan fungsi Pembina Ormawa,
              Ka Prodi dan Pendampingan Kegiatan Mahasiswa liannya)
            </option>
            <option value="3">
              Lembur Kepanitiaan (Melaksanakan Kegiatan Kepanitiaan diluar
              Job Desc Utama Jabatan)
            </option>
            <option value="4">
              Lembur Menghadiri Rapat atau Undangan Kegiatan
            </option>
          </FormSelect>

          <p className="bg-silver p-2 rounded-3 fw-semibold text-dark text-center">
            <i className="fa fa-fw fa-sm fa-circle-exclamation text-warning-2"/> Lembur pagi di hari libur cukup gunakan hari libur.
          </p>

          <div className="text-center text-dark">
            <FormRadio
              inline={1}
              error={formErrors.status_keterangan}
              name="status_keterangan"
              value="1"
              id="status_keterangan1"
              onChange={(e) =>
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                })
              }
              label="Hari Normal"
            />
            <FormRadio
              inline={1}
              error={formErrors.status_keterangan}
              name="status_keterangan"
              value="2"
              id="status_keterangan2"
              onChange={(e) =>
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                })
              }
              label="Hari Libur"
            />
            <FormRadio
              inline={1}
              error={formErrors.status_keterangan}
              name="status_keterangan"
              value="3"
              id="status_keterangan3"
              onChange={(e) =>
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                })
              }
              label="Pagi Hari"
            />
            <FormInvalid>{formErrors.status_keterangan}</FormInvalid>
          </div>
          <FormInput
            label="Dari"
            name="dari"
            type="datetime-local"
            onChange={(e) => {
              console.log(e.target.value);
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={formErrors.dari}
          />
          <FormInput
            label="Sampai"
            name="sampai"
            type="datetime-local"
            onChange={(e) => {
              console.log(e.target.value);
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={formErrors.sampai}
          />
          <FormTextarea
            label="Pekerjaan"
            name="pekerjaan"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={formErrors.pekerjaan}
          />
          <FormTextarea
            label="Keterangan"
            name="keterangan"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={formErrors.keterangan}
          />

          <div className="my-4">
            <p className="mb-0 text-center fw-semibold text-primary">Approval lembur oleh :</p>
            <p className="mb-0 text-center fw-semibold text-dark">
              {(() => {
                if (user.atasan1) {
                  return user.atasan1.nama;
                } else {
                  if (user.atasan2) {
                    return user.atasan2.nama;
                  }
                }
              })()}
            </p>
          </div>

          <div className="btn-group w-100">
            <button type="submit" className="btn btn-sm btn-success-2">
              Ajukan
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-sm btn-dark border-0"
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

const EditLembur = (props) => {
  const { lembur } = props;
  const user = useRecoilValue(userState);
  const tagForm = useRef();
  const [modal, setModal] = useState(false);
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const handleOpen = () => {
    setForm(lembur);
    setModal(true);
  };

  const handleClose = () => {
    setForm({});
    setFormErrors({});
    tagForm.current.reset();
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await patchLembur(form.id, form, "update_lembur=1");
      if (res.data === "success") {
        props.onFetchData();
        handleClose();
        saAlert("success", "Berhasil tambahkan lembur !");
      }
    } catch (err) {
      let res = err.response;
      if (res) {
        if (res.status === 422) {
          setFormErrors(res.data.errors);
        }
      }
    }
  };

  return (
    <>
      <button
        className="btn btn-sm btn-primary-3 py-0 px-1 text-sm me-1"
        onClick={handleOpen}
      >
        <i className="fa fa-fw fa-sm fa-pen"></i>
      </button>
      <LayoutModal size="md" title="Ubah Data Lembur" modal={modal} onClose={handleClose}>
        <form onSubmit={handleSubmit} ref={tagForm}>
          <FormInput label="NIP" value={user.nip} readOnly={true} />
          <FormInput label="Nama" value={user.nama} readOnly={true} />
            <FormSelect
              label="Type Lembur"
              name="type_lembur"
              value={form.type_lembur}
              onChange={(e) => {
                console.log(e.target.value);
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                });
              }}
              error={formErrors.type_lembur}
            >
              <option value="">Pilih</option>
              <option value="1">
                Lembur Jabatan (Menjalankan Fungsi Job Desc)
              </option>
              <option value="2">
                Lembur Kegiatan Mahasiswa (Menjalankan fungsi Pembina Ormawa,
                Ka Prodi dan Pendampingan Kegiatan Mahasiswa liannya)
              </option>
              <option value="3">
                Lembur Kepanitiaan (Melaksanakan Kegiatan Kepanitiaan diluar
                Job Desc Utama Jabatan)
              </option>
              <option value="4">
                Lembur Menghadiri Rapat atau Undangan Kegiatan
              </option>
            </FormSelect>
            
            <p className="bg-silver p-2 rounded-3 fw-semibold text-dark text-center">
              <i className="fa fa-fw fa-sm fa-circle-exclamation text-warning-2"/> Lembur pagi di hari libur cukup gunakan hari libur.
            </p>

            <div className="text-center text-dark">
              <FormRadio
                inline={1}
                error={formErrors.status_keterangan}
                name="status_keterangan"
                value="1"
                id="status_keterangan1"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                label="Hari Normal"
                checked={parseInt(form.status_keterangan) === 1 ? true : false}
              />
              <FormRadio
                inline={1}
                error={formErrors.status_keterangan}
                name="status_keterangan"
                value="2"
                id="status_keterangan2"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                label="Hari Libur"
                checked={parseInt(form.status_keterangan) === 2 ? true : false}
              />
              <FormRadio
                inline={1}
                error={formErrors.status_keterangan}
                name="status_keterangan"
                value="3"
                id="status_keterangan3"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                label="Pagi Hari"
                checked={parseInt(form.status_keterangan) === 3 ? true : false}
              />
            </div>
            <FormInput
              ggwp={(val) => ""}
              label="Dari"
              name="dari"
              type="datetime-local"
              value={form.dari}
              onChange={(e) =>
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                })
              }
              error={formErrors.dari}
            />
            <FormInput
              label="Sampai"
              name="sampai"
              type="datetime-local"
              value={form.sampai}
              onChange={(e) =>
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                })
              }
              error={formErrors.sampai}
            />
          <FormTextarea
            label="Pekerjaan"
            name="pekerjaan"
            value={form.pekerjaan}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={formErrors.pekerjaan}
          />
          <FormTextarea
            label="Keterangan"
            name="keterangan"
            value={form.keterangan}
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={formErrors.keterangan}
          />
          
          <div className="my-4">
            <p className="mb-0 text-center fw-semibold text-primary">Approval lembur oleh :</p>
            <p className="mb-0 text-center fw-semibold text-dark">
              {(() => {
                if (user.atasan1) {
                  return user.atasan1.nama;
                } else {
                  if (user.atasan2) {
                    return user.atasan2.nama;
                  }
                }
              })()}
            </p>
          </div>

          <div className="btn-group w-100">
            <button type="submit" className="btn btn-sm btn-success-2">
              Simpan perubahaan
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-sm btn-dark border-0"
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default LemburShow;
