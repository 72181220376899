import React from 'react'
import { getCurrency } from '../../helpers';
import FormInput from './Input';

function FormInputCurrency(props) {
    const {className, label, name, value, onChange, error} = props;
  return (
    <div className={`${className} bg-gradient-primary text-white`} style={{borderRadius: '0.9em',padding: '0.9em'}}>
      <div className='w-100'>
        <h6 className="mb-0 text-sm text-warning fw-semibold">{label}</h6>
        <h6 className="text-white">Rp. {value ? getCurrency(value,0) : 0}</h6>
        <FormInput
            className="bg-black text-white border-0"
            placeholder="Ketik disini..."
            marginBottom="mb-0"
            name={name}
            type="text"
            value={value}
            onChange={onChange}
            error={error}
        />
      </div>
    </div>
  )
}

export default FormInputCurrency