import React, { useRef, useState } from "react";
import { generateNewNim } from "../../api";
import FormSelect from "../../components/forms/Select";
import LayoutModal from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";

const ChangeNim = ({ user, mhs, jurusan, onFetchData }) => {
  const [modal, setModal] = useState(false);
  const tagForm = useRef(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleOpen = () => {
    setForm(mhs);
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await generateNewNim(form.id, form);
      if (res.data) {
        if (res.data === "success") {
          onFetchData();
          handleClose();
          saAlert("success", "Berhasil ubah NIM Mahasiswa !");
        } else if (res.data === "fail") {
          onFetchData();
          handleClose();
          saAlert("warning", "Gagal ubah NIM Mahasiswa !");
        } else if (res.data === "nothing") {
          onFetchData();
          handleClose();
          saAlert("warning", "Tidak ada perubahan NIM Mahasiswa !");
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <button type="button" className="btn btn-sm btn-primary-3 text-sm py-0 px-1 me-1" onClick={handleOpen}>
        <i className="fa fa-fw fa-sm fa-repeat"/>
      </button>
      <LayoutModal
        modal={modal}
        title={`Ganti NIM ${form.nama}`}
        onClose={handleClose}
      >
        <p className="bg-primary text-white py-1 px-2 rounded-3 text-black">
          <i className="fa fa-fw fa-circle-exclamation text-warning fa-shake"/> <span className="fs-6 fw-semibold text-warning">Perhatian!</span> Hati-hati pada saat melakukan perubahan jenjang dan jurusan karena ini
          akan berpengaruh terhadap NIM dari mahasiswa yang diubah.
        </p>
        <form className="row" ref={tagForm} onSubmit={handleSubmit}>
          {user.cabang === "pusat" && (
            <div className="col-lg-6">
              <FormSelect
                label="Cabang"
                name="cabang"
                value={form.cabang}
                onChange={handleChange}
                error={errors.cabang}
              >
                <option value="">Pilih</option>
                <option value="denpasar">Denpasar</option>
                <option value="singaraja">Singaraja</option>
                <option value="karangasem">Karangasem</option>
              </FormSelect>
            </div>
          )}
          <div className="col-lg-6">
            <FormSelect
              label="Jenjang"
              name="jenjang"
              value={form.jenjang}
              onChange={handleChange}
              error={errors.jenjang}
            >
              <option value="">Pilih</option>
              <option value="D1">D1</option>
              <option value="D2">D2</option>
            </FormSelect>
          </div>
          <div className="col-lg-6">
            <FormSelect
              label="Jurusan"
              name="jurusan_id"
              value={form.jurusan_id}
              onChange={handleChange}
              error={errors.jurusan_id}
            >
              <option value="">Pilih</option>
              {jurusan.length > 0 &&
                jurusan.map((j, index) => {
                  return (
                    <React.Fragment key={index}>
                      <option value={j.id}>{j.nama}</option>
                    </React.Fragment>
                  );
                })}
            </FormSelect>
          </div>
          <div className="col-12">
            <div className="btn-group">
              <button className="btn btn-sm btn-success-2">Simpan</button>
              <button
                className="btn btn-sm btn-dark border-0"
                onClick={handleClose}
                type="button"
              >
                <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
              </button>
            </div>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default ChangeNim;
