import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchMhs } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  defaultFilterAngkatan,
  FilterAngkatan,
  FilterCabang,
  FilterJenjang,
  FilterJurusan,
  FilterPagination,
  FilterSearch,
} from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { angkatanState, titleState } from "../../storage/pages";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import { getJenjang } from "../../helpers";

const MhsPjm = () => {
  const defAngkatan = useRecoilValue(angkatanState);
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState({});
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(10);
  const [search, setSearch] = useState("");
  const [angkatan, setAngkatan] = useState(defAngkatan);
  const [jenjang, setJenjang] = useState("");
  const [jurusan, setJurusan] = useState("");
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchMhs(
        page,
        `pagination=${pagination}&search=${search}&angkatan=${angkatan}&jenjang=${jenjang}&jurusan=${jurusan}&cabang=${cabang}`
      );
      if (res.data && res.data.mhs) {
        setItems(res.data.mhs);
        setIsLoaded(true);
      }
    } catch (error) {}
  }, [page, pagination, search, angkatan, jenjang, jurusan, cabang]);

  useEffect(() => {
    setTitle("Data Mahasiswa");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        {/* Filter 1 */}
        <div className="row mb-3">
          <div className="col-md">
            <FilterJurusan
              onChange={(res) => {
                setJurusan(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md">
            <FilterJenjang
              onChange={(res) => {
                setJenjang(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {/* Filter 2 */}
        <div className="row mb-3">
          <div className="col-md">
            <FilterCabang
              onChange={(res) => {
                setCabang(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md">
            <FilterAngkatan
              value={angkatan}
              onChange={(res) => {
                setAngkatan(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-md">
            <FilterPagination
              onChange={(res) => {
                setPagination(res);
                setPage(1);
              }}
            />
          </div>
        </div>
        {items.data.length >= 1 ? (
          <div className="row">
            <div className="col-4 pe-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center'>
                    <Th className="text-nowrap">No</Th>
                    <Th className="text-nowrap">NIM</Th>
                    <Th className="text-nowrap">Nama</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td className="text-center">{items.from + index}</Td>
                        <Td className="text-nowrap">{i.nim}</Td>
                        <Td className="text-nowrap">{i.nama}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-8 ps-0">
              <TableNew>
                <Thead>
                  <Tr className='text-center'>
                    <Th className="text-nowrap">Cabang</Th>
                    <Th className="text-nowrap">Tempat Lahir</Th>
                    <Th className="text-nowrap">Tanggal Lahir</Th>
                    <Th className="text-nowrap">Jenis Kelamin</Th>
                    <Th className="text-nowrap">Agama</Th>
                    <Th className="text-nowrap">Angkatan</Th>
                    <Th className="text-nowrap">Jenjang</Th>
                    <Th className="text-nowrap">Jurusan</Th>
                    <Th className="text-nowrap">Pilihan Waktu Belajar</Th>
                    <Th className="text-nowrap">Semester</Th>
                    <Th className="text-nowrap">Nomor KTP</Th>
                    <Th className="text-nowrap">Nama Ayah</Th>
                    <Th className="text-nowrap">Nama Ibu</Th>
                    <Th className="text-nowrap">Alamat Orang Tua</Th>
                    <Th className="text-nowrap">No HP Orang Tua</Th>
                    <Th className="text-nowrap">No HP</Th>
                    <Th className="text-nowrap">Email</Th>
                    <Th className="text-nowrap">Asal Sekolah</Th>
                    <Th className="text-nowrap">Tahun Tamat</Th>
                    <Th className="text-nowrap">Jurusan SMA</Th>
                    <Th className="text-nowrap">Informasi Dari</Th>
                    <Th className="text-nowrap">Pembimbing Kuliah</Th>
                    <Th className="text-nowrap">Tanggal Pendaftaran</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {items.data.map((i, index) => {
                    return (
                      <Tr key={index}>
                        <Td
                          style={{ textTransform: "capitalize" }}
                          className="text-nowrap"
                        >
                          {i.cabang}
                        </Td>
                        <Td className="text-nowrap">{i.tempat}</Td>
                        <Td className="text-nowrap">{i.tanggal}</Td>
                        <Td className="text-nowrap">{i.jenis}</Td>
                        <Td className="text-nowrap">{i.agama}</Td>
                        <Td className="text-nowrap">{i.angkatan}</Td>
                        <Td className="text-nowrap">{getJenjang(i)}</Td>
                        <Td className="text-nowrap">{i.jurusan_ && i.jurusan_.nama}</Td>
                        <Td className="text-nowrap">{i.waktu_belajar}</Td>
                        <Td className="text-nowrap">{i.semester}</Td>
                        <Td className="text-nowrap text-danger">No Data</Td>
                        <Td className="text-nowrap">{i.nama_ayah}</Td>
                        <Td className="text-nowrap">{i.nama_ibu}</Td>
                        <Td className="text-nowrap">{i.alamat_ortu}</Td>
                        <Td className="text-nowrap">{i.no_hp_ortu}</Td>
                        <Td className="text-nowrap">{i.no_hp}</Td>
                        <Td className="text-nowrap">{i.email}</Td>
                        <Td className="text-nowrap">{i.asal_sekolah}</Td>
                        <Td className="text-nowrap">{i.tahun_tamat}</Td>
                        <Td className="text-nowrap">{i.jurusan_sma}</Td>
                        <Td className="text-nowrap">{i.informasi}</Td>
                        <Td className="text-nowrap text-danger">No Data</Td>
                        <Td className="text-nowrap">{i.tanggal_bergabung}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </TableNew>
            </div>
          </div>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          from={items.from}
          to={items.to}
          total={items.total}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default MhsPjm;
