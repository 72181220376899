import React, { useState, useRef } from "react";
import LayoutModal from "../../components/layouts/Modal";
import FormInput from "../../components/forms/Input";
import { postPegawai } from "../../api";
import { saAlert } from "../../helpers";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import FormSelect from "../../components/forms/Select";
import { useRecoilValue } from "recoil";
import { userState } from "../../storage/auth";

const PegawaiCreate = (props) => {

  const {dataCabang, dataJabatan, onFetchData} = props;

  const user = useRecoilValue(userState);

  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
    setForm({});
    setErrors({});
  };

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };
  const handleChangeFile = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.files[0] };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    // text
    form.cabang_id && formData.append("cabang_id", form.cabang_id);
    form.jabatan_id && formData.append("jabatan_id", form.jabatan_id);
    form.cabang && formData.append("cabang", form.cabang);
    form.grade && formData.append("grade", form.grade);
    form.group && formData.append("group", form.group);
    form.atasan_1 && formData.append("atasan_1", form.atasan_1);
    form.atasan_2 && formData.append("atasan_2", form.atasan_2);
    form.nip && formData.append("nip", form.nip);
    form.nama && formData.append("nama", form.nama);
    form.nama_panggilan && formData.append("nama_panggilan", form.nama_panggilan);
    form.tempat_lahir && formData.append("tempat_lahir", form.tempat_lahir);
    form.tanggal_lahir && formData.append("tanggal_lahir", form.tanggal_lahir);
    form.email && formData.append("email", form.email);
    form.no_hp && formData.append("no_hp", form.no_hp);
    form.agama && formData.append("agama", form.agama);
    form.alamat && formData.append("alamat", form.alamat);
    form.bergabung && formData.append("bergabung", form.bergabung);
    // image
    form.ktp && formData.append("ktp", form.ktp);
    form.npwp && formData.append("npwp", form.npwp);
    form.ttd && formData.append("ttd", form.ttd);
    setErrors({});

    try {
      const res = await postPegawai(formData);
      if (res.data && res.data.status) {
        onFetchData();
        handleClose();
        saAlert(res.data.status, res.data.msg);
      }
    } catch (err) {
      console.log(err.response);
      err.response && err.response.status && err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <>
      <button onClick={handleOpen} type="button" className='w-100 mb-3 btn btn-sm btn-warning-2 fw-medium text-black rounded-3 px-4 me-1'><i className='fa fa-fw fa-pen'/> Tambah Pegawai</button>
      
      <LayoutModal
        title="Tambah Data Pegawai"
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
          <div className="row">
            {user.jabatan === "IT Programmer" &&
            <>
              <h4 className="text-primary fw-semibold">Custom</h4>
              <div className="col-md-4">
                <FormInput
                  label="Cabang"
                  name="cabang"
                  onChange={handleChange}
                  error={errors.cabang}
                />
              </div>
              <div className="col-md-4">
                <FormSelect
                  label="Grade"
                  name="grade_id"
                  onChange={handleChange}
                  error={errors.grade_id}
                >
                  <option value="">Pilih</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </FormSelect>
              </div>
              <div className="col-md-4">
                <FormSelect
                  label="Group"
                  name="group_id"
                  onChange={handleChange}
                  error={errors.group_id}
                >
                  <option value="">Pilih</option>
                  <option value="Akademik">Akademik</option>
                  <option value="Humas">Humas</option>
                </FormSelect>
              </div>
              <div className="col-md-6">
                <FormInput
                  label="Atasan I"
                  name="atasan_1"
                  onChange={handleChange}
                  error={errors.atasan_1}
                />
              </div>
              <div className="col-md-6">
                <FormInput
                  label="Atasan II"
                  name="atasan_2"
                  onChange={handleChange}
                  error={errors.atasan_2}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  label="KTP"
                  name="ktp"
                  onChange={handleChange}
                  error={errors.ktp}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  label="NPWP"
                  name="npwp"
                  onChange={handleChange}
                  error={errors.npwp}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  label="TTD"
                  name="ttd"
                  onChange={handleChange}
                  error={errors.ttd}
                />
              </div>
            </>
            }

            {user.jabatan === "IT Programmer" && <h4 className="text-primary fw-semibold"> Original</h4>}
            <div className="col-md-2">
              <FormSelect
                label="Cabang"
                name="cabang_id"
                onChange={handleChange}
                error={errors.cabang_id}
              >
                <option value="">Pilih</option>
                {dataCabang.length > 0 &&
                  dataCabang.map((dc, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={dc.id}>{dc.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col-md-5">
              <FormSelect
                label="Jabatan"
                name="jabatan_id"
                onChange={handleChange}
                error={errors.jabatan_id}
              >
                <option value="">Pilih</option>
                {dataJabatan.length > 0 &&
                  dataJabatan.map((dj, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={dj.id}>{dj.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col-md-2">
              <FormInput
                label="NIP"
                name="nip"
                onChange={handleChange}
                error={errors.nip}
              />
            </div>
            <div className="col-md-3">
              <FormInput
                label="Nama Panggilan"
                name="nama_panggilan"
                onChange={handleChange}
                error={errors.nama_panggilan}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Nama Lengkap"
                name="nama"
                onChange={handleChange}
                error={errors.nama}
              />
            </div>
            <div className="col-md-3">
              <FormInput
                label="Tempat Lahir"
                name="tempat_lahir"
                onChange={handleChange}
                error={errors.tempat_lahir}
              />
            </div>
            <div className="col-md-3">
              <FormInput
              type="date"
                label="Tanggal Lahir"
                name="tanggal_lahir"
                onChange={handleChange}
                error={errors.tanggal_lahir}
              />
            </div>
            <div className="col-md-6">
              <FormInput
                label="Email"
                name="email"
                onChange={handleChange}
                error={errors.email}
              />
            </div>
            <div className="col-md-3">
              <FormInput
                label="No HP"
                name="no_hp"
                onChange={handleChange}
                error={errors.no_hp}
              />
            </div>
            <div className="col-md-3">
              <FormInput
                label="Agama"
                name="agama"
                onChange={handleChange}
                error={errors.agama}
              />
            </div>
            <div className="col-md-10">
              <FormInput
                label="Alamat"
                name="alamat"
                onChange={handleChange}
                error={errors.alamat}
              />
            </div>
            <div className="col-md-2">
              <FormInput
                type="date"
                label="Bergabung pada"
                name="bergabung"
                onChange={handleChange}
                error={errors.bergabung}
              />
            </div>
            <div className="col-md-4">
              <FormInput
                type="file"
                label="KTP"
                name="ktp"
                onChange={handleChangeFile}
                error={errors.ktp}
              />
            </div>
            <div className="col-md-4">
              <FormInput
                type="file"
                label="NPWP"
                name="npwp"
                onChange={handleChangeFile}
                error={errors.npwp}
              />
            </div>
            <div className="col-md-4">
              <FormInput
                type="file"
                label="TTD"
                name="ttd"
                onChange={handleChangeFile}
                error={errors.ttd}
              />
            </div>
          </div>
          <div className="btn-group">
            <button type="submit" className="btn btn-success-2 btn-sm">
              Simpan
            </button>
            <button
              type="button"
              className="btn btn-dark border-0 btn-sm"
              onClick={handleClose}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"></i> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default PegawaiCreate;
