import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { fetchMhs, patchMhs_pojt, showPojt } from "../../api";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { FilterSearch } from "../../components/layouts/TopContent";
import { getJurusanShort, saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import SmallButton from "../../components/forms/SmallButton";

const PojtAddMahasiswa = (props) => {
  const user = useRecoilValue(userState);

  const { pojt_id, tahun, perusahaan, onFetchData } = props;

  const [modal, setModal] = useState(false);

  const [search, setSearch] = useState("");

  const [pojt, setPojt] = useState({});

  const fetchDataPojt = async () => {
    const res = await showPojt(pojt_id);
    setPojt(res.data.pojt);
  };

  const [mhs, setMhs] = useState([]);

  useEffect(() => {
    if (search !== "") {
      const fetchDataMhs = async () => {
        const res = await fetchMhs(
          "all",
          `with_mhs_pojt=true&search=${search}&has_mhs_pojt_active=yes&tahun=${tahun}&limit=10`
        );
        setMhs(res.data.mhs);
      };

      fetchDataMhs();
    }
  }, [search, tahun]);

  const handleUpdate = async (mhs_pojt_id, pojt_id) => {
    try {
      const res = await patchMhs_pojt(mhs_pojt_id, { pojt_id: pojt_id });
      if (res.data === "success") {
        fetchDataPojt();
        setMhs((prevState) =>
          prevState.map((m) => {
            m.mhs_pojt
              .filter((mp) => parseInt(mp.id) === parseInt(mhs_pojt_id))
              .map((mp) => (mp.pojt_id = pojt_id));
            return m;
          })
        );
        saAlert("success", "Berhasil update data.");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleDelete = (mhs_pojt_id) => {
    saConfirm("warning", "Yakin hapus data ?").then(async (res) => {
      if (res.isConfirmed) {
        try {
          const res = await patchMhs_pojt(mhs_pojt_id, { pojt_id: "deleted" });
          if (res.data === "success") {
            fetchDataPojt();
            setMhs((prevState) =>
              prevState.map((m) => {
                m.mhs_pojt
                  .filter((mp) => parseInt(mp.id) === parseInt(mhs_pojt_id))
                  .map((mp) => (mp.pojt_id = null));
                return m;
              })
            );
            saAlert("success", "Berhasil hapus data.");
          }
        } catch (err) {
          console.error(err);
        }
      }
    });
  };

  const handleOpen = () => {
    if (
      user.cabang === "pusat" ||
      perusahaan.cabang.toLowerCase() === user.cabang.toLowerCase()
    ) {
      fetchDataPojt();
      setModal(true);
    } else {
      saAlert("warning", "Tidak dapat mengubah data cabang lain.");
    }
  };

  const handleClose = () => {
    onFetchData({ triggerIsLoaded: false });
    setSearch("");
    setMhs([]);
    setPojt({});
    setModal(false);
  };

  return (
    <>
      <button onClick={handleOpen} className="btn btn-sm btn-primary-2 py-0 px-1 w-100">
        Tambah / Hapus Mahasiswa
      </button>

      {modal && (
        <LayoutModal
          title={`Mhs ${pojt.perusahaan && pojt.perusahaan.nama}`}
          modal={true}
          onClose={handleClose}
        >
          <div className="row">
            <div className="col-6">
              <h5 className="text-black fw-semibold mb-3">Data Mahasiswa di Perusahaan</h5>
              <TableNew>
                <Thead>
                  <Tr className="text-center">
                    <Th>No</Th>
                    <Th>NIM</Th>
                    <Th>Nama</Th>
                    <Th>Jurusan</Th>
                    <Th>Jenjang</Th>
                    <Th>
                      <i className="fa fa-fw fa-cog" />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {pojt.mhs &&
                    pojt.mhs.length > 0 &&
                    pojt.mhs.map((m, index) => {
                      return (
                        <Tr key={index}>
                          <Td>{index + 1}</Td>
                          <Td>{m.nim}</Td>
                          <Td>{m.nama}</Td>
                          <Td>{getJurusanShort(parseInt(m.jurusan_id))}</Td>
                          <Td>{m.jenjang}</Td>
                          <Td>
                            {m.pivot && (
                              <SmallButton type="delete" onClick={() => handleDelete(m.pivot.id)}/>
                            )}
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </TableNew>
            </div>
            <div className="col-6">
              <h5 className="text-black fw-semibold mb-3">Cari & Tambah Mahasiswa</h5>
              <FilterSearch className="mb-1" hideLabel onChange={(res) => setSearch(res)} />
              {/* <p className="text-black">
                <i className="fa fa-fw fa-circle-info text-info"/> Jika data mahasiswa berwarna <span className="text-danger">merah</span> artinya mahasiswa sudah di ploting di
                perusahaan lain.
              </p> */}
              <p className="text-black">
                <i className="fa fa-fw fa-circle-info text-info"/> Data mahasiswa yang sudah berisi kotak centang <i className="fa fa-fw fa-sm fa-check"/> pada tabel dibawah sebelah kanan artinya mahasiswa sudah di ploting di perusahaan lain.
              </p>
              <TableNew>
                <Thead>
                  <Tr className="text-center">
                    <Th>No</Th>
                    <Th>NIM</Th>
                    <Th>Nama</Th>
                    <Th>Jurusan</Th>
                    <Th>Jenjang</Th>
                    <Th>
                      <i className="fa fa-fw fa-cog" />
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {mhs.length > 0 &&
                    mhs.map((m, index) => {
                      return (
                        <Tr
                          key={index}
                        >
                          <Td className="text-center">{index + 1}</Td>
                          <Td className="text-center">{m.nim}</Td>
                          <Td>{m.nama}</Td>
                          <Td className="text-center">{getJurusanShort(parseInt(m.jurusan_id))}</Td>
                          <Td className="text-center">{m.jenjang}</Td>
                          <Td className="text-center">
                          {
                            m.mhs_pojt &&
                            m.mhs_pojt.length > 0 &&
                            m.mhs_pojt.filter((mp) => mp.pojt_id === null)
                              .length > 0
                              ? 
                                <>
                                  {m.mhs_pojt &&
                                  m.mhs_pojt.length > 0 &&
                                  m.mhs_pojt.filter((mp) => mp.tahun === tahun)
                                    .length > 0 && (
                                    <SmallButton 
                                      type="custom"
                                      buttonColor="success-2"
                                      icon="plus"
                                      onClick={() =>
                                        handleUpdate(
                                          m.mhs_pojt.filter(
                                            (mp) => mp.tahun === tahun
                                          )[0].id,
                                          pojt.id
                                        )
                                      }
                                    />
                                  )}
                                </>
                              : <SmallButton type="custom" icon="check" buttonColor="dark" disabled />
                          }
                            
                          </Td>
                        </Tr>
                      );
                    })}
                </Tbody>
              </TableNew>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="btn btn-sm btn-dark border-0"
          >
            <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
          </button>
        </LayoutModal>
      )}
    </>
  );
};

export default PojtAddMahasiswa;
