import React, { useCallback, useEffect, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { tokenState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import axios from "../../axios";
import PageLoading from "../../components/pages/Loading";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import SectionPagination from "../../components/sections/Pagination";
import LayoutModal from "../../components/layouts/Modal";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import { FilterCabang, FilterPagination, FilterSearch } from "../../components/layouts/TopContent";

const PenilaianKompetensiHasil = () => {
  const setTitle = useSetRecoilState(titleState);
  const token = useRecoilValue(tokenState);
  const [isLoaded, setIsLoaded] = useState();
  const [page, setPage] = useState(1);
  const [pagination, setPagination] = useState(10);
  const [search, setSearch] = useState("");
  const [cabang, setCabang] = useState("");

  const [items, setItems] = useState({});
  const [soalPenilaian, setSoalPenilaian] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await axios.get(`/api/penilaian-kompetensi-hasil?page=${page}&pagination=${pagination}&search=${search}&cabang=${cabang}`,
        { headers: { Authorization: "Bearer " + token } }
      );
      // console.log(res.data);
      if (res.data && res.data.pegawai) {
        setItems(res.data.pegawai);
        setSoalPenilaian(res.data.soal_penilaian);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [token, setItems, setIsLoaded, page, pagination, search, cabang,]);

  useEffect(() => {
    setTitle("Hasil Penilaian Kompetensi");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        <div className="row mb-3">
          <div className="col-lg-4">
            <FilterCabang
              onChange={(res) => {
                setCabang(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-lg-4">
            <FilterPagination
              onChange={(res) => {
                setPagination(res);
                setPage(1);
              }}
            />
          </div>
          <div className="col-lg-4">
            <FilterSearch
              onChange={(res) => {
                setSearch(res);
                setPage(1);
              }}
            />
          </div>
        </div>

        <TableNew>
          <Thead>
            <Tr className="text-center">
              <Th>No</Th>
              <Th>Nama</Th>
              <Th>
                <i className="fa fa-fw fa-cog"></i>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.data.map((i, index) => {
              return (
                <Tr key={index}>
                  <Td className="text-center">{index + 1}</Td>
                  <Td>{i.nama}</Td>
                  <Td className="text-center">
                    <Detail pegawai={i} soalPenilaian={soalPenilaian} />
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </TableNew>
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

const Detail = ({ pegawai, soalPenilaian }) => {
  const [modal, setModal] = useState(false);

  const [penilaiId, setPenilaiId] = useState([]);
  const [penilai, setPenilai] = useState([]);

  const handleOpen = () => {
    if (pegawai.penilai && pegawai.penilai.length > 0) {
      let allPenilaiId = pegawai.penilai.map((p) => p.penilai_id);

      let removePenilaiId = allPenilaiId.filter((api, i) => {
        if (allPenilaiId.indexOf(api) === i) {
          return 1;
        }
        return 0;
      });

      let allPenilai = pegawai.penilai.map((p) => p);

      setPenilai(allPenilai);
      setPenilaiId(removePenilaiId);
    }

    setModal(true);
  };

  const handleClose = () => {
    setModal(false);
  };

  return (
    <>
      <button
        className="btn btn-sm btn-primary-3 text-sm py-0 px-3"
        onClick={handleOpen}
        type="button"
        title="Lihat Detail Penilaian"
      >
        <i className="fa fa-fw fa-sm fa-square-poll-vertical"/> Hasil Penilaian
      </button>
      <LayoutModal
        modal={modal}
        title={`Hasil Penilaian Pegawai a.n ${pegawai.nama}`}
        onClose={handleClose}
      >
        {/* <LayoutTable>
          <LayoutThead>
            <th>No</th>
            <th>Aspek</th>
            {penilai.length > 0 &&
              penilai
                .filter((p, index) => {
                  let allPenilaiId = penilai.map((p) => p.penilai_id);

                  if (allPenilaiId.indexOf(p.penilai_id) === index) {
                    return 1;
                  }
                  return 0;
                })
                .map((p, index) => {
                  return (
                    <th key={index} className="text-nowrap">
                      {p.penilai && p.penilai.nama}
                    </th>
                  );
                })}
          </LayoutThead>
          <tbody>
            {soalPenilaian.length > 0 &&
              soalPenilaian.map((sp, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td className="text-nowrap">{sp.judul}</td>
                    {penilai.length > 0 &&
                      penilai
                        .filter((p) => parseInt(p.soal_id) === parseInt(sp.id))
                        .map((p, index) => {
                          return (
                            <td key={index} className="text-nowrap">
                              {p.value}
                            </td>
                          );
                        })}
                  </tr>
                );
              })}
          </tbody>
        </LayoutTable> */}
        <TableNew striped={false}>
          <Thead>
            <Tr className="text-center">
              <Th>No</Th>
              <Th>Nama</Th>
              {soalPenilaian.length > 0 &&
                soalPenilaian.map((sp, index) => {
                  return (
                    <Th key={index} className="text-nowrap">
                      {sp.judul}
                    </Th>
                  );
                })
              }
            </Tr>
          </Thead>
          <Tbody>
            {penilai.length > 0 &&
              penilai
                .filter((p, index) => {
                  let allPenilaiId = penilai.map((p) => p.penilai_id);

                  if (allPenilaiId.indexOf(p.penilai_id) === index) {
                    return 1;
                  }
                  return 0;
                })
                .map((p, index) => {
                  return (
                    <Tr key={index}>
                      <Td className="text-nowrap text-center">{index + 1}</Td>
                      <Td className="text-nowrap">
                        {p.penilai && p.penilai.nama}
                      </Td>
                      {penilai.length > 0 &&
                        penilai
                          .filter(
                            (pen) =>
                              parseInt(pen.penilai_id) ===
                              parseInt(p.penilai_id)
                          )
                          .map((pen, index) => {
                            return (
                              <Td key={index} className="text-center">
                                {pen.value}
                              </Td>
                            );
                          })}
                    </Tr>
                  );
                })}
            <Tr>
              <Td colSpan={2} className="text-center fw-semibold">
                Total
              </Td>
              {soalPenilaian.length > 0 &&
                soalPenilaian.map((sp, index) => {
                  return (
                    <Td key={index} className="text-center">
                      {penilai.length > 0 &&
                        penilai
                          .filter(
                            (p) => parseInt(p.soal_id) === parseInt(sp.id)
                          )
                          .map((p) => p.value)
                          .reduce(
                            (prev, current) =>
                              parseInt(prev) + parseInt(current)
                          )}
                    </Td>
                  );
                })}
            </Tr>
            <Tr>
              <Td colSpan={2} className="text-center fw-semibold">
                Rata-Rata
              </Td>
              {soalPenilaian.length > 0 &&
                soalPenilaian.map((sp, index) => {
                  return (
                    <Td key={index} className="text-center">
                      {(
                        parseInt(
                          penilai.length > 0 &&
                            penilai
                              .filter(
                                (p) => parseInt(p.soal_id) === parseInt(sp.id)
                              )
                              .map((p) => p.value)
                              .reduce(
                                (prev, current) =>
                                  parseInt(prev) + parseInt(current)
                              )
                        ) / parseInt(penilaiId.length)
                      ).toFixed(2)}
                    </Td>
                  );
                })}
            </Tr>
          </Tbody>
        </TableNew>
        <button className="btn btn-sm btn-dark border-0" onClick={handleClose}>
          <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
        </button>
      </LayoutModal>
    </>
  );
};

export default PenilaianKompetensiHasil;
