import React from 'react'

function LoadingBasic(props) {

    const {title} = props

  return (
    <div className="d-flex flex-column justify-content-center align-items-center mt-2 mb-4">
        <h6 className='mb-2'>{title ? title : 'Loading. . .'}</h6>
        <span className="loader-awesome-3"></span>
    </div>
  )
}

export default LoadingBasic