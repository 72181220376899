const FormButton = props => {

  FormButton.defaultProps = {
    submit: false,
    reset: false,
    back: false,
  }

  return (
    <div className="btn-group">
      {props.submit && <button type="submit" className="btn btn-success px-3">{props.submit}</button>}
      {props.reset && <button type="reset" className="btn btn-secondary px-3">{props.reset}</button>}
      {props.back && <button onClick={() => window.history.go(-1)} type="button" className="btn btn-danger px-3">{props.back}</button>}
    </div>
  )
}

export default FormButton