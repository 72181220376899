import React, { useCallback, useEffect, useState } from 'react'
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../components/layouts/TableNew'
import { useRecoilState } from 'recoil'
import { titleState } from '../../storage/pages';
import { fetchPerusahaanAlumni } from '../../api';
import PageLoading from '../../components/pages/Loading';
import CreatePerusahaanAlumni from './CreatePerusahaanAlumni';
import EditPerusahaanAlumni from './EditPerusahaanAlumni';
import DeletePerusahaanAlumni from './DeletePerusahaanAlumni';
import { FilterSearch } from '../../components/layouts/TopContent';

function PerusahaanAlumni() {

    const [title, setTitle] = useRecoilState(titleState);
    const [perusahaan, setPerusahaan] = useState([]);
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState("");

    const fetchData = useCallback( async () => {
        try {
            const res = await fetchPerusahaanAlumni("all", `search=${search}`);
            setPerusahaan(res.data.perusahaan_alumni);
            setLoading(false);
        } catch (error) {
            console.log(error.response);
            setLoading(false);
        }
      },[search]);
    

    useEffect(() => {
        fetchData();
        setTitle("Perusahaan Alumni Bekerja");
    }, [fetchData]);

  return (
    <div>
        <CreatePerusahaanAlumni fetchData={fetchData}/>
        <div className='row justify-content-end'>
            <div className='col-lg-4 mb-3'>
                <FilterSearch onChange={(res) => {setSearch(res)}}/>
            </div>
        </div>
        {!loading ? 
            perusahaan.length > 0 ? (
                <TableNew>
                    <Thead>
                        <Tr className="text-center">
                            <Th>No</Th>
                            <Th>Nama</Th>
                            <Th>Alamat</Th>
                            <Th>No Telepon</Th>
                            <Th>No HP</Th>
                            <Th>Email</Th>
                            <Th>PIC</Th>
                            <Th>Total Mhs</Th>
                            <Th><i className='fa fa-fw fa-cog'/></Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {perusahaan.map((p, index) => {
                            return (
                                <Tr key={index}>
                                    <Td className="text-start">{index + 1}</Td>
                                    <Td className="text-start">{p.nama}</Td>
                                    <Td className="text-start">{p.alamat}</Td>
                                    <Td className="text-center">{p.no_telf ? p.no_telf : '-'}</Td>
                                    <Td className="text-center">{p.no_hp ? p.no_hp : '-'}</Td>
                                    <Td className="text-start">{p.email ? p.email : '-'}</Td>
                                    <Td className="text-start">{p.pic ? p.pic : '-'}</Td>
                                    <Td className="text-center">{p.mhs.length}</Td>
                                    <Td className="text-center">
                                        <EditPerusahaanAlumni fetchData={fetchData} perusahaan={p}/>
                                        <DeletePerusahaanAlumni fetchData={fetchData} id={p.id}/>
                                    </Td>
                                </Tr>
                            )
                        })}
                    </Tbody>
                </TableNew>
            ) : (
                <div className='py-5 text-center'>
                    <p><i className='fa fa-fw fa-circle-exclamation text-info'/> Belum ada data perusahaan alumni bekerja.</p>
                </div>
            )    
        : (
            <PageLoading/>
        )}
    </div>
  )
}

export default PerusahaanAlumni