import React, { useState, useRef } from "react";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import FormInput from "../../components/forms/Input";
import { postJabatan } from "../../api";
import { saAlert } from "../../helpers";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import FormSelect from "../../components/forms/Select";
import FormCheckbox from "../../components/forms/Checkbox";

const JabatanCreate = (props) => {

  const {dataCabang, dataJabatan = [], onFetchData, direktorat, divisi, departemen, pelaksana} = props;

  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
    setForm({});
    setErrors({});
  };

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const [group, setGroup] = useState([]);

  const handleChangeCheckbox = (e) => {
    if (e.target.checked) {
      setGroup((prevState) => {
        return [...prevState, e.target.value];
      });
    } else {
      setGroup((prevState) => {
        return prevState.filter(
          (ps) => parseInt(ps) !== parseInt(e.target.value)
        );
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      const res = await postJabatan({ ...form, group });
      if (res.data && res.data.status) {
        onFetchData();
        handleClose();
        saAlert(res.data.status, res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <>
      <LayoutAddBtn onClick={handleOpen} add="Tambah Jabatan" />
      <LayoutModal
        title="Tambah Data Jabatan"
        modal={modal}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit} ref={tagForm}>
          <div className="row">
            <div className="col-md-3">
              <FormSelect
                label="Cabang"
                name="cabang_id"
                onChange={handleChange}
                error={errors.cabang_id}
              >
                <option value="">Pilih</option>
                {dataCabang &&
                  dataCabang.length > 0 &&
                  dataCabang.map((dc, index) => {
                    return (
                      <React.Fragment key={index}>
                        <option value={dc.id}>{dc.nama}</option>
                      </React.Fragment>
                    );
                  })}
              </FormSelect>
            </div>
            <div className="col-md-6">
              <FormInput
                label="Jabatan"
                name="nama"
                onChange={handleChange}
                error={errors.nama}
              />
            </div>
            <div className="col-md-3">
              <FormSelect
                label="Grade"
                name="grade"
                onChange={handleChange}
                error={errors.grade}
              >
                <option value="">Pilih</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
              </FormSelect>
            </div>
            <div className="col-md-4">
              <FormSelect
                label="Direktorat"
                name="direktorat_id"
                onChange={handleChange}
                error={errors.direktorat_id}
              >
                <option value="">Pilih</option>
                {direktorat.map((d, index) => {
                  return (
                    <option key={index} value={d.id}>{d.nama}</option>
                  )
                })}
              </FormSelect>
            </div>
            <div className="col-md-4">
              <FormSelect
                label="Divisi"
                name="divisi_id"
                onChange={handleChange}
                error={errors.divisi_id}
              >
                <option value="">Pilih</option>
                {divisi.map((d, index) => {
                  return (
                    <option key={index} value={d.id}>{d.nama}</option>
                  )
                })}
              </FormSelect>
            </div>
            <div className="col-md-4">
              <FormSelect
                label="Departemen"
                name="departemen_id"
                onChange={handleChange}
                error={errors.departemen_id}
              >
                <option value="">Pilih</option>
                {departemen.map((d, index) => {
                  return (
                    <option key={index} value={d.id}>{d.nama}</option>
                  )
                })}
              </FormSelect>
            </div>
            <div className="col-md-4">
              <FormSelect
                label="Pelaksana"
                name="pelaksana_id"
                onChange={handleChange}
                error={errors.pelaksana_id}
              >
                <option value="">Pilih</option>
                {pelaksana.map((p, index) => {
                  return (
                    <option key={index} value={p.id}>{p.nama}</option>
                  )
                })}
              </FormSelect>
            </div>
          </div>
          <div className="row my-2">
            <NewSubmitButton onClose={handleClose}/>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default JabatanCreate;
