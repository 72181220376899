import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  fetchKelompokMatkul,
  patchKelompokMatkul,
  postKelompokMatkul,
} from "../../api";
import FormInput from "../../components/forms/Input";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { saAlert } from "../../helpers";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";

const JamkulAkademik = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState({});

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchKelompokMatkul(page);
      setItems(res.data);
      setIsLoaded(true);
    } catch (err) {
      console.log(err);
    }
  }, [page]);

  useEffect(() => {
    setTitle("Data Kelompok Mata Kuliah");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        {user.cabang === "pusat" && (
          <CreateKelompokMatkul onFetchData={fetchData} />
        )}
        {items.data.length >= 1 ? (
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>Nama Kelompok Mata Kuliah</Th>
                {user.cabang === "pusat" && <Th>Ubah</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {items.data.map((i, index) => {
                return (
                  <Tr key={index}>
                    <Td className="text-center">{index + items.from}</Td>
                    <Td>{i.nama}</Td>
                    {user.cabang === "pusat" && (
                      <Td className="text-center">
                        <EditKelompokMatkul
                          kelompokMatkul={i}
                          onFetchData={fetchData}
                        />
                      </Td>
                    )}
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

const CreateKelompokMatkul = (props) => {
  const [modal, setModal] = useState(false);
  const tagForm = useRef();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    if (tagForm) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postKelompokMatkul(form)
      .then((res) => {
        if (res.data === "success") {
          props.onFetchData();
          handleClose();
          saAlert("success", "Data berhasil diinput kedalam sistem");
        }
      })
      .catch((err) => {
        if (err.response.status === 422) {
          setErrors(err.response.data.errors);
        }
      });
  };

  return (
    <>
      <LayoutAddBtn add="Tambah Data" onClick={() => setModal(true)} />
      <LayoutModal
        size="md"
        title="Tambah Data Kelompok Mata Kuliah"
        modal={modal}
        onClose={handleClose}
      >
        <form ref={tagForm} onSubmit={handleSubmit}>
          <FormInput
            type="text"
            label="Kelompok Mata Kuliah"
            name="nama"
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.nama}
          />
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success-2">
              Tambah
            </button>
            <button
              type="button"
              className="btn btn-sm btn-dark border-0"
              onClick={handleClose}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

const EditKelompokMatkul = (props) => {
  const [modal, setModal] = useState(false);
  const tagForm = useRef();
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setForm(props.kelompokMatkul);
    setModal(true);
  };

  const handleClose = () => {
    if (tagForm) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    patchKelompokMatkul(form.id, form).then((res) => {
      if (res.data === "success") {
        props.onFetchData();
        handleClose();
        saAlert("success", "Berhasil ubah data");
      }
    });
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-sm btn-success-2 text-sm py-0 px-1"
        onClick={handleOpen}
      >
        <i className="fa fa-fw fa-sm fa-pen"/>
      </button>
      <LayoutModal
        size="md"
        title="Ubah Data Kelompok Mata Kuliah"
        modal={modal}
        onClose={handleClose}
      >
        <form ref={tagForm} onSubmit={handleSubmit}>
          <FormInput
            type="text"
            label="Kelompok Mata Kuliah"
            name="nama"
            value={form.nama}
            onChange={(e) => {
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              });
            }}
            error={errors.dari}
          />
          <div className="btn-group">
            <button type="submit" className="btn btn-sm btn-success-2">
              Simpan perubahaan
            </button>
            <button
              type="button"
              className="btn btn-sm btn-dark border-0"
              onClick={handleClose}
            >
              <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
            </button>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default JamkulAkademik;
