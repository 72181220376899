import React, { useState, useCallback, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deleteLinkSop, fetchLinkSop } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import {
  FilterCabang,
  FilterSearch,
} from "../../components/layouts/TopContent";
import SectionPagination from "../../components/sections/Pagination";
import { saAlert, saConfirm } from "../../helpers";
import { userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import LinkSopCreate from "./Create";
import LinkSopEdit from "./Edit";

const LinkSop = () => {
  const setTitle = useSetRecoilState(titleState);
  const user = useRecoilValue(userState);

  useEffect(() => setTitle("Link SOP"), [setTitle]);

  const [linkSop, setLinkSop] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [cabang, setCabang] = useState("");

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchLinkSop(page, `search=${search}&cabang=${cabang}`);
      if (res.data && res.data.link_sop) {
        setLinkSop(res.data.link_sop);
      }
    } catch (err) {
      console.error(err);
    }
  }, [page, search, cabang]);

  useEffect(() => fetchData(), [fetchData]);

  return (
    <>
      <LinkSopCreate onFetchData={fetchData} />
      {/* Filter 1 */}
      <div className="row mb-3">
        <div className="col-md-4">
          {user.cabang === "pusat" && (
            <FilterCabang
              pusat={1}
              onChange={(res) => {
                setCabang(res);
                setPage(1);
              }}
            />
          )}
        </div>
        <div className="col-md-4">
          <FilterSearch
            onChange={(res) => {
              setSearch(res);
              setPage(1);
            }}
          />
        </div>
      </div>
      <LayoutTable>
        <LayoutThead>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">no</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">cabang</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">judul</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">link</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">devisi</th>
          <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal">
            <i className="fa fa-bars" />
          </th>
        </LayoutThead>
        <tbody>
          {linkSop.data &&
            linkSop.data.length > 0 &&
            linkSop.data.map((ls, index) => {
              return (
                <tr key={index}>
                  <td className="text-center">{linkSop.from + index}</td>
                  <td>{ls.cabang}</td>
                  <td>{ls.judul}</td>
                  <td><span type="button" className="text-primary" onClick={() => window.open(ls.link)}>{ls.link}</span></td>
                  <td>
                    {ls.devisi_link_sop &&
                      ls.devisi_link_sop.length > 0 &&
                      ls.devisi_link_sop.map((dls) => dls.devisi).join(" | ")}
                  </td>
                  <td className="align-middle text-center">
                    <LinkSopEdit linkSop={ls} onFetchData={fetchData} />
                    <i className="mr-1" />
                    <DeleteLinkSop linkSop={ls} onFetchData={fetchData} />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </LayoutTable>
      {linkSop.links && (
        <SectionPagination
          from={linkSop.from}
          to={linkSop.to}
          total={linkSop.total}
          links={linkSop.links}
          onChangePage={(res) => setPage(res)}
        />
      )}
    </>
  );
};

const DeleteLinkSop = ({ linkSop, onFetchData }) => {
  const handleDelete = async () => {
    const res = await saConfirm("warning", "Yakin hapus link sop ?");
    if (res.isConfirmed) {
      try {
        const res = await deleteLinkSop(linkSop.id);
        if (res.data && res.data.msg === "success") {
          onFetchData();
          saAlert("success", "Berhasil hapus data link sop !");
        }
      } catch (err) {
        console.error(err.response);
      }
    }
  };

  return (
    <span type="button" className="badge badge-danger" onClick={handleDelete}>
      <i className="fa fa-trash-alt" />
    </span>
  );
};

export default LinkSop;
