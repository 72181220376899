import React from "react";

export const getStatusItems = (purchase_request_list) => {
  if (purchase_request_list && purchase_request_list.length > 0) {
    return purchase_request_list.length + " items";
  } else {
    return <span className="text-danger">Belum ada items</span>;
  }
};

export const getStatus = (status) => {
  let status_int = parseInt(status);
  if (status_int) {
    if (status_int === 1) {
      return <span className="text-info">Approved by atasan</span>;
    } else if (status_int === 2) {
      return <span className="text-success">Approved by Dir. KSU</span>;
    } else if (status_int === 8) {
      return <span className="text-danger">Rejected by Dir. KSU</span>;
    } else if (status_int === 9) {
      return <span className="text-danger">Rejected by Atasan</span>;
    }
  } else {
    return <span className="text-warning">Waiting for approval</span>;
  }
};
