import React, { useEffect, useState, useCallback } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { fetchNilai } from "../../api";
import LayoutModal from "../../components/layouts/Modal";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { FilterTahunAjaran } from "../../components/layouts/TopContent";
import { semesterAjaranState, tahunAjaranState } from "../../storage/mhs";
import { titleState } from "../../storage/pages";
import PageLoading from "../../components/pages/Loading";

const NilaiAbsensiKurang = () => {
  const setTitle = useSetRecoilState(titleState);
  const tA = useRecoilValue(tahunAjaranState);
  const sA = useRecoilValue(semesterAjaranState);

  useEffect(() => setTitle("Nilai Absensi Kurang"), [setTitle]);

  const [nilai, setNilai] = useState({});

  const [tahunAjaran, setTahunAjaran] = useState(tA);
  const [semesterAjaran, setSemesterAjaran] = useState(sA);
  const [isLoaded, setIsLoaded] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoaded(false);
    try {
      const res = await fetchNilai(
        "all",
        `notification_akademik=1&with_krs=1&with_mhs=1&with_kelas=1&with_pengajaran=1&with_absensi=1&tahun_ajaran=${tahunAjaran}&semester_ajaran=${semesterAjaran}`
      );
      if (res.data && res.data.nilai) {
        setNilai(res.data.nilai);
        setIsLoaded(true);
      }
    } catch (err) {
      console.error(err.response.data);
    }
  }, [tahunAjaran, semesterAjaran]);

  useEffect(() => fetchData(), [fetchData]);

  if (isLoaded) {
    return (
      <div>
        <div className="mb-3">
          <FilterTahunAjaran
            onChangeTahun={(res) => setTahunAjaran(res)}
            onChangeSemester={(res) => setSemesterAjaran(res)}
            valueTahun={tahunAjaran}
            valueSemester={semesterAjaran}
          />
        </div>
        <LayoutTable>
          <LayoutThead>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">No</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Cabang</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">NIM</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Nama</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">Kelas</th>
            <th className="py-1 bg-primary text-white border-bottom border-silver fw-normal text-nowrap">
              <span className="fa fa-fw fa-cog" />
            </th>
          </LayoutThead>
          <tbody>
            {nilai.length > 0 &&
              nilai.map((n, index) => {
                return (
                  <tr key={index}>
                    <td className="border-silver text-center">{1 + index}</td>
                    <td className="border-silver text-center">
                      {n.krs && n.krs.mhs && n.krs.mhs.cabang}
                    </td>
                    <td className="border-silver text-center">
                      {n.krs && n.krs.mhs && n.krs.mhs.nim}
                    </td>
                    <td className="border-silver text-nowrap">
                      {n.krs && n.krs.mhs && n.krs.mhs.nama}
                    </td>
                    <td className="border-silver text-center">
                      {n.krs && n.krs.kelas && n.krs.kelas.kode}
                    </td>
                    <td className="border-silver text-center">
                      <ShowDetail
                        kelas={n.krs && n.krs.kelas && n.krs.kelas}
                        mhs={n.krs && n.krs.mhs && n.krs.mhs}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </LayoutTable>  
      </div>
    );
  } else {
    return <PageLoading/>
  }
};

const ShowDetail = ({ kelas = {}, mhs = {} }) => {
  const [modal, setModal] = useState(false);

  const handleOpen = () => setModal(true);

  const handleClose = () => setModal(false);

  const getAbsensi = (pengajaran) => {
    if (pengajaran.absensi && pengajaran.absensi.length > 1) {
      let absensiMhs = pengajaran.absensi
        .filter((a) => parseInt(a.mhs_id) === parseInt(mhs.id))
        .map((a) => a.status);

      return (
        <>
          {parseInt(absensiMhs) === 1 && (
            <span className="fw-semibold text-success">Hadir</span>
          )}
          {parseInt(absensiMhs) === 2 && (
            <span className="fw-semibold text-info">Ijin</span>
          )}
          {parseInt(absensiMhs) === 3 && (
            <span className="fw-semibold text-warning">Sakit</span>
          )}
          {parseInt(absensiMhs) === 4 && (
            <span className="fw-semibold text-danger">Alpa</span>
          )}
        </>
      );
    }
  };

  return (
    <>
      <button className="btn btn-sm btn-dark border-0 text-sm rounded-1 py-0 px-1" type="button" onClick={handleOpen}>
        <i className="fa fa-fw fa-eye fa-sm" />
      </button>
      <LayoutModal
        title={`Detail Pengajaran Kelas ${kelas.kode}`}
        modal={modal}
        onClose={handleClose}
      >
        <h4 className="fw-semibold text-primary">{mhs.nama}</h4>
        {/* <span className="text-success">Backgroup Hijau Kelas Berjalan</span>
        {` || `}
        <span className="text-danger">Background Merah Kelas Dibatalkan</span> */}
        <br />
        <LayoutTable>
          <tbody>
            <tr>
              <td className="py-1 bg-primary text-white border-silver text-center">Pertemuan</td>
              {kelas.pengajaran &&
                kelas.pengajaran.length > 0 &&
                kelas.pengajaran.filter((p) => parseInt(p.status) !== 9)
                .map((p, index) => {
                  return (
                    <React.Fragment key={index}>
                      <td className="py-1 bg-primary text-white border-silver text-center">{p.pertemuan}</td>
                    </React.Fragment>
                  );
                })
              }
            </tr>
            <tr>
              <td className="py-1 border-silver text-primary fw-semibold">Absensi</td>
              {kelas.pengajaran &&
                kelas.pengajaran.length > 0 &&
                kelas.pengajaran
                  .filter((p) => parseInt(p.status) !== 9)
                  .map((p, index) => {
                    return (
                      <React.Fragment key={index}>
                        <td className="py-1 border-silver text-center">{getAbsensi(p)}</td>
                      </React.Fragment>
                    );
                  })}
            </tr>
          </tbody>
        </LayoutTable>
        <button className="btn btn-sm btn-dark border-0" onClick={handleClose}>
          <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
        </button>
      </LayoutModal>
    </>
  );
};

export default NilaiAbsensiKurang;
