import React, { useState, useEffect, useCallback, useRef } from 'react'
import { titleState } from '../../storage/pages';
import { useSetRecoilState } from 'recoil';
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../components/layouts/TableNew';
import { deleteDepartemen, fetchDepartemen, patchDepartemen, postDepartemen } from '../../api';
import PageLoading from '../../components/pages/Loading';
import { FilterSearch, LayoutAddBtn } from '../../components/layouts/TopContent';
import LayoutModal from '../../components/layouts/Modal';
import FormInput from '../../components/forms/Input';
import { getOrigin, saAlert, saConfirm } from '../../helpers';

function Departemen() {
    const setTitle = useSetRecoilState(titleState);
    const [isLoaded, setIsLoaded] = useState(false);
    const [departemen, setDepartemen] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        setTitle("Departemen");
    }, [setTitle]);

    const fetchData = useCallback(
        async (reset = true) => {
        if (reset) {
            setIsLoaded(false);
        }
        try {
            const res = await fetchDepartemen("all", `&search=${search}`);
            setDepartemen(res.data.departemen);
            setIsLoaded(true);
            } catch (err) {
            //
        }
    }, [search]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div>
            <div className="row justify-content-end">
                <div className="col-md-4 mb-3">
                    <FilterSearch onChange={(res) => setSearch(res)} />
                </div>
            </div>
            <TambahDepartemen onFetchData={fetchData}/>
            {isLoaded ?
            <TableNew>
              <Thead>
                  <Tr className="text-center">
                      <Th>No</Th>
                      <Th>Nama Departemen</Th>
                      <Th>Job Description</Th>
                      <Th><i className='fa fa-fw fa-cog'/></Th>
                  </Tr>
              </Thead>
              <Tbody>
                  {departemen.length > 0 ? 
                  departemen.map((d, index) => {
                      return (
                      <Tr key={index}>
                          <Td className="align-middle text-center">{index + 1}</Td>
                          <Td className="align-middle">{d.nama}</Td>
                          <Td className="align-middle"
                            >
                                {d.job_desc ?
                                <button onClick={() => window.open(`${getOrigin()}/storage/pegawai/departemen/${d.id}.pdf`)} className='btn btn-sm btn-primary-2 text-sm py-0 px-1'><i className='fa fa-fw fa-eye'/> Lihat Job Description</button> : '-'}
                            </Td>
                          <Td className="align-middle text-center">
                              <EditDepartemen departemen={d} onFetchData={fetchData}/>
                              <DeleteDepartemen departemen={d} onFetchData={fetchData}/>
                          </Td>
                      </Tr>
                      )
                  }) :
                      <Tr>
                          <Td colSpan="4" className="text-center p-4">Tidak ada data yang tersedia.</Td>
                      </Tr> 
                  }
              </Tbody>
          </TableNew> : <PageLoading/>
            }
        </div>
      )
}

export const TambahDepartemen = (props) => {
    const {onFetchData} = props;
    const tagForm = useRef(null);
    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});

    const handleOpen = () => {
        setModal(true);
    }

    const handleClose = () => {
        onFetchData();
        tagForm.current && tagForm.current.reset();
        setForm({});
        setErrors({});
        setModal(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        form.nama && formData.append("nama", form.nama);
        form.job_desc && formData.append("job_desc", form.job_desc);

        try {
          const res = await postDepartemen(formData);
          if (res.data && res.data.status === "success") {
            onFetchData();
            handleClose();
            saAlert("success", res.data.msg);
          }
        } catch (err) {
          err.response.status === 422 && setErrors(err.response.data.errors);
        }
    };

    return (
        <>
        <LayoutAddBtn onClick={handleOpen} add="Tambah Divisi" />

        <LayoutModal
            size="md"
            title="Tambah Departemen"
            modal={modal}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} ref={tagForm}>
                <FormInput
                    label="Nama Divisi"
                    name="nama"
                    onChange={(e) => 
                        setForm((prevState) => {
                            return { ...prevState, [e.target.name]: e.target.value };
                        })
                    }
                    error={errors.nama}
                />
                <label className="fw-semibold text-primary"><i className="fa fa-fw fa-file text-dark"/> Job Description</label>
                <input
                  className={`form-control form-control-sm form-control-custom text-black rounded-3 mb-3 ${
                    errors.job_desc ? "is-invalid" : "border-silver"
                  }`}
                  name="job_desc"
                  type="file"
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.files[0],
                      };
                    })
                  }
                />
                <div className="btn-group w-100">
                    <button type="submit" className="btn btn-sm btn-success-2">
                    Simpan
                    </button>
                    <button
                    type="button"
                    className="btn btn-sm btn-dark border-0"
                    onClick={handleClose}
                    >
                    <i className='fa fa-fw fa-sm fa-arrow-left'/> Kembali
                    </button>
                </div>
            </form>
        </LayoutModal>
        </>
    )
}

export const DeleteDepartemen = (props) => {
    const {departemen, onFetchData} = props

    const handleDelete = () => {
        saConfirm("warning", `Hapus departemen ${departemen.nama} ?`).then(async (res) => {
            if (res.isConfirmed) {
            const res = await deleteDepartemen(departemen.id);
            if (res.data && res.data.msg) {
                onFetchData();
                saAlert("success", res.data.msg);
            }
            }
        });
    }

    return (
        <button onClick={handleDelete} className='btn btn-sm btn-danger-2 text-sm py-0 px-1'><i className='fa fa-fw fa-sm fa-trash-alt'/></button>
    )
}

export const EditDepartemen = (props) => {
    const {departemen, onFetchData} = props;
    const tagForm = useRef(null);
    const [modal, setModal] = useState(false);
    const [form, setForm] = useState({});
    const [errors, setErrors] = useState({});

    const handleOpen = () => {
        setModal(true);
        setForm(departemen);
    }

    const handleClose = () => {
        tagForm.current && tagForm.current.reset();
        setForm({});
        setErrors({});
        setModal(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();

        form.id && formData.append("departemen_id", form.id);
        form.nama && formData.append("nama", form.nama);
        form.job_desc && formData.append("job_desc", form.job_desc);

        try {
          const res = await postDepartemen(formData);
          if (res.data && res.data.status === "success") {
            onFetchData();
            saAlert("success", res.data.msg);
          }
        } catch (err) {
          err.response.status === 422 && setErrors(err.response.data.errors);
        }
    };

    return (
        <>
        <button onClick={handleOpen} className='btn btn-sm btn-success-2 text-sm py-0 px-1 me-1'><i className='fa fa-fw fa-sm fa-pen'/></button>

        <LayoutModal
            size="md"
            title="Edit Departemen"
            modal={modal}
            onClose={handleClose}
        >
            <form onSubmit={handleSubmit} ref={tagForm}>
                <FormInput
                    label="Nama Departemen"
                    name="nama"
                    value={departemen.nama}
                    onChange={(e) =>
                        setForm((prevState) => {
                            return { ...prevState, [e.target.name]: e.target.value };
                        })
                    }
                    error={errors.nama}
                />
                <div className='mb-3'>
                    {departemen.job_desc ?
                        <button type='button' onClick={() => window.open(`${getOrigin()}/storage/pegawai/departemen/${departemen.id}.pdf`)} className='btn btn-sm btn-primary-2 rounded-3'><i className='fa fa-fw fa-eye'/> Lihat Job Description</button>
                        :
                        <p className='mb-0'>Belum ada file pdf untuk job description.</p>
                    }
                </div>

                <label className="fw-semibold text-primary"><i className="fa fa-fw fa-file text-dark"/>Job Description</label>
                <input
                  className={`form-control form-control-sm form-control-custom text-black rounded-3 mb-3 ${
                    errors.job_desc ? "is-invalid" : "border-silver"
                  }`}
                  name="job_desc"
                  type="file"
                  onChange={(e) =>
                    setForm((prevState) => {
                      return {
                        ...prevState,
                        [e.target.name]: e.target.files[0],
                      };
                    })
                  }
                />
                <div className="btn-group w-100">
                    <button type="submit" className="btn btn-sm btn-success-2">
                    Simpan
                    </button>
                    <button
                    type="button"
                    className="btn btn-sm btn-dark border-0"
                    onClick={handleClose}
                    >
                    <i className='fa fa-fw fa-sm fa-arrow-left'/> Kembali
                    </button>
                </div>
            </form>
        </LayoutModal>
        </>
    )
}

export default Departemen