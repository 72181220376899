import React from "react";
import { MultipleMenu, SinggleMenu } from "./Sidebar";

const Pjm = (props) => {
  const { user } = props;

  return (
    <>
      {/* PJM */}
      {user.jabatan === "IT Programmer" ||
      user.jabatan === "Penjaminan Mutu" ||
      user.group === "Dir" ? (
        <>
          <hr className="border-0 my-2" />

          <div className="sidebar-heading text-black bg-gradient-warning mx-2 rounded font-weigh-bold py-1">
            Penjaminan Mutu
          </div>

          <hr className="sidebar-divider border-0 mt-2 mb-0" />

          <SinggleMenu title="Data Mahasiswa" fa="fa-users" link="/mhs-pjm" />
          <SinggleMenu title="Data Kelas" fa="fa-school" link="/kelas-pjm" />

          <MultipleMenu
            title="Link"
            fa="fa-link"
            links={[
              { title: "Link Survei", link: "/link-survei" },
              { title: "Link Sop", link: "/link-sop" },
            ]}
          />

          <SinggleMenu title="Departemen" link="/departemen" fa="fa-cogs" />

          <MultipleMenu
            title="Ceklist Monitoring"
            fa="fa-list"
            links={[
              { title: "Ceklist Monitoring", link: "/ceklist-monitoring" },
              {
                title: "Ceklist Monitoring List",
                link: "/ceklist-monitoring-list",
              },
            ]}
          />

          <MultipleMenu
            newLabel
            title="Survey"
            fa="fa-square-poll-vertical"
            links={[
              { title: "Survey Mahasiswa", link: "/survey-mahasiswa" },
              { title: "Survey Alumni", link: "/survey-alumni" },
              { title: "Survey Dosen", link: "/survey-dosen" },
              { title: "Survey Pegawai", link: "/survey-pegawai" },
            ]}
          />
        </>
      ) : null}
    </>
  );
};

export default Pjm;
