import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { deleteCuti, postCuti, showCuti } from "../../api";
import FormCr from "../../components/forms/Cr";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal, { ModalBtn } from "../../components/layouts/Modal";
import TableNew, {
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "../../components/layouts/TableNew";
import {
  defaultFilterBulan,
  defaultFilterTahun,
  FilterBulan,
  FilterTahun,
  LayoutAddBtn,
} from "../../components/layouts/TopContent";
import LoadingPage from "../../components/pages/Loading";
import NoDataPage from "../../components/pages/NoData";
import { formatDate, formatDiffDate, saAlert, saConfirm } from "../../helpers";
import { loginMasterState, userState } from "../../storage/auth";
import { titleState } from "../../storage/pages";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ShowCuti = () => {
  const setTitle = useSetRecoilState(titleState);
  const loginMaster = useRecoilValue(loginMasterState);

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState({});

  const [page] = useState("all");
  const [bulan, setBulan] = useState(defaultFilterBulan);
  const [tahun, setTahun] = useState(defaultFilterTahun);

  const fetchData = useCallback(async () => {
    try {
      const res = await showCuti(page, `bulan=${bulan}&tahun=${tahun}`);
      if (res.data) {
        setItems(res.data);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err.response);
    }
  }, [page, bulan, tahun]);

  useEffect(() => {
    fetchData();
    setTitle("Lihat Data Cuti");
  }, [fetchData, setTitle]);

  if (isLoaded) {
    return (
      <>
        <CreateCuti onFetchData={fetchData} />
        <div className="row mb-2">
          <div className="col-md">
            <FilterBulan all={true} onChange={(res) => setBulan(res)} />
          </div>
          <div className="col-md">
            <FilterTahun onChange={(res) => setTahun(res)} />
          </div>
        </div>

        <div className="text-white bg-gradient-primary border-bottom-warning rounded-3 p-4 text-justify my-3">
          <div>
            <div>
              <div className="border-bottom pb-2 mb-3">
                <h6 className="mb-0 text-warning">
                  Cuti dalam setahun maximal 12 hari
                </h6>
                <h5 className="mb-0 d-flex align-items-start">
                  Total cuti yang digunakan tahun {tahun} adalah
                  <span className="text-warning fw-semibold ms-1">
                    {items.count_cuti_tahunan &&
                      parseInt(items.count_cuti_tahunan.cuti_tahunan) +
                        parseInt(items.count_cuti_tahunan.cuti_alasan_penting) +
                        parseInt(items.count_cuti_tahunan.limit_cuti_tahunan || 0)}
                        {" "}
                    hari
                  </span>
                  <span className="small ms-1">
                    (Cuti bersama + Cuti tahunan)
                  </span>
                </h5>
              </div>

              <div>
                <h6 className="text-gray-400">
                  Rincian cuti yang telah digunakan :
                </h6>
                <div className="row">
                  <div className="col-lg-4">
                    <table className="table table-sm table-bordered">
                      <tbody>
                        <tr>
                          <td className="py-0 text-white border-secondary">
                            Cuti Tahunan
                          </td>
                          <td className="py-0 text-warning border-secondary">
                            {items.count_cuti_tahunan &&
                              items.count_cuti_tahunan.cuti_tahunan ? items.count_cuti_tahunan.cuti_tahunan : "0"}{" "}
                            hari
                          </td>
                        </tr>
                        <tr>
                          <td className="py-0 text-white border-secondary">
                            Cuti bersama
                          </td>
                          <td className="py-0 text-warning border-secondary">
                            {/* {items.count_cuti_tahunan && parseInt(items.count_cuti_tahunan.limit_cuti_tahunan || 0)}{" "}
                            hari */}
                            {items.count_cuti_tahunan &&
                              items.count_cuti_tahunan.cuti_bersama ? items.count_cuti_tahunan.cuti_bersama : "0"}{" "}
                            hari
                          </td>
                        </tr>
                        <tr>
                          <td className="py-0 text-white border-secondary">
                            Cuti Bersalin
                          </td>
                          <td className="py-0 text-warning border-secondary">
                            {items.count_cuti_tahunan &&
                              items.count_cuti_tahunan.cuti_bersalin ? items.count_cuti_tahunan.cuti_bersalin : "0"}{" "}
                            hari
                          </td>
                        </tr>
                        <tr>
                          <td className="py-0 text-white border-secondary">
                            Cuti Alasan Penting
                          </td>
                          <td className="py-0 text-warning border-secondary">
                            {items.count_cuti_tahunan &&
                              items.count_cuti_tahunan.cuti_alasan_penting ? items.count_cuti_tahunan.cuti_alasan_penting : "0"}{" "}
                            hari
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h6 className="text-gray-400">Note :</h6>
                <ul>
                  <li>Cuti tahunan akan dipotong dengan cuti bersama</li>
                  <li>
                    Apabila cuti tahunan habis, anda masih dapat mengajukan ijin
                    melalui{" "}
                    <Link
                      className="link-info text-decoration-none border-bottom border-info"
                      to="/ijin/show"
                    >
                      Klik disini
                    </Link>{" "}
                    dan akan dilakukan pemotongan gaji
                  </li>
                </ul>
              </div>

              <div>
                <h6 className="text-gray-400">
                  Cuti yang termasuk ke Cuti Alasan Penting :
                </h6>
                <div className="row">
                  <div className="col-lg-6">
                    <table className="table table-sm table-bordered">
                      <tbody>
                        <tr>
                          <td className="py-0 text-white border-secondary">
                            Pernikahan pegawai tetap yang lajang
                          </td>
                          <td className="py-0 text-warning border-secondary">
                            3 hari / Tahun
                          </td>
                        </tr>
                        <tr>
                          <td className="py-0 text-white border-secondary">
                            Istri pegawai melahirkan atau keguguran
                          </td>
                          <td className="py-0 text-warning border-secondary">
                            3 hari / Tahun
                          </td>
                        </tr>
                        <tr>
                          <td className="py-0 text-white border-secondary">
                            Keluarga inti meninggal dunia
                          </td>
                          <td className="py-0 text-warning border-secondary">
                            3 hari / Tahun
                          </td>
                        </tr>
                        <tr>
                          <td className="py-0 text-white border-secondary">
                            Keluarga / kerabat yang tinggal serumah meniggal
                            dunia
                          </td>
                          <td className="py-0 text-warning border-secondary">
                            1 hari / Tahun
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <h6 className="text-gray-400">Note :</h6>
                <ul className="mb-0">
                  <li>Cuti alasan penting tidak akan memotong cuti tahunan.</li>
                  <li>
                    Jika ingin mengajukan lebih dari ketentuan diatas harus ada
                    surat tertulis dari Direktur Utama
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        {items.cuti && items.cuti.length > 0 ? (
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>ID</Th>
                <Th>Dari</Th>
                <Th>Sampai</Th>
                <Th>Jumlah</Th>
                <Th>Keperluan</Th>
                <Th>Status</Th>
                <Th>Oleh</Th>
                <Th>
                  <i className="fa fa-fw fa-cog" />
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.cuti.map((i, index) => {

                const jenisCuti = i.keperluan.split(":")[0].trim();

                return (
                  <Tr key={index}>
                    <Td className="text-center align-middle">{1 + index}</Td>
                    <Td className="text-center align-middle">{i.id}</Td>
                    <Td className="text-center text-nowrap align-middle">
                      {i.dari && formatDate(i.dari)}
                    </Td>
                    <Td className="text-center text-nowrap align-middle">
                      {i.sampai && formatDate(i.sampai)}
                    </Td>
                    <Td className="text-center text-nowrap align-middle">
                      {formatDiffDate(i.jumlah)}
                    </Td>
                    <Td className="align-middle">
                      {jenisCuti === "Cuti Tahunan" &&
                        <p className="mb-0 text-center fw-medium text-success">{i.keperluan}</p>
                      }
                      {jenisCuti === "Cuti Bersama" &&
                        <p className="mb-0 text-center fw-medium text-primary-2">{i.keperluan}</p>
                      }
                      {jenisCuti === "Cuti Bersalin" &&
                        <p className="mb-0 text-center fw-medium text-purple">{i.keperluan}</p>
                      }
                      {jenisCuti === "Cuti Alasan Penting" &&
                        <span className="fw-medium text-warning-2">{i.keperluan.split(":")[0]} <span className="fw-normal text-black">: {i.keperluan.split(":")[1]}</span></span>
                      }
                    </Td>
                    <Td className="text-center align-middle">
                      {i.status === null && (
                        <span className="fw-semibold text-dark">
                          Menunggu...
                        </span>
                      )}
                      {parseInt(i.status) === 1 && (
                        <span className="fw-semibold text-success-2">
                          Disetujui
                        </span>
                      )}
                      {parseInt(i.status) === 2 && (
                        <span className="fw-semibold text-danger-2">
                          Ditolak
                        </span>
                      )}
                    </Td>
                    <Td className="align-middle">
                      {i.atasan && i.atasan.nama}
                    </Td>
                    <Td className="text-nowrap align-middle text-center">
                      {i.status === null ? (
                        <DeleteCuti id={i.id} onFetchData={fetchData} />
                      ) : (
                        <>
                          {loginMaster ? (
                            <DeleteCuti id={i.id} onFetchData={fetchData} />
                          ) : (
                            <i className="fa fa-fw fa-circle-check text-success-2" />
                          )}
                        </>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </TableNew>
        ) : (
          <NoDataPage />
        )}
      </>
    );
  } else {
    return <LoadingPage />;
  }
};

export const CreateCuti = (props) => {
  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);
  const user = useRecoilValue(userState);
  const [form, setForm] = useState({});
  const [formErrors, setFormErrors] = useState({});

  const handleClose = () => {
    if (tagForm.current) {
      tagForm.current.reset();
    }
    setForm({});
    setFormErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    postCuti(form)
      .then((res) => {
        if (res.data) {
          if (res.data.status === "success") {
            saAlert("success", res.data.message);
            props.onFetchData();
            handleClose();
          }

          if (res.data.status === "fail") {
            saAlert("warning", res.data.message);
            props.onFetchData();
          }
        }
      })
      .catch((err) => {
        let res = err.response;
        if (res.status === 422) {
          setFormErrors(res.data.errors);
        }
      });
  };
  
  return (
    <>
      <LayoutAddBtn onClick={() => setModal(true)} add="Buat Cuti Baru" />
      <LayoutModal
        size="md"
        title="Buat Cuti Baru"
        modal={modal}
        onClose={handleClose}
      >
        <form ref={tagForm} onSubmit={handleSubmit}>
          <FormInput label="NIP" readOnly={true} value={user.nip} />
          <FormInput label="Nama" readOnly={true} value={user.nama} />
          <div className="row">
            <div className="col">
              <FormInput
                label="Dari"
                type="date"
                name="dari"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={formErrors.dari}
              />
            </div>
            <div className="col">
              <FormInput
                label="Sampai"
                type="date"
                name="sampai"
                onChange={(e) =>
                  setForm((prevState) => {
                    return { ...prevState, [e.target.name]: e.target.value };
                  })
                }
                error={formErrors.sampai}
              />
            </div>
          </div>
          <FormTextarea
            label="Alamat"
            name="alamat"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={formErrors.alamat}
          />
          <FormInput
            label="No HP"
            name="no_hp"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={formErrors.no_hp}
          />
          {modal && (
            <FormCr
              divClass="text-dark"
              label="Keperluan"
              type="radio"
              inline={true}
              name="keperluan"
              onChange={(e) =>
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                })
              }
              option={[
                { value: "Cuti Tahunan" },
                { value: "Cuti Bersama" },
                { value: "Cuti Bersalin" },
                { value: "Cuti Alasan Penting" },
              ]}
              error={formErrors.keperluan}
            />
          )}
          {form.keperluan === "Cuti Alasan Penting" &&
            <FormTextarea
              name="keperluan_lain"
              placeholder="Tulis alasan penting disini. . ."
              onChange={(e) =>
                setForm((prevState) => {
                  return { ...prevState, [e.target.name]: e.target.value };
                })
              }
              error={formErrors.keperluan_lain}
            />
          }

          <div className="my-4">
            <p className="mb-0 text-center fw-semibold text-primary">
              Approval cuti oleh :
            </p>
            <p className="mb-0 text-center fw-semibold text-dark">
              {(() => {
                if (user.atasan1) {
                  return user.atasan1.nama;
                } else {
                  if (user.atasan2) {
                    return user.atasan2.nama;
                  }
                }
              })()}
            </p>
          </div>

          <ModalBtn width="full" onClose={handleClose} />
        </form>
      </LayoutModal>
    </>
  );
};

const DeleteCuti = (props) => {
  const handleDelete = () => {
    saConfirm("warning", "Yakin hapus data cuti ?").then((res) => {
      if (res.isConfirmed) {
        deleteCuti(props.id).then((res) => {
          if (res.data) {
            props.onFetchData();
            saAlert("success", "Berhasil hapus cuti !");
          }
        });
      }
    });
  };
  return (
    <button
      onClick={handleDelete}
      className="btn btn-sm btn-danger-2 py-0 px-1"
    >
      <i className="fa fa-fw fa-sm fa-trash-alt" />
    </button>
  );
};

// const EditCuti = (props) => {
//   const tagForm = useRef();
//   const [modal, setModal] = useState(false);
//   const user = useRecoilValue(userState);
//   const [form, setForm] = useState({});
//   const [formErrors, setFormErrors] = useState({});

//   const handleClose = () => {
//     if (tagForm) {
//       tagForm.current.reset();
//     }
//     setForm({});
//     setFormErrors({});
//     setModal(false);
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     postCuti(form)
//       .then((res) => {
//         if (res.data) {
//           if (res.data.status === "success") {
//             saAlert("success", res.data.message);
//             props.onFetchData();
//             handleClose();
//           }

//           if (res.data.status === "fail") {
//             saAlert("warning", res.data.message);
//             props.onFetchData();
//           }
//         }
//       })
//       .catch((err) => {
//         let res = err.response;
//         if (res.status === 422) {
//           setFormErrors(res.data.errors);
//         }
//       });
//   };
//   return (
//     <>
//       <LayoutAddBtn onClick={() => setModal(true)} add="Buat Cuti Baru" />
//       <LayoutModal title="Buat Cuti Baru" modal={modal} onClose={handleClose}>
//         <form ref={tagForm} onSubmit={handleSubmit}>
//           <FormInput label="NIP" readOnly={true} value={user.nip} />
//           <FormInput label="Nama" readOnly={true} value={user.nama} />
//           <div className="row">
//             <div className="col">
//               <FormInput
//                 label="Dari"
//                 type="date"
//                 name="dari"
//                 onChange={(e) =>
//                   setForm((prevState) => {
//                     return { ...prevState, [e.target.name]: e.target.value };
//                   })
//                 }
//                 error={formErrors.dari}
//               />
//             </div>
//             <div className="col">
//               <FormInput
//                 label="Sampai"
//                 type="date"
//                 name="sampai"
//                 onChange={(e) =>
//                   setForm((prevState) => {
//                     return { ...prevState, [e.target.name]: e.target.value };
//                   })
//                 }
//                 error={formErrors.sampai}
//               />
//             </div>
//           </div>
//           <FormTextarea
//             label="Alamat"
//             name="alamat"
//             onChange={(e) =>
//               setForm((prevState) => {
//                 return { ...prevState, [e.target.name]: e.target.value };
//               })
//             }
//             error={formErrors.alamat}
//           />
//           <FormInput
//             label="No HP"
//             name="no_hp"
//             onChange={(e) =>
//               setForm((prevState) => {
//                 return { ...prevState, [e.target.name]: e.target.value };
//               })
//             }
//             error={formErrors.no_hp}
//           />
//           <FormCr
//             label="Keperluan"
//             type="radio"
//             inline={true}
//             name="keperluan"
//             onChange={(e) =>
//               setForm((prevState) => {
//                 return { ...prevState, [e.target.name]: e.target.value };
//               })
//             }
//             option={[
//               { value: "Cuti Tahunan" },
//               { value: "Cuti Bersalin" },
//               { value: "Cuti Alasan Penting" },
//             ]}
//             error={formErrors.keperluan}
//           />
//           <FormTextarea
//             name="keperluan_lain"
//             placeholder="Tulis alasan penting disini !"
//             onChange={(e) =>
//               setForm((prevState) => {
//                 return { ...prevState, [e.target.name]: e.target.value };
//               })
//             }
//             error={formErrors.keperluan_lain}
//           />
//           <hr />
//           <p className="text-center">
//             Approval lembur oleh :{" "}
//             {(() => {
//               if (user.atasan1) {
//                 return user.atasan1.nama;
//               } else {
//                 if (user.atasan2) {
//                   return user.atasan2.nama;
//                 }
//               }
//             })()}
//           </p>
//           <hr />
//           <div className="btn-group">
//             <button type="submit" className="btn btn-sm btn-success">
//               Ajukan
//             </button>
//             <button
//               type="button"
//               onClick={handleClose}
//               className="btn btn-sm btn-secondary"
//             >
//               Tutup
//             </button>
//           </div>
//         </form>
//       </LayoutModal>
//     </>
//   );
// };

export default ShowCuti;
