import React from "react";
import { useEffect } from "react";
import { saToast } from "../helpers";

const ShowHideDetail = ({ detail, onClick }) => {
  useEffect(() => {
    if (detail) {
      saToast("success", "Menampilkan data secara detail.");
    } else {
      saToast("success", "Menampilkan data secara normal.");
    }
  }, [detail]);

  return (
    <div className="bg-light w-50 p-2 mb-2">
      <button className="btn btn-sm btn-primary-2 rounded-3 px-3 mb-2" onClick={() => onClick(!detail)}>
        <i className="fa fa-fw fa-eye me-1"/>
        {detail ? "Tampilan normal" : "Tampilan detail"}
      </button>
      <p className="m-0 p-0">
        Penggunaan tampilan detail memudahkan anda dalam mencopy semua data.
      </p>
    </div>
  );
};

export default ShowHideDetail;
