import React, { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { fetchBukuPerpustakaan, patchBukuPerpustakaan } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import { FilterSearch } from "../../components/layouts/TopContent";
import SectionPagination from "../../components/sections/Pagination";
import { titleState } from "../../storage/pages";
import BukuPerpustakaanCreate from "./Create";
import BukuPerusahaanEdit from "./Edit";
import TableNew, { Tbody, Td, Th, Thead, Tr } from "../../components/layouts/TableNew";
import SmallButton from "../../components/forms/SmallButton";
import { saAlert, saConfirm } from "../../helpers";
import axios from "../../axios.js";

const BukuPerpustakaan = () => {
  const setTitle = useSetRecoilState(titleState);

  useEffect(() => setTitle("Data Buku"), [setTitle]);

  const [items, setItems] = useState({});
  const [itemsJurusan, setItemsJurusan] = useState({});

  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");

  const defUrl = "fetchJurusan=true&with_jurusan=true&";

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchBukuPerpustakaan(
        page,
        `${defUrl}search=${search}`
      );
      if (res.data) {
        setItems(res.data.buku_perpustakaan);
        setItemsJurusan(res.data.jurusan);
      }
    } catch (err) {
      console.error(err);
    }
  }, [page, search]);

  useEffect(() => fetchData(), [fetchData]);

  const handleNonaktifkan = (item) => {
    const InitialItem = {...item, status: (item.status === 1 || item.status === null) ? 9 : 1};
    
    saConfirm("warning", `Yakin ${(item.status === 1 || item.status === null) ? "Nonaktifkan" : "Aktifkan"} status buku ?`).then((res) => {
      if (res.isConfirmed) {
        axios.patch(`/api/buku-perpustakaan/${item.id}`, InitialItem)
          .then((res) => {
            if (res.data === "success") {
              fetchData();
              saAlert("success", "Berhasil mengubah status buku.");
            }
          })
          .catch((err) => console.log(err));
      }
    });
  };

  return (
    <>
      <BukuPerpustakaanCreate jurusan={itemsJurusan} onFetchData={fetchData} />

      <div className="row mb-3">
        <div className="col-lg-4 ms-auto">
          <FilterSearch onChange={(res) => setSearch(res)} />
        </div>
      </div>

      <div className="row">
        <div className="col-2 pe-0">
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>No</Th>
                <Th>Kode</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data &&
                items.data.length > 0 &&
                items.data.map((i, index) => {
                  return (
                    <Tr key={index}>
                      <Td className="text-nowrap text-center">
                        {index + items.from}
                      </Td>
                      <Td className="text-nowrap text-center">{i.kode}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
        <div className="col-9 px-0">
          <TableNew>
            <Thead>
              <Tr className="text-center">
                <Th>Status</Th>
                <Th>Cabang</Th>
                <Th>Judul</Th>
                <Th>Penerbit</Th>
                <Th>Pengarang</Th>
                <Th>Jurusan</Th>
                <Th>Halaman</Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data &&
                items.data.length > 0 &&
                items.data.map((i, index) => {
                  return (
                    <Tr key={index}>
                      <Td className="text-nowrap text-center">
                        {(i.status === null || i.status === 1) && (
                          <span className="text-success fw-semibold">Aktif</span>
                        )}
                        {i.status === 9 && (
                          <span className="text-danger-2 fw-semibold">Nonaktif</span>
                        )}
                      </Td>
                      <Td className="text-nowrap text-center">{i.cabang}</Td>
                      <Td className="text-nowrap">{i.judul}</Td>
                      <Td className="text-nowrap text-nowrap">{i.penerbit}</Td>
                      <Td className="text-nowrap text-nowrap">{i.pengarang}</Td>
                      <Td className="text-nowrap text-nowrap">
                        {i.jurusan && i.jurusan.nama}
                      </Td>
                      <Td className="text-nowrap text-center">{i.halaman}</Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
        <div className="col-1 ps-0">
          <TableNew copy={false}>
            <Thead>
              <Tr className="text-center">
                <Th><i className="fa fa-fw fa-cog"/></Th>
              </Tr>
            </Thead>
            <Tbody>
              {items.data &&
                items.data.length > 0 &&
                items.data.map((i, index) => {
                  return (
                    <Tr key={index}>
                      <Td className="text-center text-nowrap">
                        <BukuPerusahaanEdit
                          bukuPerpustakaan={i}
                          jurusan={itemsJurusan}
                          onFetchData={fetchData}
                        />
                        <SmallButton onClick={() => handleNonaktifkan(i)} type="custom" icon="fa fa-fw fa-ban" buttonColor="danger-2" />
                      </Td>
                    </Tr>
                  );
                })}
            </Tbody>
          </TableNew>
        </div>
      </div>

      {items.links && (
        <SectionPagination
          from={items.from}
          to={items.to}
          total={items.total}
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      )}
    </>
  );
};

export default BukuPerpustakaan;
