import React, { useEffect, useState } from 'react'
import { titleState } from '../../../storage/pages'
import { useRecoilState } from 'recoil';
import SMCreate from './SMCreate';
import SMListMahasiswa from './SMListMahasiswa';
import SMSoalSurvey from './SMSoalSurvey';
import TableNew, { Tbody, Td, Th, Thead, Tr } from '../../../components/layouts/TableNew';
import SmallButton from '../../../components/forms/SmallButton';
import { fetchSurveyMhs, patchJenisSurveyMhs } from '../../../api';
import { fetchCabang } from '../../../modules/cabang/apis';
import LoadingBasic from '../../../components/pages/LoadingBasic';
import SMEdit from './SMEdit';
import SMDelete from './SMDelete';
import SMJenisCreate from './SMJenisCreate';
import { TableNoData } from '../../../components/layouts/Table';
import { getBulan, getGenapGanjil, saAlert, saConfirm } from '../../../helpers';
import SMJenisEdit from './SMJenisEdit';
import SMJenisDelete from './SMJenisDelete';

function SurveyMhs() {

    const [title, setTitle] = useRecoilState(titleState);
    const [surveyMhs, setSurveyMhs] = useState([]);
    const [dataCabang, setDataCabang] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTitle("Survey Mahasiswa");
    }, [title]);

    const fetchdata = async () => {
        try {
            const res = await fetchSurveyMhs("all");
            setSurveyMhs(res.data.survey);
        } catch (error) {
            console.log(error.response);
        } finally {
            setLoading(false);
        }
    }

    const fetchDataCabang = async () => {
        try {
            const res = await fetchCabang("all");
            setDataCabang(res.data.cabang);
        } catch (error) {
            console.log(error.response);
        }
      };
      
      useEffect(() => {
        fetchDataCabang();
        fetchdata();
    }, []);

    const toggleSurveyActive = (id, js) => {
        console.log(js);
        saConfirm(
            "warning",
            `${parseInt(js.status) === 9 ? "Aktif survey ini?" : "Nonaktif survey ini?"}`
        ).then(async (res) => {
            if (res.isConfirmed) {
                try {
                    const res = await patchJenisSurveyMhs(id, {...js, status: parseInt(js.status) === 9 ? '1' : '9'});
                    if (res.data.status === "success") {
                        fetchdata();
                        saAlert("success", "Perubahaan tersimpan.");
                    }
                } catch (err) {
                    // err.response &&
                    //     err.response.status === 422 &&
                    //     setErrors(err.response.data.errors);
                    console.log(err);
                }
            }
        });
    };

  return (
    <>
        <SMCreate fetchdata={fetchdata} dataCabang={dataCabang}/>

        {!loading ? (
            surveyMhs.length > 0 ? (
                surveyMhs.map((sm, index) => {
                    return (
                        <div className='row' key={index}>
                            <div className='col-8 pe-0'>
                                <TableNew>
                                    <Thead>
                                        <Tr>
                                            <Th colSpan={6} className="text-nowrap fw-normal">
                                                [ <span className='text-warning fw-bold'>{sm.cabang?.nama ? sm.cabang?.nama : 'Semua Cabang'}</span> ] <span className='fw-semibold'>{sm.name}</span>
                                            </Th>
                                        </Tr>
                                        <Tr className="text-center">
                                            <Th className="text-nowrap">No</Th>
                                            <Th className="text-nowrap">Tahun</Th>
                                            <Th className="text-nowrap">Jenis Survey</Th>
                                            <Th className="text-nowrap">Semester</Th>
                                            <Th className="text-nowrap">Pelaksanaan</Th>
                                            <Th className="text-nowrap">Diseminasi</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {sm.jenis_survey.length > 0 ? (
                                            sm.jenis_survey.map((js, index) => {
                                                return (
                                                    <Tr key={index}>
                                                        <Td className="text-nowrap text-center">{index + 1}</Td>
                                                        <Td className="text-nowrap text-center">{js.tahun}</Td>
                                                        <Td className="text-nowrap text-start">{js.name}</Td>
                                                        <Td className="text-nowrap text-center">{getGenapGanjil(js.semester)}</Td>
                                                        <Td className="text-nowrap text-center">{getBulan(js.pelaksanaan)}</Td>
                                                        <Td className="text-nowrap text-center">{getBulan(js.desiminasi)}</Td>
                                                    </Tr>
                                                )
                                            })
                                        ) : (
                                            <Tr className="text-center">
                                                <Td colSpan={6} className="text-nowrap">Belum ada jenis survey.</Td>
                                            </Tr>
                                        )}
                                    </Tbody>
                                </TableNew>
                            </div>
                            <div className='col-4 ps-0'>
                                <TableNew copy={false}>
                                    <Thead>
                                        <Tr>
                                            <Th colSpan={2} className="text-nowrap text-center">
                                                <SMJenisCreate surveyMhs={sm} fetchdata={fetchdata}/>
                                                <SMEdit fetchdata={fetchdata} dataCabang={dataCabang} surveyMhs={sm}/>
                                                <SMDelete fetchdata={fetchdata} surveyMhs={sm}/>
                                            </Th>
                                        </Tr>
                                        <Tr className="text-center">
                                            <Th className="text-nowrap"><i className='fa fa-fw fa-cog'/></Th>
                                            <Th className="text-nowrap">on/off</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                        {sm.jenis_survey.length > 0 ? (
                                            sm.jenis_survey.map((js, index) => {
                                                return (
                                                    <Tr key={index}>
                                                        <Td className="text-nowrap text-center">
                                                            <SMSoalSurvey surveyMhs={sm} jenisSurvey={js} fetchdata={fetchdata}/>
                                                            <SMListMahasiswa/>
                                                            <SMJenisEdit surveyMhs={sm} jenisSurvey={js} fetchdata={fetchdata}/>
                                                            <SMJenisDelete jenisSurvey={js} fetchdata={fetchdata}/>
                                                        </Td>
                                                        <Td className="text-nowrap text-center">
                                                            {js.status === 1 ? (
                                                                <SmallButton onClick={() => toggleSurveyActive(js.id, js)} type="custom" icon="power-off" buttonColor="success-2" text="on"/>
                                                            ) :(
                                                                <SmallButton onClick={() => toggleSurveyActive(js.id, js)} type="custom" icon="power-off" buttonColor="danger-2" text="off"/>
                                                            )}
                                                        </Td>
                                                    </Tr>
                                                )
                                            })
                                        ) : (
                                            <Tr className="text-center">
                                                <Td colSpan={6}>-</Td>
                                            </Tr>
                                        )}
                                    </Tbody>
                                </TableNew>
                            </div>
                        </div>
                    )
                })
            ) : (
                <div className='py-5 text-center'>
                    <p className='mb-0'><i className='fa fa-fw fa-circle-exclamation'/> Tidak ada data survey.</p>
                </div>
            )
        ) : (
            <div className='py-5'>
                <LoadingBasic/>
            </div>
        )}
    </>
  )
}

export default SurveyMhs