import React, { useRef, useState } from "react";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import { useRecoilValue } from "recoil";
import { userState } from "../../storage/auth";
import { patchPengumuman } from "../../api";
import { saAlert } from "../../helpers";
import FormTextarea from "../../components/forms/Textarea";

const PengumumanEdit = ({
  pengumuman,
  dataCabang,
  dataDepartemen,
  onFetchData,
}) => {
  const user = useRecoilValue(userState);
  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setForm(pengumuman);
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
    setForm({});
    setErrors({});
  };

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      let formData = new FormData(tagForm.current);

      const res = await patchPengumuman(pengumuman.id, formData);
      if (res.data && res.data.status) {
        onFetchData();
        handleClose();
        saAlert(res.data.status, res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  if (pengumuman) {
    return (
      <>
        <button className="btn btn-sm btn-success-2 text-sm py-0 px-1 m-1" type="button" onClick={handleOpen}>
          <i className="fa fa-fw fa-sm fa-pen"></i>
        </button>

        <LayoutModal
          title="Edit Data Pengumuman"
          modal={modal}
          onClose={handleClose}
        >
          <form onSubmit={handleSubmit} ref={tagForm}>
            <div className="row mb-4">
              <div className="col-md-4">
                <FormSelect
                  label="Tujuan Pengumuman"
                  name="tujuan"
                  value={form.tujuan || ""}
                  onChange={handleChange}
                  error={errors.tujuan}
                >
                  <option value="">Semua Sistem</option>
                  <option value="SIA">SIA</option>
                  <option value="SIDOS">SIDOS</option>
                </FormSelect>
              </div>
              <div className="col-md-8">
                <FormInput
                  label="Judul"
                  name="judul"
                  value={form.judul}
                  onChange={handleChange}
                  error={errors.judul}
                />
              </div>
              <div className="col-md-12">
                <FormTextarea
                  label="Isi"
                  name="isi"
                  value={form.isi}
                  onChange={handleChange}
                  error={errors.isi}
                  row={10}
                />
              </div>
              {user.cabang === "pusat" && (
                <div className="col-md-4">
                  <FormSelect
                    label="Cabang"
                    name="cabang_id"
                    value={form.cabang_id || ""}
                    onChange={handleChange}
                    error={errors.cabang_id}
                  >
                    <option value="">Pilih</option>
                    {dataCabang &&
                      dataCabang.length > 0 &&
                      dataCabang.map((dc, index) => {
                        return (
                          <React.Fragment key={index}>
                            <option value={dc.id}>{dc.nama}</option>
                          </React.Fragment>
                        );
                      })}
                  </FormSelect>
                </div>
              )}
              <div className="col-md-4">
                <FormSelect
                  label="Departemen"
                  name="departemen_id"
                  value={form.departemen_id || ""}
                  onChange={handleChange}
                  error={errors.departemen_id}
                >
                  <option value="">Pilih</option>
                  {dataDepartemen &&
                    dataDepartemen.length > 0 &&
                    dataDepartemen.map((dd, index) => {
                      return (
                        <React.Fragment key={index}>
                          <option value={dd.id}>{dd.nama}</option>
                        </React.Fragment>
                      );
                    })}
                </FormSelect>
              </div>
              <div className="col-md-4">
                <FormSelect
                  label="Jenjang"
                  name="jenjang"
                  value={form.jenjang || ""}
                  onChange={handleChange}
                  error={errors.jenjang}
                >
                  <option value="">Semua Program</option>
                  <option value="D1">Program 1 Tahun</option>
                  <option value="D2">Program 2 Tahun</option>
                </FormSelect>
              </div>
              <div className="col-md-4">
                <FormSelect
                  label="Tahun Angkatan"
                  name="tahun_angkatan"
                  value={form.tahun_angkatan || ""}
                  onChange={handleChange}
                  error={errors.tahun_angkatan}
                >
                  <option value="">Semua Jenjang</option>
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                  <option value="2024">2024</option>
                  <option value="2025">2025</option>
                  <option value="2026">2026</option>
                  <option value="2027">2027</option>
                </FormSelect>
              </div>
              <div className="col-md-4">
                <FormInput
                  label="Dari"
                  type="date"
                  name="dari"
                  value={form.dari}
                  onChange={handleChange}
                  error={errors.dari}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  label="Sampai"
                  type="date"
                  name="sampai"
                  value={form.sampai}
                  onChange={handleChange}
                  error={errors.sampai}
                />
              </div>
              <div className="col-md-4">
                <FormInput
                  type="file"
                  label="File Upload"
                  name="file"
                  error={errors.file}
                />
              </div>
            </div>
            <div className="row">
              <NewSubmitButton submitText="Simpan Perubahaan" onClose={handleClose}/>
            </div>
          </form>
        </LayoutModal>
      </>
    );
  } else {
    return "";
  }
};

export default PengumumanEdit;
