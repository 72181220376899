import React, { useRef, useState } from "react";
import { patchMhs } from "../../api";
import FormInput from "../../components/forms/Input";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import { saAlert, saConfirm } from "../../helpers";

const MahasiswaNonaktifkan = ({ mhs, onFetchData, btnClassName, btnText }) => {
  const [modal, setModal] = useState(false);
  const tagForm = useRef(null);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleOpen = () => {
    setModal(true);
  };

  const handleClose = () => {
    tagForm && tagForm.current && tagForm.current.reset();
    setForm({});
    setErrors({});
    setModal(false);
  };

  // aktif nonaktifkan mahasiswa
  const handleUpdate = (id, status) => {
    saConfirm(
      "warning",
      `Yakin ubah status menjadi ${status === null ? "Aktif" : "Non Aktif"} ?`
    ).then(async (res) => {
      if (res.isConfirmed) {
        setErrors({});
        try {
          const res = await patchMhs(id, {
            status,
            nonaktifkan: true,
            ...form,
          });
          if (res.data === "success") {
            onFetchData();
            status === null && handleClose();
            saAlert("success", "Berhasil update data mahasiswa");
          }
        } catch (err) {
          err.response &&
            err.response.status === 422 &&
            setErrors(err.response.data.errors);
        }
      }
    });
  };

  console.log(errors);

  return (
    <>
      <button onClick={handleOpen} type="button" className={`btn btn-sm ${btnClassName} text-sm py-0 px-1`}>
        {btnText}
      </button>
      <LayoutModal
        size="sm"
        modal={modal}
        title={`${mhs.status === 9 ? 'Aktifkan' : 'Nonaktifkan'} mahasiswa ${mhs.nama}`}
        onClose={handleClose}
      >
        <div className="mb-2">
          {parseInt(mhs.status) === 9 && (
            <div className="text-black">
              <p className="mb-0 fw-semibold text-primary">Keterangan :</p>
              <p>{mhs.keterangan_nonaktif}</p>
              <p className="mb-0 fw-semibold text-primary">Tanggal Nonaktif :</p>
              <p>{mhs.tanggal_nonaktif}</p>
              <p className="mb-0 fw-semibold text-primary">Nonaktif By :</p>
              <p>{parseInt(mhs.nonaktif_by) === 1 && "Akademik"} {parseInt(mhs.nonaktif_by) === 2 && "SDM"}</p>
              <button
                type="button"
                className="btn btn-success-2 btn-sm w-100"
                onClick={() => handleUpdate(mhs.id, null)}
              >
                <i className="fa fa-fw fa-sm fa-check"/> Aktifkan
              </button>
            </div>
          )}

          {(mhs.status === null || parseInt(mhs.status) === 0) && (
            <>
              <form ref={tagForm} onSubmit={(e) => e.preventDefault()}>
                <FormTextarea
                  label="Keterangan"
                  name="keterangan_nonaktif"
                  onChange={handleChange}
                  error={errors.keterangan_nonaktif}
                />
                <FormInput
                  type="date"
                  label="Tanggal"
                  name="tanggal_nonaktif"
                  onChange={handleChange}
                  error={errors.tanggal_nonaktif}
                />
              </form>
              <button
                type="button"
                className="btn btn-danger-2 btn-sm w-100"
                onClick={() => handleUpdate(mhs.id, 9)}
              >
                <i className="fa fa-fw fa-sm fa-ban"/> Nonaktifkan
              </button>
            </>
          )}
        </div>
        <button
          type="button"
          onClick={handleClose}
          className="btn btn-sm btn-dark border-0 w-100"
        >
          <i className="fa fa-fw fa-sm fa-arrow-left"/> Kembali
        </button>
      </LayoutModal>
    </>
  );
};

export default MahasiswaNonaktifkan;
