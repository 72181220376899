import { fetchDataControl, fetchingData } from "../../api";
import axios from "../../axios";

// Ceklis Monitoring
// index
export const fetchCeklistMonitoring = async (page = 1, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/ceklist-monitoring?page=${page}&${custom}`);
  });
};
// show
export const showCeklistMonitoring = async (id, custom = "") => {
  return await fetchDataControl(async () => {
    return await axios.get(`/api/ceklist-monitoring/${id}?${custom}`);
  });
};
// store
export const postCeklistMonitoring = async (data) => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.post(`/api/ceklist-monitoring`, data);
  });
};
// update
export const patchCeklistMonitoring = async (id, data, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.patch(`/api/ceklist-monitoring/${id}?${custom ? custom : ""}`, data);
  });
};
// delete
export const deleteCeklistMonitoring = async (id, custom = "") => {
  fetchingData();
  return await fetchDataControl(async () => {
    return await axios.delete(`/api/ceklist-monitoring/${id}&${custom}`);
  });
};
