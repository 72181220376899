import React from "react";
import { useRecoilValue } from "recoil";
import { executeMaster } from "../api";
import { saAlert, saConfirm } from "../helpers";
import { userState } from "../storage/auth";

const ImportExport = () => {
  const user = useRecoilValue(userState);

  const handleSubmit = async () => {
    const res = await saConfirm("warning", "Yakin execute proses ?");

    if (res.isConfirmed) {
      try {
        const res = await executeMaster();
        console.log(res.data);
        if (res.data === "success") {
          saAlert("success", "Berhasil execute proses !");
        }
      } catch (err) {
        console.error(err);
      }
    }
  };

  if (user.jabatan === "IT Programmer") {
    return (
      <>
        <button className="btn btn-danger" onClick={handleSubmit}>
          Execute
        </button>
      </>
    );
  } else {
    return <h1>Opss</h1>;
  }
};

export default ImportExport;
