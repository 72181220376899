import React, { useRef, useState } from "react";
import { patchPeminjamanRuangan } from "../../api";
import FormInput from "../../components/forms/Input";
import FormSelect from "../../components/forms/Select";
import FormTextarea from "../../components/forms/Textarea";
import LayoutModal from "../../components/layouts/Modal";
import { saAlert } from "../../helpers";

const PeminjamanRuanganEdit = ({ ruangan, peminjamanRuangan, fetchData }) => {
  const tagForm = useRef(null);
  const [modal, setModal] = useState(false);

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleOpen = () => {
    setForm(peminjamanRuangan);
    setModal(true);
  };

  const handleClose = () => {
    tagForm.current && tagForm.current.reset();
    setModal(false);
    setForm({});
    setErrors({});
  };

  const handleChange = (e) => {
    setForm((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    try {
      const res = await patchPeminjamanRuangan(peminjamanRuangan.id, form);
      console.log(res.data);
      if (res.data && res.data.status) {
        fetchData();
        handleClose();
        saAlert(res.data.status, res.data.msg);
      }
    } catch (err) {
      err.response.status === 422 && setErrors(err.response.data.errors);
    }
  };

  return (
    <>
      {!peminjamanRuangan.mengetahui &&
        !peminjamanRuangan.menyetujui &&
        !peminjamanRuangan.rejected && (
          <>
            <button
              type="button"
              className="btn btn-sm btn-success-2 py-0 px-1"
              onClick={handleOpen}
            >
              <i className="fa fa-fw fa-sm fa-pen"/>
            </button>
            <LayoutModal
              title="Ubah Data Peminjaman Ruangan"
              modal={modal}
              onClose={handleClose}
            >
              <form onSubmit={handleSubmit} ref={tagForm}>
                <div className="row">
                  <div className="col-md-4">
                    <FormSelect
                      label="Ruangan"
                      name="ruangan_id"
                      value={form.ruangan_id}
                      onChange={handleChange}
                      error={errors.ruangan_id}
                    >
                      <option value="">Pilih</option>
                      {ruangan &&
                        ruangan.length > 0 &&
                        ruangan.map((r, index) => {
                          return (
                            <React.Fragment key={index}>
                              <option value={r.id}>{r.nama}</option>
                            </React.Fragment>
                          );
                        })}
                    </FormSelect>
                  </div>
                  <div className="col-md-4">
                    <FormInput
                      label="Dari"
                      type="datetime-local"
                      name="dari"
                      value={form.dari}
                      onChange={handleChange}
                      error={errors.dari}
                    />
                  </div>
                  <div className="col-md-4">
                    <FormInput
                      label="Sampai"
                      type="datetime-local"
                      name="sampai"
                      value={form.sampai}
                      onChange={handleChange}
                      error={errors.sampai}
                    />
                  </div>
                  <div className="col-md-4">
                    <FormInput
                      label="No Telp"
                      name="no_telp"
                      value={form.no_telp}
                      onChange={handleChange}
                      error={errors.no_telp}
                    />
                  </div>
                  <div className="col-md-4">
                    <FormTextarea
                      label="Keperluan"
                      name="keperluan"
                      value={form.keperluan}
                      onChange={handleChange}
                      error={errors.keperluan}
                    />
                  </div>
                </div>
                <button className="btn btn-sm btn-success" type="submit">
                  Ubah
                </button>
                <button
                  className="btn btn-sm btn-secondary"
                  type="button"
                  onClick={handleClose}
                >
                  Kembali
                </button>
              </form>
            </LayoutModal>
          </>
        )}
    </>
  );
};

export default PeminjamanRuanganEdit;
