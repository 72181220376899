import { useCallback, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { fetchMhs } from "../../api";
import { LayoutTable, LayoutThead } from "../../components/layouts/Table";
import PageLoading from "../../components/pages/Loading";
import PageNoData from "../../components/pages/NoData";
import SectionPagination from "../../components/sections/Pagination";
import { titleState } from "../../storage/pages";

const MhsOjtAkademik = () => {
  const setTitle = useSetRecoilState(titleState);
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [items, setItems] = useState();

  const fetchData = useCallback(async () => {
    try {
      const res = await fetchMhs(page);
      if (res.data && res.data.mhs) {
        setItems(res.data.mhs);
        setIsLoaded(true);
      }
    } catch (err) {
      console.log(err);
    }
  }, [page]);

  useEffect(() => {
    setTitle("MhsOjtAkademik");
    fetchData();
  }, [setTitle, fetchData]);

  if (isLoaded) {
    return (
      <>
        {items.data.length >= 1 ? (
          <LayoutTable>
            <LayoutThead>
              <th>No</th>
              <th>Cabang</th>
              <th>NIM</th>
              <th>Nama</th>
              <th>Jenjang</th>
              <th>Jurusan</th>
              <th>Angkatan</th>
              <th>Lokasi OJT</th>
              <th>Kelompok OJT</th>
              <th>Dosen OJT</th>
            </LayoutThead>
            <tbody>
              {items.data.map((i, index) => {
                return (
                  <tr key={index}>
                    <td>{items.from + index}</td>
                    <td>{i.cabang}</td>
                    <td>{i.nim}</td>
                    <td>{i.nama}</td>
                    <td>{i.jenjang}</td>
                    <td>{i.jurusan}</td>
                    <td>{i.angkatan}</td>
                    <td>
                      {i.pojt.length >= 1 ? (
                        i.pojt[0].penempatan
                      ) : (
                        <select
                          name=""
                          className="custom-select custom-select-sm"
                        >
                          <option value="">Pilih</option>
                        </select>
                      )}
                    </td>
                    <td>
                      {i.mhs_pojt.length >= 1 ? (
                        i.mhs_pojt[0].kelompok_nim
                      ) : (
                        <select
                          name=""
                          className="custom-select custom-select-sm"
                        >
                          <option value="">Pilih</option>
                        </select>
                      )}
                    </td>
                    <td>
                      {i.dosen.length >= 1 ? (
                        i.dosen[0].nama
                      ) : (
                        <select
                          name=""
                          className="custom-select custom-select-sm"
                        >
                          <option value="">Pilih</option>
                        </select>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </LayoutTable>
        ) : (
          <PageNoData />
        )}
        <SectionPagination
          links={items.links}
          onChangePage={(res) => setPage(res)}
        />
      </>
    );
  } else {
    return <PageLoading />;
  }
};

export default MhsOjtAkademik;
