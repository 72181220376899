import React, { useRef, useState } from "react";
import { postDisc } from "../../api";
import LayoutModal from "../../components/layouts/Modal";
import { saAlert, saConfirm } from "../../helpers";

const UploadDisc = (props) => {
  const { mhs, onFetchData } = props;
  const [modal, setModal] = useState(false);

  const tagForm = useRef();
  const [form, setForm] = useState({});

  const handleUploadDisc = (e, num, id) => {
    e.preventDefault();
    saConfirm(
      "warning",
      `Yakin telah mengisi form DISC ${num} dengan benar ?`
    ).then((res) => {
      if (res.isConfirmed) {
        if (form[`disc_${num}`]) {
          const data = new FormData();
          data.append(`disc_${num}`, form[`disc_${num}`][0]);
          data.append("id", id);

          postDisc(data)
            .then((res) => {
              if (res.data === "success") {
                onFetchData();
                setForm({});
                tagForm.current && tagForm.current.reset();
                saAlert("success", `Berhasil upload data DISC ${num} !`);
              }
            })
            .catch((err) => console.log(err));
        } else {
          saAlert("warning", "Form Kosong !");
        }
      }
    });
  };

  const handleClose = () => {
    setModal(false);
  };

  const handleOpen = () => {
    setModal(true);
  };

  return (
    <>
      <button className="btn btn-sm btn-primary-3 text-sm py-0 px-1" onClick={handleOpen} type="button">
        <i className="fa fa-fw fa-sm fa-cloud-arrow-up" />
      </button>
      <LayoutModal title={mhs.nama} modal={modal} onClose={handleClose}>
        <div className="row mb-2">
          <div className="col-12">
            <li>Upload file DISC dibawah ini.</li>
            <li>Format file .PDF</li>
          </div>
          <div className="col-md-6">
            <div className="bg-light m-2 p-2">
              <form
                onSubmit={(e) => handleUploadDisc(e, 1, mhs.id)}
                ref={tagForm}
              >
                <h3>DISC 1</h3>
                <div className="custom-file mb-2">
                  <input
                    className="custom-file-input"
                    name="disc_1"
                    type="file"
                    accept="application/pdf"
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.files,
                        };
                      })
                    }
                  />
                  <label htmlFor="" className="custom-file-label">
                    {form.disc_1
                      ? form.disc_1.length + " file terpilih."
                      : "Pilih File"}
                  </label>
                </div>
                <button className="btn btn-sm btn-success">Upload</button>
              </form>
            </div>
          </div>
          <div className="col-md-6">
            <div className="bg-light m-2 p-2">
              <form
                onSubmit={(e) => handleUploadDisc(e, 2, mhs.id)}
                ref={tagForm}
              >
                <h3>DISC 2</h3>
                <div className="custom-file mb-2">
                  <input
                    className="custom-file-input"
                    name="disc_2"
                    type="file"
                    accept="application/pdf"
                    onChange={(e) =>
                      setForm((prevState) => {
                        return {
                          ...prevState,
                          [e.target.name]: e.target.files,
                        };
                      })
                    }
                  />
                  <label htmlFor="" className="custom-file-label">
                    {form.disc_2
                      ? form.disc_2.length + " file terpilih."
                      : "Pilih File"}
                  </label>
                </div>
                <button className="btn btn-sm btn-success">Upload</button>
              </form>
            </div>
          </div>
        </div>
        <button className="btn btn-sm btn-secondary" onClick={handleClose}>
          Tutup
        </button>
      </LayoutModal>
    </>
  );
};

export default UploadDisc;
