import React from "react";

const FormRadio = (props) => {
  const { inline, error, name, value, id, checked, onChange, label } = props;
  return (
    <>
      <div className={`form-check ${inline && "form-check-inline"}`}>
        <input
          className={`form-check-input ${error ? "is-invalid" : "border-silver"}`}
          name={name}
          type="radio"
          defaultValue={value}
          id={id}
          checked={checked}
          onClick={onChange}
          onChange={onChange}
        />
        {label && (
          <label
            className={`form-check-label fw-semibold ${error ? 'text-danger' : 'text-primary'}`}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        {/* {error && <div className="invalid-feedback">{error}</div>} */}
      </div>
    </>
  );
};

export default FormRadio;
