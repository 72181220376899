import React from "react";

const CountOnTop = ({ baru, perpanjang, belum, nonaktif }) => {
  return (
    <div className="row mb-2">
      <div className="col-lg-12 text-center">
        <h5 className="mb-4 text-dark fw-semibold">Status MoU Perusahaan</h5>
      </div>
      <div className="col-lg mb-3">
        <div className="position-relative text-center bg-gradient-primary text-white rounded-3 shadow border-bottom-dark p-3">
          <div className="position-absolute shadow-sm rounded-pill bg-primary" style={{padding: '10px 6px', left: '-10px', top: '-10px'}}>
            <i className="fa fa-2xl fa-circle-check text-white"/>
          </div>
          <p className="mb-0 fw-semibold">Baru MoU</p>
          <h3 className="mb-0 fw-semibold">{baru}</h3>
        </div>
      </div>
      <div className="col-lg mb-3">
        <div className="position-relative text-center bg-gradient-success text-white rounded-3 shadow border-bottom-dark p-3">
          <div className="position-absolute shadow-sm rounded-pill bg-success-2" style={{padding: '10px 6px', left: '-10px', top: '-10px'}}>
            <i className="fa fa-2xl fa-circle-up text-white"/>
          </div>
          <p className="mb-0 fw-semibold">Perpanjang MoU</p>
          <h3 className="mb-0 fw-semibold">{perpanjang}</h3>
        </div>
      </div>
      <div className="col-lg mb-3">
        <div className="position-relative text-center bg-gradient-warning text-black rounded-3 shadow border-bottom-dark p-3">
          <div className="position-absolute shadow-sm rounded-pill bg-warning" style={{padding: '10px 6px', left: '-10px', top: '-10px'}}>
            <i className="fa fa-2xl fa-circle-xmark text-black"/>
          </div>
          <p className="mb-0 fw-semibold">Belum MoU</p>
          <h3 className="mb-0 fw-semibold">{belum}</h3>
        </div>
      </div>
      <div className="col-lg mb-3">
        <div className="position-relative text-center bg-gradient-danger text-white rounded-3 shadow border-bottom-dark p-3">
          <div className="position-absolute shadow-sm rounded-pill bg-danger" style={{padding: '10px 6px', left: '-10px', top: '-10px'}}>
            <i className="fa fa-2xl fa-circle-xmark text-white"/>
          </div>
          <p className="mb-0 fw-semibold">Nonaktif MoU</p>
          <h3 className="mb-0 fw-semibold">{nonaktif}</h3>
        </div>
      </div>
    </div>
  );
};

export default CountOnTop;
