import React from "react";
import { patchPendaftar } from "../../api";
import { saAlert, saConfirm } from "../../helpers";
import SmallButton from "../../components/forms/SmallButton";

const PendaftarUpdateKdbb = (props) => {
  const { pendaftar, onFetchData } = props;

  const handleUpdate = (id) => {
    saConfirm("question", `Yakin ubah status menjadi KDBB ?`).then((res) => {
      if (res.isConfirmed) {
        patchPendaftar(id, { kdbb: 1 }).then((res) => {
          if (res.data === "success") {
            onFetchData();
            saAlert("success", `Berhasil ubah status menjadi KDBB`);
          }
        });
      }
    });
  };

  return parseInt(pendaftar.kdbb) === 1 ? (
    <i className="fa fa-lg fa-fw fa-circle-check text-success"/>
  ) : (
    <SmallButton onClick={() => handleUpdate(pendaftar.id)} type="custom" buttonColor="primary-2" icon="turn-up" text="Jadikan KDBB"/>
  );
};

export default PendaftarUpdateKdbb;
