import React, { useRef, useState } from "react";
import { postPengajaran } from "../../api";
import FormInput from "../../components/forms/Input";
import LayoutModal, { NewSubmitButton } from "../../components/layouts/Modal";
import { LayoutAddBtn } from "../../components/layouts/TopContent";
import { getGenapGanjil, saAlert } from "../../helpers";

const PengajaranCreate = (props) => {
  const { onFetchData, tahunAjaran, semesterAjaran } = props;
  const [modal, setModal] = useState(false);
  const tagForm = useRef();

  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const handleClose = () => {
    if (tagForm && tagForm.current) {
      tagForm.current.reset();
    }
    setForm({});
    setErrors({});
    setModal(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (tahunAjaran !== "" && semesterAjaran !== "") {
      postPengajaran({
        ...form,
        add_row: true,
        tahun_ajaran: tahunAjaran,
        semester: semesterAjaran,
      })
        .then((res) => {
          if (res.data === "fail") {
            saAlert("warning", "Kelas pada tanggal yang dipilih sudah dibuat");
          } else {
            if (res.data.length > 0) {
              onFetchData();
              handleClose();
              saAlert("success", "Berhasil membuat jadwal pemblajaran !");
            } else {
              saAlert(
                "warning",
                "Gagal menambahkan jadwal pengajaran !",
                "Pastikan ada jadwal pengajaran pada tanggal yang anda pilih !"
              );
            }
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 422) {
            setErrors(err.response.data.errors);
          }
        });
    } else {
      handleClose();
      saAlert(
        "warning",
        "Gagal membuat jadwal pengajaran !",
        "Pastikan anda sudah memilih tahun ajaran dan semester ajaran terlebih dahulu sebelum membuat jadwal pengajaran"
      );
    }
  };

  return (
    <>
      <LayoutAddBtn
        onClick={() => setModal(true)}
        add="Buat Jadwal Pengajaran"
      />
      <LayoutModal size="sm" title="Pilih Tanggal" modal={modal} onClose={handleClose}>
        <form onSubmit={handleSubmit} ref={tagForm} className="text-black">
          <div className="bg-gradient-primary text-white rounded p-2 mb-3">
            <h4 className="mb-0">
              Tahun Ajaran {" "}
              {tahunAjaran !== "" ? (
                <span>{tahunAjaran}</span>
              ) : (
                <span className="text-danger">
                  "belum ditentukan, silahkan pilih tahun ajaran terlebih dahulu."
                </span>
              )}
            </h4>
            <h6 className="mb-0">
              Semester{" "}
              {semesterAjaran !== "" ? (
                <span className="text-warning">
                  {getGenapGanjil(semesterAjaran)}
                </span>
              ) : (
                <span className="text-danger">
                  "belum ditentukan, silahkan pilih semester ajaran terlebih
                  dahulu."
                </span>
              )}
            </h6>
          </div>
          <FormInput
            label="Tanggal Jadwal Pengajaran"
            name="tanggal"
            type="date"
            onChange={(e) =>
              setForm((prevState) => {
                return { ...prevState, [e.target.name]: e.target.value };
              })
            }
            error={errors.tanggal}
          />
          <div className="row">
            <NewSubmitButton widthFull onClose={handleClose}/>
          </div>
        </form>
      </LayoutModal>
    </>
  );
};

export default PengajaranCreate;
